/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function PTE(props) {
  const classes = useStyles();

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 25,
                  marginTop: 30,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">PTE</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="blog-area single full-blog left-sidebar full-blog default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="container"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <div className="row">
                    <div className="blog-items" style={{ width: "100%" }}>
                      <div className="blog-content col-md-12">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      //   marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      <h6>PTE Sections :</h6>
                                      The PTE Academic examination is really a
                                      computer-based English language Test
                                      approved by educational institutions
                                      across the globe. Those pupils and
                                      aspirants who want to go overseas for
                                      immigration or studying to some
                                      significant English speaking country are
                                      expected to take the PTE Academic
                                      examination to demonstrate their English
                                      language proficiency.
                                      <br />
                                      Afternoon English instead of high-level
                                      Language language and evaluations a pupil
                                      on their capability to efficiently
                                      comprehend the speech as spoken every day.
                                      The multi-level grading method ensures
                                      that a better comprehension of the pupil's
                                      proficiency in the English language. The
                                      first and foremost differentiating aspect
                                      of the exact same is that the scoring
                                      pattern as well as the outcomes. Since the
                                      evaluations are entirely automatic, the
                                      PTE scores and results are announcing much
                                      faster, typically in 5 business days. This
                                      makes it suited to students that are at a
                                      small hurry. Candidates Seeking to look to
                                      their PTE Academic evaluation will learn
                                      about the next PTE subjects as they read
                                      together:
                                      <br /><br />
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; PTE Qualification
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; PTE Test Dates
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; PTE Test Centre
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; How to Register to PTE
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; PTE Exam Fee
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Assessment Pattern for PTE
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; PTE Scholarships
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; PTE Preparation tips
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; PTE Syllabus
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; PTE Results and Cases
                                        </li>
                                      </ul>
                                      <br />
                                      PTE Academic examination is approved by
                                      thousands of instructional Institutions
                                      around the world. People who aspire to go
                                      overseas for studying in a significant
                                      English speaking country such as the UK,
                                      Australia, USA, and Canada require the PTE
                                      Academic examination to demonstrate their
                                      English language efficacy.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Different types of PTE Exam</h6>
                                      Responsible for running two formats of
                                      this English proficiency evaluation to
                                      evaluate the English language abilities of
                                      non-native speakers of the English
                                      language.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>PTE Academic Exam</h6>The PTE Academic
                                      examination is ran round-the-year, for
                                      Students seeking to find entry to a
                                      college or institution. The examination is
                                      composed of four segments, studying,
                                      writing, talking and listening. The scores
                                      of this PTE Academic examination is valid
                                      for a period of 2 decades.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>PTE General Exam</h6>The PTE General
                                      paper-primarily is made up of 2
                                      newspapers, - a written paper plus a
                                      spoken evaluation. The PTE General Test
                                      scores are valid for lifetime and
                                      therefore are utilized to check the
                                      communication abilities of their
                                      candidate.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        What are the eligibility standards for
                                        your PTE Academic Exam?
                                      </h6>
                                      PLC Group - that the running body of this
                                      PTE Academic examination. But, as stated
                                      by the PTE Academic eligibility standards,
                                      students should be at least 16 decades old
                                      at the time of looking for the exam.
                                      Additionally, candidates that are under 18
                                      decades old have to provide a parental
                                      consent form for looking for your PTE
                                      Academic examination.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        Age Limit to Look for PTE Academic Exam
                                      </h6>
                                      To Be Able to choose the PTE Academic
                                      evaluation, the candidate should Be 16
                                      years of age. Candidates under 18 decades
                                      old have to have a parental consent form
                                      signed by parents or guardians prior to
                                      taking the exam. Candidates may download
                                      the approval form from the official site
                                      of this PTE Academic Exam.
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        Education Qualification to look for PTE
                                        Academic Exam?
                                      </h6>
                                      Pearson PLC Group - that the running body
                                      of this PTE Academic Assessment hasn't
                                      laid down any schooling qualification
                                      criteria for applicants wanting to look
                                      for your PTE Academic exam.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>PTE Exam Fee</h6>The normal PTE
                                      Academic examination fee is Rs 13,300. In
                                      the event the Candidate is reserving his
                                      PTE examination within two days of this
                                      PTE test date, the candidate will be asked
                                      to cover the PTE Late Booking Fee of Rs.
                                      13,965.
                                      <br />
                                      If you do not want to look for your PTE
                                      Academic evaluation, then You're able to
                                      claim a refund prior to 14 calendar days
                                      prior to the exam date. Canceling or
                                      rescheduling the PTE Academic test can be
                                      performed on the PTE official site.
                                      Candidates may reschedule the PTE test
                                      2020 to some other date via telephone or
                                      online.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>PTE Exam Cancellation Charges</h6>
                                      Candidates who pick on not chasing their
                                      PTE Academic Evaluation after reserving
                                      their test on line have up to 14 calendar
                                      days ahead of their evaluation date to
                                      maintain a complete refund. Candidates who
                                      cancel the exam, with less than 14
                                      calendar days, however at least seven
                                      calendar days prior to their evaluation
                                      date will get a partial refund of 50
                                      percent of the exam fee paid. But, no
                                      refunds will be supplied to candidates on
                                      devoting the examination less than 7
                                      calendar days prior to the examination.
                                      Candidates must be aware that cancellation
                                      and reschedule policy doesn't include your
                                      exam date.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>PTE Rescheduling Fees</h6>Candidates
                                      who Intend to reschedule the exam, with
                                      less than 14 calendar days, however at
                                      least seven full calendar days ahead of
                                      their evaluation date will be asked to
                                      cover 50 percent of the exam fee. No
                                      refunds will be supplied on rescheduling
                                      the examination less than 7 calendar days
                                      prior to the examination. Candidates must
                                      note the policy doesn't include your exam
                                      date.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Other Ways of registering for PTE</h6>
                                      Aside from the much favored online Way of
                                      registering For the PTE examination,
                                      candidates may also enroll for the PTE
                                      Exam through telephone. Indian students
                                      wanting to enroll for the PTE Assessment
                                      can get in touch with the PTE Academic
                                      testing system by dialing: 00080 0440
                                      2020.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Know about PTE Slot Booking</h6>
                                      Candidates Seeking to look to their PTE
                                      Academic examination Will be asked to make
                                      a Pearson accounts to enroll to their PTE
                                      Academic Slot Booking. On successful
                                      registration, applicants would get an
                                      email within 48 hours using their login
                                      credentials. Candidates have to read the
                                      handbook carefully on the official site
                                      before reserving the examination.
                                      <br />
                                      The examination is conducted during the
                                      year in the designated These dates may
                                      differ from center to center. Pupils must
                                      Look for the seats on the Pearson PTE
                                      Academic Site. Just choose the test
                                      centers nearest to you, and click on next.
                                      The site will prompt one into the calendar
                                      together with the dates.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>PTE Exam Centres</h6>
                                      Here's a listing of PTE Academic Exam
                                      evaluation centres for Candidates
                                      appearing to look to their following PTE
                                      Academic Exam. Candidates are advised to
                                      opt for the exam test center that's most
                                      suitable for them to have the ability to
                                      carry out their best on the day of their
                                      examination.
                                      <br />
                                      Committed to providing accessibility for
                                      those with Disabilities, test
                                      accommodations also called"special
                                      arrangements" or even"reasonable
                                      adjustments" are made accessible from
                                      Pearson VUE to produce the test assessable
                                      to everybody. Arrangements made possible
                                      comprise
                                      <br />
                                      Their wants and how the handicap is
                                      bothering them from doing their best under
                                      normal conditions. Candidates would need
                                      to give documentation evidence and employ
                                      weeks beforehand for the running body to
                                      generate the necessary arrangements.
                                      Candidates need to submit an application
                                      for the petition before they reserve their
                                      examination. PTE Exam Pattern
                                      <br />
                                      PTE Academic Syllabus contains three
                                      segments - Discussing You get marked with
                                      those 3 sections and you receive the PTE
                                      Academic complete score too. The PTE Exam
                                      Pattern mentioned below discussions about
                                      the Elements of this PTE Academic Paper in
                                      detail to the sake of the candidate.
                                      Candidates appearing to look to their PTE
                                      Academic Exam may take a look at the PTE
                                      Exam Pattern cited below. To get a more
                                      comprehensive PTE Academic Exam Style
                                      evaluation, Click Here.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>PTE Syllabus</h6>It's important for
                                      applicants to Comprehend that the PTE
                                      Academic syllabus so as to look for the
                                      examination. The PTE Academic is widely
                                      divided into Discussing and Reading,
                                      Writing and Listening sections. Candidates
                                      may check out the comprehensive PTE
                                      Syllabus for every class below:
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Talking and Writing</h6>For the
                                      Talking and Writing Section query kinds
                                      would Include, private debut; reading ;
                                      replicate sentences; clarify picture;
                                      re-tell lecture; brief questions and
                                      outline the text.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Reading</h6>For your Reading Section,
                                      question types could comprise fill From
                                      the event, multiple-choice inquiries,
                                      re-order sentences.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Listening</h6>For your Listening
                                      Section, query kinds would revolve
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>PTE Outcomes and Score</h6>
                                      PTE Academic Outcomes are often available
                                      online within The PTE Academic outcomes
                                      are issued on the web. And could be
                                      retrieved from the PTE Academic student
                                      accounts. To begin with, you'll get an
                                      email within five working days together
                                      with directions on obtaining the results
                                      on the internet. Following that, you have
                                      to follow the directions to log into a
                                      Pearson account. You can discuss your
                                      results with all the schools and
                                      universities of your choice via your
                                      Pearson account.
                                      <br />
                                      Is cheapest, with increments of 1 stage.
                                      The scores are presented in a chart,
                                      providing a simple comprehension of the
                                      candidate's core abilities in the language
                                      in addition to progress places. How to Get
                                      PTE Score
                                      <br />
                                      Candidates may get their PTE Academic
                                      scores through their online account.
                                      Candidates could get an email notifying
                                      them about the access to their scores.
                                      After receiving the notification,
                                      applicants will be asked to log into their
                                      Pearson VUE accounts they made to reserve
                                      their own evaluations and get their
                                      scores.
                                      <br />
                                      Candidates Who Would like to send their
                                      rating can do this through an Online
                                      secure portal setup by PTE.
                                      <br />
                                      Login to your accounts. Click on'Send
                                      Categories'. Type the title of your chosen
                                      establishment
                                      <br />
                                      Review your information scroll the page
                                      down and click on 'Next' and 'Next' again
                                      to affirm
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Colleges Accepting PTE</h6>
                                      Candidates who have appeared to their PTE
                                      Academic Exam may Select from the massive
                                      number of schools that take PTE Scores.
                                      <br />
                                      Among the most frequently accepted
                                      computer-based tests of English, PTE
                                      Academic is normally undertaken for
                                      studying overseas and immigration choices.
                                      Often pupils begin the groundwork with
                                      joining a Training centre or just
                                      enrolling in free training tests. What a
                                      pupil forget is that, like every
                                      examination, even speech proficiency
                                      examinations need to be dealt using a
                                      methodology. Listed below are a couple
                                      research ideas that can help you perform
                                      from the PTE Academic Exam. It is,
                                      nevertheless, important to be aware that
                                      no quantity of ideas, tricks or tools may
                                      outwit the dedication and efficacy of this
                                      clinic.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        Which will be the best PTE Publications
                                        and Resources?
                                      </h6>
                                      Candidates may prepare for their PTE
                                      Academic with a range Of official class
                                      books. Candidates may pick from the
                                      below-mentioned study substance to shine
                                      in their PTE test. To get a listing of
                                      best books and tools, click here.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>PTE Sample Paper</h6>Your forthcoming
                                      PTE Academic Exam. Candidates are advised
                                      to fix multiple PTE Practice Papers for
                                      the sake of the pupil. With the purpose of
                                      improving your communication abilities,
                                      the PTE Academic examination is intended
                                      to check your control over the English
                                      language. No high level language
                                      proficiency is needed to clear the
                                      examination, it instead assesses your
                                      routine English communicating, thereby
                                      assisting you to become expressive.
                                      Assessing each section individually using
                                      a relaxed and concentrated mind is a good
                                      idea to fully absorb the subject matter
                                      and score nicely. Their evaluation, to
                                      check and pass safety. They need to not
                                      neglect to bring a suitable ID, usually a
                                      passport to the smooth performance of the
                                      examination formalities.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>PTE Scholarships</h6>Scholarships are
                                      offered on the official site of every
                                      faculty alongside other fellowships and
                                      grants which are given to school students
                                      on a regular basis. Students may also go
                                      for education loans from financial
                                      institutions such as studying overseas.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>PTE Exam FAQs</h6>However, have
                                      questions regarding your PTE evaluation,
                                      check out the Most often asked questions
                                      for almost any lingering query.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        Q. The way to pick the proper PTE exam?
                                      </h6>
                                      A. PTE is essentially of two types, PTE
                                      Academic is approved By universities
                                      globally for the primary goal of higher
                                      education overseas. Whereas, PTE General
                                      can be approved by employers and
                                      universities and serves as evidence of
                                      language proficiency and can be mostly
                                      used for employment purposes.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        Q. How do I prepare the PTE to receive
                                        79+ marks?
                                      </h6>
                                      Courses, candidates are constantly advised
                                      to provide in their best through the exam.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        Q. When can we receive the PTE academic
                                        examination success?
                                      </h6>
                                      A. Results are often available online
                                      within five working The PTE Academic
                                      outcomes are issued on the web. And could
                                      be retrieved from the PTE Academic student
                                      accounts. To begin with, you'll get an
                                      email within five working days together
                                      with directions on obtaining the results
                                      on the internet. Following that, you have
                                      to follow the directions to log into a
                                      Pearson account.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        Q. How Often can I look for your PTE
                                        Academic Exam?
                                      </h6>
                                      A. There's no limit from Pearson regarding
                                      just how many times a Candidates may take
                                      the examination with a gap Of 5 times. So,
                                      candidates may take the PTE Academic
                                      examination as many times as they want.
                                    </p>
                                  </div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div className="col-md-12">
                                    <div
                                      className="content"
                                      style={{ paddingBottom: 0 }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area default-padding">
        <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="row" style={{ display: "block" }}>
            <div className="about-items">
              <div className="col-md-12">
                <div className="weekly-top-items">
                  <div className="top-courses" style={{ paddingRight: 0 }}>
                    <div className="heading" style={{ paddingBottom: 15 }}>
                      <h4 style={{ color: "#3f8ccb" }}>
                        ACCREDITATIONS AND MEMBERSHIPS <strong></strong>
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/EAIE.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/ICEF.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/NAFSA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/QISAN.jpeg"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIRC.gif"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIEA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
