// export const BaseUrl='http://campusshala.com:3009'
//import BaseUrl from './BaseUrl';
// export const BaseUrl = "http://localhost:3009";
export const BaseUrl = "https://studymetro.com:3009";


const axios = require("axios");

// const salesForceGenerateTokenUrl ="https://login.salesforce.com/services/oauth2/token?grant_type=refresh_token&refresh_token=5Aep861Xq7VoDavIt7GhOrxNl2BYBoUE316rMepOKJBx_H6DoElJj6Q0ql.a_..NvgHd9kJPNIyUvtwgJhiIRwS&client_id=3MVG97quAmFZJfVxAkbxPNHDYWkJHF3c1YcXrU6LcKLtgJTTorrL5FxMAm2vuYZTmxOqAHePK9zLJY3EEmSWk&client_secret=13CFCAC18B4EEAA1E377DDA1BFAFA898F0D4C8B43F45A03BBDF3456B2296B30A"

// const generateRequestFromSalesForce =async()=>{
//   // console.log(salesForceGenerateTokenUrl)

//   try{
//     var response=await fetch(
//       "https://login.salesforce.com/services/oauth2/token?grant_type=refresh_token&refresh_token=5Aep861Xq7VoDavIt7GhOrxNl2BYBoUE316rMepOKJBx_H6DoElJj6Q0ql.a_..NvgHd9kJPNIyUvtwgJhiIRwS&client_id=3MVG97quAmFZJfVxAkbxPNHDYWkJHF3c1YcXrU6LcKLtgJTTorrL5FxMAm2vuYZTmxOqAHePK9zLJY3EEmSWk&client_secret=13CFCAC18B4EEAA1E377DDA1BFAFA898F0D4C8B43F45A03BBDF3456B2296B30A"
//       ,{
//       method:'GET',
//       mode:'cors',
//       headers:{
//         'Content-Type':"application/json;charset=utf-8"
//       }
//     })
//     console.log("**********")
//     console.log(response)
//     var result=await response.json()
//     console.log(result.access_token)
//     return(result)

//   }catch(e){
//     console.log(e)
//   return(false)
// }}

// const generateRequestFromSalesForce = async ( url )=>{
//   var response=await fetch(`${url}`,{
//     method:'get',
//     mode:'cors',
//     body:JSON.stringify(body),
//     headers:{
//       'content-type':"application/json;charset=utf-8"
//     }
//   })
//   var result=await response.json()
//   console.log(result)
//   return(result)
// }
const token = async () => {
  try {
    var url = `${BaseUrl}/authentication`;
    var config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const response = await axios.post(url, {}, config);
    var result = response.data;
    return result.access_token;
  } catch (error) {
    // console.log({error: error.response.status});
    return {status: false};
  }
};

const getDataLocal = async (url) => {
  try {
    var response = await fetch(`${BaseUrl}/${url}`, {
      method: "GET",
      mode: "cors",
      headers: { "content-type": "application/json;charset=utf-8",Authorization:`Bearer ${await token()}` },
    });
    console.log(response);
    var result = await response.json();
    console.log("**********");

    console.log(result);
    return result;
  } catch (e) {
    return false;
  }
};

const postDataLocal = async (url, body) => {
  var response = await fetch(`${BaseUrl}/${url}`, {
    method: "post",
    mode: "cors",
    body: JSON.stringify(body),
    headers: {
      "content-type": "application/json;charset=utf-8",Authorization:`Bearer ${await token()}` },
  });
  var result = await response.json();
  return result;
};

const postDataAndImageLocal = async (url, formData) => {
  try {
    const config = {
      headers: {
        'Content-type': 'multipart/form-data',
        Authorization: `Bearer ${await token()}`,
      },
    };
    const response = await axios.post(`${BaseUrl}/${url}`, formData, config);
    var result = response.data[0].RESULT;
    return result;
  } catch (e) {
    return false;
  }
};
const getData = async (url) => {
  try {
    const response = await fetch(`${BaseUrl}/${url}`);
    const result = await response.json();
    return result;
  } catch (e) {
    return null;
  }
};




export { getDataLocal, postDataAndImageLocal, postDataLocal, getData};
