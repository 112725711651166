/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [messages, setMessages] = React.useState("");
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus, setStatus] = React.useState("");

  const handlePicture = (event) => {
    setPicturePath(URL.createObjectURL(event.target.files[0]));
    setPicture(event.target.files[0]);
  };

  const handleVerifyPassword = (e) => {
    setVerifyPassword(e.target.value);
    if (password === e.target.value) {
      setStatus("Password Matched");
    } else {
      setStatus("Password not matched");
    }
  };

  //   const addNewRecord=async()=>{
  // if(picture=='')
  // {
  //     var body={'firstName':firstName,
  //     'lastName':lastName,
  //     'emailId':emailId,
  //     'password':password,
  //     'status':"Not Approved",
  //     'picture':"Not Found"}
  //     var result=await postData('selfregister/addNewRecord',body)
  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}

  // }

  // else{
  //    var formData=new FormData()
  //    formData.append('firstName',firstName)
  //    formData.append('lastName',lastName)
  //    formData.append('emailId',emailId)
  //    formData.append('password',password)
  //    formData.append('picture',picture)
  //    formData.append('status', "Not Approve")

  //    var config={headers:{'content-type':'multipart/form-data'}}
  //     var result=await postDataAndImage('selfregister/addNewRecordPic',formData,config)

  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}
  //     }
  //     }

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 70,
                }}
              >
                {/* <div className="banner-area inc-form bg-gradient slider-less text-light" style={{backgroundImage: "url(" + "../assets/img/event/1.jpg" + ")", padding:70}}> */}
                <div className="container">
                  <div className="row">
                    {/* <div className="double-items">
                    <div className="col-md-7 info">
                        <h2 className="wow fadeInLeft">Find your ideal study program.</h2>
                        <a className="btn day btn-light border btn-md wow fadeInDown" style={{color:'#3f8ccb'}} href="#">View Courses</a>
                    </div>
                    <div className="col-md-5 reg-form">
                        <form action="#">
                            <div className="row">
<img style={{flex:1}} class="login_image" src="assets/img/elearning.png" alt="Login" data-original-title="" title="" />

                            </div>
                        </form>
                    </div>
                </div> */}
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">Apostille</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="about-area default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div className="container">
                  <div className="row">
                    <div className="about-items">
                      <div className="col-md-6 about-info">
                        <h2>
                          Apostille <span></span>
                        </h2>

                        <p
                          style={{
                            fontSize: 14,
                            color: "#212121",
                            textAlign: "justify",
                          }}
                        >
                          We would like to introduce ourselves as specialists of
                          legalisation documents from all departments like HRD,
                          Home Department Attestation as well as all Embassy
                          Apostille Attestation.
                        </p>
                        <p
                          style={{
                            fontSize: 14,
                            color: "#212121",
                            textAlign: "justify",
                          }}
                        >
                          We are well managed by a group of young and
                          experienced people with a strong technical and
                          innovative team. We offer a rich service to the
                          clients for all Embassy Attestation. Genuine
                          Attestation Services is the one stop solution center
                          for all Attestation requirements.
                        </p>
                      </div>
                      <div className="col-md-6 features text-light">
                        <img
                          style={{ flex: 1 }}
                          class="login_image"
                          src="assets/img/apostille.png"
                          alt="Login"
                          data-original-title=""
                          title=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="blog-area single full-blog left-sidebar full-blog default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="container"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <div className="row">
                    <div className="blog-items">
                      <div className="blog-content col-md-12">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    {/* <p style={{ color: "#212121" }}>
                                      We would like to introduce ourselves as
                                      specialists of legalisation documents from
                                      all departments like HRD, Home Department
                                      Attestation as well as all Embassy
                                      Apostille Attestation.
                                    </p> 
                                    <p style={{ color: "#212121" }}>
                                      We are well managed by a group of young
                                      and experienced people with a strong
                                      technical and innovative team. We offer a
                                      rich service to the clients for all
                                      Embassy Attestation. Genuine Attestation
                                      Services is the one stop solution center
                                      for all Attestation requirements.
                                    </p>*/}
                                    <p style={{ color: "#212121" }}>
                                      We Provide the Complete process of
                                      attestation for your Educational,
                                      Non­Educational, Commercial and all other
                                      Documents Attestation from HRD, MEA, GAD,
                                      SDM, NOTARY, HOME MINISTRY, ALL EMBASSIES,
                                      CONSULATE and all other related
                                      departments.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      The entire work is done as per the
                                      specific need of our customers & clients
                                      within the time frame promised. The
                                      services we are offering are given below
                                      with lowest price:­
                                    </p>
                                    <div class="container-fluid">
                                      <div class="row">
                                        <div class="col-sm-4">
                                          <p
                                            style={{
                                              color: "#212121",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            MEA
                                          </p>
                                          <p
                                            style={{
                                              color: "#212121",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Normal Apostle (MEA) :­{" "}
                                          </p>
                                          <p
                                            style={{
                                              color: "#212121",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Maharashtra +MEA :­
                                          </p>
                                          <p
                                            style={{
                                              color: "#212121",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            SDM+MEA :­
                                          </p>
                                          <p
                                            style={{
                                              color: "#212121",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            SDM+Apostille :­
                                          </p>
                                          <p
                                            style={{
                                              color: "#212121",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            SDM+Apostille WNR:­
                                          </p>
                                          <p
                                            style={{
                                              color: "#212121",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            CHAMBER +MEA :­
                                          </p>
                                        </div>
                                        <div class="col-sm-4">
                                          {" "}
                                          <p
                                            style={{
                                              color: "#212121",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Uae Embassy :­
                                          </p>
                                          <p
                                            style={{
                                              color: "#212121",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Notary+Mea+Uae:­{" "}
                                          </p>
                                          <p
                                            style={{
                                              color: "#212121",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Kuwait Embassy :­{" "}
                                          </p>
                                          <p
                                            style={{
                                              color: "#212121",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Notary+Mea+Kuwait :­{" "}
                                          </p>
                                          <p
                                            style={{
                                              color: "#212121",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Vietnam Embassy :­{" "}
                                          </p>
                                          <p
                                            style={{
                                              color: "#212121",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            China Embassy :­{" "}
                                          </p>
                                          <p
                                            style={{
                                              color: "#212121",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Indonesia Embassy :­{" "}
                                          </p>
                                        </div>
                                        <div class="col-sm-4">
                                          {" "}
                                          <p
                                            style={{
                                              color: "#212121",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Bahrain Embassy:­{" "}
                                          </p>
                                          <p
                                            style={{
                                              color: "#212121",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Thailand Embassy :­{" "}
                                          </p>
                                          <p
                                            style={{
                                              color: "#212121",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Malaysia Embassy :­{" "}
                                          </p>
                                          <p
                                            style={{
                                              color: "#212121",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Philippines Embassy :­{" "}
                                          </p>
                                          <p
                                            style={{
                                              color: "#212121",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Jordan Embassy :­{" "}
                                          </p>
                                          <p
                                            style={{
                                              color: "#212121",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Mea+Saudi :­{" "}
                                          </p>
                                          <p
                                            style={{
                                              color: "#212121",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Sdm+Mea+Saudi :­{" "}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div className="col-md-12">
                                    <div
                                      className="content"
                                      style={{ paddingBottom: 0 }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area default-padding">
        <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="row" style={{ display: "block" }}>
            <div className="about-items">
              <div className="col-md-12">
                <div className="weekly-top-items">
                  <div className="top-courses" style={{ paddingRight: 0 }}>
                    <div className="heading" style={{ paddingBottom: 15 }}>
                      <h4 style={{ color: "#3f8ccb" }}>
                        ACCREDITATIONS AND MEMBERSHIPS <strong></strong>
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/EAIE.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/ICEF.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/NAFSA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/QISAN.jpeg"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIRC.gif"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIEA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
