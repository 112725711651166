import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";
import { CenterFocusStrong } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    // margin: 'center',
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function LandingPage(props) {
  const classes = useStyles();

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <br />

      <div className="blog-area single full-blog left-sidebar full-blog default-padding">
        <div className="container">
          <div className="row">
            <div className="blog-items">
              <div className="blog-content col-md-12">
                <div className="content-items">
                  <div className="single-item">
                    <div className="item">
                      <div className="info">
                        <div className="content" style={{ paddingBottom: 0 }}>
                          <div
                            style={{
                              marginLeft: 5,
                              textAlign: "justify",
                            }}
                          >
                              <center><h3 style={{color:'black'}}>ACCREDITATIONS AND MEMBERSHIPS</h3></center>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={3}>
                                <div>
                                  <img
                                    src="assets/img/AIEA Logo .png"
                                    width="500"
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <div style={{marginTop:80, marginLeft:30}}>
                                  <img
                                    src="assets/img/AIRC Logo .png"
                                    width="500"
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <div style={{marginLeft:30}}>
                                  <img
                                    src="assets/img/APAIE logo .png"
                                    width="500"
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <div style={{marginTop:80, marginLeft:30}}>
                                  <img
                                    src="assets/img/CARE-Canada.png"
                                    width="500"
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <div style={{marginLeft:30}}>
                                  <img
                                    src="assets/img/ICEF logo .jpeg"
                                    width="500"
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <div style={{marginLeft:30}}>
                                  <img
                                    src="assets/img/IUNC .jpeg"
                                    width="500"
                                  />
                                </div>
                              </Grid>
                              
                              <Grid item xs={12} sm={3}>
                                <div style={{marginLeft:30}}>
                                  <img
                                    src="assets/img/QISAN .jpeg"
                                    width="500"
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <div style={{marginLeft:30}}>
                                  <img
                                    src="assets/img/NAFSA.png"
                                    width="500"
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <div style={{marginLeft:30}}>
                                  <img
                                    src="assets/img/eaie-logo.png"
                                    width="500"
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <div style={{marginLeft:30}}>
                                  <img
                                    src="assets/img/PIER Logo .png"
                                    width="500"
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <div style={{marginLeft:30}}>
                                  <img
                                    src="assets/img/NACAC.jpeg"
                                    width="500"
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <div style={{marginLeft:30}}>
                                  <img
                                    src="assets/img/Weba logo .png"
                                    width="500"
                                  />
                                </div>
                              </Grid>

                            </Grid>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Container>
  );
}
