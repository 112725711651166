/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-redeclare */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { postDataAndImage, postData } from "../FetchServices";
import {
  postDataAndImageLocal,
  postDataLocal,
  getDataLocal,
  BaseUrl,
} from "../FetchServicesLocal";
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Alert from "@material-ui/lab/Alert";
import renderHTML from 'react-render-html';
import createSvgIcon from "@material-ui/core";
import { isEmail, isEmpty, isMobile, isName,validate } from "./Checks";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";
import Header from "./Header";
import Footer from "./Footer";
// import BaseUrl from './BaseUrl';

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  input: {
    padding: "0px 0px",
  },
  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 16,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },
  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function CreateAccount(props) {
  const classes = useStyles();
  const [getopen, setsbOpen] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [fullName, setFullName] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [mobileNumber, setMobileNumber] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [messages, setMessages] = React.useState("");
  const [emailMessage, setEmailMessage] = React.useState("");
  const [phoneMessage, setPhoneMessage] = React.useState("");
  const [passwordMessage,setPasswordMessage]=React.useState("");
  const [getStatus, setStatus] = React.useState("");
  // const [errorMessage, setErrorMessage] = React.useState("Errors<br/>");
  // const handlePicture=(event)=>{
  //   setPicturePath(URL.createObjectURL(event.target.files[0]))
  //   setPicture(event.target.files[0])
  //  }

  const handleVerifyPassword = (e) => {
    setVerifyPassword(e.target.value);
    if (password === e.target.value) {
      setStatus("Password Matched");
    } else {
      setStatus("Password not matched");
    }
  };

  // hooks for empty values showing errors
  const [firstNameError, setFirstNameError] = React.useState(false);
  const [lastNameError, setLastNameError] = React.useState(false);
  const [fullNameError, setFullNameError] = React.useState(false);
  const [emailIdError, setEmailIdError] = React.useState(false);
  const [mobileNumberError, setMobileNumberError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState("");
  const [verifyPasswordError, setVerifyPasswordError] = React.useState("");

  const addNewRecord = async () => {
    
    setEmailMessage("")
    setPhoneMessage("")
    setPasswordMessage("")
    setMessages("");
    var count = 0;
    var passCount = 0;
    var emailCount = 0;

    // This part is to generate access token from sales force , but error occured cors

    var result = await getDataLocal("profilebuilder/generateToken");
    console.log("------")
    console.log(result)

    //   var dataFromSalesForce = await generateRequestFromSalesForce();
    //  console.log("-----")
    //   console.log(dataFromSalesForce)

    if (firstName === "") {
      setFirstNameError(true);
      count++;
    }
    if (lastName === "") {
      setLastNameError(true);
      count++;
    }
    if (fullName === "") {
      setFullNameError(true);
      count++;
    }
    if (emailId === "") {
      setEmailIdError(true);
      count++;
    }
    if (mobileNumber === "") {
      setMobileNumberError(true);
      count++;
    }
    if (password === "") {
      setPassword(true);
      count++;
    }
    if (verifyPassword === "") {
      setVerifyPasswordError(true);
      count++;
    }

     
    if(!isEmail(emailId))
    {  emailCount++
    }

    if(!validate(password)){
      passCount++
    }
    


    // This data is to save data in studymetro database
    if (count) {
      setMessages(<font color="red">Please fill all the details..</font>)
    }
    else if(emailCount){
      setEmailMessage(<font color="red">Please Enter Valid Email Address</font>)
    }

    else if(passCount){
      setPasswordMessage(<font color="red">Password must Contain atleast one uppeacase,lowercase,number,special character.
      Minimum password length must be 8 </font>)
    }

      
    else {
      await setMessages(<font color="black">Please wait..</font>);

      var body = {
        first_name: firstName,
        last_name: lastName,
        username: fullName,
        email: emailId,
        phone_number: mobileNumber,
        password: password,
        verify_password: verifyPassword,
      };

      var result = await postDataLocal("profilebuilder/registerUsers", body);
      // console.log(result)
      if (result.RESULT) {
        props.history.push({pathname:'/Login'})
        await setMessages(<font color="green">Registered Successfully..</font>);
        var Cryptr = require("cryptr");
        var cryptr = new Cryptr("mysecret");
          
        var encstring = cryptr.encrypt(emailId);
        let bodyyy = { email: emailId,
          username: fullName,
          message: `${BaseUrl}/verify/${encstring}`} 
          console.log('bodyyyyyyyyyyy>>>>>>>>>>>',bodyyy);
        let status = await postDataLocal("email/sendemail", {
          email: emailId,
          username: fullName,
          message: `${BaseUrl}/verify/${encstring}`,
        });
      } else {
        await setMessages(<font color="red">Failed to registered..</font>);
      }
    }

    // These comments data are old data

    // eslint-disable-next-line eqeqeq
    // if(picture=='')
    // {
    //     var body={'firstName':firstName,
    //     'lastName':lastName,
    //     'emailId':emailId,
    //     'password':password,
    //     'status':"Not Approved",
    //     'picture':"Not Found"}
    //     var result=await postData('selfregister/addNewRecord',body)
    // if(result){
    //   await setMessages("Agent Succefully Created")

    //       var body={
    //         'emailid':emailId,
    //         'password':password
    //        }
    // var resultt = await  postData('selfregister/checkagentsignin',body)

    // if (resultt.RESULT)
    // {

    // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
    // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
    // }
    // else
    // {
    // alert("Failed To Created Account")
    // }

    // await setFirstName('')
    // await setLastName('')
    // await setEmailId('')
    // await setPassword('')
    // await setVerifyPassword('')
    // await setPicturePath('')
    // }
    // else{setMessages("Faild to Submit")}

    // }

    // else{
    //  var formData=new FormData()
    //  formData.append('firstName',firstName)
    //  formData.append('lastName',lastName)
    //  formData.append('emailId',emailId)
    //  formData.append('password',password)
    //  formData.append('picture',picture)
    //  formData.append('status', "Not Approve")

    //  var config={headers:{'content-type':'multipart/form-data'}}
    //   var result=await postDataAndImage('selfregister/addNewRecordPic',formData,config)

    // if(result){
    //   await setMessages("Agent Succefully Created")

    // var body={
    //   'emailid':emailId,
    //   'password':password
    //  }
    // var resultt = await  postData('selfregister/checkagentsignin',body)

    // if (resultt.RESULT)
    // {

    // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
    // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
    // }
    // else
    // {
    // alert("Failed To Created Account")
    // }

    // await setFirstName('')
    // await setLastName('')
    // await setEmailId('')
    // await setPassword('')
    // await setVerifyPassword('')
    // await setPicturePath('')
    // }
    // else{setMessages("Faild to Submit")}
    //     }
  };

  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = state;

  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleSBClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setsbOpen(false);
  };


  // console.log("History ==  ",props)
  return (
    <Container className={classes.widths}>
      <Header />
      <div
        className="login-area default-padding"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="col-md-6">
                <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <CssBaseline />

                  <MDBContainer style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <MDBRow>
                      <MDBCol md="9">
                        <MDBCard>
                          <MDBCardBody>
                            <MDBCardHeader
                              className="form-header deep-blue-gradient rounded"
                              style={{
                                color: "white",
                                textAlign: "center",
                                padding: "2px 2px",
                              }}
                            >
                              <h3 className="my-2">
                                <MDBIcon icon="lock" /> Registration
                              </h3>
                            </MDBCardHeader>
                            <div className={classes.paper}>
                              <form className={classes.form} noValidate>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      // inputProps={{style: {fontSize: 40}}}
                                      // InputLabelProps={{style: {fontSize: 12}}}
                                      autoComplete="fname"
                                      name="firstName"
                                      error={firstNameError}
                                      variant="outlined"
                                      required
                                      fullWidth
                                      id="firstName"
                                      value={firstName}
                                      label="First Name"
                                      autoFocus
                                      onChange={(event) => {
                                        setFirstNameError(false);
                                        setFirstName(event.target.value);
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      variant="outlined"
                                      error={lastNameError}
                                      // InputLabelProps={{style: {fontSize: 12}}}
                                      required
                                      fullWidth
                                      id="lastName"
                                      value={lastName}
                                      label="Last Name"
                                      name="lastName"
                                      autoComplete="lname"
                                      onChange={(event) => {
                                        setLastNameError(false);
                                        setLastName(event.target.value);
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={12}>
                                    <TextField
                                      variant="outlined"
                                      error={fullNameError}
                                      // InputLabelProps={{style: {fontSize: 12}}}
                                      required
                                      fullWidth
                                      id="fullName"
                                      value={fullName}
                                      label="Full Name"
                                      name="fullName"
                                      autoComplete="fname"
                                      onChange={(event) => {
                                        setFullNameError(false);
                                        setFullName(event.target.value);
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={12}>
                                    <TextField
                                      // InputLabelProps={{style: {fontSize: 12}}}
                                      variant="outlined"
                                      required
                                      fullWidth
                                      id="emailid"
                                      error={emailIdError}
                                      value={emailId}
                                      label="Email Address"
                                      name="email"
                                      autoComplete="email"
                                      onChange={(event) => {
                                        setEmailIdError(false);
                                        setEmailId(event.target.value);
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <MuiPhoneInput
                                      defaultCountry="in"
                                      error={mobileNumberError}
                                      regions={"asia"}
                                      variant="outlined"
                                      value={mobileNumber}
                                      onChange={(value) => {
                                        setMobileNumberError(false);
                                        setMobileNumber(value);
                                      }}
                                      className={classes.formControl}
                                      label="Phone"
                                      // inputProps={{    style: {fontSize: 12}   }}
                                      // InputLabelProps={{style: {fontSize: 12}}} // font size of input label
                                    />
                                  </Grid>

                                  <Grid item xs={12}>
                                    <TextField
                                      variant="outlined"
                                      //       InputLabelProps={{style: {fontSize: 12}}}
                                      required
                                      fullWidth
                                      name="password"
                                      label="Password"
                                      type="password"
                                      id="password"
                                      value={password}
                                      //autoComplete="current-password"
                                      onChange={(event) => {
                                        // setPasswordError(false);
                                        setPassword(event.target.value);
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField
                                      //     InputLabelProps={{style: {fontSize: 12}}}
                                      variant="outlined"
                                      required
                                      fullWidth
                                      name="verifypassword"
                                      label="Confirm Password"
                                      type="password"
                                      id="verifypassword"
                                      value={verifyPassword}
                                      autoComplete="verify-password"
                                      onChange={(e) => {
                                        setVerifyPasswordError(false);
                                        handleVerifyPassword(e);
                                      }}
                                    />
                                    {getStatus}
                                  </Grid>
                                  {/*            
            <Grid item xs={12}>
            <FormControl className={classes.formControl}>
        <InputLabel >User Type</InputLabel>
        <Select
      //  InputLabelProps={{style: {fontSize: 12}}}
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          
        >
         
          <MenuItem  value={10}>Admin</MenuItem>
          <MenuItem  value={20}>User</MenuItem>
          <MenuItem  value={30}>Student</MenuItem>
        </Select>
      </FormControl>
</Grid>
   
<Grid item xs={12}>        
  <TextField
   
    id="date"
  //  InputLabelProps={{style: {fontSize: 12}}}
    label="Birthday"
    type="date"
    defaultValue="2017-05-24"
    className={classes.formControl}
    InputLabelProps={{
      shrink: true,
      
    }}
  //  inputProps={{ style: {fontSize: 12}     }}
 //   InputLabelProps={{style: {fontSize: 13}}} // font size of input label
  />
</Grid>

            <Grid item xs={12}>
               
                <FormControl className={classes.formControl}>
        <InputLabel>Intrested For</InputLabel>
        <Select
       
         
          id="demo-controlled-open-select"
          
        >
         
          <MenuItem  value={10}>Undergraduation  Masters</MenuItem>
          <MenuItem  value={20}>PhD</MenuItem>
        
        </Select>
      </FormControl>
      
            </Grid>


            <Grid item xs={12}>
               
            <TextField
                variant="outlined"
                required
             //   InputLabelProps={{style: {fontSize: 12}}}
                fullWidth
                name="city"
                label="City"
                type="text"

              />
     
           </Grid>
           <Grid item xs={12}>
               <label>Male</label>
               <Checkbox
                   variant="outlined"
                   required
                   fullWidth
                   name="city"
                   label="City"
                   type="checkbox"
   
                 />

<label>Female</label>
               <Checkbox
                   variant="outlined"
                   required
                   fullWidth
                   name="city"
                   label="City"
                   type="checkbox"
   
                 />
        
              </Grid>

              <Grid item xs={12}>
               
               <TextField
             //  InputLabelProps={{style: {fontSize: 12}}}
                   variant="outlined"
                   required
                   fullWidth
                   name="facebookid"
                   label="Facebook ID"
                   type="text"
   
                 />
        
              </Grid>

              <Grid item xs={12}>
               
               <TextField
             //  InputLabelProps={{style: {fontSize: 12}}}
                   variant="outlined"
                   required
                   fullWidth
                   name="instaid"
                   label="Instagram ID"
                   type="text"
   
                 />
        
              </Grid>

              <Grid item xs={12}>
               
               <TextField
             //  InputLabelProps={{style: {fontSize: 12}}}
                   variant="outlined"
                   required
                   fullWidth
                   name="twitterid"
                   label="Twitter ID"
                   type="text"
   
                 />
        
              </Grid> */}
                                </Grid>

                                <div className="text-center mt-3">
                                  <div className="blog-area">
                                    <div className="info">
                                      <div
                                        className="content"
                                        style={{
                                          padding: 0,
                                          position: "static",
                                        }}
                                      >
                                        <a
                                          href="#"
                                          // onClick={handleClick({ vertical: 'bottom', horizontal: 'center' })}
                                          onClick={()=>addNewRecord()}
                                          style={{
                                            color: "#000",
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          <i
                                            className="fa fa-lock"
                                            style={{ color: "#000" }}
                                          ></i>{" "}
                                          Register Now
                                        </a>
                                      </div>
                                    </div>
                                  </div>

                                  <Snackbar
                                    open={open}
                                    autoHideDuration={2000}
                                    anchorOrigin={{ vertical, horizontal }}
                                    key={vertical + horizontal}
                                    onClose={handleClose}
                                  >
                                    <Alert
                                      onClose={handleClose}
                                      severity="success"
                                    >
                                      This is a success Create Account!
                                    </Alert>
                                  </Snackbar>
                                </div>
                                <br />
                                <Typography>
                                  <div style={{display:'flex',flexDirection:'column'}}>
                                   <span> {messages}</span>
                                   <span> {emailMessage}</span>
                                   <span> {phoneMessage}</span>
                                   <span> {passwordMessage}</span>
                                  </div>
                                  </Typography>

                                <br />

                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "center" }}
                                  >
                                    <label>
                                      Already Registered User?
                                      <a
                                        href="/Login"
                                        style={{ float: "right" }}
                                      >
                                        &nbsp;&nbsp; Click here to login
                                      </a>{" "}
                                    </label>
                                  </Grid>
                                </Grid>
                              </form>
                            </div>
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </Container>
              </div>
              <div className="col-md-6">
                <img
                  style={{ flex: 1,marginTop:75,height:500,width:400,display:'flex' }}
                  class="login_image"
                  src="assets/img/login10.jpg"
                  alt="Login"
                  data-original-title=""
                  title=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
    </Container>
  );
}
