/* eslint-disable no-useless-concat */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-redeclare */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment,useState,useEffect } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import icon from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Header from './Header';
import Footer from './Footer';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';



const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
newbtn:{
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
},

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:12,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },

widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function Compare(props) {
  const classes = useStyles();
 
  const[getList, setList]=useState([]);
  const[getTempList, setTempList]=useState([]);
  
  const CertificateList=async(option)=>{
    if(props.location.state){
      var body={'course_type':props.location.state.option}
    }
     else{
      var body={'course_type':3}
     }
    let list= await postData('abroad/fetchcetificateData',body);
    setList(list);
    setTempList(list);
    // console.log(getList);
  }
/*-------------pass id as url------------------ */
const handleCourse = (id) => {
  // alert("hello next Page");
    props.history.push({
      pathname: "/DisplayCourses",
      state: {
        id: id,
      }
    });
  // console.log(event.currentTarget);
};


   useEffect (function(){
    CertificateList()
    // console.log(props);
   },[])
   var colors=['colr1','colr2','colr3','colr4','colr5','colr6','colr7','colr8','colr9','colr10','colr11','colr12','colr13','colr14','colr15','colr16','colr17','colr18','colr19'];

   // search data itme
   const[getSearchData, setSearchData]=useState('')
    
   const handleSearch=async(event)=>{
    event.preventDefault()
    var tempArr=[];
    getTempList.map(item=>{
      const lData=item.name.toLowerCase()
      if(lData.includes(getSearchData.toLowerCase())){
        tempArr.push(item)
      }
    })
    console.log({tempArr})
    setList(tempArr)
}


  return (
   <Container className={classes.widths}>
   <Header/>  <CssBaseline />
    <div className="login-area default-padding" style={{paddingTop:20,}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">
<div className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard" style={{backgroundImage: "url(" + "../assets/img/banner/6.jpg" + ")",padding:70}}>             

        <div className="container">
            <div className="row">
            <div className="double-items col-md-12 info">
                <h2 className="wow fadeInLeft">Certificate/Diploma Program</h2>
                </div>
             
            </div>
        </div>
    </div>


    <section class="video_sec" style={{padding:10,}}>
<div class="container">
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <div class="about_content">
                <h1>Certificate/Diploma Program assistance towards study abroad dream. No hurriedness-special attention</h1>
<p>Top programs study abroad assistance with direction to guidelines &amp; admission requirements, Public &amp; affordable universities, Graduate assistantship USA, Europe</p>
<p>Study Metro top guide study abroad helps find US/Canada/Europe higher degrees at an ease with great scholarship achievement, even free study Europe. GRE/IELTS/SAT guidance which helps in building attactractive profile in sense of application, visa &amp; scholarship.</p>
<p><span style={{fontSize: 14,}}><strong>Abroad Courses</strong></span></p>            </div>
        </div>
    </div>
</div>
</section>
<section class="membership_plan explore_sec">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                             <div class="explore_head">
                             <h3>Explore our Certificate/Diploma Programs in Abroad</h3>
                                    <div class="search-area text-center">

                    <div class="search-content" style={{padding:0,}}>
                        <div class="row">
                            <form action="#" style={{margin:0,marginLeft:100,}}>
                                <input type="text" placeholder="Enter course name" class="form-control" name="text"
                                onChange={(event)=>setSearchData(event.target.value)}/>
                                <button type="submit" onClick={handleSearch} style={{marginTop:5,}}>
                                    Search
                                </button>
                            </form>
                </div>
            </div>
        </div>
                                </div>

 
                                <div class="explore_box">
                               
                                <ul class="explore_list clearfix">
                                {getList.map((item)=>(<li class={colors[Math.floor((Math.random()*15))]}>
                                          {/* <a href="https://studymetro.com/search-programs?program=Accounting&amp;country=USA&amp;id=&amp;course=Graduate+Course"> */}
                                          <a 
                                          style={{borderColor:'#FFF', borderWidth:1, borderStyle:'solid'}}
                                   onClick={() => handleCourse(item.id)}
                                   >
                                            {item.name}
                                          </a>
                                          </li>))}
                                          </ul>
                            </div>
              
                        </div>
                    </div>
                </div>
            </section>


    <div className="advisor-details-area default-padding" style={{padding:0,}}>
   
   </div>



</div>
</div>
</div>
</div>

<Footer/>
    </Container>
  );
}
