import React, { Fragment, useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import { UncontrolledCollapse, CardBody, Card } from "reactstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FlagIconFactory from "react-flag-icon-css";
import Header from "./Header";
import Footer from "./Footer";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";
import { FormInput } from "semantic-ui-react";
import { getData, postData } from "../FetchServices";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function SearchUniversity(props) {
  // console.log(props.location.state.option);
  const classes = useStyles();
  // const [getUniversity,setUniversity]=useState('')
  // const [getCountries,setCountries]=useState('')
  const [getUniversitiesList, setUniversitiesList] = useState([]);
  const [getCountriesList, setCountriesList] = useState([]);
  const [getAbroadCourseList, setAbroadCourseList] = useState([]);
  const [getSearchData, setSearchData] = useState([]);
  const [min,setMin]=useState(1) 
  const [max,setMax]=useState(10)


  const top100Films = [
    { title: "Phd", year: 1994 },
    { title: "MCA", year: 1972 },
    { title: "MSC", year: 1974 },
  ];

  const handleplusten=()=>{
    setMin(min+10)
    setMax(max+10)
    
  }

  const handleminusten=()=>{
    if(min>10){
    setMin(min-10)
    setMax(max-10)
    }   
  }

  const handlestudyAbroadPrograms = async (opt) => {
    switch (opt) {
      case 1:
        // alert('1')
        fetchUniversityAll();
        break;
      case 2:
        // alert('2')
        fetchCountry();
        break;
    }
  };

  useEffect(function () {
    // handlestudyAbroadPrograms(props.location.state.option_var)
    chkOption_var();
    fetchAbroadCourses();
  }, []);

  const chkOption_var = () => {
    // console.log(props.location);
    if (props.location.state) {
      handlestudyAbroadPrograms(props.location.state.option);
    } else {
      fetchUniversityAll();
    }
  };

  const fetchUniversityAll = async () => {
    // alert('list')
    let list = await getData("universities/DisplayAllUniversity");
    console.log("universitiessssss>>>>>>>>>>>>>>",list)
    console.log("universitiessssss>>>>>>>>>>>>>>length",list.length)
    setUniversitiesList(list);
  };

  /*---------------------search function---------------*/
  const handleSearch = async () => {
    let body = { clgname: getSearchData };
    let list = await postData("universities/fetchByClgName", body);
    setUniversitiesList(list);
    console.log(list);
  };

  /*---------show University card Functiom------------- */
  const universitiesItem = () => {
    return getUniversitiesList.map((item,index) => {
      
        
      return (
        index>=min&&index<=max?(
        <div className="col-md-6 col-sm-6 equal-height" style={{ height: 440}}>
          <div className="item">
            <div className="thumb">
              <span
                className="badge"
                style={{
                  backgroundColor: "#3f97f6",
                  position: "absolute",
                  margin: 10,
                  padding: 8,
                  fontSize: 12,
                  borderTopRightRadius: 20,
                  borderBottomRightRadius: 20,
                }}
              >
                <icon class="fas fa-star"></icon> Top 10
              </span>
              {item.image ? (
                <img
                  src={item.image}
                  alt="Thumb"
                  style={{ width: 350, height: 200 }}
                />
              ) : (
                  <img
                    src={"images/noimage.png"}
                    alt="Thumb"
                    style={{ width: 350, height: 200 }}
                  />
                )}
            </div>
            <div className="info">
                <div style={{display:'flex',height:60,justifyContent:'center'}}>
                  <h4 style={{ padding: 10, fontSize: 20, textAlign: "center" }}>
                    <a  onClick={() => handleClickUnivesity(item.id)}>{item.name}</a>
                  </h4>
                </div>
              <div
                className="meta"
                style={{
                  padding: "5px 10px",
                  borderBottom: "none",
                  // height:100,
                  backgroundColor: "white",
                }}
              >
                <ul style={{ marginBottom: 0 }}>
                  <li>
                    <a href="#" style={{ color: "#3f8ccb", fontSize: 16 }}>
                      Estd.
                    </a>
                    <a href="#" style={{ color: "#3f8ccb", fontSize: 16 }}>
                      {item.founded}
                    </a>
                  </li>

                  <li style={{ float: "right" }}>67 Courses</li>
                </ul>
              </div>
              <hr />

              <div
                className="meta"
                style={{
                  padding: "5px 10px",
                  borderBottom: "none",
                  backgroundColor: "white",
                }}
              >
                <ul
                  style={{ marginBottom: 0, textAlign: "center", fontSize: 11 }}
                >
                  <li style={{ textTransform: "none", float: "left" }}>
                    <icon class="fas fa-university fa-2x"></icon>
                    <br />
                    {/* Private University */}
                    {item.institution}
                  </li>
                  <li style={{ textTransform: "none" }}>
                    <icon class="fas fa-money-bill-alt fa-2x"></icon>
                    <br />
                    Rs49,580 Tuition*
                  </li>
                  <li style={{ textTransform: "none", float: "right" }}>
                    <icon class="fas fa-percentage fa-2x"></icon>
                    <br />
                    17.8%
                    <br />
                    Admits
                  </li>
                </ul>
              </div>

              <hr />

              <p
                style={{
                  fontSize: 14,
                  textAlign: "center",
                  paddingBottom: 10,
                  color: "#000",
                }}
              >
                <a href="#">{item.location}</a>
              </p>
            </div>
          </div>
        </div>  
      ):(<div></div>)
      )}
    );
  };
  /*----------------------------------------------------------------------- */

  const fetchCountry = async () => {
    let list = await getData("universities/DisplayAllDefaultCountry");
    setCountriesList(list);
  };

  /*---------show Countries card Functiom------------- */
  const countriesItem = () => {
    return getCountriesList.map((item) => {
      return (
        <div className="col-md-6 col-sm-6 equal-height" style={{ height: 690 }}>
          <div className="item">
            <div className="thumb">
              <span
                className="badge"
                style={{
                  backgroundColor: "#3f97f6",
                  position: "absolute",
                  margin: 10,
                  padding: 8,
                  fontSize: 12,
                  borderTopRightRadius: 20,
                  borderBottomRightRadius: 20,
                }}
              >
                <icon class="fas fa-star"></icon> Top 10
              </span>
              <img src={item.image} alt="Thumb" />
            </div>
            <div className="info">
              <h4 style={{ padding: 10, fontSize: 20, textAlign: "center" }}>
                <a onClick={(event) => handleClickUnivesity(item.id)}>
                  {item.name}
                </a>
              </h4>

              <div
                className="meta"
                style={{
                  padding: "5px 10px",
                  borderBottom: "none",
                  backgroundColor: "white",
                }}
              >
                <ul style={{ marginBottom: 0 }}>
                  <li>
                    <a href="#" style={{ color: "#3f8ccb", fontSize: 16 }}>
                      Estd.
                    </a>
                    <a href="#" style={{ color: "#3f8ccb", fontSize: 16 }}>
                      {item.founded}
                    </a>
                  </li>

                  <li style={{ float: "right" }}>67 Courses</li>
                </ul>
              </div>
              <hr />

              <div
                className="meta"
                style={{
                  padding: "5px 10px",
                  borderBottom: "none",
                  backgroundColor: "white",
                }}
              >
                <ul
                  style={{ marginBottom: 0, textAlign: "center", fontSize: 11 }}
                >
                  <li style={{ textTransform: "none", float: "left" }}>
                    <icon class="fas fa-university fa-2x"></icon>
                    <br />
                    {/* Private University */}
                    {item.institution}
                  </li>
                  <li style={{ textTransform: "none" }}>
                    <icon class="fas fa-money-bill-alt fa-2x"></icon>
                    <br />
                    Rs49,580 Tuition*
                  </li>
                  <li style={{ textTransform: "none", float: "right" }}>
                    <icon class="fas fa-percentage fa-2x"></icon>
                    <br />
                    17.8%
                    <br />
                    Admits
                  </li>
                </ul>
              </div>

              <hr />

              <p
                style={{
                  fontSize: 14,
                  textAlign: "center",
                  paddingBottom: 10,
                  color: "#000",
                }}
              >
                <a href="#">{item.location}</a>
              </p>
            </div>
          </div>
        </div>
      );
    });
  };
  /*-------------pass id as url------------------ */
  const handleClickUnivesity = (id) => {
       props.history.push({
      pathname: "/UniversityDisplay",
        state: {
          id: id,
        }
      });
  };

  /*-------------Filter FUnction----------- */

  const handleClickUniversityType = async (institution) => {
    let body = { institution: institution };
    let list = await postData("universities/fetchByInstitution", body);
    setUniversitiesList(list);
  };

  const handleCountry = async (country) => {
    let body = { country: country };
    let list = await postData("universities/fetchByCountry", body);
    setUniversitiesList(list);
  };

  const fetchAbroadCourses = async () => {
    let list = await getData("abroadcourses/DisplayAllAbroadCourses");
    // console.log(list);
    setAbroadCourseList(list);
  };

  const AbroadCoursesItem = () => {
    return getAbroadCourseList.map((item) => {
      return (
        <li style={{ padding: 10, border: "1px solid #ccc" }}>
          <a onClick={() => handleCourses(item.name)}>{item.name}</a>{" "}
        </li>
      );
    });
  };

  const handleCourses = async (coursename) => {
    // alert(`hello,${coursename}`)
    let body = { programs: coursename };
    let list = await postData("universities/fetchByPrograme", body);
    setUniversitiesList(list);
  };

  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <div className="login-area default-padding" style={{ paddingTop: 20 }}>
        <div className="">
          <div className="row">
            <div className="col-md-12">
              

              <div className="blog-area single full-blog left-sidebar full-blog default-padding">
                <div className="container">
                  <div className="row">
                    <div className="blog-items">
                      <div className="blog-content col-md-8">
                        <div
                          className="event-area flex-less default-padding"
                          style={{ padding: 0 }}
                        >
                          <div className="row">
                            <div className="event-items">
                              {universitiesItem()}
                              {countriesItem()}
                             
                            </div>
                          </div>
                        </div>

                        <div className="row" style={{marginLeft:165}}>
                         
                           <div style={{width:170}}>
                                <a 
                                onClick={()=>handleminusten()}
                                >
                                    <MDBBtn
                                    gradient="blue"                                    
                                    rounded
                                    width='50'
                                    variant="contained"
                                    // onClick={addNewRecord}
                                    color="primary"
                                    className="btn-block z-depth-1a"
                                    // fullWidth
                                  >
                                    Previous
                                  </MDBBtn>
                                  </a>
                                  </div>
                               
                         <div style={{width:170,marginLeft:20}}>
                                <a 
                                onClick={()=>handleplusten()}
                                >
                                    <MDBBtn
                                    gradient="blue"                                    
                                    rounded
                                    
                                    // marginLeft="10"
                                    variant="contained"
                                    // onClick={addNewRecord}
                                    color="primary"
                                    className="btn-block z-depth-1a"
                                    // fullWidth
                                  >
                                    Next
                                  </MDBBtn>
                                  </a>
                         </div>                     
                        </div>

                      </div>
                      <div className="sidebar col-md-4">
                        <aside>
                         
                          <div
                            className="sidebar-item category"
                            style={{ padding: 15 }}
                          >
                            <div className="sidebar-info">
                              <a color="primary" id="togglerU" fullWidth>
                                <i
                                  class="fa fa-university"
                                  style={{ marginRight: 10, color: "#3f8ccb" }}
                                ></i>{" "}
                                UNIVERSITIES{" "}
                                <span style={{ float: "right" }}>
                                  <i class="fa fa-angle-down"></i>
                                </span>
                              </a>
                              <UncontrolledCollapse
                                toggler="#togglerU"
                                defaultOpen={false}
                              >
                                <ul
                                  style={{
                                    margin: 10,
                                    backgroundColor: "#fcfcfc",
                                  }}
                                >
                                  <Grid item xs={12}>
                                    <p
                                      style={{
                                        backgroundColor: "#fcfcfc",
                                        lineHeight: 3,
                                        borderBottom: "1px solid #eee",
                                        marginBottom: 0,
                                      }}
                                    >
                                      <a style={{ margin: 10 }} href="#">
                                        Top <span></span>
                                      </a>
                                    </p>
                                    <p
                                      style={{
                                        backgroundColor: "#fcfcfc",
                                        lineHeight: 3,
                                        borderBottom: "1px solid #eee",
                                        marginBottom: 0,
                                      }}
                                    >
                                      <a style={{ margin: 10 }} href="#">
                                        Popular <span></span>
                                      </a>
                                    </p>
                                  </Grid>
                                </ul>
                              </UncontrolledCollapse>
                            </div>
                          </div>
                          <div
                            className="sidebar-item category"
                            style={{ padding: 15 }}
                          >
                            <div className="sidebar-info">
                              <a color="primary" id="togglerUT" fullWidth>
                                <i
                                  class="fa fa-university"
                                  style={{ marginRight: 10, color: "#3f8ccb" }}
                                ></i>{" "}
                                UNIVERSITY TYPE{" "}
                                <span style={{ float: "right" }}>
                                  <i class="fa fa-angle-down"></i>
                                </span>
                              </a>
                              <UncontrolledCollapse toggler="#togglerUT">
                                <ul
                                  style={{
                                    margin: 10,
                                    backgroundColor: "#fcfcfc",
                                  }}
                                >
                                  <Grid item xs={12}>
                                    <p
                                      style={{
                                        backgroundColor: "#fcfcfc",
                                        lineHeight: 3,
                                        borderBottom: "1px solid #eee",
                                        marginBottom: 0,
                                      }}
                                    >
                                      <a
                                        style={{ margin: 10 }}
                                        href="#"
                                        onClick={() =>
                                          handleClickUniversityType("private")
                                        }
                                      >
                                        Private <span></span>
                                      </a>
                                    </p>
                                    <p
                                      style={{
                                        backgroundColor: "#fcfcfc",
                                        lineHeight: 3,
                                        borderBottom: "1px solid #eee",
                                        marginBottom: 0,
                                      }}
                                    >
                                      <a
                                        style={{ margin: 10 }}
                                        href="#"
                                        onClick={() =>
                                          handleClickUniversityType("public")
                                        }
                                      >
                                        Public <span></span>
                                      </a>
                                    </p>
                                  </Grid>
                                </ul>
                              </UncontrolledCollapse>
                            </div>
                          </div>

                          <div
                            className="sidebar-item category"
                            style={{ padding: 15 }}
                          >
                            <div className="sidebar-info">
                              <a color="primary" id="toggler" fullWidth>
                                <i
                                  class="fa fa-flag"
                                  style={{ marginRight: 10, color: "#3f8ccb" }}
                                ></i>{" "}
                                COUNTRY{" "}
                                <span style={{ float: "right" }}>
                                  <i class="fa fa-angle-down"></i>
                                </span>
                              </a>
                              <UncontrolledCollapse toggler="#toggler">
                                <ul
                                  style={{
                                    margin: 10,
                                    backgroundColor: "#fcfcfc",
                                  }}
                                >
                                  <Grid item xs={12}>
                                    <p
                                      style={{
                                        marginBottom: 0,
                                        marginLeft: 20,
                                      }}
                                    >
                                      <a onClick={() => handleCountry("USA")}>
                                        <i class="flag-icon flag-icon-de"></i>{" "}
                                        USA
                                      </a>
                                    </p>
                                    {/* <p style={{ marginBottom: 0, marginLeft: 20, }}><a href="" onClick={()=>handleCountry('')}><i class="flag-icon flag-icon-de"></i> Germany</a></p> */}
                                    <p
                                      style={{
                                        marginBottom: 0,
                                        marginLeft: 20,
                                      }}
                                    >
                                      <a
                                        onClick={() => handleCountry("Canada")}
                                      >
                                        <i class="flag-icon flag-icon-ca"></i>{" "}
                                        Canada
                                      </a>
                                    </p>
                                    <p
                                      style={{
                                        marginBottom: 0,
                                        marginLeft: 20,
                                      }}
                                    >
                                      <a
                                        onClick={() =>
                                          handleCountry("Australia")
                                        }
                                      >
                                        <i class="flag-icon flag-icon-au"></i>{" "}
                                        Australia
                                      </a>
                                    </p>
                                    {/* <p style={{ marginBottom: 0, marginLeft: 20, }}><a href="" onClick={()=>handleCountry('')}><i class="flag-icon flag-icon-sg"></i> Singapore</a></p> */}
                                    <p
                                      style={{
                                        marginBottom: 0,
                                        marginLeft: 20,
                                      }}
                                    >
                                      <a
                                        onClick={() =>
                                          handleCountry("New Zealand")
                                        }
                                      >
                                        <i class="flag-icon flag-icon-nz"></i>{" "}
                                        New Zealand
                                      </a>
                                    </p>
                                    <p
                                      style={{
                                        marginBottom: 0,
                                        marginLeft: 20,
                                      }}
                                    >
                                      <a
                                        onClick={() => handleCountry("France")}
                                      >
                                        <i class="flag-icon flag-icon-fr"></i>{" "}
                                        France
                                      </a>
                                    </p>
                                    {/* <p style={{ marginBottom: 0, marginLeft: 20, }}><a href="" onClick={()=>handleCountry('')}><i class="flag-icon flag-icon-hk"></i> Hong Kong</a></p>
                                    <p style={{ marginBottom: 0, marginLeft: 20, }}><a href="" onClick={()=>handleCountry('')}><i class="flag-icon flag-icon-jp"></i> Japan</a></p> */}
                                    <p
                                      style={{
                                        marginBottom: 0,
                                        marginLeft: 20,
                                      }}
                                    >
                                      <a onClick={() => handleCountry("UK")}>
                                        <i class="flag-icon flag-icon-gb"></i>{" "}
                                        United Kingdom
                                      </a>
                                    </p>
                                    {/* <p style={{ marginBottom: 0, marginLeft: 20, }}><a href="" onClick={()=>handleCountry('')}><i class="flag-icon flag-icon-nl"></i> Netherlands</a></p>
                                    <p style={{ marginBottom: 0, marginLeft: 20, }}><a href="" onClick={()=>handleCountry('')}><i class="flag-icon flag-icon-dk"></i> Denmark</a></p> */}
                                    <p
                                      style={{
                                        marginBottom: 0,
                                        marginLeft: 20,
                                      }}
                                    >
                                      <a
                                        onClick={() => handleCountry("Ireland")}
                                      >
                                        <i class="flag-icon flag-icon-ie"></i>{" "}
                                        Ireland
                                      </a>
                                    </p>
                                    {/* <p style={{ marginBottom: 0, marginLeft: 20, }}><a href="" onClick={()=>handleCountry('')}><i class="flag-icon flag-icon-se"></i> Sweden</a></p> */}
                                    <p
                                      style={{
                                        marginBottom: 0,
                                        marginLeft: 20,
                                      }}
                                    >
                                      <a onClick={() => handleCountry("Asia")}>
                                        <i class="flag-icon flag-icon-ch"></i>{" "}
                                        Asia
                                      </a>
                                    </p>
                                    <p
                                      style={{
                                        marginBottom: 0,
                                        marginLeft: 20,
                                      }}
                                    >
                                      <a onClick={() => handleCountry("China")}>
                                        <i class="flag-icon flag-icon-ch"></i>{" "}
                                        China
                                      </a>
                                    </p>
                                    <p
                                      style={{
                                        marginBottom: 0,
                                        marginLeft: 20,
                                      }}
                                    >
                                      <a
                                        onClick={() => handleCountry("Medical")}
                                      >
                                        <i class="flag-icon flag-icon-ch"></i>{" "}
                                        Medical
                                      </a>
                                    </p>
                                    <p
                                      style={{
                                        marginBottom: 0,
                                        marginLeft: 20,
                                      }}
                                    >
                                      <a
                                        onClick={() => handleCountry("Europe")}
                                      >
                                        <i class="flag-icon flag-icon-ch"></i>{" "}
                                        Europe
                                      </a>
                                    </p>
                                  </Grid>
                                </ul>
                              </UncontrolledCollapse>
                            </div>
                          </div>

                          <div
                            className="sidebar-item category"
                            style={{ padding: 15 }}
                          >
                            <div className="sidebar-info">
                              <a color="primary" id="togglerC" fullWidth>
                                <i
                                  class="fas fa-book-open"
                                  style={{ marginRight: 10, color: "#3f8ccb" }}
                                ></i>{" "}
                                COURSES{" "}
                                <span style={{ float: "right" }}>
                                  <i class="fa fa-angle-down"></i>
                                </span>
                              </a>
                              <UncontrolledCollapse toggler="#togglerC">
                                <ul
                                  style={{
                                    margin: 10,
                                    backgroundColor: "#fcfcfc",
                                  }}
                                >
                                  <Grid item xs={12}>
                                    <div class="container my-4">
                                      <input
                                        class="form-control"
                                        style={{
                                          borderRadius: 5,
                                          marginTop: 10,
                                          marginBottom: 10,
                                        }}
                                        id="listSearch"
                                        type="text"
                                        placeholder="Search.."
                                      />
                                      <br />
                                      <div
                                        style={{
                                          height: 346,
                                          overflowY: "auto",
                                        }}
                                      >
                                        <ul class="list-group" id="myList">
                                          {AbroadCoursesItem()}
                                        </ul>
                                      </div>
                                    </div>
                                    
                                  </Grid>
                                </ul>
                              </UncontrolledCollapse>
                            </div>
                          </div>
                        </aside>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
