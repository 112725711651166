/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import Header from './Header';
import Footer from './Footer';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";


const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function Gallery(props) {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [messages, setMessages] = React.useState("");
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus, setStatus] = React.useState("");

  console.log("History ==  ", props);
  return (
    <>
      <Container className={classes.widths}>
      <Header/> <CssBaseline />
        <div className="login-area default-padding" style={{ paddingTop: 0 }}>
          <div className="">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                  style={{
                    backgroundImage:
                      "url(" + "../assets/img/banner/21.jpg" + ")",
                    padding: 70,
                  }}
                >
                  <div className="container">
                    <div className="row">
                      <div className="double-items col-md-12 info">
                        <h2 className="wow fadeInLeft">Gallery</h2>
                      </div>
                    </div>
                  </div>
                </div> 

                <div id="portfolio" className="portfolio-area default-padding">
                  <div className="container">
                    <div className="portfolio-items-area text-center">
                      <div className="row">
                        <div className="col-md-12 portfolio-content">
                         
                          <div className="magnific-mix-gallery masonary text-light">
                            <div
                              id="portfolio-grid"
                              className="portfolio-items "
                            >
                               
<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/first.jpg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/first.jpg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Chaitanya Lok_16.04.2020_Indore_P05.jpeg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Chaitanya Lok_16.04.2020_Indore_P05.jpeg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Chautha Sansar_16.04.2020_Indore_P02.jpeg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Chautha Sansar_16.04.2020_Indore_P02.jpeg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Chautha Sansar_18.04.2020_Indore_P02_.jpeg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Chautha Sansar_18.04.2020_Indore_P02_.jpeg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Chautha Sansar_23.04.20_Indore_Pg02.jpeg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Chautha Sansar_23.04.20_Indore_Pg02.jpeg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Chautha Sansar_Indore_19.06.2020_P02.jpeg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Chautha Sansar_Indore_19.06.2020_P02.jpeg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Dabang Dunia_17.04.2020_Indore_P04_.jpeg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Dabang Dunia_17.04.2020_Indore_P04_.jpeg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Dabang Dunia_18.04.2020_Indore_P02_.jpeg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Dabang Dunia_18.04.2020_Indore_P02_.jpeg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Dabang Dunia_Indore_11.12.19_P06.jpg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Dabang Dunia_Indore_11.12.19_P06.jpg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Dabang Dunia_Indore_19.06.2020_P03.jpeg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Dabang Dunia_Indore_19.06.2020_P03.jpeg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Indore Samachar_16.04.2020_Indore_P09.jpeg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Indore Samachar_16.04.2020_Indore_P09.jpeg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Indore Samachar_19.04.2020_Indore_P09_.jpg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Indore Samachar_19.04.2020_Indore_P09_.jpg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Indore Samachar_23.04.2020_Indore_P09.jpeg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Indore Samachar_23.04.2020_Indore_P09.jpeg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Indore Samachar_Indore_19.06.2020_P05.jpeg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Indore Samachar_Indore_19.06.2020_P05.jpeg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Nav Bharat_17.04.2020_Indore_P03_.jpeg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Nav Bharat_17.04.2020_Indore_P03_.jpeg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Nav Bharat_18.04.2020_Indore_P03_.jpeg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Nav Bharat_18.04.2020_Indore_P03_.jpeg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Nav Bharat_18.04.2020_Indore_P08_.jpeg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Nav Bharat_18.04.2020_Indore_P08_.jpeg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Nav Bharat_22.04.2020_Indore_P03.jpeg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Nav Bharat_22.04.2020_Indore_P03.jpeg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Nav Bharat_Indore_07.12.19_P05.jpg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Nav Bharat_Indore_07.12.19_P05.jpg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Nav Bharat_Indore_19.06.2020_P03.jpeg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Nav Bharat_Indore_19.06.2020_P03.jpeg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/News Paper 1.jpg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/News Paper 1.jpg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/News Paper 2.jpg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/News Paper 2.jpg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/News Paper 3.jpg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/News Paper 3.jpg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Patrika_17.04.2020_Indore_P08.jpeg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Patrika_17.04.2020_Indore_P08.jpeg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Patrika_19.04.2020_Indore_P09_.jpg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Patrika_19.04.2020_Indore_P09_.jpg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>


<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Patrika_Indore_19.06.2020_P08.jpeg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Patrika_Indore_19.06.2020_P08.jpeg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Raj Exp._18.04.2020_Indore_P02_.jpeg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Raj Exp._18.04.2020_Indore_P02_.jpeg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Raj Express_Indore_19.06.2020_P02.jpeg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Raj Express_Indore_19.06.2020_P02.jpeg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>


<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Swadesh_24.04.2020_Indore_P02_.jpeg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Swadesh_24.04.2020_Indore_P02_.jpeg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Swadesh_Indore_07.12.19_P04.jpg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Swadesh_Indore_07.12.19_P04.jpg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Swadesh_Indore_13.12.19_P04.jpg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Swadesh_Indore_13.12.19_P04.jpg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>

<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Swadesh_Indore_19.06.2020_P06.jpeg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Swadesh_Indore_19.06.2020_P06.jpeg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>


<div className="pf-item srm students">
<div className="item-effect">
<img src="assets/img/press/Times Of India_Indore_13.12.19_P03.jpg" alt="thumb"/>
<div className="overlay">
<a href="assets/img/press/Times Of India_Indore_13.12.19_P03.jpg" className="item popup-link"><i className="fa fa-plus"></i></a>
</div>
</div>
</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </Container>
    </>
  );
}
