/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { getData } from "../FetchServices";
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#e92645 none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#e92645",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function Header(props) {
  const history = useHistory();
  const classes = useStyles();
  const [technology, setTechnology] = React.useState([]);

  const readAllTechnology = async () => {
    let result = await getData("technology/displayall");
    // console.log(result);
    setTechnology(result);
  };

  const handlelogout= async()=>{
    try {
      localStorage.removeItem('student')
      history.replace({pathname: '/'})
    } catch (error) {
      history.replace({pathname: '/'})
    }
  }

  // React.useEffect(() => {
  //   readAllTechnology();
  // }, []);

  return (
    <>
      <div
        className="fun-factor-area  slider-less text-light bg-fixed shadow dark-hard"
        style={{
          backgroundImage: "url(" + "../assets/img/banner/15.jpg" + ")"
        }}
      >
        <div
          className="top-bar-area bgdark text-light"
          style={{ backgroundColor: "#3f8ccb", color: "white", fontSize: 13 }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-11 address-info text-left">
                <div className="info">
                  <ul style={{ marginBottom: 0 }}>
                    <li>
                      <i className="fas fa-graduation-cap"></i>
                      <strong>Study Metro</strong>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-1 link text-right">
                <ul style={{ marginBottom: 0, textAlign: "center" }}>
                  <li>
                  {localStorage.getItem("student")?<></>:<a href="https://partner.studymetro.com/s/login/" target="_blank">
                  Login
                </a>}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <header id="home">
          <nav
            className="navbar navbar-default attr-border navbar-sticky bootsnav"
            style={{ padding: 0 }}
          >
            <div className="container">
              <div className="row"></div>
            </div>

            <div className="container">
              <div className="navbar-header">
                <button
                  type="button"
                  class="navbar-toggle"
                  data-toggle="collapse"
                  data-target="#navbar-menu"
                >
                  <i class="fa fa-bars"></i>
                </button>
                <a class="navbar-brand" href="/">
                  <img src="assets/img/logo.png" class="logo" alt="Logo" />
                </a>
                {/* {Object.keys(JSON.parse(localStorage.getItem("student"))).length>0?<></>:<a class="call-support show-mobile" href="/Login">
                  Login
                </a>} */}
                
              </div>

              <div className="collapse navbar-collapse" id="navbar-menu">
               

                <ul
                  className="nav navbar-nav navbar-right"
                  style={{ display: "block" }}
                  data-in="#"
                  data-out="#"
                >
                  <li className="dropdown">
                    <a href="/" className="dropdown">
                      Home
                    </a>
                  </li>
                  


                  <li class="dropdown">
                    <a href="/SearchUniversity" class="dropdown-toggle" data-toggle="dropdown">
                      University & Program
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <a target="_blank" href="https://partner.studymetro.com/s/account/Account/00B2x000007f0clEAA">University Finder</a>
                      </li>
                      <li>
                        <a target="_blank" href="https://partner.studymetro.com/s/course-session/Course_Session__c/00B2x000006IXEjEAO">Course Finder</a>
                      </li>
                      <li>
                        <a href="/CProgram">Compare Program</a>
                      </li>
                      <li>
                        <a href="/Compare">Compare Universities</a>
                      </li>

                    </ul>
                  </li>

                  
                  <li class="dropdown">
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                      Student
                    </a>
                    <ul class="dropdown-menu">
                    <li>
                        <a href="/ProfileBuilder">Profile Builder</a>
                      </li>
                      <li>
                        <a href="/Application">Application</a>                        
                      </li>
                      <li>
                        <a target="_blank" href="https://partner.studymetro.com/s/group/CollaborationGroup/00B2x000005ovOvEAI">Study Abroad Group</a>                        
                      </li>
                      <li>
                        <a target="_blank" href="https://partner.studymetro.com/s/discussion-forum">Discussions</a>                        
                      </li>
                      <li>
                        <a target="_blank" href="https://partner.studymetro.com/s/topiccatalog">Topics</a>                        
                      </li>
                    </ul>
                  </li>
                


                  <li class="dropdown">
                    <a target="_blank" href="https://partner.studymetro.com/s/account/Account/00B2x000007f0clEAA?Account-filterId=00B2x000007egRfEAI">
                      Scholarships
                    </a>
                  </li>
                  

                  {/* <li className="dropdown">
                    <a
                      href="/Scholarship"
                      class="dropdown-toggle"
                      className="dropdown"
                    >
                      Scholarships
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <a href="#">Country</a>
                      </li>
                      <li>
                        <a href="/Scholarship" className="dropdown">
                          Scholarships
                        </a>
                      </li>
                    </ul>
                  </li> */}
                  <li class="dropdown">
                    <a  href="#" class="dropdown-toggle" data-toggle="dropdown">
                      Test Preparation
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <a target="_blank" href="https://studymetro.org/ieltstraining1">IELTS</a>
                      </li>
                      <li>
                        <a href="/TOEFL">TOEFL</a>
                      </li>
                      <li>
                        <a href="/PTE">PTE</a>
                      </li>
                      <li>
                        <a href="/Duolingo">Duolingo</a>
                      </li>
                      <li>
                        <a href="/GRE">GRE</a>
                      </li>
                      <li>
                        <a href="/GMAT">GMAT</a>
                      </li>
                      <li>
                        <a href="/SAT">SAT</a>
                      </li>
                      <li>
                        <a href="/ACT">ACT</a>
                      </li>
                      <li>
                        <a href="/PersonalOnlineMentor">
                          Personal Online Mentor
                        </a>
                      </li>
                    </ul>
                  </li>
                  {/* {localStorage.getItem("student")?<></>:(
                  <li className="dropdown">
                    <a href="/ProfileBuilder" className="dropdown">
                      Profile 
                    </a>
                  </li>
                  )} */}
                  

                  {localStorage.getItem("student")?<li class="dropdown">
                    <a href="#"  data-toggle="dropdown">
                    <i class="far fa-user-circle fa-2x"></i>
                    </a>
                    <ul class="dropdown-menu">
                      

                      <li>
                       <a href="/UserDetails" target="_blank">User Details</a>
                        
                      </li>
                      <li>
                        <a onClick={handlelogout}>Logout</a>
                        
                      </li>

                      
                    </ul>
                  </li>:<></>}

                  


                </ul>
              </div>
            </div>
          </nav>
        </header>{" "}
        <CssBaseline />
          <iframe src="https://studymetro.co.in/sm-widget.html" width="100%" height="250px" frameBorder="0"></iframe>
      </div>
    </>
  );
}
