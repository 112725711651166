import React from 'react';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

import ProjectRouter from './component/ProjectRouter';


function App() {
  return (
    <div>
      {/* <Main/> */}
 <ProjectRouter/>     
 
    </div>
  );
}

export default App;
