/* eslint-disable no-useless-concat */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment, useState,useEffect } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import { UncontrolledCollapse, CardBody, Card } from "reactstrap";
import Checkbox from "@material-ui/core/Checkbox";
import Header from "./Header";
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

import {
  postDataAndImageLocal,
  getData,
  postDataLocal,
} from "../FetchServicesLocal";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },

  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function SimilarProfile(props) {
  const classes = useStyles();
  const [ownData,setOwnData]=useState([]);
  const [otherProfiles,setOtherProfiles]=useState([]);

  const fetchOwnData=async(ownid)=>{
    var body={sid:ownid}
    var list = await postDataLocal("profilebuilder/displayinfopb",body);
    fetchOtherProfiles(list.data[0])
  }

  const fetchOtherProfiles=async(a)=>{
    var body={city:a.city,preferred_country:a.preferred_country,preferred_Program:a.preferred_Program}
    var list = await postDataLocal("profilebuilder/fetchsimilarprofiles",body);
    setOtherProfiles(list.data)
  }

  const localStorageData = async () => {
    try {
      let data = await localStorage.getItem("student");
    if(data){
      let result = JSON.parse(data);
      // setstudent_Uni_ID(result.student_ID);
      fetchOwnData(result.student_ID)
    } else{
      props.history.replace({pathname:'/Login'})
    }
    } catch (error) {
      props.history.replace({pathname:'/Login'})
    }
    
    
  };

  // const fillSimilarProfiles=


  useEffect(function(){
    // localStorageData();

  },[])

  return (
    <Container className={classes.widths}>
      <Header /> <CssBaseline />
      {/*
      <div
        className="top-bar-area bgdark text-light"
        style={{ backgroundColor: "#3f8ccb", color: "white", fontSize: 13 }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-11 address-info text-left">
              <div className="info">
                <ul style={{ marginBottom: 0 }}>
                  <li>
                    <i className="fas fa-graduation-cap"></i>
                    <strong>Study Metro</strong>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-1 link text-right">
                <ul style={{ marginBottom: 0, textAlign: "center" }}>
                  <li>
                  {localStorage.getItem("student")?<></>:<a href="/Login">
                  Login
                </a>}
                  </li>
                </ul>
              </div>
          </div>
        </div>
      </div>
      <header id="home">
        <nav className="navbar navbar-default attr-border navbar-sticky bootsnav">
          <div className="container">
            <div className="row"></div>
          </div>

          <div className="container">
            <div className="navbar-header">
              <button
                type="button"
                class="navbar-toggle"
                data-toggle="collapse"
                data-target="#navbar-menu"
              >
                <i class="fa fa-bars"></i>
              </button>
              <a class="navbar-brand" href="/">
                <img src="assets/img/logo.png" class="logo" alt="Logo" />
              </a>

              <a class="call-support show-mobile" href="/Login">
                Login
              </a>
            </div>

            <div className="collapse navbar-collapse" id="navbar-menu">
              <ul
                className="nav navbar-nav navbar-right"
                style={{ display: "block" }}
                data-in="#"
                data-out="#"
              >
                <li className="dropdown">
                  <a href="/" className="dropdown">
                    Home
                  </a>
                </li>
                <li className="dropdown">
                  <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Services
                  </a>
                  <ul className="dropdown-menu">
                    <li className="dropdown">
                      <a
                        href="#"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        Student
                      </a>
                      <ul className="dropdown-menu">
                        <li className="dropdown">
                          <a href="/CreateAccount">Create Account</a>
                        </li>
                        <li className="dropdown">
                          <a href="#">Application</a>
                        </li>
                        <li className="dropdown">
                          <a href="/ProfileBuilder">Profile Builder</a>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown">
                      <a
                        href="#"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        Pages
                      </a>
                      <ul className="dropdown-menu">
                        <li className="dropdown">
                          <a href="/WhyChooses">Why Choose Us</a>
                        </li>
                        <li className="dropdown">
                          <a href="/NewPaper">NewPaper</a>
                        </li>
                        <li className="dropdown">
                          <a href="/PressMedia">PressMedia</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li class="dropdown">
                  <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                    {" "}
                    Universities
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a href="/SearchUniversity">University Finder</a>
                    </li>
                    <li>
                      <a href="/SearchCourse">Course Finder</a>
                    </li>
                    <li>
                      <a href="/Compare">Compare Universities</a>
                    </li>
                    <li>
                      <a href="/Scholarship">Scholarships</a>
                    </li>
                    <li>
                      <a href="/SearchUniversity">View All Universities</a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown">
                  <a href="/Webinar" className="dropdown">
                    {" "}
                    Webinars
                  </a>{" "}
                </li>
                <li className="dropdown">
                  <a href="/Blog" className="dropdown">
                    {" "}
                    Blogs
                  </a>
                </li>
                <li className="dropdown">
                  <a href="/aboutus" className="dropdown">
                    {" "}
                    About Us
                  </a>
                </li>
                <li className="dropdown">
                  <a href="/contact-us" className="dropdown">
                    {" "}
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>{" "}  
      <CssBaseline />*/}
      <div className="login-area default-padding" style={{ paddingTop: 20 }}>
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/6.jpg" + ")",
                  padding: 70,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <div class="col-lg-12">
                        <h2
                          className="wow fadeInLeft"
                          style={{ textAlign: "center" }}
                        >
                          Similar Profiles
                        </h2>
                        <h4
                          className="wow fadeInLeft"
                          style={{ textAlign: "center" }}
                        >
                          Connect with students having similar profiles
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="blog-area single full-blog left-sidebar full-blog default-padding">
                <div className="container">
                  <div className="row">
                    <div className="blog-items">
                      <div className="blog-content">
                        <div
                          className="event-area default-padding"
                          style={{ padding: 0 }}
                        >
                          <div className="container">
                            <div className="row">
                            {otherProfiles.map((item)=>(
                        <div className="col-md-6">
                                <div className="event-items">
                                  <div
                                    className="item"
                                    style={{ marginBottom: 10 }}
                                  >
                                    <div className="col-md-12">
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} sm={2}>
                                          <icon
                                            class="fa fa-user"
                                            style={{
                                              color: "black",
                                              fontSize: "3rem",
                                              marginTop: 10,
                                            }}
                                          ></icon>
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                          <div
                                            className="content"
                                            style={{
                                              width: "-webkit-fill-available",
                                            }}
                                          >
                                            <h4>
                                              {" "}
                                              <a
                                                href="#"
                                                style={{
                                                  color: "#008000",
                                                  fontSize: 12,
                                                }}
                                              >
                                               
                                              </a>
                                            </h4>
                                            <h4>
                                              <a href="#">{item.username}</a>
                                            </h4>
                                          </div>
                                        </Grid>
                                        <Grid item xs={12} sm={2}></Grid>
                                      </Grid>
                                      <div>
                                        <div
                                          className="content"
                                          style={{
                                            width: "-webkit-fill-available",
                                          }}
                                        >
                                          <hr />
                                          <MDBRow>
                                            <MDBCol>
                                              <strong>Current City</strong> <br />
                                              {item.city}
                                            </MDBCol>
                                            <MDBCol>
                                              <strong>Preferred Country</strong> <br />
                                              {item.preferred_country}
                                            </MDBCol>
                                            <MDBCol>
                                              <strong>Preferred Program</strong> <br />
                                              {item.preferred_Program}
                                            </MDBCol>
                                          </MDBRow>
                                          {/* <hr /> */}
                                          <p
                                            style={{
                                              fontSize: 14,
                                              textAlign: "center",
                                              paddingBottom: 0,
                                              color: "rgb(0, 0, 0)",
                                            }}
                                          >
                                            {/* <a href="/ApplyScholarship">
                                              View scholarship and apply
                                            </a> */}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                              
                            </div>
                          </div>
                        </div>

                        {/* <div className="row">
                            <div className="pagi-area">
                                <nav aria-label="navigation">
                                    <ul className="pagination">
                                        <li><a href="#"><i className="fas fa-angle-double-left"></i></a></li>
                                        <li className="active"><a href="#">1</a></li>
                                        <li><a href="#">2</a></li>
                                        <li><a href="#">3</a></li>
                                        <li><a href="#"><i className="fas fa-angle-double-right"></i></a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
