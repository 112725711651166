/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
  nestedList:{
      listStyleType:'disc !important',
      paddingLeft:'1em'
  }
}));

export default function LoanDocuments(props) {
  const classes = useStyles();

//   console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 25,
                  marginTop: 30,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">Loan Documents</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="blog-area single full-blog left-sidebar full-blog default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="container"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <div className="row">
                    <div className="blog-items" style={{ width: "100%" }}>
                      <div className="blog-content col-md-12">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      //   marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                   
                                    <p style={{ color: "#212121" }}>
                                      <h6>General Eligibility</h6>
                                      It is important for students to understand the eligibility guidelines and qualify the same, 
                                      to be able to apply to their preferred money lending institution/bank for their Education Loan.
                                       The eligibility criteria vary for different banks. However, some common factors that are taken
                                        into account by all money lending institutions (banks) include:
                                    </p>
                                   
                                    <p style={{ color: "#212121" }}>
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; The student is required to be a citizen of India.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; The loan applicant must have attained the age of 18 else his/her parents will have to avail the loan.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;The candidate must possess a good academic background.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; The aspirant must have secured admission to a recognized foreign university/institution/college.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; The desired course which the applicant is going to undertake must be a technical or professional one, 
                                          as banks give preference to job oriented courses.
                                        </li>
                                      </ul>{" "}
                                    </p>

                                    <br />
{/* Documents Required   */}
                                        <p style={{ color: "#212121" }}>
                                      <h6>Documents Required</h6>
                                      While the formalities vary for different banks while applying for an Education Loan, however, 
                                      there are a set of formalities that are identical across the board, such as documents that you
                                       would require to disclose along with your Loan Application Form. The documents primarily include
                                        but not limited to:
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                    For Student-applicants:
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Proof of Identity (anyone): PAN/ Passport/ Driver’s License/ Voter ID card.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Proof of Residence/ Address (anyone): Recent copy of Telephone Bill/ Electricity 
                                          Bill/Water Bill/ Piped Gas Bill or copy of Passport/ Driving License/ Aadhaar Card.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Passport.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Academic Records: 10th Result and 12th Result.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Graduation Result: Semester-wise (if applicable).
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Entrance Exam Result through which admission has been secured (GMAT, GRE, TOEFL or IELTS).
                                        </li>

                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Proof of admission: Offer Letter or Admission Letter from the Institution. Conditional admission letters may be considered in case of studies abroad.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Statement of cost of study/ Schedule of expenses.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; 2 passport-size photographs.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; If any previous loan from other Banks/Lenders, then Loan A/C statement for last 1 year.
                                        </li>
                                      </ul>{" "}
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                    For Co-applicants:
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Proof of Identity (anyone): PAN/ Passport/ Driver’s License/ Voter ID card.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Proof of Residence/ Address (anyone): Recent copy of Telephone Bill/ Electricity
                                           Bill/Water Bill/ Piped Gas Bill or copy of Passport/ Driving License/ Aadhaar Card.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;2 passport-size photographs.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; If any previous loan from other Banks/Lenders, then Loan A/C statement for last 1 year.
                                        </li>
                                      </ul>{" "}
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                    For Income Proof for Salaried Co-applicant/ Guarantor:
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Salary Slip or Salary Certificate of last 3 months.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Copy of Form 16 for last 2 years or a copy of IT Returns for 
                                          the last 2 financial years, acknowledged by IT Dept.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Bank account statement for the last 6 months (of Salary Account).
                                        </li>
                                       
                                      </ul>{" "}
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                    For Income Proof for Self-employed Co-applicant/ Guarantor:
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Business address proof (If applicable).
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; IT returns for last 2 years (if IT payee).
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;TDS Certificate (Form 16A, if applicable).
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Certificate of qualification (for C.A. / Doctor and other professionals).
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Bank account statement for last 6 months.
                                        </li>
                                       
                                      </ul>{" "}
                                    </p>
                                    <br/>
{/*  */}
                                    <p style={{ color: "#212121" }}>
                                      <h6>What all documents required for applying for Education Loan for Study Abroad?</h6>
                                      Before applying for an education loan, the applicant must be completely aware of the documents
                                       required to apply for a study loan for abroad. Following documents are required to apply for an 
                                       education loan for abroad studies:
                                    </p>
                                 
                                    <p style={{ color: "#212121" }}>
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Filled application form.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Photographs: Passport size photographs of the applicant and the co-applicant.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Photo ID: Photo ID of the applicant and the co-applicant. It can be a PAN card, 
                                          driving license, Voter ID card, Aadhar Card, or Passport.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Residence proof: Resident proof of the applicant and the co-applicant.
                                        </li>
                                        <li>
                                         <ul> <i class="fa fa-check-circle"></i>
                                          &nbsp;Academic documents:
                                              <li className="pl-3">
                                             1. Mark sheet and certificates of the applicant.
                                              </li>
                                              <li className="pl-3">
                                             2. Mark sheet (Score Report) of GRE, GMAT, TOEFL, IELTS, etc. whichever is applicable
                                              </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Admission proof: Admission letter shared by the university or college.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Bank statements: Last six months bank statements of the co-applicant.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Income proof: Income proof of the co-applicant.
                                        </li>
                                        <li>
                                         <ul> <i class="fa fa-check-circle"></i>
                                          &nbsp;In case of collateral (immovable property), it can be flat, home, or non-agriculture 
                                          land the following documents are required:
                                              <li className="pl-3">
                                             1. Property title deed.
                                              </li>
                                              <li className="pl-3">
                                             2. Building approved plan.
                                              </li>
                                              <li className="pl-3">
                                             3. NOC for a mortgage from builder or society.
                                              </li>
                                          </ul>
                                        </li>
                                       
                                      </ul>{" "}
                                    </p>
                                    <br/>

                                    <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr>
                                            <th scope="col">Document type</th>
                                            <th scope="col">Applicant</th>
                                            <th scope="col">Co-applicant</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <th scope="row">Date of Birth</th>
                                            <td colspan="2">Birth Certificate, Passport, Voter card with DOB, 
                                            College Passing Certificate, PAN Card, Driving license, Aadhaar Card</td>
                                            </tr>
                                            <tr>
                                            <th scope="row">Residence Proof (if owned)</th>
                                            <td colspan="2">Electricity Bill, Municipal Tax Receipt, Share Certificate 
                                            or Title Deed (with Flat No)</td>
                                            </tr>
                                            <tr>
                                            <th scope="row">Residence Proof (for a rented property)</th>
                                            <td colspan="2">Registered Rent agreement (with utility bill), Landline phone bill,
                                             Post Paid Mobile Bill, Bank statement., Passport, Driving License, Voter ID, and 
                                             Aadhaar card</td>
                                            </tr>
                                            <tr>
                                            <th scope="row">Aadhaar</th>
                                            <td colspan="2">Aadhaar card</td>
                                            </tr>
                                            <tr>
                                            <th scope="row">PAN Card</th>
                                            <td colspan="2">Copy of PAN / Form 60 if PAN not available</td>
                                            </tr>
                                            <tr>
                                            <th scope="row">Signature</th>
                                            <td colspan="2">Signature verification from the bank, Passport, Driving License,
                                             and PAN Card (all IDs should match with your current signature)</td>
                                            </tr>
                                            <tr>
                                            <th scope="row">Relationship proof</th>
                                            <td colspan="2">Passport, Pan, Aadhaar card, Marriage certificate, Birth certificate, 
                                            Legal heir certificate, Ration card or any other acceptable documents</td>
                                            </tr>
                                            <tr>
                                            <th scope="row">Academic documents</th>
                                            <td>10th,12th, UG or PG mark sheets, degree or provisional degree certificate
                                                 and applicable entrance test scores</td>
                                            <td>Registration certificates for Professionals (CA, Doctor)</td>
                                            </tr>
                                            <tr>
                                            <th scope="row">Income proof - Salaried</th>
                                            <td >Documents to establish 3 years work experience where ever applicable and available</td>
                                            <td>
                                                <p>Latest  3 salary slips, Bank Statement of last 3 months, Form 16,</p>
                                                <p style={{ color: "#212121" }}>
                                                Self-employed:
                                                </p>
                                                <p>2 years ITR with the statement of income, Income certificate from Tehsil/collector's office</p>
                                            </td>
                                            </tr>
                                            <tr>
                                            <th scope="row">Office Address</th>
                                            <td>NA</td>
                                            <td>Form 16/ Salary slip/ Letter from HR/ Snapshot of site/Identity card</td>
                                            </tr>
                                            <tr>
                                            <th scope="row">Admission proof</th>
                                            <td>Invite/admission letter from University/college and Fee structure</td>
                                            <td>NA</td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </div>

                                    <p style={{ color: "#212121" }}>
                                      <h6> Technical Documentation</h6>
                                      Apart from the above-mentioned documents, aspirants also need to have some other technical
                                       documents to avail a student loan.
                                    </p>
                                 
                                    <p style={{ color: "#212121" }}>
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Title deed (all the pages to be attached).
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Copy of approved layout plan and permissions case specific.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Revenue document (Khata / Patta).
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; BDA ALLOTMENT -NOC AND ALLOTMENT LETTER.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Non Encumbrance certificate.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Possession Certificate (In case the flat is taken from a builder).
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Latest Property tax receipt
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Copy of Prior sale deeds.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Conversion certificate.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Urban clearance certificate (Case-specific).
                                        </li>
                                       
                                      </ul>{" "}
                                    </p>
                                    <br/>

                                    <p style={{ color: "#212121" }}>
                                      <h6> Legal documentation</h6>
                                      In addition to the standard and technical documents, aspirants also need to have a list of
                                       legal documents for getting an education loan.
                                    </p>
                                 
                                    <p style={{ color: "#212121" }}>
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Sale /gift/partition deed in favour of customer – Minimum 13 years.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Khata Certificate and extract in the name of the current owner.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Encumbrance certificate – Minimum 13 years reflecting all sale transactions.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Latest Property tax receipt.
                                        </li>
                                      </ul>{" "}
                                    </p>
                                    <br/>
                                    <h6> NOTE: These requirements can vary as per the banks’ own regulations.</h6>
                                    <br />
                                    
                                
                                  </div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div className="col-md-12">
                                    <div
                                      className="content"
                                      style={{ paddingBottom: 0 }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <Footer />
    </Container>
  );
}
