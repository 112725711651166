/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import mobiscroll from '@mobiscroll/react-lite';
import '@mobiscroll/react-lite/dist/css/mobiscroll.min.css';
import Header from './Header';
import Footer from './Footer';


import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';
  





const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
input:{
  padding:'0px 0px',
},
  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:16,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },
widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#002147',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function SearchByCheckBox(props) {
  const classes = useStyles();
  const [firstName,setFirstName]=React.useState('')
  const [lastName,setLastName]=React.useState('')
  const [emailId, setEmailId]=React.useState('')
  const [password,setPassword]=React.useState('')
  const [verifyPassword,setVerifyPassword]=React.useState('')
  const [messages, setMessages ]=React.useState('')
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus,setStatus]=React.useState('') 

  const handlePicture=(event)=>{
    setPicturePath(URL.createObjectURL(event.target.files[0]))
    setPicture(event.target.files[0])
   }

   const handleVerifyPassword=(e)=>{
    setVerifyPassword(e.target.value)
    if(password===e.target.value){
      setStatus("Password Matched")
    }
    else{
      setStatus("Password not matched")
    }
  }


  const addNewRecord=async()=>{
if(picture=='')
{
    var body={'firstName':firstName,
    'lastName':lastName, 
    'emailId':emailId, 
    'password':password,
    'status':"Not Approved",
    'picture':"Not Found"}
    var result=await postData('selfregister/addNewRecord',body)
    if(result){
      await setMessages("Agent Succefully Created")

      var body={
        'emailid':emailId,
        'password':password
       }
var resultt = await  postData('selfregister/checkagentsignin',body)

if (resultt.RESULT)
{

localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
}
else
{
alert("Failed To Created Account")
}

await setFirstName('')
await setLastName('')
await setEmailId('')
await setPassword('')
await setVerifyPassword('')
await setPicturePath('')
}
else{setMessages("Faild to Submit")}

}

else{
   var formData=new FormData()
   formData.append('firstName',firstName)
   formData.append('lastName',lastName)
   formData.append('emailId',emailId)
   formData.append('password',password)
   formData.append('picture',picture)
   formData.append('status', "Not Approve")
  
   var config={headers:{'content-type':'multipart/form-data'}}  
    var result=await postDataAndImage('selfregister/addNewRecordPic',formData,config)


    if(result){
      await setMessages("Agent Succefully Created")

      var body={
        'emailid':emailId,
        'password':password
       }
var resultt = await  postData('selfregister/checkagentsignin',body)

if (resultt.RESULT)
{

localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
}
else
{
alert("Failed To Created Account")
}

await setFirstName('')
await setLastName('')
await setEmailId('')
await setPassword('')
await setVerifyPassword('')
await setPicturePath('')
}
else{setMessages("Faild to Submit")}
    }
    }
   
   
    console.log("History ==  ",props)
  

  return (
   <Container className={classes.widths}>
   <Header/>
    <div className="login-area default-padding" style={{backgroundColor:'#FFF'}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">
                <div className="col-md-6 col-md-offset-1">
                <img style={{flex:1,marginTop:80,}} class="login_image" src="assets/img/search.png" alt="Login" data-original-title="" title="" />

                </div>
                <div className="col-md-5">
                  
                <Container>
      
      <CssBaseline />
      <MDBContainer>
      <MDBRow>
        <MDBCol md="12">
          <MDBCard>
            <MDBCardBody>
              <MDBCardHeader className="form-header deep-blue-gradient rounded" style={{color: "white", textAlign:"center", padding: '2px 2px',}}>
                <h3 className="my-3">
                  <MDBIcon icon="about" /> Filter By Country
                </h3>
              </MDBCardHeader>
              <div className={classes.paper}>
       
        <form className={classes.form} noValidate>
          <Grid container spacing={2} style={{display:'block'}}>
      
<mobiscroll.Form theme="ios"  >         
<mobiscroll.FormGroupTitle>Country checkbox</mobiscroll.FormGroupTitle>
<mobiscroll.Checkbox checked >USA</mobiscroll.Checkbox>
<mobiscroll.Checkbox check="false" >CANADA</mobiscroll.Checkbox>
<mobiscroll.Checkbox check="false" >Austalia</mobiscroll.Checkbox>
<mobiscroll.Checkbox check="false" >NewZealand</mobiscroll.Checkbox>
<mobiscroll.Checkbox check="false" >Asia</mobiscroll.Checkbox>
<mobiscroll.Checkbox check="false" >Europe</mobiscroll.Checkbox>                  
<mobiscroll.Checkbox check="false" >China</mobiscroll.Checkbox>
<mobiscroll.Checkbox check="false" >France</mobiscroll.Checkbox>
<mobiscroll.Checkbox check="false" >Ireland</mobiscroll.Checkbox>
<mobiscroll.Checkbox check="false" >Medical</mobiscroll.Checkbox>
<mobiscroll.Checkbox check="false" >UK</mobiscroll.Checkbox>        
</mobiscroll.Form>
</Grid>

          <div className="text-center mt-3">
        <MDBBtn gradient="blue" rounded variant="contained"  onClick={addNewRecord}color="primary" className="btn-block z-depth-1a" fullWidth>
        Submit
        </MDBBtn>
       </div>
        <Typography>
        {messages}
        </Typography>
 
        <br/>
         


        </form>
      </div>
             
            </MDBCardBody>
          </MDBCard>
       </MDBCol>
       </MDBRow>
    </MDBContainer>
      
     
    </Container>

                </div>

</div>
</div>
</div>
</div>
<Footer/>
    </Container>
  );
}

   
