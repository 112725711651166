/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
import React, { Fragment,} from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Header from './Header';
import Footer from './Footer';


import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';



const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
newbtn:{
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
},

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:12,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    // margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },

widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const [firstName,setFirstName]=React.useState('')
  const [lastName,setLastName]=React.useState('')
  const [emailId, setEmailId]=React.useState('')
  const [password,setPassword]=React.useState('')
  const [verifyPassword,setVerifyPassword]=React.useState('')
  const [messages, setMessages ]=React.useState('')
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus,setStatus]=React.useState('') 

  const handlePicture=(event)=>{
    setPicturePath(URL.createObjectURL(event.target.files[0]))
    setPicture(event.target.files[0])
   }

   const handleVerifyPassword=(e)=>{
    setVerifyPassword(e.target.value)
    if(password===e.target.value){
      setStatus("Password Matched")
    }
    else{
      setStatus("Password not matched")
    }
  }


  // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
  const top100Films = [
    { title: 'Phd', year: 1994 },
    { title: 'MCA', year: 1972 },
    { title: 'MSC', year: 1974 },
  ];
   
    console.log("History ==  ",props)
  return (
   <Container className={classes.widths}>
  <Header/><CssBaseline />
    <div className="login-area default-padding" style={{paddingTop:20,}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">


    <div className="about-area default-padding">
        <div className="container">
            <div className="row">
                <div className="about-items">

 <div className="col-md-3 features text-light">
                    <div className="sidebar-item category" style={{padding:15,}}>
      <div className="sidebar-info" >  
     <a color="primary" id="togglerU"  style={{color:'#000',fontWeight:'bold'}}  fullWidth>                        
       Inquiry Form <span style={{float:'right'}}><i class="fa fa-envelope"></i></span>
    </a> 
       
       </div>  
       <hr/>  
       <p style={{fontSize:12,color:'#212121',textAlign:'justify',lineHeight:'inherit'}}>
       Our education counselors and campus teams help you every step of the way, from application to graduation, at no additional cost to you.
    </p>  
<div className="blog-area">
<div className="info">
<div className="content" style={{paddingBottom:0,}} style={{padding:0,position:'static'}}>
<a href="https://calendly.com/studymetro/students" style={{color:'#000',borderRadius:5,textAlign:'center'}}> Talk to an Advisor</a>
</div>
</div>
</div>  

</div>

  <div className="sidebar-item category" style={{padding:15,}}>
   <div className="sidebar-info" >  
     <a color="primary" id="togglerU"  style={{color:'#000',fontWeight:'bold'}}  fullWidth>                        
       Videos <span style={{float:'right'}}><i class="fa fa-video-camera"></i></span>
    </a> 
  </div>  
       <hr/>  
<p style={{fontSize:12,color:'#212121',textAlign:'justify',lineHeight:'inherit'}}>
 Our education counselors and campus teams help you every step of the way, from application to graduation, at no additional cost to you.
</p>  
<div className="">
    <a className="videoOverlapper" href="https://youtube.com/embed/c_newv2i3r0" data-featherlight="iframe">
        <i style={{position:'absolute',padding:90,fontSize:40,}} class="fa fa-play-circle"></i>
        <img src="https://www.studymetro.com/static/uploads/videos/150169728359821503548a8.jpg" />
            </a>
            </div>

</div>
<div className="sidebar-item category" style={{padding:15,}}>
   <div className="sidebar-info" >  
     <a color="primary" id="togglerU"  style={{color:'#000',fontWeight:'bold'}}  fullWidth>                        
       Event <span style={{float:'right'}}><i class="fa fa-flag"></i></span>
    </a> 
  </div>  
       <hr/>  

</div>

</div>

<div className="col-md-9 about-info">
<div style={{backgroundColor:'#f3f3f3',display:'flex',alignItems:'center'}}>
<h4>Deadlines should not hamper your studies! Study abroad experience, visa aid.</h4>
<br />
</div><br />



<p style={{ color: "#212121" }}>
                                    Top study abroad consultant in India- various branches accessible for students' guidance, rolling admissions help applicants get higher degree US/Europe/Canada
<br/>
<br/>
Study Metro with successful gateway to career, helps in finding undergrad & graduate degrees study abroad with all parameters fitted in place. Gradual movement we accept, advise and appreciate for potential applicants. Visa guidance is like nowhere else.
<br/>
<br/>
Your future education starts here! Study Metro makes applying for the program or course of your choice easy. We guide you through the entire process whether it is university, college, language study or high school. Studying abroad may be that defining moment in your education that will change your life.
<br/>
<br/>
Nothing will be quite the same after you have studied abroad. Your perspectives will be global, your attitudes will be international and you will have memories that you will carry forever. Your resume will be more attractive, in some cases your language proficiency will be advanced, and you will have developed lifelong friendships.
<br/>
<br/>
We are team of informed and committed senior education advisors/counselors who understand the need of the students and their parents or sponsors. We firmly believe in customer service to the students and our client institutions. Our strongly student centered approach to counseling mean, we give students the fullest possible information on the all available options to help them make sensible decisions.
<br/>
<br/>
The advantages of overseas education are very high nowadays, since it facilitates better exposure in a wider horizon. Obtaining a globally accepted degree from abroad allows people to travel and live anywhere in the world with highflying careers, a truly cosmopolitan experience.
<br/>
<br/>
Study Metro provides overall support from pre-application to immigration processes and we offers a time-tested and cost effective service package for students to ensure successful admission.
<br/>
<br/>
Foreign Universities offer Undergraduate programs as Bachelor degree, Bachelor Degree with Honors, Undergraduate Diplomas and Associate Diplomas. After graduation there are Graduate Programs or Postgraduate programs as, Masters Degrees, Doctoral Degrees, Graduate Diplomas and Graduate Certificates.
<br/>
<br/>
The most popular courses of study for international students have been in the fields of Business, Administration and Economics, followed by Science, then Arts, Humanities, Social Sciences, Aeronautical Engineering, Automotive Engineering, Civil Engineering, Electrical Engineering, Mechanical Engineering, Computer Engineering, Mining Engineering and Petrochemical Engineering. The wide range of study fields offered in foreign universities includes.
<br/>
<br/>
Studying Abroad" has always been allured the aspiring students who actually want to give their career a radical move. Such decision not only opens thousands avenues for higher education but makes an individual capable of facing and resolving the global issues; builds a lifetime commitment to the self-esteem, self-determination and social values. However, the main benefits of studying abroad can be summarized as follows:
<br/>
<br/>
1. Growth: Students learn a lot more about themselves in that one semester than they did in the three and a half years in their home school because of the unique space in which they learn experience and spend exploring another culture. Study abroad leads to long-lasting friendships with other students and impacts current relationships.
<br/>
<br/>
2. Develop skills and gain experience a classroom setting will never provide: Being immersed in an entirely new cultural setting is exciting. It's an opportunity to discover new strengths and abilities, conquer new challenges, and solve new problems. You will encounter situations that are wholly unfamiliar to you and will learn to adapt and respond in effective ways.
<br/>
<br/>
3. The opportunity to break out of your academic routine: You can become familiar with an entirely new academic system and you will have the chance to take courses not offered on your home campus. It's also a great opportunity to break out the monotony of the routine you follow semester after semester.
<br/>
<br/>
4. Expand your worldview: Students normally tend to be uninformed about the world beyond the nation's boundaries. Students who study abroad return home with an informed and much less biased perspective toward other cultures and peoples.
<br/>
<br/>
5. Enhance the value of your degree: While abroad, you can take courses you would never have had the opportunity to take on your home campus. In addition, study abroad gives your language skills such a boost that it is normally quite easy to add a minor in a language or even a second major without having to take many more additional courses after the return to your home campus.
<br/>
<br/>
6. Learn about yourself: The experience abroad often challenges students to reconsider their own beliefs and values. The experience may perhaps strengthen those values or it may cause students to alter or abandon them and embrace new concepts and perceptions. The encounter with other cultures enables students to see their own culture through new eyes.
<br/>
<br/>
7. Enhance employment opportunities: Through an employer's eyes, a student who has studied abroad is self-motivated, independent, willing to embrace challenges, and able to cope with diverse problems and situations. Your experience living and studying in a foreign country, adjusting to another culture, and acquiring a foreign language will all set you apart from the majority of other job applicants.
<br/>
<br/>
8. A Lifetime of benefits: With study abroad offering so many life-changing and enduring academic, career, intercultural, personal, and social benefits, students should carefully consider studying abroad when searching for a college/ university. In addition, colleges, parents, and employers should encourage and enable students to study abroad.
<br/>
<br/>
9. Education and career attainment: When questioned about academic pursuits, 87 percent of respondents said that study abroad influenced subsequent educational experiences, 63 percent said that it influenced their decision to expand or change academic majors, and 64 percent reported that it influenced their decision to attend graduate school. Nearly half of all respondents have engaged in international work or volunteerism since studying abroad.
<br/>
<br/>
10. Longer stay - greater benefits: Conventional wisdom in the study abroad field has been that "more is better"â€”the longer students study abroad, the more significant the academic and cultural development and personal growth benefits. Students will be successful in producing important academic, inter- and intra-personal, career, and intercultural development outcomes.
<br/>
<br/>
</p>     
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>
</div>
</div>
</div>

<div style={{marginLeft:250}}>
  <img src="./images/schob2.jpg" style={{width:900}} />
</div>

<div style={{display:'flex',flexDirection:'row',border:'1px solid #0082c3',backgroundColor:'#fff',borderRadius:15,height:500,width:1100,marginLeft:150,marginBottom:20}}>

                                    <div style={{width:500}}>
                                        <img src='./images/scho1.jpg' style={{height:498,borderRadius:15|0|0|15}} />
                                    </div>
                                
                                    <div style={{display:'flex',width:600,justifyContent:'center',flexDirection:'column'}}>
                                    
                                             <div style={{display:'flex',flexDirection:'column',marginBottom:40,justifyContent:'center'}}>
                                                  <h2><span style={{fontFamily:'cursive',fontWeight:'bold',justifyContent:'center',display:'flex'}}>Top Programs in Abroad!</span></h2>                                                              
                                                  <span  style={{display:'flex',marginLeft:4,fontWeight:'bold',fontSize:23,marginTop:20}}>&#x2022;  Free Education in Germany</span>
                                                  <span  style={{display:'flex',marginLeft:4,fontWeight:'bold',fontSize:23,marginTop:4}}>&#x2022; Paid Internship in France</span> 
                                                  <span  style={{display:'flex',marginLeft:4,fontWeight:'bold',fontSize:23,marginTop:4}}>&#x2022; SVP Visa process in Australia</span>
                                                  <span  style={{display:'flex',marginLeft:4,fontWeight:'bold',fontSize:23,marginTop:4}}>&#x2022; Study in New Zealand With Paid Internship</span>
                                                  <span  style={{display:'flex',marginLeft:4,fontWeight:'bold',fontSize:23,marginTop:4}}>&#x2022; Study and Work Singapore</span>  
                                                  <span  style={{display:'flex',marginLeft:4,fontWeight:'bold',fontSize:23,marginTop:4}}>&#x2022; Work and Study program in USA</span>  
                                                  <span  style={{display:'flex',marginLeft:4,fontWeight:'bold',fontSize:23,marginTop:4}}>&#x2022; Co-Op Program in Canada with Paid Internship</span>  
                                                  <span  style={{display:'flex',marginLeft:4,fontWeight:'bold',fontSize:23,marginTop:4}}>&#x2022; Study-Work-Settle in Ireland</span>  
                                                  <span  style={{display:'flex',marginLeft:4,fontWeight:'bold',fontSize:23,marginTop:4}}>&#x2022; Study in Malaysia at Indian Cost</span>  
                                                  <div style={{width:300,marginTop:30,marginLeft:130,display:'flex',justifyContent:'center'}}>
                                                    <a href='/scholarship'>
                                                      <MDBBtn
                                                      gradient="blue"                                    
                                                      rounded
                                                      variant="contained"
                                                      // onClick={addNewRecord}
                                                      color="primary"
                                                      className="btn-block z-depth-1a"
                                                      // fullWidth
                                                    >
                                                      Apply For Scholarship
                                                    </MDBBtn>
                                                    </a>
                                                  </div>
                                             </div>
                                            
                                             
                                       
                                                                    
                                    </div>
                               </div>
<Footer/>
    </Container>
  );


}
