/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import icon from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Header from './Header';
import Footer from './Footer';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';


const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
newbtn:{
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
},

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:12,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },

widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function Compare(props) {
  const classes = useStyles();
 

  return (
   <Container className={classes.widths}>
   <Header/>  <CssBaseline />
    <div className="login-area default-padding" style={{paddingTop:20,}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">
<div className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard" style={{backgroundImage: "url(" + "../assets/img/banner/6.jpg" + ")",padding:70}}>             

        <div className="container">
            <div className="row">
            <div className="double-items col-md-12 info">
                <h2 className="wow fadeInLeft">Europe</h2>
                </div>
             
            </div>
        </div>
    </div>


    <section class="video_sec blog_wrap">
<div class="container">
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <div class="blog_box faq">
                <h2>Frequently Asked Questions?</h2>
                <div class=" blog_box_content">
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is there a deadline for applying?</h3>
                        <p style={{color:'#333'}}>A: Yes, there is deadline for every intake.  The deadline is 2 months before starting intake date.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: IS GRE/GMAT or IELTS/TOEFL is required for admission?</h3>
                        <p style={{color:'#333'}}>A: No.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much Time it will take to get the offer letter?</h3>
                        <p style={{color:'#333'}}>A: It takes around 1 weeks to get the offer letter.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I work on campus?</h3>
                        <p style={{color:'#333'}}>A: You can work on campus or off-campus as a part-time during your academic session.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long will it take for me to obtain my internship employment?</h3>
                        <p style={{color:'#333'}}>A: Students can start working once you reach over their.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What type of employment will I get initially?</h3>
                        <p style={{color:'#333'}}>A: That depends upon your skills and experience. But it is always better to work in the field in which you are studying there to gain beneficial experience.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Must I find an internship job only in the field related to my major or is any job O.K.?</h3>
                        <p style={{color:'#333'}}>A: No that is not mandatory but this is preferred to take internship in the course related to your studies.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How many working hours will I probably work each week?</h3>
                        <p style={{color:'#333'}}>A: During the studies you can work only as a part-time and after completion of your studies you will get 1 year of work permit.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I have to work overtime? Can I keep the overtime bonus?</h3>
                        <p style={{color:'#333'}}>A: You can't work overtime during your academic session. But you can work in the duration of your summer vacation.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What if I can't find employment?</h3>
                        <p style={{color:'#333'}}>A: You will get 1 year of job search stay back to search the job in any European countries.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Will I have to pay taxes?</h3>
                        <p style={{color:'#333'}}>A: That depends upon how much you will earn over there.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is there a way for me to work before actually starting to attend classes in order for me to save more money and add it to my current savings?</h3>
                        <p style={{color:'#333'}}>A: No, there is no way to work before you start the study program.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Could the work authorization be possibly converted into a full time work permit upon completion of the masters program?</h3>
                        <p style={{color:'#333'}}>A: Yes, once you complete your studies you can apply for work permit their.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much is the bank statement required for applying VISA?</h3>
                        <p style={{color:'#333'}}>A: You need to show bank funds equivalent to Indian rupees  Rs  3.5 Lakh.  One day bank statement can be accepted for admission.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q:  How long I have to keep amount in my bank account for VISA?</h3>
                        <p style={{color:'#333'}}>A: You need to keep that amount into your bank until you reach their. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much tuition fee I have to pay before and after getting VISA/Offer letter?</h3>
                        <p style={{color:'#333'}}>A: After getting the offer letter you need to pay 1st year tuition fee. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can one apply for Dependent VISA?</h3>
                        <p style={{color:'#333'}}>A: Yes.  But only after completion of 4-6 months of applicants academic studies.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How can apply as a dependent VISA?</h3>
                        <p style={{color:'#333'}}>A: The student can go there and then send a request for their dependents visa or dependent can apply for their home country.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is required in order to bring my spouse and family?</h3>
                        <p style={{color:'#333'}}>A: You need to have a proof of relationship and sufficient funds to sponsor them.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my spouse get the wok permit there?</h3>
                        <p style={{color:'#333'}}>A: No.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much duration work permit will spouse get there?</h3>
                        <p style={{color:'#333'}}>A: Dependents are not allowed to work in Latvia.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I apply work permit after completing my studies?</h3>
                        <p style={{color:'#333'}}>A: Yes, you will get around 1 year of stay back option to search for job and apply for work permit.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much time will I get after my studies to search job there?</h3>
                        <p style={{color:'#333'}}>A: You will get around 1 year to search for a job there.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my spouse extend the work permit?</h3>
                        <p style={{color:'#333'}}>A: Dependents are not allowed to work in Latvia.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my accompanying dependent attend school?</h3>
                        <p style={{color:'#333'}}>A: Yes.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I work on campus?</h3>
                        <p style={{color:'#333'}}>A: Yes.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Will University secure job for me?</h3>
                        <p style={{color:'#333'}}>A: They will assist you for job but they don’t guarantee the job.  Somewhat, it dependents upon your skills and experience.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Why Should I study in Latvia?</h3>
                        <p style={{color:'#333'}}>A: If you apply for Latvia then you will get schengen visa with which you can travel to any of the 25 European countries and do internship during your summer vacation.  Also, you can do part-time job during your academic session and after completion of your studies you will get 1 year stay back option.
Latvia has very affordable in terms of University tuition fee and living expenses.  
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is there a deadline for applying?</h3>
                        <p style={{color:'#333'}}>A: Yes, there is deadline for every intake.  </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: IS GRE/GMAT or IELTS/TOEFL is required for admission?</h3>
                        <p style={{color:'#333'}}>A: No.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much Time it will take to get the offer letter?</h3>
                        <p style={{color:'#333'}}>A: It takes around 15 days to get the offer letter.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I work on campus?</h3>
                        <p style={{color:'#333'}}>A: No Part time jobs allowed as the leaving expenses is on 8,000 INR to 10,000 INR</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long will it take for me to obtain my internship employment?</h3>
                        <p style={{color:'#333'}}>A: Mostly Internship will be in last semester’s. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much is the bank statement required for applying VISA?</h3>
                        <p style={{color:'#333'}}>A: You need to show bank funds equivalent to Indian rupees  Rs  4 Lakh.  </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much tuition fee I have to pay before and after getting VISA/Offer letter?</h3>
                        <p style={{color:'#333'}}>A: After getting the offer letter you need to pay 1st year tuition fee. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can one apply for Dependent VISA?</h3>
                        <p style={{color:'#333'}}>A: Yes.  But only after completion of 4-6 months of applicants academic studies.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How can apply as a dependent VISA?</h3>
                        <p style={{color:'#333'}}>A: The student can go there and then send a request for their dependents visa or dependent can apply for their home country.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is required in order to bring my spouse and family?</h3>
                        <p style={{color:'#333'}}>A: You need to have a proof of relationship and sufficient funds to sponsor them.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my spouse get the wok permit there?</h3>
                        <p style={{color:'#333'}}>A: No.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I apply work permit after completing my studies?</h3>
                        <p style={{color:'#333'}}>A:  Yes, you will get around 1 year of stay back option to search for job and apply for work permit. Around 3000 Companies from Europe come there for Recruitments.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my accompanying dependent attend school?</h3>
                        <p style={{color:'#333'}}>A: Yes.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: WHY STUDY IN LITHUANIA?</h3>
                        <p style={{color:'#333'}}>A: 1.	High educational standards and Quality.
2.	 Inspiring and comfortable learning environment.
3.	 Internationally valuable and recognized Certifications
4. Wide choice of degree programs.
5. Tuition fees and living expenses are reasonable.
6. Accommodation availability
7. Mobility opportunities.
8. Rich culture.
9. Welcoming people.
10. Lithuania has the fastest broadband in the whole Europe!
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q:  Where is Ukraine located?</h3>
                        <p style={{color:'#333'}}>A: Ukraine is the second largest country and situated in the south-eastern part of Europe .

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the Capital of Ukraine and its population?</h3>
                        <p style={{color:'#333'}}>A: Kiev is the Capital of Ukraine, which has an overall population of 50 million (UN, 2004 data).</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is one of the major religion followed by the people in Ukraine?</h3>
                        <p style={{color:'#333'}}>A: Christianity.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Are these Universities recognized or affiliated by any board of education or any other Educational Council?</h3>
                        <p style={{color:'#333'}}>A: Yes, they are recognized by the Ministry of Higher Education of Ukraine.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the duration of the program in Ukraine?</h3>
                        <p style={{color:'#333'}}>A: The duration varies between 2 - 6 years depending upon the courses of study.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the currency used in Ukraine?</h3>
                        <p style={{color:'#333'}}>A: The currency used in Ukraine is Hryvnia and the coins are known as Kopiyka. US Dollars are also acceptable. US$ 1 = 5.2 Hryvnia approximately (Please check current FOREX rates for latest update).</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the language used in Ukraine?</h3>
                        <p style={{color:'#333'}}>A: The language is Ukrainian, Widely used: Russian, English, French and German languages.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the medium of instruction in Ukraine?</h3>
                        <p style={{color:'#333'}}>A: The medium of instruction is "ENGLISH".</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do we need to qualify any English test like IELTS or TOEFL for seeking admission into Ukrainian University?</h3>
                        <p style={{color:'#333'}}>A: No, it is not mandatory, but there are educational support programs available for those who need extra help in areas such as ESL or who have learning disorders.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can we apply for the Work Permit after completion of the program?</h3>
                        <p style={{color:'#333'}}>A: Yes, you can apply for the Work Permit. Universities itself provides job placement in Ukraine and other western countries.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Does the student have to come back to his/her home country to apply?</h3>
                        <p style={{color:'#333'}}>A: No, it is not necessary.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can we apply for Permanent Residence and citizenship of Ukraine?</h3>
                        <p style={{color:'#333'}}>A: Yes, you can apply but after 2 years of regular stay in Ukraine as per Ukrainian Law.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Are Ukrainian degrees valued globally?</h3>
                        <p style={{color:'#333'}}>A: Yes, they are valued and recognised globally.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How is the accommodation arrangement and what are the charges?</h3>
                        <p style={{color:'#333'}}>A: The University Hostels are available for accommodation and it is included in the fee package.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can we pay the tuition fee in installments?</h3>
                        <p style={{color:'#333'}}>A: Yes, you can pay the fee in installments.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q:  Do we need to pay the books and exam fee separately or is it included in the fee?</h3>
                        <p style={{color:'#333'}}>A: It is included in the Tuition fee.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can we travel to any other countries while studying in Ukraine? Which countries, when and for how long?</h3>
                        <p style={{color:'#333'}}>A: Yes, you can travel to other countries like UK, Germany, Sweden, Italy, France, Poland, etc. during summer and winter vacations.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: In which languages do the teachers teach?</h3>
                        <p style={{color:'#333'}}>A: Studies are provided in Ukrainian, Russian, or in English language for International students.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the visa invitation letter for education?</h3>
                        <p style={{color:'#333'}}>A: The invitation letter for education is the official document, which is processed by the department of passport-visa service of the Ministry of Internal Affairs of Ukraine. It confirms that the student is admitted to a particular University. It specifies your passport with details and the full details of the University. This invitation support letter is required by the Ukrainian Embassy to stamp the visa on your passport. Generally, it takes around 2-4 days for the visa invitation to be processed at the Ministry of Internal Affairs. You need to apply to the Ukrainian Embassy for a visa during the validity of the invitation issued.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the migration card?</h3>
                        <p style={{color:'#333'}}>A: The migration card is the document which testifies that the student has crossed the border of Ukraine. It is an obligatory document for the further processing of the student registration and hence is an important document. Without the migration card the registration on the territory of Ukraine cannot be done and the student would be deported back to the home country. The migration card is handed out by the flight attendant on the plane or is also available at the airport on arrival at the immigration department. The card consists of 2 identical parts, which are necessary to fill. At the immigration of the International airport, the immigration officer stamps the both parts of the card and retains one while the other part is handed over to the student. Before leaving the immigration desk, it is important that you check that you have received the stamped part from the officer. It is important and obligatory to retain the migration card till the departure from Ukraine.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Will I have to incur some additional expenses upon arrival in Ukraine?</h3>
                        <p style={{color:'#333'}}>A: Upon arrival, the student should pay to the University the officially established charges for the registration, which would be around US$ 10-30. In some cases the University would require the student to be re-examined for HIV or medical fitness which would cost an additional US$ 10. Validity of the HIV certificate would be 3 months.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What will be my average month expenses?</h3>
                        <p style={{color:'#333'}}>A: Usually the expenses for first month of staying in Ukraine are more than those of the subsequent months. You will need about 400 USD for the first three months of staying in Ukraine. This is due to the reason that the student would need to pay the registration charges, purchase items of necessity etc. Subsequently, the average monthly expenses for food are about US$ 100-US$ 150.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How do my parents can send me money for my expenses?</h3>
                        <p style={{color:'#333'}}>A: Parents can send the money to the student bank account in Ukraine by the way of a wire transfer. The student is recommended to open a bank account as soon as the process of registration is completed, which normally takes around 8-14 days. After the registration is completed the student is required to come to the office of "Study in Ukraine", where one of the representatives would assist him / her to open a bank account where the parents could transfer the money. For opening a bank account, the student would be required to deposit an initial deposit of US$ 5.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Whether a health insurance are necessary?</h3>
                        <p style={{color:'#333'}}>A: Before the arrival of the student to Ukraine, "Study In Ukraine" processes the health insurance on the behalf of the student. Within several days of the arrival to Ukraine, the insurance certificate is given to the student. The health insurance guarantees that the student gets the required medical aid in times of illness according to the program of the insurance cover. The insurance policy mentions the names and the telephone numbers of the doctors and the hospitals. The insurance company also informs StudyInUkraine.org of the illness of the student who apply for the medical help. StudyInUkraine.org therefore is in a position not only to monitor and supervise the condition of the student’s health but also to inform the parents of the student and keeping them informed about the progress of the student’s health. Without the health insurance it is not possible to process the registration of the student. Therefore, the health insurance is necessary and obligatory for all foreign students wishing to study in Ukraine.</p>
                    </div>
                                   
                </div>
                
            </div>

        </div>
    </div>

</div>

</section>



    <div className="advisor-details-area default-padding" style={{padding:0,}}>
   
   </div>



</div>
</div>
</div>
</div>

<Footer/>
    </Container>
  );
}
