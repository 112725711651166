// const BaseUrl = "http://campusshala.com:3009";
// import BaseUrl from './BaseUrl';
// const BaseUrl = "http://localhost:3009";
// const BaseUrl = "https://isef-online.com:3009";
const BaseUrl = "https://studymetro.com:3009";

const axios = require("axios");

const token = async () => {
  try {
    var url = `${BaseUrl}/authentication`;
    var config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const response = await axios.post(url, {}, config);
    var result = response.data;
    return result.access_token;
  } catch (error) {
    // console.log({error: error.response.status});
    return {status: false};
  }
};

const getData = async (url) => {
  try {
    var response = await fetch(`${BaseUrl}/${url}`, {
      method: "GET",
      mode: "cors",
      headers: { "content-type": "application/json;charset=utf-8",Authorization:`Bearer ${await token()}` },
    });
    var result = await response.json();
    return result;
  } catch (e) {
    return false;
  }
};

const postData = async (url, body) => {
  var response = await fetch(`${BaseUrl}/${url}`, {
    method: "post",
    mode: "cors",
    body: JSON.stringify(body),
    headers: { "content-type": "application/json;charset=utf-8",Authorization:`Bearer ${await token()}` },
  });
  var result = await response.json();
  console.log(result);
  return result;
};

const postDataAndImage = async (url, formData) => {
  try {
    const config = {
      headers: {
        'Content-type': 'multipart/form-data',
        Authorization: `Bearer ${await token()}`,
      },
    };
    const response = await axios.post(`${BaseUrl}/${url}`, formData, config);
    var result = response.data[0].RESULT;
    return result;
  } catch (e) {
    return false;
  }
};


const postDataCompleteURL = async (url, formData) => {
  try {
    const response = await axios.post(`${url}`, formData);
    var result = response.data;
    return result;
  } catch (e) {
    return false;
  }

  // try {
  //   var xhr = new XMLHttpRequest();
  //   xhr.withCredentials = false;

  //   xhr.addEventListener("readystatechange", function () {
  //     if (this.readyState === 4) {
  //       return this.responseText
  //     }
  //   });
  //   xhr.open("POST", url);
  //   xhr.send(formData);

  //   return xhr
  // } catch (e) {
  //   return false;
  // }
};

export { getData, postDataAndImage, postData, postDataCompleteURL };
