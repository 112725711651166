/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function Duolingo(props) {
  const classes = useStyles();

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 25,
                  marginTop: 30,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">Duolingo</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="blog-area single full-blog left-sidebar full-blog default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="container"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <div className="row">
                    <div className="blog-items" style={{ width: "100%" }}>
                      <div className="blog-content col-md-12">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      //   marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      The Duolingo English Exam is an Internet
                                      examination that applicants May take on
                                      their private computers. Nominees will
                                      need to establish English language
                                      proficiency may provide the Duolingo
                                      English Exam that may be obtained online
                                      from anyplace. In this informative
                                      article, we'll notify you of what about
                                      the Duolingo evaluation and if you need to
                                      take it or not.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      The test is adaptive, so the problem level
                                      of this Test increases with each correct
                                      answer and the next question gets simpler
                                      if your response isn't right. Even though
                                      the test interval is just about an hour
                                      but just with couple of queries it could
                                      examine the ability of the test taker
                                      quickly.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      Candidates may take the test in their
                                      residence and no Appointment necessary for
                                      taking the exam, as applicants may take it
                                      anytime anyplace, according to their
                                      convenience. Additionally, it has an
                                      updated section where applicants may have
                                      a video interview about 10 minutes and
                                      then record their own answers to
                                      open-ended inquiries.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      In this period of COVID-19 catastrophe
                                      when pupils Aren't capable To provide the
                                      English Language Proficiency examinations
                                      from the evaluation center, Duolingo
                                      provides them the chance to try the test
                                      out of their houses. Although not all of
                                      the schools are accepting the Duolingo
                                      evaluation scores.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      Presently, the Duolingo examination is
                                      used for entrance IELTS and TOEFL
                                      examinations are approved by thousands of
                                      apps. Additionally, together with
                                      Duolingo, an individual could send scores
                                      to as many educational institutions as you
                                      needs free of charge, whereas, with IELTS
                                      and TOEFL examinations one needs to cover
                                      a $20 fee for sending score reports into
                                      some educational institution other than
                                      that which they're permitted in the time
                                      of enrollment.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      You may take the test on the web anywhere,
                                      anytime. Nominees Need not traveling to a
                                      test centre for trying this test.
                                      Candidates may take the test in their
                                      residence according to their advantage,
                                      they simply require some essential
                                      equipment to provide it.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      As candidates will probably be taking the
                                      test in their residence, Duolingo ensures
                                      that applicants follow the strict
                                      principles it had put in position,
                                      therefore, you take the exam with no sort
                                      of assistance. Nominees have to be alone
                                      in the area when taking the exam and
                                      nobody ought to be speaking to you in the
                                      time of this exam. Additionally, aspirants
                                      can not navigate out of the browser in the
                                      time of accepting the exam. Aside from
                                      that, there are additional set of rules
                                      that you will need to follow along with
                                      taking the examination.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h5>Highlights</h5>
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Accepted by over 1000
                                          institutions globally
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Candidates can get their score
                                          for just $49
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Candidates may send their score
                                          reports for example many Institutes as
                                          they need for free
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Nominees Have to Have the
                                          Essential equipment and setup For
                                          registering for this particular test.
                                          Aside from this, applicants may
                                          utilize their driving license or
                                          government ID Aside from the passport
                                          to enroll for the exam
                                        </li>
                                      </ul>{" "}
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Test duration</h6>45 minutes to the
                                      Adaptive evaluation where all of your
                                      reading, listening, writing, and speaking
                                      skills are analyzed. Additionally, there's
                                      a video interview about 10 minutes that
                                      lists your replies to open-ended queries.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Duolingo English Exam Fee</h6>This is
                                      definitely the most inexpensive
                                      certification evaluation. You can take
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        The Way to prepare for your Duolingo
                                        English Exam?
                                      </h6>
                                      You have to prepare yourself for carrying
                                      the Duolingo examination. You should focus
                                      on improving each of the facets of
                                      listening. It's possible to listen to
                                      English podcasts and browse articles from
                                      leading magazines and papers and practice
                                      with a conversation in English with
                                      somebody with native Language speakers.
                                      <br />
                                      Additionally, You May Also Have a sample
                                      test and receive Familiar with the sorts
                                      of queries you will become at the actual
                                      test. You are able to choose the sample
                                      exam free on Duolingo's web site.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        Difference Between Duolingo English
                                        Evaluation and TOEFL Exam
                                      </h6>
                                      The items of this Duolingo evaluation tend
                                      to be short in Contrast The TOEFL exam
                                      concentrates on situations you will see in
                                      the actual world especially in academics.
                                      This means you need to adapt fast to fix
                                      another query. If one query tests your
                                      studying abilities then another one can
                                      examine your listening skills.
                                      Additionally, the Duolingo evaluation is
                                      elastic so the difficulty level of
                                      questions is linked to the prior query,
                                      whereas, at the TOEFL examination, the
                                      difficulty level doesn't have anything
                                      with your prior replies.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        Difference Between Duolingo English
                                        Evaluation and IELTS Exam
                                      </h6>
                                      Dulolingo test could be given in 1 effort,
                                      whereas the IELTS Examination is provided
                                      generally more than a two- day interval.
                                      Additionally, there are a variety of
                                      differences in the arrangement of the
                                      examinations. Although both the
                                      examinations test each of the four skills
                                      of the English language, but you won't see
                                      long texts from the Duolingo examination.
                                      <br />
                                      As Soon as You complete the evaluation,
                                      It's delivered into the proctors and If
                                      the scores are prepared, the offender is
                                      advised via email. Candidates may take two
                                      accredited tests inside a 30-day period. A
                                      certified evaluation is a customary test
                                      that you just give and receive scores. If,
                                      if you face any technical problem during
                                      the examination, it won't be counted
                                      against the two-time limitation.
                                      <br />
                                      As Soon as You get Your scores, you can
                                      send it into the educational institutions
                                      right from the results page and there's
                                      absolutely no limitation of sending the
                                      score accounts. It is possible to send the
                                      score record as many associations you
                                      desire.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Why we want a fresh English Test?</h6>
                                      The procedure for accepting the English
                                      Language Proficiency evaluation Can be
                                      difficult for some pupils. Pupils will
                                      need to travel extended distances
                                      advertisement schedule the exam weeks or
                                      in some instances months ahead of taking
                                      the exam. Additionally, the price of the
                                      exam goes to countless bucks.
                                      <br />
                                      Duolingo examination uses technology to
                                      Generate education accessible To
                                      everybody. Expensive to reevaluate their
                                      English abilities. Consequently, Duolingo
                                      Includes an Examination that's cheap,
                                      convenient, and quick.
                                    </p>
                                  </div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div className="col-md-12">
                                    <div
                                      className="content"
                                      style={{ paddingBottom: 0 }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area default-padding">
        <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="row" style={{ display: "block" }}>
            <div className="about-items">
              <div className="col-md-12">
                <div className="weekly-top-items">
                  <div className="top-courses" style={{ paddingRight: 0 }}>
                    <div className="heading" style={{ paddingBottom: 15 }}>
                      <h4 style={{ color: "#3f8ccb" }}>
                        ACCREDITATIONS AND MEMBERSHIPS <strong></strong>
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/EAIE.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/ICEF.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/NAFSA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/QISAN.jpeg"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIRC.gif"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIEA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
