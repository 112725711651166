/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
import React, {  } from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
// import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import Header from "./Header";
import Footer from "./Footer";

import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    "@global": {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    navbarnav: {
        flexDirection: "row",
    },
    newbtn: {
        border: "none",
        position: "absolute",
        right: "5",
        top: "5",
        minHeight: 50,
        background: "#3f8ccb none repeat scroll 0 0",
        padding: "0 30",
        borderRadius: 30,
        fontWeight: 600,
        letterSpacing: 1,
        color: "#ffffff",
        textTransform: "uppercase",
    },

    formControl: {
        margin: 0,
        minWidth: "100%",
        fontSize: 12,
    },

    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    paper: {
        marginTop: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: "blue",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    bigAvatar: {
        margin: "center",
        marginBottom: "10px",
        width: 60,
        height: 60,
        margin: theme.spacing(-1),
    },
    button: {
        margin: "center",
        marginTop: "10px",
    },
    Title: {
        boxSizing: "border-box",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "10",
        marginBottom: "2em",
        backgroundColor: "#000",
        color: "#fff",
    },

    widths: {
        paddingLeft: 0,
        paddingRight: 0,
        maxWidth: "100%",
    },
    bgdark: {
        backgroundColor: "#3f8ccb",
    },
    day: {
        "&:hover": {
            background: "#efefef",
        },
        "&:last-child": {
            borderRight: "solid 1px #cccccc",
        },
    },
}));

export default function CompareUniversity(props) {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    // useEffect(() => {
    //   if (!location.state) {
    //     alert("no props");
    //     history.replace({ pathname: "/Compare" });
    //   } else {
    //     alert("props");
    //   }
    // }, []);

    if (!location.state) {
        history.replace("/Compare");
        return null;
    } else {
        // data from compare universities
        
        const stateValues = Object.values(location.state)
        console.log('SSSSXXXXXXXX',location.state)
      
        return (
            <Container className={classes.widths}>
                <Header /> <CssBaseline />
                <div className="login-area default-padding" style={{ paddingTop: 20 }}>
                    <div className="">
                        <div className="row">
                            <div className="col-md-12">
                                <div
                                    className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                                    style={{
                                        backgroundImage:
                                            "url(" + "../assets/img/banner/6.jpg" + ")",
                                        padding: 70,
                                    }}
                                >
                                    <div className="container">
                                        <div className="row">
                                            <div className="double-items col-md-12 info">
                                                <h2 className="wow fadeInLeft">
                                                    <i
                                                        class="fa fa-balance-scale"
                                                        style={{ fontSize: 30 }}
                                                    ></i>{" "}
                          Search and add programs to compare
                        </h2>
                                            </div>
                                            {/* <div
                                                class="col-lg-12"
                                                style={{
                                                    padding: 15,
                                                    backgroundColor: "#FFF",
                                                    borderRadius: 50,
                                                    marginTop: 25,
                                                }}
                                            >
                                                <div class="col-lg-10">
                                                    <TextField
                                                        fullWidth
                                                        name="study"
                                                        label="Search Universities"
                                                        type="text"
                                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                                    />
                                                </div>

                                                <div
                                                    class="col-lg-2"
                                                    style={{ padding: 0, textAlign: "center" }}
                                                >
                                            
                                                    <div className="blog-area">
                                                        <div className="info">
                                                            <div className="content" style={{ padding: 0 }}>
                                                                <a href="#">
                                                                    <i className="fas fa-search"></i> Search
                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="about-area default-padding"
                                    style={{ padding: 10 }}
                                >
                                    <div className="container">
                                        <div className="row">
                                            <div className="about-items">
                                                <div className="col-md-12 about-info">
                                                    <h2>Compare programs</h2>
                                                    {/* <blockquote style={{fontSize:15, lineHeight:'1', }}>
                        As overseas education consultants, we help students achieve their goals.
                        </blockquote> */}
                                                    <p
                                                        style={{
                                                            fontSize: 14,
                                                            color: "#212121",
                                                            textAlign: "justify",
                                                        }}
                                                    >
                                                        A comprehensive rating about the Universities on
                                                        noteworthy parameters can easily narrow down an
                                                        choice from the pool of universities. Study Metro
                                                        university comparer helps in making informed
                                                        decision about universities.
                          </p>
                                                </div>
                                                {/* <div className="col-md-6 features text-light">
 <img style={{flex:1}} class="login_image" src="assets/img/compare.png" alt="Login" data-original-title="" title="" />
<i class="fa fa-balance-scale" style={{fontSize:100,}}></i> 
                    </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="advisor-details-area default-padding"
                                    style={{ padding: 0 }}
                                >
                                    <div className="container">
                                        <div className="row">
                                            <div className="advisor-info">
                                                <div className="col-md-8 content">
                                                    {/* <div className="course-info-list">
                            <ul>
                                <li>
                                    <h2>12</h2>
                                    <h5>Courses Authored</h5>
                                </li>
                                <li>
                                    <h2>120+</h2>
                                    <h5>Student Enrolled</h5>
                                </li>
                                <li>
                                    <h2>4.5</h2>
                                    <h5>Avg Ratings</h5>
                                </li>
                            </ul>
                        </div> */}

                                                    <div className="tab-info">
                                                        <ul className="nav nav-pills">
                                                            <li className="active">
                                                                <a
                                                                    data-toggle="tab"
                                                                    href="#tab1"
                                                                    aria-expanded="true"
                                                                >
                                                                    OVERVIEW
                                </a>
                                                            </li>
                                                            <li className="">
                                                                <a
                                                                    data-toggle="tab"
                                                                    href="#tab2"
                                                                    aria-expanded="false"
                                                                >
                                                                    FINANCE
                                </a>
                                                            </li>
                                                            <li className="">
                                                                <a
                                                                    data-toggle="tab"
                                                                    href="#tab3"
                                                                    aria-expanded="false"
                                                                >
                                                                    CONDITIONS
                                </a>
                                                            </li>
                                                        </ul>

    

                                                        <div className="tab-content tab-content-info">

{/* OverView tab Data   */}
                                                            <div id="tab1" className="tab-pane  active in">
                                                                <div className="info title">


                                                                    {
                                                                        stateValues.map((item,index)=>{
                                                                            return(
                                                                               item !==""? 
                                                                               <>
                                                                                    <div className="course-info-list">
                                                                                        <ul>

                                                                                            {/* college name and country */}
                                                                                            <li>
                                                                                                <h2
                                                                                                    style={{
                                                                                                        fontSize: 13,
                                                                                                        color: "#3f8ccb",
                                                                                                    }}
                                                                                                >
                                                                                                    {item.name}
                                                                                                </h2>
                                                                                                <h5
                                                                                                    style={{
                                                                                                        fontSize: 12,
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {item.country}
                                                                                                </h5>
                                                                                            </li>

                                                                                            {/* Founder and year  */}

                                                                                            <li>
                                                                                                <h2
                                                                                                    style={{
                                                                                                        fontSize: 13,
                                                                                                        color: "#3f8ccb",
                                                                                                    }}
                                                                                                >
                                                                                                    Founded
                                                                                                </h2>
                                                                                                <h5
                                                                                                    style={{
                                                                                                        fontSize: 12,
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {item.founded}
                                                                                                </h5>
                                                                                            </li>

                                                                                            {/* email id */}

                                                                                            <li>
                                                                                                <h2
                                                                                                    style={{
                                                                                                        fontSize: 13,
                                                                                                        color: "#3f8ccb",
                                                                                                    }}
                                                                                                >
                                                                                                    Email
                                                                                                </h2>
                                                                                                <h5
                                                                                                    style={{
                                                                                                        fontSize: 12,
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {item.email}
                                                                                                </h5>
                                                                                            </li>

                                                                                            {/* enrollment  */}
                                                                                            <li>
                                                                                                <h2
                                                                                                    style={{
                                                                                                        fontSize: 13,
                                                                                                        color: "#3f8ccb",
                                                                                                    }}
                                                                                                >
                                                                                                    Enrollment
                                                                                               </h2>
                                                                                                <h5
                                                                                                    style={{
                                                                                                        fontSize: 12,
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        item
                                                                                                            .total_students
                                                                                                    }
                                                                                                </h5>
                                                                                            </li>

                                                                                            {/* Avg Count  */}
                                                                                            <li>
                                                                                                <h2
                                                                                                    style={{
                                                                                                        fontSize: 13,
                                                                                                        color: "#3f8ccb",
                                                                                                    }}
                                                                                                >
                                                                                                    Avg. Quant
                                                                                                 </h2>
                                                                                                <h5
                                                                                                    style={{
                                                                                                        fontSize: 12,
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    NA
                                                                                                </h5>
                                                                                            </li>


                                                                                        </ul>
                                                                                   </div>
                                                                                   <hr />
                                                                                   </>
                                                                                   :null
                                                                            )
                                                                        })
                                                                    }


                                                                </div>
                                                            </div>

{/* Finance tab data  */}
                                                            <div id="tab2" className="tab-pane ">
                                                                <div className="info title">

                                                                {
                                                                        stateValues.map((item,index)=>{
                                                                            return(
                                                                               item !==""? 
                                                                               <>
                                                                               <div className="course-info-list">
                                                                                    <ul>
                                                                                        <li>
                                                                                            <h2
                                                                                                style={{
                                                                                                    fontSize: 13,
                                                                                                    color: "#3f8ccb",
                                                                                                }}
                                                                                            >
                                                                                                {item.name}
                                                                                            </h2>
                                                                                            <h5
                                                                                                style={{
                                                                                                    fontSize: 12,
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                {item.country}
                                                                                            </h5>
                                                                                        </li>
                                                                                        <li>
                                                                                            <h2
                                                                                                style={{
                                                                                                    fontSize: 13,
                                                                                                    color: "#3f8ccb",
                                                                                                }}
                                                                                            >
                                                                                                Tution Fees
                                                                                            </h2>
                                                                                            <h5
                                                                                                style={{
                                                                                                    fontSize: 12,
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                <i class="fas fa-pound-sign"></i>{" "}
                                                                                                {item.tution_fee}
                                                                                            </h5>
                                                                                        </li>
                                                                                        <li>
                                                                                            <h2
                                                                                                style={{
                                                                                                    fontSize: 13,
                                                                                                    color: "#3f8ccb",
                                                                                                }}
                                                                                            >
                                                                                                Expenses/yr
                                                                                            </h2>
                                                                                            <h5
                                                                                                style={{
                                                                                                    fontSize: 12,
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                <i class="fas fa-pound-sign"></i>{" "}
                                                                                            4,200
                                                                                            </h5>
                                                                                        </li>
                                                                                        <li>
                                                                                            <h2
                                                                                                style={{
                                                                                                    fontSize: 13,
                                                                                                    color: "#3f8ccb",
                                                                                                }}
                                                                                            >
                                                                                                Financial AID
                                                                                            </h2>
                                                                                            <h5
                                                                                                style={{
                                                                                                    fontSize: 12,
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                NA
                                                                                            </h5>
                                                                                        </li>
                                                                                        <li>
                                                                                            <h2
                                                                                                style={{
                                                                                                    fontSize: 13,
                                                                                                    color: "#3f8ccb",
                                                                                                }}
                                                                                            >
                                                                                                Jobs
                                                                                            </h2>
                                                                                            <h5
                                                                                                style={{
                                                                                                    fontSize: 12,
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                NA
                                                                                             </h5>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div> 
                                                                                <hr/>
                                                                                </>
                                                                                   :null

                                                                                   )
                                                                                })
                                                                            }
                                                                </div>
                                                            </div>

{/* conditions tab data  */}

                                                            <div id="tab3" className="tab-pane">
                                                                <div className="info title">
                                                                {
                                                                        stateValues.map((item,index)=>{
                                                                            return(
                                                                               item !==""? 
                                                                               <>
                                                                                <div className="course-info-list">
                                                                                        <ul>
                                                                                            <li>
                                                                                                <h2
                                                                                                    style={{
                                                                                                        fontSize: 13,
                                                                                                        color: "#3f8ccb",
                                                                                                    }}
                                                                                                >
                                                                                                    {item.name}
                                                                                                </h2>
                                                                                                <h5
                                                                                                    style={{
                                                                                                        fontSize: 12,
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {item.country}
                                                                                                </h5>
                                                                                            </li>
                                                                                            <li>
                                                                                                <h2
                                                                                                    style={{
                                                                                                        fontSize: 13,
                                                                                                        color: "#3f8ccb",
                                                                                                    }}
                                                                                                >
                                                                                                    Type
                                                                                                 </h2>
                                                                                                <h5
                                                                                                    style={{
                                                                                                        fontSize: 12,
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {" "}
                                                                                                    {
                                                                                                        item
                                                                                                            .institution
                                                                                                    }
                                                                                                </h5>
                                                                                            </li>
                                                                                            <li>
                                                                                                <h2
                                                                                                    style={{
                                                                                                        fontSize: 13,
                                                                                                        color: "#3f8ccb",
                                                                                                    }}
                                                                                                >
                                                                                                    Address
                                                                                                  </h2>
                                                                                                <h5
                                                                                                    style={{
                                                                                                        fontSize: 12,
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {item.address}
                                                                                                
                                                                                                </h5>
                                                                                            </li>
                                                                                        
                                                                                        </ul>
                                                                                    </div>

                                                                                <hr/>
                                                                                </>
                                                                                   :null

                                                                                   )
                                                                                })
                                                                            }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="thumb">
                                                        <img
                                                            src="assets/img/compare.png"
                                                            style={{ borderRadius: "50%" }}
                                                            alt="Thumb"
                                                        />
                                                     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Container>
        );
    }
}
