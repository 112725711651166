
import React, { useEffect, useState } from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from '@material-ui/core/Button';
import { postData } from "../FetchServices";
import Header from "./Header";
import Footer from "./Footer";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    // margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  margin: {
    margin: theme.spacing(1),
    outline:'none'
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function UniversityDisplay(props) {
  const classes = useStyles();

  // const parameter = useParams();
  const location = useLocation();
  const history = useHistory();
  const [getClgName, setClgName] = useState({});
  const[programList,setProgramList]=useState([]);

  const handleCourse = (id) => {
    // alert("hello next Page");
      props.history.push({
        pathname: "/DisplayCourses",
        state: {
          id: id,
        }
      });
    // console.log(event.currentTarget);
  };

  useEffect(function () {
    if (!location.state) {
      history.replace("/SearchUniversity");
    } else {
      fetchDataById();
      fetchProgramsByUniId();
    }
  }, []);

  const fetchProgramsByUniId=async()=>{
    let body = { ID: location.state.id };
    let result = await postData("abroad/fetchbyuniversityid",body);
    setProgramList(result.data)
  };

  const displayPrograms=()=>{
   return programList.map((item)=>{
    return  <div style={{display:'flex',background:'red',margin:5}}>    
                    {item.name}
            </div>
   })
  }

  const fetchDataById = async () => {
    let body = { ID: location.state.id };
    let result = await postData("universities/fetchById", body);
    setClgName(result[0]);
  };

 


  if (!location.state) {
    history.replace("/SearchUniversity");
    return null;
  } else {
    return (
      <Container className={classes.widths}>
        <Header />

        <CssBaseline />
        <div className="login-area default-padding" style={{ paddingTop: 20 }}>
          <div className="">
            <div className="row">
              <div className="col-md-12">
                <img
                  // src={`${getClgName}`}
                  src={`${getClgName.image}`}
                  width="1400"
                  height="400"
                ></img>
                
                <div className="blog-area single full-blog left-sidebar full-blog default-padding">
                  <div className="container">
                    <div className="row">
                      <div className="blog-items">
                        <div className="blog-content col-md-12">
                          <div className="content-items">
                            <div className="single-item">
                              <div className="item">
                                <div className="info">
                                  <div
                                    className="content"
                                    style={{ paddingBottom: 0 }}
                                  >
                                    <h2>
                                      {/* About{" "} */}
                                      <img
                                        // src={`${getClgName}`}
                                        src={`${getClgName.logo}`}
                                        width="100"
                                        height="100"
                                      ></img>
                                      <span>
                                        {/* {getClgName} */}
                                        {getClgName.name}
                                      </span>

                                      <p
                                        style={{
                                          fontSize: 20,
                                          color: "#212121",
                                          textAlign: "justify",
                                        }}
                                      >
                                        Estd. {getClgName.founded}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: 15,
                                          color: "#212121",
                                          textAlign: "justify",
                                        }}
                                      >
                                        Address : {getClgName.address},{" "}
                                        {getClgName.location},{" "}
                                        {getClgName.country}
                                      </p>
                                    </h2>
                                    <blockquote
                                      style={{ fontSize: 15, lineHeight: "1" }}
                                    >
                                      As overseas education consultants, we help
                                      students achieve their goals.
                                    </blockquote>
                                    <p
                                      style={{
                                        fontSize: 14,
                                        color: "#212121",
                                        textAlign: "justify",
                                      }}
                                    >
                                      {getClgName.meta_descprition}
                                    </p>
                                    <br />

                                  
                                      <div>
                                    <h2>
                                    <span style={{ marginLeft: 40 }}>
                                      Admissions
                                    </span>
                                  </h2>
                                  
                                  
                                                  
                                                      <div class="info" style={{marginLeft:38,marginBottom:5}}>
                                                       
                                                           <span style={{fontFamily:'arial-black',fontWeight:'bold',fontSize:22}}> Institute Type :</span>
                                                          <span style={{fontSize:17,marginLeft:8}}>
                                                          {
                                                            getClgName.institution
                                                          }
                                                          </span>
                                                          
                                                      </div>

                                                      <div class="info"  style={{marginLeft:38,marginBottom:5}}>
                                                        
                                                      <span style={{fontFamily:'arial-black',fontWeight:'bold',fontSize:22}}> Requirement :{" "}</span>
                                                      <span style={{fontSize:17,marginLeft:8}}>
                                                          {
                                                            getClgName.requirement
                                                          }
                                                          </span>
                                                          
                                                      </div>

                                                      <div class="info"  style={{marginLeft:38,marginBottom:5}}>
                                                       
                                                      <span style={{fontFamily:'arial-black',fontWeight:'bold',fontSize:22}}>Tution Fee :</span>
                                                      <span style={{fontSize:17,marginLeft:8}}>
                                                          {
                                                            getClgName.tution_fee
                                                          }
                                                       </span>
                                                      </div>

                                                      <div class="info"  style={{marginLeft:38,marginBottom:5}}>
                                                      <span style={{fontFamily:'arial-black',fontWeight:'bold',fontSize:22}}> Accommodation :</span>
                                                      <span style={{fontSize:17,marginLeft:8}}>
                                                          {
                                                            getClgName.accommodation
                                                          }
                                                      </span>
                                                      </div>
                                        
                           </div>
                           
                           
                                  <h2>
                                    <span style={{ marginLeft: 40,marginTop:40}}>
                                      Offered Programs
                                    </span>
                                  </h2>
                                  
                                  <div style={{ marginLeft: 38,display:'flex',flexWrap:'wrap',flexDirection:'row' }}>    
                                                    {programList.map((item)=>(
                                                      <div style={{display:'flex',marginBottom:10,width:400}}>
                                                         <a 
                                                          onClick={() => handleCourse(item.id)}
                                                          >
                                                        <span style={{fontFamily:'arial-black',fontWeight:'bold',fontSize:22}}>&#8226; {item.name}</span>
                                                        </a>
                                                      </div>
                                                    ))}
                                                        <br/>
                                                        <br/>
                                  
</div>
                           
                        
                        
                            {/* end of individual div's */}



                                  </div>
                                  
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

               
                <section>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>

                </section>

                <div
                  class="features-area default-padding-bottom fixed bottom-less bg-color text-light"
                  style={{ marginTop: "50" }}
                >
                  <div class="container">
                    <div className="weekly-top-items">
                      <div className="col-md-12">
                        <div
                          className="top-courses"
                          style={{ paddingRight: 0 }}
                        >
                          <div
                            className="heading"
                            style={{ paddingBottom: 15 }}
                          >
                            <h4 style={{ color: "#3f8ccb" }}>
                              STUDY ABROAD PROGRAMS & {" "}
                              <strong></strong>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="">
                      <div class="features">
                        <div
                          class="equal-height col-md-3 col-sm-6"
                          style={{ height: 330 }}
                        >
                          <div class="item mariner">
                            <a href="#">
                              <div class="icon">
                                <icon
                                  style={{
                                    color: "white",
                                    fontSize: "4rem",
                                    marginBottom: 10,
                                  }}
                                  class="fa fa-university"
                                ></icon>
                              </div>
                              <div class="info">
                                <h1>947</h1>
                                <h4>Universities</h4>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div
                          class="equal-height col-md-3 col-sm-6"
                          style={{ height: 330 }}
                        >
                          <div class="item brilliantrose">
                            <a href="#">
                              <div class="icon">
                                <icon
                                  style={{
                                    color: "white",
                                    fontSize: "4rem",
                                    marginBottom: 10,
                                  }}
                                  class="fa fa-graduation-cap"
                                ></icon>
                              </div>
                              <div class="info">
                                <h1>56184</h1>
                                <h4>Programs</h4>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div
                          class="equal-height col-md-3 col-sm-6"
                          style={{ height: 330 }}
                        >
                          <div class="item casablanca">
                            <a href="#">
                              <div class="icon">
                                <icon
                                  style={{
                                    color: "white",
                                    fontSize: "4rem",
                                    marginBottom: 10,
                                  }}
                                  class="fa fa-flag"
                                ></icon>
                              </div>
                              <div class="info">
                                <h1>11</h1>
                                <h4>Countries</h4>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div
                          class="equal-height col-md-3 col-sm-6"
                          style={{ height: 330 }}
                        >
                          <div class="item malachite">
                            <a href="#">
                              <div class="icon">
                                <icon
                                  style={{
                                    color: "white",
                                    fontSize: "4rem",
                                    marginBottom: 10,
                                  }}
                                  class="fa fa-users"
                                ></icon>
                              </div>
                              <div class="info">
                                <h1>28052</h1>
                                <h4>Students</h4>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="weekly-top-items carousel-shadow default-padding-bottom">
                  <div className="container">
                    <div className="row" style={{ display: "block" }}>
                      <div className="col-md-12">
                        <div className="top-courses">
                          <div className="heading">
                            <h4>
                              Trending Programs <strong></strong>
                            </h4>
                          </div>
                          <div className="top-course-items top-courses-carousel owl-carousel owl-theme">
                            <div className="item">
                              <div className="thumb">
                                <span className="badge">New</span>
                                <img
                                  src="assets/img/courses/1.jpg"
                                  alt="Thumb"
                                />
                                <div className="overlay">
                                  <a href="#">
                                    <img
                                      src="assets/img/team/1.jpg"
                                      alt="Thumb"
                                    />
                                  </a>
                                  <ul>
                                    <li>
                                      <i className="fas fa-clock"></i> 04:15:38
                                    </li>
                                    <li>
                                      <i className="fas fa-list-ul"></i> 18
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="info">
                                <div className="meta">
                                  <ul>
                                    <li>
                                      <a href="#">Education</a>
                                      <a href="#">Tech</a>
                                    </li>
                                    <li>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star-half-alt"></i>
                                      <span>(1k)</span>
                                    </li>
                                  </ul>
                                </div>
                                <h4>
                                  <a href="#">data science and software</a>
                                </h4>
                                <p>
                                  Wisdom praise things she before. Be mother
                                  itself vanity favour do me of. Begin sex was
                                  power joy after had walls miles.
                                </p>
                                <div className="footer-meta">
                                  <div className="blog-area">
                                    <div className="info">
                                      <div
                                        className="content"
                                        style={{ padding: 0 }}
                                      >
                                        <a
                                          href="#"
                                          style={{
                                            padding: "10px 30px",
                                            color: "#000",
                                          }}
                                        >
                                          <i className="fa fa-bookmark"></i>{" "}
                                          Book Now
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="item">
                              <div className="thumb">
                                <img
                                  src="assets/img/courses/2.jpg"
                                  alt="Thumb"
                                />
                                <div className="overlay">
                                  <a href="#">
                                    <img
                                      src="assets/img/team/2.jpg"
                                      alt="Thumb"
                                    />
                                  </a>
                                  <ul>
                                    <li>
                                      <i className="fas fa-clock"></i> 08:27:00
                                    </li>
                                    <li>
                                      <i className="fas fa-list-ul"></i> 65
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="info">
                                <div className="meta">
                                  <ul>
                                    <li>
                                      <a href="#">Illustration</a>
                                      <a href="#">Design</a>
                                    </li>
                                    <li>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <span>(2k)</span>
                                    </li>
                                  </ul>
                                </div>
                                <h4>
                                  <a href="#">Social Science & Humanities</a>
                                </h4>
                                <p>
                                  Wisdom praise things she before. Be mother
                                  itself vanity favour do me of. Begin sex was
                                  power joy after had walls miles.
                                </p>
                                <div className="footer-meta">
                                  <div className="blog-area">
                                    <div className="info">
                                      <div
                                        className="content"
                                        style={{ padding: 0 }}
                                      >
                                        <a
                                          href="#"
                                          style={{
                                            padding: "10px 30px",
                                            color: "#000",
                                          }}
                                        >
                                          <i className="fa fa-bookmark"></i>{" "}
                                          Book Now
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="item">
                              <div className="thumb">
                                <span className="badge">Best Seller</span>
                                <img
                                  src="assets/img/courses/6.jpg"
                                  alt="Thumb"
                                />
                                <div className="overlay">
                                  <a href="#">
                                    <img
                                      src="assets/img/team/3.jpg"
                                      alt="Thumb"
                                    />
                                  </a>
                                  <ul>
                                    <li>
                                      <i className="fas fa-clock"></i> 04:15:38
                                    </li>
                                    <li>
                                      <i className="fas fa-list-ul"></i> 32
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="info">
                                <div className="meta">
                                  <ul>
                                    <li>
                                      <a href="#">Science</a>
                                      <a href="#">Tech</a>
                                    </li>
                                    <li>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <span>(4.6k)</span>
                                    </li>
                                  </ul>
                                </div>
                                <h4>
                                  <a href="#">Programming MasterclassName</a>
                                </h4>
                                <p>
                                  Wisdom praise things she before. Be mother
                                  itself vanity favour do me of. Begin sex was
                                  power joy after had walls miles.
                                </p>
                                <div className="footer-meta">
                                  <div className="blog-area">
                                    <div className="info">
                                      <div
                                        className="content"
                                        style={{ padding: 0 }}
                                      >
                                        <a
                                          href="#"
                                          style={{
                                            padding: "10px 30px",
                                            color: "#000",
                                          }}
                                        >
                                          <i className="fa fa-bookmark"></i>{" "}
                                          Book Now
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>

                <div className="weekly-top-items carousel-shadow default-padding-bottom">
                  <div className="container">
                    <div className="row" style={{ display: "block" }}>
                      <div className="col-md-12">
                        <div className="top-courses">
                          <div className="heading">
                            <h4>
                              Trending Universities <strong></strong>
                            </h4>
                          </div>
                          <div className="top-course-items top-courses-carousel owl-carousel owl-theme">
                            <div className="item">
                              <div className="thumb">
                                <span className="badge">New</span>
                                <img
                                  src="assets/img/courses/1.jpg"
                                  alt="Thumb"
                                />
                                <div className="overlay">
                                  <a href="#">
                                    <img
                                      src="assets/img/team/1.jpg"
                                      alt="Thumb"
                                    />
                                  </a>
                                  <ul>
                                    <li>
                                      <i className="fas fa-clock"></i> 04:15:38
                                    </li>
                                    <li>
                                      <i className="fas fa-list-ul"></i> 18
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="info">
                                <div className="meta">
                                  <ul>
                                    <li>
                                      <a href="#">Education</a>
                                      <a href="#">Tech</a>
                                    </li>
                                    <li>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star-half-alt"></i>
                                      <span>(1k)</span>
                                    </li>
                                  </ul>
                                </div>
                                <h4>
                                  <a href="#">
                                    Florida International University
                                  </a>
                                </h4>
                                <p>University Park, Florida,U.S.USA...</p>
                                <div className="footer-meta">
                                  <div className="blog-area">
                                    <div className="info">
                                      <div
                                        className="content"
                                        style={{ padding: 0 }}
                                      >
                                        <a
                                          href="#"
                                          style={{
                                            padding: "10px 30px",
                                            color: "#000",
                                          }}
                                        >
                                          <i className="fa fa-bookmark"></i>{" "}
                                          Contact
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="item">
                              <div className="thumb">
                                <img
                                  src="assets/img/courses/2.jpg"
                                  alt="Thumb"
                                />
                                <div className="overlay">
                                  <a href="#">
                                    <img
                                      src="assets/img/team/2.jpg"
                                      alt="Thumb"
                                    />
                                  </a>
                                  <ul>
                                    <li>
                                      <i className="fas fa-clock"></i> 08:27:00
                                    </li>
                                    <li>
                                      <i className="fas fa-list-ul"></i> 65
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="info">
                                <div className="meta">
                                  <ul>
                                    <li>
                                      <a href="#">Illustration</a>
                                      <a href="#">Design</a>
                                    </li>
                                    <li>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <span>(2k)</span>
                                    </li>
                                  </ul>
                                </div>
                                <h4>
                                  <a href="#">
                                    Florida International University
                                  </a>
                                </h4>
                                <p>University Park, Florida,U.S.USA...</p>
                                <div className="footer-meta">
                                  <div className="blog-area">
                                    <div className="info">
                                      <div
                                        className="content"
                                        style={{ padding: 0 }}
                                      >
                                        <a
                                          href="#"
                                          style={{
                                            padding: "10px 30px",
                                            color: "#000",
                                          }}
                                        >
                                          <i className="fa fa-bookmark"></i>{" "}
                                          Contact
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="item">
                              <div className="thumb">
                                {/* <span className="badge">Best Seller</span> */}
                                <img
                                  src="assets/img/courses/6.jpg"
                                  alt="Thumb"
                                />
                                <div className="overlay">
                                  <a href="#">
                                    <img
                                      src="assets/img/team/3.jpg"
                                      alt="Thumb"
                                    />
                                  </a>
                                  <ul>
                                    <li>
                                      <i className="fas fa-clock"></i> 04:15:38
                                    </li>
                                    <li>
                                      <i className="fas fa-list-ul"></i> 32
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="info">
                                <div className="meta">
                                  <ul>
                                    <li>
                                      <a href="#">Science</a>
                                      <a href="#">Tech</a>
                                    </li>
                                    <li>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <span>(4.6k)</span>
                                    </li>
                                  </ul>
                                </div>
                                <h4>
                                  <a href="#">
                                    Florida International University
                                  </a>
                                </h4>
                                <p>University Park, Florida,U.S.USA...</p>
                                <div className="footer-meta">
                                  <div className="blog-area">
                                    <div className="info">
                                      <div
                                        className="content"
                                        style={{ padding: 0 }}
                                      >
                                        <a
                                          href="#"
                                          style={{
                                            padding: "10px 30px",
                                            color: "#000",
                                          }}
                                        >
                                          <i className="fa fa-bookmark"></i>{" "}
                                          Contact
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-4">
                    <div className="top-author">
                        <h4>Fast filling universities</h4>
                        <div className="author-items">

                            <div className="item">
                                <div className="thumb">
                                    <a href="#">
                                        <img src="assets/img/team/6.jpg" alt="Thumb" />
                                    </a>
                                </div>
                                <div className="info">
                                    <h5><a href="#">Professon. Nuri Paul</a></h5>
                                    <ul>
                                        <li>Students enrolled <strong>12k+</strong></li>
                                        <li>
                                            <span>Rating</span>
                                            <div className="rating">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star-half-alt"></i>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="item">
                                <div className="thumb">
                                    <a href="#">
                                        <img src="assets/img/team/7.jpg" alt="Thumb" />
                                    </a>
                                </div>
                                <div className="info">
                                    <h5><a href="#">Dr. Bubly Minu</a></h5>
                                    <ul>
                                        <li>Students enrolled <strong>16k+</strong></li>
                                        <li>
                                            <span>Rating</span>
                                            <div className="rating">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="item">
                                <div className="thumb">
                                    <a href="#"><img src="assets/img/team/8.jpg" alt="Thumb" /></a>
                                </div>
                                <div className="info">
                                    <h5><a href="#">Monayem Pruda</a></h5>
                                    <ul>
                                        <li>Students enrolled <strong>23k+</strong></li>
                                        <li>
                                            <span>Rating</span>
                                            <div className="rating">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star-half-alt"></i>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <a href="#">View All <i className="fas fa-angle-double-right"></i></a>
                        </div>
                    </div>
                </div>*/}
                    </div>
                  </div>
                </div>

                {/* <div className="weekly-top-items carousel-shadow default-padding-bottom ">
                  <div className="container">
                    <div className="row" style={{ display: "block" }}>
                      <div className="col-md-12">
                        <div className="top-courses">
                          <div className="heading">
                            <h4>
                              UPCOMING WEBINARS <strong></strong>
                            </h4>
                          </div>
                          <div className="top-course-items top-courses-carousel owl-carousel owl-theme">
                            <div className="item">
                              <div className="thumb">
                                <img src="assets/img/event/1.jpg" alt="Thumb" />
                              </div>
                              <div className="info">
                                <div className="meta">
                                  <ul>
                                    <li>
                                      <a
                                        href="#"
                                        style={{
                                          color: "#ffb606",
                                          fontSize: 16,
                                        }}
                                      >
                                        16 APR
                                      </a>
                                      <a
                                        href="#"
                                        style={{
                                          color: "#ffb606",
                                          fontSize: 16,
                                        }}
                                      >
                                        2020
                                      </a>
                                    </li>
                                    <li>
                                      <i class="fas fa-clock"></i> 8:00 - 16:00
                                    </li>
                                    <li>
                                      <i class="fas fa-map-marked-alt"></i>{" "}
                                      California, TX 70240
                                    </li>
                                  </ul>
                                </div>
                                <h4>
                                  <a href="#">
                                    Secondary Schools United Nations
                                  </a>
                                </h4>
                                <p>
                                  Attachment astonished to on appearance
                                  imprudence so collecting in excellence. Tiled
                                  way blind lived whose new. The for fully had
                                  she there leave merit enjoy forth.
                                </p>
                                <div className="footer-meta">
                                  <div className="blog-area">
                                    <div className="info">
                                      <div
                                        className="content"
                                        style={{ padding: 0 }}
                                      >
                                        <a
                                          href="#"
                                          style={{
                                            padding: "10px 30px",
                                            color: "#000",
                                          }}
                                        >
                                          <i className="fa fa-user-plus"></i>{" "}
                                          Enroll Now
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="item">
                              <div className="thumb">
                                <img src="assets/img/event/2.jpg" alt="Thumb" />
                              </div>
                              <div className="info">
                                <div className="meta">
                                  <ul>
                                    <li>
                                      <a
                                        href="#"
                                        style={{
                                          color: "#ffb606",
                                          fontSize: 16,
                                        }}
                                      >
                                        8 JUL
                                      </a>
                                      <a
                                        href="#"
                                        style={{
                                          color: "#ffb606",
                                          fontSize: 16,
                                        }}
                                      >
                                        2020
                                      </a>
                                    </li>
                                    <li>
                                      <i class="fas fa-clock"></i> 9:00 - 12:00
                                    </li>
                                    <li>
                                      <i class="fas fa-map-marked-alt"></i>{" "}
                                      California, TX 70240
                                    </li>
                                  </ul>
                                </div>
                                <h4>
                                  <a href="#">
                                    Secondary Schools United Nations
                                  </a>
                                </h4>
                                <p>
                                  Attachment astonished to on appearance
                                  imprudence so collecting in excellence. Tiled
                                  way blind lived whose new. The for fully had
                                  she there leave merit enjoy forth.
                                </p>
                                <div className="footer-meta">
                                  <div className="blog-area">
                                    <div className="info">
                                      <div
                                        className="content"
                                        style={{ padding: 0 }}
                                      >
                                        <a
                                          href="#"
                                          style={{
                                            padding: "10px 30px",
                                            color: "#000",
                                          }}
                                        >
                                          <i className="fa fa-user-plus"></i>{" "}
                                          Enroll Now
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="item">
                              <div className="thumb">
                                <img src="assets/img/event/3.jpg" alt="Thumb" />
                              </div>
                              <div className="info">
                                <div className="meta">
                                  <ul>
                                    <li>
                                      <a
                                        href="#"
                                        style={{
                                          color: "#ffb606",
                                          fontSize: 16,
                                        }}
                                      >
                                        16 MAY
                                      </a>
                                      <a
                                        href="#"
                                        style={{
                                          color: "#ffb606",
                                          fontSize: 16,
                                        }}
                                      >
                                        2020
                                      </a>
                                    </li>
                                    <li>
                                      <i class="fas fa-clock"></i> 10:00 - 14:00
                                    </li>
                                    <li>
                                      <i class="fas fa-map-marked-alt"></i>{" "}
                                      California, TX 70240
                                    </li>
                                  </ul>
                                </div>
                                <h4>
                                  <a href="#">Programming MasterclassName</a>
                                </h4>
                                <p>
                                  Attachment astonished to on appearance
                                  imprudence so collecting in excellence. Tiled
                                  way blind lived whose new. The for fully had
                                  she there leave merit enjoy forth.
                                </p>
                                <div className="footer-meta">
                                  <div className="blog-area">
                                    <div className="info">
                                      <div
                                        className="content"
                                        style={{ padding: 0 }}
                                      >
                                        <a
                                          href="#"
                                          style={{
                                            padding: "10px 30px",
                                            color: "#000",
                                          }}
                                        >
                                          <i className="fa fa-user-plus"></i>{" "}
                                          Enroll Now
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="testimonials-area carousel-shadow active-dots bg-gray default-padding bg-cover" style={{backgroundImage: "url(" + "../assets/img/shape-bg.png" + ")",}}>
        <div className="container">
            <div className="">
                <div className="site-heading text-center">
                    <div className="col-md-8 col-md-offset-2">
                        <h2>What Students Says</h2>
                       
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="testimonial-items testimonial-carousel owl-carousel owl-theme text-center">
                        <div className="item">
                            <div className="icon">
                                <i className="ti-quote-left"></i>
                            </div>
                            <div className="content">
                                <p>
                                    Regret eat looked warmth easily far should now. Prospect at me wandered on extended wondered thoughts appetite to. Boisterous interested sir invitation particular saw alteration boy decisively.
                                </p>
                                <div className="rating">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star-half-alt"></i>
                                </div>
                                <img src="assets/img/team/1.jpg" alt="Thumb" />
                            </div>
                            <div className="author">
                                <h4>Jonat Harik</h4>
                                <span>Student of DIU</span>
                            </div>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <i className="ti-quote-left"></i>
                            </div>
                            <div className="content">
                                <p>
                                    Ashamed herself has distant can studied mrs. Led therefore its middleton perpetual fulfilled provision frankness. Small he drawn after among every three no.
                                </p>
                                <div className="rating">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star-half-alt"></i>
                                </div>
                                <img src="assets/img/team/2.jpg" alt="Thumb" />
                            </div>
                            <div className="author">
                                <h4>Bunah Ahem</h4>
                                <span>Student of COO</span>
                            </div>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <i className="ti-quote-left"></i>
                            </div>
                            <div className="content">
                                <p>
                                    Regret eat looked warmth easily far should now. Prospect at me wandered on extended wondered thoughts appetite to. Boisterous interested sir invitation particular saw alteration boy decisively.
                                </p>
                                <div className="rating">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star-half-alt"></i>
                                </div>
                                <img src="assets/img/team/3.jpg" alt="Thumb" />
                            </div>
                            <div className="author">
                                <h4>Proda Huk</h4>
                                <span>Student of ICC</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
                <div
                  className="fun-factor-area default-padding text-center bg-fixed shadow dark-hard"
                  style={{
                    backgroundImage:
                      "url(" + "../assets/img/banner/20.jpg" + ")",
                  }}
                >
                  <div className="container" style={{ padding: 100 }}>
                    <div className="row" style={{ display: "block" }}>
                      <div className="col-md-2 ">
                        <div className="fun-fact">
                          <div className="icon">
                            <i class="fab fa-facebook-f"></i>
                          </div>
                          <div className="info">
                            <span
                              className="timer"
                              data-to="116179"
                              data-speed="5000"
                            ></span>
                            <span className="medium">Facebook</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-6 item">
                        <div className="fun-fact">
                          <div className="icon">
                            <i
                              className="fab fa-twitter"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="info">
                            <span
                              className="timer"
                              data-to="116000"
                              data-speed="5000"
                            ></span>
                            <span className="medium">Twitter</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-6 item">
                        <div className="fun-fact">
                          <div className="icon">
                            <i className="fab fa-instagram"></i>
                          </div>
                          <div className="info">
                            <span
                              className="timer"
                              data-to="37300"
                              data-speed="5000"
                            ></span>
                            <span className="medium">Instagram</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-6 item">
                        <div className="fun-fact">
                          <div className="icon">
                            <i className="fab fa-youtube"></i>
                          </div>
                          <div className="info">
                            <span
                              className="timer"
                              data-to="11184"
                              data-speed="5000"
                            ></span>
                            <span className="medium">Youtube</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-6 item">
                        <div className="fun-fact">
                          <div className="icon">
                            <i className="fab fa-facebook-f"></i>
                          </div>
                          <div className="info">
                            <span
                              className="timer"
                              data-to="880"
                              data-speed="5000"
                            ></span>
                            <span className="medium">Facebook Review</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-6 item">
                        <div className="fun-fact">
                          <div className="icon">
                            <i className="fab fa-google-plus"></i>
                          </div>
                          <div className="info">
                            <span
                              className="timer"
                              data-to="100"
                              data-speed="5000"
                            ></span>
                            <span className="medium">Google Review</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="blog-area default-padding bottom-less">
                  <div className="weekly-top-items">
                    <div className="container">
                      <div className="col-md-12">
                        <div
                          className="top-courses"
                          style={{ paddingRight: 0 }}
                        >
                          <div
                            className="heading"
                            style={{ paddingBottom: 15 }}
                          >
                            <h4 style={{ color: "#3f8ccb" }}>
                              LATEST BLOGS<strong></strong>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container">
                    <div className="">
                      <div className="blog-items">
                        <div className="col-md-4 single-item">
                          <div className="item">
                            <div className="thumb">
                              <a href="#">
                                <img src="assets/img/blog/1.jpg" alt="Thumb" />
                              </a>
                            </div>
                            <div className="info">
                              <div className="content">
                                <h5>
                                  <a href="#">
                                    Inquietude assistance precaution any
                                    impression man sufficient.
                                  </a>
                                </h5>

                                <a href="#">
                                  <i className="fas fa-plus"></i> Read More
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 single-item">
                          <div className="item">
                            <div className="thumb">
                              <a href="#">
                                <img src="assets/img/blog/2.jpg" alt="Thumb" />
                              </a>
                            </div>
                            <div className="info">
                              <div className="content">
                                <h5>
                                  <a href="#">
                                    Discourse ye continued pronounce we
                                    Particular use abilities.
                                  </a>
                                </h5>

                                <a href="#">
                                  <i className="fas fa-plus"></i> Read More
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 single-item">
                          <div className="item">
                            <div className="thumb">
                              <a href="#">
                                <img src="assets/img/blog/3.jpg" alt="Thumb" />
                              </a>
                            </div>
                            <div className="info">
                              <div className="content">
                                <h5>
                                  <a href="#">
                                    Deficient discourse do newspaper be an
                                    eagerness continued.
                                  </a>
                                </h5>

                                <a href="#">
                                  <i className="fas fa-plus"></i> Read More
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </Container>
    );
  }
}
