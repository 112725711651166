import React, {useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  postDataAndImageLocal,
  getDataLocal,
  postDataLocal,
  getData
} from "../FetchServicesLocal";

import MaterialTable from "material-table";

import Container from "@material-ui/core/Container";
import { postDataAndImage, postData } from "../FetchServices";

import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormGroup from "@material-ui/core/FormGroup";

import { green } from "@material-ui/core/colors";
// import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Radio from "@material-ui/core/Radio";
import Switch from "@material-ui/core/Switch";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { TextFields } from "@material-ui/icons";
import Header from "./Header";
import Footer from "./Footer";

  const useStyles = makeStyles((theme) => ({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    navbarnav: {
      flexDirection: "row",
    },
    input: {
      padding: "0px 0px",
    },
    formControl: {
      margin: 0,
      minWidth: "100%",
      fontSize: 16,
    },
  
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    paper: {
      marginTop: theme.spacing(1),
      display: "block",
      flexDirection: "column",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: "blue",
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    bigAvatar: {
      margin: "center",
      marginBottom: "10px",
      width: 60,
      height: 60,
      margin: theme.spacing(-1),
    },
    button: {
      margin: "center",
      marginTop: "10px",
    },
    Title: {
      boxSizing: "border-box",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      padding: "10",
      marginBottom: "2em",
      backgroundColor: "#000",
      color: "#fff",
    },
    widths: {
      paddingLeft: 0,
      paddingRight: 0,
      maxWidth: "100%",
    },
    bgdark: {
      backgroundColor: "#3f8ccb",
    },
    day: {
      "&:hover": {
        background: "#efefef",
      },
      "&:last-child": {
        borderRight: "solid 1px #cccccc",
      },
    },
    table: {
      minWidth: 650,
    },
  }));

  export default function ApplyProgram(props) {
    const classes = useStyles();
    // const [firstName, setFirstName] = React.useState("");
    // const [lastName, setLastName] = React.useState("");
    // const [emailId, setEmailId] = React.useState("");
    // const [password, setPassword] = React.useState("");
    // const [verifyPassword, setVerifyPassword] = React.useState("");
    const [messages, setMessages] = React.useState("");
    // const [picture, setPicture] = React.useState("");
    // const [getpicturePath, setPicturePath] = React.useState("");
    const [getStatus, setStatus] = React.useState("");
  
    // New update state
    const [getUniversitiesList, setUniversitiesList] = useState([]);
    const [getProgramsList, setProgramsList] = useState([]);
  
    const [getStudentUniqueId, setStudentUniqueId] = useState('')
    const [getStudentUniqueName, setStudentUniqueName] = useState('')
  
    const [getUniversityUniqueId, setUniversityUniqueId] = useState('')
    const [getUniversityUniqueName, setUniversityUniqueName] = useState('')
  
    const [getProgramUniqueId, setProgramUniqueId] = useState('')
    const [getProgramUniqueName, setProgramUniqueName] = useState('')
  
    const [getApplyDate, setApplyDate] = useState('')
    const [getCountry, setCountry] = useState('')
    const [getCampusName, setCampusName] = useState('')
    const [getAccountManager, setAccountManager] = useState('')
    const [getIntake, setIntake] = useState('')
    const [getStage, setStage] = useState('')
    const [getStudentNameProgram, setStudentNameProgram] = useState('')
    
  const [getlist, setlist] = useState([]);
  const [getstudent_Uni_ID, setstudent_Uni_ID] = useState("");
  

    const fetchData = async (sid) => {
      var body={sid : sid}
      var list = await postDataLocal("applyApplication/displayapplication", body);
      setlist(list.data);
    };

    const localStorageData = async () => {
      try {
        let data = await localStorage.getItem("student");
      if(data){
        let result = JSON.parse(data);
        setstudent_Uni_ID(result.student_ID);
        fetchData(result.student_ID)
      } else{
        props.history.replace({pathname:'/Login'})
      }
      } catch (error) {
        props.history.replace({pathname:'/Login'})
      }

    };
  
    //---------------CONSTRUCTOR--------------------//
    useEffect(function () {
      // localStorageData();
    }, []);

  //-----------------------------------------------------------//
  function DisplayApplication() {
    return(
      <Container className={classes.widths}>
      <Header />
      <div>
        <MaterialTable
          title="Application"
          columns={[
            // { title: "Student ID", field: "student_Uni_ID" },
            { title: "Student Name", field: "studentUniqueName" },
            { title: "University Name", field: "universityUniqueName" },
            { title: "Apply Date", field: "applyDate" },
            { title: "Country", field: "country" },
            { title: "Campus Name", field: "campusName" },
            // { title: "Stage", field: "stage" },

          ]}
          data={getlist}
        />
      </div>
      <Footer />
    </Container>
 );
  }

  return (
    <div >
      {DisplayApplication()}
    </div>
  );
}