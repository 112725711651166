/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function EducationLoans(props) {
  const classes = useStyles();
  const [showData,setShowData]=React.useState('USA')

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 25,
                  marginTop: 30,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">Education Loans</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="blog-area single full-blog left-sidebar full-blog default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="container"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                 <div className="row">
                    <div className="blog-items">
                        <div class="sidebar col-md-3">
                        <aside >
                          <div class="sidebar-item category"  style={{position:'sticky'}}>
                            <div class="title">
                              <h4>Country list</h4>
                            </div>
                            <div class="sidebar-info" >
                              <ul>
                                <li>
                                  <a onClick={()=>setShowData('USA')} style={{color: "#3f8ccb"}}><i class="flag-icon flag-icon-us"></i>&nbsp;&nbsp;USA</a>
                                </li>
                                <li>
                                  <a onClick={()=>setShowData('CANADA')} ><i class="flag-icon flag-icon-ca"></i>&nbsp;&nbsp;Canada </a>
                                </li>
                                <li>
                                  <a onClick={()=>setShowData('UK')}><i class="flag-icon flag-icon-gb"></i>&nbsp;&nbsp;UK </a>
                                </li>
                                <li>
                                  <a onClick={()=>setShowData('AUSTRALIA')}><i class="flag-icon flag-icon-au"></i>&nbsp;&nbsp;Australia </a>
                                </li>
                              
                                <li>
                                  <a onClick={()=>setShowData('GERMANY')}><i class="flag-icon flag-icon-de"></i>&nbsp;&nbsp;Germany </a>
                                </li>
                      
                              </ul>
                            </div>
                          </div>
                        </aside>
                      </div>
                      
                      <div className="blog-content col-md-9">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    
                                    {showData=='USA'?(
                                    <div id="usa">
                                     <p style={{ color: "#212121" }}>
                                      <h4>
                                      Education Loan to study in - USA
                                      </h4>
                                      The largest challenge one constantly faces for studying in the USA would be to
                                       arrange for capital. Cost of studying and living in a country such as the USA 
                                       could be exorbitant and everybody can't afford it. On the other hand, the aspiration 
                                       to study overseas and also make it big in life consistently induce you to overcome 
                                       those hurdles. Organizing funds is among the most vital areas of the entire exercise. 
                                       A variety of banks have known this situation and solved the sophistication of funding 
                                       higher education by providing student loans into aspirants who want to study overseas.
                                        In this informative article, we'll inform you about different aspects one needs to think
                                         about while obtaining a student loan. We'll also talk about what all records are needed, 
                                         just how much tuition charges and other expenses that a student loan insures.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6>Process for applying to get an instruction loan</h6>
                                      The application procedure for a Vast Majority of these US universities begins in October.
                                       So, an individual should always initiate the process of loan analysis, loan sanction and
                                        receiving solvency letters that prove as'evidence of funds' into the universities earlier
                                         this period. Loan disbursement may be achieved after getting the confirmation of entrance 
                                         and i20 in the college. The loan test can be achieved before the entrance is supported. 
                                         Since the entire process is time consuming, an individual should always begin early with 
                                         every exercise to be able to prevent the last minute rush. After submitting your software 
                                         to the institute, while awaiting the offer letter, then don't take a break and sit . This is
                                          the opportunity to do thorough study on loans provided by several banks. Check out the way to 
                                          select your lender for schooling loan to study overseas.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6>Evaluate the loans supplied by various banks on these parameters:</h6>
                                      <br/>
                                      <h6>Factors to Remember before applying for a instruction loan</h6>
                                      Prior to applying for a student loan an individual ought to have a very clear idea how much cash they need 
                                      and how much they will cover in their pocket. Other important factors to Think about Before applying for your loan:
                                      <br/>
                                      Documents needed for procuring a student loan whilst giving financing, banks also should confirm 
                                      that you're a real student (client ) and you've guaranteed admission in an established college, 
                                      sothey could evaluate your possibility of repaying your loan. Following is a listing of basic records,
                                       which are ordinarily demanded from the banks to process a instruction loan.

                                    </p>
                                    <br />
                                    <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Provide letter supplied by the college (evidence of entrance ).
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Loan application form (full ).
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;The estimated cost of this analysis (according to the I-20 form).
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Initial schooling certificates/mark sheets.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Records of qualifying examinations (GMAT, TOEFL).
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Residential Proof of pupil and co-borrower.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;IT yields of co-borrower.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; PAN card along with other identity signs of pupil and co-borrower.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Evidence of earnings using a co-borrowe.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Passport backup.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Information of assets and obligations of parents/co-obligants/guarantors.
                                        </li>
                                      </ul>{" "}
                                      <p style={{ color: "#212121" }}>
                                      NOTE: Requirement of records can differ in accordance with the banks' rules and regulations,
                                       therefore it's always recommended to look at the listing of the records required with the various lender.
                                       </p>
                                       <br/>
                                       <p style={{ color: "#212121" }}>
                                            Always be certain that you check besides the tuition fee that which other expenses are covered 
                                            on your loan strategy. There are several different things which you want to take good care. Now 
                                            many loan schemes are offered which cover several kinds of expenses aside from the tuition fee.
                                            </p>
                                            <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Fee payable to the school and hostel.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Evaluation, library, lab fee.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Purchase of books, gear, tools.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Caution deposit, construction finance, refundable deposit.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Traveling expenses.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Insurance premium for your pupil, if appropriate.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Buy of notebook (if needed for your class ).
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Other costs such as study tours, job, or thesis.
                                        </li>
                                      </ul>{" "}

                                      This interval varies from bank to bank and may continue to sometime after conclusion of this program.
                                      <br/>
                                      <p style={{ color: "#212121" }}>
                                      Loan Margin: Generally, banks don't offer the comprehensive amount i.e. 100 percent cash required to 
                                      finance the instruction. Vast majority of the public sector banks to issue 90 percent of their whole sum, 
                                      the remaining 10% needs to be organized by himself.
                                      </p>
                                      <br/>
                                      Most Indian banks require collateral as collateral so as to decrease the risk involved with paying off the charge.
                                      <br/>
                                      <p style={{ color: "#212121" }}>
                                      Sort of tools called security: Any liquid advantage rather stocks bonds, FD or some other immovable property 
                                      such as the home, any commercial property or land could be categorized as collateral. 1 important thing to 
                                      remember is that agricultural property isn't accepted as security.
                                      </p>

                                      <p style={{ color: "#212121" }}>
                                      <h6>Instruction loan with no security</h6>
                                      Instruction loan has turned into a major relief to people who wish to follow an excellent education but face 
                                      a large challenge whilst organizing the capital. For loansup to INR 7.5 lacs, parents or guardians are created 
                                      joint debtor and a third-party warranty are obtained. To get financing exceeding INR 7.5 lacs home or another
                                       advantage is needed as collateral. You might even contact India's top education loan suppliers through Shiksha Study Abroad.
                                    </p>
                                    
                                    <p style={{ color: "#212121" }}>
                                      <h6>Just how much can you borrow from security?</h6>
                                      If you would like to spend the loan together with your own property as security, in that circumstance, the lender's 
                                      representative will confirm your premises and prepare a report on if it could be mortgaged or not.
                                      <br/>
                                      In addition, the lender's representative will appraise your premises. According to the report, the amount of the loan will be accepted.
                                      <br/>
                                      In case the debtor is unable to pay off the loan amount no matter the conditions, in that scenario, 
                                      the guarantor retains the obligation to settle the comprehensive sum to banks.
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                      <h6>The overall eligibility criteria which are followed by each of the banks are:</h6>
                                      Banks consistently favor giving loans for task oriented classes.
                                        The applicant should have secured entrance at the overseas exchange
                                        The applicant has to be over age 18 decades or his/her parents might need to spend the loan
                                        Repayment
                                        The repayment of their student loan is usually compensated in equated monthly instalments (EMIs) 
                                        for a span of 15 years for several types of loans.
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                      <h6>Ability Certification</h6>
                                      Many universities need the pupils to submit a certification issued in their banks providing particulars of the patrons' financial capacity.
                                       It's done as a way to make certain that the patrons are financially capable of meeting the expenses of their pupil going for research.
                                       Indian Banks that offer loans to research at the USA.
                                       <br/>
                                       A number of those loan schemes provided by Indian banks for studying overseas are:
                                       <br/>
                                       In addition, it gives tax advantages to the borrower also it may be sanctioned before entrance too.
                                        A max of INR 20 lacs could be availed at a minimal rate of interest and it needs to be repaid over 15 decades. 
                                        Furthermore, if the amount of the loan is over INR 7.5 Lacs, subsequently debtors will need to provide collateral 
                                        as collateral. The repayment begins after a year of conclusion of this program.
                                        <br/>
                                        SBI International ED-VANTAGE Scheme: This loan could be availed by Indian pupils who want to go for 
                                        a full-time class overseas. Pupils can avail a loan of around INR 1.5 crore and need to repay it over 
                                        a span of 15 decades. The creditors will need to give security to secure that loan also might need to 
                                        begin repayment following 6 weeks of completion of this program.
                                        <br/>
                                        In the event the student wants to pursue his research in america, then the sum of the loan 
                                        will be dependent on the fee structure of the institute they've applied to. The highlight of
                                         the scheme is that it doesn't cost a prepayment penalty from the creditors and give insurance policy 
                                         too. The margin for your loan sum exceeding INR 4 Lacs is 15 percent on the loans required for studying overseas.

                                         <br/>
                                         Other than that, there are far more student loan providers in India for research overseas.
                                         <br/>
                                         Loans from NBFCs (Non-banking financial firms ) aside in the banks that are insolvent, student
                                          loans may likewise be deducted from non-banking fiscal companies/institutions (NBFCs). Non-banking 
                                          institutions may provide full tuition fees on the pupils. Borrowers will need to give security as 
                                          collateral to the banks since the amount of the loan is usually greater than INR 7.5 Lacs. A Few of 
                                          the NBFCs choices are:
                                          <br/>
                                          Charge: Credila is a portion of this renowned HDFC lender and gives loans to Indian students wanting to study overseas.
                                           The loans have been given at an aggressive floating interest rate and could be paid back within a span of a decade.
                                           <br/>
                                           Avanse: Section of this DHFL group, it's thought to be one of the very common non-banking monetary
                                            institutions in India. Student loans in Avanse pay tuition fees up to 100 percent and other expenditures. 
                                            Aside from that, the rates of interest are drifting along with the processing fee may go around 1 to 2% 
                                            of the amount of the loan.
                                            <br/>
                                            Global Student Loan Program (ISLP): This application is especially designed for global students who wish to research in the united states.

                                    </p>
       <br/>
       </div>
                                    ):<></>}

{showData=='CANADA'?(
  <div>
  <p style={{ color: "#212121" }}>
                                      <h4>
                                      Education Loan to Study in - Canada
                                      </h4>
                                      Price of studying in Canada is contingent on the college, class, and period of research and cost of living.
                                       Even though there are a great deal of great universities in India too, but superb career opportunities 
                                       and higher living standards drive pupils to elect for studying overseas. Not every aspiring student is 
                                       able to study and live in Canada. Although distinct financial aids and scholarships will also be available,
                                        but due to the fierce rivalry, not each student can avail them. In the following guide, we'll advise you 
                                        about each and every facet from eligibility to advance employing procedure to documents needed for obtaining 
                                        an education loan to research in Canada. Aside from that, we'll also notify you about what all costs are
                                         covered under the instruction loan.
                                         <br/>
                                         Obtaining a loan sanctioned isn't just dull but also time consuming, therefore, so as to avert any last-minute
                                         hurry, an individual should always begin applying for financing early. After obtaining the admit letter in the
                                         college, students get an notion of the entire expenditure including living and study. Calculate the amount you 
                                         want to fund and how much you're likely to spend out of your pocket. Do a little research before applying for
                                         financing and assess which banks are providing the best prices on loans. Additionally, check out the way to
                                         select your lender for schooling loan to study overseas.
                                    </p>
                                    <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Interest speed.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Processing charge.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Loan processing period.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Prepayment provisions and length.
                                        </li>
                                      </ul>{" "}
                                      <p style={{ color: "#212121" }}>
                                      After picking out the lender, fill out the application form and examine the demands of the bank.
                                       The inability to reveal sufficient funds may also cause rejection, so it's always suggested to
                                        approach at two-three banks to get loans. Contact India's top education loan suppliers through 
                                        Shiksha Study Abroad.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                    To be able to sanction the loan, banks additionally confirm the details provided by the candidates.
                                     So, there's a list of files that the applicant should submit to the banks. Here's a listing of the 
                                     files necessary while applying for your own instruction loan:
                                    </p>
                                    <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Passport size photos.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Academic files (certificates and markers ).
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Records of qualifying examinations (such as GMAT, IELTS).
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Resident evidence of the pupil and the guarantor.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Statements of debtors bank balances (past one year).
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Income evidence of this guarantor.
                                        </li>
                                      </ul>{" "}
                                      <p style={{ color: "#212121" }}>
                                      Notice: Requirement of files can differ in accordance with the banks'
                                       rules and regulations, therefore it's always recommended to look at 
                                       the listing of those files required with the various lender.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                    Banks also give loans for SPP applications. As banks constantly consider
                                     getting the sum back from the pupil looking for capital, they always want
                                      to disburse loans for job-oriented classes.
                                    </p>
                                    <br/>
                                    <p style={{ color: "#212121" }}>
                                    The loan applicant should be over age 18 decades or his/her parents might need to apply to your loan.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Repayment</h6>
                                      Repayment begins after a duration of one year or six weeks, whichever is sooner, following the conclusion
                                       of this class when the candidate gets the job. The loan amount must be paid back in 5 
                                       to 10 decades after the repayment begins.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                    This is only one of the most significant elements to remember when applying for an education loan. 
                                    Because there are a great deal of student loan schemes offered by banks, so it's always suggested 
                                    to check which strategy suits your requirements the best. Various costs are covered under different
                                     strategies, so one should check with the lender regarding what other expenses are covered in the 
                                     loan strategy besides tuition fee. The list of expenditures includes:
                                    </p>
                                    <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Traveling expenses.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Price of Purchasing books and other research material.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Laboratory charge.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Hostel charge.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Purchase of a notebook (If necessary for your course).
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Expenses for job work or research tours.
                                        </li>
                                      </ul>{" "}
                                      <p style={{ color: "#212121" }}>
                                      In the event the amount of the loan exceeds INR 7.5 lakh then concrete assets are 
                                      required as security security. Additionally, parents are needed to be more 
                                      co-borrowers for all of the instruction loans.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                    <h6>Tax Gains</h6>
                                    Tax advantages can be maintained on the interest on the whole amount of the loan under section 80E.
                                     What's more, the tax advantages can be maintained up to seven successive years after the repayment begins.
                                     <br/>
                                     Loans are blessing for brilliant pupils who can earn large in life but because of increasing cost
                                      aren't able to acquire the top quality instruction. But, it's suggested to thoroughly assess
                                       the terms of the loan arrangement.
                                    </p>
                                    </div>
):<></>}

{showData=='UK'?(  
  <>  
  <p style={{ color: "#212121" }}>
                                        <h4>Education Loan to Study in the UK</h4>
                                        Only getting a admit letter from your fantasy global university isn't sufficient 
                                        to set your career in motion. Before the tedious application process of submitting 
                                        files, tests scores, along with the wait to obtain an approval, comes the actual deal
                                        of organizing funds. An individual can be a fantastic student and also be eligible 
                                        for grants or scholarships, yet studying overseas can cut a huge hole in your pocket.
                                        While scholarships may pay for 25-50percent of their research cost, the rest of the
                                        expenses have to be taken care of on a cash-in-hand foundation. This is where education
                                        loans play a considerable part in determining your future.
                                        </p>

                                        <p style={{ color: "#212121" }}>
                                        <h6>Researching in the UK having an instruction loan</h6>
                                        Nowadays, many Indians are choosing the United Kingdom because their favorite study 
                                        destination due to its schooling system and home of the very best school in their own 
                                        universities. However, the charge arrangement alongside other expenses like lodging, 
                                        travelling, food, etc create the United Kingdom among the costliest nations to study 
                                        overseas. Can it be an undergraduate diploma or a master's degree, the applicant should 
                                        exhibit a well-structured financing program whilst applying to UK universities. 
                                        A particular amount of liquid resources will need to be revealed from the financial 
                                        statement together with loan records or scholarship letters. It needs to be sufficient
                                        to pay the tuition fees and living cost for the first year of research in the nation.
                                        Post that, an individual can function part time to pay his/her daily expenditures and 
                                        tuition fees.
                                        <br/>
                                        To encourage the fantasies of countless students who wish to study in overseas in 
                                        countries such as the UK, several Indian banks, and financial institutions assist 
                                        Indian students with their financing. From providing a lot of financial assistance 
                                        to the lowest rate of interest and flexible repayment period, many banks and financial 
                                        institutions compete with one another to offer aspirants with the most straightforward 
                                        expertise in attaining their dreams.
                                        <br/>
                                        While an superb academic record is essential for applying to get an education loan,
                                        an offer letter in some of the reputed schools / universities also assist in hastening
                                        the speed of the entire approval procedure. Here's a listing of the top ten universities
                                        in the UK which you can aim for, no matter programmes or course:
                                        <br/>
                                        The most important purpose of a school loan would be to help school students in fulfilling 
                                        their dream of studying within their preferred university by giving financial help. The applicant
                                        should begin applying to get an education loan the moment he/she gets the unconditional offer letter
                                        from their preferred university. It's so advised, since the lender or the personal financial institution's
                                        loan sanctioning is period taking owing to several requirements and documentation procedure. Additionally,
                                        the amount of the loan won't be sanctioned from the associations until and unless you've got a proof of 
                                        approval from the college (unconditional offer letter) and consequently meet all of the banks' terms and 
                                        conditions. As all the fiscal thing will possess their own stipulations, it's in your favor to perform a 
                                        comprehensive study for their needs, maximum loan amount permitted, rate of interest, repayment period and
                                        so forth.
                                        <br/>
                                        That Will Help You Begin, here are some initiatives with a couple of banks Which Are specifically tailored 
                                        to aspirants Searching for financial aid in studying in the UK:
                                        <br/>
                                        The Bank of Baroda provides an education loan initiative known as'Baroda Scholar Loan' that helps Indian 
                                        pupils because of their dream of studying overseas. This initiative is available to pupils choosing an 
                                        undergraduate or postgraduate programme, doctoral level, or some other technical job-oriented class in
                                        reputed overseas universities. The lender makes it possible for a maximum loan quantity of Rs.60 lakhs 
                                        in the event the college drops under List-A of the strategy and Rs.40 Lakhs in case the university drops 
                                        under List-B. Collateral security is necessary for financing quantity of Rs.7.50 Lakhs or over.
                                        <br/>
                                        'Star Education Loan'- tailor-made for Indian pupils, it's among the famed education loan initiatives with 
                                        a government lender. Available for pupils opting for undergraduate and master's level, this strategy especially
                                        helps courses provided by institutes such as CIMA-London, UK.
                                        <br/>
                                        This expression loan interest MCLR+2percent for boys (marginal cost of funding based lending rate) and MCLR+1.5percent 
                                        for women. Moreover, they could dish out concession programs around the rate of interest for specific cases throughout
                                        the repayment period, supplying around 1 percent of concession. The further advantage of this strategy is that it also
                                        provides life insurance coverage to the creditors.
                                        <br/>
                                        Famously called HDFC Credila, in partnership with Credila, HDFC provides upto Rs.20 lakhs education loan for students
                                        looking to study overseas. With flexible loan terms and low-interest prices, in addition, it provides tax benefits 
                                        for the creditors under Section 80 (E) of the Income Tax Act.
                                        <br/>
                                        SBI includes two loan strategies for students aspiring to study in the united kingdom, specifically, SBI Student Loan 
                                        and SBI Global ED-VANTAGE. Underneath the student loan scheme, the lender provides upto Rs.20 Lakhs maximum loan amount
                                        while below ED-VANTAGE, you can get funded upto Rs.1 Crore.
                                        <br/>
                                        While all each of those financial and banking institutions has their own documentation requirements and process, 
                                        here is a generic of a listing of files you can keep prepared before-hand:

                                        </p>
                                        <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Duly full mortgage application variant of the Specific lender.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Proof old.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;2 passport-sized photos.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;For salaried people, evidence of earnings (salary slips or Form 16 etc).
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Additionally, latest salary slip and income tax evaluation order of the past two decades.
                                        </li>

                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;All monetary supporting files.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Borrower's past six months' bank accounts statement.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Co-borrower's short statement of assets and obligations.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Copies of this Currency permit.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Marksheet from past qualifying exam in India ( faculty or graduate research ).
                                        </li>

                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Unconditional offer letter/ evidence of entry from the college.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;List of anticipated expenses for the specified class.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Copies of Scholarship letter, when allowed any.
                                        </li>
                                      </ul>{" "}
</>
):<></>}

{showData=='AUSTRALIA'?(    
  <>
 <p style={{ color: "#212121" }}>
                                    <h4>Education Loan to Study in Australia</h4>
                                    Often called a top worldwide powerhouse of schooling, Australia provides a number of the planet's 
                                    finest educators and amenities to international students searching for quality instruction. Home 
                                    to a number of the very best universities in the world, students may pick from over 22,000 classes 
                                    across 1,100 associations in Australia. Students traveling to Australia for the purpose of higher
                                     education might anticipate invention, creativity, and independent thinking deeply embedded into 
                                     their own center syllabus.
                                     <br/>
                                     Famous for the Kangaroos, Koalas and open areas of Outback Bush, Australia as a nation has a whole 
                                     lot more to offer you. A significant favourite study destination for global students, Australia 
                                     retains the next most popular study destination place for global students following the United
                                      States of America and the uk. A top quality of schooling, friendly natives, and ethnic diversity 
                                      are a few of the facets which make Australia a personal favorite among International students 
                                      looking to study overseas.
                                      <br/>
                                      Australia enjoys one of the greatest standards of living on the planet. International
                                      students will have the chance to work part time to facilitate their home expenses. 
                                      International students are entitled to work 20 hours each week. This part time job 
                                      facility won't just help them facilitate their living costs but also permit them to
                                      obtain important work experience in their area of interest. Just like most universities,
                                      international students may also gain from the a variety of scholarship programs recorded
                                      on the official site of universities, therefore lowering their tuition prices farther. 
                                      As an global student in Australia, you can expect to develop, live and learn at a youthful, 
                                      welcoming nation filled with opportunities.
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                    <h6>Procedure for Implementing For Instruction Loan</h6>
                                    After students have secured entrance into the college of their choice, their next major 
                                    obstacle is to organize the capital needed for their educational expenses. The expense of 
                                    studying in Australia is in par with other leading destinations like the united states and
                                    UK, which makes it a costly investment when compared with researching within India. Students
                                    and families seeking to avail an Education Loan will be delighted to know that in India many
                                    Banking and Non-Banking associations provide Education Loan in a low interest rate for respectable
                                    pupils to pursue their higher education in Australia.
                                     <br/>
                                     Applying for an Education Loan has come a very long way and now pupils and their parents
                                     seeking to apply for an Education Loan may do this from the comfort of their property. 
                                     Nowadays applying for an Instruction loan is straightforward and each student should 
                                     make the most of the exact same and research overseas. But prior to applying for an
                                     Education Loan, then there are particular details that have to be considered.
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                    <h6>Top Colleges in Australia</h6>
                                    Qualification standards to apply for an education loan to study in Australia
                                    <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;The student Must be a citizen of India.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;The applicant must have registered for a class That's Been recognized by
                                           the financing institution (occupation oriented professional diploma, technical degree,
                                            and diploma classes ).
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;List of Records Necessary for a Education Loan to Australia.
                                        </li>
                                      </ul>{" "}
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                    Nationalized banks like State Bank of India (SBI) supplies Education Loan up to Rs. 30 Lakhs
                                    to deserving students who would like to visit Australia for higher research. But prior to the 
                                    lender can process the amount of the loan, there's a listing of files which have to be supplied
                                    by the debtor (student).
                                    <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Loan application form filled out in complete.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Records on the Expense of research.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Identification evidence.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Home proof.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;PAN Information of this candidate.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Guarantor/ / co-borrower/ pupil's bank statements.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Guarantor/ / co-borrower/ pupil's statement of resources.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Guarantor/ co-borrower/ pupil's evidence of income.
                                        </li>
                                      </ul>{" "}
                                        </p>

                                        <p style={{ color: "#212121" }}>
                                            <h6>Expenses covered under the loan comprise</h6>
                                            The loan warranted by an institution could only be for education 
                                            purpose, not cover personal expenses. The loan amount sanctioned will Look after.
                                    <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Caution deposit cash.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Traveling expenses.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Study tours and job work throughout the year.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Purchase of publications, notebook, and static.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Other reasonable expenses which would have to successfully complete the Program.
                                        </li>
                                      </ul>{" "}
                                      <h6>Repayment</h6>
                                      Pupils who have applied for a loan numbers that range from 4-7.5 lakhs have 10 years 
                                      to repay the whole volume. And pupils who were sanctioned amounts over 7.5 lakhs are
                                       awarded 20 years to repay the whole volume. However, students should comprehend the 
                                       moratorium time changes for different loan supplying associations.
                                        </p>

                                            <br/>
</>
):<></>}

{showData=='IRELAND'?(
  <>    
 <p style={{ color: "#212121" }}>
 <h4>Ireland:</h4>
                                  Its capital and largest city is Dublin.
                                  <br/>
                                  There are lots of explanations. To begin with, it's a friendly state; actually, it was called the friendliest nation by Lonely Planet in 2010 and has been rated as the 13th most tranquil area from the International Peace Index at 2014.
                                  <br/>
                                  Secondly, it has several applications for overseas students. Third, individuals speak English. And even more significant, it's dedicated to providing a fantastic educational system. In reality, in 2011, it had been established the National Strategy for Higher Education that attempts to alter this business to supply a more varied and meaningful experience which joins students efficiently with labour field requirements.
                                  <br/>
                                  These provide a vast selection of classes to choose from Colleges to Doctorate levels. Every of those universities has an entrance criterion for overseas a pupil which depends upon English proficiency and the federal exam.
                                  
                              </p>

                              <p style={{ color: "#212121" }}>
                                  <h6>The Faculties are:</h6>
                                  By way of instance, if you're a Non-EU student considering an undergraduate program the fee may go from $9,850 to $55,000 on apps more costly like health & medicine sciences. Moreover, tuition prices can vary, which means you need to consult the University that you opt for the present price.
                                  <br/>
                                  On the flip side, if you're an EU student, then you may qualify for free charges unless you're repeating a session or following a second undergraduate class. Within this scenario, you'll need to pay a donation of $3,000 each year.
                                      The selection criteria rely upon those associations.
                                      <br/>
                                      It boosts education overseas and also the exchange of expertise and knowledge. With this strategy, you might have a complete level pupil or even a Master's Degree Loan.
                                      <br/>
                                      Moreover, the Irish authorities supplies Ireland's Fellowship Program. They supply numerous classes to students from developing nations. To know if your state is eligible for a number of those classes, please assess Ireland's Fellowship Program official website (https://www.irishaidfellowships.ie/)
                                  <br/>
                                  To learn more about scholarships, check out the HEA site (https://hea.ie/funding-governance-performance/funding/student-finance/other-finance-bursaries-scholarships/). But if you would like to help to some particular University, you are able to look on the scholarships available and the standards to register in it on its own official page.

                              </p>

                              <p style={{ color: "#212121" }}>
                                  <h6>Price of Living in Ireland</h6>
                                  It is crucial that you calculate your prices before beginning the visa or college procedure.
                                   Even in the event that you buy a scholarship, then there'll be additional expenses. Your financial 
                                   plan will depend on the way you live and the town you select. And, should you require a visa, you'll
                                    have to prove you've got the quantity of money required to research in Ireland.
                                  <br/>
                                  Between those expenses monthly, you want to think about books and course materials, $70; meals, $171,
                                   traveling, $135, as well as many others.
                                  <br/>
                                  In lodging conditions, this institute computes you'll need $427 monthly. Even though there are plenty 
                                  of choices, you might select on-campus, rented accommodation or a sponsor family. The first choice, on-campus,
                                   is dependent on accessibility and it is a great deal more expensive. A leased location requires you to cover 
                                   a month beforehand plus a deposit of one month. Remember you have to follow along with the duration of this
                                    lease and provide a month of note to depart and regain your deposit cash. And lastly, you might reside with 
                                    a host family that provides you freedom but at precisely the exact same time the relaxation of a house and 
                                    the aid of your hosts.
                                  <br/>
                                  On the flip side, if you are not an EU student, you will need health insurance that's also necessary for the
                                   visa program. There are a range of insurance companies you may select from, as an instance, Irish Life Health,
                                    VHI health care, and many others.

                              </p>

                              <p style={{ color: "#212121" }}>
                                  <h6>Internships & Company Placements at Ireland</h6>
                                  students may take internships as part of this app they register in. This internship can continue
                                   half of the period of this program period. If you're in a 4-year livelihood, then you're permitted 
                                   to have a 2-year internship. The University or Institute is responsible for setting this internship
                                    and reassuring it is significant to this program that the student is carrying. For the remainder of
                                     the calendar year, they're permitted to work for 20 hours each week.
                                  <br/>
                                  On the flip side, Ireland prompts overseas students who have studied in one of Ireland's 
                                  applications to continue their livelihood in the nation. That is why you've got many chances 
                                  to remain in the nation. An educational facility will be able to help you to find an area at 
                                  a business regarding the career you've selected.
                              </p>

                              <p style={{ color: "#212121" }}>
                                  <h6>Working in Ireland</h6>
                                  you may opt to remain in the county once you complete your research to try to find employment.
                                   In fact, 51 percent of pupils who get a greater & post quality diploma are functioning in Ireland, 
                                   and 37 percent of honor bachelor level finds a job in the nation.
                                  <br/>
                                  You'll have to read carefully the prerequisites along with the fee. You can locate 
                                  this information in the official website http://www.inis.gov.ie/en/INIS/Pages/Irish%20Visa%20Information
                                  <br/>
                                  After comprehending the entire procedure and having the prerequisites, you can produce 
                                  your program in https://www.visas.inis.gov.ie/AVATS/OnlineHome.aspx

                              </p>
                              <br/>
                              </>
):<></>}

{showData=='GERMANY'?(    
  <>
 <p style={{ color: "#212121" }}>
                                          <h4>
                                          Education loan to Study in Germany 
                                          </h4>
                                          Low tuition prices, fantastic quality of living, superb quality education,
                                          security, and safety are a few reason which make Germany an attractive study 
                                          destination for many pupils around the world. There's something for everybody 
                                          in Germany. When it's the big city buzz, famous architecture, historical sights,
                                          packed shopping roads or yummy cuisine, aside from being a significant academic hub,
                                          Germany is also a famous tourist destination globally.
                                          <br/>
                                          International students preferring to pursue their higher education could expect to
                                          experience cultural diversity, friendly sailors and also a high quality of schooling.
                                          A significant instruction preferred, pupils would be delighted to know that nearly
                                          all people in Germany have experienced their tuition fees waived off. The pupils
                                          would be asked to pay only an administrative fee at the start of each term. The 
                                          living costs in Germany is deemed moderate, thus placing less fiscal strain on 
                                          Indian students wanting to study overseas.
                                      </p>
                                      <p style={{ color: "#212121" }}>
                                      To make residing much farther economical, international students will have the chance
                                      to work part time to facilitate their home expenses. International students are entitled 
                                      to work 20 hours each week. This part time job facility won't just help them facilitate 
                                      their living costs but also permit them to obtain important work experience in their area 
                                      of interest. Just like most universities, international students may also gain from the a 
                                      variety of scholarship programs recorded on the official site of universities or www.daad.de. 
                                      Germany is home to a world-recognized schooling system, superb business interface, an advanced 
                                      and research-based surroundings and multi-cultural prospects which produce the nation a superb 
                                      alternative for students looking to study overseas.
                                      </p>

                                      <p style={{ color: "#212121" }}>
                                          <h6>Procedure for Implementing For an Education Loan</h6>
                                          Procuring an education loan for Germany is as straightforward as obtaining an education 
                                          loan for almost any other study overseas destinations. The practice is exactly the same, 
                                          with easy modifications. Once pupils have secured entrance into the college of their choice,
                                          their second major hurdle is to organize the capital needed for their educational expenses.
                                          The expense of studying in the nation is broadly cost-effective, with pupils needing to pay 
                                          no tuition fees because of its undergraduate classes and instead minimal tuition prices for 
                                          its grad classes.
                                      </p>

                                      <p style={{ color: "#212121" }}>
                                      Pupils and families seeking to avail that the student loan will be pleased to know that at India
                                      many Banking and Non-Banking associations provide education loans in a very low interest rate for
                                      commendable pupils to pursue their higher education abroad. Applying for a student loan has 
                                      evolved since its debut and pupils and their parents seeking to apply for your Loan may do this 
                                      readily. But prior to applying for your loan, then there are particular particulars that have to 
                                      be considered. Occasionally to acquire financing might require security as collateral but that is 
                                      purely on a case to case basis. In the majority of the instances, there would most likely be a co-applicant
                                      that could become your parent, sibling, in-laws or partner.
                                      </p>

                                      <p style={{ color: "#212121" }}>
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;The student Must be a citizen of India.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;The student must have obtained admission to a college / course That's recognized by the financing institution and qualified for grant.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;The student should have attained age 18 decades or his/her parents must apply to your loan.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;List of files required to get an education loan to Germany.
                                        </li>
                                      </ul>{" "}
                                      </p>

                                      <p style={{ color: "#212121" }}>
                                      Nationalized banks like State Bank of India (SBI) supplies Education Loan to deserving students who want 
                                      to visit Germany for higher research. But prior to the lender can process the amount of the loan, there's
                                      a listing of files which have to be supplied by the borrower.
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Letter of entry in the college/University.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Records on the Expense of research.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Identification evidence.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Home proof.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;PAN Particulars of the debtor.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Guarantor/co-borrower's/pupil's bank statements.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Guarantor/co-borrower's/pupil's statement of resources.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Guarantor/co-borrower's/pupil's evidence of income.
                                        </li>
                                      </ul>{" "}
                                      </p>

                                      <p style={{ color: "#212121" }}>
                                      The university, in the time of entrance, would supply you with records
                                      that would obviously mention the fee in addition to the whole sum necessary
                                      for completion of their planned class. Please be aware that while colleges 
                                      in Germany don't charge a tuition fee, there are additional expenses which students 
                                      encounter during their stay in the nation, and that are cared off beneath the amount 
                                      of the loan. On the other hand, the loan sanctioned by an institution could be solely 
                                      for education purpose, not cover personal expenses. The loan amount sanctioned would care for:
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Caution deposit cash (collateral ).
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Traveling expenses.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Study tours and job work throughout the year.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Purchase of publications, notebook, and static.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Other reasonable expenses which would have to successfully complete the Program.
                                        </li>
                                      </ul>{" "}
                                      </p>
                                      <p style={{ color: "#212121" }}>
                                      Instruction in Germany is a really serious affair and they've all calculated to the last cent. 
                                      Each year, based on the prevailing economic conditions in addition to the applicable fees arrangement,
                                      the college would ask that a monthly amount is supplied for the student to ease his living expenses 
                                      from the nation in addition to finishing his schooling. This could include your lodging, living costs,
                                       as well as travel pass expenses.
                                      </p>

                                      <p style={{ color: "#212121" }}>
                                      After pupils hit Germany, they must start a Student Loan Account using a local bank at which they have 
                                      to follow the'Blocked Amount' for the whole calendar year. Each month they'd be permitted to draw the 
                                      sanctioned monthly sum to pay their monthly expenses. This is a compulsory practice related to all pupils 
                                      who opt to pursue higher education in Germany.
                                      </p>

                                      <p style={{ color: "#212121" }}>
                                          <h6>Repayment</h6>
                                          As stated before, international students will have the chance to work part time to facilitate their 
                                          home expenses. International students will be qualified to work 20 hours each week. Additionally, 
                                          after the conclusion of the overall course length, pupils have the choice of requesting an 18-month
                                          Residence Permit to start looking for work which matches their area of eligibility. During those 18
                                          months, they could take up any type of job to support themselves and finance their job hunt.
                                      </p>

                                      <p style={{ color: "#212121" }}>
                                      Pupils who have applied to get an education loan from a nationalized bank are needed to begin the repayment 
                                      procedure after the conclusion of this program period and moratorium interval (Repayment starts one year
                                       following the program completion or 6 weeks after procuring a project, whichever is sooner ). However, 
                                       students should comprehend the moratorium time changes for different loan supplying associations.
                                      </p>

                                 </>
):<></>}

{showData=='FRANCE'?(    
  <>
 <p style={{ color: "#212121" }}>
 <h4>France:</h4>
                                    France is frequently recorded in the top 5 international destinations for global students. With quite low tuition fees and more than 1,000 English-taught programs it's no surprise that France is popular. There's an extensive number of cultural riches and societal diversity in France, together with innumerable monuments and museums like the Eiffel Tower or the Louvre Palace, both a must-see for anybody visiting France.
                                    <ul style={{ marginLeft: 20 }}>
                                <li>
                                  <i class="fa fa-check-circle"></i>
                                  &nbsp;Non-EU Student Tuition Fees.
                                </li>
                                <li>
                                  <i class="fa fa-check-circle"></i>
                                  &nbsp;Certification programs: 2,770 EUR per instructional.
                                </li>
                                <li>
                                  <i class="fa fa-check-circle"></i>
                                  &nbsp;Master plans: 3,770 EUR per academic year.
                                </li>
                                <li>
                                  <i class="fa fa-check-circle"></i>
                                  &nbsp;Master plans: 3,770 EUR per academic year.
                                </li>
                              </ul>{" "}
                              Tuition fees at private higher education institutions vary from 3,000 EUR into 20,000 EUR annually. Consult with the University of Business School of your choice to validate the tuition fees of this program you're interested in.

                                    </p>
                                    <p style={{ color: "#212121" }}>
                                    <h6>Scholarships to Study in France</h6>
                                    Additionally, in France, scholarships are widespread and accessible from many different institutions to many different people based on chosen criteria. The French Ministry of Higher education and research, as an instance, provides scholarship aid for global students, according to a societal norm.
                                    <br/>
                                    For a pupil from the European Union, you could also be qualified to apply for an Erasmus + pupil assistance.
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                    <h6>Prices of living in France</h6>
                                    the price of living in France ranges from 800 / 1,800 EUR a month, including pupil lodging. Cities like Paris are in the end of this spectrum, whereas Lyon, Nantes or even Toulouse are in the lower end of this spectrum.

                                    </p>

                                    <p style={{ color: "#212121" }}>
                                    <h6>Internships & Company Placements in France</h6>
                                    France isn't merely a favorite destination for both tourists and global students who wish to study overseas but also for young people seeking to acquire work experience through an internship or business placement called a point in French.
                                    <br/>
                                    If you're a student in France, you might first wish to contact the coordinator of your program, who could have the ability to help in finding you a suitable business positioning. You might even try your luck on the internet to locate an internship based on the area or the subject of study. Some of the very common French sites which list internships, comprise AdioStage and Stage.fr.
                                    Though you may be lucky to obtain an English-speaking business that is going to employ you, it's wise and advisable to be able to speak French to a conversational level.
                                    <h6>Working at France</h6>   
                                    As a foreign student, you're qualified to work part-time to get as many as 20 hours each week.                                        
                                    </p>
                                    </>
):<></>}

{showData=='SWEDEN'?(    
  <>
  <p style={{ color: "#212121" }}>
               <h4>Sweden:</h4>
                                                Researching in Sweden means you'll be located in the north of Europe and subjected to a multicultural society that's proven to be more welcoming.
                                                Sweden is frequently known as the funding of creation of Europe, together with several large brands and world-famous family titles coming out of Sweden, such as IKEA, H&M among many others.
                                                <br/>
                                                A listing of Colleges in Sweden would not be complete without the following associations:
                                                <br/>
                                                English educated programs comprise both Bachelor applications and Master applications.
                                            </p>

                                            <p style={{ color: "#212121" }}>
                                                <h6>Tuition Fees in Sweden</h6>
                                                The most appealing characteristic of studying in Sweden is the fact that it may be liberated. We state it could be, since not every pupil can make the most of this tuition-free system in Swedish Universities.
                                                <br/>
                                                Since 2011, pupils from beyond those EU/EEA and Switzerland must pay.
                                                <br/>

                                            </p>

                                            <p style={{ color: "#212121" }}>
                                                <h6>Scholarships to Study in Sweden</h6>
                                                The Swedish Institute details an Assortment of scholarship opportunities categorized by the area of research in Addition to the viability of the pupil. The Erasmus traineeship mobility programme is just another organization that helps pupils in Sweden to find internships or business placements.

                                                <br/>
                                                
                                            </p>

                                            <p style={{ color: "#212121" }}>
                                                <h6>Prices of living in Sweden</h6>
                                                Average living costs in Sweden are approximately 8,000 SEK (800 EUR) per month, such as leasing on lodging. Obviously, this may change based upon the place, as living prices from the capital of Sweden, Stockholm, are greater than in smaller towns.

                                                <br/>
                                            </p>

                                            <p style={{ color: "#212121" }}>
                                                <h6>Internships & Company Placements at Sweden</h6>
                                                Locating an internship in Sweden (called a praktik at Swedish) is often best when eased directly through the careers service office in your University.

                                                <br/>
                                            </p>

                                            <p style={{ color: "#212121" }}>
                                                <h6>Working in Sweden</h6>
                                                As an global student you'll be legally eligible to look for work as you study. Unlike in other European states, there's also no legal restriction on the amount of hours you'll be permitted to operate in Sweden, as you're studying.
                                                <br/>
                                                Being fluent in the language is surely a major plus when searching for work in Sweden but it might not be a comprehensive barrier if Swedish isn't your forte.
                                                <br/>
                                                There are an infinite number of foreign businesses based in Sweden that highly appreciate other languages and might even appreciate your native language as a valuable advantage.
                                                <br/>
                                                To acquire this residence permit, you may ned to show that you can finance your research and life in Sweden. Besides this you'll have to demonstrate a valid passport, be approved as a full-time pupil at an institution of high education and reveal that you hold a valid insurance coverage.

                                                <br/>
                                            </p>
                                            </>
):<></>}

{showData=='NETHERLAND'?(    
  <>
 <p style={{ color: "#212121" }}>
 <h4>Netherlands or Holland</h4>
                                The Kingdom of the Netherlands, frequently Known as Holland is a small state in the northwest of Europe. The fact that approximately 95 percent of the Dutch people speak English, which makes it an amazing destination for global students.
                                <br/>
                                Holland is regarded as among the safest areas on earth and ranks at the top 10 funniest states also.Holland has many high-ranking Universities. These include:
                                <br/>
                                Additionally, there Are numerous Small Business Schools in the Netherlands, such as:
                                <br/>
                                There are many Universities in Holland offering programs taught entirely in English. As of 2019, you will find only over 2,100 applications at Bachelor, Master and PhD degree that follow instruction in English.
                                <br/>
                                Webster University is currently the sole American University in Holland. This is a personal US accredited University that enables students to pursue a Master or Bachelor Program in English.
                                <br/>
                                Tuition fees are very fair in Holland. Students from the European Union, Switzerland or Surinam pay only over 2,000 EUR per year. Non-EU pupils pay between 6,000 EUR and 15,000 EUR annually to get a Bachelor program or 8,000 EUR to 20,000 EUR annually for a Master program. Precise tuition prices for a particular program are located on the official site StudyFinder.nl.
                                <br/>
                                Detecting a scholarship to study in Holland is made simple by a government initiative which lists dozens of upgraded scholarship and grant opportunities in their site .
                            </p>

                            <p style={{ color: "#212121" }}>
                                <h6>Prices of living in Holland</h6>
                                The price of living in Holland averages between 600 - 800 EUR a month. Add to that about 200 EUR a month for tuition prices and you've got an average budget of 800 - 1,000 EUR a month to pay all of your expenses. An area prices anywhere between 300 - 600 EUR a month. Groceries only under 200 EUR and entertainment or clothes are insured by approximately 100 EUR a month.

                            </p>

                            <p style={{ color: "#212121" }}>
                                <h6>Internships & Company Placements at Holland</h6>
                                An internship in the Netherlands is popularly called a"stage". Internships from the Netherlands are just allowed for applicants registered at an education institution for a student. Most colleges and Faculties assist students with placements in companies in Holland.
                                <br/>
                                Please note that there are gaps in work license, internship remuneration and social security deductions which will depend on in the event that you're pursuing an internship as part of a research program or research abroad application.
                                <br/>
                                If you're applying for an internship as part of a research program in Holland, you may be a resident in Holland, so there's absolutely not any requirement for any extra license. Additionally, there are no limitations on payment, but you'll be asked to pay tax as well as your employer should include social security contributions, unless the payment is just a reimbursement for state travel expenses.
                                <br/>
                                If you're a participating in a study abroad program, it's still possible to perform an internship at Holland, but you have to be aware of particular specifics: Unless you're an EU/EEC or national, along with your internship is going to be 90 days or less then your employer will be asked to submit an application for a work permit on your behalf. If your internship will survive over 90 days, you may require a residence permit. If your stay at Holland is less than 6 weeks and you're spending at least 50 percent of the time around the internship, and then you could be compensated tax-free. Those staying more than 90 days will often have a part of their wages deducted as taxation.
                                <br/>
                                For more interesting strategies and suggestions on regulations concerning internships from Holland, please see the Work placement business and interns segment on the Netherlands Enterprise Agency site.
                                <br/>                                                
                            </p>

                            <p style={{ color: "#212121" }}>
                                <h6>Working in Holland</h6>
                                International students in Holland are lawfully eligible to work while they study in the nation. Non-EU or non-EEA pupil will take a work permit. An employer can ask for that for your benefit. This will let you work 10 hours each week throughout the academic year; throughout June, July and August you'll be permitted to operate fulltime.
                                <br/>
                                Whether you require a student visa or to not research in Holland depends upon your native state. For EU citizens and taxpayers in Australia, Canada, Japan, Monaco, New Zealand, South Korea, the US or even the Vatican City State, There Is Absolutely No requirement to get a student visa. Other nationals are expected to submit an application for a student visa.
                                <br/>
                                The University or higher education institution in which you want to research will ask information from the Dutch Immigration Service (IND) with respect to a MVV program. If the information is positive, then you are able to apply to your true MVV in the Dutch Embassy or Consulate into your home country.
                                <br/>
                                Upon entrance into the Netherlands, pupils are expected to enroll at the local immigration authority office in addition to the municipality where they intend to live. In the end, a pupil must then make an application for a residence permit (VRR). Even though the VRR is valid for the length of your research, you'll have to make at least 50 percent of the credits needed per year.
                                <br/>
                                To be able to apply for a student loan, you'll require a valid passport in addition to evidence of funds to prove you could support yourself throughout your stay. A letter confirming approval at an institution of high education and legitimate medical insurance will also be required.

                            </p>

                            <br/>
                            </>
):<></>}
                                    </div>
                                  </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                              </div>           
                                                       </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area default-padding">
        <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="row" style={{ display: "block" }}>
            <div className="about-items">
              <div className="col-md-12">
                <div className="weekly-top-items">
                  <div className="top-courses" style={{ paddingRight: 0 }}>
                    <div className="heading" style={{ paddingBottom: 15 }}>
                      <h4 style={{ color: "#3f8ccb" }}>
                        ACCREDITATIONS AND MEMBERSHIPS <strong></strong>
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/EAIE.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/ICEF.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/NAFSA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/QISAN.jpeg"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIRC.gif"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIEA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
