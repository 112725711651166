import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
import Footer from "./Footer";
import {postDataAndImage,postData} from '../FetchServices';

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";
import { CenterFocusStrong } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    // margin: 'center',
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
  
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [messages, setMessages] = React.useState("");
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus, setStatus] = React.useState("");
  const[getList, setList]=React.useState([]);
  const[getTempList, setTempList]=React.useState([]);
  const[getgraduateList, setGraduateList]=React.useState([]);
  const[getgraduateTempList, setGraduateTempList]=React.useState([]);
  const[getdoctoralList, setDoctoralList]=React.useState([]);
  const[getdoctoralTempList, setDoctoralTempList]=React.useState([]);
  const[getCertificateDiplomaList, setCertificateDiplomaList]=React.useState([]);
  const[getCertificateDiplomaTempList, setCertificateDiplomaTempList]=React.useState([])
  const [majorsData, setMajorsData] = React.useState([]);



  const handlePicture = (event) => {
    setPicturePath(URL.createObjectURL(event.target.files[0]));
    setPicture(event.target.files[0]);
  };

  const handleVerifyPassword = (e) => {
    setVerifyPassword(e.target.value);
    if (password === e.target.value) {
      setStatus("Password Matched");
    } else {
      setStatus("Password not matched");
    }
  };

  const UndergraduateDataList=async(option)=>{
    if(props.location.state){
     var body={'course_type':props.location.state.option}
    }else{
     var body={'course_type':0}
    }
     let list= await postData('abroad/fetchUndergraduateData',body);
   setList(list);
   setTempList(list);
   // console.log(getList);
 }


  const handleCourse = (id) => {
    // alert("hello next Page");
      props.history.push({
        pathname: "/DisplayCourses",
        state: {
          id: id,
        }
      });
    // console.log(event.currentTarget);
  };

  React.useEffect (function(){
    UndergraduateDataList()
  },[])


  const GraduateDataList=async(option)=>{
    if(props.location.state){
      var body={'course_type':props.location.state.option}
    }else{
      var body={'course_type':1}
    }
    let list= await postData('abroad/fetchgraduateData',body);
    setGraduateList(list);
    setGraduateList(list);
    // console.log(getList);
  }
/*-------------pass id as url------------------ */
const handleGraduateCourse = (id) => {
  // alert("hello next Page");
    props.history.push({
      pathname: "/DisplayCourses",
      state: {
        id: id,
      }
    });
  // console.log(event.currentTarget);
};

   React.useEffect (function(){
    GraduateDataList()
    // console.log(props);
   },[])

   const DoctoralDataList=async(option)=>{
    if(props.location.state){
      var body={'course_type':props.location.state.option}
    }
     else{
      var body={'course_type':2}
     }
   let list=await postData('abroad/fetchDoctoralData',body);
   setDoctoralList(list);
   setDoctoralTempList(list);
  }
  
  /*-------------pass id as url------------------ */
const handleDoctoralCourse = (id) => {
  // alert("hello next Page");
    props.history.push({
      pathname: "/DisplayCourses",
      state: {
        id: id,
      }
    });
  // console.log(event.currentTarget);
};


  React.useEffect(function(){
    DoctoralDataList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const CertificateList=async(option)=>{
    if(props.location.state){
      var body={'course_type':props.location.state.option}
    }
     else{
      var body={'course_type':3}
     }
    let list= await postData('abroad/fetchcetificateData',body);
    setCertificateDiplomaList(list);
    setCertificateDiplomaTempList(list);
    // console.log(getList);
  }
/*-------------pass id as url------------------ */
const handleCertificateDiplomaCourse = (id) => {
  // alert("hello next Page");
    props.history.push({
      pathname: "/DisplayCourses",
      state: {
        id: id,
      }
    });
  // console.log(event.currentTarget);
};


   React.useEffect (function(){
    CertificateList()
    // console.log(props);
   },[])

   var colors=['colr1','colr2','colr3','colr4','colr5','colr6','colr7','colr8','colr9','colr10','colr11','colr12','colr13','colr14','colr15','colr16','colr17','colr18','colr19'];
  
  

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />

      <div className="blog-area single full-blog left-sidebar full-blog default-padding">
        <div className="container">
          <div className="row">
            <div className="blog-items">
              

              <div className="blog-content col-md-12">
                <div className="content-items">
                  <div className="single-item">
                    <div className="item">
                      <div className="info">
                        <div className="content" style={{ paddingBottom: 0 }}>
                          <div
                            style={{
                              marginLeft: 15,
                              textAlign: "justify",
                              width:1000
                            }}
                          >
                            <div >
                            <h5>Search All Majors</h5>

{/* ///////////////////////////////////////////////Under Graduate Programs///////////////////////////////////////// */}

                              <h3 style={{marginLeft:280}}>
                                
                                Undergraduate Programs
                              </h3>
                            </div>

                            {/* <div className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard" style={{backgroundImage: "url(" + "../assets/img/banner/6.jpg" + ")",padding:70}}>             

        <div className="container">
            <div className="row">
            <div className="double-items col-md-12 info">
                <h2 className="wow fadeInLeft">Undergraduate Programs</h2>
                </div>
             
            </div>
        </div>
    </div> */}
    
                            
                            <ul
                              style={{
                 
                              }}
                            >
                              <div >

                                <ul  
                                class="explore_list clearfix"
                                style={{
                                
                                cursor:'pointer'   
                              }}>
                                 {getList.map((item)=>(<li class={colors[Math.floor((Math.random()*15))]}>
                                   <a 
                                   style={{borderColor:'#FFF', borderWidth:1, borderStyle:'solid'}}
                                   onClick={() => handleCourse(item.id)}
                                  //  style={{ flexDirection:'row', flex:1, display:'flex'}}
                                   >
                                   {/* <a href="https://studymetro.com/search-programs?program=Accounting&amp;country=USA&amp;id=&amp;course=Undergraduate+Course"> */}
                                     {item.name}
                                   </a>
                                  </li>
                                 ))}
                                                                        
                               </ul>
                            </div>

                            </ul>

                            
{/* /////////////////////////////////////////////////End UnderGraduate Programs///////////////////////////////////////////// */}



{/* /////////////////////////////////////////////////Graduate Programs///////////////////////////////////////////// */}

                            <div>
                              <h3 style={{marginLeft:340}}>Graduate programs</h3>
                            </div>

                            <div>                    
                              <ul  
                              class="explore_list clearfix"
                              style={{
                                
                              }}>

                              {getgraduateList.map((item)=>(<li class={colors[Math.floor((Math.random()*15))]}>
                              {/* <a href="https://studymetro.com/search-programs?program=Accounting&amp;country=USA&amp;id=&amp;course=Graduate+Course"> */}
                                <a 
                                style={{borderColor:'#FFF', borderWidth:1, borderStyle:'solid'}}
                               onClick={() => handleGraduateCourse(item.id)}
                               >
                                        {item.name}
                                      </a>
                                      </li>))}                                 
                                  </ul>
                        </div>

{/* ////////////////////////////////////////////////End Graduate Programs////////////////////////////////////////// */}


                            {/* <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a href="https://www.cappex.com/major/architectural-history-and-criticism-general" style={{ color: "black" }}>
                                  Architectural History and Criticism, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/architectural-sciences-and-technology-other" style={{ color: "black" }}>
                                  Architectural Sciences and Technology, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/architectural-technologytechnician" style={{ color: "black" }}>
                                  Architectural Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/architectural-and-building-sciencestechnology" style={{ color: "black" }}>
                                  Architectural and Building Sciences/Technology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/architectural-and-building-sciencestechnology" style={{ color: "black" }}>
                                  Architecture
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/architecture-and-related-services-other" style={{ color: "black" }}>
                                  Architecture and Related Services, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/cityurban-community-and-regional-planning" style={{ color: "black" }}>
                                  City/Urban, Community and Regional Planning
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/environmental-designarchitecture" style={{ color: "black" }}>
                                  Environmental Design/Architecture
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/interior-architecture" style={{ color: "black" }}>
                                  Interior Architecture
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/landscape-architecture" style={{ color: "black" }}>
                                  Landscape Architecture
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/real-estate-development" style={{ color: "black" }}>
                                  Real Estate Development
                                </a>
                              </li>
                            </ul>
                            <br />
<hr /> */}

{/* ////////////////////////////////////////////////Doctoral Programs////////////////////////////////////////// */}

                            <div>
                              <h3 style={{marginLeft:340}}>
                                {/* Area, Ethnic, Cultural, Gender, and Group
                                Studies */}
                                Doctoral Programs
                              </h3>
                            </div>

                            <div>
                                <ul 
                                 class="explore_list clearfix"
                                style={{
                               
                              }}>
                                  {getdoctoralList.map((item)=>(<li class={colors[Math.floor((Math.random()*15))]}>
                                    {/* <a href="https://studymetro.com/search-programs?program=Accounting&amp;country=USA&amp;id=&amp;course=Undergraduate+Course"> */}
                                    <a 
                                    style={{borderColor:'#FFF', borderWidth:1, borderStyle:'solid'}}
                                   onClick={() => handleDoctoralCourse(item.id)}
                                   >
                                      {item.name}
                                    </a>
                                  </li>
                                  ))}                                   
                                </ul>
                            </div>

{/* ////////////////////////////////////////////////End Doctoral Programs////////////////////////////////////////// */}


                            {/* <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a href="https://www.cappex.com/major/african-studies" style={{ color: "black" }}>
                                  African Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/african-americanblack-studies" style={{ color: "black" }}>
                                  African-American/Black Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/african-americanblack-studies" style={{ color: "black" }}>
                                  American Indian/Native American Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/americanunited-states-studiescivilization" style={{ color: "black" }}>
                                American/United States Studies/Civilization
                                </a>
                              </li>
                              
                              <li>
                                <a href="https://www.cappex.com/major/area-studies-other" style={{ color: "black" }}>
                                  Area Studies, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/area-ethnic-cultural-and-gender-studies-other" style={{ color: "black" }}>
                                  Area, Ethnic, Cultural, and Gender Studies,
                                  Other{" "}
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/asian-studiescivilization" style={{ color: "black" }}>
                                  Asian Studies/Civilization
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/asian-american-studies" style={{ color: "black" }}>
                                  Asian-American Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/balkans-studies" style={{ color: "black" }}>
                                  Balkans Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/canadian-studies" style={{ color: "black" }}>
                                  Canadian Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/caribbean-studies" style={{ color: "black" }}>
                                  Caribbean Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/chinese-studies" style={{ color: "black" }}>
                                  Chinese Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/deaf-studies" style={{ color: "black" }}>
                                  Deaf Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/disability-studies" style={{ color: "black" }}>
                                  Disability Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/east-asian-studies" style={{ color: "black" }}>
                                  East Asian Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/ethnic-studies" style={{ color: "black" }}>
                                  Ethnic Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/ethnic-cultural-minority-gender-and-group-studies-other" style={{ color: "black" }}>
                                  Ethnic, Cultural Minority, Gender, and Group
                                  Studies, Other{" "}
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/european-studiescivilization" style={{ color: "black" }}>
                                  European Studies/Civilization
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/folklore-studies" style={{ color: "black" }}>
                                  Folklore Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/french-studies" style={{ color: "black" }}>
                                  French Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/gaylesbian-studies" style={{ color: "black" }}>
                                  Gay/Lesbian Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/german-studies" style={{ color: "black" }}>
                                  German Studies
                                </a>
                              </li>
                          
                              <li>
                                <a href="https://www.cappex.com/major/hispanic-american-puerto-rican-and-mexican-americanchicano-studies" style={{ color: "black" }}>
                                  Hispanic-American, Puerto Rican, and
                                  Mexican-American/Chicano Studies{" "}
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/irish-studies" style={{ color: "black" }}>
                                  Irish Studies
                                </a>
                              </li> 
		<li>
                                <a href="https://www.cappex.com/major/italian-studies" style={{ color: "black" }}>
                                Italian Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/japanese-studies" style={{ color: "black" }}>
                                  Japanese Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/korean-studies" style={{ color: "black" }}>
                                  Korean Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/latin-american-studies" style={{ color: "black" }}>
                                  Latin American Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/latin-american-and-caribbean-studies" style={{ color: "black" }}>
                                  Latin American and Caribbean Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/near-and-middle-eastern-studies" style={{ color: "black" }}>
                                  Near and Middle Eastern Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pacific-areapacific-rim-studies" style={{ color: "black" }}>
                                  Pacific Area/Pacific Rim Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/regional-studies-us-canadian-foreign" style={{ color: "black" }}>
                                  Regional Studies (US, Canadian, Foreign)
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/russian-studies" style={{ color: "black" }}>
                                  Russian Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/russian-central-european-east-european-and-eurasian-studies" style={{ color: "black" }}>
                                  Russian, Central European, East European and
                                  Eurasian Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/scandinavian-studies" style={{ color: "black" }}>
                                  Scandinavian Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/slavic-studies" style={{ color: "black" }}>
                                  Slavic Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/south-asian-studies" style={{ color: "black" }}>
                                  South Asian Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/southeast-asian-studies" style={{ color: "black" }}>
                                  Southeast Asian Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/spanish-and-iberian-studies" style={{ color: "black" }}>
                                  Spanish and Iberian Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/ukraine-studies" style={{ color: "black" }}>
                                  Ukraine Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/ural-altaic-and-central-asian-studies" style={{ color: "black" }}>
                                  Ural-Altaic and Central Asian Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/western-european-studies" style={{ color: "black" }}>
                                  Western European Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/womens-studies" style={{ color: "black" }}>
                                  Women's Studies
                                </a>
                              </li>
                            </ul>
                            <br />
<hr /> */}

{/* ////////////////////////////////////////////////Certificate/Diploma Programs////////////////////////////////////////// */}

                            <div>
                              <h3 style={{marginLeft:280}}>
                                {/* Basic Skills and Developmental/Remedial
                                Education */}
                                Certificate/Diploma Program
                              </h3>
                            </div>

                            <div>
                               
                                <ul  
                                 class="explore_list clearfix"
                                   style={{cursor:'pointer'}}>
                                {getCertificateDiplomaList.map((item)=>(<li class={colors[Math.floor((Math.random()*15))]}>
                                          {/* <a href="https://studymetro.com/search-programs?program=Accounting&amp;country=USA&amp;id=&amp;course=Graduate+Course"> */}
                                          <a 
                                          style={{borderColor:'#FFF', borderWidth:1, borderStyle:'solid'}}
                                   onClick={() => handleCertificateDiplomaCourse(item.id)}
                                   >
                                            {item.name}
                                          </a>
                                          </li>))}
                                          </ul>
                            </div>

{/* ////////////////////////////////////End Certificate/Diploma Programs/////////////////////////////////////*/}


                            {/* <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a href="https://www.cappex.com/major/basic-skills-and-developmentalremedial-education-general" style={{ color: "black" }}>
                                  Basic Skills and Developmental/Remedial
                                  Education, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/career-explorationawareness-skills" style={{ color: "black" }}>
                                  Career Exploration/Awareness Skills
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/second-language-learning" style={{ color: "black" }}>
                                  Second Language Learning
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/workforce-development-and-training" style={{ color: "black" }}>
                                  Workforce Development and Training
                                </a>
                              </li>
                            </ul>
                            <br />
<hr />
 */}

                            {/* <div>
                              <h3>Biological and Biomedical Sciences</h3>
                            </div>

                            <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a href="https://www.cappex.com/major/aerospace-physiology-and-medicine" style={{ color: "black" }}>
                                  Aerospace Physiology and Medicine
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/anatomy" style={{ color: "black" }}>
                                  Anatomy
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/animal-behavior-and-ethology" style={{ color: "black" }}>
                                  Animal Behavior and Ethology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/animal-genetics" style={{ color: "black" }}>
                                  Animal Genetics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/animal-physiology" style={{ color: "black" }}>
                                  Animal Physiology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/aquatic-biologylimnology" style={{ color: "black" }}>
                                  Aquatic Biology/Limnology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/biochemistry" style={{ color: "black" }}>
                                  Biochemistry
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/biochemistry-and-molecular-biology" style={{ color: "black" }}>
                                  Biochemistry and Molecular Biology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/biochemistry-biophysics-and-molecular-biology-other" style={{ color: "black" }}>
                                  Biochemistry, Biophysics and Molecular
                                  Biology, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/bioinformatics" style={{ color: "black" }}>
                                  Bioinformatics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/biological-and-biomedical-sciences-other" style={{ color: "black" }}>
                                  Biological and Biomedical Sciences, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/biologybiological-sciences-general" style={{ color: "black" }}>
                                  Biology/Biological Sciences, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/biomathematics-bioinformatics-and-computational-biology-other" style={{ color: "black" }}>
                                  Biomathematics, Bioinformatics, and
                                  Computational Biology, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/biomedical-sciences-general" style={{ color: "black" }}>
                                  Biomedical Sciences, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/biometrybiometrics" style={{ color: "black" }}>
                                  Biometry/Biometrics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/biophysics" style={{ color: "black" }}>
                                  Biophysics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/biostatistics" style={{ color: "black" }}>
                                  Biostatistics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/biotechnology" style={{ color: "black" }}>
                                  Biotechnology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/botanyplant-biology" style={{ color: "black" }}>
                                  Botany/Plant Biology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/botanyplant-biology-other" style={{ color: "black" }}>
                                  Botany/Plant Biology, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/cardiovascular-science" style={{ color: "black" }}>
                                  Cardiovascular Science
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/cell-biology-and-anatomy" style={{ color: "black" }}>
                                  Cell Biology and Anatomy
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/cell-physiology" style={{ color: "black" }}>
                                  Cell Physiology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/cellcellular-biology-and-anatomical-sciences-other" style={{ color: "black" }}>
                                  Cell/Cellular Biology and Anatomical Sciences,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/cellcellular-biology-and-histology" style={{ color: "black" }}>
                                  Cell/Cellular Biology and Histology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/cellcellular-and-molecular-biology" style={{ color: "black" }}>
                                  Cell/Cellular and Molecular Biology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computational-biology" style={{ color: "black" }}>
                                  Computational Biology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/conservation-biology" style={{ color: "black" }}>
                                  Conservation Biology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/developmental-biology-and-embryology" style={{ color: "black" }}>
                                  Developmental Biology and Embryology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/ecology" style={{ color: "black" }}>
                                  Ecology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/ecology-and-evolutionary-biology" style={{ color: "black" }}>
                                  Ecology and Evolutionary Biology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/ecology-evolution-systematics-and-population-biology-other" style={{ color: "black" }}>
                                  Ecology, Evolution, Systematics and Population
                                  Biology, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/endocrinology" style={{ color: "black" }}>
                                  Endocrinology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/entomology" style={{ color: "black" }}>
                                  Entomology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/environmental-biology" style={{ color: "black" }}>
                                  Environmental Biology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/environmental-toxicology" style={{ color: "black" }}>
                                  Environmental Toxicology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/epidemiology" style={{ color: "black" }}>
                                  Epidemiology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/evolutionary-biology" style={{ color: "black" }}>
                                  Evolutionary Biology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/exercise-physiology" style={{ color: "black" }}>
                                  Exercise Physiology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/genetics-general" style={{ color: "black" }}>
                                  Genetics, General
                                </a>
                              </li>
                    
                              <li>
                                <a href="https://www.cappex.com/major/genetics-other" style={{ color: "black" }}>
                                  Genetics, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/genome-sciencesgenomics" style={{ color: "black" }}>
                                  Genome Sciences/Genomics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/humanmedical-genetics" style={{ color: "black" }}>
                                  Human/Medical Genetics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/immunology" style={{ color: "black" }}>
                                  Immunology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/marine-biology-and-biological-oceanography" style={{ color: "black" }}>
                                  Marine Biology and Biological Oceanography
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/medical-microbiology-and-bacteriology" style={{ color: "black" }}>
                                  Medical Microbiology and Bacteriology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/microbial-and-eukaryotic-genetics" style={{ color: "black" }}>
                                  Microbial and Eukaryotic Genetics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/microbiological-sciences-and-immunology-other" style={{ color: "black" }}>
                                  Microbiological Sciences and Immunology, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/microbiology-and-immunology" style={{ color: "black" }}>
                                  Microbiology and Immunology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/microbiology-general" style={{ color: "black" }}>
                                  Microbiology, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/molecular-biochemistry" style={{ color: "black" }}>
                                  Molecular Biochemistry
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/molecular-biology" style={{ color: "black" }}>
                                  Molecular Biology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/molecular-biophysics" style={{ color: "black" }}>
                                  Molecular Biophysics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/molecular-genetics" style={{ color: "black" }}>
                                  Molecular Genetics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/molecular-medicine" style={{ color: "black" }}>
                                  Molecular Medicine
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/molecular-pharmacology" style={{ color: "black" }}>
                                  Molecular Pharmacology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/molecular-physiology" style={{ color: "black" }}>
                                  Molecular Physiology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/molecular-toxicology" style={{ color: "black" }}>
                                  Molecular Toxicology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/neuroanatomy" style={{ color: "black" }}>
                                  Neuroanatomy
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/neurobiology-and-anatomy" style={{ color: "black" }}>
                                  Neurobiology and Anatomy
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/neurobiology-and-behavior" style={{ color: "black" }}>
                                  Neurobiology and Behavior
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/neurobiology-and-neurosciences-other" style={{ color: "black" }}>
                                  Neurobiology and Neurosciences, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/neuropharmacology" style={{ color: "black" }}>
                                  Neuropharmacology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/neuroscience" style={{ color: "black" }}>
                                  Neuroscience
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/oncology-and-cancer-biology" style={{ color: "black" }}>
                                  Oncology and Cancer Biology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/parasitology" style={{ color: "black" }}>
                                  Parasitology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pathologyexperimental-pathology" style={{ color: "black" }}>
                                  Pathology/Experimental Pathology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pharmacology" style={{ color: "black" }}>
                                  Pharmacology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pharmacology-and-toxicology" style={{ color: "black" }}>
                                  Pharmacology and Toxicology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pharmacology-and-toxicology-other" style={{ color: "black" }}>
                                  Pharmacology and Toxicology, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/physiology-general" style={{ color: "black" }}>
                                  Physiology, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/physiology-pathology-and-related-sciences-other" style={{ color: "black" }}>
                                  Physiology, Pathology, and Related Sciences,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/plant-genetics" style={{ color: "black" }}>
                                  Plant Genetics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/plant-molecular-biology" style={{ color: "black" }}>
                                  Plant Molecular Biology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/plant-pathologyphytopathology" style={{ color: "black" }}>
                                  Plant Pathology/Phytopathology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/plant-physiology" style={{ color: "black" }}>
                                  Plant Physiology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/population-biology" style={{ color: "black" }}>
                                  Population Biology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/radiation-biologyradiobiology" style={{ color: "black" }}>
                                  Radiation Biology/Radiobiology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/reproductive-biology" style={{ color: "black" }}>
                                  Reproductive Biology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/structural-biology" style={{ color: "black" }}>
                                  Structural Biology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/systematic-biologybiological-systematics" style={{ color: "black" }}>
                                  Systematic Biology/Biological Systematics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/toxicology" style={{ color: "black" }}>
                                  Toxicology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/virology" style={{ color: "black" }}>
                                  Virology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/vision-sciencephysiological-optics" style={{ color: "black" }}>
                                  Vision Science/Physiological Optics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/wildlife-biology" style={{ color: "black" }}>
                                  Wildlife Biology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/zoologyanimal-biology" style={{ color: "black" }}>
                                  Zoology/Animal Biology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/zoologyanimal-biology-other" style={{ color: "black" }}>
                                  Zoology/Animal Biology, Other
                                </a>
                              </li>
                            </ul>
                            <br />
<hr /> */}
                            {/* <div>
                              <h3>
                                Business, Management, Marketing, and Related
                                Support Services
                              </h3>
                            </div>

                            <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a href="https://www.cappex.com/major/accounting" style={{ color: "black" }}>
                                  Accounting
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/accounting-technologytechnician-and-bookkeeping" style={{ color: "black" }}>
                                  Accounting Technology/Technician and
                                  Bookkeeping
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/accounting-and-businessmanagement" style={{ color: "black" }}>
                                  Accounting and Business/Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/accounting-and-finance" style={{ color: "black" }}>
                                  Accounting and Finance
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/accounting-and-related-services-other" style={{ color: "black" }}>
                                  Accounting and Related Services, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/actuarial-science" style={{ color: "black" }}>
                                  Actuarial Science
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/administrative-assistant-and-secretarial-science-general" style={{ color: "black" }}>
                                  Administrative Assistant and Secretarial
                                  Science, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/apparel-and-accessories-marketing-operations" style={{ color: "black" }}>
                                  Apparel and Accessories Marketing Operations{" "}
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/auditing" style={{ color: "black" }}>
                                  Auditing
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/banking-and-financial-support-services" style={{ color: "black" }}>
                                  Banking and Financial Support Services
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/business-administration-and-management-general" style={{ color: "black" }}>
                                  Business Administration and Management,
                                  General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/business-administration-management-and-operations-other" style={{ color: "black" }}>
                                  Business Administration, Management and
                                  Operations, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/business-operations-support-and-secretarial-services-other" style={{ color: "black" }}>
                                  Business Operations Support and Secretarial
                                  Services, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/business-statistics" style={{ color: "black" }}>
                                  Business Statistics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/business-and-personalfinancial-services-marketing-operations" style={{ color: "black" }}>
                                  Business and Personal/Financial Services
                                  Marketing Operations
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/business-management-marketing-and-related-support-services-other" style={{ color: "black" }}>
                                  Business, Management, Marketing, and Related
                                  Support Services, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/businesscommerce-general" style={{ color: "black" }}>
                                  Business/Commerce, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/businesscorporate-communications" style={{ color: "black" }}>
                                  Business/Corporate Communications
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/businessmanagerial-economics" style={{ color: "black" }}>
                                  Business/Managerial Economics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/businessoffice-automationtechnologydata-entry" style={{ color: "black" }}>
                                  Business/Office Automation/Technology/Data
                                  Entry
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/casino-management" style={{ color: "black" }}>
                                  Casino Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/construction-management" style={{ color: "black" }}>
                                  Construction Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/credit-management" style={{ color: "black" }}>
                                  Credit Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/customer-service-management" style={{ color: "black" }}>
                                  Customer Service Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/customer-service-supportcall-centerteleservice-operation" style={{ color: "black" }}>
                                  Customer Service Support/Call
                                  Center/Teleservice Operation
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/e-commerceelectronic-commerce" style={{ color: "black" }}>
                                  E-Commerce/Electronic Commerce
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/entrepreneurial-and-small-business-operations-other" style={{ color: "black" }}>
                                  Entrepreneurial and Small Business Operations,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/entrepreneurshipentrepreneurial-studies" style={{ color: "black" }}>
                                  Entrepreneurship/Entrepreneurial Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/executive-assistantexecutive-secretary" style={{ color: "black" }}>
                                  Executive Assistant/Executive Secretary
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/fashion-merchandising" style={{ color: "black" }}>
                                  Fashion Merchandising
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/fashion-modeling" style={{ color: "black" }}>
                                  Fashion Modeling
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/finance-and-financial-management-services-other" style={{ color: "black" }}>
                                  Finance and Financial Management Services,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/finance-general" style={{ color: "black" }}>
                                  Finance, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/financial-planning-and-services" style={{ color: "black" }}>
                                  Financial Planning and Services
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/franchising-and-franchise-operations" style={{ color: "black" }}>
                                  Franchising and Franchise Operations
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/general-merchandising-sales-and-related-marketing-operations-other" style={{ color: "black" }}>
                                  General Merchandising, Sales, and Related
                                  Marketing Operations, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/general-office-occupations-and-clerical-services" style={{ color: "black" }}>
                                  General Office Occupations and Clerical
                                  Services
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/hospitality-administrationmanagement-general" style={{ color: "black" }}>
                                  Hospitality Administration/Management, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/hospitality-administrationmanagement-other" style={{ color: "black" }}>
                                  Hospitality Administration/Management, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/hospitality-and-recreation-marketing-operations" style={{ color: "black" }}>
                                  Hospitality and Recreation Marketing
                                  Operations
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/hotel-motel-and-restaurant-management" style={{ color: "black" }}>
                                  Hotel, Motel, and Restaurant Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/hotelmotel-administrationmanagement" style={{ color: "black" }}>
                                  Hote/Motel Administration/Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/human-resources-development" style={{ color: "black" }}>
                                  Human Resources Development
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/human-resources-management-and-services-other" style={{ color: "black" }}>
                                  Human Resources Management and Services, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/human-resources-managementpersonnel-administration-general" style={{ color: "black" }}>
                                  Human Resources Management/Personnel
                                  Administration, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/information-resources-management" style={{ color: "black" }}>
                                  Information Resources Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/insurance" style={{ color: "black" }}>
                                  Insurance
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/international-businesstradecommerce" style={{ color: "black" }}>
                                  International Business/Trade/Commerce
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/international-finance" style={{ color: "black" }}>
                                  International Finance
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/international-marketing" style={{ color: "black" }}>
                                  International Marketing
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/investments-and-securities" style={{ color: "black" }}>
                                  Investments and Securities
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/knowledge-management" style={{ color: "black" }}>
                                  Knowledge Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/labor-studies" style={{ color: "black" }}>
                                  Labor Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/labor-and-industrial-relations" style={{ color: "black" }}>
                                  Labor and Industrial Relations
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/logistics-materials-and-supply-chain-management" style={{ color: "black" }}>
                                  Logistics, Materials, and Supply Chain
                                  Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/management-information-systems-and-services-other" style={{ color: "black" }}>
                                  Management Information Systems and Services,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/management-information-systems-general" style={{ color: "black" }}>
                                  Management Information Systems, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/management-science" style={{ color: "black" }}>
                                  Management Science
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/management-sciences-and-quantitative-methods-other" style={{ color: "black" }}>
                                  Management Sciences and Quantitative Methods,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/marketing-research" style={{ color: "black" }}>
                                  Marketing Research
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/marketing-other" style={{ color: "black" }}>
                                  Marketing, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/marketingmarketing-management-general" style={{ color: "black" }}>
                                  Marketing/Marketing Management, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/meeting-and-event-planning" style={{ color: "black" }}>
                                  Meeting and Event Planning
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/merchandising-and-buying-operations" style={{ color: "black" }}>
                                  Merchandising and Buying Operations
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/non-profitpublicorganizational-management" style={{ color: "black" }}>
                                  Non-Profit/Public/Organizational Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/office-management-and-supervision" style={{ color: "black" }}>
                                  Office Management and Supervision
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/operations-management-and-supervision" style={{ color: "black" }}>
                                  Operations Management and Supervision
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/organizational-behavior-studies" style={{ color: "black" }}>
                                  Organizational Behavior Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/organizational-leadership" style={{ color: "black" }}>
                                  Organizational Leadership
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/parts-warehousing-and-inventory-management-operations" style={{ color: "black" }}>
                                  Parts, Warehousing, and Inventory Management
                                  Operations
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/project-management" style={{ color: "black" }}>
                                  Project Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/public-finance" style={{ color: "black" }}>
                                  Public Finance
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/purchasing-procurementacquisitions-and-contracts-management" style={{ color: "black" }}>
                                  Purchasing, Procurement/Acquisitions and
                                  Contracts Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/real-estate" style={{ color: "black" }}>
                                  Real Estate
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/research-and-development-management" style={{ color: "black" }}>
                                  Research and Development Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/resort-management" style={{ color: "black" }}>
                                  Resort Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/restaurantfood-services-management" style={{ color: "black" }}>
                                  Restaurant/Food Services Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/retail-management" style={{ color: "black" }}>
                                  Retail Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/retailing-and-retail-operations" style={{ color: "black" }}>
                                  Retailing and Retail Operations
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/sales-distribution-and-marketing-operations-general" style={{ color: "black" }}>
                                  Sales, Distribution, and Marketing Operations,
                                  General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/selling-skills-and-sales-operations" style={{ color: "black" }}>
                                  Selling Skills and Sales Operations
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/small-business-administrationmanagement" style={{ color: "black" }}>
                                  Small Business Administration/Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/special-products-marketing-operations" style={{ color: "black" }}>
                                  Special Products Marketing Operations
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/specialized-merchandising-sales-and-marketing-operations-other" style={{ color: "black" }}>
                                  Specialized Merchandising, Sales, and
                                  Marketing Operations, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/taxation" style={{ color: "black" }}>
                                  Taxation
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/telecommunications-management" style={{ color: "black" }}>
                                  Telecommunications Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/tourism-promotion-operations" style={{ color: "black" }}>
                                  Tourism Promotion Operations
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/tourism-and-travel-services-management" style={{ color: "black" }}>
                                  Tourism and Travel Services Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/tourism-and-travel-services-marketing-operations" style={{ color: "black" }}>
                                  Tourism and Travel Services Marketing
                                  Operations
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/traffic-customs-and-transportation-clerktechnician" style={{ color: "black" }}>
                                  Traffic, Customs, and Transportation
                                  Clerk/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/transportationmobility-management" style={{ color: "black" }}>
                                  Transportation/Mobility Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/vehicle-and-vehicle-parts-and-accessories-marketing-operations" style={{ color: "black" }}>
                                  Vehicle and Vehicle Parts and Accessories
                                  Marketing Operations
                                </a>
                              </li>
                            </ul>
                            <br />
<hr /> */}
                            {/* <div>
                              <h3>Citizenship Activities</h3>
                            </div>

                            <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a href="https://www.cappex.com/major/community-awareness" style={{ color: "black" }}>
                                  Community Awareness
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/community-involvement" style={{ color: "black" }}>
                                  Community Involvement
                                </a>
                              </li>
                            </ul>
                            <br />
<hr /> */}

                            {/* <div>
                              <h3>
                                Communication, Journalism, and Related Programs
                              </h3>
                            </div>

                            <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a href="https://www.cappex.com/major/advertising" style={{ color: "black" }}>
                                  Advertising
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/broadcast-journalism" style={{ color: "black" }}>
                                  Broadcast Journalism
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/communication-and-media-studies-other" style={{ color: "black" }}>
                                  Communication and Media Studies, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/communication-general" style={{ color: "black" }}>
                                  Communication, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/communication-journalism-and-related-programs-other" style={{ color: "black" }}>
                                  Communication, Journalism, and Related
                                  Programs, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/digital-communication-and-mediamultimedia" style={{ color: "black" }}>
                                  Digital Communication and Media/Multimedia
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/health-communication" style={{ color: "black" }}>
                                  Health Communication
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/international-and-intercultural-communication" style={{ color: "black" }}>
                                  International and Intercultural Communication
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/journalism" style={{ color: "black" }}>
                                  Journalism
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/journalism-other" style={{ color: "black" }}>
                                  Journalism, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/mass-communicationmedia-studies" style={{ color: "black" }}>
                                  Mass Communication/Media Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/organizational-communication-general" style={{ color: "black" }}>
                                  Organizational Communication, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/photojournalism" style={{ color: "black" }}>
                                  Photojournalism
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/political-communication" style={{ color: "black" }}>
                                  Political Communication
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/public-relations-advertising-and-applied-communication" style={{ color: "black" }}>
                                  Public Relations, Advertising, and Applied
                                  Communication
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/public-relations-advertising-and-applied-communication-other" style={{ color: "black" }}>
                                  Public Relations, Advertising, and Applied
                                  Communication, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/public-relationsimage-management" style={{ color: "black" }}>
                                  Public Relations/Image Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/publishing" style={{ color: "black" }}>
                                  Publishing
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/radio-and-television" style={{ color: "black" }}>
                                  Radio and Television
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/radio-television-and-digital-communication-other" style={{ color: "black" }}>
                                  Radio, Television, and Digital Communication,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/speech-communication-and-rhetoric" style={{ color: "black" }}>
                                  Speech Communication and Rhetoric
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/sports-communication" style={{ color: "black" }}>
                                  Sports Communication
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/technical-and-scientific-communication" style={{ color: "black" }}>
                                  Technical and Scientific Communication
                                </a>
                              </li>
                            </ul>
                            <br />
<hr /> */}
                            {/* <div>
                              <h3>
                                Communications Technologies/Technicians and
                                Support Services
                              </h3>
                            </div>

                            <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a href="https://www.cappex.com/major/animation-interactive-technology-video-graphics-and-special-effects" style={{ color: "black" }}>
                                  Animation, Interactive Technology, Video
                                  Graphics and Special Effects
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/audiovisual-communications-technologiestechnicians-other" style={{ color: "black" }}>
                                  Audiovisual Communications
                                  Technologies/Technicians, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/communications-technologiestechnicians-and-support-services-other" style={{ color: "black" }}>
                                  Communications Technologies/Technicians and
                                  Support Services, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/communications-technologytechnician" style={{ color: "black" }}>
                                  Communications Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/graphic-communications-general" style={{ color: "black" }}>
                                  Graphic Communications, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/graphic-communications-other" style={{ color: "black" }}>
                                  Graphic Communications, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/graphic-and-printing-equipment-operator-general-production" style={{ color: "black" }}>
                                  Graphic and Printing Equipment Operator,
                                  General Production
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/photographic-and-filmvideo-technologytechnician-and-assistant" style={{ color: "black" }}>
                                  Photographic and Film/Video
                                  Technology/Technician and Assistant
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/platemakerimager" style={{ color: "black" }}>
                                  Platemaker/Imager
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/prepressdesktop-publishing-and-digital-imaging-design" style={{ color: "black" }}>
                                  Prepress/Desktop Publishing and Digital
                                  Imaging Design
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/printing-management" style={{ color: "black" }}>
                                  Printing Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/printing-press-operator" style={{ color: "black" }}>
                                  Printing Press Operator
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/radio-and-television-broadcasting-technologytechnician" style={{ color: "black" }}>
                                  Radio and Television Broadcasting
                                  Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/recording-arts-technologytechnician" style={{ color: "black" }}>
                                  Recording Arts Technology/Technician
                                </a>
                              </li>
                            </ul>
                            <br />
<hr /> */}
                           

                            {/* <div>
                              <h3>
                                Computer and Information Sciences and Support
                                Services
                              </h3>
                            </div>

                            <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a href="https://www.cappex.com/major/artificial-intelligence" style={{ color: "black" }}>
                                  Artificial Intelligence
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-graphics" style={{ color: "black" }}>
                                  Computer Graphics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-programming-other" style={{ color: "black" }}>
                                  Computer Programming, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-programming-specific-applications" style={{ color: "black" }}>
                                  Computer Programming, Specific Applications
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-programming-vendorproduct-certification" style={{ color: "black" }}>
                                  Computer Programming, Vendor/Product
                                  Certification
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-programmingprogrammer-general" style={{ color: "black" }}>
                                  Computer Programming/Programmer, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-science" style={{ color: "black" }}>
                                  Computer Science
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-software-and-media-applications-other" style={{ color: "black" }}>
                                  Computer Software and Media Applications,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-support-specialist" style={{ color: "black" }}>
                                  Computer Support Specialist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-systems-analysisanalyst" style={{ color: "black" }}>
                                  Computer Systems Analysis/Analyst
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-systems-networking-and-telecommunications" style={{ color: "black" }}>
                                  Computer Systems Networking and
                                  Telecommunications
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-and-information-sciences-and-support-services-other" style={{ color: "black" }}>
                                  Computer and Information Sciences and Support
                                  Services, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-and-information-sciences-other" style={{ color: "black" }}>
                                  Computer and Information Sciences, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-and-information-sciences-general" style={{ color: "black" }}>
                                  Computer and Information Sciences, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-and-information-systems-securityinformation-assurance" style={{ color: "black" }}>
                                  Computer and Information Systems
                                  Security/Information Assurance
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computerinformation-technology-services-administration-and-management-other" style={{ color: "black" }}>
                                  Computer/Information Technology Services
                                  Administration and Management, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/data-entrymicrocomputer-applications-general" style={{ color: "black" }}>
                                  Data Entry/Microcomputer Applications, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/data-entrymicrocomputer-applications-other" style={{ color: "black" }}>
                                  Data Entry/Microcomputer Applications, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/data-modelingwarehousing-and-database-administration" style={{ color: "black" }}>
                                  Data Modeling/Warehousing and Database
                                  Administration
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/data-processing-and-data-processing-technologytechnician" style={{ color: "black" }}>
                                  Data Processing and Data Processing
                                  Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/informatics" style={{ color: "black" }}>
                                  Informatics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/information-sciencestudies" style={{ color: "black" }}>
                                  Information Science/Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/information-technology" style={{ color: "black" }}>
                                  Information Technology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/information-technology-project-management" style={{ color: "black" }}>
                                  Information Technology Project Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/modeling-virtual-environments-and-simulation" style={{ color: "black" }}>
                                  Modeling, Virtual Environments and Simulation
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/network-and-system-administrationadministrator" style={{ color: "black" }}>
                                  Network and System
                                  Administration/Administrator
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/system-networking-and-lanwan-managementmanager" style={{ color: "black" }}>
                                  System, Networking, and LAN/WAN
                                  Management/Manager
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/web-page-digitalmultimedia-and-information-resources-design" style={{ color: "black" }}>
                                  Web Page, Digital/Multimedia and Information
                                  Resources Design
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/webmultimedia-management-and-webmaster" style={{ color: "black" }}>
                                  Web/Multimedia Management and Webmaster
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/word-processing" style={{ color: "black" }}>
                                  Word Processing
                                </a>
                              </li>
                            </ul>
                            <br />
<hr /> */}

                            {/* <div>
                              <h3>Construction Trades</h3>
                            </div>

                            <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a href="https://www.cappex.com/major/blastingblaster" style={{ color: "black" }}>
                                  Blasting/Blaster
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/building-construction-technology" style={{ color: "black" }}>
                                  Building Construction Technology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/buildingconstruction-finishing-management-and-inspection-other" style={{ color: "black" }}>
                                  Building/Construction Finishing, Management,
                                  and Inspection, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/buildingconstruction-site-managementmanager" style={{ color: "black" }}>
                                  Building/Construction Site Management/Manager
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/buildinghomeconstruction-inspectioninspector" style={{ color: "black" }}>
                                  Building/Home/Construction
                                  Inspection/Inspector
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/buildingproperty-maintenance" style={{ color: "black" }}>
                                  Building/Property Maintenance
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/carpentrycarpenter" style={{ color: "black" }}>
                                  Carpentry/Carpenter
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/carpet-floor-and-tile-worker" style={{ color: "black" }}>
                                  Carpet, Floor, and Tile Worker
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/concrete-finishingconcrete-finisher" style={{ color: "black" }}>
                                  Concrete Finishing/Concrete Finisher
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/construction-trades-general" style={{ color: "black" }}>
                                  Construction Trades, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/construction-trades-other" style={{ color: "black" }}>
                                  Construction Trades, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/drywall-installationdrywaller" style={{ color: "black" }}>
                                  Drywall Installation/Drywaller
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/electrical-and-power-transmission-installationinstaller-general" style={{ color: "black" }}>
                                  Electrical and Power Transmission
                                  Installation/Installer, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/electrical-and-power-transmission-installers-other" style={{ color: "black" }}>
                                  Electrical and Power Transmission Installers,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/electrician" style={{ color: "black" }}>
                                  Electrician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/glazier" style={{ color: "black" }}>
                                  Glazier
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/lineworker" style={{ color: "black" }}>
                                  Lineworker
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/masonmasonry" style={{ color: "black" }}>
                                  Mason/Masonry
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/metal-building-assemblyassembler" style={{ color: "black" }}>
                                  Metal Building Assembly/Assembler
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/paintingpainter-and-wall-coverer" style={{ color: "black" }}>
                                  Painting/Painter and Wall Coverer
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pipefittingpipefitter-and-sprinkler-fitter" style={{ color: "black" }}>
                                  Pipefitting/Pipefitter and Sprinkler Fitter
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/plumbing-technologyplumber" style={{ color: "black" }}>
                                  Plumbing Technology/Plumber
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/plumbing-and-related-water-supply-services-other" style={{ color: "black" }}>
                                  Plumbing and Related Water Supply Services,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/well-drillingdriller" style={{ color: "black" }}>
                                  Well Drilling/Driller
                                </a>
                              </li>
                            </ul>
                            <br />
<hr /> */}

                            {/* <div>
                              <h3>Education</h3>
                            </div>

                            <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a href="https://www.cappex.com/major/administration-of-special-education" style={{ color: "black" }}>
                                  Administration of Special Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/adult-literacy-tutorinstructor" style={{ color: "black" }}>
                                  Adult Literacy Tutor/Instructor
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/adult-and-continuing-education-administration" style={{ color: "black" }}>
                                  Adult and Continuing Education Administration{" "}
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/adult-and-continuing-education-and-teaching" style={{ color: "black" }}>
                                  Adult and Continuing Education and Teaching
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/agricultural-teacher-education" style={{ color: "black" }}>
                                  Agricultural Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/art-teacher-education" style={{ color: "black" }}>
                                  Art Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/bilingual-and-multilingual-education" style={{ color: "black" }}>
                                  Bilingual and Multilingual Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/bilingual-multilingual-and-multicultural-education-other" style={{ color: "black" }}>
                                  Bilingual, Multilingual, and Multicultural
                                  Education, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/biology-teacher-education" style={{ color: "black" }}>
                                  Biology Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/business-teacher-education" style={{ color: "black" }}>
                                  Business Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/chemistry-teacher-education" style={{ color: "black" }}>
                                  Chemistry Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/college-student-counseling-and-personnel-services" style={{ color: "black" }}>
                                  College Student Counseling and Personnel
                                  Services
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/community-college-education" style={{ color: "black" }}>
                                  Community College Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-teacher-education" style={{ color: "black" }}>
                                  Computer Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/counselor-educationschool-counseling-and-guidance-services" style={{ color: "black" }}>
                                  Counselor Education/School Counseling and
                                  Guidance Services
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/curriculum-and-instruction" style={{ color: "black" }}>
                                  Curriculum and Instruction
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/drama-and-dance-teacher-education" style={{ color: "black" }}>
                                  Drama and Dance Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/driver-and-safety-teacher-education" style={{ color: "black" }}>
                                  Driver and Safety Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/early-childhood-education-and-teaching" style={{ color: "black" }}>
                                  Early Childhood Education and Teaching
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/earth-science-teacher-education" style={{ color: "black" }}>
                                  Earth Science Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/education-general" style={{ color: "black" }}>
                                  Education, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/education-other" style={{ color: "black" }}>
                                  Education, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/educationteaching-of-individuals-who-are-developmentally-delayed" style={{ color: "black" }}>
                                  Education/Teaching of Individuals Who are
                                  Developmentally Delayed
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/educationteaching-of-individuals-in-early-childhood-special-education-programs" style={{ color: "black" }}>
                                  Education/Teaching of Individuals in Early
                                  Childhood Special Education Programs
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/educationteaching-of-individuals-in-elementary-special-education-programs" style={{ color: "black" }}>
                                  Education/Teaching of Individuals in
                                  Elementary Special Education Programs
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/educationteaching-of-individuals-in-junior-highmiddle-school-special-education-programs" style={{ color: "black" }}>
                                  Education/Teaching of Individuals in Junior
                                  High/Middle School Special Education Programs
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/educationteaching-of-individuals-in-secondary-special-education-programs" style={{ color: "black" }}>
                                  Education/Teaching of Individuals in Secondary
                                  Special Education Programs
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/educationteaching-of-individuals-with-autism" style={{ color: "black" }}>
                                  Education/Teaching of Individuals with Autism
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/educationteaching-of-individuals-with-emotional-disturbances" style={{ color: "black" }}>
                                  Education/Teaching of Individuals with
                                  Emotional Disturbances
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/educationteaching-of-individuals-with-hearing-impairments-including-deafness" style={{ color: "black" }}>
                                  Education/Teaching of Individuals with Hearing
                                  Impairments Including Deafness
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/educationteaching-of-individuals-with-mental-retardation" style={{ color: "black" }}>
                                  Education/Teaching of Individuals with Mental
                                  Retardation
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/educationteaching-of-individuals-with-multiple-disabilities" style={{ color: "black" }}>
                                  Education/Teaching of Individuals with
                                  Multiple Disabilities
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/educationteaching-of-individuals-with-orthopedic-and-other-physical-health-impairments" style={{ color: "black" }}>
                                  Education/Teaching of Individuals with
                                  Orthopedic and Other Physical Health
                                  Impairments
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/educationteaching-of-individuals-with-specific-learning-disabilities" style={{ color: "black" }}>
                                  Education/Teaching of Individuals with
                                  Specific Learning Disabilities
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/educationteaching-of-individuals-with-speech-or-language-impairments" style={{ color: "black" }}>
                                  Education/Teaching of Individuals with Speech
                                  or Language Impairments
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/educationteaching-of-individuals-with-vision-impairments-including-blindness" style={{ color: "black" }}>
                                  Education/Teaching of Individuals with Vision
                                  Impairments Including Blindness
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/educationteaching-of-the-gifted-and-talented" style={{ color: "black" }}>
                                  Education/Teaching of the Gifted and Talented
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/educational-administration-and-supervision-other" style={{ color: "black" }}>
                                  Educational Administration and Supervision,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/educational-assessment-evaluation-and-research-other" style={{ color: "black" }}>
                                  Educational Assessment, Evaluation, and
                                  Research, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/educational-assessment-testing-and-measurement" style={{ color: "black" }}>
                                  Educational Assessment, Testing, and
                                  Measurement
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/educational-evaluation-and-research" style={{ color: "black" }}>
                                  Educational Evaluation and Research
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/educational-leadership-and-administration-general" style={{ color: "black" }}>
                                  Educational Leadership and Administration,
                                  General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/educational-statistics-and-research-methods" style={{ color: "black" }}>
                                  Educational Statistics and Research Methods
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/educational-instructional-and-curriculum-supervision" style={{ color: "black" }}>
                                  Educational, Instructional, and Curriculum
                                  Supervision
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/educationalinstructional-technology" style={{ color: "black" }}>
                                  Educational/Instructional Technology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/elementary-education-and-teaching" style={{ color: "black" }}>
                                  Elementary Education and Teaching
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/elementary-and-middle-school-administrationprincipalship" style={{ color: "black" }}>
                                  Elementary and Middle School
                                  Administration/Principalship
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/englishlanguage-arts-teacher-education" style={{ color: "black" }}>
                                  English/Language Arts Teacher Education
                                </a>
                              </li>
                              
                              <li>
                                <a href="https://www.cappex.com/major/environmental-education" style={{ color: "black" }}>
                                  Environmental Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/family-and-consumer-scienceshome-economics-teacher-education" style={{ color: "black" }}>
                                  Family and Consumer Sciences/Home Economics
                                  Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/foreign-language-teacher-education" style={{ color: "black" }}>
                                  Foreign Language Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/french-language-teacher-education" style={{ color: "black" }}>
                                  French Language Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/geography-teacher-education" style={{ color: "black" }}>
                                  Geography Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/german-language-teacher-education" style={{ color: "black" }}>
                                  German Language Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/health-occupations-teacher-education" style={{ color: "black" }}>
                                  Health Occupations Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/health-teacher-education" style={{ color: "black" }}>
                                  Health Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/higher-educationhigher-education-administration" style={{ color: "black" }}>
                                  Higher Education/Higher Education
                                  Administration
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/health-teacher-education" style={{ color: "black" }}>
                                  History Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/indiannative-american-education" style={{ color: "black" }}>
                                  Indian/Native American Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/international-and-comparative-education" style={{ color: "black" }}>
                                  International and Comparative Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/junior-highintermediatemiddle-school-education-and-teaching" style={{ color: "black" }}>
                                  Junior High/Intermediate/Middle School
                                  Education and Teaching
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/kindergartenpreschool-education-and-teaching" style={{ color: "black" }}>
                                  Kindergarten/Preschool Education and Teaching
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/latin-teacher-education" style={{ color: "black" }}>
                                  Latin Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/learning-sciences" style={{ color: "black" }}>
                                  Learning Sciences
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/mathematics-teacher-education" style={{ color: "black" }}>
                                  Mathematics Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/montessori-teacher-education" style={{ color: "black" }}>
                                  Montessori Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/multicultural-educationm" style={{ color: "black" }}>
                                  Multicultural Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/music-teacher-education" style={{ color: "black" }}>
                                  Music Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/physical-education-teaching-and-coaching" style={{ color: "black" }}>
                                  Physical Education Teaching and Coaching
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/physics-teacher-education" style={{ color: "black" }}>
                                  Physics Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/psychology-teacher-education" style={{ color: "black" }}>
                                  Psychology Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/reading-teacher-education" style={{ color: "black" }}>
                                  Reading Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/sales-and-marketing-operationsmarketing-and-distribution-teacher-education" style={{ color: "black" }}>
                                  Sales and Marketing Operations/Marketing and
                                  Distribution Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/school-librarianschool-library-media-specialist" style={{ color: "black" }}>
                                  School Librarian/School Library Media
                                  Specialist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/science-teacher-educationgeneral-science-teacher-education" style={{ color: "black" }}>
                                  Science Teacher Education/General Science
                                  Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/secondary-education-and-teaching" style={{ color: "black" }}>
                                  Secondary Education and Teaching
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/secondary-school-administrationprincipalship" style={{ color: "black" }}>
                                  Secondary School Administration/Principalship
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/social-science-teacher-education" style={{ color: "black" }}>
                                  Social Science Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/social-studies-teacher-education" style={{ color: "black" }}>
                                  Social Studies Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/social-and-philosophical-foundations-of-education" style={{ color: "black" }}>
                                  Social and Philosophical Foundations of
                                  Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/spanish-language-teacher-education" style={{ color: "black" }}>
                                  Spanish Language Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/special-education-and-teaching-general" style={{ color: "black" }}>
                                  Special Education and Teaching, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/special-education-and-teaching-other" style={{ color: "black" }}>
                                  Special Education and Teaching, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/speech-teacher-education" style={{ color: "black" }}>
                                  Speech Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/student-counseling-and-personnel-services-other" style={{ color: "black" }}>
                                  Student Counseling and Personnel Services,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/superintendency-and-educational-system-administration" style={{ color: "black" }}>
                                  Superintendency and Educational System
                                  Administration
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/teacher-assistantaide" style={{ color: "black" }}>
                                  Teacher Assistant/Aide
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/teacher-education-and-professional-development-specific-levels-and-methods-other" style={{ color: "black" }}>
                                  Teacher Education and Professional
                                  Development, Specific Levels and Methods,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/teacher-education-and-professional-development-specific-subject-areas-other" style={{ color: "black" }}>
                                  Teacher Education and Professional
                                  Development, Specific Subject Areas, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/teacher-education-multiple-levels" style={{ color: "black" }}>
                                  Teacher Education, Multiple Levels
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/teaching-english-second-or-foreign-languageesl-language-instructor" style={{ color: "black" }}>
                                  Teaching English as a Second or Foreign
                                  Language/ESL Language Instructor
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/teaching-english-or-french-second-or-foreign-language-other" style={{ color: "black" }}>
                                  Teaching English or French as a Second or
                                  Foreign Language, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/technical-teacher-education" style={{ color: "black" }}>
                                  Technical Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/technology-teacher-educationindustrial-arts-teacher-education" style={{ color: "black" }}>
                                  Technology Teacher Education/Industrial Arts
                                  Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/trade-and-industrial-teacher-education" style={{ color: "black" }}>
                                  Trade and Industrial Teacher Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/urban-education-and-leadership" style={{ color: "black" }}>
                                  Urban Education and Leadership
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/waldorfsteiner-teacher-education" style={{ color: "black" }}>
                                  Waldorf/Steiner Teacher Education
                                </a>
                              </li>
                            </ul>
                            
                            <br />
                            <hr />

 */}

                            {/* <div>
                              <h3>Engineering</h3>
                            </div>

                            <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a href="https://www.cappex.com/major/aerospace-aeronautical-and-astronauticalspace-engineering" style={{ color: "black" }}>
                                  Aerospace, Aeronautical and
                                  Astronautical/Space Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/agricultural-engineering" style={{ color: "black" }}>
                                  Agricultural Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/architectural-engineering" style={{ color: "black" }}>
                                Architectural Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/biochemical-engineering" style={{ color: "black" }}>
                                  Biochemical Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/bioengineering-and-biomedical-engineering" style={{ color: "black" }}>
                                  Bioengineering and Biomedical Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/biologicalbiosystems-engineering" style={{ color: "black" }}>
                                  Biological/Biosystems Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/ceramic-sciences-and-engineering" style={{ color: "black" }}>
                                  Ceramic Sciences and Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/chemical-engineering" style={{ color: "black" }}>
                                  Chemical Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/chemical-engineering-other" style={{ color: "black" }}>
                                  Chemical Engineering, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/chemical-and-biomolecular-engineering" style={{ color: "black" }}>
                                  Chemical and Biomolecular Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/civil-engineering-general" style={{ color: "black" }}>
                                  Civil Engineering, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/civil-engineering-other" style={{ color: "black" }}>
                                  Civil Engineering, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-engineering-general" style={{ color: "black" }}>
                                  Computer Engineering, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-engineering-other" style={{ color: "black" }}>
                                  Computer Engineering, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-hardware-engineering" style={{ color: "black" }}>
                                  Computer Hardware Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-software-engineering" style={{ color: "black" }}>
                                  Computer Software Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/construction-engineering" style={{ color: "black" }}>
                                  Construction Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/electrical-and-electronics-engineering" style={{ color: "black" }}>
                                  Electrical and Electronics Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/electrical-electronics-and-communications-engineering-other" style={{ color: "black" }}>
                                  Electrical, Electronics and Communications
                                  Engineering, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/electromechanical-engineering" style={{ color: "black" }}>
                                  Electromechanical Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/engineering-chemistry" style={{ color: "black" }}>
                                  Engineering Chemistry
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/engineering-mechanics" style={{ color: "black" }}>
                                  Engineering Mechanics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/engineering-physicsapplied-physics" style={{ color: "black" }}>
                                  Engineering Physics/Applied Physics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/engineering-science" style={{ color: "black" }}>
                                  Engineering Science
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/engineering-general" style={{ color: "black" }}>
                                  Engineering, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/engineering-other" style={{ color: "black" }}>
                                  Engineering, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/environmentalenvironmental-health-engineering" style={{ color: "black" }}>
                                  Environmental/Environmental Health Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/forest-engineering" style={{ color: "black" }}>
                                  Forest Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/geologicalgeophysical-engineering" style={{ color: "black" }}>
                                  Geological/Geophysical Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/geotechnical-and-geoenvironmental-engineering" style={{ color: "black" }}>
                                  Geotechnical and Geoenvironmental Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/industrial-engineering" style={{ color: "black" }}>
                                  Industrial Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/laser-and-optical-engineering" style={{ color: "black" }}>
                                  Laser and Optical Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/manufacturing-engineering" style={{ color: "black" }}>
                                  Manufacturing Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/materials-engineering" style={{ color: "black" }}>
                                  Materials Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/mechanical-engineering" style={{ color: "black" }}>
                                  Mechanical Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/mechatronics-robotics-and-automation-engineering" style={{ color: "black" }}>
                                  Mechatronics, Robotics, and Automation
                                  Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/metallurgical-engineering" style={{ color: "black" }}>
                                  Metallurgical Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/mining-and-mineral-engineering" style={{ color: "black" }}>
                                  Mining and Mineral Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/naval-architecture-and-marine-engineering" style={{ color: "black" }}>
                                  Naval Architecture and Marine Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/nuclear-engineering" style={{ color: "black" }}>
                                  Nuclear Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/ocean-engineering" style={{ color: "black" }}>
                                  Ocean Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/operations-research" style={{ color: "black" }}>
                                  Operations Research
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/paper-science-and-engineering" style={{ color: "black" }}>
                                  Paper Science and Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/petroleum-engineering" style={{ color: "black" }}>
                                  Petroleum Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/polymerplastics-engineering" style={{ color: "black" }}>
                                  Polymer/Plastics Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pre-engineering" style={{ color: "black" }}>
                                  Pre-Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/structural-engineering" style={{ color: "black" }}>
                                  Structural Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/surveying-engineering" style={{ color: "black" }}>
                                  Surveying Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/systems-engineering" style={{ color: "black" }}>
                                  Systems Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/telecommunications-engineering" style={{ color: "black" }}>
                                  Telecommunications Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/textile-sciences-and-engineering" style={{ color: "black" }}>
                                  Textile Sciences and Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/transportation-and-highway-engineering" style={{ color: "black" }}>
                                  Transportation and Highway Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/water-resources-engineering" style={{ color: "black" }}>
                                  Water Resources Engineering
                                </a>
                              </li>
                            </ul>
                               <br />
                              <hr />
                            */}
                            {/* ----------------------------------------------------------------------------------------------------- */}
                         
                            {/* <div>
                              <h3>
                                Engineering Technologies and Engineering-Related
                                Fields
                              </h3>
                            </div>

                            <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a href="https://www.cappex.com/major/aeronauticalaerospace-engineering-technologytechnician" style={{ color: "black" }}>
                                  Aeronautical/Aerospace Engineering
                                  Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/architectural-drafting-and-architectural-cadcadd" style={{ color: "black" }}>
                                  Architectural Drafting and Architectural
                                  CAD/CADD
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/architectural-engineering-technologytechnician" style={{ color: "black" }}>
                                  Architectural Engineering
                                  Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/automation-engineer-technologytechnician" style={{ color: "black" }}>
                                  Automation Engineer Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/automotive-engineering-technologytechnician" style={{ color: "black" }}>
                                  Automotive Engineering Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/biomedical-technologytechnician" style={{ color: "black" }}>
                                  Biomedical Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/cadcadd-drafting-andor-design-technologytechnician" style={{ color: "black" }}>
                                  CAD/CADD Drafting and/or Design
                                  Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/chemical-engineering-technologytechnician" style={{ color: "black" }}>
                                  Chemical Engineering Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/civil-drafting-and-civil-engineering-cadcadd" style={{ color: "black" }}>
                                  Civil Drafting and Civil Engineering CAD/CADD
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/civil-engineering-technologytechnician" style={{ color: "black" }}>
                                  Civil Engineering Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-engineering-technologiestechnicians-other" style={{ color: "black" }}>
                                  Computer Engineering Technologies/Technicians,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-engineering-technologytechnician" style={{ color: "black" }}>
                                  Computer Engineering Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-hardware-technologytechnician" style={{ color: "black" }}>
                                  Computer Hardware Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-software-technologytechnician" style={{ color: "black" }}>
                                  Computer Software Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-technologycomputer-systems-technology" style={{ color: "black" }}>
                                  Computer Technology/Computer Systems
                                  Technology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/construction-engineering-technologytechnician" style={{ color: "black" }}>
                                  Construction Engineering Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/drafting-and-design-technologytechnician-general" style={{ color: "black" }}>
                                  Drafting and Design Technology/Technician,
                                  General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/draftingdesign-engineering-technologiestechnicians-other" style={{ color: "black" }}>
                                  Drafting/Design Engineering
                                  Technologies/Technicians, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/electrical-and-electronic-engineering-technologiestechnicians-other" style={{ color: "black" }}>
                                  Electrical and Electronic Engineering
                                  Technologies/Technicians, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/electrical-electronic-and-communications-engineering-technologytechnician" style={{ color: "black" }}>
                                  Electrical, Electronic and Communications
                                  Engineering Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/electricalelectronics-drafting-and-electricalelectronics-cadcadd" style={{ color: "black" }}>
                                  Electrical/Electronics Drafting and
                                  Electrical/Electronics CAD/CADD
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/electromechanical-technologyelectromechanical-engineering-technology" style={{ color: "black" }}>
                                  Electromechanical Technology/Electromechanical
                                  Engineering Technology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/electromechanical-and-instrumentation-and-maintenance-technologiestechnicians-other" style={{ color: "black" }}>
                                  Electromechanical and Instrumentation and
                                  Maintenance Technologies/Technicians, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/energy-management-and-systems-technologytechnician" style={{ color: "black" }}>
                                  Energy Management and Systems
                                  Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/engineering-design" style={{ color: "black" }}>
                                  Engineering Design
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/engineering-technologies-and-engineering-related-fields-other" style={{ color: "black" }}>
                                  Engineering Technologies and
                                  Engineering-Related Fields, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/engineering-technology-general" style={{ color: "black" }}>
                                  Engineering Technology, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/engineering-related-fields-other" style={{ color: "black" }}>
                                  Engineering-Related Fields, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/engineering-related-technologies-other" style={{ color: "black" }}>
                                  Engineering-Related Technologies, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/engineeringindustrial-management" style={{ color: "black" }}>
                                  Engineering/Industrial Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/environmental-control-technologiestechnicians-other" style={{ color: "black" }}>
                                  Environmental Control
                                  Technologies/Technicians, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/environmental-engineering-technologyenvironmental-technology" style={{ color: "black" }}>
                                  Environmental Engineering
                                  Technology/Environmental Technology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/hazardous-materials-management-and-waste-technologytechnician" style={{ color: "black" }}>
                                  Hazardous Materials Management and Waste
                                  Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/heating-ventilation-air-conditioning-and-refrigeration-engineering-technologytechnician" style={{ color: "black" }}>
                                  Heating, Ventilation, Air Conditioning and
                                  Refrigeration Engineering
                                  Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/hydraulics-and-fluid-power-technologytechnician" style={{ color: "black" }}>
                                  Hydraulics and Fluid Power
                                  Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/industrial-production-technologiestechnicians-other" style={{ color: "black" }}>
                                  Industrial Production
                                  Technologies/Technicians, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/industrial-safety-technologytechnician" style={{ color: "black" }}>
                                  Industrial Safety Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/industrial-technologytechnician" style={{ color: "black" }}>
                                  Industrial Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/instrumentation-technologytechnician" style={{ color: "black" }}>
                                  Instrumentation Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/integrated-circuit-design" style={{ color: "black" }}>
                                  Integrated Circuit Design
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/laser-and-optical-technologytechnician" style={{ color: "black" }}>
                                  Laser and Optical Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/manufacturing-engineering-technologytechnician" style={{ color: "black" }}>
                                  Manufacturing Engineering
                                  Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/mechanical-drafting-and-mechanical-drafting-cadcadd" style={{ color: "black" }}>
                                  Mechanical Drafting and Mechanical Drafting
                                  CAD/CADD
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/mechanical-engineering-related-technologiestechnicians-other" style={{ color: "black" }}>
                                  Mechanical Engineering Related
                                  Technologies/Technicians, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/mechanical-engineeringmechanical-technologytechnician" style={{ color: "black" }}>
                                  Mechanical Engineering/Mechanical
                                  Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/metallurgical-technologytechnician" style={{ color: "black" }}>
                                  Metallurgical Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/mining-technologytechnician" style={{ color: "black" }}>
                                  Mining Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/mining-and-petroleum-technologiestechnicians-other" style={{ color: "black" }}>
                                  Mining and Petroleum Technologies/Technicians,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/nanotechnology" style={{ color: "black" }}>
                                  Nanotechnology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/nuclear-engineering-technologytechnician" style={{ color: "black" }}>
                                  Nuclear Engineering Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/occupational-safety-and-health-technologytechnician" style={{ color: "black" }}>
                                  Occupational Safety and Health
                                  Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/packaging-science" style={{ color: "black" }}>
                                Packaging Science
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/petroleum-technologytechnician" style={{ color: "black" }}>
                                  Petroleum Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/plastics-and-polymer-engineering-technologytechnician" style={{ color: "black" }}>
                                  Plastics and Polymer Engineering
                                  Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/quality-control-technologytechnician" style={{ color: "black" }}>
                                  Quality Control Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/quality-control-and-safety-technologiestechnicians-other" style={{ color: "black" }}>
                                  Quality Control and Safety
                                  Technologies/Technicians, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/robotics-technologytechnician" style={{ color: "black" }}>
                                  Robotics Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/semiconductor-manufacturing-technology" style={{ color: "black" }}>
                                  Semiconductor Manufacturing Technology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/solar-energy-technologytechnician" style={{ color: "black" }}>
                                  Solar Energy Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/surveying-technologysurveying" style={{ color: "black" }}>
                                  Surveying Technology/Surveying
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/telecommunications-technologytechnician" style={{ color: "black" }}>
                                  Telecommunications Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/water-quality-and-wastewater-treatment-management-and-recycling-technologytechnician" style={{ color: "black" }}>
                                  Water Quality and Wastewater Treatment
                                  Management and Recycling Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/welding-engineering-technologytechnician" style={{ color: "black" }}>
                                  Welding Engineering Technology/Technician
                                </a>
                              </li>
                            </ul>
 */}

 {/* ----------------------------------------------------------------------------------------------------- */}



                            <br /><hr />

                            {/* <div>
                              <h3>English Language and Literature/Letters</h3>
                            </div>

                            <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a href="https://www.cappex.com/major/american-literature-united-states" style={{ color: "black" }}>
                                  American Literature (United States)
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/childrens-and-adolescent-literature" style={{ color: "black" }}>
                                  Children's and Adolescent Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/creative-writing" style={{ color: "black" }}>
                                  Creative Writing
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/english-composition" style={{ color: "black" }}>
                                  English Composition
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/english-language-and-literature-general" style={{ color: "black" }}>
                                  English Language and Literature, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/english-language-and-literatureletters-other" style={{ color: "black" }}>
                                  English Language and Literature/Letters, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/english-literature-british-and-commonwealth" style={{ color: "black" }}>
                                  English Literature (British and Commonwealth)
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/general-literature" style={{ color: "black" }}>
                                  General Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/literature-other" style={{ color: "black" }}>
                                  Literature, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/professional-technical-business-and-scientific-writing" style={{ color: "black" }}>
                                  Professional, Technical, Business, and
                                  Scientific Writing
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/rhetoric-and-composition" style={{ color: "black" }}>
                                  Rhetoric and Composition
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/rhetoric-and-compositionwriting-studies-other" style={{ color: "black" }}>
                                  Rhetoric and Composition/Writing Studies,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/technical-and-business-writing" style={{ color: "black" }}>
                                  Technical and Business Writing
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/writing-general" style={{ color: "black" }}>
                                  Writing, General
                                </a>
                              </li>
                            </ul>
                            <br />
<hr /> */}

                            {/* <div>
                              <h3>
                                Family and Consumer Sciences/Human Sciences
                              </h3>
                            </div>

                            <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a href="https://www.cappex.com/major/adult-development-and-aging" style={{ color: "black" }}>
                                  Adult Development and Aging
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/apparel-and-textile-manufacture" style={{ color: "black" }}>
                                  Apparel and Textile Manufacture
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/apparel-and-textile-marketing-management" style={{ color: "black" }}>
                                  Apparel and Textile Marketing Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/apparel-and-textiles-general" style={{ color: "black" }}>
                                  Apparel and Textiles, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/apparel-and-textiles-other" style={{ color: "black" }}>
                                  Apparel and Textiles, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/business-family-and-consumer-scienceshuman-sciences" style={{ color: "black" }}>
                                  Business Family and Consumer Sciences/Human
                                  Sciences
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/child-care-providerassistant" style={{ color: "black" }}>
                                  Child Care Provider/Assistant
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/child-care-and-support-services-management" style={{ color: "black" }}>
                                  Child Care and Support Services Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/child-development" style={{ color: "black" }}>
                                  Child Development
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/consumer-economics" style={{ color: "black" }}>
                                  Consumer Economics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/consumer-merchandisingretailing-management" style={{ color: "black" }}>
                                  Consumer Merchandising/Retailing Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/consumer-services-and-advocacy" style={{ color: "black" }}>
                                  Consumer Services and Advocacy
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/developmental-services-worker" style={{ color: "black" }}>
                                  Developmental Services Worker
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/facilities-planning-and-management" style={{ color: "black" }}>
                                  Facilities Planning and Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/family-resource-management-studies-general" style={{ color: "black" }}>
                                  Family Resource Management Studies, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/family-systems" style={{ color: "black" }}>
                                  Family Systems
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/family-and-community-services" style={{ color: "black" }}>
                                  Family and Community Services
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/family-and-consumer-economics-and-related-services-other" style={{ color: "black" }}>
                                  Family and Consumer Economics and Related
                                  Services, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/family-and-consumer-scienceshuman-sciences-business-services-other" style={{ color: "black" }}>
                                  Family and Consumer Sciences/Human Sciences
                                  Business Services, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/family-and-consumer-scienceshuman-sciences-communication" style={{ color: "black" }}>
                                  Family and Consumer Sciences/Human Sciences
                                  Communication
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/family-and-consumer-scienceshuman-sciences-general" style={{ color: "black" }}>
                                  Family and Consumer Sciences/Human Sciences,
                                  General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/family-and-consumer-scienceshuman-sciences-other" style={{ color: "black" }}>
                                  Family and Consumer Sciences/Human Sciences,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/fashion-and-fabric-consultant" style={{ color: "black" }}>
                                  Fashion and Fabric Consultant
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/foods-nutrition-and-related-services-other" style={{ color: "black" }}>
                                  Foods, Nutrition, and Related Services, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/foods-nutrition-and-wellness-studies-general" style={{ color: "black" }}>
                                  Foods, Nutrition, and Wellness Studies,
                                  General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/foodservice-systems-administrationmanagement" style={{ color: "black" }}>
                                  Foodservice Systems Administration/Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/housing-and-human-environments-general" style={{ color: "black" }}>
                                  Housing and Human Environments, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/housing-and-human-environments-other" style={{ color: "black" }}>
                                  Housing and Human Environments, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/human-development-and-family-studies-general" style={{ color: "black" }}>
                                  Human Development and Family Studies, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/human-development-family-studies-and-related-services-other" style={{ color: "black" }}>
                                  Human Development, Family Studies, and Related
                                  Services, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/human-nutrition" style={{ color: "black" }}>
                                  Human Nutrition
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/textile-science" style={{ color: "black" }}>
                                  Textile Science
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/work-and-family-studies" style={{ color: "black" }}>
                                  Work and Family Studies
                                </a>
                              </li>
                            </ul>
                            <br />
<hr /> */}

                            {/* <div>
                              <h3>
                                Foreign Languages, Literatures, and Linguistics
                              </h3>
                            </div>

                            <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a href="https://www.cappex.com/major/african-languages-literatures-and-linguistics" style={{ color: "black" }}>
                                  African Languages, Literatures, and
                                  Linguistics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/american-indiannative-american-languages-literatures-and-linguistics" style={{ color: "black" }}>
                                  American Indian/Native American Languages,
                                  Literatures, and Linguistics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/american-sign-language-asl" style={{ color: "black" }}>
                                  American Sign Language (ASL)
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/american-sign-language-other" style={{ color: "black" }}>
                                  American Sign Language, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/ancient-near-eastern-and-biblical-languages-literatures-and-linguistics" style={{ color: "black" }}>
                                  Ancient Near Eastern and Biblical Languages,
                                  Literatures, and Linguistics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/ancientclassical-greek-language-and-literature" style={{ color: "black" }}>
                                  Ancient/Classical Greek Language and
                                  Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/applied-linguistics" style={{ color: "black" }}>
                                  Applied Linguistics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/arabic-language-and-literature" style={{ color: "black" }}>
                                  Arabic Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/bosnian-serbian-and-croatian-languages-and-literatures" style={{ color: "black" }}>
                                  Bosnian, Serbian, and Croatian Languages and
                                  Literatures
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/celtic-languages-literatures-and-linguistics" style={{ color: "black" }}>
                                  Celtic Languages, Literatures, and Linguistics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/chinese-language-and-literature" style={{ color: "black" }}>
                                  Chinese Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/classics-and-classical-languages-literatures-and-linguistics-general" style={{ color: "black" }}>
                                  Classics and Classical Languages, Literatures,
                                  and Linguistics, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/classics-and-classical-languages-literatures-and-linguistics-other" style={{ color: "black" }}>
                                  Classics and Classical Languages, Literatures,
                                  and Linguistics, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/comparative-literature" style={{ color: "black" }}>
                                  Comparative Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/czech-language-and-literature" style={{ color: "black" }}>
                                  Czech Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/danish-language-and-literature" style={{ color: "black" }}>
                                  Danish Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/dutchflemish-language-and-literature" style={{ color: "black" }}>
                                  Dutch/Flemish Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/east-asian-languages-literatures-and-linguistics-general" style={{ color: "black" }}>
                                  East Asian Languages, Literatures, and
                                  Linguistics, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/east-asian-languages-literatures-and-linguistics-other" style={{ color: "black" }}>
                                  East Asian Languages, Literatures, and
                                  Linguistics, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/filipinotagalog-language-and-literature" style={{ color: "black" }}>
                                  Filipino/Tagalog Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/foreign-languages-and-literatures-general" style={{ color: "black" }}>
                                  Foreign Languages and Literatures, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/foreign-languages-literatures-and-linguistics-other" style={{ color: "black" }}>
                                  Foreign Languages, Literatures, and
                                  Linguistics, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/french-language-and-literature" style={{ color: "black" }}>
                                  French Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/german-language-and-literature" style={{ color: "black" }}>
                                  German Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/germanic-languages-literatures-and-linguistics-general" style={{ color: "black" }}>
                                  Germanic Languages, Literatures, and
                                  Linguistics, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/germanic-languages-literatures-and-linguistics-other" style={{ color: "black" }}>
                                  Germanic Languages, Literatures, and
                                  Linguistics, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/hebrew-language-and-literature" style={{ color: "black" }}>
                                  Hebrew Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/hindi-language-and-literature" style={{ color: "black" }}>
                                  Hindi Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/hispanic-and-latin-american-languages-literatures-and-linguistics-general" style={{ color: "black" }}>
                                  Hispanic and Latin American Languages,
                                  Literatures, and Linguistics, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/indonesianmalay-languages-and-literatures" style={{ color: "black" }}>
                                  Indonesian/Malay Languages and Literatures
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/iranian-languages-literatures-and-linguistics" style={{ color: "black" }}>
                                  Iranian Languages, Literatures, and
                                  Linguistics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/italian-language-and-literature" style={{ color: "black" }}>
                                  Italian Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/japanese-language-and-literature" style={{ color: "black" }}>
                                  Japanese Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/korean-language-and-literature" style={{ color: "black" }}>
                                  Korean Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/language-interpretation-and-translation" style={{ color: "black" }}>
                                  Language Interpretation and Translation
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/latin-language-and-literature" style={{ color: "black" }}>
                                  Latin Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/linguistic-comparative-and-related-language-studies-and-services-other" style={{ color: "black" }}>
                                  Linguistic, Comparative, and Related Language
                                  Studies and Services, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/linguistics" style={{ color: "black" }}>
                                  Linguistics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/linguistics-of-asl-and-other-sign-languages" style={{ color: "black" }}>
                                  Linguistics of ASL and Other Sign Languages
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/middlenear-eastern-and-semitic-languages-literatures-and-linguistics-other" style={{ color: "black" }}>
                                  Middle/Near Eastern and Semitic Languages,
                                  Literatures, and Linguistics, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/middlenear-eastern-and-semitic-languages-literatures-and-linguistics-other" style={{ color: "black" }}>
                                  Middle/Near Eastern and Semitic Languages,
                                  Literatures, and Linguistics, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/modern-greek-language-and-literature" style={{ color: "black" }}>
                                  Modern Greek Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/norwegian-language-and-literature" style={{ color: "black" }}>
                                  Norwegian Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/polish-language-and-literature" style={{ color: "black" }}>
                                  Polish Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/portuguese-language-and-literature" style={{ color: "black" }}>
                                  Portuguese Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/punjabi-language-and-literature" style={{ color: "black" }}>
                                  Punjabi Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/romance-languages-literatures-and-linguistics-general" style={{ color: "black" }}>
                                  Romance Languages, Literatures, and
                                  Linguistics, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/romance-languages-literatures-and-linguistics-other" style={{ color: "black" }}>
                                  Romance Languages, Literatures, and
                                  Linguistics, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/russian-language-and-literature" style={{ color: "black" }}>
                                  Russian Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/sanskrit-and-classical-indian-languages-literatures-and-linguistics" style={{ color: "black" }}>
                                  Sanskrit and Classical Indian Languages,
                                  Literatures, and Linguistics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/scandinavian-languages-literatures-and-linguistics" style={{ color: "black" }}>
                                  Scandinavian Languages, Literatures, and
                                  Linguistics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/sign-language-interpretation-and-translation" style={{ color: "black" }}>
                                  Sign Language Interpretation and Translation
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/slavic-languages-literatures-and-linguistics-general" style={{ color: "black" }}>
                                  Slavic Languages, Literatures, and
                                  Linguistics, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/slavic-baltic-and-albanian-languages-literatures-and-linguistics-other" style={{ color: "black" }}>
                                  Slavic, Baltic, and Albanian Languages,
                                  Literatures, and Linguistics, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/south-asian-languages-literatures-and-linguistics-general" style={{ color: "black" }}>
                                  South Asian Languages, Literatures, and
                                  Linguistics, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/spanish-language-and-literature" style={{ color: "black" }}>
                                  Spanish Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/swedish-language-and-literature" style={{ color: "black" }}>
                                  Swedish Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/turkish-language-and-literature" style={{ color: "black" }}>
                                  Turkish Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/uralic-languages-literatures-and-linguistics" style={{ color: "black" }}>
                                  Uralic Languages, Literatures, and Linguistics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/urdu-language-and-literature" style={{ color: "black" }}>
                                  Urdu Language and Literature
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/vietnamese-language-and-literature" style={{ color: "black" }}>
                                  Vietnamese Language and Literature
                                </a>
                              </li>
                            </ul>
                            <br />
<hr /> */}

                            {/* <div>
                              <h3>Health Professions and Related Programs</h3>
                            </div>

                            <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a href="https://www.cappex.com/major/acupuncture-and-oriental-medicine" style={{ color: "black" }}>
                                  Acupuncture and Oriental Medicine
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/adult-health-nursenursing" style={{ color: "black" }}>
                                  Adult Health Nurse/Nursing
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/advanced-general-dentistry" style={{ color: "black" }}>
                                  Advanced General Dentistry
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/advancedgraduate-dentistry-and-oral-sciences-other" style={{ color: "black" }}>
                                  Advanced/Graduate Dentistry and Oral Sciences,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/allied-health-diagnostic-intervention-and-treatment-professions-other" style={{ color: "black" }}>
                                  Allied Health Diagnostic, Intervention, and
                                  Treatment Professions, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/allied-health-and-medical-assisting-services-other" style={{ color: "black" }}>
                                  Allied Health and Medical Assisting Services,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/alternative-and-complementary-medical-support-services-other" style={{ color: "black" }}>
                                  Alternative and Complementary Medical Support
                                  Services, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/alternative-and-complementary-medicine-and-medical-systems-general" style={{ color: "black" }}>
                                  Alternative and Complementary Medicine and
                                  Medical Systems, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/alternative-and-complementary-medicine-and-medical-systems-other" style={{ color: "black" }}>
                                  Alternative and Complementary Medicine and
                                  Medical Systems, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/anesthesiologist-assistant" style={{ color: "black" }}>
                                  Anesthesiologist Assistant
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/animal-assisted-therapy" style={{ color: "black" }}>
                                  Animal-Assisted Therapy
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/art-therapytherapist" style={{ color: "black" }}>
                                  Art Therapy/Therapist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/assistiveaugmentative-technology-and-rehabilitation-engineering" style={{ color: "black" }}>
                                  Assistive/Augmentative Technology and
                                  Rehabilitation Engineering
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/athletic-trainingtrainer" style={{ color: "black" }}>
                                  Athletic Training/Trainer
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/audiologyaudiologist" style={{ color: "black" }}>
                                  Audiology/Audiologist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/audiologyaudiologist-and-speech-language-pathologypathologist" style={{ color: "black" }}>
                                  Audiology/Audiologist and Speech-Language
                                  Pathology/Pathologist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/ayurvedic-medicineayurveda" style={{ color: "black" }}>
                                  Ayurvedic Medicine/Ayurveda
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/behavioral-aspects-of-health" style={{ color: "black" }}>
                                  Behavioral Aspects of Health
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/bioethicsmedical-ethics" style={{ color: "black" }}>
                                  Bioethics/Medical Ethics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/blood-bank-technology-specialist" style={{ color: "black" }}>
                                  Blood Bank Technology Specialist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/cardiopulmonary-technologytechnologist" style={{ color: "black" }}>
                                  Cardiopulmonary Technology/Technologist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/cardiovascular-technologytechnologist" style={{ color: "black" }}>
                                  Cardiovascular Technology/Technologist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/chiropractic" style={{ color: "black" }}>
                                  Chiropractic
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/chiropractic-assistanttechnician" style={{ color: "black" }}>
                                  Chiropractic Assistant/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/clinical-laboratory-sciencemedical-technologytechnologist" style={{ color: "black" }}>
                                  Clinical Laboratory Science/Medical
                                  Technology/Technologist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/clinical-nurse-leader" style={{ color: "black" }}>
                                  Clinical Nurse Leader
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/clinical-nurse-specialist" style={{ color: "black" }}>
                                  Clinical Nurse Specialist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/clinical-nutritionnutritionist" style={{ color: "black" }}>
                                  Clinical Nutrition/Nutritionist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/clinical-pastoral-counselingpatient-counseling" style={{ color: "black" }}>
                                  Clinical Pastoral Counseling/Patient
                                  Counseling
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/clinical-research-coordinator" style={{ color: "black" }}>
                                  Clinical Research Coordinator
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/clinical-and-industrial-drug-development" style={{ color: "black" }}>
                                  Clinical and Industrial Drug Development
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/clinical-hospital-and-managed-care-pharmacy" style={{ color: "black" }}>
                                  Clinical, Hospital, and Managed Care Pharmacy
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/clinicalmedical-laboratory-assistant" style={{ color: "black" }}>
                                  Clinical/Medical Laboratory Assistant
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/clinicalmedical-laboratory-science-and-allied-professions-other" style={{ color: "black" }}>
                                  Clinical/Medical Laboratory Science and Allied
                                  Professions, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/clinicalmedical-laboratory-technician" style={{ color: "black" }}>
                                  Clinical/Medical Laboratory Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/clinicalmedical-social-work" style={{ color: "black" }}>
                                  Clinical/Medical Social Work
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/communication-disorders-sciences-and-services-other" style={{ color: "black" }}>
                                  Communication Disorders Sciences and Services,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/communication-sciences-and-disorders-general" style={{ color: "black" }}>
                                  Communication Sciences and Disorders, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/community-health-servicesliaisoncounseling" style={{ color: "black" }}>
                                  Community Health Services/Liaison/Counseling
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/community-health-and-preventive-medicine" style={{ color: "black" }}>
                                  Community Health and Preventive Medicine
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/comparative-and-laboratory-animal-medicine" style={{ color: "black" }}>
                                  Comparative and Laboratory Animal Medicine
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/critical-care-nursing" style={{ color: "black" }}>
                                  Critical Care Nursing
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/cytogeneticsgeneticsclinical-genetics-technologytechnologist" style={{ color: "black" }}>
                                  Cytogenetics/Genetics/Clinical Genetics
                                  Technology/Technologist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/cytotechnologycytotechnologist" style={{ color: "black" }}>
                                  Cytotechnology/Cytotechnologist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/dance-therapytherapist" style={{ color: "black" }}>
                                  Dance Therapy/Therapist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/dental-assistingassistant" style={{ color: "black" }}>
                                  Dental Assisting/Assistant
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/dental-clinical-sciences-general" style={{ color: "black" }}>
                                  Dental Clinical Sciences, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/dental-hygienehygienist" style={{ color: "black" }}>
                                  Dental Hygiene/Hygienist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/dental-laboratory-technologytechnician" style={{ color: "black" }}>
                                  Dental Laboratory Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/dental-materials" style={{ color: "black" }}>
                                  Dental Materials
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/dental-public-health-and-education" style={{ color: "black" }}>
                                  Dental Public Health and Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/dental-services-and-allied-professions-other" style={{ color: "black" }}>
                                  Dental Services and Allied Professions, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/dentistry" style={{ color: "black" }}>
                                  Dentistry
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/diagnostic-medical-sonographysonographer-and-ultrasound-technician" style={{ color: "black" }}>
                                  Diagnostic Medical Sonography/Sonographer and
                                  Ultrasound Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/dietetic-technician" style={{ color: "black" }}>
                                  Dietetic Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/dietetics-and-clinical-nutrition-services-other" style={{ color: "black" }}>
                                  Dietetics and Clinical Nutrition Services,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/dieteticsdietitian" style={{ color: "black" }}>
                                  Dietetics/Dietitian
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/dietitian-assistant" style={{ color: "black" }}>
                                  Dietitian Assistant
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/direct-entry-midwifery" style={{ color: "black" }}>
                                  Direct Entry Midwifery
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/electrocardiograph-technologytechnician" style={{ color: "black" }}>
                                  Electrocardiograph Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/electroneurodiagnosticelectroencephalographic-technologytechnologist" style={{ color: "black" }}>
                                  Electroneurodiagnostic/Electroencephalographic
                                  Technology/Technologist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/emergency-care-attendant-emt-ambulance" style={{ color: "black" }}>
                                  Emergency Care Attendant (EMT Ambulance)
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/emergency-medical-technologytechnician-emt-paramedic" style={{ color: "black" }}>
                                  Emergency Medical Technology/Technician (EMT
                                  Paramedic)
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/emergency-roomtrauma-nursing" style={{ color: "black" }}>
                                  Emergency Room/Trauma Nursing
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/endodonticsendodontology" style={{ color: "black" }}>
                                  Endodontics/Endodontology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/energy-and-biologically-based-therapies-other" style={{ color: "black" }}>
                                  Energy and Biologically Based Therapies, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/environmental-health" style={{ color: "black" }}>
                                  Environmental Health
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/family-practice-nursenurse-practitioner" style={{ color: "black" }}>
                                  Family Practice Nurse/Nurse Practitioner
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/family-practice-nursenursing" style={{ color: "black" }}>
                                  Family Practice Nurse/Nursing
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/genegenetic-therapy" style={{ color: "black" }}>
                                  Gene/Genetic Therapy
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/genetic-counselingcounselor" style={{ color: "black" }}>
                                  Genetic Counseling/Counselor
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/geriatric-nursenursing" style={{ color: "black" }}>
                                  Geriatric Nurse/Nursing
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/health-aide" style={{ color: "black" }}>
                                  Health Aide
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/health-aidesattendantsorderlies-other" style={{ color: "black" }}>
                                  Health Aides/Attendants/Orderlies, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/health-informationmedical-records-administrationadministrator" style={{ color: "black" }}>
                                  Health Information/Medical Records
                                  Administration/Administrator
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/health-informationmedical-records-technologytechnician" style={{ color: "black" }}>
                                  Health Information/Medical Records
                                  Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/health-professions-and-related-clinical-sciences-other" style={{ color: "black" }}>
                                  Health Professions and Related Clinical
                                  Sciences, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/health-services-administration" style={{ color: "black" }}>
                                  Health Services Administration
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/health-servicesallied-healthhealth-sciences-general" style={{ color: "black" }}>
                                  Health Services/Allied Health/Health Sciences,
                                  General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/health-unit-coordinatorward-clerk" style={{ color: "black" }}>
                                  Health Unit Coordinator/Ward Clerk
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/health-unit-managerward-supervisor" style={{ color: "black" }}>
                                  Health Unit Manager/Ward Supervisor
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/health-and-medical-administrative-services-other" style={{ color: "black" }}>
                                  Health and Medical Administrative Services,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/health-and-wellness-general" style={{ color: "black" }}>
                                  Health and Wellness, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/healthhealth-care-administrationmanagement" style={{ color: "black" }}>
                                  Health/Health Care Administration/Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/healthmedical-physics" style={{ color: "black" }}>
                                  Health/Medical Physics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/healthmedical-preparatory-programs-other" style={{ color: "black" }}>
                                  Health/Medical Preparatory Programs, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/hearing-instrument-specialist" style={{ color: "black" }}>
                                  Hearing Instrument Specialist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/hematology-technologytechnician" style={{ color: "black" }}>
                                  Hematology Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/herbalismherbalist" style={{ color: "black" }}>
                                  Herbalism/Herbalist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/histologic-technician" style={{ color: "black" }}>
                                  Histologic Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/histologic-technologyhistotechnologist" style={{ color: "black" }}>
                                  Histologic Technology/Histotechnologist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/holistic-health" style={{ color: "black" }}>
                                  Holistic Health
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/home-health-aidehome-attendant" style={{ color: "black" }}>
                                  Home Health Aide/Home Attendant
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/homeopathic-medicinehomeopathy" style={{ color: "black" }}>
                                  Homeopathic Medicine/Homeopathy
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/hospital-and-health-care-facilities-administrationmanagement" style={{ color: "black" }}>
                                  Hospital and Health Care Facilities
                                  Administration/Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/industrial-and-physical-pharmacy-and-cosmetic-sciences" style={{ color: "black" }}>
                                  Industrial and Physical Pharmacy and Cosmetic
                                  Sciences
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/international-public-healthinternational-health" style={{ color: "black" }}>
                                  International Public Health/International
                                  Health
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/kinesiotherapykinesiotherapist" style={{ color: "black" }}>
                                  Kinesiotherapy/Kinesiotherapist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/lactation-consultant" style={{ color: "black" }}>
                                  Lactation Consultant
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/large-animalfood-animal-and-equine-surgery-and-medicine" style={{ color: "black" }}>
                                  Large Animal/Food Animal and Equine Surgery
                                  and Medicine
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/licensed-practicalvocational-nurse-training" style={{ color: "black" }}>
                                  Licensed Practical/Vocational Nurse Training
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/licensed-practicalvocational-nurse-training-lpn-lvn-cert-dipl-aas" style={{ color: "black" }}>
                                  Licensed Practical/Vocational Nurse Training
                                  (LPN, LVN, Cert, Dipl, AAS)
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/long-term-care-administrationmanagement" style={{ color: "black" }}>
                                  Long Term Care Administration/Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/magnetic-resonance-imaging-mri-technologytechnician" style={{ color: "black" }}>
                                  Magnetic Resonance Imaging (MRI)
                                  Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/mammography-techniciantechnology" style={{ color: "black" }}>
                                  Mammography Technician/Technology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/marriage-and-family-therapycounseling" style={{ color: "black" }}>
                                  Marriage and Family Therapy/Counseling
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/massage-therapytherapeutic-massage" style={{ color: "black" }}>
                                  Massage Therapy/Therapeutic Massage
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/maternal-and-child-health" style={{ color: "black" }}>
                                  Maternal and Child Health
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/maternalchild-health-and-neonatal-nursenursing" style={{ color: "black" }}>
                                  Maternal/Child Health and Neonatal
                                  Nurse/Nursing
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/medical-administrativeexecutive-assistant-and-medical-secretary" style={{ color: "black" }}>
                                  Medical Administrative/Executive Assistant and
                                  Medical Secretary
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/medical-illustration-and-informatics-other" style={{ color: "black" }}>
                                  Medical Illustration and Informatics, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/medical-illustrationmedical-illustrator" style={{ color: "black" }}>
                                  Medical Illustration/Medical Illustrator
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/medical-informatics" style={{ color: "black" }}>
                                  Medical Informatics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/medical-insurance-coding-specialistcoder" style={{ color: "black" }}>
                                  Medical Insurance Coding Specialist/Coder
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/medical-insurance-specialistmedical-biller" style={{ color: "black" }}>
                                  Medical Insurance Specialist/Medical Biller
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/medical-office-assistantspecialist" style={{ color: "black" }}>
                                  Medical Office Assistant/Specialist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/medical-office-computer-specialistassistant" style={{ color: "black" }}>
                                  Medical Office Computer Specialist/Assistant
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/medical-office-managementadministration" style={{ color: "black" }}>
                                  Medical Office Management/Administration
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/medical-radiologic-technologyscience-radiation-therapist" style={{ color: "black" }}>
                                  Medical Radiologic Technology/Science -
                                  Radiation Therapist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/medical-scientist" style={{ color: "black" }}>
                                  Medical Scientist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/medical-staff-services-technologytechnician" style={{ color: "black" }}>
                                  Medical Staff Services Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/medical-transcriptiontranscriptionist" style={{ color: "black" }}>
                                  Medical Transcription/Transcriptionist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/medicalclinical-assistant" style={{ color: "black" }}>
                                  Medical/Clinical Assistant
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/medicalhealth-management-and-clinical-assistantspecialist" style={{ color: "black" }}>
                                  Medical/Health Management and Clinical
                                  Assistant/Specialist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/medication-aide" style={{ color: "black" }}>
                                  Medication Aide
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/medicinal-and-pharmaceutical-chemistry" style={{ color: "black" }}>
                                  Medicinal and Pharmaceutical Chemistry
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/medicine" style={{ color: "black" }}>
                                  Medicine
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/mental-health-counselingcounselor" style={{ color: "black" }}>
                                  Mental Health Counseling/Counselor
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/mental-and-social-health-services-and-allied-professions-other" style={{ color: "black" }}>
                                  Mental and Social Health Services and Allied
                                  Professions, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/movement-therapy-and-movement-education" style={{ color: "black" }}>
                                  Movement Therapy and Movement Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/movement-and-mind-body-therapies-and-education-other" style={{ color: "black" }}>
                                  Movement and Mind-Body Therapies and
                                  Education, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/music-therapytherapist" style={{ color: "black" }}>
                                  Music Therapy/Therapist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/natural-products-chemistry-and-pharmacognosy" style={{ color: "black" }}>
                                  Natural Products Chemistry and Pharmacognosy
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/naturopathic-medicinenaturopathy" style={{ color: "black" }}>
                                  Naturopathic Medicine/Naturopathy
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/nuclear-medical-technologytechnologist" style={{ color: "black" }}>
                                  Nuclear Medical Technology/Technologist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/nurse-anesthetist" style={{ color: "black" }}>
                                  Nurse Anesthetist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/nurse-midwifenursing-midwifery" style={{ color: "black" }}>
                                  Nurse Midwife/Nursing Midwifery
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/nursenursing-assistantaide-and-patient-care-assistant" style={{ color: "black" }}>
                                  Nurse/Nursing Assistant/Aide and Patient Care
                                  Assistant
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/nursing-administration" style={{ color: "black" }}>
                                  Nursing Administration
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/nursing-administration-msn-ms-phd" style={{ color: "black" }}>
                                  Nursing Administration (MSN, MS, PhD)
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/nursing-assistantaide-and-patient-care-assistantaide" style={{ color: "black" }}>
                                  Nursing Assistant/Aide and Patient Care
                                  Assistant/Aide
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/nursing-education" style={{ color: "black" }}>
                                  Nursing Education
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/nursing-practice" style={{ color: "black" }}>
                                  Nursing Practice
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/nursing-school" style={{ color: "black" }}>
                                  Nursing School
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/nursing-science" style={{ color: "black" }}>
                                  Nursing Science
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/nursing-science-ms-phd" style={{ color: "black" }}>
                                  Nursing Science (MS, PhD)
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/nursing-other" style={{ color: "black" }}>
                                  Nursing, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/nursingregistered-nurse-rn-asn-bsn-msn" style={{ color: "black" }}>
                                  Nursing/Registered Nurse (RN, ASN, BSN, MSN)
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/occupational-health-and-industrial-hygiene" style={{ color: "black" }}>
                                  Occupational Health and Industrial Hygiene
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/occupational-therapist-assistant" style={{ color: "black" }}>
                                  Occupational Therapist Assistant
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/occupational-therapytherapist" style={{ color: "black" }}>
                                  Occupational Therapy/Therapist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/occupational-and-environmental-health-nursing" style={{ color: "black" }}>
                                  Occupational and Environmental Health Nursing
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/ophthalmic-techniciantechnologist" style={{ color: "black" }}>
                                  Ophthalmic Technician/Technologist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/ophthalmic-and-optometric-support-services-and-allied-professions-other" style={{ color: "black" }}>
                                  Ophthalmic and Optometric Support Services and
                                  Allied Professions, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/opticianryophthalmic-dispensing-optician" style={{ color: "black" }}>
                                  Opticianry/Ophthalmic Dispensing Optician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/optometric-technicianassistant" style={{ color: "black" }}>
                                  Optometric Technician/Assistant
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/optometry" style={{ color: "black" }}>
                                  Optometry
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/oral-biology-and-oral-and-maxillofacial-pathology" style={{ color: "black" }}>
                                  Oral Biology and Oral and Maxillofacial
                                  Pathology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/oralmaxillofacial-surgery" style={{ color: "black" }}>
                                  Oral/Maxillofacial Surgery
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/orthodonticsorthodontology" style={{ color: "black" }}>
                                  Orthodontics/Orthodontology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/orthopticsorthoptist" style={{ color: "black" }}>
                                  Orthoptics/Orthoptist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/orthotistprosthetist" style={{ color: "black" }}>
                                  Orthotist/Prosthetist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/osteopathic-medicineosteopathy" style={{ color: "black" }}>
                                  Osteopathic Medicine/Osteopathy
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/palliative-care-nursing" style={{ color: "black" }}>
                                  Palliative Care Nursing
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pathologypathologist-assistant" style={{ color: "black" }}>
                                  Pathology/Pathologist Assistant
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pediatric-dentistrypedodontics" style={{ color: "black" }}>
                                  Pediatric Dentistry/Pedodontics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pediatric-nursenursing" style={{ color: "black" }}>
                                  Pediatric Nurse/Nursing
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/perfusion-technologyperfusionist" style={{ color: "black" }}>
                                  Perfusion Technology/Perfusionist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/periodonticsperiodontology" style={{ color: "black" }}>
                                  Periodontics/Periodontology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/perioperativeoperating-room-and-surgical-nursenursing" style={{ color: "black" }}>
                                  Perioperative/Operating Room and Surgical
                                  Nurse/Nursing
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pharmaceutical-marketing-and-management" style={{ color: "black" }}>
                                  Pharmaceutical Marketing and Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pharmaceutical-sciences" style={{ color: "black" }}>
                                  Pharmaceutical Sciences
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pharmaceutics-and-drug-design" style={{ color: "black" }}>
                                  Pharmaceutics and Drug Design
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pharmacoeconomicspharmaceutical-economics" style={{ color: "black" }}>
                                  Pharmacoeconomics/Pharmaceutical Economics
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pharmacy" style={{ color: "black" }}>
                                  Pharmacy
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pharmacy-administration-and-pharmacy-policy-and-regulatory-affairs" style={{ color: "black" }}>
                                  Pharmacy Administration and Pharmacy Policy
                                  and Regulatory Affairs
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pharmacy-technicianassistant" style={{ color: "black" }}>
                                  Pharmacy Technician/Assistant
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pharmacy-pharmaceutical-sciences-and-administration-other" style={{ color: "black" }}>
                                  Pharmacy, Pharmaceutical Sciences, and
                                  Administration, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/phlebotomy-technicianphlebotomist" style={{ color: "black" }}>
                                  Phlebotomy Technician/Phlebotomist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/physical-therapy-technicianassistant" style={{ color: "black" }}>
                                  Physical Therapy Technician/Assistant
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/physical-therapytherapist" style={{ color: "black" }}>
                                  Physical Therapy/Therapist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/physician-assistant" style={{ color: "black" }}>
                                  Physician Assistant
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/podiatric-medicinepodiatry" style={{ color: "black" }}>
                                  Podiatric Medicine/Podiatry
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/polysomnography" style={{ color: "black" }}>
                                  Polysomnography
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/practical-nursing-vocational-nursing-and-nursing-assistants-other" style={{ color: "black" }}>
                                  Practical Nursing, Vocational Nursing and
                                  Nursing Assistants, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pre-chiropractic-studies" style={{ color: "black" }}>
                                  Pre-Chiropractic Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pre-dentistry-studies" style={{ color: "black" }}>
                                  Pre-Dentistry Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pre-medicinepre-medical-studies" style={{ color: "black" }}>
                                  Pre-Medicine/Pre-Medical Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pre-nursing-studies" style={{ color: "black" }}>
                                  Pre-Nursing Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pre-occupational-therapy-studies" style={{ color: "black" }}>
                                  Pre-Occupational Therapy Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pre-optometry-studies" style={{ color: "black" }}>
                                  Pre-Optometry Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pre-pharmacy-studies" style={{ color: "black" }}>
                                  Pre-Pharmacy Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pre-veterinary-studies" style={{ color: "black" }}>
                                  Pre-Physical Therapy Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pre-veterinary-studies" style={{ color: "black" }}>
                                  Pre-Veterinary Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/prosthodonticsprosthodontology" style={{ color: "black" }}>
                                  Prosthodontics/Prosthodontology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/psychiatricmental-health-nursenursing" style={{ color: "black" }}>
                                  Psychiatric/Mental Health Nurse/Nursing
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/psychiatricmental-health-services-technician" style={{ color: "black" }}>
                                  Psychiatric/Mental Health Services Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/psychoanalysis-and-psychotherapy" style={{ color: "black" }}>
                                  Psychoanalysis and Psychotherapy
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/public-health-education-and-promotion" style={{ color: "black" }}>
                                  Public Health Education and Promotion
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/public-health-general" style={{ color: "black" }}>
                                  Public Health, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/public-health-other" style={{ color: "black" }}>
                                  Public Health, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/public-healthcommunity-nursenursing" style={{ color: "black" }}>
                                  Public Health/Community Nurse/Nursing
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/radiation-protectionhealth-physics-technician" style={{ color: "black" }}>
                                  Radiation Protection/Health Physics Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/radiologic-technologyscience-radiographer" style={{ color: "black" }}>
                                  Radiologic Technology/Science - Radiographer
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/radiologist-assistant" style={{ color: "black" }}>
                                  Radiologist Assistant
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/registered-nursing-nursing-administration-nursing-research-and-clinical-nursing-other" style={{ color: "black" }}>
                                  Registered Nursing, Nursing Administration,
                                  Nursing Research and Clinical Nursing, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/registered-nursingregistered-nurse" style={{ color: "black" }}>
                                  Registered Nursing/Registered Nurse
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/rehabilitation-aide" style={{ color: "black" }}>
                                  Rehabilitation Aide
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/rehabilitation-science" style={{ color: "black" }}>
                                  Rehabilitation Science
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/rehabilitation-and-therapeutic-professions-other" style={{ color: "black" }}>
                                  Rehabilitation and Therapeutic Professions,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/renaldialysis-technologisttechnician" style={{ color: "black" }}>
                                  Renal/Dialysis Technologist/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/respiratory-care-therapytherapist" style={{ color: "black" }}>
                                  Respiratory Care Therapy/Therapist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/respiratory-therapy-technicianassistant" style={{ color: "black" }}>
                                  Respiratory Therapy Technician/Assistant
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/smallcompanion-animal-surgery-and-medicine" style={{ color: "black" }}>
                                  Small/Companion Animal Surgery and Medicine
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/somatic-bodywork" style={{ color: "black" }}>
                                  Somatic Bodywork
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/speech-language-pathology-assistant" style={{ color: "black" }}>
                                  Speech-Language Pathology Assistant
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/speech-language-pathologypathologist" style={{ color: "black" }}>
                                  Speech-Language Pathology/Pathologist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/sterile-processing-technologytechnician" style={{ color: "black" }}>
                                  Sterile Processing Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/surgical-technologytechnologist" style={{ color: "black" }}>
                                  Substance Abuse/Addiction Counseling
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/surgical-technologytechnologist" style={{ color: "black" }}>
                                  Surgical Technology/Technologist
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/therapeutic-recreationrecreational-therapy" style={{ color: "black" }}>
                                  Therapeutic Recreation/Recreational Therapy
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/traditional-chinese-medicine-and-chinese-herbology" style={{ color: "black" }}>
                                  Traditional Chinese Medicine and Chinese
                                  Herbology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/veterinary-infectious-diseases" style={{ color: "black" }}>
                                  Veterinary Infectious Diseases
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/veterinary-medicine" style={{ color: "black" }}>
                                  Veterinary Medicine
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/veterinary-microbiology-and-immunobiology" style={{ color: "black" }}>
                                  Veterinary Microbiology and Immunobiology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/veterinary-pathology-and-pathobiology" style={{ color: "black" }}>
                                  Veterinary Pathology and Pathobiology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/veterinary-physiology" style={{ color: "black" }}>
                                  Veterinary Physiology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/veterinary-preventive-medicine-epidemiology-and-public-health" style={{ color: "black" }}>
                                  Veterinary Preventive Medicine, Epidemiology,
                                  and Public Health
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/veterinary-sciencesveterinary-clinical-sciences-general" style={{ color: "black" }}>
                                  Veterinary Sciences/Veterinary Clinical
                                  Sciences, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/veterinaryanimal-health-technologytechnician-and-veterinary-assistant" style={{ color: "black" }}>
                                  Veterinary/Animal Health Technology/Technician
                                  and Veterinary Assistant
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/vocational-rehabilitation-counselingcounselor" style={{ color: "black" }}>
                                  Vocational Rehabilitation Counseling/Counselor
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/womens-health-nursenursing" style={{ color: "black" }}>
                                  Women's Health Nurse/Nursing
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/yoga-teacher-trainingyoga-therapy" style={{ color: "black" }}>
                                  Yoga Teacher Training/Yoga Therapy
                                </a>
                              </li>
                            </ul>
                            <br />
<hr /> */}

                            {/* <div>
                              <h3>Health-Related Knowledge and Skills</h3>
                            </div>

                            <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a href="https://www.cappex.com/major/addiction-prevention-and-treatment" style={{ color: "black" }}>
                                  Addiction Prevention and Treatment
                                </a>
                              </li>
                            </ul>
                            <br />
<hr /> */}

                            {/* <div>
                              <h3>
                                High School/Secondary Diplomas and Certificates
                              </h3>
                            </div>

                            <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a href="https://www.cappex.com/major/high-schoolsecondary-certificates-other" style={{ color: "black" }}>
                                  High School/Secondary Certificates, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/regulargeneral-high-schoolsecondary-diploma-program" style={{ color: "black" }}>
                                  Regular/General High School/Secondary Diploma
                                  Program
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/vocational-high-school-and-secondary-businessvocational-industrialoccupational-diploma" style={{ color: "black" }}>
                                  Vocational High School and Secondary
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/vocational-high-school-and-secondary-businessvocational-industrialoccupational-diploma" style={{ color: "black" }}>
                                  Business/Vocational-Industrial/Occupational
                                  Diploma Program
                                </a>
                              </li>
                            </ul>
                            <br />
<hr />
                            <div>
                              <h3>History</h3>
                            </div>

                            <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a href="https://www.cappex.com/major/american-history-united-states" style={{ color: "black" }}>
                                  American History (United States)
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/asian-history" style={{ color: "black" }}>
                                  Asian History
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/european-history" style={{ color: "black" }}>
                                  European History
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/history-and-philosophy-of-science-and-technology" style={{ color: "black" }}>
                                  History and Philosophy of Science and
                                  Technology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/history-general" style={{ color: "black" }}>
                                  History, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/history-other" style={{ color: "black" }}>
                                  History, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/military-history" style={{ color: "black" }}>
                                  Military History
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/publicapplied-history" style={{ color: "black" }}>
                                  Public/Applied History
                                </a>
                              </li>
                            </ul>
                            <br />
<hr /> */}

                            {/* <div>
                              <h3>
                                Homeland Security, Law Enforcement, Firefighting
                                and Related Protective Services
                              </h3>
                            </div> */}

                            {/* <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a href="https://www.cappex.com/major/corrections" style={{ color: "black" }}>
                                  Corrections
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/corrections-administration" style={{ color: "black" }}>
                                  Corrections Administration
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/corrections-and-criminal-justice-other" style={{ color: "black" }}>
                                  Corrections and Criminal Justice, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/criminal-justicelaw-enforcement-administration" style={{ color: "black" }}>
                                  Criminal Justice/Law Enforcement
                                  Administration
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/criminal-justicepolice-science" style={{ color: "black" }}>
                                  Criminal Justice/Police Science
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/criminal-justicesafety-studies" style={{ color: "black" }}>
                                  Criminal Justice/Safety Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/criminalistics-and-criminal-science" style={{ color: "black" }}>
                                  Criminalistics and Criminal Science
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/crisisemergencydisaster-management" style={{ color: "black" }}>
                                  Crisis/Emergency/Disaster Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/critical-incident-responsespecial-police-operations" style={{ color: "black" }}>
                                  Critical Incident Response/Special Police
                                  Operations
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/critical-infrastructure-protection" style={{ color: "black" }}>
                                  Critical Infrastructure Protection
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/cybercomputer-forensics-and-counterterrorism" style={{ color: "black" }}>
                                  Cyber/Computer Forensics and Counterterrorism
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/financial-forensics-and-fraud-investigation" style={{ color: "black" }}>
                                  Financial Forensics and Fraud Investigation
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/fire-prevention-and-safety-technologytechnician" style={{ color: "black" }}>
                                  Fire Prevention and Safety
                                  Technology/Technician
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/fire-protection-other" style={{ color: "black" }}>
                                  Fire Protection, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/fire-sciencefire-fighting" style={{ color: "black" }}>
                                  Fire Science/Fire-fighting
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/fire-services-administration" style={{ color: "black" }}>
                                  Fire Services Administration
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/fire-systems-technology" style={{ color: "black" }}>
                                  Fire Systems Technology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/firearson-investigation-and-prevention" style={{ color: "black" }}>
                                  Fire/Arson Investigation and Prevention
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/forensic-science-and-technology" style={{ color: "black" }}>
                                  Forensic Science and Technology
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/homeland-security" style={{ color: "black" }}>
                                  Homeland Security
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/homeland-security-law-enforcement-firefighting-and-related-protective-services-other" style={{ color: "black" }}>
                                  Homeland Security, Law Enforcement,
                                  Firefighting and Related Protective Services,
                                  Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/homeland-security-other" style={{ color: "black" }}>
                                  Homeland Security, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/juvenile-corrections" style={{ color: "black" }}>
                                  Juvenile Corrections
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/law-enforcement-intelligence-analysis" style={{ color: "black" }}>
                                  Law Enforcement Intelligence Analysis
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/law-enforcement-investigation-and-interviewing" style={{ color: "black" }}>
                                  Law Enforcement Investigation and Interviewing
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/protective-services-operations" style={{ color: "black" }}>
                                  Protective Services Operations
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/securities-services-administrationmanagement" style={{ color: "black" }}>
                                  Securities Services Administration/Management
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/security-and-loss-prevention-services" style={{ color: "black" }}>
                                  Security and Loss Prevention Services
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/terrorism-and-counterterrorism-operations" style={{ color: "black" }}>
                                  Terrorism and Counterterrorism Operations
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/wildlandforest-firefighting-and-investigation" style={{ color: "black" }}>
                                  Wildland/Forest Firefighting and Investigation
                                </a>
                              </li>
                            </ul>
                            <br />
<hr/> */}

                            {/* <div>
                              <h3>Legal Professions and Studies</h3>
                            </div>

                            <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a href="https://www.cappex.com/major/advanced-legal-researchstudies-general" style={{ color: "black" }}>
                                  Advanced Legal Research/Studies, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/americanus-lawlegal-studiesjurisprudence" style={{ color: "black" }}>
                                  American/US Law/Legal Studies/Jurisprudence
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/banking-corporate-finance-and-securities-law" style={{ color: "black" }}>
                                  Banking, Corporate, Finance, and Securities
                                  Law
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/comparative-law" style={{ color: "black" }}>
                                  Comparative Law
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/court-reportingcourt-reporter" style={{ color: "black" }}>
                                  Court Reporting/Court Reporter
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/energy-environment-and-natural-resources-law" style={{ color: "black" }}>
                                  Energy, Environment, and Natural Resources Law
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/health-law" style={{ color: "black" }}>
                                  Health Law
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/intellectual-property-law" style={{ color: "black" }}>
                                  Intellectual Property Law
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/international-business-trade-and-tax-law" style={{ color: "black" }}>
                                  International Business, Trade, and Tax Law
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/international-law-and-legal-studies" style={{ color: "black" }}>
                                  International Law and Legal Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/law" style={{ color: "black" }}>
                                  Law
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/legal-administrative-assistantsecretary" style={{ color: "black" }}>
                                  Legal Administrative Assistant/Secretary
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/legal-assistantparalegal" style={{ color: "black" }}>
                                  Legal Assistant/Paralegal
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/legal-professions-and-studies-other" style={{ color: "black" }}>
                                  Legal Professions and Studies, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/legal-research-and-advanced-professional-studies-other" style={{ color: "black" }}>
                                  Legal Research and Advanced Professional
                                  Studies, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/legal-studies-general" style={{ color: "black" }}>
                                  Legal Studies, General
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/legal-support-services-other" style={{ color: "black" }}>
                                  Legal Support Services, Other
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/pre-law-studies" style={{ color: "black" }}>
                                  Pre-Law Studies
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/programs-foreign-lawyers" style={{ color: "black" }}>
                                  Programs for Foreign Lawyers
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/tax-lawtaxation" style={{ color: "black" }}>
                                  Tax Law/Taxation
                                </a>
                              </li>
                            </ul>
<br />
<hr /> */}
                            {/* <div>
                              <h3>Leisure and Recreational Activities</h3>
                            </div>

                            <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a href="https://www.cappex.com/major/aircraft-pilot-private" style={{ color: "black" }}>
                                  Aircraft Pilot (Private)
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/computer-games-and-programming-skills" style={{ color: "black" }}>
                                  Computer Games and Programming Skills
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/handicrafts-and-model-making" style={{ color: "black" }}>
                                  Handicrafts and Model-Making
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/music" style={{ color: "black" }}>
                                  Music
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/reading" style={{ color: "black" }}>
                                  Reading
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/sports-and-exercise" style={{ color: "black" }}>
                                  Sports and Exercise
                                </a>
                              </li>
                              <li>
                                <a href="https://www.cappex.com/major/theatretheater" style={{ color: "black" }}>
                                  Theatre/Theater
                                </a>
                              </li>
                              <br/>
                              <br/>
                            </ul> */}


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Container>
  );
}
