/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-concat */
/* eslint-disable default-case */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment, useState,useEffect } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Autocomplete from "@material-ui/lab/Autocomplete";
import GraduatePrograms from "./GraduatePrograms";
import UndergraduatePrograms from "./UndergraduatePrograms";
import CertificateDiploma from "./CertificateDiploma";
import Doctoral from "./Doctoral";
import Header from "./Header";
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [messages, setMessages] = React.useState("");
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus, setStatus] = React.useState("");
  const [st_Id,setst_Id]=useState('')


  useEffect(function(){
    // localStorageData();
  },[])

  const handleClickUnivesity = (id) => {
    props.history.push({
   pathname: "/UniversityDisplay",
     state: {
       id: id,
     }
   });
};
  

  const localStorageData=async()=>{
    let data=await localStorage.getItem('student')
    var result=JSON.parse(data)
    console.log("asyndataaaaaaaaaaaaaaaaaaaaaaa",result.student_ID);
    setst_Id(result.student_ID)
    
    // console.log("asyndataaaaaaaaaaaaaaaaaaaaaaa",result);
  }

  const handlePicture = (event) => {
    setPicturePath(URL.createObjectURL(event.target.files[0]));
    setPicture(event.target.files[0]);
  };

  const handleVerifyPassword = (e) => {
    setVerifyPassword(e.target.value);
    if (password === e.target.value) {
      setStatus("Password Matched");
    } else {
      setStatus("Password not matched");
    }
  };

  // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
  const top100Films = [
    { title: "Phd", year: 1994 },
    { title: "MCA", year: 1972 },
    { title: "MSC", year: 1974 },
  ];

  //  EXPLORE PROGRAMS IN ABROAD Fetch Service //
  const [getClick, setClick] = useState("");

  const handleUnderGraduate = async () => {
    //  const body={'course_type':getClick}
    props.history.push({ pathname: "/UndergraduatePrograms" });
  };

  const handleGraduatePrograms = async () => {
    //  const body={'course_type':getClick}
    props.history.push({ pathname: `/GraduatePrograms` });
  };

  const handleDoctoral = async () => {
    // const body={'course_type':getClick}
    props.history.push({ pathname: `/Doctoral` });
  };

  const handleCertificateDiploma = async () => {
    // const body={'course_type':getClick}
    props.history.push({ pathname: `/CertificateDiploma` });
  };

  const handleClick = async (opt) => {
    switch (opt) {
      case 0:
        // {handleUnderGraduate()}
        props.history.push({
          pathname: "/UndergraduatePrograms",
          state: { option: opt },
        });
        break;
      case 1:
        //   {handleGraduatePrograms()}
        props.history.push({
          pathname: "/GraduatePrograms",
          state: { option: opt },
        });
        break;
      case 2:
        //   {handleDoctoral()}
        props.history.push({ pathname: "/Doctoral", state: { option: opt } });
        break;
      case 3:
        //   {handleCertificateDiploma()}
        props.history.push({
          pathname: "/CertificateDiploma",
          state: { option: opt },
        });
        break;
    }
  };

  const handleUniversities = (opt) => {
    console.log(opt);
    props.history.push({
      pathname: "/SearchUniversity",
      state: { option: opt },
    });
  };

  const handleCountries = (opt) => {
    props.history.push({
      pathname: "/SearchUniversity",
      state: { option: opt },
    });
  };

  console.log("History ==  ", props);
  return (
    
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <div className="login-area default-padding" style={{ paddingTop: 0 }}>
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 70,
                }}
              >
                {/* <div className="banner-area inc-form bg-gradient slider-less text-light" style={{backgroundImage: "url(" + "../assets/img/event/1.jpg" + ")", padding:70}}> */}
                <div className="container">
                  <div className="row">
                    {/* <div className="double-items">
                    <div className="col-md-7 info">
                        <h2 className="wow fadeInLeft">Find your ideal study program.</h2>
                        <a className="btn day btn-light border btn-md wow fadeInDown" style={{color:'#3f8ccb'}} href="#">View Courses</a>
                    </div>
                    <div className="col-md-5 reg-form">
                        <form action="#">
                            <div className="row">
<img style={{flex:1}} class="login_image" src="assets/img/elearning.png" alt="Login" data-original-title="" title="" />

                            </div>
                        </form>
                    </div>
                </div> */}
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">
                        Find your ideal study program
                      </h2>
                    </div>
                    <div
                      class="col-lg-12"
                      style={{
                        padding: 15,
                        backgroundColor: "#FFF",
                        borderRadius: 50,
                        marginTop: 25,
                      }}
                    >
                      <div class="col-lg-5">
                        {/* <InputLabel for="browser" style={{fontSize:15,}}>What do you want to Study </InputLabel>
        <Select >
   <MenuItem  value="hello" style={{fontSize:13,}}>PhD</MenuItem>
   <MenuItem  value={20} style={{fontSize:13,}}>MCA</MenuItem>
   <MenuItem  value={30} style={{fontSize:13,}}>MSC</MenuItem>
</Select> */}

                        <Autocomplete
                          options={top100Films}
                          getOptionLabel={(option) => option.title}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="What do you want to Study"
                              fullWidth
                              InputLabelProps={{ style: { fontSize: 13 } }}
                            />
                          )}
                        />
                      </div>
                      <div class="col-lg-5">
                        <FormControl className={classes.formControl}>
                          <InputLabel style={{ fontSize: 13 }}>
                            Choose a country
                          </InputLabel>
                          <Select>
                          <MenuItem value={"Afghanistan"}>
                                              Afghanistan
                                            </MenuItem>
                                            <MenuItem value={"Albania"}>
                                              Albania
                                            </MenuItem>
                                            <MenuItem value={"Algeria"}>
                                              Algeria
                                            </MenuItem>
                                            <MenuItem value={"Andorra"}>
                                              Andorra
                                            </MenuItem>
                                            <MenuItem value={"Angola"}>
                                              Angola
                                            </MenuItem>
                                            <MenuItem
                                              value={"Antigua and Barbuda"}
                                            >
                                              Antigua and Barbuda
                                            </MenuItem>
                                            <MenuItem value={"Argentina"}>
                                              Argentina
                                            </MenuItem>
                                            <MenuItem value={"Armenia"}>
                                              Armenia
                                            </MenuItem>
                                            <MenuItem value={"Australia"}>
                                              Australia
                                            </MenuItem>
                                            <MenuItem value={"Austria"}>
                                              Austria
                                            </MenuItem>
                                            <MenuItem value={"Austrian Empire"}>
                                              Austrian Empire
                                            </MenuItem>
                                            <MenuItem value={"Azerbaijan"}>
                                              Azerbaijan
                                            </MenuItem>

                                            <MenuItem value={"Bahamas"}>
                                              Bahamas
                                            </MenuItem>
                                            <MenuItem value={"Bahrain"}>
                                              Bahrain
                                            </MenuItem>
                                            <MenuItem value={"Bangladesh"}>
                                              Bangladesh
                                            </MenuItem>
                                            <MenuItem value={"Barbados"}>
                                              Barbados
                                            </MenuItem>
                                            <MenuItem value={"Belarus"}>
                                              Belarus
                                            </MenuItem>
                                            <MenuItem value={"Belgium"}>
                                              Belgium
                                            </MenuItem>
                                            <MenuItem value={"Belize"}>
                                              Belize
                                            </MenuItem>
                                            <MenuItem value={"Benin"}>
                                              Benin
                                            </MenuItem>
                                            <MenuItem value={"Bolivia"}>
                                              Bolivia
                                            </MenuItem>
                                            <MenuItem
                                              value={"Bosnia and Herzegovina"}
                                            >
                                              Bosnia and Herzegovina
                                            </MenuItem>
                                            <MenuItem value={"Botswana"}>
                                              Botswana
                                            </MenuItem>
                                            <MenuItem value={"Brazil"}>
                                              Brazil
                                            </MenuItem>
                                            <MenuItem value={"Brunei"}>
                                              Brunei
                                            </MenuItem>
                                            <MenuItem
                                              value={"Brunswick and Lüneburg"}
                                            >
                                              Brunswick and Lüneburg
                                            </MenuItem>
                                            <MenuItem value={"Bulgaria"}>
                                              Bulgaria
                                            </MenuItem>
                                            <MenuItem value={"Burkina Faso"}>
                                              Burkina Faso
                                            </MenuItem>
                                            <MenuItem value={"Burma"}>
                                              Burma
                                            </MenuItem>
                                            <MenuItem value={"Burundi"}>
                                              Burundi
                                            </MenuItem>
                                            <MenuItem value={"Cambodia"}>
                                              Cambodia
                                            </MenuItem>
                                            <MenuItem value={"Cameroon"}>
                                              Cameroon
                                            </MenuItem>
                                            <MenuItem value={"Canada"}>
                                              Canada
                                            </MenuItem>
                                            <MenuItem value={"Cape Verde"}>
                                              Cape Verde
                                            </MenuItem>
                                            <MenuItem
                                              value={"Central African Republic"}
                                            >
                                              Central African Republic
                                            </MenuItem>
                                            <MenuItem value={"Chad"}>
                                              Chad
                                            </MenuItem>
                                            <MenuItem value={"Chile"}>
                                              Chile
                                            </MenuItem>
                                            <MenuItem value={"China"}>
                                              China
                                            </MenuItem>
                                            <MenuItem value={"Colombia"}>
                                              Colombia
                                            </MenuItem>
                                            <MenuItem value={"Comoros"}>
                                              Comoros
                                            </MenuItem>
                                            <MenuItem
                                              value={"Congo, Republic of the"}
                                            >
                                              Congo, Republic of the
                                            </MenuItem>
                                            <MenuItem
                                              value={
                                                "Congo, Democratic Republic of the"
                                              }
                                            >
                                              Congo, Democratic Republic of the
                                            </MenuItem>
                                            <MenuItem value={"Costa Rica"}>
                                              Costa Rica
                                            </MenuItem>
                                            <MenuItem value={"Cote d'Ivoire"}>
                                              Cote d'Ivoire
                                            </MenuItem>
                                            <MenuItem value={"Croatia"}>
                                              Croatia
                                            </MenuItem>
                                            <MenuItem value={"Cuba"}>
                                              Cuba
                                            </MenuItem>
                                            <MenuItem value={"Cyprus"}>
                                              Cyprus
                                            </MenuItem>
                                            <MenuItem value={"Czech Republic"}>
                                              Czech Republic
                                            </MenuItem>
                                            <MenuItem value={"Denmark"}>
                                              Denmark
                                            </MenuItem>
                                            <MenuItem value={"Djibouti"}>
                                              Djibouti
                                            </MenuItem>
                                            <MenuItem value={"Dominica"}>
                                              Dominica
                                            </MenuItem>
                                            <MenuItem
                                              value={"Dominican Republic"}
                                            >
                                              Dominican Republic
                                            </MenuItem>
                                            <MenuItem value={"East Timor"}>
                                              East Timor
                                            </MenuItem>
                                            <MenuItem value={"Ecuador"}>
                                              Ecuador
                                            </MenuItem>
                                            <MenuItem value={"Egypt"}>
                                              Egypt
                                            </MenuItem>
                                            <MenuItem value={"El Salvador"}>
                                              El Salvador
                                            </MenuItem>
                                            <MenuItem
                                              value={"Equatorial Guinea"}
                                            >
                                              Equatorial Guinea
                                            </MenuItem>
                                            <MenuItem value={"Eritrea"}>
                                              Eritrea
                                            </MenuItem>
                                            <MenuItem value={"Estonia"}>
                                              Estonia
                                            </MenuItem>
                                            <MenuItem value={"Ethiopia"}>
                                              Ethiopia
                                            </MenuItem>
                                            <MenuItem value={"Fiji"}>
                                              Fiji
                                            </MenuItem>
                                            <MenuItem value={"Finland"}>
                                              Finland
                                            </MenuItem>
                                            <MenuItem value={"France"}>
                                              France
                                            </MenuItem>
                                            <MenuItem value={"Gabon"}>
                                              Gabon
                                            </MenuItem>
                                            <MenuItem value={"The Gambia"}>
                                              The Gambia
                                            </MenuItem>
                                            <MenuItem value={"Georgia"}>
                                              Georgia
                                            </MenuItem>
                                            <MenuItem value={"Germany"}>
                                              Germany
                                            </MenuItem>
                                            <MenuItem value={"Ghana"}>
                                              Ghana
                                            </MenuItem>
                                            <MenuItem value={"Greece"}>
                                              Greece
                                            </MenuItem>
                                            <MenuItem value={"Grenada"}>
                                              Grenada
                                            </MenuItem>
                                            <MenuItem value={"Guatemala"}>
                                              Guatemala
                                            </MenuItem>
                                            <MenuItem value={"Guinea"}>
                                              Guinea
                                            </MenuItem>
                                            <MenuItem value={"Guinea-Bissau"}>
                                              Guinea-Bissau
                                            </MenuItem>
                                            <MenuItem value={"Guyana"}>
                                              Guyana
                                            </MenuItem>
                                            <MenuItem value={"Haiti"}>
                                              Haiti
                                            </MenuItem>
                                            <MenuItem value={"Honduras"}>
                                              Honduras
                                            </MenuItem>
                                            <MenuItem value={"Hungary"}>
                                              Hungary
                                            </MenuItem>
                                            <MenuItem value={"Iceland"}>
                                              Iceland
                                            </MenuItem>
                                            <MenuItem value={"India"}>
                                              India
                                            </MenuItem>
                                            <MenuItem value={"Indonesia"}>
                                              Indonesia
                                            </MenuItem>
                                            <MenuItem value={"Iran"}>
                                              Iran
                                            </MenuItem>
                                            <MenuItem value={"Iraq"}>
                                              Iraq
                                            </MenuItem>
                                            <MenuItem value={"Ireland"}>
                                              Ireland
                                            </MenuItem>
                                            <MenuItem value={"Israel"}>
                                              Israel
                                            </MenuItem>
                                            <MenuItem value={"Italy"}>
                                              Italy
                                            </MenuItem>
                                            <MenuItem value={"Jamaica"}>
                                              Jamaica
                                            </MenuItem>
                                            <MenuItem value={"Japan"}>
                                              Japan
                                            </MenuItem>
                                            <MenuItem value={"Jordan"}>
                                              Jordan
                                            </MenuItem>
                                            <MenuItem value={"Kazakhstan"}>
                                              Kazakhstan
                                            </MenuItem>
                                            <MenuItem value={"Kenya"}>
                                              Kenya
                                            </MenuItem>
                                            <MenuItem value={"Kiribati"}>
                                              Kiribati
                                            </MenuItem>
                                            <MenuItem value={"Korea, North"}>
                                              Korea, North
                                            </MenuItem>
                                            <MenuItem value={"Korea, South<"}>
                                              Korea, South
                                            </MenuItem>
                                            <MenuItem value={"Kosovo"}>
                                              Kosovo
                                            </MenuItem>
                                            <MenuItem value={"Kuwait"}>
                                              Kuwait
                                            </MenuItem>
                                            <MenuItem value={"Kyrgyzstan"}>
                                              Kyrgyzstan
                                            </MenuItem>
                                            <MenuItem value={"Laos"}>
                                              Laos
                                            </MenuItem>
                                            <MenuItem value={"Latvia"}>
                                              Latvia
                                            </MenuItem>
                                            <MenuItem value={"Lebanon"}>
                                              Lebanon
                                            </MenuItem>
                                            <MenuItem value={"Lesotho"}>
                                              Lesotho
                                            </MenuItem>
                                            <MenuItem value={"Liberia"}>
                                              Liberia
                                            </MenuItem>
                                            <MenuItem value={"Libya"}>
                                              Libya
                                            </MenuItem>
                                            <MenuItem value={"Liechtenstein"}>
                                              Liechtenstein
                                            </MenuItem>
                                            <MenuItem value={"Lithuania"}>
                                              Lithuania
                                            </MenuItem>
                                            <MenuItem value={"Luxembourg"}>
                                              Luxembourg
                                            </MenuItem>
                                            <MenuItem value={"Macedonia"}>
                                              Macedonia
                                            </MenuItem>
                                            <MenuItem value={"Madagascar"}>
                                              Madagascar
                                            </MenuItem>
                                            <MenuItem value={"Malawi"}>
                                              Malawi
                                            </MenuItem>
                                            <MenuItem value={"Malaysia"}>
                                              Malaysia
                                            </MenuItem>
                                            <MenuItem value={"Maldives"}>
                                              Maldives
                                            </MenuItem>
                                            <MenuItem value={"Mali"}>
                                              Mali
                                            </MenuItem>
                                            <MenuItem value={"Malta"}>
                                              Malta
                                            </MenuItem>
                                            <MenuItem
                                              value={"Marshall Islands"}
                                            >
                                              Marshall Islands
                                            </MenuItem>
                                            <MenuItem value={"Mauritania"}>
                                              Mauritania
                                            </MenuItem>
                                            <MenuItem value={"Mauritius"}>
                                              Mauritius
                                            </MenuItem>
                                            <MenuItem value={"Mexico"}>
                                              Mexico
                                            </MenuItem>
                                            <MenuItem value={"Micronesia"}>
                                              Micronesia
                                            </MenuItem>
                                            <MenuItem value={"Moldova"}>
                                              Moldova
                                            </MenuItem>
                                            <MenuItem value={"Monaco"}>
                                              Monaco
                                            </MenuItem>
                                            <MenuItem value={"Mongolia"}>
                                              Mongolia
                                            </MenuItem>
                                            <MenuItem value={"Montenegro"}>
                                              Montenegro
                                            </MenuItem>
                                            <MenuItem value={"Morocco"}>
                                              Morocco
                                            </MenuItem>
                                            <MenuItem value={"Mozambique"}>
                                              Mozambique
                                            </MenuItem>
                                            <MenuItem value={"Myanmar"}>
                                              Myanmar
                                            </MenuItem>
                                            <MenuItem value={"Namibia"}>
                                              Namibia
                                            </MenuItem>
                                            <MenuItem value={"Nauru"}>
                                              Nauru
                                            </MenuItem>
                                            <MenuItem value={"Nepal"}>
                                              Nepal
                                            </MenuItem>
                                            <MenuItem value={"Netherlands"}>
                                              Netherlands
                                            </MenuItem>
                                            <MenuItem value={"New Zealand"}>
                                              New Zealand
                                            </MenuItem>
                                            <MenuItem value={"Nicaragua"}>
                                              Nicaragua
                                            </MenuItem>
                                            <MenuItem value={"Niger"}>
                                              Niger
                                            </MenuItem>
                                            <MenuItem value={"Nigeria"}>
                                              Nigeria
                                            </MenuItem>
                                            <MenuItem value={"Norway"}>
                                              Norway
                                            </MenuItem>
                                            <MenuItem value={"Oman"}>
                                              Oman
                                            </MenuItem>
                                            <MenuItem value={"Pakistan"}>
                                              Pakistan
                                            </MenuItem>
                                            <MenuItem value={"Palau"}>
                                              Palau
                                            </MenuItem>
                                            <MenuItem value={"Panama"}>
                                              Panama
                                            </MenuItem>
                                            <MenuItem
                                              value={"Papua New Guinea"}
                                            >
                                              Papua New Guinea
                                            </MenuItem>
                                            <MenuItem value={"Paraguay"}>
                                              Paraguay
                                            </MenuItem>
                                            <MenuItem value={"Peru"}>
                                              Peru
                                            </MenuItem>
                                            <MenuItem value={"Philippines"}>
                                              Philippines
                                            </MenuItem>
                                            <MenuItem value={"Poland"}>
                                              Poland
                                            </MenuItem>
                                            <MenuItem value={"Portugal"}>
                                              Portugal
                                            </MenuItem>
                                            <MenuItem value={"Qatar"}>
                                              Qatar
                                            </MenuItem>
                                            <MenuItem value={"Romania"}>
                                              Romania
                                            </MenuItem>
                                            <MenuItem value={"Russia"}>
                                              Russia
                                            </MenuItem>
                                            <MenuItem value={"Rwanda"}>
                                              Rwanda
                                            </MenuItem>
                                            <MenuItem
                                              value={"Saint Kitts and Nevis"}
                                            >
                                              Saint Kitts and Nevis
                                            </MenuItem>
                                            <MenuItem value={"Saint Lucia"}>
                                              Saint Lucia
                                            </MenuItem>
                                            <MenuItem
                                              value={
                                                "Saint Vincent and the Grenadines"
                                              }
                                            >
                                              Saint Vincent and the Grenadines
                                            </MenuItem>
                                            <MenuItem value={"Samoa"}>
                                              Samoa
                                            </MenuItem>
                                            <MenuItem value={"San Marino"}>
                                              San Marino
                                            </MenuItem>
                                            <MenuItem
                                              value={"Sao Tome and Principe"}
                                            >
                                              Sao Tome and Principe
                                            </MenuItem>
                                            <MenuItem value={"Saudi Arabia"}>
                                              Saudi Arabia
                                            </MenuItem>
                                            <MenuItem value={"Senegal"}>
                                              Senegal
                                            </MenuItem>
                                            <MenuItem value={"Serbia"}>
                                              Serbia
                                            </MenuItem>
                                            <MenuItem value={"Seychelles"}>
                                              Seychelles
                                            </MenuItem>
                                            <MenuItem value={"Sierra Leone"}>
                                              Sierra Leone
                                            </MenuItem>
                                            <MenuItem value={"Singapore"}>
                                              Singapore
                                            </MenuItem>
                                            <MenuItem value={"Slovakia"}>
                                              Slovakia
                                            </MenuItem>
                                            <MenuItem value={"Slovenia"}>
                                              Slovenia
                                            </MenuItem>
                                            <MenuItem value={"Solomon Islands"}>
                                              Solomon Islands
                                            </MenuItem>
                                            <MenuItem value={"Somalia"}>
                                              Somalia
                                            </MenuItem>
                                            <MenuItem value={"South Africa"}>
                                              South Africa
                                            </MenuItem>
                                            <MenuItem value={"South Sudan"}>
                                              South Sudan
                                            </MenuItem>
                                            <MenuItem value={"Spain"}>
                                              Spain
                                            </MenuItem>
                                            <MenuItem value={"Sri Lanka"}>
                                              Sri Lanka
                                            </MenuItem>
                                            <MenuItem value={"Sudan"}>
                                              Sudan
                                            </MenuItem>
                                            <MenuItem value={"Suriname"}>
                                              Suriname
                                            </MenuItem>
                                            <MenuItem value={"Swaziland"}>
                                              Swaziland
                                            </MenuItem>
                                            <MenuItem value={"Sweden"}>
                                              Sweden
                                            </MenuItem>
                                            <MenuItem value={"Switzerland"}>
                                              Switzerland
                                            </MenuItem>
                                            <MenuItem value={"Syria"}>
                                              Syria
                                            </MenuItem>
                                            <MenuItem value={"Taiwan"}>
                                              Taiwan
                                            </MenuItem>
                                            <MenuItem value={"Tajikistan"}>
                                              Tajikistan
                                            </MenuItem>
                                            <MenuItem value={"Tanzania"}>
                                              Tanzania
                                            </MenuItem>
                                            <MenuItem value={"Thailand"}>
                                              Thailand
                                            </MenuItem>
                                            <MenuItem value={"Togo"}>
                                              Togo
                                            </MenuItem>
                                            <MenuItem value={"Tonga"}>
                                              Tonga
                                            </MenuItem>
                                            <MenuItem
                                              value={"Trinidad and Tobago"}
                                            >
                                              Trinidad and Tobago
                                            </MenuItem>
                                            <MenuItem value={"Tunisia"}>
                                              Tunisia
                                            </MenuItem>
                                            <MenuItem value={"Turkey"}>
                                              Turkey
                                            </MenuItem>
                                            <MenuItem value={"Turkmenistan"}>
                                              Turkmenistan
                                            </MenuItem>
                                            <MenuItem value={"Tuvalu"}>
                                              Tuvalu
                                            </MenuItem>
                                            <MenuItem value={"Uganda"}>
                                              Uganda
                                            </MenuItem>
                                            <MenuItem value={"Ukraine"}>
                                              Ukraine
                                            </MenuItem>
                                            <MenuItem
                                              value={"United Arab Emirates"}
                                            >
                                              United Arab Emirates
                                            </MenuItem>
                                            <MenuItem value={"United Kingdom"}>
                                              United Kingdom
                                            </MenuItem>
                                            <MenuItem
                                              value={"United States of America"}
                                            >
                                              United States of America
                                            </MenuItem>
                                            <MenuItem value={"Uruguay"}>
                                              Uruguay
                                            </MenuItem>
                                            <MenuItem value={"Uzbekistan"}>
                                              Uzbekistan
                                            </MenuItem>
                                            <MenuItem value={"Vanuatu"}>
                                              Vanuatu
                                            </MenuItem>
                                            <MenuItem value={"Vatican City"}>
                                              Vatican City
                                            </MenuItem>
                                            <MenuItem value={"Venezuela"}>
                                              Venezuela
                                            </MenuItem>
                                            <MenuItem value={"Vietnam"}>
                                              Vietnam
                                            </MenuItem>
                                            <MenuItem value={"Yemen"}>
                                              Yemen
                                            </MenuItem>
                                            <MenuItem value={"Zambia"}>
                                              Zambia
                                            </MenuItem>
                                            <MenuItem value={"Zimbabwe"}>
                                              Zimbabwe
                                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>

                      <div
                        class="col-lg-2"
                        style={{ padding: 0, textAlign: "center" }}
                      >
                        {/* <MDBBtn color="yellow" rounded variant="contained" style={{fontSize:16,borderRadius:50,color:'#FFF',}}  className="btn-block z-depth-1a" fullWidth>
        Search  <i class="fa fa-search"></i>
        
        </MDBBtn> */}
                        <div className="blog-area">
                          <div className="info">
                            <div className="content" style={{ padding: 0 }}>
                              <a href="#">
                                <i className="fas fa-search"></i> Search
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="about-area default-padding">
                <div className="container">
                  <div className="row">
                    <div className="about-items">
                      <div className="col-md-6 about-info">
                        <h2>
                          Welcome ! <span>Study Metro</span>
                        </h2>
                        <blockquote style={{ fontSize: 15, lineHeight: "1" }}>
                          As overseas education consultants, we help students
                          achieve their goals.
                        </blockquote>
                        <p
                          style={{
                            fontSize: 14,
                            color: "#212121",
                            textAlign: "justify",
                          }}
                        >
                          Whether you want to earn your MS in the US, study and
                          work in Canada, or find employment in the USA, we can
                          match you with the right program. Study Metro is a
                          professional education consultancy firm, extending
                          excellent support and service to students all over
                          India and gives unlimited study opportunities across
                          the Globe. Our aim is to improve educational
                          standards. Scores of parents have sought our advice to
                          find suitable Universities for their children.
                        </p>
                      </div>
                      <div className="col-md-6 features text-light">
                        <img
                          style={{ flex: 1 }}
                          class="login_image"
                          src="assets/img/learn.png"
                          alt="Login"
                          data-original-title=""
                          title=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <section class="membership_plan explore_sec">
                <div class="container">
                  <div class="row">
                    <div class="col-sm-12">
                      <div className="weekly-top-items">
                        <div className="col-md-12">
                          <div
                            className="top-courses"
                            style={{ paddingRight: 0 }}
                          >
                            <div
                              className="heading"
                              style={{ paddingBottom: 15 }}
                            >
                              <h4>
                                EXPLORE PROGRAMS IN ABROAD<strong></strong>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="features-area default-padding bottom-less shape-radius">
                        <div class="container">
                          <div class="row">
                            <div class="features text-center">
                              <div
                                class="equal-height col-md-3"
                                style={{ height: 304 }}
                              >
                                <div class="item mariner">
                                  <a
                                    href=""
                                    onClick={() => handleClick(0)}
                                    style={{ backgroundColor: "#3a448d" }}
                                  >
                                    <div class="icon">
                                      <icon
                                        class="fas fa-book-open"
                                        style={{
                                          color: "white",
                                          fontSize: "4rem",
                                          marginBottom: 10,
                                        }}
                                      ></icon>
                                    </div>
                                    <div class="info">
                                      <h5
                                        style={{
                                          fontSize: 20,
                                          fontWeight: "bold",
                                          color: "white",
                                        }}
                                      >
                                        Undergraduate Programs
                                      </h5>
                                      {/* <p>
                                                                        attention say frankness intention out dashwoods now curiosity. Stronger ecstatic as no judgment daughter.
                                                                    </p> */}
                                      <div className="blog-area">
                                        <div className="info">
                                          <div
                                            className="content"
                                            style={{ padding: 0 }}
                                          >
                                            <a
                                              href="#"
                                              style={{
                                                padding: "2px 10px",
                                                fontSize: 12,
                                              }}
                                            >
                                              {" "}
                                              Explore More
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div
                                class="equal-height col-md-3"
                                style={{ height: 304 }}
                              >
                                <div class="item brilliantrose">
                                  <a
                                    href=""
                                    onClick={() => handleClick(1)}
                                    style={{ backgroundColor: "#e948ae" }}
                                  >
                                    <div class="icon">
                                      <icon
                                        class="fas fa-user-graduate"
                                        style={{
                                          color: "white",
                                          fontSize: "4rem",
                                          marginBottom: 10,
                                        }}
                                      ></icon>
                                    </div>
                                    <div class="info">
                                      <h5
                                        style={{
                                          fontSize: 20,
                                          fontWeight: "bold",
                                          color: "white",
                                        }}
                                      >
                                        {" "}
                                        Graduate Programs
                                      </h5>
                                      {/* <p>
                                        attention say frankness intention out dashwoods now curiosity. Stronger ecstatic as no judgment daughter.
                                    </p> */}
                                      <div className="blog-area">
                                        <div className="info">
                                          <div
                                            className="content"
                                            style={{ padding: 0 }}
                                          >
                                            <a
                                              href="#"
                                              style={{
                                                padding: "2px 10px",
                                                fontSize: 12,
                                              }}
                                            >
                                              {" "}
                                              Explore More
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div
                                class="equal-height col-md-3"
                                style={{ height: 304 }}
                              >
                                <div class="item brilliantrose">
                                  <a
                                    href=""
                                    onClick={() => handleClick(2)}
                                    style={{ backgroundColor: "#ff6d24" }}
                                  >
                                    <div class="icon">
                                      <icon
                                        class="fa fa-graduation-cap"
                                        style={{
                                          color: "white",
                                          fontSize: "4rem",
                                          marginBottom: 10,
                                        }}
                                      ></icon>
                                    </div>
                                    <div class="info">
                                      <h5
                                        style={{
                                          fontSize: 20,
                                          fontWeight: "bold",
                                          color: "white",
                                        }}
                                      >
                                        Doctoral Programs
                                      </h5>
                                      {/* <p>
                                        attention say frankness intention out dashwoods now curiosity. Stronger ecstatic as no judgment daughter.
                                    </p> */}
                                      <div className="blog-area">
                                        <div className="info">
                                          <div
                                            className="content"
                                            style={{ padding: 0 }}
                                          >
                                            <a
                                              href="#"
                                              style={{
                                                padding: "2px 10px",
                                                fontSize: 12,
                                              }}
                                            >
                                              {" "}
                                              Explore More
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div
                                class="equal-height col-md-3"
                                style={{ height: 304 }}
                              >
                                <div class="item casablanca">
                                  <a
                                    href=""
                                    onClick={() => handleClick(3)}
                                    style={{ backgroundColor: "#3d83cd" }}
                                  >
                                    <div class="icon">
                                      <icon
                                        class="fas fa-file-alt"
                                        style={{
                                          color: "white",
                                          fontSize: "4rem",
                                          marginBottom: 10,
                                        }}
                                      ></icon>
                                    </div>
                                    <div class="info">
                                      <h5
                                        style={{
                                          fontSize: 20,
                                          fontWeight: "bold",
                                          color: "white",
                                        }}
                                      >
                                        Certificate/Diploma Programs
                                      </h5>
                                      {/* <p>
                                        attention say frankness intention out dashwoods now curiosity. Stronger ecstatic as no judgment daughter.
                                    </p> */}
                                      <div className="blog-area">
                                        <div className="info">
                                          <div
                                            className="content"
                                            style={{ padding: 0 }}
                                          >
                                            <a
                                              href="#"
                                              style={{
                                                padding: "2px 10px",
                                                fontSize: 12,
                                              }}
                                            >
                                              {" "}
                                              Explore More
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* <div className="course-cats-area default-padding bottom-less">
        <div className="container">
        <div class="site-heading text-center"><div class="col-md-8 col-md-offset-2"><h2>Upcoming Events</h2></div></div>
            <div className="row">
                <div className="cats-box text-center text-light">

                    <div className="single-item equal-height col-md-3 col-sm-6">
                        <div className="item">
                        
                            <a href="#" style={{backgroundImage: "url(" + "../assets/img/courses/1.jpg" + ")",}}>
                                <h5>Algorithms</h5>
                                <span>28 Courses</span>
                            </a>
                        </div>
                    </div>

                    <div className="single-item equal-height col-md-3 col-sm-6">
                        <div className="item">
                            <a href="#" style={{backgroundImage: "url(" + "../assets/img/courses/2.jpg" + ")",}}>
                                <h5>Computer Science</h5>
                                <span>53 Courses</span>
                            </a>
                        </div>
                    </div>

                    <div className="single-item equal-height col-md-3 col-sm-6">
                        <div className="item">
                            <a href="#" style={{backgroundImage: "url(" + "../assets/img/courses/3.jpg" + ")",}}>
                                <h5>Data Science</h5>
                                <span>134 Courses</span>
                            </a>
                        </div>
                    </div>

                    <div className="single-item equal-height col-md-3 col-sm-6">
                        <div className="item">
                            <a href="#" style={{backgroundImage: "url(" + "../assets/img/courses/4.jpg" + ")",}}>
                                <h5>Programming</h5>
                                <span>16 Courses</span>
                            </a>
                        </div>
                    </div>

                    <div className="single-item equal-height col-md-3 col-sm-6">
                        <div className="item">
                            <a href="#" style={{backgroundImage: "url(" + "../assets/img/courses/5.jpg" + ")",}}>
                                <h5>Engineering</h5>
                                <span>75 Courses</span>
                            </a>
                        </div>
                    </div>

                    <div className="single-item equal-height col-md-3 col-sm-6">
                        <div className="item">
                            <a href="#" style={{backgroundImage: "url(" + "../assets/img/courses/6.jpg" + ")",}}>
                                <h5>Machine Learning</h5>
                                <span>12 Courses</span>
                            </a>
                        </div>
                    </div>

                    <div className="single-item equal-height col-md-3 col-sm-6">
                        <div className="item">
                            <a href="#" style={{backgroundImage: "url(" + "../assets/img/courses/7.jpg" + ")",}}>
                                <h5>Architecture</h5>
                                <span>245 Courses</span>
                            </a>
                        </div>
                    </div>

                    <div className="single-item equal-height col-md-3 col-sm-6">
                        <div className="item">
                            <a href="#" style={{backgroundImage: "url(" + "../assets/img/courses/1.jpg" + ")",}}>
                                <h5>Art & Design</h5>
                                <span>57 Courses</span>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div> */}

              <div
                class="features-area default-padding-bottom fixed bottom-less bg-color text-light"
                style={{ marginTop: "50" }}
              >
                <div class="container">
                  <div className="weekly-top-items">
                    <div className="col-md-12">
                      <div className="top-courses" style={{ paddingRight: 0 }}>
                        <div className="heading" style={{ paddingBottom: 15 }}>
                          <h4 style={{ color: "#3f8ccb" }}>
                            STUDY ABROAD PROGRAMS & UNIVERSITY <strong></strong>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <div class="features">
                      <div
                        class="equal-height col-md-3 col-sm-6"
                        style={{ height: 330 }}
                      >
                        <div class="item mariner">
                          <a href="#" onClick={() => handleUniversities(0)}>
                            <div class="icon">
                              <icon
                                style={{
                                  color: "white",
                                  fontSize: "4rem",
                                  marginBottom: 10,
                                }}
                                class="fa fa-university"
                              ></icon>
                            </div>
                            <div class="info">
                              <h1>947</h1>
                              <h4>Universities</h4>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div
                        class="equal-height col-md-3 col-sm-6"
                        style={{ height: 330 }}
                      >
                        <div class="item brilliantrose">
                          <a href="#">
                            <div class="icon">
                              <icon
                                style={{
                                  color: "white",
                                  fontSize: "4rem",
                                  marginBottom: 10,
                                }}
                                class="fa fa-graduation-cap"
                              ></icon>
                            </div>
                            <div class="info">
                              <h1>56184</h1>
                              <h4>Programs</h4>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div
                        class="equal-height col-md-3 col-sm-6"
                        style={{ height: 330 }}
                      >
                        <div class="item casablanca">
                          <a href="#" onClick={() => handleCountries(1)}>
                            <div class="icon">
                              <icon
                                style={{
                                  color: "white",
                                  fontSize: "4rem",
                                  marginBottom: 10,
                                }}
                                class="fa fa-flag"
                              ></icon>
                            </div>
                            <div class="info">
                              <h1>11</h1>
                              <h4>Countries</h4>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div
                        class="equal-height col-md-3 col-sm-6"
                        style={{ height: 330 }}
                      >
                        <div class="item malachite">
                          <a href="#">
                            <div class="icon">
                              <icon
                                style={{
                                  color: "white",
                                  fontSize: "4rem",
                                  marginBottom: 10,
                                }}
                                class="fa fa-users"
                              ></icon>
                            </div>
                            <div class="info">
                              <h1>28052</h1>
                              <h4>Students</h4>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="weekly-top-items carousel-shadow default-padding-bottom">
                <div className="container">
                  <div className="row" style={{ display: "block" }}>
                    <div className="col-md-12">
                      <div className="top-courses">
                        <div className="heading">
                          <h4>
                            Trending Programs <strong></strong>
                          </h4>
                        </div>
                        <div className="top-course-items top-courses-carousel owl-carousel owl-theme">
                          <div className="item">
                            <div className="thumb">
                              <span className="badge">New</span>
                              <img src="assets/img/courses/1.jpg" alt="Thumb" />
                              <div className="overlay">
                                <a href="#">
                                  <img
                                    src="assets/img/team/1.jpg"
                                    alt="Thumb"
                                  />
                                </a>
                                <ul>
                                  <li>
                                    <i className="fas fa-clock"></i> 04:15:38
                                  </li>
                                  <li>
                                    <i className="fas fa-list-ul"></i> 18
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="info">
                              <div className="meta">
                                <ul>
                                  <li>
                                    <a href="#">Education</a>
                                    <a href="#">Tech</a>
                                  </li>
                                  <li>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star-half-alt"></i>
                                    <span>(1k)</span>
                                  </li>
                                </ul>
                              </div>
                              <h4>
                                <a href="#">data science and software</a>
                              </h4>
                              <p>
                                Wisdom praise things she before. Be mother
                                itself vanity favour do me of. Begin sex was
                                power joy after had walls miles.
                              </p>
                              <div className="footer-meta">
                                <div className="blog-area">
                                  <div className="info">
                                    <div
                                      className="content"
                                      style={{ padding: 0 }}
                                    >
                                      <a
                                        href="#"
                                        style={{
                                          padding: "10px 30px",
                                          color: "#000",
                                        }}
                                      >
                                        <i className="fa fa-bookmark"></i> Book
                                        Now
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="item">
                            <div className="thumb">
                              <img src="assets/img/courses/2.jpg" alt="Thumb" />
                              <div className="overlay">
                                <a href="#">
                                  <img
                                    src="assets/img/team/2.jpg"
                                    alt="Thumb"
                                  />
                                </a>
                                <ul>
                                  <li>
                                    <i className="fas fa-clock"></i> 08:27:00
                                  </li>
                                  <li>
                                    <i className="fas fa-list-ul"></i> 65
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="info">
                              <div className="meta">
                                <ul>
                                  <li>
                                    <a href="#">Illustration</a>
                                    <a href="#">Design</a>
                                  </li>
                                  <li>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <span>(2k)</span>
                                  </li>
                                </ul>
                              </div>
                              <h4>
                                <a href="#">Social Science & Humanities</a>
                              </h4>
                              <p>
                                Wisdom praise things she before. Be mother
                                itself vanity favour do me of. Begin sex was
                                power joy after had walls miles.
                              </p>
                              <div className="footer-meta">
                                <div className="blog-area">
                                  <div className="info">
                                    <div
                                      className="content"
                                      style={{ padding: 0 }}
                                    >
                                      <a
                                        href="#"
                                        style={{
                                          padding: "10px 30px",
                                          color: "#000",
                                        }}
                                      >
                                        <i className="fa fa-bookmark"></i> Book
                                        Now
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="item">
                            <div className="thumb">
                              <span className="badge">Best Seller</span>
                              <img src="assets/img/courses/6.jpg" alt="Thumb" />
                              <div className="overlay">
                                <a href="#">
                                  <img
                                    src="assets/img/team/3.jpg"
                                    alt="Thumb"
                                  />
                                </a>
                                <ul>
                                  <li>
                                    <i className="fas fa-clock"></i> 04:15:38
                                  </li>
                                  <li>
                                    <i className="fas fa-list-ul"></i> 32
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="info">
                              <div className="meta">
                                <ul>
                                  <li>
                                    <a href="#">Science</a>
                                    <a href="#">Tech</a>
                                  </li>
                                  <li>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <span>(4.6k)</span>
                                  </li>
                                </ul>
                              </div>
                              <h4>
                                <a href="#">Programming MasterclassName</a>
                              </h4>
                              <p>
                                Wisdom praise things she before. Be mother
                                itself vanity favour do me of. Begin sex was
                                power joy after had walls miles.
                              </p>
                              <div className="footer-meta">
                                <div className="blog-area">
                                  <div className="info">
                                    <div
                                      className="content"
                                      style={{ padding: 0 }}
                                    >
                                      <a
                                        href="#"
                                        style={{
                                          padding: "10px 30px",
                                          color: "#000",
                                        }}
                                      >
                                        <i className="fa fa-bookmark"></i> Book
                                        Now
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-4">
                    <div className="top-author">
                        <h4>Fast filling universities</h4>
                        <div className="author-items">

                            <div className="item">
                                <div className="thumb">
                                    <a href="#">
                                        <img src="assets/img/team/6.jpg" alt="Thumb" />
                                    </a>
                                </div>
                                <div className="info">
                                    <h5><a href="#">Professon. Nuri Paul</a></h5>
                                    <ul>
                                        <li>Students enrolled <strong>12k+</strong></li>
                                        <li>
                                            <span>Rating</span>
                                            <div className="rating">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star-half-alt"></i>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="item">
                                <div className="thumb">
                                    <a href="#">
                                        <img src="assets/img/team/7.jpg" alt="Thumb" />
                                    </a>
                                </div>
                                <div className="info">
                                    <h5><a href="#">Dr. Bubly Minu</a></h5>
                                    <ul>
                                        <li>Students enrolled <strong>16k+</strong></li>
                                        <li>
                                            <span>Rating</span>
                                            <div className="rating">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="item">
                                <div className="thumb">
                                    <a href="#"><img src="assets/img/team/8.jpg" alt="Thumb" /></a>
                                </div>
                                <div className="info">
                                    <h5><a href="#">Monayem Pruda</a></h5>
                                    <ul>
                                        <li>Students enrolled <strong>23k+</strong></li>
                                        <li>
                                            <span>Rating</span>
                                            <div className="rating">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star-half-alt"></i>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <a href="#">View All <i className="fas fa-angle-double-right"></i></a>
                        </div>
                    </div>
                </div>*/}
                  </div>
                </div>
              </div>

              <div className="weekly-top-items carousel-shadow default-padding-bottom">
                <div className="container">
                  <div className="row" style={{ display: "block" }}>
                    <div className="col-md-12">
                      <div className="top-courses">
                        <div className="heading">
                          <h4>
                            Trending Universities <strong></strong>
                          </h4>
                        </div>
                        <div className="top-course-items top-courses-carousel owl-carousel owl-theme">
                          <div className="item">
                            <div className="thumb">
                              <span className="badge">New</span>
                              <img src="assets/img/courses/1.jpg" alt="Thumb" />
                              <div className="overlay">
                                <a href="#">
                                  <img
                                    src="assets/img/team/1.jpg"
                                    alt="Thumb"
                                  />
                                </a>
                                <ul>
                                  <li>
                                    <i className="fas fa-clock"></i> 04:15:38
                                  </li>
                                  <li>
                                    <i className="fas fa-list-ul"></i> 18
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="info">
                              <div className="meta">
                                <ul>
                                  <li>
                                    <a href="#">Education</a>
                                    <a href="#">Tech</a>
                                  </li>
                                  <li>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star-half-alt"></i>
                                    <span>(1k)</span>
                                  </li>
                                </ul>
                              </div>
                              <h4>
                                <a href="#">Florida International University</a>
                              </h4>
                              <p>University Park, Florida,U.S.USA...</p>
                              <div className="footer-meta">
                                <div className="blog-area">
                                  <div className="info">
                                    <div
                                      className="content"
                                      style={{ padding: 0 }}
                                    >
                                      <a
                                        href="#"
                                        style={{
                                          padding: "10px 30px",
                                          color: "#000",
                                        }}
                                      >
                                        <i className="fa fa-bookmark"></i>{" "}
                                        Contact
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="item">
                            <div className="thumb">
                              <img src="assets/img/courses/2.jpg" alt="Thumb" />
                              <div className="overlay">
                                <a href="#">
                                  <img
                                    src="assets/img/team/2.jpg"
                                    alt="Thumb"
                                  />
                                </a>
                                <ul>
                                  <li>
                                    <i className="fas fa-clock"></i> 08:27:00
                                  </li>
                                  <li>
                                    <i className="fas fa-list-ul"></i> 65
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="info">
                              <div className="meta">
                                <ul>
                                  <li>
                                    <a href="#">Illustration</a>
                                    <a href="#">Design</a>
                                  </li>
                                  <li>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <span>(2k)</span>
                                  </li>
                                </ul>
                              </div>
                              <h4>
                                <a href="#">Florida International University</a>
                              </h4>
                              <p>University Park, Florida,U.S.USA...</p>
                              <div className="footer-meta">
                                <div className="blog-area">
                                  <div className="info">
                                    <div
                                      className="content"
                                      style={{ padding: 0 }}
                                    >
                                      <a
                                        href="#"
                                        style={{
                                          padding: "10px 30px",
                                          color: "#000",
                                        }}
                                      >
                                        <i className="fa fa-bookmark"></i>{" "}
                                        Contact
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="item">
                            <div className="thumb">
                              {/* <span className="badge">Best Seller</span> */}
                              <img src="assets/img/courses/6.jpg" alt="Thumb" />
                              <div className="overlay">
                                <a href="#">
                                  <img
                                    src="assets/img/team/3.jpg"
                                    alt="Thumb"
                                  />
                                </a>
                                <ul>
                                  <li>
                                    <i className="fas fa-clock"></i> 04:15:38
                                  </li>
                                  <li>
                                    <i className="fas fa-list-ul"></i> 32
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="info">
                              <div className="meta">
                                <ul>
                                  <li>
                                    <a href="#">Science</a>
                                    <a href="#">Tech</a>
                                  </li>
                                  <li>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <span>(4.6k)</span>
                                  </li>
                                </ul>
                              </div>
                              <h4>
                                <a href="#">Florida International University</a>
                              </h4>
                              <p>University Park, Florida,U.S.USA...</p>
                              <div className="footer-meta">
                                <div className="blog-area">
                                  <div className="info">
                                    <div
                                      className="content"
                                      style={{ padding: 0 }}
                                    >
                                      <a
                                        href="#"
                                        style={{
                                          padding: "10px 30px",
                                          color: "#000",
                                        }}
                                      >
                                        <i className="fa fa-bookmark"></i>{" "}
                                        Contact
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-4">
                    <div className="top-author">
                        <h4>Fast filling universities</h4>
                        <div className="author-items">

                            <div className="item">
                                <div className="thumb">
                                    <a href="#">
                                        <img src="assets/img/team/6.jpg" alt="Thumb" />
                                    </a>
                                </div>
                                <div className="info">
                                    <h5><a href="#">Professon. Nuri Paul</a></h5>
                                    <ul>
                                        <li>Students enrolled <strong>12k+</strong></li>
                                        <li>
                                            <span>Rating</span>
                                            <div className="rating">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star-half-alt"></i>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="item">
                                <div className="thumb">
                                    <a href="#">
                                        <img src="assets/img/team/7.jpg" alt="Thumb" />
                                    </a>
                                </div>
                                <div className="info">
                                    <h5><a href="#">Dr. Bubly Minu</a></h5>
                                    <ul>
                                        <li>Students enrolled <strong>16k+</strong></li>
                                        <li>
                                            <span>Rating</span>
                                            <div className="rating">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="item">
                                <div className="thumb">
                                    <a href="#"><img src="assets/img/team/8.jpg" alt="Thumb" /></a>
                                </div>
                                <div className="info">
                                    <h5><a href="#">Monayem Pruda</a></h5>
                                    <ul>
                                        <li>Students enrolled <strong>23k+</strong></li>
                                        <li>
                                            <span>Rating</span>
                                            <div className="rating">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star-half-alt"></i>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <a href="#">View All <i className="fas fa-angle-double-right"></i></a>
                        </div>
                    </div>
                </div>*/}
                  </div>
                </div>
              </div>

              {/* <div className="testimonials-area carousel-shadow active-dots bg-gray default-padding bg-cover" style={{backgroundImage: "url(" + "../assets/img/shape-bg.png" + ")",}}>
        <div className="container">
            <div className="">
                <div className="site-heading text-center">
                    <div className="col-md-8 col-md-offset-2">
                        <h2>What Students Says</h2>
                       
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="testimonial-items testimonial-carousel owl-carousel owl-theme text-center">
                        <div className="item">
                            <div className="icon">
                                <i className="ti-quote-left"></i>
                            </div>
                            <div className="content">
                                <p>
                                    Regret eat looked warmth easily far should now. Prospect at me wandered on extended wondered thoughts appetite to. Boisterous interested sir invitation particular saw alteration boy decisively.
                                </p>
                                <div className="rating">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star-half-alt"></i>
                                </div>
                                <img src="assets/img/team/1.jpg" alt="Thumb" />
                            </div>
                            <div className="author">
                                <h4>Jonat Harik</h4>
                                <span>Student of DIU</span>
                            </div>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <i className="ti-quote-left"></i>
                            </div>
                            <div className="content">
                                <p>
                                    Ashamed herself has distant can studied mrs. Led therefore its middleton perpetual fulfilled provision frankness. Small he drawn after among every three no.
                                </p>
                                <div className="rating">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star-half-alt"></i>
                                </div>
                                <img src="assets/img/team/2.jpg" alt="Thumb" />
                            </div>
                            <div className="author">
                                <h4>Bunah Ahem</h4>
                                <span>Student of COO</span>
                            </div>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <i className="ti-quote-left"></i>
                            </div>
                            <div className="content">
                                <p>
                                    Regret eat looked warmth easily far should now. Prospect at me wandered on extended wondered thoughts appetite to. Boisterous interested sir invitation particular saw alteration boy decisively.
                                </p>
                                <div className="rating">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star-half-alt"></i>
                                </div>
                                <img src="assets/img/team/3.jpg" alt="Thumb" />
                            </div>
                            <div className="author">
                                <h4>Proda Huk</h4>
                                <span>Student of ICC</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
              <div
                className="fun-factor-area default-padding text-center bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/20.jpg" + ")",
                }}
              >
                <div className="container" style={{ padding: 100 }}>
                  <div className="row" style={{ display: "block" }}>
                    <div className="col-md-2 ">
                      <div className="fun-fact">
                        <div className="icon">
                          <i class="fab fa-facebook-f"></i>
                        </div>
                        <div className="info">
                          <span
                            className="timer"
                            data-to="116179"
                            data-speed="5000"
                          ></span>
                          <span className="medium">Facebook</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-6 item">
                      <div className="fun-fact">
                        <div className="icon">
                          <i className="fab fa-twitter" aria-hidden="true"></i>
                        </div>
                        <div className="info">
                          <span
                            className="timer"
                            data-to="116000"
                            data-speed="5000"
                          ></span>
                          <span className="medium">Twitter</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-6 item">
                      <div className="fun-fact">
                        <div className="icon">
                          <i className="fab fa-instagram"></i>
                        </div>
                        <div className="info">
                          <span
                            className="timer"
                            data-to="37300"
                            data-speed="5000"
                          ></span>
                          <span className="medium">Instagram</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-6 item">
                      <div className="fun-fact">
                        <div className="icon">
                          <i className="fab fa-youtube"></i>
                        </div>
                        <div className="info">
                          <span
                            className="timer"
                            data-to="11184"
                            data-speed="5000"
                          ></span>
                          <span className="medium">Youtube</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-6 item">
                      <div className="fun-fact">
                        <div className="icon">
                          <i className="fab fa-facebook-f"></i>
                        </div>
                        <div className="info">
                          <span
                            className="timer"
                            data-to="880"
                            data-speed="5000"
                          ></span>
                          <span className="medium">Facebook Review</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-6 item">
                      <div className="fun-fact">
                        <div className="icon">
                          <i className="fab fa-google-plus"></i>
                        </div>
                        <div className="info">
                          <span
                            className="timer"
                            data-to="100"
                            data-speed="5000"
                          ></span>
                          <span className="medium">Google Review</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Container>
  );
}
