/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import { Text, StyleSheet } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
  MDBSideNav,
  MDBNavItem,
} from "mdbreact";

//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";

import color from "@material-ui/core/colors/amber";
import Header from "./Header";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
  category: {
    position: "sticky",
  },
  category: {
    a: {
      display: "block",
    },
  },
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [messages, setMessages] = React.useState("");
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus, setStatus] = React.useState("");
  const [showData,setShowData]=React.useState('Aerospace Engineering')

  const handlePicture = (event) => {
    setPicturePath(URL.createObjectURL(event.target.files[0]));
    setPicture(event.target.files[0]);
  };

  const handleVerifyPassword = (e) => {
    setVerifyPassword(e.target.value);
    if (password === e.target.value) {
      setStatus("Password Matched");
    } else {
      setStatus("Password not matched");
    }
  };

  //   const addNewRecord=async()=>{
  // if(picture=='')
  // {
  //     var body={'firstName':firstName,
  //     'lastName':lastName,
  //     'emailId':emailId,
  //     'password':password,
  //     'status':"Not Approved",
  //     'picture':"Not Found"}
  //     var result=await postData('selfregister/addNewRecord',body)
  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}

  // }

  // else{
  //    var formData=new FormData()
  //    formData.append('firstName',firstName)
  //    formData.append('lastName',lastName)
  //    formData.append('emailId',emailId)
  //    formData.append('password',password)
  //    formData.append('picture',picture)
  //    formData.append('status', "Not Approve")

  //    var config={headers:{'content-type':'multipart/form-data'}}
  //     var result=await postDataAndImage('selfregister/addNewRecordPic',formData,config)

  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}
  //     }
  //     }

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />
      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 70,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">Course Guide</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="blog-area single full-blog left-sidebar full-blog default-padding">
              <div className="container">
                <div className="row">
                  <div className="blog-items">
                    
                    <div
                      className="sidebar col-md-4"
                      style={{
                        paddingLeft: 35,
                        overflow: "auto",
                        display: "block",
                        position:'sticky',
                        top:0,
                      }}
                    >
                      <aside>
                        <div
                          className="sidebar-item category"
                          style={{ padding: 15 , overflow:'scroll',
                          height:550}}
                        >
                          <div className="title">
                            <h4 style={{ fontSize: 25 }}>Course</h4>
                          </div>
                          <div className="course">
                            <ul
                              style={{
                                listStyleType: "square",
                                color: "black",
                              }}
                            >
                              <li>
                                <a onClick={()=>setShowData('Aerospace Engineering')} style={{ color: "black" }}>
                                  Aerospace Engineering
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Computer Engineering')} style={{ color: "black" }}>
                                  Computer Engineering
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Computer Science')} style={{ color: "black" }}>
                                  Computer Science
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Data Science')} style={{ color: "black" }}>
                                  Data Science
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Biological Engineering')} style={{ color: "black" }}>
                                  Biological Engineering
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Biomedical Engineering')} style={{ color: "black" }}>
                                  Biomedical Engineering
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Chemical Engineering')} style={{ color: "black" }}>
                                  Chemical Engineering
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Civil Engineering')} style={{ color: "black" }}>
                                  Civil Engineering
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Cyber Security')} style={{ color: "black" }}>
                                  Cyber Security
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Electrical Engineering')} style={{ color: "black" }}>
                                  Electrical Engineering
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Embedded Systems')} style={{ color: "black" }}>
                                  Embedded Systems
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Telecommunication')} style={{ color: "black" }}>
                                  Telecommunication
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Engineering Management')} style={{ color: "black" }}>
                                  Engineering Management
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Financial Technology')} style={{ color: "black" }}>
                                  Financial Technology
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Industrial Engineering')} style={{ color: "black" }}>
                                  Industrial Engineering
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Masters of Business Administration')} style={{ color: "black" }}>
                                  Masters of Business Administration (MBA)
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Entrepreneurship')} style={{ color: "black" }}>
                                  Entrepreneurship
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Sport management')} style={{ color: "black" }}>
                                  Sport management
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Business Analytics')} style={{ color: "black" }}>
                                  What's Business Analytics?
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('MIM')} style={{ color: "black" }}>
                                  What's Management (MIM)?
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Accounting')} style={{ color: "black" }}>
                                  Accounting
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Health Care')} style={{ color: "black" }}>
                                  What's Health Care Management?
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Human Resources Management')} style={{ color: "black" }}>
                                  Human Resources Management
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Marketing')} style={{ color: "black" }}>
                                  Marketing
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Architecture')} style={{ color: "black" }}>
                                  Architecture
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Journalism')} style={{ color: "black" }}>
                                  Journalism
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Nursing')} style={{ color: "black" }}>
                                  Nursing
                                </a>
                              </li>
                              <li>
                                <a onClick={()=>setShowData('Pharmacy')} style={{ color: "black" }}>
                                  Pharmacy
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </aside>
                    </div>

                    <div className="blog-content col-md-8">
                      <div className="content-items">
                        <div className="single-item">
                          <div className="item">
                            <div className="info">
                              <div
                                className="content"
                                style={{ paddingBottom: 0 }}
                              >
                                <div
                                  style={{
                                    marginLeft: 5,
                                    textAlign: "justify",
                                  }}
                                >
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Aerospace Engineering'?(
                                  <div id="AE">
                                    <h4>Aerospace Engineering</h4>

                                    <p>
                                      Since the flight technologies slowly
                                      improved to craft working from the outside
                                      area (aeronautics), this expression came
                                      to use. It's two main branches:
                                      Aeronautical Engineering and Astronautical
                                      Engineering.
                                      <br />
                                      <br />
                                      Aerospace Engineering is a branch of
                                      technology concerned with the evolution of
                                      aircraft and spacecraft. Aerospace
                                      Engineering entails interaction between
                                      technology such as aerodynamics,
                                      propulsion, materials scienceand
                                      structural investigation and production.
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Prerequisites to Study Aerospace
                                      Engineering
                                    </p>
                                    <p>
                                      IELTS or TOEFL is mandatory for obtaining
                                      a student visa and as an evidence of
                                      English proficiency.
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Core Topics in Aerospace Engineering
                                    </p>

                                    <p>
                                      In Aerospace Engineering, students get an
                                      chance to study the principles regarding
                                      the science of flight and its own motion.
                                      A few of it's subjects include:
                                    </p>
                                    <p>
                                      Specialization Issues include: <br />
                                      Aircraft and Spacecraft Guidance and
                                      Control
                                      <br />
                                      Aeronautics
                                      <br />
                                      Micro Air Vehicles
                                      <br />
                                      Navigation
                                      <br />
                                      Optimization Techniques for Aerospace
                                      Systems
                                      <br />
                                      Little Satellites
                                      <br />
                                      Space and Atmospheric Flight Optimal
                                      Control
                                      <br />
                                      Space Cryogenic Fluids Thermal Control
                                      <br />
                                      Space Environment Modeling
                                      <br />
                                      Space Mission Design and Planning
                                      <br />
                                      Spacecraft and Vehicle Design
                                      <br />
                                    </p>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Price of Studying Aerospace Engineering
                                    </p>
                                    <p>
                                      Even though the prices differ from
                                      university to college, the normal price of
                                      analyzing aerospace from the USA is about
                                      $38, 000. It is almost the Exact Same in
                                      Canada. In Australia, it is around AU$20,
                                      000 - AU$37000.
                                    </p>
                                    <br />
                                    <hr />
                                  </div>
                                  ):<></>}

                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Computer Engineering'?(
                                  <div id="CE">
                                    <h4>Computer Engineering</h4>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Why Research Bachelor of Computer
                                      Engineering?
                                    </p>
                                    <p>
                                      Computer Engineering isalso in ways, a
                                      step up from science. Big shots such as
                                      Google and Amazon themselves operate on
                                      applications created and made by
                                      engineers. So, 1 thing is for certain;
                                      there is big bucks involved! The
                                      fastidiousness aside, a computer engineer
                                      is a place of responsibility and involves
                                      all sorts of fun tasks. It is also
                                      possible to expand into other professions
                                      or enter entrepreneurship. Your career
                                      should you decide to major in computer
                                      technology (Mission Impossible Reference).
                                      . .will be a smart one.
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      What is Computer Engineering?
                                    </p>
                                    <p>
                                      A branch of technology which incorporates
                                      several areas of computer science and
                                      electronic technology needed to create
                                      computer hardware and software. Computer
                                      Engineering is a growing discipline of
                                      technology. Networking, architecture,
                                      infrastructure, in addition to program and
                                      web development all play their role in
                                      computer technology; the creators of
                                      Google, Facebook, and Amazon came because
                                      of their engineering developed by
                                      engineers.
                                      <br />
                                      <br />
                                      A profession in Computer Engineering will
                                      entail creating design patterns for part
                                      production; testing and/or assessing
                                      computer gear; troubleshooting hardware
                                      structure, etc.. Hardware technology (e.g.
                                      microprocessors) and applications
                                      development are the 2 areas where job
                                      could be found readily after graduation.
                                      Some likely companies for could include
                                      research labs, technology manufacturers,
                                      semiconductor companies, electronic
                                      consulting companies amongst others.
                                      <br />
                                      <br />
                                      Even though the prices differ from
                                      university to college, the average tuition
                                      price for bachelors in Computer
                                      Engineering at the united states is
                                      $39,000(28.02 Lakhs) annually. In
                                      Australia, typical tuition prices are
                                      AU$36,500(18.11 Lakhs) /year & in Canada
                                      the typical tuition prices are
                                      C$43,900(24.33 Lakhs) annually.
                                    </p>
                                    <br />
                                    <hr />
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Computer Science'?(
                                  <div id="CS">
                                    <h4>Computer Science</h4>

                                    <p>
                                      Bear in mind the very first PC you
                                      employed? Maybe it was a box of something,
                                      as large as a house? Or maybe you're
                                      fortunate enough to be around when the
                                      thinner versions came out. Whatever
                                      situation you struck your first personal
                                      computer, did you receive an irresistible
                                      urge to take it apart and know bit by bit
                                      about what makes it move? Or maybe
                                      understand what was from the so
                                      called'mind' of the computer which wasn't
                                      observable but could nevertheless make so
                                      much potential? Well, a class in computer
                                      science can answer these questions and
                                      much more. CS can also be concerning the
                                      collection and evaluation of humungous
                                      information with the support of
                                      calculations. If this sounds interesting
                                      to you. . .Go for this!
                                      <br />
                                      <br />
                                      Computer Science or CS, a varied
                                      discipline, is the analysis of computer
                                      technology, both hardware and software. It
                                      may be broken into a selection of
                                      sub-disciplines like computer concept,
                                      hardware systems, software systems, and
                                      computing. CS research the procedures that
                                      interact with information and that may be
                                      represented as information in the shape of
                                      programs. It enables using calculations to
                                      control, store, and convey electronic data
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      What topics are needed for Computer
                                      Science
                                    </p>
                                    <p>
                                      <ul style={{ listStyleType: "square" }}>
                                        <li>Calculus</li>

                                        <li>General Mathematics</li>
                                        <li>Computer Science</li>
                                        <li>Profession</li>
                                        <li>
                                          Core Courses in Computer Science
                                        </li>
                                        <li>
                                          Fundamental intro into C++ programming
                                        </li>
                                        <li>Low level programming</li>
                                        <li>Computer Network Basics</li>
                                        <li>
                                          Intro to Algorithms and Complexity
                                        </li>
                                        <li>
                                          Software Design & Implementation
                                        </li>
                                        <li>
                                          Probability & Statistics for Computer
                                          Science
                                        </li>
                                        <li>Computer Pictures</li>
                                        <li>Introductory Electronics</li>
                                        <li>Career in Computer Science</li>
                                      </ul>
                                      You'd have a thriving career in Computer
                                      Science should you've got an analytical
                                      mind with a knack for learning new
                                      languages. Creatively expressing technical
                                      terminology in non-technical terms of the
                                      frequent people is the section of a CS
                                      significant livelihood. There are various
                                      chances that would be accessible after
                                      graduation, but it's determined a lot on
                                      personal abilities and interests.
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Job Roles following Computer Science
                                    </p>

                                    <p>
                                      <ul style={{ listStyleType: "square" }}>
                                        <li>Software Developer</li>
                                        <li>Sport developer</li>
                                        <li>Cyber safety adviser</li>
                                        <li>Data analyst</li>
                                        <li>Database administrator</li>
                                        <li>Enforcement pc analyst</li>
                                        <li>Computer Hardware Engineer</li>
                                        <li>
                                          Can Be Computer Science STEM
                                          course?Yes
                                        </li>
                                      </ul>
                                    </p>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Price of Studying Computer Science
                                    </p>

                                    <p>
                                      Even though the prices differ from
                                      university to college, the average tuition
                                      price for bachelors in Computer Science at
                                      the united states is $37,500(26.94 Lakhs)
                                      annually. In Australia, typical tuition
                                      prices are AU$37,200(18.45 Lakhs) /year &
                                      in Canada the typical tuition prices are
                                      C$32,200(17.84 Lakhs) annually.
                                    </p>

                                    <br />
                                    <hr />
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Data Science'?(
                                  <div id="DS">
                                    <h4>Data Science</h4>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      What's Data Science?
                                    </p>
                                    <p>
                                      Pros in Data Science is a path that
                                      revolves round 3 theories: programming,
                                      data and decision making utilizing machine
                                      learning. The conclusions drawn from
                                      information are utilized to make decisions
                                      in addition to make predictions. It uses
                                      concepts and technology to draw perception
                                      from information in a variety of forms,
                                      both unstructured and structured.
                                      <br />
                                      <br />
                                      Courses typically include topics like
                                      data, probability, large information and
                                      machine learning and optimization.
                                      There'll be many more subjects based on
                                      the university as well as the paths (if
                                      any) which you are able to pick from. You
                                      may work with large number of information,
                                      so database information is essential.
                                      Additionally, R and Python are extremely
                                      popular languages used for statistical
                                      evaluation.
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Prerequisites to Study Data Science
                                    </p>
                                    <p>
                                      Enjoying programming is a fantastic
                                      beginning. <br />
                                      You ought to have the ability to check at
                                      situations from various perspectives. Why?
                                      As you'll have to understand particular
                                      patterns in data and what they represent
                                      in actual life in order to come to
                                      detailed decisions and assist in decision
                                      making.
                                      <br />
                                      An background in mathematics and
                                      statistics is essential. This is because
                                      information is represented in charts and
                                      graphs to aid in simple comprehension of
                                      patterns and tendencies.
                                      <br />
                                      GRE score (based on college demands )
                                      <br />
                                      TOEFL/IELTS/PTE
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Core Topics in Data Science
                                    </p>

                                    <p>
                                      Data science pupils deal mostly with the
                                      information and spend a good deal of time
                                      at the practice of gathering, cleaning,
                                      and wrangling data. Few topics include:{" "}
                                      <br />
                                      <br />
                                      Specialization subjects include: <br />
                                      <br />
                                      Even Though the fees Differ from
                                      university to college, the typical tuition
                                      charges and yearly cost of living to get
                                      master's in data science in Various
                                      nations are:
                                      <br />
                                      <br />
                                      Pros in Data Science at USA: $45,000;
                                      $1,000
                                      <br />
                                      Experts in Data Science at UK: #21,000;
                                      #1,500
                                    </p>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Job Prospects after Pros in Data Science
                                    </p>
                                    <p>
                                      After finishing your degree, you could be
                                      hired for these tasks:
                                      <ul style={{ listStyleType: "square" }}>
                                        <li>Data scientist</li>
                                        <li>Data analyst</li>
                                        <li>
                                          Information mining or large
                                          information enginner
                                        </li>
                                        <li>company analyst</li>
                                        <li>statistician, etc..</li>
                                      </ul>
                                      <p>
                                        These are a couple salaries that will
                                        assist you recognize the different
                                        criteria in different states.
                                      </p>
                                    </p>
                                    <br />
                                    <hr />
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Biological Engineering'?(
                                  <div id="BlogE">
                                    <h4>Biological Engineering</h4>

                                    <p>
                                      Biological Engineering is the use of
                                      biology together with the essentials of
                                      technology to make economically viable
                                      products. It's employed in the design of
                                      medical devices, biocompatible materials,
                                      environmental engineering, diagnostic
                                      equipment, renewable bioenergy, along with
                                      other regions to create better living
                                      standards.
                                      <br />
                                      <br />
                                      Generally, Biological Engineers attempt or
                                      aim to imitate the biological methods to
                                      make products or alter and control
                                      biological systems so they can replace,
                                      expand, sustain, or expect mechanical and
                                      chemical processes.
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Prerequisites to Study Biological
                                      Engineering
                                    </p>
                                    <p>
                                      The Majority of the universities in the
                                      United States, Canada require you to
                                      Provide the GRE. Few universities out of
                                      Germany need GRE scores. On the other
                                      hand, the GRE isn't required for entrance
                                      to Australian universities. IELTS or TOEFL
                                      is mandatory for obtaining a student visa
                                      and as an evidence of English proficiency.
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Core Topics in Biological Engineering
                                    </p>

                                    <p>
                                      In biological technology, pupils attempt
                                      to mimic biological methods to control and
                                      enhance mechanical and chemical processes.
                                      Biocatalysis, bioprocess technology,
                                      genetic modification of animals and plants
                                      are a few of the different regions of
                                      application. Some topics include:
                                      <br />
                                      <br />
                                      Specialization Issues include:
                                      <br />
                                      Even though the prices differ from
                                      university to college, the average tuition
                                      price for master's research in Biological
                                      engineering at the united states are $40,
                                      000/year. They're nearly the exact same in
                                      Canada. Back in Australia, average tuition
                                      prices are A$28,200 annually and the
                                      average living costs are A$8,500annually.
                                    </p>
                                    <br />
                                    <hr />
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Biomedical Engineering'?(
                                  <div id="BioE">
                                    <h4>Biomedical Engineering</h4>

                                    <p>
                                      Biomedical Engineering field attempts to
                                      close the gap between technology and
                                      medicine, combining the design and problem
                                      solving abilities of technology with
                                      medical sciences to advance healthcare
                                      treatment, such as identification,
                                      monitoring, and treatment.
                                      <br />
                                      <br />
                                      It's the use of principles of technology
                                      to biology and medicine for health care
                                      functions. Biomedical engineering programs
                                      include the development of diagnostic and
                                      therapeutic devices, MRIs, pharmaceutical
                                      medications and therapeutic biologicals.
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Prerequisites to Study Biomedical
                                      Engineering
                                    </p>
                                    <p>
                                      IELTS or TOEFL is mandatory for obtaining
                                      a student visa and as an evidence of
                                      English proficiency.
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Core Topics in Biomedical Engineering
                                    </p>

                                    <p>
                                      Students want to bridge the gap between
                                      technology and medicine, combining the
                                      difficulty solving abilities of technology
                                      with medical sciences. A few of it's
                                      subjects include:
                                      <ul style={{ listStyleType: "square" }}>
                                        <li>
                                          Physics and mathematics of Biomedical
                                          Engineering
                                        </li>
                                        <li>
                                          Basic Mechanics for Biomedical
                                          Engineering
                                        </li>
                                        <li>Bio-fluid Mechanics</li>
                                        <li>
                                          Power of Materials for Biomedical
                                          Engineering
                                        </li>
                                        <li>
                                          Thermodynamics to Biomedical
                                          Engineering
                                        </li>
                                        Medical Aspects of Electromagnetic
                                        Theory
                                        <li>
                                          Electronic and Electrical Circuits
                                        </li>
                                        <li>Fundamental Biology</li>
                                        <li>Medical Molecular Biology</li>
                                        <li>
                                          Physiology for Biomedical Engineering
                                        </li>
                                        <li>Anatomy</li>
                                        <li>
                                          Instrumental Biomolecular Evaluation
                                        </li>
                                        <li>Basic Biochemistry</li>
                                        <li>
                                          Specialisations at Biomedical
                                          Engineering
                                        </li>
                                      </ul>
                                    </p>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Specialization subjects include:
                                      <ul style={{ listStyleType: "square" }}>
                                        <li>
                                          Cardiovascular and brain Disorders
                                        </li>
                                        <li>Cognitive neuroscience</li>
                                        <li>Molecular Self-Assembly</li>
                                        <li>
                                          Stem Cell Therapies and Engineering
                                        </li>
                                        <li>Computer Aided Diagnosis</li>
                                        <li>Multivariate Signal Processing</li>
                                        <li>Biomedical Imaging Technologies</li>
                                        <li>
                                          Biomedical Imaging for Diagnosis and
                                          Infection Treatment
                                        </li>
                                        <li>Bionic Prosthesis</li>
                                        <li>
                                          Cell and Tissue Repair, Regeneration,
                                          and Engineering
                                        </li>
                                      </ul>
                                    </p>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Price of Assessing Biomedical Engineering
                                    </p>
                                    <p>
                                      Even though the prices differ from
                                      university to college, the average tuition
                                      price for master's research in Biomedical
                                      technology in the united states are $28,
                                      000/year. They're nearly the exact same in
                                      Canada. In Australia, typical tuition
                                      prices are A$28,200 annually and the
                                      average living costs are A$8,500annually.
                                    </p>
                                    <br />
                                    <hr />
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Chemical Engineering'?(
                                  <div id="ChE">
                                    <h4>Chemical Engineering</h4>

                                    <p>
                                      Chemical Engineering as its name implies
                                      is associated with compounds. Chemical
                                      Engineering has materialized upon the
                                      progression of unit operations, a basic
                                      idea of the field of chemical technology.
                                      It utilizes principles of physics,
                                      chemistry, mathematics, and economics to
                                      effectively use, create, alter, and
                                      transportation compounds. Additionally, it
                                      entails different aspects including
                                      design, evaluation and performance facets
                                      of plants .
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Prerequisites to Study Chemical
                                      Engineering
                                    </p>
                                    <p>
                                      IELTS or TOEFL is mandatory for obtaining
                                      a student visa and as an evidence of
                                      English proficiency.
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Core Topics in Chemical Engineering
                                    </p>
                                    <p>
                                      Students design large-scale procedures
                                      that convert compounds, germs, and energy
                                      to useful forms and goods. Subjects
                                      include:
                                      <ul style={{ listStyleType: "square" }}>
                                        Fluid flow/mechanics
                                        <li>Heat & Mass move</li>
                                        <li>
                                          Chemical technology Thermodynamics
                                        </li>
                                        <li>
                                          Process control & instrumentation
                                        </li>
                                        <li>Chemical reaction technology</li>
                                        <li>Procedure calculations</li>
                                        <li>Chemical Technology/Synthesis</li>
                                        <li>
                                          Procedure & mechanical Gear layout
                                        </li>
                                        <li>optimization</li>
                                        <li>
                                          Specialisations in Chemical
                                          Engineering
                                        </li>
                                      </ul>
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Price of Studying Chemical Engineering
                                    </p>

                                    <p>
                                      Even though the prices differ from
                                      university to college, the average tuition
                                      price for master's research in Chemical
                                      technology in the united states are
                                      $23,000annually. They're nearly the exact
                                      same in Canada. In Australia, typical
                                      tuition prices are A$46,200 annually and
                                      the average living costs are
                                      A$8,500annually.
                                    </p>
                                    <br />
                                    <hr />
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Civil Engineering'?(
                                  <div id="CiE">
                                    <h4>Civil Engineering</h4>

                                    <p>
                                      Civil engineering occurs from the public
                                      business from municipal throughout to
                                      federal authorities, and at the private
                                      business from homeowners through to global
                                      businesses. Civil engineering is the use
                                      of scientific and physical principles for
                                      solving the issues of society, and its own
                                      history is dispersed connected to
                                      improvements in understanding of
                                      mathematics and physics throughout
                                      history. Since civil engineering is a
                                      broad profession, such as many technical
                                      sub-disciplines, whose background is
                                      connected to understanding of structures,
                                      materials science, geography, geology,
                                      soils, hydrology, environment, mechanics
                                      and other areas.
                                      <br />
                                      <br />
                                      Civil Engineering as its name implies is
                                      linked to building. It addresses the
                                      design, structure, and upkeep of the
                                      physical and obviously constructed
                                      environment, including functions such as
                                      railways, streets, etc..
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Prerequisites to Study Civil Engineering
                                    </p>
                                    <p>
                                      IELTS or TOEFL is mandatory for obtaining
                                      a student visa and as an evidence of
                                      English proficiency.
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Core Topics in Civil Engineering
                                    </p>
                                    <p>
                                      Students get to understand the fundamental
                                      theories of various basic principles of
                                      civil engineering and building procedures.
                                      Subjects include:
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Price of Studying Civil Engineering
                                    </p>

                                    <p>
                                      Even though the prices differ from
                                      university to college, the average tuition
                                      price for master's research in civil
                                      engineering at the united states are $23,
                                      000/year. They're nearly the exact same in
                                      Canada. In Australia, typical tuition
                                      prices are A$46,200 annually and the
                                      average living costs are A$8,500annually.
                                    </p>
                                    <br />
                                    <hr />
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Cyber Security'?(
                                  <div id="CyE">
                                    <h4>Cyber Security</h4>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      What's Cyber Security?
                                    </p>
                                    <p>
                                      Cybersecurity is the security of computer
                                      programs from theft of or damage to its
                                      own hardware, software or information. It
                                      entails controlling physical access to
                                      this machine to safeguard against
                                      malicious attack, and other malpractices.
                                      <br />
                                      <br />
                                      The area is increasing because of
                                      increasing reliance on computer systems,
                                      the web and wireless networks like wifi,
                                      Bluetooth, etc and due to developing smart
                                      devices such as smartphones, tv, and
                                      assorted little devices which constitute
                                      the net of items.
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Prerequisites to Study Cyber Security
                                    </p>
                                    <p>
                                      IELTS or TOEFL is mandatory for obtaining
                                      a student visa and as an evidence of
                                      English proficiency.
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Core Topics in Cyber Security
                                    </p>

                                    <p>
                                      Cyber safety student research about
                                      different technologies which are utilized
                                      by the business to stop malevolent attacks
                                      on computer systems. Few topics include:
                                    </p>
                                    <p>
                                      Even though the prices differ from
                                      university to college, the average tuition
                                      price for master's research in Cyber
                                      Security technology in the united states
                                      are $37, 000/year. They're nearly the
                                      exact same in Canada. In Australia,
                                      typical tuition prices are A$40000
                                      annually and the average living costs are
                                      A$8,500per year.
                                    </p>
                                    <br />
                                    <hr />
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Electrical Engineering'?(
                                  <div id="ElecE">
                                    <h4>Electrical Engineering</h4>

                                    <p>
                                      Electrical Engineering entails the
                                      analysis and application of power,
                                      electronics, and electromagnetism. Many
                                      branches such as electricity technology,
                                      electronics, computer engineering have now
                                      been phased out from engineering.
                                      Electrical engineer's actions vary a good
                                      deal. Some may design complicated power
                                      systems, though others may look
                                      microscopic electronic devices and
                                      circuitry.
                                      <br />
                                      <br />
                                      The area became an identifiable job after
                                      commercialization of the electrical
                                      telegraph, the telephone, and electrical
                                      energy distribution and its usage.
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Prerequisites to Study Electric
                                      Engineering
                                    </p>
                                    <p>
                                      IELTS or TOEFL is mandatory for obtaining
                                      a student visa and as an evidence of
                                      English proficiency.
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Core Topics in Electrical Engineering
                                    </p>

                                    <p>
                                      Electrical technology aims to cover the
                                      basic principles and principles as well as
                                      the topics include:
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Price of Studying Electrical Engineering
                                    </p>

                                    <p>
                                      Even though the prices differ from
                                      university to college, the average tuition
                                      price for master's research in Electrical
                                      Engineering at the united states are
                                      $36,000annually. They're nearly the exact
                                      same in Canada. In Australia, typical
                                      tuition prices are A$34000 annually and
                                      the average living costs are A$8,500per
                                      year.
                                    </p>
                                    <br />
                                    <hr />
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Embedded Systems'?(
                                  <div id="EmbE">
                                    <h4>Embedded Systems</h4>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      What's Embedded Systems?
                                    </p>
                                    <p>
                                      A profession in embedded systems entails
                                      analyzing subjects related to embedded
                                      hardware. Embedded systems are computer
                                      systems using a dedicated operate within a
                                      larger electrical or mechanical system. By
                                      building intelligence mechanics in
                                      addition to the hardware, benefiting from
                                      potential present detectors and the
                                      presence of a system of embedded
                                      components, an individual can both
                                      optimally handle available resources in
                                      the machine and unit levels and supply
                                      augmented capabilities, well beyond these
                                      accessible. By way of instance, smart
                                      techniques can be made to control energy
                                      consumption of embedded systems.
                                      <br />
                                      <br />
                                      They are frequently related with all the
                                      microcontrollers and microprocessors. Many
                                      daily devices such as MP3 players, digital
                                      cameras, etc.. utilize embedded approaches
                                      to achieve their performance.
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Prerequisites to Study Embedded Systems
                                    </p>
                                    <p>
                                      The Majority of the universities in the
                                      United States, Canada require you to
                                      Provide the GRE. Few universities out of
                                      Germany need GRE scores. On the other
                                      hand, the GRE isn't required for entrance
                                      to Australian universities. IELTS or TOEFL
                                      is mandatory for obtaining a student visa
                                      and as an evidence of English proficiency.
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Core Topics in Embedded Systems
                                    </p>
                                    <p>
                                      Pupils are often involved with the design
                                      and programming of digital chips. Numerous
                                      subjects are:
                                    </p>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Price of Studying Embedded Systems
                                    </p>

                                    <p>
                                      Even though the prices differ from
                                      university to college, the average tuition
                                      price for master's research in Embedded
                                      Systems technology in the united states
                                      are $36,000annually. They're nearly the
                                      exact same in Canada.
                                    </p>
                                    <br />
                                    <hr />
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Telecommunication'?(
                                  <div id="Tele">
                                    <h4>Telecommunication</h4>

                                    <p>
                                      Telecommunications relates to the
                                      transmission of signs of any Character by
                                      cable, radio or other unmanned systems.
                                      Programs include phone network, net
                                      network, etc.. Telecommunication takes
                                      place when the exchange of data between
                                      communicating participants incorporate
                                      using technologies. It's transmitted
                                      either over physical media, like cables,
                                      or through electromagnetic radiation.
                                      Telecommunication has a substantial
                                      social, cultural and financial effect on
                                      modern society.
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Prerequisites to Study Telecommunications
                                    </p>
                                    <p>
                                      IELTS or TOEFL is mandatory for obtaining
                                      a student visa and as an evidence of
                                      English proficiency.
                                    </p>

                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Core Topics in Telecommunications
                                    </p>
                                    <p>
                                      A student pupil gets an Chance to examine
                                      the fundamental
                                      <br />
                                      <br />
                                      Specializations subjects include:
                                      <ul style={{ listStyleType: "square" }}>
                                        <li>
                                          Communication technology fundamentals
                                        </li>
                                        <li>Running systems</li>
                                        <li>Telecommunication signals</li>
                                        <li>System protocols</li>
                                        <li>Performance evaluations</li>
                                        <li>Communications safety</li>
                                      </ul>
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Price of Studying Telecommunications
                                    </p>

                                    <p>
                                      Even Though the fees Differ from
                                      university to college, the typical Tuition
                                      price for master's research in
                                      Telecommunications technology in That the
                                      US is $36,000annually. They Are almost the
                                      exact same in Canada. In Australia,
                                      typical tuition prices are
                                    </p>
                                    <br />
                                    <hr />
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Engineering Management'?(
                                  <div id="EM">
                                    <h4>Engineering Management</h4>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      What's Engineering Management?
                                    </p>
                                    <p>
                                      Especially to elevate engineers into major
                                      decision making crucial roles in leading
                                      organizations. It's a profession that
                                      brings together the technical
                                      problem-solving comprehension of
                                      engineering and also the organizational
                                      facet of direction to care for the
                                      operations of technology businesses. The
                                      individual is eligible to coordinate with
                                      all the direction, production to go over
                                      project specifications and processes and
                                      reach goals and directives of their
                                      enterprise.
                                      <br />
                                      <br />
                                      Engineering Management applications
                                      typically consist of education in
                                      accounting, economics, finance, project
                                      management, systems engineering,
                                      industrial engineering, predictive
                                      modeling and optimization, management
                                      information systems, quality management &
                                      six sigma, operations management,
                                      operations analysis, human resources
                                      management, industrial psychology, and
                                      security and wellness. There are numerous
                                      alternatives for entering engineering
                                      direction, albeit the base requirement is
                                      the engineering level (or other personal
                                      computer science, math or mathematics
                                      level ) along with a business diploma. Job
                                      prospects are bright as major supervisors
                                      in technical firms or carrying up
                                      intensive functions in monetary
                                      institutions.
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Prerequisites to Study Engineering
                                      Management
                                    </p>
                                    <p>
                                      The Majority of the universities in the
                                      United States, Canada require you to
                                      Provide the GRE. Few universities out of
                                      Germany need GRE scores. On the other
                                      hand, the GRE isn't required for entrance
                                      to Australian universities. IELTS or TOEFL
                                      is mandatory for obtaining a student visa
                                      and as an evidence of English proficiency.
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Price of Studying Engineering Management
                                    </p>

                                    <p>
                                      Even Though the fees Differ from
                                      university to college, the typical Tuition
                                      price for master's research in Engineering
                                      Management at the united states Are
                                      $30,000annually. They're Almost the exact
                                      same in Canada. In Australia, typical
                                      tuition prices are
                                    </p>
                                    <br />
                                    <hr />
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Financial Technology'?(
                                  <div id="FT">
                                    <h4>Financial Technology</h4>
                                    <p>
                                      Financial Technology is a
                                      multidisciplinary field between monetary
                                      concept, methods of technology, tools of
                                      math and the tradition of programming.
                                      It's using mathematical methods to solve
                                      financial issues. It's occasionally known
                                      as quantitative investigation. Financial
                                      Engineering, unlike its title, doesn't
                                      belong to some of the conventional fields
                                      of expert engineering.
                                      <br />
                                      <br />
                                      It pulls tools from Applied math, computer
                                      science, data and financial theory.
                                      Financial technology plays an integral
                                      part in the customer-driven derivatives
                                      industry which encircles quantitative
                                      modelling and programming, trading and
                                      risk handling derivative goods in
                                      compliance with regulations and Basel
                                      capital/liquidity requirements.
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Prerequisites to Study Financial
                                      Engineering
                                    </p>
                                    <p>
                                      The Majority of the Universities in the
                                      united states, Canada ask that you provide
                                      the GRE. Few universities out of Germany
                                      need GRE scores. On the other hand, the
                                      GRE isn't required for entrance to
                                      Australian universities. IELTS or TOEFL is
                                      mandatory for obtaining student visa and
                                      as an evidence of English proficiency.
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Core Topics in Financial Engineering
                                    </p>
                                    <p>
                                      Financial Engineering research apply the
                                      fundamentals of mathematics to handle
                                      financial troubles.
                                      <br />
                                      Specialisations in Financial Engineering
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Price of Studying Financial Engineering
                                    </p>

                                    <p>
                                      Even Though the fees Vary from college to
                                      college, the average tuition price for
                                      master research in Financial Engineering
                                      at the united states are $23,000per year.
                                      Tuition prices are approximately C$20000
                                      in Canada. In Australia, typical tuition
                                      prices are A$35000 annually and the
                                      average living costs are A$8,500annually.
                                    </p>
                                    <br />
                                    <hr />
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Industrial Engineering'?(
                                  <div id="IE">
                                    <h4>Industrial Engineering</h4>
                                    <p>
                                      Industrial Engineering is a branch of
                                      engineering dealing with the optimisation
                                      of complex systems or processes. Its
                                      inherent concepts overlap substantially
                                      with specific business-oriented areas such
                                      as operations management, but the
                                      technology side tends to highlight
                                      extensive mathematical ability and
                                      utilization of qualitative procedures.
                                      <br />
                                      <br />
                                      Industrial Technology is concerned with
                                      the advancement, advancement, and
                                      implementation of integrated systems of
                                      people, cash, knowledge, data, equipment,
                                      energy, materials, evaluation and
                                      synthesis, in addition to the
                                      mathematical, physical and social sciences
                                      along with the fundamentals and processes
                                      of engineering design to specify, predict,
                                      and assess the results to be obtained from
                                      these systems or procedures. The
                                      Industrial Engineers creates technology
                                      systems and processes that enhance
                                      productivity and quality.
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Prerequisites to Study Industrial
                                      Engineering
                                    </p>
                                    <p>
                                      The Majority of the Universities in the
                                      united states, Canada ask that you provide
                                      the GRE. Few universities out of Germany
                                      need GRE scores. On the other hand, the
                                      GRE isn't required for entrance to
                                      Australian universities. IELTS or TOEFL is
                                      mandatory for obtaining student visa and
                                      as an evidence of English proficiency.
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Core Topics in Industrial Engineering
                                    </p>
                                    <p>
                                      Industrial Engineers aim to boost
                                      productivity through the management of
                                      individuals and methods of company
                                      organisation. In addition they study
                                      management control systems to help in
                                      fiscal planning, cost analysis,
                                      manufacturing preparation and physical
                                      supply of products and services. Numerous
                                      themes include:
                                      <ul style={{ listStyleType: "square" }}>
                                        <li>Engineering economics</li>

                                        <li>Engineering statistics</li>

                                        <li>Facilities design and planning</li>

                                        <li>Inventory management</li>

                                        <li>Management science</li>

                                        <li>Manufacturing technology</li>

                                        <li>
                                          Production planning and management
                                        </li>
                                      </ul>
                                      <br />
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "20",
                                        }}
                                      >
                                        Specialisations in Industrial
                                        Engineering
                                      </p>
                                      Specialization Topics include:
                                      <ul style={{ listStyleType: "square" }}>
                                        <li>Engineering economics</li>

                                        <li>Engineering statistics</li>

                                        <li>Facilities design and planning</li>

                                        <li>Inventory management</li>

                                        <li>Management science</li>

                                        <li>Manufacturing technology</li>

                                        <li>
                                          Production planning and management
                                        </li>
                                      </ul>
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Price of Studying Industrial Engineering
                                    </p>

                                    <p>
                                      Even Though the fees Vary from college to
                                      college, the average tuition price for
                                      master's research in Industrial
                                      Engineering at the united states are
                                      $30,000annually. Tuition prices are
                                      approximately C$20000 in Canada. In
                                      Australia, typical tuition prices are
                                      A$37000 annually and the average living
                                      costs are A$8,500annually.
                                    </p>
                                    <br />
                                    <hr />
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Masters of Business Administration'?(
                                  <div id="MBA">
                                    <h4>
                                      Masters of Business Administration (MBA)
                                    </h4>
                                    <p>
                                      Masters of Business Administration (MBA)
                                      includes a range of topics and
                                      professions. This is possibly the most
                                      fancied route among students who hope to
                                      be a leading tier executives in
                                      businesses. Additionally among the most
                                      expensive and sought after classes,
                                      management is famous for its exceptional
                                      ROI and significance in a globalised
                                      world. But management classes are no
                                      longer limited only to company management.
                                      They also include non-profit direction,
                                      advisory, academia and people management.
                                      <br />
                                      <br />
                                      For People Who want To contact their
                                      working life shortly, the 1 - year program
                                      offered by most European universities will
                                      be perfect. On the flip side, the majority
                                      of these US universities offer you the
                                      conventional 2- year old program. Based
                                      upon the ease and affordability of their
                                      pupils to steer clear of the work market,
                                      MBA has been broken into various classes
                                      for example -
                                      <br />
                                      <br />
                                      Full-time MBA: It's for the students that
                                      are eager to leave their occupation and
                                      devote their whole time into the week day
                                      courses and studying. Part-time MBA: This
                                      is for people who would like to boost
                                      their livelihood by acquiring managerial
                                      abilities while still being used. It's
                                      also called Day or Weekend MBA application
                                      since the courses are held throughout the
                                      weekends. International MBA: It aims at
                                      supplying global business experience to
                                      its students. The courses are conducted in
                                      various classroom environment,
                                      occasionally different nations and
                                      cultures also.
                                      <br />
                                      <br />
                                      Double MBA: It entails adding an
                                      additional Masters level to the MBA class
                                      Prerequisites to Study Business
                                      Administration
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Fundamental requirements Comprise:
                                    </p>
                                    <p>
                                      <ul style={{ listStyleType: "square" }}>
                                        <li>GMAT score</li>
                                        <li>TOEFL/IELTS/PTE</li>
                                        <li>
                                          Work experience (2-3 years; even
                                          greater for Executive MBA)
                                        </li>
                                        <li>
                                          Great communication skills are a plus
                                          stage
                                        </li>
                                        <li>
                                          Core Topics in Company Administration
                                        </li>
                                      </ul>
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      The Primary Purpose of Numerous topics
                                      include:
                                    </p>
                                    <ul style={{ listStyleType: "square" }}>
                                      <li>Accounting</li>
                                      <li>Introduction to Fund</li>
                                      <li>
                                        Operations and Information direction
                                      </li>
                                      <li>Human Resource management</li>
                                      <li>Introduction to advertising</li>
                                      <li>Micro Economics</li>
                                      <li>Organizational Behaviour</li>
                                      <li>Business Law</li>
                                      <li>
                                        Specialisations in Company
                                        Administration
                                      </li>
                                    </ul>

                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Price of Studying Business Administration
                                    </p>

                                    <p>
                                      Even Though the fees Vary from college to
                                      college, the typical tuition fees and
                                      yearly cost of living to get MBA in
                                      various nations are:
                                      <br />
                                      MBA at USA: $100,000; $1,000
                                      <br />
                                      CA$1,500
                                      <br />
                                      AU$2,000
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Job Prospects after Pros in Business
                                      Administration
                                    </p>

                                    <p>
                                      The Practice of Designing, starting and
                                      managing a new business is known as
                                      entrepreneurship. Entrepreneurship was
                                      described as the'ability and willingness
                                      to grow, arrange and manage a company
                                      enterprise alongside some of its dangers
                                      so as to create a profit'. A wider
                                      definition of this term is employed in
                                      economics.
                                    </p>
                                    <br />
                                    <hr />
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Entrepreneurship'?(
                                  <div id="Entre">
                                    <h4>Entrepreneurship</h4>
                                    <p>
                                      An Entrepreneur is A device that has the
                                      power to locate and act upon opportunities
                                      to directly interpret technology or
                                      inventions to new products. The
                                      entrepreneur can comprehend the commercial
                                      potential of this creation and arrange the
                                      capital, ability, and other sources which
                                      turn an invention into a commercially
                                      viable transformation or invention. It
                                      employs concepts in finance, management,
                                      market and math. The main focus of this is
                                      to create maximum benefit using as few
                                      sources as possible.
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Prerequisites to Study Entrepreneurship
                                    </p>
                                    <p>
                                      Business colleges Require one to provide
                                      the GMAT which creates the most
                                      significant part your program. Few
                                      universities also take GRE scores in set
                                      of GMAT scores. They also demand a minimum
                                      2-3 decades of job experience (based on
                                      the application you wish to combine ).
                                      IELTS or TOEFL is mandatory for obtaining
                                      student visa and as an evidence of English
                                      proficiency.
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Price of Studying Entrepreneurship
                                    </p>
                                    <p>
                                      Even Though the fees Vary from college to
                                      college, the average tuition price for
                                      entrepreneurs at Entrepreneurship in the
                                      usa is $30,000annually. Tuition prices are
                                      approximately C$28000 in Canada. Back in
                                      Australia, the average tuition prices are
                                      A$38000 annually and the average living
                                      costs are A$8,500annually.
                                    </p>
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Sport management'?(
                                  <div id="SM">
                                    <h4>Sport management</h4>

                                    <p>
                                      Sport management is A company field that
                                      handles recreation and sports. In the usa,
                                      jobs in game management comprise working
                                      for specialist applications such as the
                                      NFL, NBA, MLB, NHL, MLS, along with other
                                      specialist or non-professional game
                                      leagues concerning advertising, wellness,
                                      and promotions.
                                      <br />
                                      <br />
                                      Examples include College sports
                                      supervisors, recreational sport managers,
                                      event management, sport fund and data,
                                      leading workplace in professional sports,
                                      school sports supervisors, sports
                                      promotion, facility management and sports
                                      economics.
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Prerequisites to Study Sport Direction
                                    </p>
                                    <p>
                                      Business colleges Require one to provide
                                      the GMAT which creates the most
                                      significant part your program. Few
                                      universities also take GRE scores in set
                                      of GMAT scores. They also demand a minimum
                                      2-3 decades of job experience (based on
                                      the application you wish to combine ).
                                      IELTS or TOEFL is mandatory for obtaining
                                      student visa and as an evidence of English
                                      proficiency.
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Core Topics in Sport Management
                                    </p>
                                    <p>
                                      Sports Management Type of deals with
                                      fundamentals from sports and management.
                                      Various course topics include:
                                      <ul style={{ listStyleType: "square" }}>
                                        <li>Business communicating.</li>
                                        <li>Advertising.</li>
                                        <li>Sports and culture.</li>
                                        <li>Information technologies.</li>
                                        <li>Event administration.</li>
                                        <li>
                                          Specialisations at Sport Management
                                        </li>
                                      </ul>
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Price of Assessing Sport Management
                                    </p>

                                    <p>
                                      Even Though the fees Vary from college to
                                      college, the average tuition price for
                                      entrepreneurs in sports management from
                                      the united states are $37,000annually.
                                      Tuition prices are approximately C$32000
                                      in Canada. Back in Australia, the average
                                      tuition prices are A$40000 annually and
                                      the average living costs are
                                      A$8,500annually.
                                    </p>
                                    <br />
                                    <hr />
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Business Analytics'?(
                                  <div id="BA?">
                                    <h4>What's Business Analytics? </h4>

                                    <p>
                                      Business Analytics Is a combo of
                                      information Analytics and business
                                      processes. Company Analytics identifies
                                      the abilities, and techniques for constant
                                      iterative exploration and analysis of
                                      previous business performance to acquire
                                      insight and galvanize company preparation.
                                      Business analytics concentrates on
                                      creating new insights and comprehension of
                                      company performance based on information
                                      and statistical procedures.
                                      <br />
                                      <br />
                                      Business analytics Depends on adequate
                                      quantities of top quality information. The
                                      difficulty in procuring data quality is
                                      blending and reconciling information
                                      across various systems, then determining
                                      what subsets of information to produce
                                      accessible. Business analytics utilizes
                                      statistical analysis, modeling to assist
                                      decision making.
                                      <br />
                                      <br />
                                      Some universities Include MS in Business
                                      Analytics within their Engineering schools
                                      though others include it into their
                                      business schools. Bearing this in mind,
                                      you need to assess whether your college
                                      will ask that you submit a GRE or a GMAT
                                      score.
                                      <br />
                                      <br />
                                      In USA, it's Considered a STEM program.
                                    </p>
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='MIM'?(
                                  <div id="M?">
                                    <h4>What's Management (MIM)? </h4>

                                    <p>
                                      The Master in Management (MIM) is a
                                      postgraduate master's diploma awarded to
                                      students who generally finish an
                                      individual - to two-year schedule of
                                      graduate level training in business
                                      management in an accredited academic
                                      institution. Since the program is intended
                                      for students considering entering
                                      leadership functions, the level attracts
                                      applicants from diverse academic areas.
                                      <br />
                                      <br />
                                      The primary difference Involving MBA and
                                      MIM is that MIM assists pupils begin into
                                      a livelihood while MBA helps young
                                      professionals to boost their management
                                      career. Experts in Management is for all
                                      those people who are young and would like
                                      to find out more beyond their bachelor's
                                      level. MBA, on the other hand, is for
                                      people who have already begun their
                                      professions and need additional
                                      information to excel in it.
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Prerequisites to Study Management
                                    </p>
                                    <p>
                                      Business colleges Require one to provide
                                      the GMAT which creates the most
                                      significant part your program. Few
                                      universities also take GRE scores in set
                                      of GMAT scores. They also demand a minimum
                                      2-3 decades of job experience (based on
                                      the application you wish to combine ).
                                      IELTS or TOEFL is mandatory for obtaining
                                      student visa and as an evidence of English
                                      proficiency.
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Price of Studying Management (MIM)
                                    </p>

                                    <p>
                                      Even Though the fees Vary from college to
                                      college, the average tuition price for
                                      masters in direction in the united states
                                      are $37,000annually. Tuition prices are
                                      approximately C$32000 in Canada. Back in
                                      Australia, the average tuition prices are
                                      A$40000 annually and the average living
                                      costs are A$8,500annually.
                                    </p>
                                    <br />
                                    <hr />
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Accounting'?(
                                  <div id="Acc">
                                    <h4>Accounting</h4>

                                    <p>
                                      Accounting is the Dimension, processing,
                                      communicating and evaluation of all
                                      financial information of businesses like
                                      companies and corporations. It's been
                                      colloquially known as"Language of
                                      Business" since it measures the outcomes
                                      of a company's economic pursuits and
                                      communicates these compilations to many
                                      different users.
                                      <br />
                                      <br />A Master of Professional Accounting
                                      may also be obtained from Australian
                                      universities to be eligible for the
                                      Australian CPA, IPA or CA. This
                                      specialization program generally runs 1-2
                                      years in duration and comprises in 10-12
                                      three semester credit classes (30 to 36
                                      semester hours complete ). The program may
                                      include graduate accounting classes or a
                                      mix of graduate accounting courses,
                                      graduate management, taxation, leadership
                                      and other graduate business electives. The
                                      program is intended to not only prepare
                                      students for the CPA assessment but also
                                      to supply a solid understanding of
                                      bookkeeping principles and company
                                      applications.
                                    </p>

                                    <br />
                                    <hr />
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Health Care'?(
                                  <div id="Health">
                                    <h4>What's Health Care Management?</h4>

                                    <p>
                                      Healthcare Management is the area
                                      concerning direction, direction, and
                                      management of public health programs,
                                      hospitals and social networks. Healthcare
                                      management needs to make sure that
                                      department inside the team run smoothly,
                                      the targets set are attained, the tools
                                      are used economically and all are
                                      functioning productively. Approved
                                      programs Of research normally require
                                      pupils to finish implemented experiences
                                      in addition to coursework in areas like
                                      public health, health care economics,
                                      organizational behaviour, management of
                                      health care organizations, health care
                                      communications and marketing, healthcare,
                                      fiscal analysis and administration, human
                                      resource management, information systems
                                      management and evaluation, operations
                                      evaluation and improvement, governance,
                                      direction, statistical evaluation and
                                      program, and plan formulation and
                                      execution. The diploma plan is designed to
                                      provide scholars of health areas
                                      (specifically ) greater comprehension of
                                      management problems and also prepare them
                                      for senior management jobs, and is given
                                      by several European, American, European,
                                      Indian and Sri Lankan (Postgraduate
                                      Institute of Medicine - University of
                                      Colombo) universities.
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Prerequisites to Study Health Care
                                      Direction
                                    </p>
                                    <p>
                                      Business colleges Require one to provide
                                      the GMAT which creates the most
                                      significant part your program. Few
                                      universities also take GRE scores in set
                                      of GMAT scores. They also demand a minimum
                                      2-3 decades of job experience (based on
                                      the application you wish to combine ).
                                      IELTS or TOEFL is mandatory for obtaining
                                      student visa and as an evidence of English
                                      proficiency.
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Price of Studying Health Care Direction
                                    </p>

                                    <p>
                                      Even Though the fees Vary from college to
                                      college, the average tuition price for
                                      entrepreneurs at Health Care in the usa is
                                      $25,000annually. Tuition prices are
                                      approximately C$17000 in Canada. In
                                      Australia, typical tuition prices are
                                      A$33000 annually and the average living
                                      costs are A$8,500annually.
                                    </p>
                                    <br />
                                    <hr />
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Human Resources Management'?(
                                  <div id="HRM">
                                    <h4>Human Resources Management</h4>

                                    <p>
                                      Human Resources Management deals with
                                      powerful management of employees to attain
                                      the aims of the business. Human Resources
                                      Management must optimize employee
                                      efficiency and can be concerned with
                                      policies, systems, employee-benefits,
                                      employee-recruitment, training and
                                      advancement, and rewarding. Specialist
                                      classes in HR policy and training, HR
                                      analytics and Strategic HRM are
                                      complemented by courses in bookkeeping,
                                      international operations management and
                                      promotion. Opportunities for the
                                      development of a solid portfolio of
                                      specialist abilities lead into an expert
                                      HRM focussed industry-linked consulting
                                      endeavor.
                                      <br />
                                      <br />
                                      Having a Master of Human Resource
                                      Management graduates will be armed with
                                      the knowledge and skills needed for a
                                      career in areas related to human resource
                                      administration.
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Prerequisites to Study Human Resource
                                      Direction
                                    </p>
                                    <p>
                                      Business colleges Require one to provide
                                      the GMAT which creates the most
                                      significant part your program. Few
                                      universities also take GRE scores in set
                                      of GMAT scores. They also demand a minimum
                                      2-3 decades of job experience (based on
                                      the application you wish to combine ).
                                      IELTS or TOEFL is mandatory for obtaining
                                      student visa and as an evidence of English
                                      proficiency.
                                      <br />
                                      <br />
                                      Student visa and as an evidence of English
                                      proficiency.
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Price of Assessing Human Resource
                                      Direction
                                    </p>

                                    <p>
                                      Even Though the fees Vary from college to
                                      college, the average tuition price for
                                      entrepreneurs in Human Resources
                                      Management at the usa is $27,000annually.
                                      Average living costs vary around
                                      $8900/year. Tuition prices are
                                      approximately C$20000 in Canada. In
                                      Australia, typical tuition prices are
                                      A$33000 annually and the average living
                                      costs are A$8,500annually.
                                    </p>
                                    <br />
                                    <hr />
                                  </div>
                                   ):<></>}
                                  {/* --------------------------------------------------------------------------------- */}
                                  {showData=='Marketing'?(
                                  <div id="Market">
                                    <h4>Marketing</h4>

                                    <p>
                                      Marketing is the Analysis of
                                      customer-relationships. It's the most
                                      indispensable part of a business
                                      enterprise and helps encourage one's
                                      company and its reach in the society.
                                      Postgraduate Masters in Marketing class
                                      diversifies your abilities by analyzing
                                      the practical and theoretical frameworks
                                      that underpin excellent promotion.
                                      <br />
                                      <br />
                                      According to American Marketing
                                      Association, marketing is defined as"the
                                      action, set of associations, and
                                      procedures for producing, communicating,
                                      delivering, and monitoring offerings which
                                      have significance for customers,
                                      customers, partners, and society at
                                      large".
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Prerequisites to Study Marketing
                                    </p>
                                    <p>
                                      Business colleges Require one to provide
                                      the GMAT which creates the most
                                      significant part your program. Few
                                      universities also take GRE scores in set
                                      of GMAT scores. They also demand a minimum
                                      2-3 decades of job experience (based on
                                      the application you wish to combine ).
                                      IELTS or TOEFL is mandatory for obtaining
                                      student visa and as an evidence of English
                                      proficiency.
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Price of Studying Marketing
                                    </p>

                                    <p>
                                      Even Though the fees Vary from college to
                                      college, the average tuition price for
                                      masters in advertising in the usa are
                                      $27,000annually. Tuition prices are
                                      approximately C$27000 in Canada. Back in
                                      Australia, the average tuition prices are
                                      A$38000 annually and the average living
                                      costs are A$8,500annually.
                                    </p>
                                    <br />
                                    <hr />
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Architecture'?(
                                  <div id="Arch">
                                    <h4>Architecture</h4>

                                    <p>
                                      The Master of Is a professional degree in
                                      training, qualifying the graduate to
                                      proceed through the several phases of
                                      specialist certification (internship,
                                      examinations ) that lead to getting a
                                      permit. Architecture is the art of
                                      designing and building structures. The
                                      amount has been earned through several
                                      potential avenues of research, based on
                                      both a specific program's structure, and
                                      the candidate's previous academic
                                      experience and levels. M.Arch. Degrees
                                      vary in type, so that they are often given
                                      names like M.Arch. II' to differentiate
                                      them. All M.Arch. Levels are professional
                                      degrees in design. There are,
                                      nevertheless, other master's degrees
                                      offered by architecture colleges which
                                      aren't licensed at all.
                                      <br />
                                      <br />
                                      Its literal sense is somebody
                                      that'creates'. The program is closely
                                      linked but comparable to civil
                                      engineering, much as both the
                                      professionals work together in erecting
                                      structures. Prerequisites to Study
                                      Architecture.
                                      <br />
                                      <br />
                                      There aren't any Standard entrance
                                      examinations for masters in design. A few
                                      of the universities have their personal
                                      entrance examinations and mostly all them
                                      want your academic profile. But, TOEFL or
                                      IELTS is mandatory for obtaining visa and
                                      as evidence of proficiency in English.
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Price of Studying Architecture
                                    </p>

                                    <p>
                                      Even Though the fees Vary from college to
                                      college, the average tuition price for
                                      masters in structure in the usa is
                                      $24,000annually. Tuition prices are
                                      approximately C$20000 in Canada. Back in
                                      Australia, the average tuition prices are
                                      A$35000 annually and the average living
                                      costs are A$8,500annually.
                                    </p>
                                    <br />
                                    <hr />
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Journalism'?(
                                  <div id="Jour">
                                    <h4>Journalism</h4>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      What's Journalism (MA)?
                                    </p>
                                    <p>
                                      Journalism deals Together with the
                                      manufacturing and supply of reports on
                                      current occasions. A Masters in Journalism
                                      may be a definite advantage. "This really
                                      is a really competitive business to input
                                      and also the more'job-ready' you are the
                                      greater," states Angela Phillips, who runs
                                      the Masters in Journalism at Goldsmiths,
                                      University of London? She
                                      adds,"Publications in the united kingdom
                                      increasingly need a postgraduate
                                      qualification of any sort so they don't
                                      need to train new recruits" "New media
                                      demand professionals with advanced skills,
                                      creative thinking and a fresh
                                      entrepreneurial mindset. Individuals
                                      interested in getting into journalism
                                      should create new abilities (without
                                      failing traditional principles) so as to
                                      manage the radical changes in the
                                      business."
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Prerequisites to Study Journalism (MA)
                                    </p>
                                    <p>
                                      Though many Universities have waived the
                                      entry of GRE scores, and submitting them
                                      will provide you a much better chance.
                                      Aside from that, TOEFL or IELTS is
                                      mandatory for obtaining visa and as
                                      evidence of proficiency in English.
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Price of Studying Journalism (MA)
                                    </p>

                                    <p>
                                      Even Though the fees Vary from college to
                                      college, the average tuition price for
                                      masters in journalism in the united states
                                      is $28,000annually. Tuition prices are
                                      approximately C$22000 in Canada. Back in
                                      Australia, the average tuition prices are
                                      A$26000 annually and the average living
                                      costs are A$8,500annually.
                                    </p>
                                    <br />
                                    <hr />
                                  </div>
 ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Nursing'?(
                                  <div id="Nurs">
                                    <h4>Nursing</h4>

                                    <p>
                                      The Master of Nursing (Graduate entry)
                                      builds on your previous undergraduate
                                      instruction, preparing you for work in
                                      local, national and global health
                                      settings. You will obtain an extensive
                                      understanding of the way to work together
                                      with other caregivers to give the finest
                                      grade person-centred care.
                                      <br />
                                      <br />
                                      The healthcare Element - Nursing is a
                                      profession concerned with care for
                                      individuals, families, and communities
                                      with the objective of achieving,
                                      maintaining and regaining optimum health
                                      and quality of life. Nurses coordinate
                                      with doctors, physicians, patients to
                                      concentrate on treating illness and
                                      enhancing quality of life. Duties include
                                      to diagnose health issues, provide care,
                                      drugs and treatments to the patients.
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Prerequisites to Study Nursing
                                    </p>

                                    <p>
                                      There exist several Exams such as the
                                      NCLEX (a nurse licensing examination ) to
                                      be able to become a qualified Registered
                                      Nurse. Aside from that, TOEFL or IELTS is
                                      mandatory for obtaining visa and as
                                      evidence of proficiency in English.
                                    </p>

                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Price of Studying Nursing
                                    </p>

                                    <p>
                                      Even Though the fees Vary from college to
                                      college, the average tuition price for
                                      masters in nursing in america is
                                      $30,000annually. Tuition prices are
                                      approximately C$24000 in Canada. In
                                      Australia, typical tuition prices are
                                      A$35000 annually and the average living
                                      costs are A$8,500annually.
                                    </p>
                                  </div>
                                 
                                  ):<></>}
                                  {/* ------------------------------------------------------------------------------------- */}
                                  {showData=='Pharmacy'?(
                                  <div id="Pharm">
                                    <h4>Pharmacy</h4>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      What is Pharmacy?
                                    </p>
                                    <p>
                                      The Master of Sciences of Pharmacy
                                      (MPharm) is your conventional master's
                                      level program . It's the earliest jelqing
                                      Diploma (Amount ) licensed in the European
                                      Association of Pharmacy because it
                                      requires five to seven years to finish.
                                      <br />
                                      <br />
                                      Pharmacy is your" science And method of
                                      preparing and administering drugs".
                                      Pharmacy for a master's path possibly done
                                      by those who have some degree
                                      qualification in health sciences, biology
                                      and related subjects. The makeup of the
                                      numerous classes under pharmacy are linked
                                      to manufacturing, monitoring, regulating,
                                      exploring and analyzing drugs and
                                      pharmaceutical goods such as food items.
                                    </p>
                                    <br />
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Prerequisites to Study Pharmacy
                                    </p>
                                    <p>
                                      There exist several Exams such as the
                                      NAPLEX and MPJE you have to qualify for
                                      pursuing specialists in pharmacy. Aside
                                      from that, TOEFL or IELTS is mandatory for
                                      obtaining visa and as evidence of
                                      proficiency in English.
                                    </p>
                                    <br />

                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20",
                                      }}
                                    >
                                      Price of Studying Pharmacy
                                    </p>

                                    <p>
                                      Even Though the fees Vary from college to
                                      college, the average tuition price for
                                      masters in pharmacy in the united states
                                      are $27,000annually. Average living costs
                                      vary upto $8900/year. Tuition prices are
                                      approximately C$25000 in Canada. In
                                      Australia, typical tuition prices are
                                      A$33000 annually and the average living
                                      costs are A$8,500annually.
                                    </p>
                                    <br />
                                    <hr />
                                  </div>
                                   ):<></>}

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Container>
  );
}
