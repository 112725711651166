/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet';

import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';



const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
newbtn:{
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
},

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:12,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    // margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },

widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const [firstName,setFirstName]=React.useState('')
  const [lastName,setLastName]=React.useState('')
  const [emailId, setEmailId]=React.useState('')
  const [password,setPassword]=React.useState('')
  const [verifyPassword,setVerifyPassword]=React.useState('')
  const [messages, setMessages ]=React.useState('')
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus,setStatus]=React.useState('') 

  const handlePicture=(event)=>{
    setPicturePath(URL.createObjectURL(event.target.files[0]))
    setPicture(event.target.files[0])
   }

   const handleVerifyPassword=(e)=>{
    setVerifyPassword(e.target.value)
    if(password===e.target.value){
      setStatus("Password Matched")
    }
    else{
      setStatus("Password not matched")
    }
  }


//   const addNewRecord=async()=>{
// if(picture=='')
// {
//     var body={'firstName':firstName,
//     'lastName':lastName, 
//     'emailId':emailId, 
//     'password':password,
//     'status':"Not Approved",
//     'picture':"Not Found"}
//     var result=await postData('selfregister/addNewRecord',body)
//     if(result){
//       await setMessages("Agent Succefully Created")

//       var body={
//         'emailid':emailId,
//         'password':password
//        }
// var resultt = await  postData('selfregister/checkagentsignin',body)

// if (resultt.RESULT)
// {

// localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
// props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
// }
// else
// {
// alert("Failed To Created Account")
// }

// await setFirstName('')
// await setLastName('')
// await setEmailId('')
// await setPassword('')
// await setVerifyPassword('')
// await setPicturePath('')
// }
// else{setMessages("Faild to Submit")}

// }

// else{
//    var formData=new FormData()
//    formData.append('firstName',firstName)
//    formData.append('lastName',lastName)
//    formData.append('emailId',emailId)
//    formData.append('password',password)
//    formData.append('picture',picture)
//    formData.append('status', "Not Approve")
  
//    var config={headers:{'content-type':'multipart/form-data'}}  
//     var result=await postDataAndImage('selfregister/addNewRecordPic',formData,config)


//     if(result){
//       await setMessages("Agent Succefully Created")

//       var body={
//         'emailid':emailId,
//         'password':password
//        }
// var resultt = await  postData('selfregister/checkagentsignin',body)

// if (resultt.RESULT)
// {

// localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
// props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
// }
// else
// {
// alert("Failed To Created Account")
// }

// await setFirstName('')
// await setLastName('')
// await setEmailId('')
// await setPassword('')
// await setVerifyPassword('')
// await setPicturePath('')
// }
// else{setMessages("Faild to Submit")}
//     }
//     }
   
   
    // console.log("History ==  ",props)
  return (
   <Container className={classes.widths}>
   <Helmet>
    <html lang="en" />
    <title>About US - Study Metro</title>
    <meta name="description" content="We are leading overseas education consultant. Want to study abroad? Find employment in the USA! Or, study and work in Canada!" />
    <meta name="theme-color" content="#000000" />
</Helmet>
  <Header/> <CssBaseline />
    <div className="login-area default-padding" style={{paddingTop:0,paddingBottom:0,}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">
<div className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard" style={{backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",padding:70}}>             
{/* <div className="banner-area inc-form bg-gradient slider-less text-light" style={{backgroundImage: "url(" + "../assets/img/event/1.jpg" + ")", padding:70}}> */}
        <div className="container">
            <div className="row">
                {/* <div className="double-items">
                    <div className="col-md-7 info">
                        <h2 className="wow fadeInLeft">Find your ideal study program.</h2>
                        <a className="btn day btn-light border btn-md wow fadeInDown" style={{color:'#3f8ccb'}} href="#">View Courses</a>
                    </div>
                    <div className="col-md-5 reg-form">
                        <form action="#">
                            <div className="row">
<img style={{flex:1}} class="login_image" src="assets/img/elearning.png" alt="Login" data-original-title="" title="" />

                            </div>
                        </form>
                    </div>
                </div> */}
                <div className="double-items col-md-12 info">
                <h2 className="wow fadeInLeft">About Us</h2>
                </div>
             </div>
        </div>
    </div>

    <div className="about-area default-padding" style={{paddingBottom:0,}}>
        <div className="container">
            <div className="row">
                <div className="about-items">
                    <div className="col-md-6 about-info">
                        <h2>About US ! <span>Study Metro</span></h2>
                        <blockquote style={{fontSize:15, lineHeight:'1', }}>
                        As overseas education consultants, we help students achieve their goals.
                        </blockquote>
                        <p style={{fontSize:14,color:'#212121',textAlign:'justify'}}>
Whether you want to earn your MS in the US, study and work in Canada, or find employment in the USA, we can match you with the right program.
Study Metro is a professional education consultancy firm, extending excellent support and service to students all over India and gives unlimited study opportunities across the Globe. Our aim is to improve educational standards. Scores of parents have sought our advice to find suitable Universities for their children.

                        </p>
                       
                    </div>
                    <div className="col-md-6 features text-light">
                       <img style={{flex:1}} class="login_image" src="assets/img/learn.png" alt="Login" data-original-title="" title="" />

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="blog-area single full-blog left-sidebar full-blog default-padding" style={{paddingBottom:0,}}>
        <div className="container" style={{paddingLeft:0,paddingRight:0,}}>
     
            <div className="row">
                <div className="blog-items">
                    <div className="blog-content col-md-12">

                        <div className="content-items">
                            <div className="single-item">
                                <div className="item">
                                    <div className="info">
                                        <div className="content" style={{paddingBottom:0,}}>
<h4>We're the Education Provider organization who put you first!!!</h4>
                                        </div>



<div className="content" style={{paddingBottom:0,}}>
<h4><li>Best Education your way: </li></h4>

<div style={{marginLeft:35,textAlign:'justify'}}>
<p style={{color:'#212121',}}>
At the point when we started in 2011, we set out to do things another way. We needed to show all universities/school choices in a single spot, creating a user-friendly option as an alternative to the befuddling sites that cause Study Abroad to feel like a difficult task. 
</p>
<p style={{color:'#212121',}}>Fast forward to today and we've gone from an education search engine to a worldwide pioneer in Education Industry. We're glad that more than 1 million individuals over the world (that's quite a few!) rely on our app and site every month to assist them with their study abroad plans. </p>
<p style={{color:'#212121',}}>Committed to making study abroad as simple as possible, we help each and everybody of you locate the most ideal choices across the globe, Top universities, according to your profile, to shape your ideal career. </p>
</div>

</div>

<div className="content" style={{paddingBottom:0,}}>
<h4><li>Be that as it may, we're not halting here... </li></h4>

<div style={{marginLeft:35,textAlign:'justify'}}>
<p style={{color:'#212121',}}>It has been the trend since ages that those with big pockets can only go and Study Abroad. Study Metro with their mission to ‘’Make Study Abroad Acces-sible and Affordable to All’’</p>
<p style={{color:'#212121',}}>As Saint Augustine says “The world is a book, and those who do not travel read only a page” </p>
<p style={{color:'#212121',}}>We encourage students to explore diversity. The Universities around the globe offers Astronomical Scholarships to International Students and we mentor students how they can successfully avail the scholarships from the renowned universities from all over the world. </p>
<p style={{color:'#212121',}}>We realized you're searching for the best affordable education cost and the maximum adapta-bility to choose what's right for you. Which is why we're in every case working diligently ensuring our application and site are too direct and speedy without causing any hassle to any-one. </p>
<p style={{color:'#212121',}}>Pick where you need to study, when you need to begin programs and get the very best educa-tion cost from a great many universities without looking anywhere else. In addition, apply to multiple universities with a single application. All the manners in which we can assist you with finding a correct institution that is custom fitted to what exactly you're searching for, regardless of your current education or your budget plans.</p>
<p style={{color:'#212121',}}>Feeling flexible and adaptable? Search 'everywhere' to see where you can study for a great ed-ucation cost. Got a destination in mind? Utilize our 'University Finder' to discover the best match institution according to your profile. </p>
<p style={{color:'#212121',}}>What's more, when you know when and where you're going for higher education, apply to your 'Dream University' in just a few quick steps, utilizing our app or website.</p>
</div>

</div>


<div className="content" style={{paddingBottom:0,}}>
<h4><li>Our journey so far</li></h4>

<div style={{marginLeft:35,textAlign:'justify'}}>
<p style={{color:'#212121',}}>2011 - The Education industry changes perpetually after two brothers get together and begin to consider creating easy ways to study abroad. Thus, Study Metro has been born!</p>
<p style={{color:'#212121',}}>2014 - Our popular ‘Study Everywhere’ feature launched. It’s been created to show students the best universities they can study abroad.</p>
<p style={{color:'#212121',}}>2015- We go global, with our 23 office in India and 6 office Overseas.</p>
<p style={{color:'#212121',}}>2016- We get AIRC certified and members of NAFSA, EAIE, ICEF, QISAN, AIEA, APAIE, QISAN, CARE, EATC, RICE, IUNC and WEBA</p>
<p style={{color:'#212121',}}>2017 - Our New Study Portal and App been launched to find the universities and programs on the go even easier. </p>
<p style={{color:'#212121',}}>2018- To help students and parents, we started ISEF tour to create a meeting bridge between international universities delegates and the students & parents one-to-one to solve their queries and making study abroad more easier. </p>
<p style={{color:'#212121',}}>2019- We went beyond and launched the OIEF platform for online webinars with the best universities speakers at the finger tip of the students. </p>
<p style={{color:'#212121',}}>2020 - We hit one million monthly users and announce our mission to lead the global transformation to modern and sustainable Study Abroad.</p>


</div>

</div>

<div className="content" style={{paddingBottom:0,}}>
<h4>It’s your world.</h4>
<h4>We’ll help you explore it.</h4>
</div>


<div className="content" style={{paddingBottom:0,}}>
<h4><li>University:  </li></h4>

<div style={{marginLeft:35,textAlign:'justify'}}>
<p style={{color:'#212121',}}>From quick breaks to epic adventures, locate the best universities to study abroad across a large number of colleges directly here. No hidden charges. </p>
<p style={{color:'#212121',}}>Smart search filters, such as, Ranking, Intake, Program choice, Scholarships and more, assist you with finding your ideal university. Additionally, we have lots of tips and tricks to assist you save more.</p>

</div>

</div>


<div className="content" style={{paddingBottom:0,}}>
<h4><li>Programs:  </li></h4>

<div style={{marginLeft:35,textAlign:'justify'}}>
<p style={{color:'#212121',}}>Getting there is only part of the journey. We're here to help round-off your entire study abroad with millions of programs from all around the world, from low-cost to extravagance. </p>
<p style={{color:'#212121',}}>Compare all the most demanded programs right here. Furthermore, with our in-credible education cost rate with the world's leading universities and colleges, you'll save significantly more.</p>
</div>

</div>


<div className="content" style={{paddingBottom:0,}}>
<h4><li>Internships: </li></h4>

<div style={{marginLeft:35,textAlign:'justify'}}>
<p style={{color:'#212121',}}>We likewise help you to find the internships while you are studying in university or after your degree completion there.</p>

</div>

</div>




                                       

 <div className="content" style={{paddingBottom:0,}}>
                                         

  <div className="col-md-12">
   <div className="content" style={{paddingBottom:0,}} >
                                
                          </div>
                                </div>
                          
                                        </div>




                                    </div>
                                </div>
                            </div>

                            </div>

                      
                    </div>
                  
  
                </div>
            </div>
        </div>
    </div>
  

</div>
</div>
</div>
</div>
<div className="about-area default-padding">
        <div className="container" style={{paddingLeft:0,paddingRight:0,}}>
            <div className="row" style={{display:'block'}}>
                <div className="about-items">
                    <div className="col-md-12">
                    
                    <div className="weekly-top-items">
                    <div className="top-courses" style={{paddingRight:0}}>
                        <div className="heading" style={{paddingBottom:15,}}>
                            <h4 style={{color:'#3f8ccb'}}>ACCREDITATIONS AND MEMBERSHIPS <strong></strong></h4>
                        </div>
                    </div>
                    </div>
<div class="col-md-2"><img src="assets/img/footer/EAIE.png" class="logo" alt="Logo" /></div>
<div class="col-md-2"><img src="assets/img/footer/ICEF.png" class="logo" alt="Logo" /></div>
<div class="col-md-2"><img src="assets/img/footer/NAFSA.png" class="logo" alt="Logo" /></div>
<div class="col-md-2"><img src="assets/img/footer/QISAN.jpeg" class="logo" alt="Logo" /></div>
<div class="col-md-2"><img src="assets/img/footer/AIRC.gif" class="logo" alt="Logo" /></div>
<div class="col-md-2"><img src="assets/img/footer/AIEA.png" class="logo" alt="Logo" /></div>


                       
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
    </Container>
  );
}
