import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";
import { CenterFocusStrong } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    // margin: 'center',
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [messages, setMessages] = React.useState("");
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus, setStatus] = React.useState("");
  const [showData,setShowData]=React.useState('USA')

  const handlePicture = (event) => {
    setPicturePath(URL.createObjectURL(event.target.files[0]));
    setPicture(event.target.files[0]);
  };

  const handleVerifyPassword = (e) => {
    setVerifyPassword(e.target.value);
    if (password === e.target.value) {
      setStatus("Password Matched");
    } else {
      setStatus("Password not matched");
    }
  };

  //   const addNewRecord=async()=>{
  // if(picture=='')
  // {
  //     var body={'firstName':firstName,
  //     'lastName':lastName,
  //     'emailId':emailId,
  //     'password':password,
  //     'status':"Not Approved",
  //     'picture':"Not Found"}
  //     var result=await postData('selfregister/addNewRecord',body)
  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}

  // }

  // else{
  //    var formData=new FormData()
  //    formData.append('firstName',firstName)
  //    formData.append('lastName',lastName)
  //    formData.append('emailId',emailId)
  //    formData.append('password',password)
  //    formData.append('picture',picture)
  //    formData.append('status', "Not Approve")

  //    var config={headers:{'content-type':'multipart/form-data'}}
  //     var result=await postDataAndImage('selfregister/addNewRecordPic',formData,config)

  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}
  //     }
  //     }

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <br/>

      <div className="blog-area single full-blog left-sidebar full-blog default-padding">
        <div className="container">
          <div className="row">
            <div className="blog-items">
              <div
                className="sidebar col-md-2"
                style={{
                  paddingLeft: 0,
                  display: "block",
                  overflowY: "auto",
                  position: "sticky",
                  top:0
                }}
              >
                <aside>
                  <div
                    className="sidebar-item category"
                    style={{
                      height: 310,
                    }}
                  >
                    <div className="title">
                      <h4 style={{ fontSize: 25 }}>Country</h4>
                    </div>
                    <div className="country">
                      <ul
                        style={{
                          listStyleType: "square",
                          color: "black",
                        }}
                      >
                        <li>
                          <a onClick={()=>setShowData('USA')} style={{ color: "black" }}>
                          <i class="flag-icon flag-icon-us"></i>&nbsp;&nbsp;USA
                          </a>
                        </li>
                        <li>
                          <a onClick={()=>setShowData('CANADA')} style={{ color: "black" }}>
                          <i class="flag-icon flag-icon-ca"></i>&nbsp;&nbsp;Canada
                          </a>
                        </li>
                        <li>
                          <a onClick={()=>setShowData('UK')} style={{ color: "black" }}>
                          <i class="flag-icon flag-icon-gb"></i>&nbsp;&nbsp;UK
                          </a>
                        </li>
                        <li>
                          <a onClick={()=>setShowData('AUSTRALIA')} style={{ color: "black" }}>
                          <i class="flag-icon flag-icon-au"></i>&nbsp;&nbsp;Australia
                          </a>
                        </li>
                        <li>
                          <a onClick={()=>setShowData('FRANCE')} style={{ color: "black" }}>
                          <i class="flag-icon flag-icon-fr"></i>&nbsp;&nbsp;France
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </aside>
              </div>

              <div className="blog-content col-md-10">
                <div className="content-items">
                  <div className="single-item">
                    <div className="item">
                      <div className="info">
                        <div className="content" style={{ paddingBottom: 0 }}>
                          <div
                            style={{
                              marginLeft: 5,
                              textAlign: "justify",
                            }}
                          >
                            
                            {/* ------------------------------------------------------------------------------------- */}
                            {showData=='USA'?(
                            <div id="USA">
                              <h4>USA</h4>
                              <p>
                                In case you've just begun preparing to Study in
                                USA, then there'll be several questions on mind.
                                The very first thing that you need to think
                                about when applying to a college or College is
                                your intakes that the nation has to offer you.
                                What's the principal intake of all of the USA
                                intakes? To answer all the questions, here are
                                the kinds for USA masters ingestion:
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Intakes at USA
                                </p>
                                You will find just three USA intakes offered
                                from the schools and universities. The 3 intakes
                                available in the United States are:
                                <br />
                                <br />
                                It may find somewhat confusing to decide on the
                                ingestion which suits you . Consider factors
                                such as the availability of your required
                                schedule, your academic documents, English
                                language proficiency scores, and entrance exam
                                scores, and the institution's approval prices,
                                job opportunities and your willingness to join
                                the application whilst making a determination.
                                If you aren't able to receive prepared until
                                your preferred ingestion then it is far better
                                to bypass the rush and use for the next intake
                                for USA.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Fall Intake can also be called the September
                                  intake.
                                </p>
                                Like the majority of the nations fall ingestion
                                is the principal ingestion in USA. Each of the
                                Faculties provide a vast array of classes in
                                this intake. Many Universities and schools only
                                have autumn intake for the entry procedure.
                                <br />
                                <br />
                                The majority of the pupils opt for this
                                ingestion for above-mentioned explanations. If
                                you're planning for entrance in the fall
                                semester, then begin your faculty and University
                                shortlisting and study from April. Look for
                                IELTS / TOEFL or alternative mandatory
                                assessments around June. You then should begin
                                documentation like SOP, LOR, Application Essays,
                                and most significant, the visa procedure.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Spring ingestion in USA can also be Called
                                  January Intake.
                                </p>
                                Spring Intake is much more of a secondary
                                ingestion for USA. The spring ingestion has a
                                length of January to May. Pupils who have missed
                                the autumn intake are able to apply with this
                                one. But, spring ingestion doesn't have as many
                                classes on offer as drop intakes in the
                                Faculties.
                                <br />
                                <br />
                                If you're planning to submit an application to
                                your Spring Intake at USA, then you ought to be
                                carried out with your shortlisting of
                                universities and also all the essential research
                                by August. Give mandatory English Language and
                                other aggressive eligibility examinations by
                                September / October. Following that, pay
                                attention to your program procedure and visa
                                requirement to death.
                                <br />
                                <br />
                                Shortlist that the US Universities which are
                                January intake Colleges, and also have classes
                                that you need to apply for. It is possible to
                                find entry requirements, application procedure
                                and deadlines on the University sites.
                                <br />
                                <br />
                                You also need to look ahead to scholarships and
                                education loans that you wish to use to, or
                                qualified for. One other important aspect to be
                                considered is the lodging in USA. Pick either
                                you'll be staying or off-campus and organize
                                accordingly.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Measure 2: Look for the Accreditation: June to
                                  November{" "}
                                </p>
                                At the University site, start looking for the
                                necessary entry or the English language
                                evaluations you're supposed to take.
                                <br />
                                <br />
                                Registration interval for those tests also
                                change. You'll need to reserve for GMAT and GRE
                                much earlier than the window you'll need to
                                register for IELTS and TOEFL.
                                <br />
                                <br />
                                Have a while, in the event you will need to
                                retake the situation following an undesirable
                                outcome.
                                <br />
                                <br />
                                Restrict your choices and begin preparing your
                                own documents. Universities in USA normally
                                request an application composition, or SOP. Pick
                                what makes you a exceptional applicant and
                                compose a well-crafted announcement of purpose.
                                The objective is to inform the University why
                                you match for the app, and also how the college
                                can help you proceed.
                                <br />
                                <br />
                                Both the essays and documents ought to be
                                extended a month's period to be ready.
                                <br />
                                <br />
                                After everything appears in the area, submit the
                                final program before the expected date.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Measure 4: Acceptance Letters and Interviews:
                                  October into November{" "}
                                </p>
                                as soon as you receive a response from the
                                faculty or university you've applied to, then
                                revert them once possible. If necessary, you'll
                                be requested for a movie or private interview
                                from the faculty.
                                <br />
                                <br />
                                As soon as you have the approval letter, make a
                                determination and notify the faculty relating to
                                this. This procedure may also have a deadline,
                                also. So be sure that you are keeping an eye on
                                the moment.
                                <br />
                                <br />
                                You'll be requested to cover a non refundable
                                deposit to verify the admission.
                                <br />
                                <br />
                                USA student visa is going to have some
                                prerequisites required to be fulfilled with you.
                                <br />
                                <br />
                                Apply for your own pupils loan to demonstrate
                                the financial proof to bail officials. All of
                                the paperwork needs to be performed in this time
                                period, both for your own visa and pupils loan.
                                <br />
                                <br />
                                USA visa takes a while to process your program.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Measure 6: Tickets and Death: December into
                                  January{" "}
                                </p>
                                you're only 1 step away from coming in USA!
                                Reserve the flight to get the same.
                                <br />
                                <br />
                                You'll require an global debit or charge card
                                until you depart, so be certain to have ordered
                                one.
                                <br />
                                <br />
                                Before flying, collect all the needed files and
                                photocopies. Create a pre-departure listing and
                                follow it accordingly there isn't any last
                                minute hassle.
                              </p>
                            </div>
                              ):<></>}
                            
                            {showData=='CANADA'?(
                            <div id="Canada">
                              <h4>Canada</h4>
                              <p>
                                There are 3 Distinct intakes in Canada to Get
                                International Students. In certain universities
                                of Canada, intakes might also be termed as a
                                session. Canada ingestion for Global students
                                are clarified below:
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Fall ingestion for Canada begins in September
                                  and it's the main intake.
                                </p>
                                Intake : That would indicate that nearly all of
                                the universities provide each of their classes
                                in Fall/September Intake since it's the
                                principal intake and Winter/January Intake is
                                for the students who missed their opportunity in
                                Fall. Many universities offer you a high number
                                of classes from January/Winter Intake in Canada.
                                Summer Intake for Canada would begin in May, as
                                well as the classes offered You will find few
                                and far. MBA Intakes in Canada is exactly the
                                same as previously, and so will be the intakes
                                in Canada for Experts.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Important Intakes in Canada Begins in Details
                                  regarding Study Intakes at Canada
                                </p>
                                1. Fall Intake September Main Intake, Colleges
                                offers each of the classes Available for pupils
                                who missed the autumn ingestion
                                <br />
                                <br />
                                2. Spring Intake May Just Few classes Accessible
                                Not all universities provide
                                <br />
                                <br />
                                3. Fall Intake can be referred to as September
                                Intake.
                                <br />
                                <br />
                                This ingestion Contains the Most institutes
                                offering the biggest Number of seats offered in
                                all of the classes, when compared with other two
                                Intakes.
                                <br />
                                <br />
                                It's the principal intake for those pupils who
                                wish to research in Canada. Most Pupils prefer
                                drop intake to begin their research in Canada,
                                since it will provide them sufficient time to
                                become more conditioned to the research and the
                                surroundings. This will place these pupils, 4 to
                                5 weeks ahead of their pupils joining in winter
                                ingestion. Aside from that, the weather at the
                                autumn is much more pleasant in comparison to
                                winter.
                                <br />
                                <br />
                                Examine the deadline of this Universities that
                                you need to examine in and employ at least a
                                month prior to your deadline. Do not delay in
                                responding to college mails.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Winter ingestion in Canada can also be called
                                  January Intake.
                                </p>
                                This ingestion can be regarded as the
                                continuation of the autumn ingestion. A Fewer
                                number of institutes offer classes in winter
                                intake in contrast to the autumn ingestion.
                                <br />
                                <br />
                                Mostly, the pupils who've missed the entrance in
                                autumn ingestion would Elect for chilly
                                ingestion. They have another opportunity to
                                continue their research without needing a
                                complete calendar year. The air in winter
                                becomes really cold and it isn't pleasant to
                                begin in these problems. Furthermore, students
                                will need to adapt to the surroundings in the
                                institute fast, when compared with the pupils
                                from the autumn ingestion.
                                <br />
                                <br />
                                Get completely free counselling.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Spring ingestion in Canada or summertime
                                  ingestion in Canada is also Called May Intake.
                                </p>
                                Here is the least favorite intake of All of the
                                intakes in Canada which are available. Very few
                                schools provide a class during the spring
                                semester and also those which provide are quite
                                few.
                                <br />
                                <br />
                                The Majority of the students choose to Return to
                                their houses or Have a spring Break off during
                                summertime. It's not a compulsory term. Students
                                may still take the classes if they need to, but
                                as mentioned before, there aren't many
                                alternatives to select from.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  When if I Employ spring ingestion in Canada?
                                </p>
                                Most spring Intake schools in Apply before the
                                expected date.
                                <br />
                                <br />
                                Are generally drop in February. On the other
                                hand, the dates can vary from institute to
                                institute. They're Much different from
                                University Intakes at Canada. Here's the list of
                                these and deadlines to apply to Intakes in
                                Canada:
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  6 Approaches to Study at January Intake at
                                  Canada
                                </p>
                                If You Would like to research in Canada and
                                intending to go for January ingestion in Canada,
                                this is your analysis in Canada Deadline for
                                January. Info about classes in Canada for
                                January intake, When to use for January
                                ingestion in Canada, also about final dates to
                                use for January ingestion in Canada.
                                <br />
                                <br />
                                Most schools have a first come basis entry
                                policy for January Ingestion in Canada, and
                                you'd wish to begin your application process as
                                soon as you can. Bear in mind that the deadlines
                                universities in Canada for January ingestion and
                                commence so.
                                <br />
                                <br />
                                Before that, shortlist the schools and
                                universities That You're interested in. Do
                                thorough research among the listing of schools
                                in Canada for January ingestion and see exactly
                                what would be the classes in Canada for January
                                ingestion, see those which suit you the very
                                best for your own academic and career
                                objectives.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Measure 2: Look for the Tests: July to August
                                </p>
                                Give proper examination in accordance with your
                                faculty requirements. Required evaluations You
                                need to be carrying your IELTS/TOEFL
                                examination, depending upon the course and
                                University needs, latest by August 2018, prior
                                to applying for its institutes.
                                <br />
                                <br />
                                Remember It Will take two weeks to the results
                                of this Evaluation to be announced, along with a
                                buffer month ought to be maintained, also, if
                                you happen to would like to retake the exam due
                                to any unforeseen or undesirable outcome.
                                <br />
                                <br />
                                Start Looking for winter intake schools At
                                Canada, shortlist the schools and Universities
                                prior to the expected dates to the various
                                institutes. Prepare your final program. The
                                program has to depict you as a exceptional
                                candidate also it ought to be emphasizing your
                                strengths.
                                <br />
                                <br />
                                Get in touch with your professors and Managers
                                to the reference letters. Start All of this
                                documentation procedures should start at the
                                very least a month prior to the expected date of
                                this program.
                                <br />
                                <br />
                                Some may go as late as mid-November, but you
                                need to always keep assessing the Faculties
                                deadlines for Jan from college site Apply prior
                                to the expected date. Faculties will email you
                                once you've applied for the classes. Don't Delay
                                in responding to them. Look for video or
                                personal interviews if necessary.
                                <br />
                                <br />
                                If you're accepted to a college/university,
                                create a fast choice. Answer to them the moment
                                you decide. A number of them follow come first
                                serve policy. Don't await the deadlines of
                                January intake schools in Canada to answer.
                                <br />
                                <br />
                                You Will Need to pay a non refundable
                                verification deposit, after you Affirm your
                                entrance with a college/university. (Read : Cost
                                of Research in Canada)
                                <br />
                                <br />
                                Whenever you get the approval letter, use for a
                                student's loan With no delay. Some procedures
                                take time, and a candidate must always look at
                                that before planning to your deadline.
                                <br />
                                <br />
                                Document all of the paperwork needed for the
                                student visa requirement. Apply to your student
                                visa for Canada punctually. The processing to
                                get a visa may also take a while.
                                <br />
                                <br />
                                When appropriate, use for an outside scholarship
                                Also, after you Receive the approval letter in
                                the college/university. For Longer Read: Student
                                Visa at Canada.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Measure 6: Telephones and Death: November into
                                  December
                                </p>
                                Reserve airline tickets. Most sessions to get
                                the Classes will begin in early January, so it's
                                highly advisable to reach Canada from December
                                and search for lodging on or outside the campus.
                                <br />
                                <br />
                                Before flying, collect all the needed files and
                                their photocopies.
                                <br />
                                <br />
                                Organize an Global Credit / Debit card before
                                death, and also make Sure you've marked every
                                checkbox on your pre-departure checklist. Get
                                prepared to research in Canada intake.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  List of schools in Canada for January
                                  ingestion
                                </p>
                                Here We've said January intake universities in
                                Canada to get International students: Fanshawe
                                College Cape Breton University Douglas College
                                Lambton College Carleton University Centennial
                                College
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  6 Approaches to Study in May ingestion in
                                  Canada
                                </p>
                                If You Would like to research in Canada and
                                intending to go for May ingestion in Canada,
                                here is your analysis in Canada deadline for
                                May. Information about classes in Canada for May
                                ingestion, When to use for May ingestion in
                                Canada, and about final dates to use for May
                                ingestion in Canada.
                                <br />
                                <br />
                                Most schools have a first come basis entrance
                                policy for the Admission, and you'd want to
                                begin your application process as soon as you
                                can. Bear in mind that the deadlines for the May
                                ingestion for Canada and begin so.
                                <br />
                                <br />
                                Before that, visit the schools and universities
                                in Canada which you Are curious about. Do
                                thorough research among the listing of schools
                                in Canada for May ingestion and after that, see
                                what would be the classes at Canada for May
                                ingestion, see those which suit you the very
                                best for your own academic and career
                                objectives.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Measure 2: Look for the Accreditation - August
                                  to September
                                </p>
                                Weeks prior to the evaluation. Give ideal
                                examinations in accordance with your college
                                requirements. You need to be carrying your
                                IELTS/TOEFL examination, depending upon the
                                course and University needs, latest by October
                                2019, prior to applying for its institutes.
                                <br />
                                <br />
                                Remember It Will take about two weeks to the
                                results of this Evaluation to be announced,
                                along with a buffer month ought to be
                                maintained, also, if you happen to would like to
                                retake the exam due to any unforeseen or
                                undesirable outcome.
                                <br />
                                <br />
                                Start Looking for May Intake schools in Canada,
                                shortlist the schools and Colleges Prior to the
                                expected dates to the various institutes.
                                Prepare your final program. The program has to
                                portray you a exceptional candidate also it
                                ought to be emphasizing your strengths.
                                <br />
                                <br />
                                Get in touch with your professors and Managers
                                to the reference letters. Start All of this
                                documentation procedures should start at the
                                very least a month prior to the expected date of
                                this program.
                                <br />
                                <br />
                                February, but you always need to be assessing
                                university site to get updated May Intake at
                                Canada deadlines. Apply before the expected
                                date. Faculties will email you once you've
                                applied for the classes. Don't Delay in
                                responding to them. Look for video or personal
                                interviews if necessary.
                                <br />
                                <br />
                                If you're accepted to a college/university,
                                create a fast choice. Answer to them the moment
                                you decide. A number of them follow come first
                                serve policy. Don't await the deadlines to
                                answer.
                                <br />
                                <br />
                                You Will Need to pay a non refundable
                                verification deposit, after you Verify your
                                entrance with a college/university.
                                <br />
                                <br />
                                Whenever you get the approval letter, use for a
                                student's loan With no delay. Some procedures
                                take time, and the offender must always look at
                                that before planning to your deadline.
                                <br />
                                <br />
                                Document all of the paperwork needed for the
                                student visa requirement. The processing to get
                                a visa may also take a while.
                                <br />
                                <br />
                                When appropriate, use for an outside scholarship
                                Also, after you Receive the approval letter in
                                the college/university.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Measure 6: Telephones and Departure - March
                                  into April
                                </p>
                                Reserve airline tickets. Most sessions to get
                                the Classes will begin in early May, so it's
                                highly advisable to reach Canada from April and
                                search for lodging on or outside the campus.
                                <br />
                                <br />
                                Before flying, collect all the needed files and
                                their photocopies.
                                <br />
                                <br />
                                Organize an Global Credit / Debit card before
                                death, and also make Sure you've marked every
                                checkbox on your pre-departure checklist.
                                <br />
                                <br />
                                Faculties for Spring ingestion in Canada Brock
                                University Kwantlen Polytechnic University
                                Capilano University Lakehead University
                              </p>
                            </div>
                            ):<></>}
                            {showData=='UK'?(
                            <div id="UK">
                              <h4>UK</h4>
                              <p>
                                Intake and. Many universities in UK also supply
                                April / May ingestion for a couple classes.{" "}
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  January Intake
                                </p>
                                January ingestion is the secondary ingestion.
                                Not as many classes Are provided in January
                                intake in comparison to September ingestion, but
                                this ingestion gives an chance to the students
                                who've missed the entrance in the primary
                                intake. It provides pupils some more time to
                                operate on their program. The program deadlines
                                will probably drop between June and September
                                and will vary from course to course and
                                University to University.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  September Intake
                                </p>
                                The principal ingestion in UK is September
                                ingestion. It's also known as Autumn ingestion
                                in UK. Most Colleges in UK offer all of the
                                classes from the September intake. The
                                application deadlines for the September intake
                                will drop between February and May of the year.
                                But, it will always change based on changes and
                                course in University to University. Students
                                should assess the various university for those
                                particulars.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  May Intake
                                </p>
                                • May UK University ingestion isn't much
                                available. Nevertheless you'll be able to locate
                                some classes in May ingestion too.
                                <br />
                                <br />
                                • Faculties For example Coventry University,
                                Northumbria University, Southampton Solent
                                University, University of Bedfordshire etc.
                                provide Might ingestion in a couple of classes
                                for global students.
                                <br />
                                <br />
                                You've obtained the Reply to your question of
                                just how many Intakes are present in UK. Now
                                let's talk about each ingestion in detail using
                                a step-by-step procedure.
                                <br />
                                <br />
                                January/February Intake June and September
                                <br />
                                <br />
                                September Intake February and May
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Important UCAS Deadlines
                                </p>
                                All Undergraduate classes applications are
                                handled by UK Deadlines of UCAS are given below
                                to the UK consumption also, however these are
                                the critical dates to make certain that you have
                                to speak to your preferred university.
                                <br />
                                <br />
                                • 15 October -it's for all of the classes in
                                Cambridge and Oxford and many medicine, and
                                dentistry veterinary drug classes at the Other
                                associations
                                <br />
                                <br />
                                • 15 January - It's for many other undergraduate
                                classes
                                <br />
                                <br />
                                • 30 June - It's for many other undergraduate
                                classes For global students
                                <br />
                                <br />
                                Particular UK universities do allow global
                                applications After certain deadlines too. Test
                                it with your own University in the event you're
                                late.
                                <br />
                                <br />
                                (FYI - September ingestion afterward January
                                intake)
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  6 Approaches to Study at January Intake at UK
                                </p>
                                Here We've cited the Step-by-step procedure for
                                the United Kingdom Universities with January
                                ingestion for undergraduate and postgraduate
                                classes.
                                <br />
                                <br />
                                • The first Step to research in UK for January
                                consumption is to select a class and start to
                                brief list the Faculties that is most
                                appropriate for you.
                                <br />
                                <br />
                                • The picking Process differs for everybody and
                                will vary from individual to individual in
                                accordance with your academics as well as the
                                future strategies. Do thorough research on the
                                classes that you would like to do, and also the
                                classes and universities you're qualified for.
                                <br />
                                <br />
                                To begin would be to go through the official
                                sites of the Faculties in UK and find out the
                                path information, have it completely researched
                                before you decide on short list.
                                <br />
                                <br />
                                • Be familiar With lender loan alternatives and
                                scholarships to finance your research.
                                <br />
                                <br />
                                • Don't Forget to search for the January intake
                                and also the program deadlines for the course
                                that you need to use.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Measure 2 - Look for the Tests: July to August
                                </p>
                                • The most Important eligibility prerequisites
                                for your classes and college you decide on will
                                be the entry examinations.
                                <br />
                                <br />
                                • There will Be overall aptitude tests such as
                                the GRE and GMAT, and that is asked to get
                                entrance in Uk universities. Prepare and try it.
                                <br />
                                <br />
                                • Another Essential evaluations are language
                                proficiency evaluations like IELTS and TOEFL.
                                <br />
                                <br />
                                • Bear in Mind These evaluations will require
                                some preparation period, and the outcomes will
                                also announced after weeks.
                                <br />
                                <br />
                                • In the event the Outcomes aren't desirable,
                                you may want to look for evaluations again. All
                                this time ought to be considered while preparing
                                a deadline for January ingestion in UK.
                                <br />
                                <br />
                                Now could be the time to begin preparing the
                                program. Keep a watch out for the program
                                deadlines. Various universities and classes will
                                have different deadlines.
                                <br />
                                <br />
                                • per month Prior to the deadline, begin the
                                documentation process for all of the paperwork
                                that's necessary.
                                <br />
                                <br />
                                • Prepare your SOP/Application Essay. Statement
                                of Purpose will be composed by you saying your
                                academic background and why you've selected the
                                University.
                                <br />
                                <br />
                                • You should Additionally begin calling the
                                professors and supervisors to write letters of
                                recommendations for you if they're needed
                                <br />
                                <br />
                                Universities in UK prior to the expected date. •
                                If there's Any advancement on your program
                                together with the University, you'll be given a
                                email in their side. You're essential to revert
                                with an affirmation if you're thinking about
                                attending the program.
                                <br />
                                <br />
                                • If You're Prepared to attend the program, you
                                should allow the university know using a
                                confirmation email as soon as possible. Don't
                                await the deadlines .
                                <br />
                                <br />
                                • You may Also be requested to cover a non
                                refundable verification fees. That, also, has
                                its own deadlines and the sum ought to be paid
                                prior to that.
                                <br />
                                <br />
                                Whenever you Get the approval letter in the
                                University, you need to initiate the practice of
                                student loan and UK student Visa. You should
                                apply to your UK student Visa at least three
                                weeks prior to the January intake begins. The
                                Visa and Loan procedure will take some time and
                                are among the most crucial processes.
                                <br />
                                <br />
                                If you Want to, also You'll be asked to reveal
                                the fiscal funds throughout the visa procedure,
                                and some other scholarships or grants ought to
                                be announced afterward, while implementing.
                                <br />
                                <br />
                                You're only 1 step away from the research in UK
                                travel. Before you reserve for those flights, be
                                sure to have all of the files which will be
                                required.
                                <br />
                                <br />
                                • The following Step is to search for an
                                accommodation. If you aren't likely to reside on
                                the campus center in the University, you'll need
                                to search for student accommodations by
                                yourself. Begin this procedure a month prior to
                                your arrival.
                                <br />
                                <br />
                                • Organize an Global debit or credit card.
                                <br />
                                <br />
                                • Create a Pre-departure checklist to ensure
                                that you don't overlook anything.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Faculties with January ingestion in UK
                                </p>
                                • Bangor University
                                <br />
                                • Birmingham City University
                                <br />
                                • Brunel University
                                <br />
                                • Edge Hill University
                                <br />
                                • Kingston University
                                <br />
                                • Northumbria University
                                <br />
                                • University of Chester
                                <br />
                                • University of Greenwich
                                <br />
                                • University of Sunderland
                                <br />
                                • Anglia Ruskin University
                                <br />
                                • Birkbeck
                                <br />
                                • City
                                <br />
                                • Coventry University
                                <br />
                                • De Montfort University
                                <br />
                                • Keele University
                                <br />
                                • Oxford Brookes University
                                <br />
                                • University of Brighton
                                <br />
                                • University of Lincoln
                                <br />
                                • University
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Which UK ingestion to select?
                                </p>
                                It Can Be Very confusing for you to choose the
                                UK ingestion To Pick the UK research intakes
                                consider factors like
                                <br />
                                • Availability Of your preferred program,
                                <br />
                                • Your academic records,
                                <br />
                                • Your Entry exam scores
                                <br />
                                • Acceptance Rates of this university
                                <br />
                                • Job opportunities available
                                <br />
                                • Your Willingness to join the college
                                <br />
                                The Majority of the international students
                                choose to take the September intake. But,
                                January and May intakes will also be Great for a
                                few of The classes. If You Don't have your own
                                scorecards ready, it is Much Better to bypass
                                the Quickness and use to the next intake.
                              </p>
                            </div>
                            ):<></>}
                            {showData=='AUSTRALIA'?(
                            <div id="Australia">
                              <h4>Australia</h4>
                              <p>
                                February ingestion is the Main (primary ) Intake
                                at Australia, And as its name implies it begins
                                in February, the secondary ingestion falls in
                                July. Like the majority of the nations, the
                                principal ingestion will have the maximum number
                                of those classes provided by nearly every
                                University.
                                <br />
                                <br />
                                The July ingestion will have a restricted number
                                of Faculties Supplying a limited variety of
                                classes to pick from. Both of these are
                                important intakes in Australia.
                                <br />
                                <br />
                                You will find additional intakes such as
                                September and November ingestion In Australia,
                                also, with fewer classes.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Intakes in Australia
                                </p>
                                Various Faculties have different deadlines for
                                every Ingestion, so the application procedure
                                ought to be planned so. Start before six months,
                                be performed with Speech and Entrance Tests
                                prior to three months and devote the rest time
                                for the entrance and visa procedures.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Australia intakes Deadline
                                </p>
                                1. February Intake October - November
                                <br />
                                2. July ingestion April - Could
                                <br />
                                <br />
                                If You're Concerned about the Ideal ingestion to
                                select in As we've mentioned, the February (also
                                referred to as term 1), is the principal
                                consumption in Australia and the majority of the
                                classes, nearly all of the classes are offered
                                throughout the February consumption by virtually
                                all of the universities. The Universities also
                                provide classes in July, and should you overlook
                                the entrance by any way in February, July is an
                                alternative for you.
                                <br />
                                <br />
                                Additionally, there Are intakes for
                                October/November, nevertheless, just a Handful
                                of universities provide them and the classes
                                offered in these are scarce. A few of the
                                universities have the yearlong program, where it
                                is possible to use any time of the year, but the
                                choices are chosen there, also.
                                <br />
                                <br />
                                Here's the listing of top Colleges in Australia,
                                Intakes And classes out there for them, as well
                                as the program deadlines.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Bond University
                                </p>
                                Remember these are the overall deadlines and for
                                Many universities, they'll change with
                                particular courses. The best way to be ready on
                                time would be to look at the deadline for the
                                particular courses while picking the university
                                and course. These are only for general
                                reference.
                                <br />
                                <br />
                                Get FREE Counselling
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  6 Approaches to Study at February Intake at
                                  Australia
                                </p>
                                In Case You Have chose to Study in Australia and
                                do not understand Where to begin, we've got
                                answers for you. Most important issue is
                                ingestion. Australian Colleges have two intakes;
                                the February ingestion is the main of both, to
                                research in Australia for global students.
                                <br />
                                <br />
                                Most Colleges in Australia for Global students
                                Have served basis admission procedure. For
                                February ingestion in Australia, you should
                                begin as soon as you can. Remember that the
                                deadlines which vary from college to university,
                                since there's no fixed deadline for every single
                                faculty or University in Australia.
                                <br />
                                <br />
                                Before that, Shortlist the Faculties you want to
                                employ to.
                                <br />
                                <br />
                                Select the classes that satisfy you the maximum
                                as per your livelihood Goals, academic
                                objectives, and discipline of research.
                                <br />
                                <br />
                                Assess the University site for your own
                                application forms and requirements.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Measure 2: Look for the Accreditation -
                                  September to October
                                </p>
                                Requirements: On the University site, you'll
                                discover the entry tests and requirements you
                                have to take. For competitive tests like GMAT
                                and GRE, you Need to reserve The evaluation a
                                great deal sooner compared to English Language
                                Proficiency evaluations such as IELTS and TOEFL.
                                <br />
                                <br />
                                Regardless, It's important to look for
                                examinations three Weeks earlier, as you may
                                need in order to take the exam .
                                <br />
                                <br />
                                Search for February intake schools in Australia
                                2019, Shortlist and choose the faculty or the
                                university prior to the deadline.
                                <br />
                                <br />
                                The program Must include your uniqueness for a
                                candidate and also show you at a fantastic
                                light.
                                <br />
                                <br />
                                Speak to your immediate managers and professors
                                to the Reference letters. The documentation
                                procedure should start prior to a month out of
                                the program deadline.
                                <br />
                                <br />
                                Submit the program before the expected date.
                                <br />
                                <br />
                                Faculties will revert you in your program status
                                following a while. You shouldn't delay in
                                responding to this email.
                                <br />
                                <br />
                                Some Australia schools and Universities might
                                request A movie interview, so be ready for this,
                                also.
                                <br />
                                <br />
                                Create a Fast choice as Soon as You get a
                                verification from the University. Email the
                                university or college once you make the choice.
                                As said before, it may come to come first serve,
                                so don't hesitate to answer until the deadlines.
                                <br />
                                <br />
                                Once registered, You'll Be asked to cover the
                                non refundable Affirmation fees.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Loan- December into January
                                </p>
                                Some procedures will require some time, which
                                should always be Considered prior to
                                preparation. Apply for Study loan for overseas
                                studies the moment you receive confirmation from
                                the college.
                                <br />
                                <br />
                                Document each of the paperwork. As soon as
                                you've got all of the vital files, the visa
                                procedure isn't as complicated as it might
                                appear. Research in Australia. It's also
                                advisable to apply for them after you have the
                                approval letter. You've done all of the hard
                                work and it is time to fly. Most classes will
                                begin in early February, so it's suggested to
                                arrive in Australia per month before and search
                                for lodging on or off-campus.
                                <br />
                                <br />
                                Collect All of Your necessary files and their
                                photocopies Before flying.
                                <br />
                                <br />
                                You Have to arrange to get an Global
                                credit/debit card Before death. Create a
                                pre-departure checklist and Be Sure You check
                                every Box onto it.
                              </p>
                            </div>
                            ):<></>}
                            {showData=='FRANCE'?(
                            <div id="France">
                              <h4>France</h4>
                              <p>
                                The Intakes simply denote the conditions or the
                                courses at The universities will begin at the
                                time of year. There are two primary Intakes in
                                France for its international students, January
                                and September, the two are equally important for
                                both universities and students for France.
                                However, the September consumption is given more
                                significant by a few Here we're talking the
                                deadline to follow along with the procedure to
                                research for the intakes at France.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Intakes in France
                                </p>
                                The Spring Intake at France takes off in the
                                start of The entire year in January. As we've
                                mentioned, both spring and fall ingestion hold
                                the exact same significance and the amount of
                                classes offered are nearly exactly the same.
                                <br />
                                <br />
                                If your Aim is to Research in France, and you
                                also want to employ For January ingestion, we
                                deliver you the deadline you may follow to use
                                Universities in France to examine in January.
                                Remember there are different intakes in France,
                                also, and autumn or September ingestion in
                                France in Main Intake. There's a spring
                                ingestion, also. But if you would like to begin
                                in January, here are the deadlines and deadline
                                for the actions that you will need to follow
                                along.
                                <br />
                                <br />
                                Start Searching for a class in your field of
                                research, bachelor And Experts in France Courses
                                beginning in January, and following this, a
                                University. Shortlist the University which most
                                fits your academic and career objectives. In a
                                nutshell, do comprehensive research in every
                                manner possible before picking the University
                                you'll be analyzing in.
                                <br />
                                <br />
                                You have to submit your program ahead of the
                                deadlines. Here's a List of Faculties in France
                                for January Intake:
                                <br />
                                <br />
                                January Intake Faculties
                                <br />
                                CentraleSupélec, Paris
                                <br />
                                <br />
                                These are Just a Few of the Very Best Colleges
                                in France with January intake. There are
                                numerous Universities and Colleges offering
                                programs beginning in January term.
                                <br />
                                <br />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Measure two -Look for the Tests: July to
                                  August
                                </p>
                                The University you've shortlisted will possess
                                some special Admission requirements you will
                                need to fulfil. Some of these are entry
                                examinations. There are lots of aggressive and
                                entry examinations, an individual can take to
                                find an entry to a French University.
                                <br />
                                <br />
                                The evaluation results also take the time to be
                                announced and you May want to retake them in the
                                event of undesirable outcomes. For that reason,
                                it's necessary to get a buffer of 2 weeks for
                                the examinations.
                                <br />
                                <br />
                                Start preparing to your program once You're sure
                                about The route and University. French Colleges
                                might request an application composition or SOPs
                                and LORs. The concept is to emphasize the
                                strengths you have and introducing yourself as a
                                exceptional applicant.
                                <br />
                                <br />
                                Contact The documentation should begin at the
                                very least a month prior to the program
                                deadline.
                                <br />
                                <br />
                                To use, go to the various University and follow
                                along with the Measures on the official sites.
                                Ensure that you are filling the proper details.
                                Submit an application time prior to the expected
                                date.
                                <br />
                                <br />
                                Following a Particular period, the University
                                will email you with the Status of your program.
                                You need to revert to them the moment you're
                                prepared with your choice. You may be requested
                                to look for a private or even a video interview.
                                <br />
                                <br />
                                If You're chosen and prepared to attend the
                                Program, you Should respond immediately. Do wait
                                for those deadlines .
                                <br />
                                <br />
                                As Soon as You confirm your favorable response,
                                You May Be requested to Pay a confirmation
                                charges by some Colleges. Pay them according to
                                the deadlines.
                                <br />
                                <br />
                                Following the verification, you will Find an
                                approval letter In the University. The moment
                                you have the verification letter in the French
                                University, begin preparing for your Student
                                Visa from France Program. The visa procedure
                                will also take some time, so bear this in mind
                                before applying.
                                <br />
                                <br />
                                Start applying to your Student's loan and also
                                handle the financial funds, you'll be asked to
                                show. If you're applying for study abroad
                                scholarships, then begin the procedure for
                                exactly the same. Document all of the proofs and
                                documents you'll need for these programs.
                                <br />
                                <br />
                                You're almost ready. Reserve tickets. If the
                                Orientations and Courses start from January,
                                it's highly recommended to arrive at France from
                                December. Start searching for lodging on or
                                outside the campus based upon your preference.
                                <br />
                                <br />
                                Collect all the necessary files and the
                                photocopies Required prior to flying. Arrange
                                for an global charge / debit card, and also a
                                pre-departure checklist to have a hassle free
                                travel to France.
                                <br />
                                <br />
                                The Fall Intake in France begins from the
                                September, it's Considered the principal
                                ingestion by most, and you will find a
                                significant number of course Accessible for your
                                September Intake. If You're someone looking
                                ahead to examine In September Intake to get
                                France, this really is your deadline one wants
                                to follow:
                              </p>
                              <br />
                              <br />
                            </div>
                            ):<></>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Container>
  );
}
