/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
// import './styles.css';
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },

  font1:{
    fontWeight:'bold',
    color:'#00b894'
  },

  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [messages, setMessages] = React.useState("");
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus, setStatus] = React.useState("");

  const handlePicture = (event) => {
    setPicturePath(URL.createObjectURL(event.target.files[0]));
    setPicture(event.target.files[0]);
  };

  const handleVerifyPassword = (e) => {
    setVerifyPassword(e.target.value);
    if (password === e.target.value) {
      setStatus("Password Matched");
    } else {
      setStatus("Password not matched");
    }
  };

  //   const addNewRecord=async()=>{
  // if(picture=='')
  // {
  //     var body={'firstName':firstName,
  //     'lastName':lastName,
  //     'emailId':emailId,
  //     'password':password,
  //     'status':"Not Approved",
  //     'picture':"Not Found"}
  //     var result=await postData('selfregister/addNewRecord',body)
  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}

  // }

  // else{
  //    var formData=new FormData()
  //    formData.append('firstName',firstName)
  //    formData.append('lastName',lastName)
  //    formData.append('emailId',emailId)
  //    formData.append('password',password)
  //    formData.append('picture',picture)
  //    formData.append('status', "Not Approve")

  //    var config={headers:{'content-type':'multipart/form-data'}}
  //     var result=await postDataAndImage('selfregister/addNewRecordPic',formData,config)

  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}
  //     }
  //     }

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 70,
                }}
              >
                {/* <div className="banner-area inc-form bg-gradient slider-less text-light" style={{backgroundImage: "url(" + "../assets/img/event/1.jpg" + ")", padding:70}}> */}
                <div className="container">
                  <div className="row">
                    {/* <div className="double-items">
                    <div className="col-md-7 info">
                        <h2 className="wow fadeInLeft">Find your ideal study program.</h2>
                        <a className="btn day btn-light border btn-md wow fadeInDown" style={{color:'#3f8ccb'}} href="#">View Courses</a>
                    </div>
                    <div className="col-md-5 reg-form">
                        <form action="#">
                            <div className="row">
<img style={{flex:1}} class="login_image" src="assets/img/elearning.png" alt="Login" data-original-title="" title="" />

                            </div>
                        </form>
                    </div>
                </div> */}
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">Forex</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="about-area default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div className="container">
                  <div className="row">
                    <div className="about-items">
                      <div className="col-md-6 about-info">
                        <h2>
                          Forex <span></span>
                        </h2>

                        <p
                          style={{
                            fontSize: 14,
                            color: "#212121",
                            textAlign: "justify",
                          }}
                        >
                          Planning to study overseas and perplexed as to how to
                          cover your fees and other expenses via Forex? Listed
                          below are a few Forex hints that can make your job
                          simpler. There are normally 4 measures in the
                          procedure:1. Program: A pupil narrows back on the
                          universities of the pick and on a typical applies to
                          5-6 of these. A few of the universities need an
                          application fee and a number of them don't. Where
                          program fee is necessary, the payment is typically
                          done through wire transfer.
                        </p>
                      </div>
                      <div className="col-md-6 features text-light">
                        <img
                          style={{ flex: 1 }}
                          class="login_image"
                          src="assets/img/forex.png"
                          alt="Login"
                          data-original-title=""
                          title=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="blog-area single full-blog left-sidebar full-blog default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="container"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <div className="row">
                    <div className="blog-items">
                      <div className="blog-content col-md-12">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                    <p style={{ color: "#212121" }}>
                                      1. <font className={classes.font1}>Online Sites Banks </font>
                                      generally
                                      concentrate on account holders and possess
                                      a greater margin on sending and rates
                                      commission. Local sellers are a really
                                      handy option, due to closeness, but a
                                      prospect of money counterfeiting might be
                                      large and reloading forex might not be as
                                      simple. Online sellers are of two kinds --
                                      bidding motors or aggregators where it is
                                      possible to compare offline sellers and
                                      bid for prices, and internet portals where
                                      you are able to buy forex entirely online
                                      and upload files online too.
                                      <br />
                                      Online portals generally provide
                                      additional discounts on present buck
                                      prices and supply easy reload options if
                                      you happen to run out of money when are
                                      overseas. Additionally, most online
                                      portals offer you ATM withdrawals free,
                                      resulting in higher savings.
                                      <br />
                                      Forex cards can be bought directly from
                                      the banks supplying themoffline sellers
                                      and via online portals. Wire transfers
                                      typically performed via Banks, but could
                                      also be routed through offline & online
                                      sellers that provide these services at
                                      better prices.
                                      <br />
                                      Documents Necessary for buying Forex
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      Ideally, forex could be bought a month
                                      until you depart for the college. It's
                                      always better to keep a look out on the
                                      prices on the marketplace and also about
                                      the prices provided by various channels. A
                                      fantastic strategy is to have a mixture of
                                      remittances and currency trades, which if
                                      bought from an internet vendor may cause a
                                      saving of 5-7 percent more than
                                      conventional banks.
                                      <br />
                                      <br />
                                      <font className={classes.font1}>Money Currency Notes</font>
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      Carrying foreign money is the most
                                      insecure so that it is strongly
                                      recommended to carry just a tiny quantity
                                      of money currency when traveling overseas.
                                      However, you have to take some since your
                                      cards might not be approved at several
                                      areas like shopping on roads or for road
                                      food, etc..
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      If travellers wish to take more money,
                                      then the equilibrium amount can be
                                      obtained in the shape of Forex Card or
                                      Travellers Cheque. It's possible to draw
                                      foreign currency notes together with your
                                      Forex Card at ATMs overseas at minimum
                                      transaction fees.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      Global wire transfer or cash transfer is
                                      the very best and most convenient method
                                      to transfer money overseas. It's a digital
                                      mode of moving cash from 1 lender to
                                      another where cash from the lender is
                                      transferred into a beneficiary's bank
                                      accounts by means of a network such as
                                      SWIFT.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      The funds are available within a couple of
                                      days following the move is processed as
                                      well as the acknowledgement of cable
                                      transfer could be obtained in the shape of
                                      SWIFT backup that's supplied by the lender
                                      following 24 hours.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      Dependent on the card issuer, kind of
                                      currency being applied for and the number
                                      being loaded on the card there might be
                                      other files which have to be supplied.
                                    </p>
                                      <br />
                                      2. <font className={classes.font1}>Upon approval</font>, some universities ask
                                      that you pay a registration fee.
                                      <br />
                                      3. When you get an <font className={classes.font1}>Offer Letter </font> from the
                                      college of choice, now is the time to
                                      apply for a <font className={classes.font1}>VISA</font>. After procurement of a
                                      VISA, then you'll be asked to pay your
                                      Tuition charge. Based on the college, this
                                      fee could be paid in 1 go or even a
                                      partial payment could be achieved prior to
                                      joining and the remainder could be paid in
                                      person as soon as you arrive at the
                                      university. If you must create a progress
                                      payment, wire transfer is the preferred
                                      choice, and also for in person payments,
                                      then a Forex card would be the ideal
                                      choice.
                                      <br />
                                      4. <font className={classes.font1}>Care and living costs</font>.
                                      <br />
                                      Some universities nevertheless take
                                      payment through Demand Drafts, but that is
                                      quickly becoming an antiquated
                                      alternative.
                                      <br />
                                      <br />
                                      The whole number of foreign exchange used
                                      by a pupil both concerning forex trades
                                      and wire transfers, at a fiscal year
                                      should not exceed USD 250,000 for
                                      instructional purposes. This amount
                                      generally includes forex prices for
                                      maintenance/living expenses in addition to
                                      your tuition fee. Nonetheless, in the
                                      event your tuition cost exceeds this sum,
                                      you are able to justify it by providing
                                      your charge slip.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      To remit cash, you'll have to get a bank
                                      accounts along with a receiving bank
                                      accounts. There'll be a prevailing Forex
                                      rate that will be suspended in the time of
                                      transport, a shipping fee payable from the
                                      bank and also a Beneficiary commission or
                                      BEN charges levied by the receiving bank.
                                      Therefore, for example you're sending
                                      $10,000 overseas, you may be charged a
                                      shipping charge of INR 250 to 2,500/and
                                      BEN costs of $13-$15. Based upon your
                                      destination and your selection of sending
                                      lender, these fees vary.
                                      <br />
                                      Forex trades are prepaid cards on which
                                      you load cash in local currency which gets
                                      converted to the foreign currency of
                                      choice in a predetermined FX rate. Forex
                                      cards may be used to draw cash from ATMs,
                                      swiped in terminals, and may also be used
                                      to create online transactions. Forex cards
                                      can be reloaded with new funds if one runs
                                      from currency. Based on which lender you
                                      bought from, a few forex cards arrive with
                                      an issuance fee in the assortment of INR
                                      100-350.
                                      <br />
                                      Here, it's worth noting that you can't use
                                      a currency card issued from your
                                      sibling/relatvie/friend's title for your
                                      own function. Forex is highly controlled
                                      and KYC compliant. Post demonetisation,
                                      Customs look at airports is stringent and
                                      has been tightened. If you don't have a
                                      valid evidence of currency buy, your whole
                                      forex sum is liable to be redeemed. This
                                      amount could be redeemed only on entry of
                                      a proof of purchase.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                    <font className={classes.font1}>Benefits </font> of Forex card within Credit/Debit
                                      Card
                                      <br />
                                      Below are a number of these:
                                      <br />
                                      Issuance charge -- There is not any
                                      Issuance fee for currency cards if bought
                                      online. But some banks can charge between
                                      INR 0-350. For global credit cards that
                                      the issuance fee is generally involving
                                      INR 0-1500.
                                      <br />
                                      Withdrawal commission -- For foreign
                                      exchange cards, $2 or its equivalent in
                                      other money is billed per cent at ATMs,
                                      whereas around INR 500 is billed per cent
                                      for global banking cards, as well as INR
                                      1000 percent for charge cards.
                                      <br />
                                      Merchant service charge -- Forex cards
                                      don't have any retailer commission,
                                      whereas for global debit card and credit
                                      cards, an extra 3.5 percent retailer
                                      service fee could be billed.
                                      <br />
                                      FX prices -- For foreign exchange cards,
                                      exchange rates are suspended at time of
                                      loading, and thereby having no chance of
                                      speed changes. Whereas for global credit
                                      and debit cards, it's not feasible to
                                      suspend rates, and the greatest prices for
                                      your day is used for any trade.
                                      <br />
                                      FX conversion fees -- No extra conversion
                                      fees are appropriate for currency cards,
                                      but also for global credit and debit
                                      cards, a cross currency markup of 4-6
                                      percent may be implemented to each trade.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <font className={classes.font1}>Different types of Forex cards</font>
                                      <br />
                                      There are lots of cards from AXIS, ICICI,
                                      HDFC that are student friendly and
                                      targeted towards creating college payments
                                      simple and hassle free.
                                      <br />
                                      There are two different types of Forex
                                      cards
                                      <br />
                                      Multi-currency cards Multi-currency cards
                                      enable you to load unique currencies on a
                                      single card. You may load up to 18 monies
                                      on a single card, dependent on the seller.
                                      <br />
                                      Single-currency cards Single-currency
                                      cards have been card filled up with single
                                      money, and these may be a fantastic
                                      alternative if travelling into one nation.
                                      <br />
                                      You may buy cards from banks, online in
                                      addition to offline sellers.
                                    </p>
                                    
                                  </div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div className="col-md-12">
                                    <div
                                      className="content"
                                      style={{ paddingBottom: 0 }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area default-padding">
        <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="row" style={{ display: "block" }}>
            <div className="about-items">
              <div className="col-md-12">
                <div className="weekly-top-items">
                  <div className="top-courses" style={{ paddingRight: 0 }}>
                    <div className="heading" style={{ paddingBottom: 15 }}>
                      <h4 style={{ color: "#3f8ccb" }}>
                        ACCREDITATIONS AND MEMBERSHIPS <strong></strong>
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/EAIE.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/ICEF.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/NAFSA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/QISAN.jpeg"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIRC.gif"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIEA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
