/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Header from "./Header";
import Footer from "./Footer";
import { postData, getData } from "../FetchServices";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [messages, setMessages] = React.useState("");
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus, setStatus] = React.useState("");
  const [countryName, setCountryName] = React.useState([])
  const [uniName, setUniName] = React.useState([])
  const [programName, setProgramName] = React.useState([])
  const [getProgramId,setProgramId] = React.useState();

   const handleFetchProgramId=async(event,Id)=>{
    // console.log("Event...............",e)
    console.log("Id...............>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",Id.id)
    // setcName(val.country) 

    setProgramId(Id.id)
    // console.log("or kuch Aagya...",res);
   }

   const handleCourse = (id) => {
    // alert("hello next Page");
    console.log('id>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>',id)
      props.history.push({
        pathname: "/DisplayCourses",
        state: {
          id: id,
         
        }
      });
    // console.log(event.currentTarget);
  };

  
  const handlePicture = (event) => {
    setPicturePath(URL.createObjectURL(event.target.files[0]));
    setPicture(event.target.files[0]);
  };

  const handleVerifyPassword = (e) => {
    setVerifyPassword(e.target.value);
    if (password === e.target.value) {
      setStatus("Password Matched");
    } else {
      setStatus("Password not matched");
    }
  };

  const fetchAllCountryName=async()=>{
    var result=await getData("universities/DisplayCountry")
    console.log("=====>>>>>>",result)
    setCountryName(result)
  }

  React.useEffect(function(){
    fetchAllCountryName()
  },[])

  const handleFetchUniversity=async(event,Id)=>{
    // console.log("Event...............",e)
    console.log("val...............",Id.country)
    // setcName(val.country) 
    let body={
       cname:Id.country
     }
     let res = await postData('universities/fetchuniversity',body)
     setUniName(res)
    //  console.log("Result Aagya...",res);
   }

   const handleFetchProgram=async(event,Id)=>{
    // console.log("Event...............",e)
    // console.log("val...............",Id)
    // setcName(val.country) 
    let body={
       pname:Id.name
     }
     let res = await postData('abroadcourses/fetchprogram',body)
     setProgramName(res)
    // console.log("or kuch Aagya...",res);
   }


  // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
  // const top100Films = [
  //   { title: "Phd", year: 1994 },
  //   { title: "MCA", year: 1972 },
  //   { title: "MSC", year: 1974 },
  // ];

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header /> <CssBaseline />
      <div className="login-area default-padding" style={{ paddingTop: 20 }}>
        <div className="">
          <div className="row">
            <div className="col-md-12">
            
              <div className="about-area default-padding">
                <div className="container">
                  <div className="row">
                    <div className="about-items">
                      <div className="col-md-3 features text-light">
                        <div
                          className="sidebar-item category"
                          style={{ padding: 15 }}
                        >
                          <div className="sidebar-info">
                            <a
                              color="primary"
                              id="togglerU"
                              style={{ color: "#000", fontWeight: "bold" }}
                              fullWidth
                            >
                              Inquiry Form{" "}
                              <span style={{ float: "right" }}>
                                <i class="fa fa-envelope"></i>
                              </span>
                            </a>
                          </div>
                          <hr />
                          <p
                            style={{
                              fontSize: 12,
                              color: "#212121",
                              textAlign: "justify",
                              lineHeight: "inherit",
                            }}
                          >
                            Our education counselors and campus teams help you
                            every step of the way, from application to
                            graduation, at no additional cost to you.
                          </p>
                          <div className="blog-area">
                            <div className="info">
                              <div
                                className="content"
                                style={{ paddingBottom: 0 }}
                                style={{ padding: 0, position: "static" }}
                              >
                                <a
                                  href="https://calendly.com/studymetro/students"
                                  style={{
                                    color: "#000",
                                    borderRadius: 5,
                                    textAlign: "center",
                                  }}
                                >
                                  {" "}
                                  Talk to an Advisor
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="sidebar-item category"
                          style={{ padding: 15 }}
                        >
                          <div className="sidebar-info">
                            <a
                              color="primary"
                              id="togglerU"
                              style={{ color: "#000", fontWeight: "bold" }}
                              fullWidth
                            >
                              Videos{" "}
                              <span style={{ float: "right" }}>
                                <i class="fa fa-video-camera"></i>
                              </span>
                            </a>
                          </div>
                          <hr />
                          <p
                            style={{
                              fontSize: 12,
                              color: "#212121",
                              textAlign: "justify",
                              lineHeight: "inherit",
                            }}
                          >
                            Our education counselors and campus teams help you
                            every step of the way, from application to
                            graduation, at no additional cost to you.
                          </p>
                          <div className="">
                            <a
                              className="videoOverlapper"
                              href="https://youtube.com/embed/c_newv2i3r0"
                              data-featherlight="iframe"
                            >
                              <i
                                style={{
                                  position: "absolute",
                                  padding: 90,
                                  fontSize: 40,
                                }}
                                class="fa fa-play-circle"
                              ></i>
                              <img src="https://www.studymetro.com/static/uploads/videos/150169728359821503548a8.jpg" />
                            </a>
                          </div>
                        </div>
                        <div
                          className="sidebar-item category"
                          style={{ padding: 15 }}
                        >
                          <div className="sidebar-info">
                            <a
                              color="primary"
                              id="togglerU"
                              style={{ color: "#000", fontWeight: "bold" }}
                              fullWidth
                            >
                              Event{" "}
                              <span style={{ float: "right" }}>
                                <i class="fa fa-flag"></i>
                              </span>
                            </a>
                          </div>
                          <hr />
                        </div>
                      </div>

                      <div className="col-md-9 about-info">
                        {/* <h2 style={{fontSize:32,marginTop:20,}}>BS/MS/MBA plan successfully with us to study abroad. <span>Affordable fee, visa aid</span></h2> */}

                        <p
                          style={{
                            fontSize: 14,
                            color: "#212121",
                            textAlign: "justify",
                          }}
                        >
                          Plan your study abroad with our planning team,
                          application, recruitment, visa training, postlanding
                          all support under one overseas consultancy. MBA/MS/BS.
                        </p>
                        <p
                          style={{
                            fontSize: 14,
                            color: "#212121",
                            textAlign: "justify",
                          }}
                        >
                          Study Metro never misguide for study abroad. Road to
                          success should be transparent which leads to top
                          institutes abroad with sequential assistance to school
                          & course selection, documents completion, application
                          filing, top visa assistance, & post landing.
                        </p>
                        <p
                          style={{
                            fontSize: 14,
                            color: "#212121",
                            textAlign: "justify",
                          }}
                        >
                          One of the vital steps that involve your future
                          prospects is selection of a university. There are
                          numerous universities across the globe, which offer
                          admissions to students, but one needs to carefully
                          inspect various aspects before finally deciding to
                          apply because university is the place, where the
                          student is going to invest his valuable time and
                          energy to gain knowledge. Moreover, the more reputed
                          the college is, the more would be the student
                          facilities.
                        </p>
                        <h2 style={{ fontSize: 32, marginTop: 20 }}>
                          Few of the aspects students need to review before
                          applying:
                        </h2>

                        <p
                          style={{
                            fontSize: 14,
                            color: "#212121",
                            textAlign: "justify",
                          }}
                        >
                          Cost of study at the University
                          <br />
                          Availability of financial assistance
                          <br />
                          Availability of interested field of specialization
                          <br />
                          Current employment chances
                          <br />
                          The prospects of further education
                          <br />
                          What sort of accreditation does the university have
                          <br />
                          Flexibility of transferring to other courses or
                          programs within and outside the university Student to
                          staff ratio
                          <br />
                          Faculty and infrastructure
                          <br />
                          The geography of the university
                          <br />
                          <br />
                          Student's knowledge and discretion play an essential
                          role in choosing a university. In fact, if you look at
                          the university websites almost all Universities have
                          an attractive appearance. But in truth, they are a lot
                          different. Some Universities have huge campuses spread
                          across acres of land and some are too small to be
                          actually called a University. But still, both
                          Universities present an equal appeal on the web. And
                          an unsuspecting student can easily be misled into
                          joining a less reputed institute.
                        </p>
                        <p
                          style={{
                            fontSize: 14,
                            color: "#212121",
                            textAlign: "justify",
                          }}
                        >
                          This is where our professional guidance comes to
                          rescue. Our years of experience in the field taught us
                          how to cull out relevant information from various
                          sources and present you with the best possible
                          options. We evaluate, test, reconfirm, so you don't
                          make a wrong move. A step in the wrong direction can
                          be irreversibly expensive in this current fast-paced
                          lifestyle.
                        </p>
                        <p
                          style={{
                            fontSize: 14,
                            color: "#212121",
                            textAlign: "justify",
                          }}
                        >
                          At Study Metro, we go through the pain so you don't
                          have to. We empower you with all relevant knowledge.
                          Whether it's about Universities, Academic Programs,
                          Financial Aids, and Processâ€¦you could trust us to
                          provide nothing but the best. Our Student advisors,
                          managerial staff, research team and the top management
                          are ever at call to help you touch new horizons in
                          life.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
