/* eslint-disable no-empty-pattern */
/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import Header from "./Header";
import Footer from "./Footer";


const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const [password] = React.useState("");
  const [, setVerifyPassword] = React.useState("");
  const [] = React.useState("");
  const [, setPicture] = React.useState("");
  const [, setPicturePath] = React.useState("");
  const [, setStatus] = React.useState("");



  //   const addNewRecord=async()=>{
  // if(picture=='')
  // {
  //     var body={'firstName':firstName,
  //     'lastName':lastName,
  //     'emailId':emailId,
  //     'password':password,
  //     'status':"Not Approved",
  //     'picture':"Not Found"}
  //     var result=await postData('selfregister/addNewRecord',body)
  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}

  // }

  // else{
  //    var formData=new FormData()
  //    formData.append('firstName',firstName)
  //    formData.append('lastName',lastName)
  //    formData.append('emailId',emailId)
  //    formData.append('password',password)
  //    formData.append('picture',picture)
  //    formData.append('status', "Not Approve")

  //    var config={headers:{'content-type':'multipart/form-data'}}
  //     var result=await postDataAndImage('selfregister/addNewRecordPic',formData,config)

  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}
  //     }
  //     }

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 70,
                }}
              >
                {/* <div className="banner-area inc-form bg-gradient slider-less text-light" style={{backgroundImage: "url(" + "../assets/img/event/1.jpg" + ")", padding:70}}> */}
                <div className="container">
                  <div className="row">
                    {/* <div className="double-items">
                    <div className="col-md-7 info">
                        <h2 className="wow fadeInLeft">Find your ideal study program.</h2>
                        <a className="btn day btn-light border btn-md wow fadeInDown" style={{color:'#3f8ccb'}} href="#">View Courses</a>
                    </div>
                    <div className="col-md-5 reg-form">
                        <form action="#">
                            <div className="row">
<img style={{flex:1}} class="login_image" src="assets/img/elearning.png" alt="Login" data-original-title="" title="" />

                            </div>
                        </form>
                    </div>
                </div> */}
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">Why Choose Us</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="about-area default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div className="container">
                  <div className="row">
                    <div className="about-items">
                      <div className="col-md-6 about-info">
                        <h2>
                          Why Choose Us? <span>Study Metro</span>
                        </h2>

                        <p
                          style={{
                            fontSize: 14,
                            color: "#212121",
                            textAlign: "justify",
                          }}
                        >
                          For one simple reason - working with Study Metro will
                          be a win-win situation for you both in admissions and
                          scholarships. Studying abroad can be an expensive
                          process if you do not save before and after going.
                          Here is where we can provide you the best support.
                          There are many solutions - Scholarships, application
                          fee waiver, quality, after sales support and
                          end-to-end services to name a few. We strive for
                          utmost customer satisfaction through our support and
                          solutions.
                        </p>
                        <blockquote style={{ fontSize: 15, lineHeight: "1" }}>
                          Below are some of the points for why you should choose
                          Study Metro
                        </blockquote>
                      </div>
                      <div className="col-md-6 features text-light">
                        <img
                          style={{ flex: 1 }}
                          class="login_image"
                          src="assets/img/learn.png"
                          alt="Login"
                          data-original-title=""
                          title=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="blog-area single full-blog left-sidebar full-blog default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="container"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <div className="row">
                    <div className="blog-items">
                      <div className="blog-content col-md-12">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  {/* <h4>We're the Education Provider organization who put you first!!!</h4> */}
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <h4>
                                    <li>Emphasis on Quality: </li>
                                  </h4>

                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      Study Metro is India's one of the youngest
                                      ISO 9001:2000 Certified Companies along
                                      with AIRC, NAFSA, ICEF, AIEA, EAIE, QISAN
                                      accreditation. It's our emphasis on
                                      processes that helped us pass the tough
                                      certification through an American
                                      Accreditation Agency without a single
                                      non-conformity.
                                    </p>
                                  </div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <h4>
                                    <li>Cost Effective Solutions: </li>
                                  </h4>

                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      Our clients say that our rates are most
                                      reasonable and competitive. We never
                                      over-charge our clients. We work as
                                      technology partners and focus on repeat
                                      business. We understand that it would not
                                      be possible if we are not reasonable in
                                      prices for our services and products (as
                                      mentioned-cost effective solutions). We
                                      achieve this by keeping our operational
                                      costs to the minimum and always keeping a
                                      check on it. Also, we try to automate most
                                      of the processes and remove unwanted
                                      processes which would add to the overall
                                      cost, thus keeping the cost controlled.
                                    </p>
                                  </div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <h4>
                                    <li>End-to-End Solutions:</li>
                                  </h4>

                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      If you have checked all the categories of
                                      our 22 Products and Services, you must be
                                      aware that we are capable of providing
                                      end-to-end solutions to our customers for
                                      any country they choose. This helps in the
                                      smooth application process including
                                      classes & exam support, profile building,
                                      selecting the best match university for
                                      your profile, profile projection, visa
                                      support and more to any country.
                                    </p>
                                  </div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <h4>
                                    <li>Technical Expertise: </li>
                                  </h4>
                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      We have a strong country specialised
                                      counsellors’ team, and our employees are
                                      trained to work in groups of small units
                                      and roll out projects in a smooth,
                                      efficient and timely manner. We have
                                      dedicated applications and visa teams
                                      separately to not mingle the processes and
                                      for the effective outcome.
                                    </p>
                                  </div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <h4>
                                    <li>Single Point of Contact (SPOC): </li>
                                  </h4>

                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      At Study Metro, we stress on making our
                                      clients feel comfortable. Hence, we
                                      provide you a Single Point of Contact
                                      (SPOC) or Account Manager for your Study
                                      Abroad plans. This ensures that you always
                                      talk to a person who already knows your
                                      profile details, and you do not need to
                                      repeat the entire details again. This
                                      makes you comfortable and in turn process
                                      easy for you.{" "}
                                    </p>
                                  </div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <h4>
                                    <li>Timely Guidance: </li>
                                  </h4>

                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      Our focus is not on just the initial
                                      enrolment, but on giving the timely
                                      guidance post that. Our success stories
                                      tell all this about us. Nothing could be
                                      fruitful if it’s not done on time. Thus, a
                                      single point of contact, technical
                                      support, and friendly approach help us
                                      provide timely guidance to the students
                                      looking for support from us. Our technical
                                      expertise, up-to-date application portal,
                                      quick verification of the submitted
                                      documents, writing expertise, all help in
                                      achieving this.
                                    </p>
                                  </div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <h4>
                                    <li>Technical Support Call-Center: </li>
                                  </h4>

                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      For all the students and their parents, we
                                      have a call-center for after sales
                                      support. This comes into the picture once
                                      the enrollment is processed. The Idea
                                      behind this is to provide efficient
                                      support and also to become a bridge
                                      between the Sr. Counsellors’ team and the
                                      students to avoid any discrepancies. Also,
                                      our executives are trained to provide the
                                      solutions to basic problems, so that you
                                      get the support instantly without missing
                                      a single task.
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <h4>
                                    <li>Friendly Approach: </li>
                                  </h4>

                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      Being a successful start-up, reason being
                                      our own director studied abroad, we know
                                      what’s the student's expectation from any
                                      Abroad guiding agency like us, and we try
                                      to fulfil all the requirements of students
                                      from selecting the university till they
                                      get placed there, we also support our
                                      customers in generating successful
                                      internships and jobs overseas.
                                    </p>
                                  </div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div className="col-md-12">
                                    <div
                                      className="content"
                                      style={{ paddingBottom: 0 }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area default-padding">
        <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="row" style={{ display: "block" }}>
            <div className="about-items">
              <div className="col-md-12">
                <div className="weekly-top-items">
                  <div className="top-courses" style={{ paddingRight: 0 }}>
                    <div className="heading" style={{ paddingBottom: 15 }}>
                      <h4 style={{ color: "#3f8ccb" }}>
                        ACCREDITATIONS AND MEMBERSHIPS <strong></strong>
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/EAIE.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/ICEF.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/NAFSA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/QISAN.jpeg"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIRC.gif"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIEA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
