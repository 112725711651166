/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function Countries(props) {
  const classes = useStyles();
  const [showData,setShowData]=React.useState('USA')
//   console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 25,
                  marginTop: 30,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">Countries</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="blog-area single full-blog left-sidebar full-blog default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="container"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
   <div
                className="blog-area single full-blog left-sidebar full-blog default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="container"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <div className="row">
                    <div className="blog-items">
                        <div class="sidebar col-md-3">
                        <aside >
                          <div class="sidebar-item category"  style={{position:'sticky'}}>
                            <div class="title">
                              <h4>Country list</h4>
                            </div>
                            <div class="sidebar-info" >
                              <ul>
                                <li>
                                  <a onClick={()=>setShowData('USA')} style={{color: "#3f8ccb"}}><i class="flag-icon flag-icon-us"></i>&nbsp;&nbsp;USA</a>
                                </li>
                                <li>
                                  <a onClick={()=>setShowData('CANADA')} ><i class="flag-icon flag-icon-ca"></i>&nbsp;&nbsp;Canada </a>
                                </li>
                                <li>
                                  <a onClick={()=>setShowData('UK')}><i class="flag-icon flag-icon-gb"></i>&nbsp;&nbsp;UK </a>
                                </li>
                                <li>
                                  <a onClick={()=>setShowData('AUSTRALIA')}><i class="flag-icon flag-icon-au"></i>&nbsp;&nbsp;Australia </a>
                                </li>
                                <li>
                                  <a onClick={()=>setShowData('FRANCE')}><i class="flag-icon flag-icon-fr"></i>&nbsp;&nbsp;France </a>
                                </li>
                                <li>
                                  <a onClick={()=>setShowData('IRELAND')}><i class="flag-icon flag-icon-ie"></i>&nbsp;&nbsp;Ireland </a>
                                </li>
                                <li>
                                  <a onClick={()=>setShowData('GERMANY')}><i class="flag-icon flag-icon-de"></i>&nbsp;&nbsp;Germany </a>
                                </li>
                                <li>
                                  <a onClick={()=>setShowData('SWEDEN')}><i class="flag-icon flag-icon-se"></i>&nbsp;&nbsp;Sweden </a>
                                </li>
                                <li>
                                  <a onClick={()=>setShowData('NETHERLAND')}><i class="flag-icon flag-icon-nl"></i>&nbsp;&nbsp;Netherland </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </aside>
                      </div>
                      
                      <div className="blog-content col-md-9">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    
                                    {showData=='USA'?(
                                    <div id="usa">
                                    <p style={{ color: "#212121" }}>
       <h4>USA: </h4>
                                        International students considering the studying in the USA, have a varied 
                                        assortment of places and institutions to select from.
                                        <br/>
                                        So as to get admission in a US University or College you may have to pass an 
                                        admissions test like the SAT (for undergraduate studies) or GRE (for grad research ).
                                    </p>
                                    <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Faculties in the United States.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Business Schools at The USA.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Tuition Fees in the United States.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Scholarships to Study at The USA.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Costs of Living from The USA.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Internships & Company Placements at The USA.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Working at The USA.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Applying for a Student Visa to Study at The USA.
                                        </li>
                                      </ul>{" "}
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                        <h6>Faculties in the United States</h6>
                                        You will find more than 7,000 institutions of higher education in the United States.
                                        As an global student, you've over 3,000 Colleges and Colleges in the US to pick from.
                                        We urge prospective pupils to research their choices utilizing the College Navigator,
                                        a formal governmental research tool provided by the National Center for Education 
                                        Statistics from the USA. Another database to study is that the Council for Higher 
                                        Education Accreditation, which lists licensed US Colleges and Faculties.
                                        <br/>
                                        This will depend mostly on the sort of application and University you pick.
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                        <h6>Scholarships to Study at The USA</h6>
                                        Considering the USA is among the costliest areas to study, you'll be very happy to 
                                        know there are choices to relieve this burden. You will find sports scholarships, 
                                        merit scholarships in addition to needs-based financial help.
                                        <br/>
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                        <h6>Prices of living in The USA</h6>
                                        Prices of living in the USA fluctuate depending upon the condition. 
                                        These may vary from 1,000 EUR to 3,000 EUR a month.
                                        <br/>
                                        The CPT internships are the ones which are associated with your area of research 
                                        and relate back to your own academic credits. This option is for students who have 
                                        studied a minumum of one year in University. Besides holding a valid student visa, 
                                        you have to make an application for a particular authorization. Upon acceptance, 
                                        students will get an upgraded I-20 form.
                                        <br/>
                                        On the flip side, the OPT internships aren't necessarily a part of a study-related 
                                        program. These internships may be part of a program or may be chased after graduation. 
                                        You Will Have to attain acceptance in the U.S. Citizenship and Immigration Services.
                                        <br/>
                                        Just how much can interns in the united states earn? This may obviously depend on the business.
                                        <br/>
                                        If You're looking for an internship in the United States, these resources will be of Fantastic worth: USA Internships, Placement International, and Internship USA.
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                        <h6>Working at The USA</h6>
                                        Pupils with an F-1 visa may find a part-time occupation in the US working on campus 
                                        during their first year using a limit of 20 hours each week during semester time. 
                                        Next, international students may ask a license from the U.S. Citizenship and Immigration 
                                        Services to operate off-campus. Pupils with an M-1 visa aren't allowed to do the job.
                                     </p>

                                     <p style={{ color: "#212121" }}>
                                        <h6>Applying for a Student Visa to Study at The USA</h6>
                                        so as to research in the USA, then you will Require a student visa. 
                                        Based on the kind of program and faculty that you need to attend, 
                                        then you might need to apply for an F visa or a M visa. The F visa is 
                                        for students who want to study in a University of College, although the 
                                        visa is for students that intend to attend a school institution or other 
                                        established non-academic institution.
                                        <br/>
                                        Application processes might differ from country to country, therefore we advise that you get in 
                                        contact with the US Embassy or Consulate in your house country and ask the most up-to-date process 
                                        and requirements which match your own situation.
                                     </p>
                                    </div>
                                    ):<></>}

{showData=='CANADA'?(
  <div>
  <p style={{ color: "#212121" }}>
           <h4>Canada</h4>
                                        It's found in the northern area of the continent, and also the capital of Canada is Ottawa. It's the 2nd biggest nation 
                                        on the planet territory-wise. Using its own immigration, friendly policies research in Canada is becoming easier than ever. 
                                        Additionally, it's a favorite destination for diploma and vocational classes among international students.
                                        <br/>
                                        <h6>The best way to research in Canada out of India?</h6>
                                        Students consistently have this question in your mind how they could research in Canada in India. As soon as you get entrance, 
                                        you are able to submit an application for a study permit for Canada. Additionally, students also needs to know the whole 
                                        application procedure for Canada.
                                        <br/>
                                        The college programs in Canada are distinct from one another, based on the state or territory. Instruction can be found in both 
                                        English and in French in many areas, provided that there are sufficient students in that field talking the language that is 
                                        secondary. In Québec, higher school finishes in grade eleven and can be accompanied by a program that prepares the student 
                                        for college or a specialized field named CEGEP.
                                        <br/>
                                        Beyond that, students may attend vocational colleges or enter a college. In a college, they will initially make a Bachelor's 
                                        level and can continue research to get a Master's or a Doctorate level. Vocational students will make certifications and 
                                        diplomas which will aid in improving their skills in a variety of trades.
                                    </p>


                                    <p style={{ color: "#212121" }}>
                                        
                                        <h6>Popular pupil destinations:</h6>
                                        The majority of the top schools in the united states are located in cities such as Toronto, British Columbia, Montreal. 
                                        The amount of Indian students who obtained study licenses from the year 2019 climbed 13.8percent to 1.39 lakh.
                                        <br/>
                                        Canada is your favored destination for vocational studies instead of level classes as the former appears to provide additional 
                                        employment opportunities. Canada is a fantastic selection for authorities as the nation provides immense scope for work due to 
                                        its low population density.
                                        <br/>
                                        Canada is among the safest states, with among the greatest standards of living on the planet.
                                        <br/>
                                        The chilly in Montreal could be particularly biting due to the wind-chill element. Summer temperatures vary from 15°C into 25°C.
                                        <br/>
                                        Toronto Weather - The weather in winter is much milder than many Canadian cities, nevertheless still chilly and snowy. Summer temperatures vary from 15°C into 25°C.
                                        <br/>
                                        Vancouver Weather - It stays moist, yet mild during the year. Summer at Vancouver is warm with a mean temperature of 22°C.
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                        
                                        <h6>Lifestyle hints</h6>
                                        Canadians are proven to become open-minded and proud of the multicultural and diverse inhabitants. 
                                        As with any other nation, Canadians have particular expectations of behaviour. Here are a Couple of hints:
                                        <br/>
                                        <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Take time for both skilled and social events.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Be respectful in dialog and behaviour.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Canadians believe in equality between sexes, so show respect to the other sex.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Do not crack jokes or create remarks that are insulting to people's race, faith, sex, handicap, sexual orientation or look.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Prevent personal questions about matters like age, salary, marital status, relationships, and also the price of personal items until you're well-acquainted with individuals.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Language issues: Living in a society in which you must use English or sometimes French on a daily basis can be hard in the beginning.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;You might not see the local accent immediately. Regional accents vary considerably in Canada.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Canadians may not know you immediately. You'll also have your personal accent. 
                                          Speak slowly and do not worry about asking other people to talk slowly if you're having trouble understanding them.
                                        </li>
                                      </ul>{" "}
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                    Canadians utilize a good deal of slang in their address and it may take you a bit of time until you fully grasp it all. If you do 
                                    not understand something, just ask the significance of it.
                                    Participation: It is critical to take part in all activities on your classes. It's essential to engage outside the classroom too. 
                                    This will supply you with many chances to practice your English abilities in several distinct settings.
                                        <br/>
                                    Primarily, you want to choose whether you would like to reside at university-managed lodging, or using a private landlord. 
                                    Deciding on a university-managed lodging may also provide you a catered or self-catered alternative. Catered accommodation 
                                    delivers the advantages of your foods being cooked for you along with also a level of certainty with meal expenses.
                                    <br/>
                                    When you've got some idea what you would like, the lodging office in your college will have the ability to let you know exactly 
                                    what accommodation they have available - so that is where to get started. If you're thinking about renting from a private landlord 
                                    or in case your preferred university can not offer you anything in its very own residential center, the lodging office ought to have 
                                    the ability to supply you with a listing of personal landlords and properties in the region.
                                    <br/>
                                    Wherever you decide to live, you need to be certain you know your contractual rights and duties. Typically, you'll be requested to enter into a tenancy 
                                    agreement, which you need to read thoroughly before you register it.<br/>
                                    Per week is compulsory for global students, which means you wish to be certain and arrive before it begins. Here is the time at which 
                                    you'll be introduced into the college and its providers, in addition to enroll in your courses. It's very important that you examine 
                                    your guidebook, which is supplied by the faculty. The manual describes each component of the admission procedure.
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                        <h6>Tasks</h6>
                                    Together with sports, schools provide extra-curricular actions offering students a vast assortment of 
                                    experiences. Visits to theatres and concerts, and also to areas related to the courses of study like art 
                                    museums and galleries, spiritual centres or historic sites, scientific businesses, and jobs are part of 
                                    faculty life.
                                        </p>

                                        <p style={{ color: "#212121" }}>
                                        <h6>The way to Acquire Entry to Canadian Universities?</h6>
                                        These change between research programs and degrees. For every program, 
                                        Indian students need to fulfill a minimum English language requirement. Along with this a minimal 
                                        academic record of 65 percent and over for humanities flow in category XII, and 70 - 80 percent and 
                                        over for Science/Commerce flow in class XII are also required. Foundations and Diploma programs are 
                                        offered for pupils who have procured under 60%. The student should have completed 18 decades old prior 
                                        to linking a diploma program.
                                        <br/>
                                        It's very important to be aware that these amounts are only for reference function, the actual amounts may differ from college to university.
                                        </p>
                                
                                        <p style={{ color: "#212121" }}>
                                        <h5>Records</h5>
                                        These documents also Have to Be filed -
                                        <h6>Timeline</h6>
                                        Many Canadian schools accept online software. You'll need to go to each school's site to apply. 
                                        Typically, you'll need to make an account on the faculty website to give your basic information, 
                                        submit an application version of your files, and pay program fees. You'll be educated about the 
                                        application process and phases through this account.
                                        <br/>
                                        Please refer to the web site of the schools of your decision to be aware of the practice of employing 
                                        since it might vary from institute to institute.

                                        <br/>
                                        Application fee: All schools require that you pay a program fee whilst employing. The fee amount will fluctuate depending on the faculty 
                                        and class being employed to, so check with individual schools about their program fee.
                                        <br/>
                                        Measures: The most common Actions to applying for entry are as follows -
                                        </p>
                                        <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Look for schools and classes.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Contact colleges and see sites for advice.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Narrow your listing of colleges.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Afford the entry exams like SAT, GMAT, GRE, TOEFL, IELTS.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Write SOPs and Request LORs.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Apply to the schools that match your interests.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Look for video interviews of those schools that shortlisted you.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;When approved, apply for a student visa. 
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Read in Depth Research in Canada Timeline. 
                                        </li>
                                      </ul>{" "}
                                      <p style={{ color: "#212121" }}>
                                      It's always composed in the first person and explains the basis for
                                       applying to a specific college. It ought to highlight why you're an 
                                       ideal match for your faculty and why the faculty should take you.
                                        The style of writing may vary from formal to casual, however it's 
                                        necessary to keep in mind that it must reflect your character too.
                                      </p>

                                      <p style={{ color: "#212121" }}>
                                      Essay: they're also needed to be filed by a potential pupil. Essays are an significant part the 
                                      university admissions process. Pupils might have to write a couple of essays, together with a couple optional essays also.
                                      The third party may be a professor, guide supervisor, etc.<br/>
                                      The significant ingestion season for the best classes in Canada is currently September, although the 
                                      small ingestion season is January, which can be intended to get a rather few of classes.<br/>
                                      You should begin your admission procedure around six months prior to the program deadline. It's all up 
                                      to the ease of the pupils, which deadline to target for. You ought to be achieved with your speech and 
                                      capability evaluations by three months prior to the deadline. The previous 3 months must be devoted to 
                                      completing the application form correctly.
                                      <br/>
                                      It's imperative to make certain that your'complete application procedure' along with looking for interviews 
                                      and visa application processes should be complete by June or July for the September intake.
                                      <br/>
                                      The principal ingestion season for top schools and classes from the autumn season i.e. September. It depends 
                                      on what class you're searching to pursue since most lessons do not possess the January intakes.
                                      <br/>
                                      If you're searching to have entry into postsecondary courses, then a few classes might have admissions available 
                                      in January and maybe even May or July.
                                      </p>

                                      <p style={{ color: "#212121" }}>
                                          <h6>What's the lifetime of Indian pupils in Canada?</h6>
                                          Many Indians choose to reside at bigger urban centers such as Toronto, and Vancouver, where over 70 
                                          percent of Indians reside. An area called Little India is present in Vancouver plus a part of Gerrard 
                                          Street (Toronto) at Toronto too. Indians in Vancouver mostly reside in the suburb of Surrey, or neighboring 
                                          Abbotsford, and in different parts of Vancouver.
                                          <br/>
                                          Indian pupils are one of the largest group of international students who obtained study licenses in 2019. 
                                          With 139,740 Indian pupils were issued research licenses during this period compared to 84,710 from China.
                                          <br/>
                                          And if you're thinking about applying to a postgraduate program, the price would be CAD 15,000 - 55,000 annually roughly.
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                          <h6>Living expenditures</h6>
                                          Generally, a student spends roughly CAD 10,000 - 12,000 each year. However, the costs also count on the 
                                          area where a pupil remains. If a student remains in a metropolitan area such as Toronto or Vancouver, 
                                          the costs can go around CAD 15,000 compared to a student researching at the suburbs at which the expenses 
                                          could be approximately CAD 8,000 - 10,000 annually.
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                          <h6>Medical insurance</h6>
                                          insurance plan for global students is compulsory, as pupils must remain abroad for 
                                          a lengthy time period. Before obtaining an insurance policy ensure it covers medical 
                                          costs, personal injury, dental therapy, research disruption, passport reduction, etc.. 
                                          Insurance is essentially cashless so a pupil doesn't have to pay money in the period of hospitalization.
                                          <br/>
                                          Candidate with strong academics, fantastic performance in standardized tests, and extracurricular accomplishments 
                                          would qualify for scholarship awards and financial aid. To profit from such opportunities, one needs to be certain 
                                          that you send all the essential files by specific deadlines. Along with this, the demonstration of this program can 
                                          also be significant because one is judged with the image one projects.
                                          <br/>
                                          Documents required: The files usually needed to get a scholarship program are as follows, Even Though the requirements may vary:
                                          <br/>
                                          The overall eligibility criteria which are followed by each of the banks are:
                                    </p>
                                    <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;You ought to be an Indian national.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;You need to have a solid academic record.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;you have to be seeking entry to a professional, technical or a different course of research. Most banks assert the chosen course ought to be job oriented..
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;You have to have secured admission to overseas University Institutions.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;You have to be over age 18 decades or your parents could avail your loan..
                                        </li>
                                      </ul>{" "}
                                        <br/>
                                        </div>
):<></>}

{showData=='UK'?(  
  <>  
  <p style={{ color: "#212121" }}>
     <h4>UK:</h4>
                                        It's also the first and obvious selection for students which are trying to get an instruction taught in English. 
                                        International students in the Uk come in over 200 nations, with the majority coming from China, India, Nigeria, 
                                        Malaysia and America.<br/>

                                        The UK is home to a number of the most well-known Universities in the world, for example Cambridge University 
                                        and Oxford University and has a top reputation for world class research. Therefore, it comes as no surprise 
                                        that high education qualifications in the united kingdom are recognised globally. Given that the UK is currently 
                                        home to a very diverse group of races and cultures it makes it very simple for pupils from far afield to research 
                                        there and incorporate into everyday life.<br/>

                                        The UK provides an extremely broad assortment of cuisine and is well known for an extremely complete public transportation, 
                                        which makes it a suitable place to research as an global student. Another appealing element of studying in the united kingdom 
                                        is that the numerous clubs and societies in the world.

                                    </p>
                                    <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Faculties in the Uk.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Business Schools in the Uk.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Tuition Fees in the Uk.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Scholarships to Study at The Uk.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Prices of living in the Uk.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Internships & Company Placements at the Uk
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Working at the Uk.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Applying for a Student Visa to Study at the United Kingdom.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Faculties in the Uk.
                                        </li>
                                      </ul>{" "}

                                      <p style={{ color: "#212121" }}>
                                      The UK is home to over 140 universities, and many of that rank highly in global rankings with 
                                      heaps of these generally including at the top 100. If we include also Colleges and company Faculties, 
                                      then the UK is currently home to almost 400 higher education associations.
                                      <br/>

                                      To be able to apply to some University or higher education program in the united kingdom, 
                                      pupils should process a UCAS program.
                                      <br/>
                                      A few of the most notable Colleges in the United Kingdom include:
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                        <h6>Scholarships to Study at the Uk</h6>
                                        There's a wide Assortment of scholarships for Global students to study in the United Kingdom. 
                                        Some scholarships are financed by the united kingdom authorities, while some are directly 
                                        supplied from the institution, a hope or some particular University. Cases of scholarships, 
                                        grants and funding comprise:
                                        <br/>
                                        The Cambridge Trust gives scholarships to research in the University of Cambridge.
                                        The British Chevening Scholarships is geared toward international students who attempt to pursue fulltime graduate applications.
                                        Marshall Scholarships are for US students which are taking a look at pursuing a postgraduate degree in the 
                                        united kingdom and given on merit, leadership capacity and ambassadorial possible.
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                        <h6>Costs of residing in the uk</h6>
                                        the price of living in the united kingdom varies based on the country and area however as a ball park 
                                        figure, we suggest that you compute with living expenses of about #7,000 - #9,000 each year.
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                        <h6>Internships & Company Placements at the Uk</h6>
                                        Many Faculties in the UK to supply pupils with the opportunity to gain work experience during their research. 
                                        Some work placements might even be compulsory. The same as in different nations, internships or business placements 
                                        in the united kingdom could be paid or unpaid. Placement UK, lists a number of business placements on their site.
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                        <h6>Working at the uk</h6>
                                        Pupils who hold a legal Tier 4 visa supervised by a higher education institution and therefore are analyzing full-time
                                        may elect to work together with their studies. The especially permitted functioning conditions are often detailed in 
                                        your visa decal or Biometric Residence Permit (BRP).
                                        <br/>
                                        To be qualified for the program you'll have to have been given a place on a program, you need to have the ability to 
                                        show your fluency in the English language (speak, write and read ) and also have sufficient cash to prove you're in a 
                                        position to pay tuition and living expenses.
                                        <br/>
                                        The expense of applying for a UK student loan is now #348. For your most up-to-date info and tips on submitting an 
                                        application for a student visa to study at the UK we suggest that you see the British Government's student visa site.
                                    </p>
</>
):<></>}

{showData=='AUSTRALIA'?(    
  <>
  <p style={{ color: "#212121" }}>
  <h4>Australia:</h4>
                               Australia is currently home to both private and public institutions for higher education, the majority of which sponsor international students from countries all around the world. Australia's unique landscapes, contemporary cities, and varied population are highly appealing to a lot of students considering studying overseas.
                               <br/>
                               Pupils attend the country's many universities and colleges to make undergraduate bachelor degrees or to make master's and PhD levels through rigorous graduate research.
                               <br/>
                               Now, there are approximately 1,313,776 pupils registered in Australia's associations for higher education. Recent statistics demonstrate that global student enrollment is up by 12 percent, a rise that's prompting several Colleges and Faculties in Australia to boost their offerings and programs to serve this expanding student population.
                               <br/>
                               Almost 80 percent of Australia's population speak English; this brings many foreign students who wish to immerse themselves within an English-speaking nation so as to sharpen their particular language abilities.
                               <br/>
                               Additionally, a number of Australia's Universities and Colleges provide a wide selection of coursework which appeals to a varied variety of students appearing to enrol in English, engineering, education, medicine, and other popular areas of research.
                               <br/>
                               Each nation is home to one primary university campus. Additionally, you will find 99 schools which also host international students. Australia's oldest college is the University of Sydney, that was set in 1850. Some of the Nation's important and best-respected universities comprise:
                               <br/>
                               Australia features may highly respected business schools which comprise:
                               <br/>
                               These, also, are particularly appealing to a lot of study-abroad-students.
                               <br/>
                               Tuition prices in Australia do fluctuate widely. International students can expect to cover on average-AU$30,840 (19,400 EUR) annually for undergraduate coursework and marginally more (AU$31,596) to get graduate-level coursework. These figures don't reflect high-value applications like medication and veterinary levels. A number of the country's university's and school's post graduate costs on their sites together with other related fees.
                               </p>

                               <p style={{ color: "#212121" }}>
                               <h6>Scholarships in Australia</h6>
                               Australia includes scholarships and fellowships for global students. Most receivers are inclined to be focusing on advanced levels, nevertheless. The government provides the Australia awards to international students from developing nations; those monetary awards sum to $305 million. Most Australian universities and schools offer their own scholarships and provide applications which are available to both national and global students. Their websites offer information regarding application deadlines and rules.

                               </p>

                               <p style={{ color: "#212121" }}>
                               <h6>Price of Living in Australia</h6>
                               International students should prepare yourself to possess AU$20,290 (12,800 EUR) to reside to get a year of research in Australia. Pupils who bring together a spouse or children will require extra funds to pay for cost-of-living expenses. The above figure is that the government standard, but a lot of pupils can live on much less throughout their stay.

                               </p>

                               <p style={{ color: "#212121" }}>
                               <h6>Internships & Company Placements at Australia</h6>
                               Lots of Australian Universities and Faculties boast internship programs for students. Additionally, there are Australian-based companies that assist domestic and worldwide students locate internships around the nation. These internships are intended to help pupils gain real-world work experience or to enhance their professional advancement.

                               </p>

                               <p style={{ color: "#212121" }}>
                               <h6>Working in Australia</h6>
                               to be able to operate in Australia, international students should have a student visa which includes work approval. Present law doesn't require international students to pay additional for their job permission visa. Under this visa, international students are permitted to work around 20 hours each week.
                               <br/>
                               Prospective students should examine that price since fees can change from 1 year to another. Students are able to apply for a student visa by post, email, or even online. The procedure differs slightly for each. In all instances, prospective students need to meet standards which include: Genuine Temporary Entrant necessity, fiscal demands, English competency requirement, and health and character requirements. More details concerning the student visa application procedure can be seen in the Australian Department of Home Affairs site.

                               </p>
</>
):<></>}

{showData=='IRELAND'?(
  <>    
 <p style={{ color: "#212121" }}>
 <h4>Ireland:</h4>
                                  Its capital and largest city is Dublin.
                                  <br/>
                                  There are lots of explanations. To begin with, it's a friendly state; actually, it was called the friendliest nation by Lonely Planet in 2010 and has been rated as the 13th most tranquil area from the International Peace Index at 2014.
                                  <br/>
                                  Secondly, it has several applications for overseas students. Third, individuals speak English. And even more significant, it's dedicated to providing a fantastic educational system. In reality, in 2011, it had been established the National Strategy for Higher Education that attempts to alter this business to supply a more varied and meaningful experience which joins students efficiently with labour field requirements.
                                  <br/>
                                  These provide a vast selection of classes to choose from Colleges to Doctorate levels. Every of those universities has an entrance criterion for overseas a pupil which depends upon English proficiency and the federal exam.
                                  
                              </p>

                              <p style={{ color: "#212121" }}>
                                  <h6>The Faculties are:</h6>
                                  By way of instance, if you're a Non-EU student considering an undergraduate program the fee may go from $9,850 to $55,000 on apps more costly like health & medicine sciences. Moreover, tuition prices can vary, which means you need to consult the University that you opt for the present price.
                                  <br/>
                                  On the flip side, if you're an EU student, then you may qualify for free charges unless you're repeating a session or following a second undergraduate class. Within this scenario, you'll need to pay a donation of $3,000 each year.
                                      The selection criteria rely upon those associations.
                                      <br/>
                                      It boosts education overseas and also the exchange of expertise and knowledge. With this strategy, you might have a complete level pupil or even a Master's Degree Loan.
                                      <br/>
                                      Moreover, the Irish authorities supplies Ireland's Fellowship Program. They supply numerous classes to students from developing nations. To know if your state is eligible for a number of those classes, please assess Ireland's Fellowship Program official website (https://www.irishaidfellowships.ie/)
                                  <br/>
                                  To learn more about scholarships, check out the HEA site (https://hea.ie/funding-governance-performance/funding/student-finance/other-finance-bursaries-scholarships/). But if you would like to help to some particular University, you are able to look on the scholarships available and the standards to register in it on its own official page.

                              </p>

                              <p style={{ color: "#212121" }}>
                                  <h6>Price of Living in Ireland</h6>
                                  It is crucial that you calculate your prices before beginning the visa or college procedure.
                                   Even in the event that you buy a scholarship, then there'll be additional expenses. Your financial 
                                   plan will depend on the way you live and the town you select. And, should you require a visa, you'll
                                    have to prove you've got the quantity of money required to research in Ireland.
                                  <br/>
                                  Between those expenses monthly, you want to think about books and course materials, $70; meals, $171,
                                   traveling, $135, as well as many others.
                                  <br/>
                                  In lodging conditions, this institute computes you'll need $427 monthly. Even though there are plenty 
                                  of choices, you might select on-campus, rented accommodation or a sponsor family. The first choice, on-campus,
                                   is dependent on accessibility and it is a great deal more expensive. A leased location requires you to cover 
                                   a month beforehand plus a deposit of one month. Remember you have to follow along with the duration of this
                                    lease and provide a month of note to depart and regain your deposit cash. And lastly, you might reside with 
                                    a host family that provides you freedom but at precisely the exact same time the relaxation of a house and 
                                    the aid of your hosts.
                                  <br/>
                                  On the flip side, if you are not an EU student, you will need health insurance that's also necessary for the
                                   visa program. There are a range of insurance companies you may select from, as an instance, Irish Life Health,
                                    VHI health care, and many others.

                              </p>

                              <p style={{ color: "#212121" }}>
                                  <h6>Internships & Company Placements at Ireland</h6>
                                  students may take internships as part of this app they register in. This internship can continue
                                   half of the period of this program period. If you're in a 4-year livelihood, then you're permitted 
                                   to have a 2-year internship. The University or Institute is responsible for setting this internship
                                    and reassuring it is significant to this program that the student is carrying. For the remainder of
                                     the calendar year, they're permitted to work for 20 hours each week.
                                  <br/>
                                  On the flip side, Ireland prompts overseas students who have studied in one of Ireland's 
                                  applications to continue their livelihood in the nation. That is why you've got many chances 
                                  to remain in the nation. An educational facility will be able to help you to find an area at 
                                  a business regarding the career you've selected.
                              </p>

                              <p style={{ color: "#212121" }}>
                                  <h6>Working in Ireland</h6>
                                  you may opt to remain in the county once you complete your research to try to find employment.
                                   In fact, 51 percent of pupils who get a greater & post quality diploma are functioning in Ireland, 
                                   and 37 percent of honor bachelor level finds a job in the nation.
                                  <br/>
                                  You'll have to read carefully the prerequisites along with the fee. You can locate 
                                  this information in the official website http://www.inis.gov.ie/en/INIS/Pages/Irish%20Visa%20Information
                                  <br/>
                                  After comprehending the entire procedure and having the prerequisites, you can produce 
                                  your program in https://www.visas.inis.gov.ie/AVATS/OnlineHome.aspx

                              </p>
                              <br/>
                              </>
):<></>}

{showData=='GERMANY'?(    
  <>
 <p style={{ color: "#212121" }}>
 <h4>Germany :</h4>
                                     Add to the fact that the vast majority of Faculties in Germany are free, and you've got a top analysis destination.
                                     <br/>
                                     In 2017, roughly 375,000 global students attended Universities in Germany.
                                     <br/>
                                     Of those around 400 are people Universities and more than 100 are personal. The Colleges in Germany are divided into four classes: Research Faculties (Universität) centered on academic function. Technical Faculties (Technische Universität) that tend to concentrate on engineering and technology. Finally, you will find the Faculties of Art, Film and Music that concentrate on the creative areas like Fashion, Dance and Fine Arts, Amongst Others. Some of the most notable institutions comprise:
                                     <br/>
                                     As of 2019, you will find more than 1,000 applications in Germany which are educated in English, such as Bachelor degrees and Master levels.
                                     <br/>
                                     <h6>Tuition Fees in Germany</h6>
                                     Many people Universities in Germany are tuition-free and just want a tiny administrative fee of 250 EUR per session, which in some instances even includes transportation. The sole exceptions are the Colleges in the State of Baden-Wuerttemberg, that have introduced a charge of 1,500 EUR per session for non-EU taxpayers.
                                     <br/>
                                     Costs at private schools that are independent and Business Schools vary considerably and may cost anywhere from 5,000 EUR into 30,000 EUR for postgraduate or undergraduate studies.
                                     For eligibility requirements and program requirements you're advised to talk to your house University.
                                     <br/>
                                     The DAAD also publishes a number of scholarships and also for overseas students on their site, so check it out to see whether you're entitled.
                                     <br/>
                                     This ought to cover your rent for lodging, food in addition to clothing and fundamental leisure expenses like cultural events.
                                     <br/>
                                     Of course as with numerous EU destinations, living costs in larger cities like Berlin, Hamburg or Munich are likely to be greater than in cities like Leipzig, for instance.
                                 </p>

                                 <p style={{ color: "#212121" }}>
                                     <h6>Internships & Company Placements in Germany</h6>
                                     Internships in Germany are not just popular but occasionally additionally an integral part of a research program. International students studying in Germany can use for an internship through associations like IASTE or even AIESEC. Another popular way to locate a business positioning as you research in Germany would be to speak to the Career Office in the University. Other interesting resources comprise MeinPraktikum.de or Graduate Property.

                                 </p>

                                 <p style={{ color: "#212121" }}>
                                     <h6>Working in Germany</h6>
                                     International students residing in Germany are allowed to lawfully work together with their studies. The typical number of hours a student can perform is generally 20 hours each week. Australian students are invited to seek out the Federal Government even has a site devoted to directing them.
                                     <br/>
                                     A residence permit nevertheless, is demanded. Non-EU foreign students on the other hand are needed to submit an application for a student visa in addition to a residence permit.
                                     <br/>
                                     So as to apply for a visa, you'll have to supply that the German Embassy or Consulate in your house state with an evidence of entrance for an educational institution, a certification confirming health insurance policy, evidence of funds to pay your stay during your period of research in addition to verification of having procured accommodation. A proof of no criminal record may also be demanded.
                                     <br/>
                                     Upon arrival in the nation and over the first 90 days you'll be asked to submit an application for a residence permit at the Foreigner's Registration Office at the town where you will live. You'll be given a two-year house license which may be renewed if needed.

                                 </p>
                                 <br/>
                                 </>
):<></>}

{showData=='FRANCE'?(    
  <>
 <p style={{ color: "#212121" }}>
 <h4>France:</h4>
                                    France is frequently recorded in the top 5 international destinations for global students. With quite low tuition fees and more than 1,000 English-taught programs it's no surprise that France is popular. There's an extensive number of cultural riches and societal diversity in France, together with innumerable monuments and museums like the Eiffel Tower or the Louvre Palace, both a must-see for anybody visiting France.
                                    <ul style={{ marginLeft: 20 }}>
                                <li>
                                  <i class="fa fa-check-circle"></i>
                                  &nbsp;Non-EU Student Tuition Fees.
                                </li>
                                <li>
                                  <i class="fa fa-check-circle"></i>
                                  &nbsp;Certification programs: 2,770 EUR per instructional.
                                </li>
                                <li>
                                  <i class="fa fa-check-circle"></i>
                                  &nbsp;Master plans: 3,770 EUR per academic year.
                                </li>
                                <li>
                                  <i class="fa fa-check-circle"></i>
                                  &nbsp;Master plans: 3,770 EUR per academic year.
                                </li>
                              </ul>{" "}
                              Tuition fees at private higher education institutions vary from 3,000 EUR into 20,000 EUR annually. Consult with the University of Business School of your choice to validate the tuition fees of this program you're interested in.

                                    </p>
                                    <p style={{ color: "#212121" }}>
                                    <h6>Scholarships to Study in France</h6>
                                    Additionally, in France, scholarships are widespread and accessible from many different institutions to many different people based on chosen criteria. The French Ministry of Higher education and research, as an instance, provides scholarship aid for global students, according to a societal norm.
                                    <br/>
                                    For a pupil from the European Union, you could also be qualified to apply for an Erasmus + pupil assistance.
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                    <h6>Prices of living in France</h6>
                                    the price of living in France ranges from 800 / 1,800 EUR a month, including pupil lodging. Cities like Paris are in the end of this spectrum, whereas Lyon, Nantes or even Toulouse are in the lower end of this spectrum.

                                    </p>

                                    <p style={{ color: "#212121" }}>
                                    <h6>Internships & Company Placements in France</h6>
                                    France isn't merely a favorite destination for both tourists and global students who wish to study overseas but also for young people seeking to acquire work experience through an internship or business placement called a point in French.
                                    <br/>
                                    If you're a student in France, you might first wish to contact the coordinator of your program, who could have the ability to help in finding you a suitable business positioning. You might even try your luck on the internet to locate an internship based on the area or the subject of study. Some of the very common French sites which list internships, comprise AdioStage and Stage.fr.
                                    Though you may be lucky to obtain an English-speaking business that is going to employ you, it's wise and advisable to be able to speak French to a conversational level.
                                    <h6>Working at France</h6>   
                                    As a foreign student, you're qualified to work part-time to get as many as 20 hours each week.                                        
                                    </p>
                                    </>
):<></>}

{showData=='SWEDEN'?(    
  <>
  <p style={{ color: "#212121" }}>
               <h4>Sweden:</h4>
                                                Researching in Sweden means you'll be located in the north of Europe and subjected to a multicultural society that's proven to be more welcoming.
                                                Sweden is frequently known as the funding of creation of Europe, together with several large brands and world-famous family titles coming out of Sweden, such as IKEA, H&M among many others.
                                                <br/>
                                                A listing of Colleges in Sweden would not be complete without the following associations:
                                                <br/>
                                                English educated programs comprise both Bachelor applications and Master applications.
                                            </p>

                                            <p style={{ color: "#212121" }}>
                                                <h6>Tuition Fees in Sweden</h6>
                                                The most appealing characteristic of studying in Sweden is the fact that it may be liberated. We state it could be, since not every pupil can make the most of this tuition-free system in Swedish Universities.
                                                <br/>
                                                Since 2011, pupils from beyond those EU/EEA and Switzerland must pay.
                                                <br/>

                                            </p>

                                            <p style={{ color: "#212121" }}>
                                                <h6>Scholarships to Study in Sweden</h6>
                                                The Swedish Institute details an Assortment of scholarship opportunities categorized by the area of research in Addition to the viability of the pupil. The Erasmus traineeship mobility programme is just another organization that helps pupils in Sweden to find internships or business placements.

                                                <br/>
                                                
                                            </p>

                                            <p style={{ color: "#212121" }}>
                                                <h6>Prices of living in Sweden</h6>
                                                Average living costs in Sweden are approximately 8,000 SEK (800 EUR) per month, such as leasing on lodging. Obviously, this may change based upon the place, as living prices from the capital of Sweden, Stockholm, are greater than in smaller towns.

                                                <br/>
                                            </p>

                                            <p style={{ color: "#212121" }}>
                                                <h6>Internships & Company Placements at Sweden</h6>
                                                Locating an internship in Sweden (called a praktik at Swedish) is often best when eased directly through the careers service office in your University.

                                                <br/>
                                            </p>

                                            <p style={{ color: "#212121" }}>
                                                <h6>Working in Sweden</h6>
                                                As an global student you'll be legally eligible to look for work as you study. Unlike in other European states, there's also no legal restriction on the amount of hours you'll be permitted to operate in Sweden, as you're studying.
                                                <br/>
                                                Being fluent in the language is surely a major plus when searching for work in Sweden but it might not be a comprehensive barrier if Swedish isn't your forte.
                                                <br/>
                                                There are an infinite number of foreign businesses based in Sweden that highly appreciate other languages and might even appreciate your native language as a valuable advantage.
                                                <br/>
                                                To acquire this residence permit, you may ned to show that you can finance your research and life in Sweden. Besides this you'll have to demonstrate a valid passport, be approved as a full-time pupil at an institution of high education and reveal that you hold a valid insurance coverage.

                                                <br/>
                                            </p>
                                            </>
):<></>}

{showData=='NETHERLAND'?(    
  <>
 <p style={{ color: "#212121" }}>
 <h4>Netherlands or Holland</h4>
                                The Kingdom of the Netherlands, frequently Known as Holland is a small state in the northwest of Europe. The fact that approximately 95 percent of the Dutch people speak English, which makes it an amazing destination for global students.
                                <br/>
                                Holland is regarded as among the safest areas on earth and ranks at the top 10 funniest states also.Holland has many high-ranking Universities. These include:
                                <br/>
                                Additionally, there Are numerous Small Business Schools in the Netherlands, such as:
                                <br/>
                                There are many Universities in Holland offering programs taught entirely in English. As of 2019, you will find only over 2,100 applications at Bachelor, Master and PhD degree that follow instruction in English.
                                <br/>
                                Webster University is currently the sole American University in Holland. This is a personal US accredited University that enables students to pursue a Master or Bachelor Program in English.
                                <br/>
                                Tuition fees are very fair in Holland. Students from the European Union, Switzerland or Surinam pay only over 2,000 EUR per year. Non-EU pupils pay between 6,000 EUR and 15,000 EUR annually to get a Bachelor program or 8,000 EUR to 20,000 EUR annually for a Master program. Precise tuition prices for a particular program are located on the official site StudyFinder.nl.
                                <br/>
                                Detecting a scholarship to study in Holland is made simple by a government initiative which lists dozens of upgraded scholarship and grant opportunities in their site .
                            </p>

                            <p style={{ color: "#212121" }}>
                                <h6>Prices of living in Holland</h6>
                                The price of living in Holland averages between 600 - 800 EUR a month. Add to that about 200 EUR a month for tuition prices and you've got an average budget of 800 - 1,000 EUR a month to pay all of your expenses. An area prices anywhere between 300 - 600 EUR a month. Groceries only under 200 EUR and entertainment or clothes are insured by approximately 100 EUR a month.

                            </p>

                            <p style={{ color: "#212121" }}>
                                <h6>Internships & Company Placements at Holland</h6>
                                An internship in the Netherlands is popularly called a"stage". Internships from the Netherlands are just allowed for applicants registered at an education institution for a student. Most colleges and Faculties assist students with placements in companies in Holland.
                                <br/>
                                Please note that there are gaps in work license, internship remuneration and social security deductions which will depend on in the event that you're pursuing an internship as part of a research program or research abroad application.
                                <br/>
                                If you're applying for an internship as part of a research program in Holland, you may be a resident in Holland, so there's absolutely not any requirement for any extra license. Additionally, there are no limitations on payment, but you'll be asked to pay tax as well as your employer should include social security contributions, unless the payment is just a reimbursement for state travel expenses.
                                <br/>
                                If you're a participating in a study abroad program, it's still possible to perform an internship at Holland, but you have to be aware of particular specifics: Unless you're an EU/EEC or national, along with your internship is going to be 90 days or less then your employer will be asked to submit an application for a work permit on your behalf. If your internship will survive over 90 days, you may require a residence permit. If your stay at Holland is less than 6 weeks and you're spending at least 50 percent of the time around the internship, and then you could be compensated tax-free. Those staying more than 90 days will often have a part of their wages deducted as taxation.
                                <br/>
                                For more interesting strategies and suggestions on regulations concerning internships from Holland, please see the Work placement business and interns segment on the Netherlands Enterprise Agency site.
                                <br/>                                                
                            </p>

                            <p style={{ color: "#212121" }}>
                                <h6>Working in Holland</h6>
                                International students in Holland are lawfully eligible to work while they study in the nation. Non-EU or non-EEA pupil will take a work permit. An employer can ask for that for your benefit. This will let you work 10 hours each week throughout the academic year; throughout June, July and August you'll be permitted to operate fulltime.
                                <br/>
                                Whether you require a student visa or to not research in Holland depends upon your native state. For EU citizens and taxpayers in Australia, Canada, Japan, Monaco, New Zealand, South Korea, the US or even the Vatican City State, There Is Absolutely No requirement to get a student visa. Other nationals are expected to submit an application for a student visa.
                                <br/>
                                The University or higher education institution in which you want to research will ask information from the Dutch Immigration Service (IND) with respect to a MVV program. If the information is positive, then you are able to apply to your true MVV in the Dutch Embassy or Consulate into your home country.
                                <br/>
                                Upon entrance into the Netherlands, pupils are expected to enroll at the local immigration authority office in addition to the municipality where they intend to live. In the end, a pupil must then make an application for a residence permit (VRR). Even though the VRR is valid for the length of your research, you'll have to make at least 50 percent of the credits needed per year.
                                <br/>
                                To be able to apply for a student loan, you'll require a valid passport in addition to evidence of funds to prove you could support yourself throughout your stay. A letter confirming approval at an institution of high education and legitimate medical insurance will also be required.

                            </p>

                            <br/>
                            </>
):<></>}
                                    </div>
                                  </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                              </div>           
                                                       </div>
                            </div>
                                    </div>
                              </div>
                                    </div>
                                    </div>
                                    </div>
                                    </div>
                                    </div>
      <Footer />
    </Container>
  );
}
