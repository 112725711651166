/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function TOEFL(props) {
  const classes = useStyles();

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 25,
                  marginTop: 30,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">TOEFL</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="blog-area single full-blog left-sidebar full-blog default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="container"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <div className="row">
                    <div className="blog-items" style={{ width: "100%" }}>
                      <div className="blog-content col-md-12">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      //   marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      <h6>What's the TOEFL® Exam?</h6>
                                      TOEFL is among the hottest English
                                      proficiency tests accepted TOEFL tests
                                      global students' use and comprehension of
                                      English as it's spoken, written and heard
                                      in college and university environment. ETS
                                      (Educational Testing Services) is the
                                      running body of the TOEFL test and can be
                                      in charge of placing the TOEFL questions,
                                      running the evaluation, and sending every
                                      examinee their scorecard. Students looking
                                      to study overseas, can select from several
                                      TOEFL Test specimens accessible
                                      round-the-year and choose from the a
                                      variety of test centers located across
                                      major cities or examine liberally at home
                                      together with the TOEFL iBT® Particular
                                      Home Edition test. Candidates appearing to
                                      look for their TOEFL examination is going
                                      to learn about the subsequent TOEFL
                                      subjects as they read together.
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; TOEFL Qualification
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; TOEFL Registration
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; TOEFL Exam Pattern
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; TOEFL Exam Syllabus
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; TOEFL Preparation
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; TOEFL Practice Evaluation
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Which are TOEFL Scores?
                                        </li>
                                      </ul>
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      TOEFL is largely undertaken by students
                                      That Are planning to Pursue higher
                                      education overseas. Seeking TOEFL would
                                      also allow students to be eligible for
                                      many scholarships and help students and
                                      applicants searching for employment
                                      overseas with their visa application
                                      procedure. Founded in over 11,000
                                      institutions and universities globally,
                                      such as universities in Australia, Canada,
                                      New Zealand, the united kingdom, the USA,
                                      and around Europe and Asia, TOEFL is among
                                      the most favored English-language
                                      evaluations for pupils wanting to study
                                      overseas.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      Students looking to study overseas and
                                      picking for TOEFL comes With many added
                                      benefits. TOEFL is a much more favored
                                      exam among students that wish to pursue
                                      higher education overseas, as more TOEFL
                                      scores are sent to universities from the
                                      USA and Canada than other English-language
                                      tests united. In the same way, TOEFL is a
                                      much more favorite English evaluation
                                      among nations as more TOEFL scores have
                                      been delivered to German, French, US, and
                                      Canadian universities compared to other
                                      English tests. ETS - the running body of
                                      this TOEFL also ensures students a fair
                                      and unbiased procedure for score
                                      reporting, which makes the exam more
                                      authentic in character.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      Registration for TOEFL can be obtained 24
                                      hours a day, seven days per week week.
                                      Candidates may register for their TOEFL
                                      assessment via the internet method, via
                                      telephone or via the email or in-person
                                      (through a licensed agent). Candidates
                                      must bear in mind the TOEFL online
                                      registrations close seven days before the
                                      examination and late enrollment closes 4
                                      days ahead of the evaluation date with a
                                      late fee of US$40. Candidates looking for
                                      their TOEFL out of India need to cover a
                                      TOEFL registration fee of US$185.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      ETS includes a series of webinars on its
                                      official Site that familiarizes the
                                      candidates for this new attribute. The
                                      home edition of the TOEFL test will be
                                      accessible to students until evaluation
                                      centers restart working in total capacity
                                      in the many nations. The TOEFL scores
                                      could be communicated to the student in
                                      6-10 days. Candidates must bear in mind
                                      that as a result of continuing outbreak
                                      delivery of paper score reports can be
                                      postponed.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      There's been several safety measures
                                      including Artificial Intelligence
                                      technologies and reside proctors which
                                      were embraced by ETS for its smooth
                                      performance of this test in the home.
                                      Accommodation for test-takers having a
                                      handicap can be accessible to students who
                                      need the exact same and on petition. No
                                      other significant changes are introduced
                                      with ETS for running the TOEFL test in
                                      your home. Candidates are asked to go to
                                      the official site for a thorough
                                      comprehension of exactly the exact same.
                                      At present, enrollment is available for
                                      dates in September 2020. ETS has also
                                      briefly waived off rescheduling fees for
                                      all of the test takers as a result of
                                      publication coronavirus pandemic.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        New TOEFL Exam Pattern in August 2019
                                      </h6>
                                      Of TOEFL has increased the length of this
                                      evaluation by 30 minutes beginning August
                                      1, 2019. The preceding period of the
                                      examination has been 3 hrs and 30 minutes,
                                      the present period of the examination will
                                      be 3 hours. ETS has introduced'MyBest
                                      score' - that will be essentially
                                      accepting the candidate's greatest scores
                                      for each segment from all legal TOEFL
                                      scores in the past 2 decades. Students may
                                      now reserve TOEFL test slots for day
                                      testing sessions, globally.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        Which are the Kinds of TOEFL Exam?
                                      </h6>
                                      The TOEFL iBT is a more favored manner of
                                      the test and represents greater than 98
                                      percent of the TOEFL tests provided
                                      globally, while the revised TOEFL
                                      Paper-delivered Exam is only accessible
                                      places which don't encourage the usage of
                                      the net. There's not any Discussing
                                      section due to the technical requirements
                                      of getting spoken answers.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6> What's TOEFL iBT?</h6>
                                      As the Name Implies the TOEFL
                                      Internet-Based Evaluation (TOEFL IBT) is
                                      the internet version of the TOEFL test for
                                      applicants appearing to look for their
                                      examination. It's the preferred medium of
                                      TOEFL owing to its simplicity of advantage
                                      and utilizes fewer resources.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        What's TOEFL Paper-delivered Exam?
                                      </h6>
                                      The revised TOEFL® Paper-delivered Exam
                                      was made accessible for places which can't
                                      support testing through the net. The
                                      newspaper evaluation was modeled closely
                                      using all the TOEFL iBT test and requires
                                      applicants to unite their communication
                                      abilities -- for instance, listening to a
                                      lecture, reading a passage, then composing
                                      a response. Candidates must take note that
                                      in this arrangement of the TOEFL
                                      examination, there's not any Discussing
                                      section due to the technical needs of
                                      getting spoken answers.
                                      <br />
                                      Students looking to study overseas are
                                      required to look for The TOEFL
                                      examination. There are a whole lot of
                                      students drifting about the TOEFL
                                      eligibility standards. Candidates could be
                                      relieved to know that ETS - that the
                                      running body of their TOEFL iBT hasn't
                                      given any age standards or instruction
                                      eligibility or requirements to look for
                                      your TOEFL test.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6>TOEFL Exam 2020 Charges</h6>
                                      The enrollment fee for the TOEFL
                                      examination varies from place to location.
                                      Aside from that, candidates also will need
                                      to pay extra for additional services such
                                      as late enrollment, rescheduling the
                                      examination or score inspection. The
                                      expense of various services are cited
                                      below.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        TOEFL Cancellation/Rescheduling Fee
                                      </h6>
                                      Candidates that have reserved an
                                      evaluation date and might want to Cancel
                                      or reschedule the exact same will have to
                                      do this 4 complete days beforehand before
                                      the actual exam date. Rescheduling of
                                      evaluations by mail or email or in the
                                      test centre isn't acceptable. Candidates
                                      would need to call their Regional
                                      Registration Centre (India -
                                      91-124-4147700) and supply them with their
                                      entire title and appointment variety to
                                      reschedule. Candidates may also log in
                                      their ETS account online and reschedule
                                      any forthcoming evaluations.
                                      <br />
                                      A Good Deal of instances, candidates Might
                                      Need to avail of particular Services
                                      supplied by the ETS. Candidates may have a
                                      look at the charges of particular handling
                                      requests cited in the table below. Special
                                      Requests Charges
                                      <br />
                                      TOEFL Rescheduling prices $60
                                      <br />
                                      Added TOEFL score reports (per association
                                      or service ) $20 per
                                      <br />
                                      Discussing and Writing Section score
                                      inspection 160
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6>TOEFL Payment Policies</h6>
                                      TOEFL has set down specific payment
                                      policies which the Candidates have to
                                      follow. These comprise, candidates need to
                                      cover the whole fee at the time that the
                                      service is asked, they ought to have the
                                      proper numerical sum and have any
                                      essential signature(s).
                                      <br />
                                      Types of Payment
                                      <br />
                                      Nation Wise TOEFL Charges
                                      <br />
                                      Nations throughout the world. Check out
                                      the TOEFL Fee to your nation, under:
                                      Nation Price USA $205 Australia $300 India
                                      $185 Germany $255
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6>How to Register to TOEFL</h6>
                                      Registration for TOEFL may be achieved via
                                      telephone, online, email, Or in person in
                                      the TOEFL 2020 test enrollment facility.
                                      An individual can enroll for the TOEFL
                                      exam during the year. There are numerous
                                      test centers out there for your TOEFL
                                      applicant. Before completing the program,
                                      an individual ought to pick the place of
                                      the examination. Kindly Note: Candidates
                                      may register online as many as two days
                                      prior to the exam, although a late fee
                                      applies beginning 7 days prior to the
                                      evaluation. Check Steps to Register on the
                                      internet.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6>TOEFL Online Application</h6>
                                      Nominees are required to see the TOEFL
                                      login page and Make an internet profile. .
                                      Online registration on the ETS site is the
                                      quickest and simplest method. Through the
                                      online process, you can enroll anytime and
                                      on daily. You'll have to pay using a
                                      credit/debit card.
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Go into the
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Fill all of the
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Make sure The information which
                                          that you supply is exactly the exact
                                          same as it is in your passport (Most
                                          significant, your title should match
                                          your ID exactly.)
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Proceed into Pay the program
                                          fee.
                                        </li>
                                      </ul>
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      Candidates will be required to go through
                                      the enrollment form Until they call. Spell
                                      your name properly and precisely as it
                                      appears in your passport. Telephone the
                                      Regional Registration Center with this
                                      amount - 91-124-4147700. Purchase a
                                      credit/debit card as guided. Test takers
                                      can enroll by telephone till 5 pm local
                                      test centre time on the company day prior
                                      to the test. A late charge (US$40)
                                      implements, beginning 7 days prior to the
                                      evaluation.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      Nominees are required to print and
                                      download the Registration form. Then
                                      complete the form.
                                      <br />
                                      Whenever You Intend to Provide the TOEFL
                                      examination, simply check the Proper TOEFL
                                      dates and TOEFL test centre to reserve
                                      your slot. People that are thinking about
                                      pursuing an global level, giving TOEFL
                                      should be in their priority list.
                                      Selecting dates is among the most
                                      essential variables since it's the first
                                      step in receiving into your college of
                                      choice. Additionally, it is dependent upon
                                      the college/university you're likely to
                                      employ, as some colleges have one deadline
                                      at which the candidates will need to
                                      submit their TOEFL score reports prior to
                                      the deadline. Additionally, there are
                                      schools that have rolling deadlines, so
                                      here applicants have the choice of
                                      submitting their TOEFL scores according to
                                      their convenience. So, according to your
                                      requirement, pick the TOEFL examination
                                      date along with the TOEFL exam center as
                                      part of your TOEFL Slot Booking throughout
                                      the enrollment. Along with this, the
                                      candidate also should have a valid
                                      passport for displaying as an identity
                                      evidence.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6>TOEFL Exam Dates</h6>
                                      Your goal for taking the TOEFL test ought
                                      to be at A month prior to your initial
                                      application deadline. This gives you lots
                                      of time to work in your own software, and
                                      in the event you want to retake the
                                      examination you are able to do this after
                                      three days of this initial effort.
                                      Therefore, in the event the deadline for
                                      the program is November, then you ought to
                                      take the examination by October. You are
                                      able to retake the TOEFL following three
                                      times post your very first effort. Watch
                                      city-wise TOEFL significant dates .
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      NOTE: Nominees must always check the
                                      access to their exam time and date in the
                                      closest centre and then reserve it
                                      accordingly. ETS provides three Distinct
                                      Sorts of testing lodging Solutions to
                                      assist its own test takers with medical
                                      needs and disabilities. The exam takers
                                      should satisfy with the ETS needs to be in
                                      a position to avail of those services.
                                      It's necessary to remember that test
                                      takers have to submit an application for
                                      lodging before enrolling for the exam.
                                      <br />
                                      Accommodations Supplied by ETS
                                      <br />
                                      <h5>
                                        Following types of testing lodging
                                        services in accordance with their
                                        documented demands:
                                      </h5>
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6>TOEFL Exam Pattern</h6>
                                      TOEFL examination pattern is as follows -
                                      four segments, namely, Each segment has a
                                      score selection of 0-30, to get a whole
                                      score assortment of 0-120. Scores will be
                                      submitted online 10 days following the
                                      exam date. Here we pay for the TOEFL iBT
                                      test routine.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6>TOEFL Syllabus</h6>
                                      The TOEFL iBT syllabus for pupils includes
                                      studying, Listening, writing, and speaking
                                      abilities.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6>TOEFL Reading Syllabus</h6>
                                      In this section, you may read 3-4 passages
                                      and reply 10 The part is scored depending
                                      on the amount of proper reading
                                      understanding answers.
                                      <br />
                                      Total no. Of questions: Around 30-40
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6>TOEFL Listening Syllabus</h6>
                                      From the Listening test segment, you'll
                                      hear lectures and Conversations, according
                                      to campus language. This section is aimed
                                      to comprehend the capacity of the test
                                      taker to comprehend lectures and
                                      conversations in English. It has listening
                                      to fundamental understanding and
                                      understanding, linking, and synthesizing
                                      information.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>TOEFL Speaking Syllabus</h6>
                                      For one to make the Greatest scores from
                                      the Talking Section, Your answers must
                                      meet the requirements of this job given
                                      with just minor errors or lapses. The
                                      evaluation graders are searching for an
                                      extremely intelligible and ongoing
                                      conversation. There are 3 chief facets
                                      which include scoring for the department,
                                      viz, delivery, speech usage, and subject
                                      development.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>TOEFL Writing Syllabus</h6>
                                      Both essays ought to efficiently handle a
                                      subject each. The Answer ought to be
                                      well-organized and well-developed using
                                      applicable explanations and thorough
                                      support. What's more, it also needs to
                                      display unity, development, and coherence.
                                      If you would like to accomplish a high
                                      writing score, then ensure you show the
                                      syntactic variety and proper word choice
                                      with minimal grammatical mistakes.
                                      <br />
                                      When You are done with your evaluation,
                                      you need not wait considerably for The
                                      TOEFL outcomes. Test takers can see their
                                      unofficial Reading and Listening scores in
                                      the conclusion of the test. Whenever your
                                      official scores are announced on the
                                      internet, you'll get an email intimation
                                      concerning it. Log into your TOEFL iBT
                                      accounts and click the score's
                                      alternative. The official TOEFL iBT score
                                      is going to be made available for you in
                                      about 6 days in the exam date and
                                      test-takers who choose the exceptional
                                      House Edition of the TOEFL test will get
                                      their outcomes from 6-10 days from the day
                                      of the exam. In case the candidate has
                                      requested a paper copy of the score before
                                      they took the exam, it is going to be sent
                                      within 11 days following the exam date.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>What's TOEFL Scores calculated?</h6>
                                      Scoring methods are utilized. This is
                                      achieved as a way to supply a full and
                                      accurate picture of the candidate's skill.
                                      While the automatic scoring process has
                                      the benefit of being impartial, it doesn't
                                      quantify the potency of the content and
                                      language. Individual raters are needed to
                                      attend a larger assortment of attributes,
                                      like the quality of ideas and material in
                                      addition to form. Additionally, you're
                                      expected to answer a minumum of one
                                      question every in most sections. For
                                      listening and reading, you need to answer
                                      a minumum of one question. For writing and
                                      talking, you must try 1 job each. For the
                                      internet TOEFL iBT test and the end result
                                      is four-scaled together with a entire
                                      score.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>What's a Fantastic TOEFL Score?</h6>
                                      There are no bad or good scores when
                                      looking for the TOEFL test. Candidates
                                      will be asked to satisfy with the TOEFL
                                      score determined from the universities.
                                      Anything above would boost their odds of
                                      getting entry into the college and any
                                      score under the range could function as a
                                      roadblock at the entry procedure.
                                      <br />
                                      But to create your evaluation experience
                                      more private and for You to know your
                                      score ETS has supplied 4 0r 5 competency
                                      levels for each ability. So where your
                                      score falls within that scope informs you
                                      your competence for this skill. This will
                                      provide you a reasonable idea about your
                                      operation, your own strengths, sections
                                      that require improvement, etc. Test takers
                                      are able to examine the table below to
                                      know that their TOEFL iBT Scores better.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>The way to get TOEFL Scores?</h6>
                                      Approximately 6 Days following your test
                                      date, then you'll get an email telling you
                                      your score outcome is available. All you
                                      need to do is log into your TOEFL accounts
                                      on the site and click on'see scores' to
                                      view your scores. As stated before, the
                                      official TOEFL iBT score is going to be
                                      made available for you in about 6 days in
                                      the exam date and test-takers who choose
                                      the exceptional Home Edition of the TOEFL
                                      test will get their outcomes from 6-10
                                      days from the exam date.
                                      <br />
                                      Report before looking for the exam, it'll
                                      be sent within 11 days following the exam
                                      date. PDF score reports are prepared for
                                      downloading within seven days after taking
                                      the exam.
                                      <br />
                                      Announcement of your outcome. If you
                                      aren't pleased with the scores in your
                                      first effort, you can try again since
                                      there's not any limit on the amount of
                                      retakes. But, there should be a 3-day gap
                                      between your retakes; i.e in the event
                                      that you've got a test appointment for
                                      now, you cannot enroll for another
                                      appointment for another 3 days.
                                    </p>
                                  </div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div className="col-md-12">
                                    <div
                                      className="content"
                                      style={{ paddingBottom: 0 }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area default-padding">
        <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="row" style={{ display: "block" }}>
            <div className="about-items">
              <div className="col-md-12">
                <div className="weekly-top-items">
                  <div className="top-courses" style={{ paddingRight: 0 }}>
                    <div className="heading" style={{ paddingBottom: 15 }}>
                      <h4 style={{ color: "#3f8ccb" }}>
                        ACCREDITATIONS AND MEMBERSHIPS <strong></strong>
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/EAIE.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/ICEF.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/NAFSA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/QISAN.jpeg"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIRC.gif"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIEA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
