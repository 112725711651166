/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    // margin: 'center',
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
  ul: {
    listStyleType: "disc",
    padding: "0px 0px 0px 35px",
    fontSize: 16,
    fontWeight: 400,
  },
  nonpermitted: {
    color: "#000",
    fontWeight: 400,
  },
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [messages, setMessages] = React.useState("");
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus, setStatus] = React.useState("");

  const handlePicture = (event) => {
    setPicturePath(URL.createObjectURL(event.target.files[0]));
    setPicture(event.target.files[0]);
  };

  const handleVerifyPassword = (e) => {
    setVerifyPassword(e.target.value);
    if (password === e.target.value) {
      setStatus("Password Matched");
    } else {
      setStatus("Password not matched");
    }
  };

  //   const addNewRecord=async()=>{
  // if(picture=='')
  // {
  //     var body={'firstName':firstName,
  //     'lastName':lastName,
  //     'emailId':emailId,
  //     'password':password,
  //     'status':"Not Approved",
  //     'picture':"Not Found"}
  //     var result=await postData('selfregister/addNewRecord',body)
  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}

  // }

  // else{
  //    var formData=new FormData()
  //    formData.append('firstName',firstName)
  //    formData.append('lastName',lastName)
  //    formData.append('emailId',emailId)
  //    formData.append('password',password)
  //    formData.append('picture',picture)
  //    formData.append('status', "Not Approve")

  //    var config={headers:{'content-type':'multipart/form-data'}}
  //     var result=await postDataAndImage('selfregister/addNewRecordPic',formData,config)

  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}
  //     }
  //     }

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 70,
                }}
              >
                {/* <div className="banner-area inc-form bg-gradient slider-less text-light" style={{backgroundImage: "url(" + "../assets/img/event/1.jpg" + ")", padding:70}}> */}
                <div className="container">
                  <div className="row">
                    {/* <div className="double-items">
                    <div className="col-md-7 info">
                        <h2 className="wow fadeInLeft">Find your ideal study program.</h2>
                        <a className="btn day btn-light border btn-md wow fadeInDown" style={{color:'#3f8ccb'}} href="#">View Courses</a>
                    </div>
                    <div className="col-md-5 reg-form">
                        <form action="#">
                            <div className="row">
<img style={{flex:1}} class="login_image" src="assets/img/elearning.png" alt="Login" data-original-title="" title="" />

                            </div>
                        </form>
                    </div>
                </div> */}
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">Affiliates</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="blog-area single full-blog left-sidebar full-blog default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="container"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <div className="row">
                    <div className="blog-items">
                      <div className="blog-content col-md-12">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  {/* <h4>Exclusive Courier services Give on Study Metro</h4> */}

                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <h5 style={{ color: "black" }}>
                                      Affiliate Networks
                                    </h5>
                                    <p style={{ color: "black" }}>
                                      Study Metro is the leading global study
                                      abroad search site providing instant
                                      online comparisons for university,
                                      programs, and country. With our study
                                      abroad affiliate programme, you can
                                      receive a revenue share starting at 70%.
                                      Join our community and start earning
                                      through your website.
                                    </p>

                                    <a
                                      href="https://studymetro.typeform.com/to/XSReTgjx"
                                      style={{ color: "blue" }}
                                    >
                                      Sign up
                                    </a>
                                    <br />
                                    <br />
                                    <h4>
                                      Why Study Metro’s affiliate program?
                                    </h4>
                                    <br />
                                    <ol className={classes.ul} style={{ listStyleType: "decimal",}}>
                                      <li style={{ color: "black" }}>
                                        Study Metro accepts click-outs from any
                                        country around the world without any
                                        geographic restrictions.
                                      </li>

                                      <li style={{ color: "black" }}>
                                        Study Metro offers a variable rate of
                                        commission from each university.{" "}
                                      </li>
                                      <li style={{ color: "black" }}>
                                        Study Metro also pays commission if
                                        students enrol for classes.
                                      </li>
                                      <li style={{ color: "black" }}>
                                        A click out to our partner’s site will
                                        be paid for even if a coupon code is
                                        used.{" "}
                                      </li>
                                      <li style={{ color: "black" }}>
                                        Affiliates can monetise traffic of users
                                        at all stages of their Study Abroad
                                        planning: from inspirational stage to
                                        effective time of Enrollment.
                                      </li>
                                      <li style={{ color: "black" }}>
                                        Study Metro has a choice to advertise in
                                        text links, banners or our smart search
                                        widgets with Study abroad insights
                                      </li>
                                    </ol>

                                    <h4>Who can use it?</h4>
                                    <h5>
                                      Permitted traffic sources and activities:
                                    </h5>
                                    <br />
                                    <ul className={classes.ul}>
                                      <li>Study Abroad websites</li>
                                      <li>Blogs</li>
                                      <li>Forumss</li>
                                      <li>Content platforms</li>
                                      <li>Social media groups</li>
                                      <li>Price comparison websites</li>
                                      <li>Deals websites</li>
                                      <li>Advertising networks</li>
                                      <li>Cashback websites</li>
                                      <li>
                                        Subnetworks (subject to transparency
                                        screening)
                                      </li>
                                    </ul>

                                    <br />

                                    <h5>
                                      Non Permitted traffic sources and
                                      activities:
                                    </h5>

                                    <ul className={classes.ul}>
                                      <li>SEM bidding</li>
                                      <li>Teaser networks</li>
                                      <li>Cookie stuffing</li>
                                      <li>Content platforms</li>
                                      <li>
                                        Disruptive Ad Formats (Pop-ups, etc.)
                                      </li>
                                      <li>Toolbars and browser extensions</li>
                                      <li>Auto-direct sites</li>
                                      <li>Domain buying websites</li>
                                    </ul>

                                    <br />
                                    <h6>
                                      <a
                                        href="https://studymetro.typeform.com/to/XSReTgjx"
                                      >
                                        Register Here
                                      </a>
                                    </h6>

                                    {/* <p,}}>
                    You can send your transcripts and files to Faculties in anyplace in overseas or WES for Credential Assessment from Study Metro

                    </p>
                    <p style={{color:'#212121',}}>
                    Applying for your MS or MBA to universities overseas requires plenty of study and can be very dull.
                    </p>
                    <p style={{color:'#212121',}}>
                    To be able to send these records safely and punctually, you want a reliable International Courier Service. We know this can be very costly once you apply to numerous universities. Study Metro can help you to save on those courier costs by providing the exact same support from leading global courier spouses at special discounted rates for students.
                    </p>
                    <p style={{color:'#212121',}}>
                    How it works

                    </p>
                    <p style={{color:'#212121',fontWeight:'bold',}}>
                    1. Fill form online
                    Signup and discuss your pickup
                    And delivery address details  
                    </p>
                    <p style={{color:'#212121',fontWeight:'bold',}}>2. Wait for Courier business call</p>
                    <p style={{color:'#212121',fontWeight:'bold',}}>
                    3. Pickup of packet
                    During pickup be prepared with money,
                    ID proof, proof printout and
                    Real bundle in open state

                    ­</p>
                    <p style={{color:'#212121',fontWeight:'bold',}}>
                    4. Track your shipment
                    Track your shipment courier business site. Delivery within
                    2-3 working days

                    </p> */}
                                  </div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div className="col-md-12">
                                    <div
                                      className="content"
                                      style={{ paddingBottom: 0 }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area default-padding">
        <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="row" style={{ display: "block" }}>
            <div className="about-items">
              <div className="col-md-12">
                <div className="weekly-top-items">
                  <div className="top-courses" style={{ paddingRight: 0 }}>
                    <div className="heading" style={{ paddingBottom: 15 }}>
                      <h4 style={{ color: "#3f8ccb" }}>
                        ACCREDITATIONS AND MEMBERSHIPS <strong></strong>
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/EAIE.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/ICEF.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/NAFSA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/QISAN.jpeg"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIRC.gif"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIEA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
