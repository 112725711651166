/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function PartnerBanks(props) {
  const classes = useStyles();

//   console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 25,
                  marginTop: 30,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">Partner Banks</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="blog-area single full-blog left-sidebar full-blog default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="container"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <div className="row">
                    <div className="blog-items" style={{ width: "100%" }}>
                      <div className="blog-content col-md-12">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      //   marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                    Various financial institutions in India work with 
                                    Study Metro Eduloans and are able to, create awareness 
                                    to the next generation of customers provide services at
                                     there convenience. The centralized system reduces the 
                                     processing time of the loan and Bank is able to provide 
                                     timely feedback. They usually provide collateral based loan.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6> Bank of Baroda</h6>
                                      Education is the most important investment one makes in life.
                                       Higher studies and specialization in certain fields call for 
                                       additional financial support from time to time. The Bank of 
                                       Baroda Education Loans, can help finance your ambitions and goals.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                    Bank of Baroda (BOB) offers the following benefits :
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Loan Processing : Fast Online Processing.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Interest Rate : Preferential Interest Rate starting at 9.95 %.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Special Rate : Discount of 0.50 % for Girl Students.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Loan amount : Up to Rs 80 Lacs.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Tax Benefit : Interest paid on education loan to bank is Tax waived under section 80
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Margin : For overseas studies, select colleges 0 % margin, all other colleges 10.00 % margin.
                                        </li>

                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Repayment Period : Up to 15 years.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Moratorium Period : Course Period + 1 year.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Loan Proceeds : Proceeds happen from your nearest BOB branch.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Processing Fee : 1 % of Loan amount (maximum ₹ 10,000/-) which is Refundable on availment of first disbursement.
                                        </li>
                                      </ul>{" "}
                                    </p>

                                    <br />
{/* State Bank  */}
                                        <p style={{ color: "#212121" }}>
                                      <h6> State Bank of India</h6>
                                      SBI offers education loan for pursuing higher education in India or abroad 
                                      for various courses in leading institutions.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                    State Bank of Indai (SBI) offers the following benefits :
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Interest Rate : Interest Rate starting at 10.65 %.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Special Rate : Discount of 0.50 % for Girl Students.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Concession : 0.50 % against LIC policy kept as security.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Loan amount : Up to Rs.1.50 Crores.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Tax Benefit : Interest paid on education loan to bank is Tax waived under section 80E.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Margin : 10.00 % margin for overseas studies.
                                        </li>

                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Repayment Period : Up to 15 years
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Moratorium Period : Moratorium on repayment during the study period available
                                           + 1 year or 6 months after getting the job, whichever is earlier.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Loan Proceeds : Proceeds happen from your nearest SBI branch.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Processing Fee : Rs.10,000/- for any amount upto Rs.1.50 Crores (Not Refundable).
                                        </li>
                                      </ul>{" "}
                                    </p>
                                    <br/>
{/* Axis Bank          */}
                                    <p style={{ color: "#212121" }}>
                                      <h6> Axis Bank</h6>
                                      Axis Bank offers attractive Education Loan interest rates in India.Apply for Study loan at highly
                                       affordable interest rates and associated servicing charges.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                    AXIS Bank offers the following benefits :
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; No Processing Fees (Depends on Case).
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; No Pre-Payment Charges.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Only bank to have dedicated RM and door to door service.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Income Tax Benefit Under Section 80E.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Sanction Letter prior to receiving I20 for US.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Re-payment term upto 15 years.
                                        </li>

                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Validity of Sanction Letter is upto 6 months.
                                        </li>
                                      </ul>{" "}
                                    </p>
                                    <br/>
{/* ICICI Bank       */}
                                    <p style={{ color: "#212121" }}>
                                      <h6> ICICI Bank</h6>
                                      You may have studied hard and excelled in your exams or just planned 
                                      the next move in your career. Whatever be your reason to study, your 
                                      research should end at finding the right institute. Let finance be the
                                       last thing to worry about. We at ICICI Bank, understand your career 
                                       aspirations and offer the best student loan to successfully aid your journey as a student.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                    ICICI Bank offers the following benefits :
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Clubbing of both Secured and Unsecured Loan together as per students requirement.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Lesser Currency Conversion Charges as compared to other Financial Institutions.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; NRI Co-Signor acceptable along with an additional Co-Signor for Secured Loans.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Economical Valuation and Legal Formalities Charges as compared to other Banks.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Sanction Letter process through Loan Centre instead of Bank Branch and so its Faster.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Sanction Letter prior to receiving I20 , VISA and disbursement prior to departure.
                                        </li>

                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Income Tax Benefit Under Section 80E (For both Secured and Unsecured Loans).
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Doorstep service as per convenience of the customer available at selected locations.s).
                                        </li>
                                      </ul>{" "}
                                    </p>
                                    <br/>
{/* HDFC Bank  */}
<p style={{ color: "#212121" }}>
                                      <h6> HDFC Credila</h6>
                                      HDFC Credila is India's First Dedicated Education Loan company and a pioneer in the field of education loan. 
                                      HDFC Credila has already funded Indian students going to: 35+ Countries, 2100+ Institutes & studying 1000+ different Courses.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                    HDFC Credil offers the following benefits :
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Upto 100% finance of the I20 value.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Dedicated Relationship manager and Doorstep Service.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;No Collateral / Less than 100% Collateral option available.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Loan Sanction before admission for I20 available.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; ONLY NBFC to have Tax benefit under Section 80E of Income tax Act.
                                        </li>
                                       
                                      </ul>{" "}
                                    </p>
                                    <br/>

{/* DHFL Avanse  */}
<p style={{ color: "#212121" }}>
                                      <h6> DHFL Avanse</h6>
                                      100% Education Finance to study in india or abroad. Give wings to your dreams.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                    Avanse offers the following benefits :
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; 100 % Education Finance (No Margin Money).
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; No Upper Limit on Loan Amount.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Flexible Repayment Options.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Sanction within 1 Business Day after submission of all documents.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Student-focused flexible Loans : Due weight-age given to Academic Merit.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Disbursement in 1 single installment OR as per payment schedule Tuition Fee disbursed to institute.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Doorstep Service.
                                        </li>
                                      </ul>{" "}
                                    </p>
                                    <br/>
{/* Incred  */}
<p style={{ color: "#212121" }}>
                                      <h6> Incred</h6>
                                      Fulfill your educational dreams with InCred cutting-edge financial services.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                    Incred offers the following benefits :
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Can offer Entire cost of Education including Tution Fees, Living Expenses, Books, Laptop, Insurance.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Can accept multi-city co-signers.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;All legal fees, registration charges are included in InCred's origination fees.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Pre Visa Disbursal Available for USA, Germany & other European countries if situation demands.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Offers door step service when applying for education loan.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Can offer Non Collateral loan upto 40-45 Lacs for USA.
                                        </li>
                                       
                                      </ul>{" "}
                                    </p>
                                    <br/>
{/* Auxilo  */}
                                    <p style={{ color: "#212121" }}>
                                      <h6> Auxilo</h6>
                                      Our Education Loans aim to help aspiring students across segments be it Graduate or Post Graduate courses in India or Overseas.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                    Auxilo offers the following benefits :
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;0.50% Processing Fees - Only for EDULOANs/IMPERIAL Students.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Customized Loans.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;100% Financing.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Pre Admission Loan.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Pre-Visa Loan Disbursment.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Fast Track Loan.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;High on Services.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Competitive Interest Rates.
                                        </li>
                                      </ul>{" "}
                                    </p>
                                    <br/>
{/* Bajaj Finserv  */}
                                    <p style={{ color: "#212121" }}>
                                      <h6> Bajaj Finserv : Loan Against Property for higher education</h6>
                                      Financing higher education requires careful planning and consideration.
                                       A part of this planning involves understanding how to fund various expenses, 
                                       such as housing, tuition and additional expenses at minimal cost. Loan Against
                                        Property from Bajaj Finserv enables you to do it all, offering customised loans 
                                        to salaried and self-employed individuals.
                                    </p>
                                 
                                    <p style={{ color: "#212121" }}>
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Lower interest rate than an Education Loan.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;High loan amount.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Flexible tenor and repayment.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Lower EMI.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Fast Processing.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Easy balance transfer facility.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp;Hassle-free loan disbursal.
                                        </li>
                                       
                                      </ul>{" "}
                                    </p>
                                    <br/>

                                    <br />
                                    
                                
                                  </div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div className="col-md-12">
                                    <div
                                      className="content"
                                      style={{ paddingBottom: 0 }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <Footer />
    </Container>
  );
}
