import React, { Fragment } from "react";
import { Text, StyleSheet } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
  MDBSideNav,
  MDBNavItem,
} from "mdbreact";

//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";

import color from "@material-ui/core/colors/amber";
import Header from "./Header";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
  category: {
    position: "sticky",
  },
  category: {
    a: {
      display: "block",
    },
  },
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [messages, setMessages] = React.useState("");
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus, setStatus] = React.useState("");

  const handlePicture = (event) => {
    setPicturePath(URL.createObjectURL(event.target.files[0]));
    setPicture(event.target.files[0]);
  };

  const handleVerifyPassword = (e) => {
    setVerifyPassword(e.target.value);
    if (password === e.target.value) {
      setStatus("Password Matched");
    } else {
      setStatus("Password not matched");
    }
  };

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />
      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 70,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">Study Abroad API</h2>
                    </div>
                  </div>
                </div>
              </div>



              <div className="blog-area single full-blog left-sidebar full-blog default-padding">
              <div className="container">
                <div className="row">
                  <div className="blog-items">

              <div className="blog-content col-md-12">
                <div className="content-items">
                  <div className="single-item">
                    <div className="item">
                      <div className="info">
                        <div className="content" style={{ paddingBottom: 0 }}>
                          <div
                            style={{
                              marginLeft: 50,
                              textAlign: "justify",
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 20,
                                }}
                              >
                                Study Abroad APIs
                              </p>
                              <p>
                                Our Study Abroad APIs directly connect you to
                                all the data you need to build an innovative
                                website or app. Your customers will be able to
                                take advantage of the best deals on
                                universities, programs and universities from
                                wherever they are in the world - either as three
                                independent study abroad solutions or integrated
                                together.
                              </p>
                              <p>
                                <a href=" https://studymetro.typeform.com/to/kdDGaGVo">
                                  Request Access
                                </a>
                              </p>

                              <p>
                                We've made it easier than ever to develop your
                                site using flexible university search options
                                with rapid response times. This makes it simple
                                to build exciting tools that open up multiple
                                universities search options to your students.
                              </p>
<br/>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 20,
                                }}
                              >
                                Study Abroad APIs Advantages
                              </p>

                              <p>
                                <ul style={{ listStyleType: "square" }}>
                                  <li>
                                    Mobile first solutions to enable you to take
                                    advantage of the latest trends as they
                                    happen
                                  </li>
                                  <li>
                                    Support Network - Dedicated Account Manager,
                                    Engineering support team and a developer hub
                                    with all the resources needed to integrate
                                  </li>
                                  <li>
                                    Easy set up and flexible integration of
                                    study abroad search
                                  </li>
                                  <li>
                                    Inspirational search features - combine our
                                    study metro APIs with your existing students
                                    offering to complement your product range
                                  </li>
                                  <li>
                                    Dedicated online portal to manage your
                                    revenue and commissions
                                  </li>
                                  <li>
                                    Global content and coverage supporting your
                                    expansion to over 30 markets and languages
                                    and over 2,000 agents partners
                                  </li>
                                  <li>
                                    Complete solution with data, content and
                                    legal requirements all taken care of so you
                                    can focus on growing your business
                                  </li>
                                </ul>
                              </p>
                              <br/>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 20,
                                }}
                              >
                                Study Abroad APIs Features
                              </p>
                              <p>
                                Browse cache API allows broad search queries
                                such as "What's the cheapest university in the
                                USA?”
                              </p>

                              <p>
                                <ul style={{ listStyleType: "square" }}>
                                  <li>
                                    Supports a variety of geo formats, for easy
                                    integration with your existing geo-tagged
                                    pages
                                  </li>

                                  <li>
                                    Continually optimised product solutions
                                  </li>

                                  <li>
                                    First class technology from the team behind
                                    Study Metro award winning global university
                                    search
                                  </li>

                                  <li>
                                    Asynchronous format, allowing for real time
                                    streaming of tuition fee for your students
                                  </li>

                                  <li>
                                    Developer resource area with online test
                                    harnesses, change logs, support and
                                    documentation
                                  </li>

                                  <li>
                                    RESTful API format, with JSON or XML
                                    responses available
                                  </li>
                                </ul>
                              </p>
                              <p>
                                <a href="https://studymetro.typeform.com/to/kdDGaGVo">
                                  Request Access
                                </a>
                              </p>
                                <br/>
                                <br/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Container>
  );
}
