/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
import React, { Fragment,} from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Header from './Header';
import Footer from './Footer';


import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';



const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
newbtn:{
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
},

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:12,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    // margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },

widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const [firstName,setFirstName]=React.useState('')
  const [lastName,setLastName]=React.useState('')
  const [emailId, setEmailId]=React.useState('')
  const [password,setPassword]=React.useState('')
  const [verifyPassword,setVerifyPassword]=React.useState('')
  const [messages, setMessages ]=React.useState('')
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus,setStatus]=React.useState('') 

  const handlePicture=(event)=>{
    setPicturePath(URL.createObjectURL(event.target.files[0]))
    setPicture(event.target.files[0])
   }

   const handleVerifyPassword=(e)=>{
    setVerifyPassword(e.target.value)
    if(password===e.target.value){
      setStatus("Password Matched")
    }
    else{
      setStatus("Password not matched")
    }
  }


  // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
  const top100Films = [
    { title: 'Phd', year: 1994 },
    { title: 'MCA', year: 1972 },
    { title: 'MSC', year: 1974 },
  ];
   
    // console.log("History ==  ",props)
  return (
   <Container className={classes.widths}>
  <Header/><CssBaseline />
    <div className="login-area default-padding" style={{paddingTop:10,}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">


    <div className="about-area default-padding">
        <div className="container">
            <div className="row">
                <div className="about-items">

 <div className="col-md-3 features text-light">
                    <div className="sidebar-item category" style={{padding:15,}}>
      <div className="sidebar-info" >  
     <a color="primary" id="togglerU"  style={{color:'#000',fontWeight:'bold'}}  fullWidth>                        
       Inquiry Form <span style={{float:'right'}}><i class="fa fa-envelope"></i></span>
    </a> 
       
       </div>  
       <hr/>  
       <p style={{fontSize:12,color:'#212121',textAlign:'justify',lineHeight:'inherit'}}>
       Our education counselors and campus teams help you every step of the way, from application to graduation, at no additional cost to you.
    </p>  
<div className="blog-area">
<div className="info">
<div className="content" style={{paddingBottom:0,}} style={{padding:0,position:'static'}}>
<a href="https://calendly.com/studymetro/students" style={{color:'#000',borderRadius:5,textAlign:'center'}}> Talk to an Advisor</a>
</div>
</div>
</div>  

</div>

  <div className="sidebar-item category" style={{padding:15,}}>
   <div className="sidebar-info" >  
     <a color="primary" id="togglerU"  style={{color:'#000',fontWeight:'bold'}}  fullWidth>                        
       Videos <span style={{float:'right'}}><i class="fa fa-video-camera"></i></span>
    </a> 
  </div>  
       <hr/>  
<p style={{fontSize:12,color:'#212121',textAlign:'justify',lineHeight:'inherit'}}>
 Our education counselors and campus teams help you every step of the way, from application to graduation, at no additional cost to you.
</p>  
<div className="">
    <a className="videoOverlapper" href="https://youtube.com/embed/c_newv2i3r0" data-featherlight="iframe">
        <i style={{position:'absolute',padding:90,fontSize:40,}} class="fa fa-play-circle"></i>
        <img src="https://www.studymetro.com/static/uploads/videos/150169728359821503548a8.jpg" />
            </a>
            </div>

</div>
<div className="sidebar-item category" style={{padding:15,}}>
   <div className="sidebar-info" >  
     <a color="primary" id="togglerU"  style={{color:'#000',fontWeight:'bold'}}  fullWidth>                        
       Event <span style={{float:'right'}}><i class="fa fa-flag"></i></span>
    </a> 
  </div>  
       <hr/>  

</div>

</div>

<div className="col-md-9 about-info">
{/* <h2 style={{fontSize:32,marginTop:20,}}>Europe-100% scholarship/very low tuition. <span>Achieve dreams with less funds USA.</span></h2>
                      
<p style={{fontSize:14,color:'#212121',textAlign:'justify'}}>
Europe-100% scholarship study abroad-Easy journey- average grades. US scholarship GRE/IELTS/SAT/Previous study criteria. E-visa rigrous guidance raise the chances.
</p> */}
<p style={{fontSize:14,color:'#212121',textAlign:'justify'}}>
Study Metro study abroad admission guidance is top among many consultants. We believe in nourishing & nurturing instead of spoon feeding. SOP/Essays top level guidance where you could be your own author. Right way on right time could lead you to high level of success.
</p>
<p style={{fontSize:14,color:'#212121',textAlign:'justify'}}>
Study Metro help student to make the best career choice. We help you to choose the right course in the right university. Keeping in view the student profile and the professional market scenario Our Expert provides in-depth personal guidance to help student in choosing the right course in the university according to his/her profile, financial requirements.   
</p>
<p style={{fontSize:14,color:'#212121',textAlign:'justify'}}>
We also help the student make right career choices. In addition, we help the student set career goals and plan a career path that has a higher predictability of career success.    
</p>
<p style={{fontSize:14,color:'#212121',textAlign:'justify'}}>
We give special attention to the students application, highlighting the essential to present a good application and help you to get best program and university for your career   
</p>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>
</div>
</div>
</div>

<Footer/>
    </Container>
  );


}
