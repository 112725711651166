/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function ACT(props) {
  const classes = useStyles();

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 25,
                  marginTop: 30,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">ACT</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="blog-area single full-blog left-sidebar full-blog default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="container"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <div className="row">
                    <div className="blog-items" style={{ width: "100%" }}>
                      <div className="blog-content col-md-12">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      //marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      <h6>About the Assessment</h6>
                                      The ACT is administered by the ACT,
                                      Incorporated. ACT complete form is
                                      American College Testing. It's a
                                      standardized test that's required to be
                                      obtained by students searching for
                                      entrance in undergraduate schools located
                                      in the united states and Canada. ACT
                                      examination was developed to assess the
                                      written, mathematical, verbal and
                                      scientific abilities of their candidates.
                                      <br />
                                      In 2015, ACT will start to be provided as
                                      a computer-based test rather than the
                                      classic paper-based test. The evaluation
                                      syllabus, complete score, and several
                                      choice format won't be impacted by this
                                      shift as merely the test-taking
                                      arrangement varies.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        International students should enroll for
                                        the ACT online just.
                                      </h6>
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; When you intend to try in the
                                          Indian evaluation centers, register
                                          online by producing an ACT Web
                                          account.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Pick a test date and test
                                          center.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Create a student profile and
                                          fill out the program petition, submit
                                          the necessary documentation.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; You'll obtain an instant
                                          registration verification
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Pay the examination fee via
                                          credit/debit card or internet funds
                                          transfer.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Registration deadlines are
                                          generally about a few months prior to
                                          a test date.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Deadlines for late enrollment
                                          are usually about two to three weeks
                                          prior to test date and enrolling late
                                          will incur a late charge.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Learn More about the ACT
                                          Registration here.
                                        </li>
                                      </ul>
                                      <br />
                                      Candidate may print and download the admit
                                      card from the official site. Candidate
                                      must create the admit card whilst looking
                                      for the examination. When you receive your
                                      entry ticket, check it carefully to be
                                      sure that your identifying info, speech
                                      and score recipients ' are right.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Exam Centers</h6>
                                      The ACT examination is conducted in the
                                      test centers in these cities.
                                      <br />
                                      ACT Exam Pattern
                                      <br />
                                      ACT examination routine is as follows-
                                      four segments, namely, English, Math,
                                      Reading and Science. There's an optional
                                      section that's known as the Writing
                                      section, which includes one 30-40 minutes
                                      writing prompt which will define a
                                      problem, along with just two points of
                                      view on that situation. Pupils aren't
                                      scored with this section.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Scoring at ACT</h6>
                                      Each segment of the ACT (English, Math,
                                      Reading, and Science) is performed on a 1
                                      to 36 point scale. The composite score is
                                      the average of four tests. Pupils taking
                                      the optional writing test will obtain a
                                      writing score of the area ranging from 1
                                      to 36. The writing score doesn't influence
                                      the composite score.
                                      <br />
                                      ACT Score Validity - The ACT absolute
                                      scores are valid for five years following
                                      the testing season in which you analyzed.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>ACT Outcomes</h6>ACT test results are
                                      generally published within eight months of
                                      the test management and could be seen on
                                      the web. If you took the ACT with writing,
                                      your writing scores will be posted on line
                                      when they're prepared, which is normally
                                      two weeks following your overall scores
                                      are outside.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6> Important Dates</h6>
                                      The ACT is ran just a few times per Year
                                      between September to July. You don't have
                                      the luxury of choosing an exam date
                                      appropriate depending on your advantage,
                                      that's true with other examinations.
                                    </p>
                                  </div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div className="col-md-12">
                                    <div
                                      className="content"
                                      style={{ paddingBottom: 0 }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area default-padding">
        <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="row" style={{ display: "block" }}>
            <div className="about-items">
              <div className="col-md-12">
                <div className="weekly-top-items">
                  <div className="top-courses" style={{ paddingRight: 0 }}>
                    <div className="heading" style={{ paddingBottom: 15 }}>
                      <h4 style={{ color: "#3f8ccb" }}>
                        ACCREDITATIONS AND MEMBERSHIPS <strong></strong>
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/EAIE.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/ICEF.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/NAFSA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/QISAN.jpeg"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIRC.gif"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIEA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
