import React, { Fragment, useState, Platform, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import ApplyProgram from './ApplyProgram';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { postDataAndImage, postData } from "../FetchServices";
import {
  postDataAndImageLocal,
  getData,
  postDataLocal,
} from "../FetchServicesLocal";
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormGroup from "@material-ui/core/FormGroup";
// import Checkbox from '@material-ui/core/Checkbox';

import { green } from "@material-ui/core/colors";
// import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Radio from "@material-ui/core/Radio";
import Switch from "@material-ui/core/Switch";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { TextFields } from "@material-ui/icons";
import Header from "./Header";
import Footer from "./Footer";
import $ from "jquery";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  input: {
    padding: "0px 0px",
  },
  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 16,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(1),
    display: "block",
    flexDirection: "column",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },
  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function ProfileBuilder(props) {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [fullName, setFullName] = React.useState("");
  const [dob, setDob] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [LevelOfEducation, setLevelOfEducation] = React.useState("");

  const [nationality, setNationality] = React.useState("");
  const [passportNo, setPassportNO] = React.useState("");

  const [preferedCountry, setPreferedCountry] = React.useState("");
  const [firstLan, setFirstLang] = React.useState("");
  const [preferedProgram, setPreferedProgram] = React.useState("");
  const [applicationHandling, setApplicationHandling] = React.useState("");
  const [stage, setStage] = React.useState("New");
  const [accmanager, setAccManager] = React.useState("");

  const [emerPersonName, setEmerPersonName] = React.useState("");
  const [emeremail, setEmerEmail] = React.useState("");
  const [emermobile, setEmerMobile] = React.useState("");
  const [emerrelation, setEmerRelation] = React.useState("");

  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [states, setStates] = React.useState("");
  const [zipCode, setZipCode] = React.useState("");
  const [country, setCountry] = React.useState("");
  // const [addemail, setAddEmail] = React.useState("");
  // const [addmobile, setAddMobile] = React.useState("");

  const [recentEducation, setRecentEducation] = React.useState("");
  const [countryEducation, setCountryEducation] = React.useState("");

  const [educationLevel, setEducationLevel] = React.useState(false);
  const [ug, setug] = React.useState(false);
  // const [hs, seths] = React.useState(false);
  const [pg, setpg] = React.useState(false);
  const [doc, setdoc] = React.useState(false);
  const [certi, setcerti] = React.useState(false);
  const [dip, setdip] = React.useState(false);
  const [el, setel] = React.useState(false);

  const [highschoolmarks, setHighSchoolMarks] = React.useState("");
  const [highsecmarks, setHighSecMarks] = React.useState("");
  const [ugmarks, setUGMarks] = React.useState("");
  const [pgmarks, setPGMarks] = React.useState("");

  const [instituteName, setInstituteName] = React.useState("");
  const [instituteLang, setInstituteLang] = React.useState("");
  const [degreeName, setDegreeName] = React.useState("");
  const [attInstituteFrom, setAttInstituteFrom] = React.useState("");
  const [attInstituteTO, setAttInstituteTo] = React.useState("");
  const [degreeAwardedOn, setDegreeAwardedOn] = React.useState("");
  const [major, setMajor] = React.useState("");
  const [gradeAvg, setGradeAvg] = React.useState("");
  const [schoolAdd, setSchoolAdd] = React.useState("");

  const [activityType, setActivityType] = React.useState("");
  const [organisation, setOrganisation] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [duration, setDuration] = React.useState("");

  const [gre, setGRE] = React.useState(false);
  const [greAppreadOn, setGREAppreadOn] = React.useState("");
  const [greVS, setGREVS] = React.useState("");
  const [greQS, setGREQS] = React.useState("");
  const [greWS, setGREWS] = React.useState("");
  const [greS1Name, setGRES1Name] = React.useState("");
  const [greS1Score, setGRES1Score] = React.useState("");
  const [greS2Name, setGRES2Name] = React.useState("");
  const [greS2Score, setGRES2Score] = React.useState("");

  const [gmat, setGMAT] = React.useState(false);
  const [gmatAppreadOn, setGMATAppreadOn] = React.useState("");
  const [gmatVS, setGMATVS] = React.useState("");
  const [gmatQS, setGMATQS] = React.useState("");
  const [gmatWS, setGMATWS] = React.useState("");
  const [gmatOS, setGMAtOS] = React.useState("");

  const [sat, setSAT] = React.useState(false);
  const [satAppreadOn, setSATAppreadOn] = React.useState("");
  const [satRWS, setSATRWS] = React.useState("");
  const [satES, setSATES] = React.useState("");
  const [satMS, setSATMS] = React.useState("");
  const [satS1Name, setSATS1Name] = React.useState("");
  const [satS1Score, setSATS1Score] = React.useState("");
  const [satS2Name, setSATS2Name] = React.useState("");
  const [satS2Score, setSATS2Score] = React.useState("");

  const [act, setACT] = React.useState(false);
  const [actAppreadOn, setACTAppreadOn] = React.useState("");
  const [actCS, setACTCS] = React.useState("");
  const [actWS, setACTWS] = React.useState("");
  const [actMS, setACTMS] = React.useState("");

  const [toefl, setTOEFL] = React.useState(false);
  const [toeflAppreadOn, setTOEFLAppreadOn] = React.useState("");
  const [toeflLS, setTOEFLLS] = React.useState("");
  const [toeflRS, setTOEFLRS] = React.useState("");
  const [toeflSS, setTOEFLSS] = React.useState("");
  const [toeflTS, setTOEFLTS] = React.useState("");
  const [toeflWS, setTOEFLWS] = React.useState("");

  const [ielts, setIELTS] = React.useState(false);
  const [ieltsAppreadOn, setIELTSAppreadOn] = React.useState("");
  const [ieltsTS, setIELTSTS] = React.useState("");
  const [ieltsLS, setIELTSLS] = React.useState("");
  const [ieltsRS, setIELTSRS] = React.useState("");
  const [ieltsSS, setIELTSSS] = React.useState("");
  const [ieltsWS, setIELTSWS] = React.useState("");

  const [pte, setPTE] = React.useState(false);
  const [pteAppreadOn, setPTEAppreadOn] = React.useState("");
  const [pteOS, setPTEOS] = React.useState("");
  const [pteLS, setPTELS] = React.useState("");
  const [pteRS, setPTERS] = React.useState("");
  const [pteSS, setPTESS] = React.useState("");
  const [pteWS, setPTEWS] = React.useState("");

  const [duo, setDuo] = React.useState(false);
  const [duoAppreadOn, setDuoAppreadOn] = React.useState("");
  const [duoOS, setDuoOS] = React.useState("");

  const [lastCountryVisited, setLastCountryVisited] = React.useState("");
  const [countryRefusal, setCountryRefusal] = React.useState("");
  const [countryVisa, setCountryVisa] = React.useState("");

  const [moi, setMOI] = React.useState(false);

  const [provideLater, setProvideLater] = React.useState(false);

  const [dontHave, setDontHave] = React.useState(false);

  const [resume, setResume] = React.useState(false);
  const [etd, setETD] = React.useState(false);
  const [passport, setpassport] = React.useState(false);
  const [tsc, setTSC] = React.useState(false);
  const [sop, setSOP] = React.useState(false);
  const [ielts2, setIELTS2] = React.useState(false);
  const [fd, setFD] = React.useState(false);
  const [ad, setAD] = React.useState(false);

  const [studygapjust, setStudyGapJust] = React.useState("");

  const [messages, setMessages] = React.useState("");

  const [resumedoc, setResumeDoc] = React.useState({ bytes: "" });
  const [etddoc, setetdDoc] = React.useState({ bytes: "" });
  const [passportdoc, setPassportDoc] = React.useState({ bytes: "" });
  const [tscdoc, settscDoc] = React.useState({ bytes: "" });
  const [sopdoc, setsopDoc] = React.useState({ bytes: "" });
  const [ieltsdoc, setieltsDoc] = React.useState({ bytes: "" });
  const [fddoc, setfdDoc] = React.useState({ bytes: "" });
  const [addoc, setadDoc] = React.useState({ bytes: "" });
  // const [msg, setMsg] = React.useState("");
  const [emailIdError, setEmailIdError] = React.useState(false);
  const [student_ID, setstudent_ID] = React.useState("");

  // const [getlist, setlist]=useState([]);

  //------------------DATE-------------------------------//

  const initDate = () => {
    var cd = new Date();
    var d = "";
    var m = "";
    if (cd.getDate() <= 9) {
      d = "0" + cd.getDate();
    } else {
      d = cd.getDate();
    }
    if (cd.getMonth() <= 8) {
      m = "0" + (cd.getMonth() + 1);
    } else {
      m = cd.getMonth();
    }
    var setcd = cd.getFullYear() + "-" + m + "-" + d;

    setDob(setcd);
    // setAttInstituteFrom(setcd);
    // setAttInstituteTo(setcd);
    // setDegreeAwardedOn(setcd);
    // setGREAppreadOn(setcd);
    // setGMATAppreadOn(setcd);
    // setSATAppreadOn(setcd);
    // setACTAppreadOn(setcd);
    // setTOEFLAppreadOn(setcd);
    // setIELTSAppreadOn(setcd);
    // setPTEAppreadOn(setcd);
    // setDuoAppreadOn(setcd);
  };

  const checkEmail = async () => {
    // setMessages("");
    // var count = 0;

    if (email === "") {
      // setEmailIdError(true);
      // count++;
      alert("Email Can not be Empty");
    }
    //   if (count) {
    //     setMessages(<font color="red">Email can not be empty</font>);
    //   } else {
    //     await setMessages(<font color="black">Please wait..</font>);
    // }
  };

  useEffect(function () {
    // localStorageData();
    initDate();
  }, []);


  const fetchnames = async (sid) => {

    var body={sid : sid}
    var list = await postDataLocal("profilebuilder/displayinfopb", body);

    setDob(list.data[0].date_of_Birth)
    setFirstName(list.data[0].first_name)
    setLastName(list.data[0].last_name)
    setFullName(list.data[0].username)
    setEmail(list.data[0].email)
    setMobile(list.data[0].phone_number)
    setAttInstituteFrom(list.data[0].attended_Institution_From);
    setAttInstituteTo(list.data[0].attended_Institution_To);
    setDegreeAwardedOn(list.data[0].degree_Awarded);
    setGender(list.data[0].gender);
    setNationality(list.data[0].nationality);
    setPassportNO(list.data[0].passport_Number);
    setAddress(list.data[0].address);
    setCity(list.data[0].cityTown);
    setStates(list.data[0].provinceState);
    setZipCode(list.data[0].provinceZip_Code);
    setCountry(list.data[0].country);
    setRecentEducation(list.data[0].current_or_most_recent_level_of_Education);
    setCountryEducation(list.data[0].country_Of_Education);
    setPreferedCountry(list.data[0].preferred_country);
    setPreferedProgram(list.data[0].preferred_Program);
    
    setEducationLevel(list.data[0].level_of_Education);    
    setug(list.data[0].undergraduateBachelor);
    setpg(list.data[0].master_Postgraduate);
    setdoc(list.data[0].doctoral);
    setcerti(list.data[0].certificate);
    setdip(list.data[0].diploma);
    setel(list.data[0].english_Language);

    setHighSchoolMarks(list.data[0].high_School);
    setHighSecMarks(list.data[0].higher_Secondary);
    setUGMarks(list.data[0].undergraduate_Bachelor);
    setPGMarks(list.data[0].masterUndergraduate);  

    setInstituteName(list.data[0].name_Of_Institution);
    setInstituteLang(list.data[0].language_of_Instruction);
    setDegreeName(list.data[0].degree_name);
    setMajor(list.data[0].major);
    setGradeAvg(list.data[0].grade_Average);
    setSchoolAdd(list.data[0].school_Address);

    setGRE(list.data[0].GRE);
    setGMAT (list.data[0].GMAT);  
    setSAT(list.data[0].SAT);
    setACT(list.data[0].ACT);
    setTOEFL(list.data[0].TOEFL);

    setIELTS(list.data[0].IELTS);
    setDuo(list.data[0].duolingo);  
    setPTE(list.data[0].PTE);
    setMOI(list.data[0].I_have_MOI);
    setProvideLater(list.data[0].I_will_provide_Later);
    setDontHave (list.data[0].I_don_t_have_it);

    setGREAppreadOn(list.data[0].GRE_appearing_on);
    setGREVS(list.data[0].GRE_Verbal_Score);  
    setGREQS(list.data[0].GRE_Quantitative_Score);
    setGREWS(list.data[0].GRE_Writing_Score);
    setGRES1Name(list.data[0].GRE_Subject_1_name);
    setGRES1Score(list.data[0].GRE_Subject_1_Score);
    setGRES2Name(list.data[0].GRE_Subject_2_name);
    setGRES2Score(list.data[0].GRE_Subject_2_Score);

    setGREAppreadOn(list.data[0].GRE_appearing_on);
    setGREVS(list.data[0].GRE_Verbal_Score);  
    setGREQS(list.data[0].GRE_Quantitative_Score);
    setGREWS(list.data[0].GRE_Writing_Score);
    setGRES1Name(list.data[0].GRE_Subject_1_name);
    setGRES1Score(list.data[0].GRE_Subject_1_Score);
    setGRES2Name(list.data[0].GRE_Subject_2_name);
    setGRES2Score(list.data[0].GRE_Subject_2_Score);

    setGMATAppreadOn(list.data[0].GMAT_appearing_on);
    setGMATWS(list.data[0].GMAT_Writing_Score);  
    setGMATQS(list.data[0].GMAT_Quantitative_Score);
    setGMATWS(list.data[0].GMAT_Writing_Score);
    setGMAtOS(list.data[0].GMAT_Overall_Score);

    setSATAppreadOn(list.data[0].SAT_Appearing_On);
    setSATRWS(list.data[0].SAT_Reading_Writing_Score);  
    setSATES(list.data[0].SAT_Essay_Score);
    setSATMS(list.data[0].SAT_Maths_Score);
    setSATS1Name(list.data[0].SAT_Subject_1_Name);
    setSATS1Score(list.data[0].SAT_Subject_1_Score);
    setSATS2Name(list.data[0].SAT_Subject_2_Name);
    setSATS2Score(list.data[0].SAT_Subject_2_Score);  

    setACTAppreadOn(list.data[0].ACT_Appearing_On);
    setACTCS(list.data[0].ACT_Composite_Score); 
    setACTWS(list.data[0].ACT_Writing_Score); 
    setACTMS(list.data[0].ACT_Math_Scoring); 

    setTOEFLAppreadOn(list.data[0].TOEFL_appearing_on);
    setTOEFLTS(list.data[0].TOEFL_Test_Score); 
    setTOEFLLS(list.data[0].TOEFL_Listening_Score); 
    setTOEFLRS(list.data[0].TOEFL_Reading_Score); 
    setTOEFLSS(list.data[0].TOEFL_Speaking_Score);
    setTOEFLWS(list.data[0].TOEFL_Writing_Score); 

    setIELTSAppreadOn(list.data[0].IELTS_appearing_on );
    setIELTSTS(list.data[0].IELTS_Test_Score); 
    setIELTSLS(list.data[0].IELTS_Listening_Score); 
    setIELTSRS(list.data[0].IELTS_Reading_Scores); 
    setIELTSSS(list.data[0].IELTS_Speaking_Score);
    setIELTSWS(list.data[0].IELTS_Writing_Score); 

    setDuoAppreadOn(list.data[0].duolingo_appearing_on);
    setDuoOS(list.data[0].duolingo_Overall_Score); 
    
    setPTEAppreadOn(list.data[0].PTE_appearing_on); 
    setPTEOS(list.data[0].PTE_Overall_Score); 
    setPTELS(list.data[0].PTE_Listening_Score);
    setPTERS(list.data[0].PTE_Reading_Score); 
    setPTESS(list.data[0].PTE_Speaking_Score);
    setPTEWS(list.data[0].PTE_Writing_Score); 

    setPTEAppreadOn(list.data[0].PTE_appearing_on); 
    setPTEOS(list.data[0].PTE_Overall_Score); 
    setPTELS(list.data[0].PTE_Listening_Score);
    setPTERS(list.data[0].PTE_Reading_Score); 
    setPTESS(list.data[0].PTE_Speaking_Score);
    setPTEWS(list.data[0].PTE_Writing_Score); 

    setLastCountryVisited(list.data[0].last_5_years_countries_visited); 
    setCountryRefusal(list.data[0].any_country_Refusal);
    setCountryVisa(list.data[0].do_you_hold_any_country_Visa); 

    setResume(list.data[0].Resume_CV); 
    setETD(list.data[0].Education_History_Transcript_Degree); 
    setpassport(list.data[0].passport);
    setTSC(list.data[0].test_Score_Card); 
    setSOP(list.data[0].SOP_s);
    setFD(list.data[0].finance_Documents); 
    setAD(list.data[0].additional_Documents); 

    setResumeDoc(list.data[0].resumedoc); 
    setetdDoc(list.data[0].etddoc); 
    setPassportDoc(list.data[0].passportdoc);
    settscDoc(list.data[0].tscdoc); 
    setsopDoc(list.data[0].sopdoc);
    setieltsDoc(list.data[0].ieltsdoc);
    setfdDoc(list.data[0].fddoc);   
    setadDoc(list.data[0].addoc);   
    setStudyGapJust(list.data[0].study_Gap_Justification);   

  };


  // const localStorageData = async () => {
  //   try {
  //     let data = await localStorage.getItem("student");
  //   if(data){
  //     let result = JSON.parse(data);
  //     setstudent_ID(result.student_ID);
  //     fetchnames(result.student_ID)
  //   } else{
  //     props.history.replace({pathname:'/Login'})
  //   }
  //   } catch (error) {
  //     props.history.replace({pathname:'/Login'})
  //   }   
  // };
  //----------------Submit Form--------------------------//

  function dateHelper(tempDate) {
    var temp = new Date(tempDate);
    let date = temp.getDate() < 9 ? "0" + temp.getDate() : temp.getDate();
    let month =
      temp.getMonth() < 9
        ? "0" + parseInt(temp.getMonth() + 1)
        : parseInt(temp.getMonth() + 1);
    let year = temp.getFullYear();

    return year + "-" + month + "-" + date;
  }

  //-------------------------------------------------------------------//
  const handleSubmit = async () => {
    

    await setMessages(<font color="black">Please wait..</font>);

    var formdata = new FormData();
    formdata.append("student_ID", student_ID);
    formdata.append("name", fullName);
    formdata.append("cityTown", city);
    formdata.append("email", email);
    formdata.append("phone", mobile);
    formdata.append(
      "current_or_most_recent_level_of_Education",
      recentEducation
    );
    formdata.append("country_Of_Education", countryEducation);
    formdata.append("student_First_Name", firstName);
    formdata.append("student_Last_Name", lastName);
    formdata.append("first_Language", firstLan);
    formdata.append("date_of_Birth", dateHelper(dob));
    formdata.append("passport_Number", passportNo);
    formdata.append("nationality", nationality);
    formdata.append("preferred_country", preferedCountry);
    formdata.append("gender", gender);
    formdata.append("preferred_Program", preferedProgram);

    //:applicationHandling,
    formdata.append("stage", stage);
    //:accmanager

    formdata.append("contact_person_name", emerPersonName);
    formdata.append("email_id", emeremail);
    formdata.append("contact_number", emermobile);
    formdata.append("relationship_To_Applicant", emerrelation);

    formdata.append("address", address);
    formdata.append("provinceState", states);
    formdata.append("provinceZip_Code", zipCode);
    //addemail,
    //addmobile,
    formdata.append("country", country);

    formdata.append("level_of_Education",educationLevel);
    // formdata.append("high_School", Boolean(hs));

    formdata.append("undergraduateBachelor", Boolean(ug));
    formdata.append("master_Postgraduate", Boolean(pg));
    formdata.append("doctoral", Boolean(doc));
    formdata.append("diploma", Boolean(dip));
    formdata.append("certificate", Boolean(certi));
    formdata.append("english_Language", Boolean(el));

    formdata.append("high_School", highschoolmarks);
    formdata.append("higher_Secondary", highsecmarks);
    //undergraduateBachelor:
    //master_Postgraduate:
    formdata.append("undergraduate_Bachelor", ugmarks);
    formdata.append("masterUndergraduate", pgmarks);
    formdata.append("name_Of_Institution", instituteName);
    formdata.append("language_of_Instruction", instituteLang);
    formdata.append("degree_name", degreeName);
    formdata.append("attended_Institution_From", dateHelper(attInstituteFrom));
    formdata.append("attended_Institution_To", dateHelper(attInstituteTO));
    formdata.append("degree_Awarded", dateHelper(degreeAwardedOn));
    formdata.append("major", major);
    formdata.append("grade_Average", gradeAvg);
    formdata.append("school_Address", schoolAdd);

    formdata.append("activity_Type", activityType);
    formdata.append("organisation", organisation);
    formdata.append("description", description);
    formdata.append("duration", duration);

    formdata.append("GRE", Boolean(gre));
    formdata.append("GMAT", Boolean(gmat));
    formdata.append("ACT", Boolean(act));
    formdata.append("SAT", Boolean(sat));
    formdata.append("PTE", Boolean(pte));
    formdata.append("TOEFL", Boolean(toefl));
    formdata.append("IELTS", Boolean(ielts));
    formdata.append("duolingo", Boolean(duo));
    formdata.append("I_have_MOI", Boolean(moi));
    formdata.append("I_will_provide_Later", Boolean(provideLater));
    formdata.append("I_don_t_have_it", Boolean(dontHave));
    formdata.append("GRE_appearing_on", dateHelper(greAppreadOn));
    formdata.append("GRE_Quantitative_Score", greQS);
    formdata.append("GRE_Subject_1_name", greS1Name);
    formdata.append("GRE_Subject_1_Score", greS1Score);
    formdata.append("GRE_Subject_2_name", greS2Name);
    formdata.append("GRE_Subject_2_Score", greS2Score);
    formdata.append("GRE_Verbal_Score", greVS);
    formdata.append("GRE_Writing_Score", greWS);
    formdata.append("GMAT_appearing_on", dateHelper(gmatAppreadOn));
    formdata.append("GMAT_Overall_Score", gmatOS);
    formdata.append("GMAT_Quantitative_Score", gmatQS);
    formdata.append("GMAT_Verbal_Score", gmatVS);
    formdata.append("GMAT_Writing_Score", gmatWS);
    formdata.append("SAT_Appearing_On", dateHelper(satAppreadOn));
    formdata.append("SAT_Essay_Score", satES);
    formdata.append("SAT_Maths_Score", satMS);
    formdata.append("SAT_Reading_Writing_Score", satRWS);
    formdata.append("SAT_Subject_1_Name", satS1Name);
    formdata.append("SAT_Subject_2_Name", satS2Name);
    formdata.append("SAT_Subject_2_Score", satS2Score);
    formdata.append("SAT_Subject_1_Score", satS1Score);
    formdata.append("ACT_Appearing_On", dateHelper(actAppreadOn));
    formdata.append("ACT_Composite_Score", actCS);
    formdata.append("ACT_Math_Scoring", actMS);
    formdata.append("ACT_Writing_Score", actWS);
    formdata.append("TOEFL_appearing_on", dateHelper(toeflAppreadOn));
    formdata.append("TOEFL_Listening_Score", toeflLS);
    formdata.append("TOEFL_Reading_Score", toeflRS);
    formdata.append("TOEFL_Speaking_Score", toeflSS);
    formdata.append("TOEFL_Test_Score", toeflTS);
    formdata.append("TOEFL_Writing_Score", toeflWS);
    formdata.append("IELTS_appearing_on", dateHelper(ieltsAppreadOn));
    formdata.append("IELTS_Listening_Score", ieltsLS);
    formdata.append("IELTS_Reading_Scores", ieltsRS);
    formdata.append("IELTS_Speaking_Score", ieltsSS);
    formdata.append("IELTS_Test_Score", ieltsTS);
    formdata.append("IELTS_Writing_Score", ieltsWS);
    formdata.append("PTE_appearing_on", dateHelper(pteAppreadOn));
    formdata.append("PTE_Listening_Score", pteLS);
    formdata.append("PTE_Overall_Score", pteOS);
    formdata.append("PTE_Reading_Score", pteRS);
    formdata.append("PTE_Speaking_Score", pteSS);
    formdata.append("PTE_Writing_Score", pteWS);
    formdata.append("duolingo_appearing_on", dateHelper(duoAppreadOn));
    formdata.append("duolingo_Overall_Score", duoOS);

    formdata.append("last_5_years_countries_visited", lastCountryVisited);
    formdata.append("any_country_Refusal", countryRefusal);
    formdata.append("do_you_hold_any_country_Visa", countryVisa);
    formdata.append("Resume_CV", Boolean(resume));
    formdata.append("SOP_s", Boolean(sop));
    formdata.append("passport", Boolean(passport));
    formdata.append("test_Score_Card", Boolean(tsc));
    formdata.append("LOR_s", Boolean(ielts2));
    formdata.append("finance_Documents", Boolean(fd));
    formdata.append("study_Gap_Justification", studygapjust);
    formdata.append("additional_Documents", Boolean(ad));
    formdata.append("Education_History_Transcript_Degree", Boolean(etd));
    formdata.append("resumedoc", resumedoc.bytes);
    formdata.append("etddoc", etddoc.bytes);
    formdata.append("passportdoc", passportdoc.bytes);
    formdata.append("tscdoc", tscdoc.bytes);
    formdata.append("sopdoc", sopdoc.bytes);
    formdata.append("ieltsdoc", ieltsdoc.bytes);
    formdata.append("fddoc", fddoc.bytes);
    formdata.append("addoc", addoc.bytes);

    //alert('body',JSON.stringify(body))
    const config = { headers: { "content-type": "multipart/form-data" } };

    var result = await postDataAndImageLocal(
      "profilebuilder/addprofile",
      formdata,
      config
    );
    //alert('result',result)
    if (result) {
      props.history.push({pathname:'/ApplyProgram'});

      await setMessages(
        <font color="green">Profile Build Successfully..</font>
      );
    } else {
      await setMessages(<font color="red">Failed to Profile..</font>);
    }
  };

  // const checkTextInput = () => {
  //   //Check for the TextInput
  //   if (firstName == "") {
  //     alert("Please Enter First Name");
  //     return;
  //   }
  //   //Check for the Email TextInput
  //   if (lastName == "") {
  //     alert("Please Enter Last Name");
  //     return;
  //   }
  //   // if (email == "") {
  //   //   alert("Please Enter Email Address");
  //   //   return;
  //   // }
  //   // if (mobile == "") {
  //   //   alert("Please Enter Mobile Number");
  //   //   return;
  //   // }
  //   // if (city == "") {
  //   //   alert("Please Enter Your City");
  //   //   return;
  //   // }
  //   //Checked Successfully
  //   //Do whatever you want
  //   //alert('Success');
  // };

  // const [password, setPassword] = React.useState("");
  // const [verifyPassword, setVerifyPassword] = React.useState("");
  // const [messages, setMessages] = React.useState("");
  // const [picture, setPicture] = React.useState("");
  // const [getpicturePath, setPicturePath] = React.useState("");
  // const [getStatus, setStatus] = React.useState("");

  //--------------------------------------------------//
  // const handleLevelEducation =() =>{
  //   $('.levelofeducation').on('change', function() {
  //     $('.levelofeducation').not(this).prop('checked', false);
  // });
  // }

  //--------------------PG---------------------------//
  //  const handlePG = () => {
  //   // var PG = document.getElementById("pg");
  //   var text = document.getElementById("ShowPG");
  //   // if (PG.checked == true) {
  //     text.style.display = "inline-flex";
  //   // } else {
  //   //   text.style.display = "none";
  //   // }
  //   //alert('Hello...')
  // };

  //  //--------------------UG---------------------------//
  //  const handleUG = () => {
  //   // var UG = document.getElementById("ug");
  //   var text = document.getElementById("ShowUG");
  //   // if (UG.checked == true) {
  //     text.style.display = "inline-flex";
  //   // } else {
  //   //   text.style.display = "none";
  //   // }
  //   //alert('Hello...')
  // };
  //------------Image------------------//
  const handleResumeDoc = (event) => {
    console.log(URL.createObjectURL(event.target.files[0]));
    console.log(event.target.files[0]);

    setResumeDoc({
      bytes: event.target.files[0],
    });
  };

  const handleETDDoc = (event) => {
    console.log(URL.createObjectURL(event.target.files[0]));
    console.log(event.target.files[0]);
    setetdDoc({
      url: URL.createObjectURL(event.target.files[0]),
      bytes: event.target.files[0],
    });
  };

  const handlePassportDoc = (event) => {
    setPassportDoc({
      url: URL.createObjectURL(event.target.files[0]),
      bytes: event.target.files[0],
    });
  };

  const handleTSCDoc = (event) => {
    settscDoc({
      url: URL.createObjectURL(event.target.files[0]),
      bytes: event.target.files[0],
    });
  };

  const handleSOPDoc = (event) => {
    setsopDoc({
      url: URL.createObjectURL(event.target.files[0]),
      bytes: event.target.files[0],
    });
  };
  const handleIELTSDoc = (event) => {
    setieltsDoc({
      url: URL.createObjectURL(event.target.files[0]),
      bytes: event.target.files[0],
    });
  };
  const handleFDDoc = (event) => {
    setfdDoc({
      url: URL.createObjectURL(event.target.files[0]),
      bytes: event.target.files[0],
    });
  };

  const handleADDoc = (event) => {
    setadDoc({
      url: URL.createObjectURL(event.target.files[0]),
      bytes: event.target.files[0],
    });
  };

  // --------------------Resume---------------------------//
  const handleResume = () => {
    var checkBox = document.getElementById("resume");
    var text = document.getElementById("showResume");
    if (checkBox.checked == true) {
      text.style.display = "inline-flex";
    } else {
      text.style.display = "none";
    }
  };
  // --------------------Education Transcript & Degree---------------------------//
  const handleETD = () => {
    var checkBox = document.getElementById("etd");
    var text = document.getElementById("showETD");
    if (checkBox.checked == true) {
      text.style.display = "inline-flex";
    } else {
      text.style.display = "none";
    }
  };

  // --------------------Passport---------------------------//
  const handlePassport = () => {
    var checkBox = document.getElementById("passport");
    var text = document.getElementById("showPassport");
    if (checkBox.checked == true) {
      text.style.display = "inline-flex";
    } else {
      text.style.display = "none";
    }
  };

  // --------------------TSC---------------------------//
  const handleTSC = () => {
    var checkBox = document.getElementById("tsc");
    var text = document.getElementById("showTSC");
    if (checkBox.checked == true) {
      text.style.display = "inline-flex";
    } else {
      text.style.display = "none";
    }
  };

  // --------------------SOP---------------------------//
  const handleSOP = () => {
    var checkBox = document.getElementById("sop");
    var text = document.getElementById("showSOP");
    if (checkBox.checked == true) {
      text.style.display = "inline-flex";
    } else {
      text.style.display = "none";
    }
  };
  // --------------------IELTS---------------------------//
  const handleIELTS2 = () => {
    var checkBox = document.getElementById("ielts2");
    var text = document.getElementById("showIELTS2");
    if (checkBox.checked == true) {
      text.style.display = "inline-flex";
    } else {
      text.style.display = "none";
    }
  };
  // --------------------Finance Documents---------------------------//
  const handleFD = () => {
    var checkBox = document.getElementById("fd");
    var text = document.getElementById("showFD");
    if (checkBox.checked == true) {
      text.style.display = "inline-flex";
    } else {
      text.style.display = "none";
    }
  };
  // --------------------Additional Documents---------------------------//
  const handleAD = () => {
    var checkBox = document.getElementById("ad");
    var text = document.getElementById("showAD");
    if (checkBox.checked == true) {
      text.style.display = "inline-flex";
    } else {
      text.style.display = "none";
    }
  };
  //--------------------GRE---------------------------//
  const handleGRE = () => {
    var checkBox = document.getElementById("gre");
    var text = document.getElementById("showGRE");
    if (checkBox.checked == true) {
      text.style.display = "inline-flex";
    } else {
      text.style.display = "none";
    }
  };

  //--------------------GMAT---------------------------//
  const handleGMAT = () => {
    var checkBox = document.getElementById("gmat");
    var text = document.getElementById("showGMAT");
    if (checkBox.checked == true) {
      text.style.display = "inline-flex";
    } else {
      text.style.display = "none";
    }
  };

  //--------------------SAT---------------------------//
  const handleSAT = () => {
    var checkBox = document.getElementById("sat");
    var text = document.getElementById("showSAT");
    if (checkBox.checked == true) {
      text.style.display = "inline-flex";
    } else {
      text.style.display = "none";
    }
  };

  //--------------------ACT---------------------------//
  const handleACT = () => {
    var checkBox = document.getElementById("act");
    var text = document.getElementById("showACT");
    if (checkBox.checked == true) {
      text.style.display = "inline-flex";
    } else {
      text.style.display = "none";
    }
  };

  //--------------------TOEFL---------------------------//
  const handleTOEFL = () => {
    var checkBox = document.getElementById("toefl");
    var text = document.getElementById("showTOEFL");
    if (checkBox.checked == true) {
      text.style.display = "inline-flex";
    } else {
      text.style.display = "none";
    }
  };

  //--------------------IELTS---------------------------//
  const handleIELTS = () => {
    var checkBox = document.getElementById("ielts");
    var text = document.getElementById("showIELTS");
    if (checkBox.checked == true) {
      text.style.display = "inline-flex";
    } else {
      text.style.display = "none";
    }
  };

  //--------------------DUOLINGO---------------------------//

  const handleDuo = () => {
    var checkBox = document.getElementById("duolingo");
    var text = document.getElementById("showDuo");
    if (checkBox.checked == true) {
      text.style.display = "inline-flex";
    } else {
      text.style.display = "none";
    }
  };

  //--------------------PTE---------------------------//
  const handlePTE = () => {
    var checkBox = document.getElementById("pte");
    var text = document.getElementById("showPTE");
    if (checkBox.checked == true) {
      text.style.display = "inline-flex";
    } else {
      text.style.display = "none";
    }
  };
  //---------------------------------------------------------//

  // const handlePicture = (event) => {
  //   setPicturePath(URL.createObjectURL(event.target.files[0]));
  //   setPicture(event.target.files[0]);
  // };

  // const handleVerifyPassword = (e) => {
  //   setVerifyPassword(e.target.value);
  //   if (password === e.target.value) {
  //     setStatus("Password Matched");
  //   } else {
  //     setStatus("Password not matched");
  //   }
  // };

  // const addNewRecord = async () => {
  //   if (picture == "") {
  //     var body = {
  //       firstName: firstName,
  //       lastName: lastName,
  //       emailId: emailId,
  //       password: password,
  //       status: "Not Approved",
  //       picture: "Not Found",
  //     };
  //     var result = await postData("selfregister/addNewRecord", body);
  //     if (result) {
  //       await setMessages("Agent Succefully Created");

  //       var body = {
  //         emailid: emailId,
  //         password: password,
  //       };
  //       var resultt = await postData("selfregister/checkagentsignin", body);

  //       if (resultt.RESULT) {
  //         localStorage.setItem("AGENT", JSON.stringify(resultt.RESULT)); // JSON.stringify use for change json to string
  //         props.history.push({ pathname: "/Dashboard" }); // we can use push(back) or replace(not back)
  //       } else {
  //         alert("Failed To Created Account");
  //       }

  //       await setFirstName("");
  //       await setLastName("");
  //       await setEmailId("");
  //       await setPassword("");
  //       await setVerifyPassword("");
  //       await setPicturePath("");
  //     } else {
  //       setMessages("Faild to Submit");
  //     }
  //   } else {
  //     var formData = new FormData();
  //     formData.append("firstName", firstName);
  //     formData.append("lastName", lastName);
  //     formData.append("emailId", emailId);
  //     formData.append("password", password);
  //     formData.append("picture", picture);
  //     formData.append("status", "Not Approve");

  //     var config = { headers: { "content-type": "multipart/form-data" } };
  //     var result = await postDataAndImage(
  //       "selfregister/addNewRecordPic",
  //       formData,
  //       config
  //     );

  //     if (result) {
  //       await setMessages("Agent Succefully Created");

  //       var body = {
  //         emailid: emailId,
  //         password: password,
  //       };
  //       var resultt = await postData("selfregister/checkagentsignin", body);

  //       if (resultt.RESULT) {
  //         localStorage.setItem("AGENT", JSON.stringify(resultt.RESULT)); // JSON.stringify use for change json to string
  //         props.history.push({ pathname: "/Dashboard" }); // we can use push(back) or replace(not back)
  //       } else {
  //         alert("Failed To Created Account");
  //       }

  //       await setFirstName("");
  //       await setLastName("");
  //       await setEmailId("");
  //       await setPassword("");
  //       await setVerifyPassword("");
  //       await setPicturePath("");
  //     } else {
  //       setMessages("Faild to Submit");
  //     }
  //   }
  // };
  // const [selectedDate, setSelectedDate] = React.useState(
  //   new Date("2020-07-25T21:11:54")
  // );

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };

  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = state;

  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const [moveToNext, setMoveToNext] = React.useState(false);

  const handleMove = () => {
    console.log("next button pressed");
  };

  // console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />
      <div
        className="login-area default-padding"
        style={{ backgroundColor: "rgb(57, 69, 139)" }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              {/* <div className="col-md-6 col-md-offset-1">
                <img style={{flex:1}} class="login_image" src="assets/img/login.png" alt="Login" data-original-title="" title="" />

                </div> */}
              <div className="">
                <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <CssBaseline />

                  <MDBContainer>
                    <MDBRow>
                      <MDBCol md="12">
                        <MDBCard>
                          <MDBCardBody>
                            <MDBCardHeader
                              className="form-header deep-blue-gradient rounded"
                              style={{
                                color: "white",
                                textAlign: "center",
                                padding: "2px 2px",
                              }}
                            >
                              <h3 className="my-1">
                                <MDBIcon icon="profile" /> Profile Builder
                              </h3>
                            </MDBCardHeader>
                            <div className={classes.paper}>
                              <div class="col-md-12 form-box">
                                <div
                                  role="form"
                                  class="registration-form"
                                  action="javascript:void(0);"
                                >
                                  <fieldset>
                                    <div class="form-bottom">
                                      <h4>Personal Information</h4>
                                      <p>(As indicated on your passport)</p>
                                      <hr />
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            name="firstName"
                                            //error={firstNameError}
                                            variant="outlined"
                                            className="input-error"
                                            fullWidth
                                            onChange={(event)=>setFirstName(event.target.value)}
                                            value={firstName}
                                            label="First Name"
                                            type="text"
                                            autoFocus
                                          />
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            name="lname"
                                            //error={lastNameError}
                                            variant="outlined"
                                            fullWidth
                                            onChange={(event)=>setLastName(event.target.value)}                                      
                                            
                                            // onChangeText={1
                                            //   (value) => setLastName(value)
                                            // }
                                            value={lastName}
                                            label="Last Name"
                                            type="text"
                                            autoFocus
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            name="fullname"
                                            //error={fullNameError}
                                            variant="outlined"
                                            fullWidth
                                            value={fullName}
                                            onChange={(event)=>setFullName(event.target.value)}
                                            label="Full Name (as per the Passport)"
                                            type="text"
                                            autoFocus
                                          />
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                          {/* <TextField
                                            name="dob"
                                            id="dob"
                                            type="date"
                                            value={dob}
                                            fullWidth
                                            label="DOB"
                                            variant="outlined"
                                            onChange={(event) => setDob(event)}
                                            // InputLabelProps={{
                                            //   shrink: true,
                                            // }}
                                          /> */}
                                          <MuiPickersUtilsProvider
                                            style={{ width: "100%" }}
                                            fullWidth
                                            utils={DateFnsUtils}
                                          >
                                            <KeyboardDatePicker
                                              autoOk
                                              variant="inline"
                                              inputVariant="outlined"
                                              fullWidth
                                              InputAdornmentProps={{
                                                position: "end",
                                              }}
                                              style={{ width: "100%" }}
                                              id="date-picker-dialog"
                                              label="Date of Birth"
                                              //format="dd/MM/yyyy"
                                              format="yyyy/MM/dd"
                                              value={dob}
                                              onChange={(event) =>
                                                setDob(event)
                                              }
                                              KeyboardButtonProps={{
                                                "aria-label": "change date",
                                              }}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                            />
                                          </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            id="outlined-helperText"
                                            name="email"
                                            variant="outlined"
                                            fullWidth
                                            value={email}
                                            onChange={(event) => {setEmailIdError(false);setEmail(event.target.value)}}
                                            label="Email"
                                            type="email"
                                            autoFocus
                                            helperText="Mandatory"
                                            variant="outlined"
                                          />
                                          
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            name="mobile"
                                            variant="outlined"
                                            fullWidth
                                            
                                            label="Mobile Number"
                                            type="number"
                                            value={mobile}
                                            //error={mobileError}
                                            //error={mobile}
                                            autoFocus
                                            onChange={(event) =>
                                              setMobile(event.target.value)
                                            }
                                          />
                                        </Grid>

                                        {/* <Grid item xs={12} sm={4}>


            
        <FormControl  fullWidth>
         <InputLabel >Country of Citizenship</InputLabel>
        <Select  >
          <MenuItem  value={10}>India</MenuItem>
          <MenuItem  value={20}>USA</MenuItem>
          <MenuItem  value={30}>Canada</MenuItem>
        </Select>
      </FormControl>


          </Grid> */}

                                        <Grid item xs={12} sm={4}>
                                          <h6 style={{ marginBottom: 0 }}>
                                            Gender
                                          </h6>
                                          <RadioGroup
                                            row
                                            aria-label="gender"
                                            name="gender1"
                                            value={gender}
                                            onChange={(event) =>
                                              setGender(event.target.value)
                                            }
                                          >
                                            <FormControlLabel
                                              value="female"
                                              control={<Radio />}
                                              label="Female"
                                              color="primary"
                                            />
                                            <FormControlLabel
                                              value="male"
                                              control={<Radio />}
                                              label="Male"
                                              color="primary"
                                            />
                                          </RadioGroup>
                                          {/* <label>Male</label>
                                          <Radio
                                            variant="outlined"
                                            style={{ color: "#3f8ccd" }}
                                            fullWidth
                                            name="male"
                                            type="radio"
                                          />

                                          <label>Female</label>
                                          <Radio
                                            variant="outlined"
                                            style={{ color: "#3f8ccd" }}
                                            fullWidth
                                            name="female"
                                            type="radio"
                                          /> */}
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            id="outlined-select-currency"
                                            select
                                            label="Nationality"
                                            fullWidth
                                            onChange={(event) =>
                                              setNationality(event.target.value)
                                            }
                                            value={nationality}
                                            //helperText="Please select your currency"
                                            variant="outlined"
                                          >
                                            {/* <MenuItem value={"India"}>
                                              India
                                            </MenuItem> */}
                                            <MenuItem value={"Afghanistan"}>
                                              Afghanistan
                                            </MenuItem>
                                            <MenuItem value={"Albania"}>
                                              Albania
                                            </MenuItem>
                                            <MenuItem value={"Algeria"}>
                                              Algeria
                                            </MenuItem>
                                            <MenuItem value={"Andorra"}>
                                              Andorra
                                            </MenuItem>
                                            <MenuItem value={"Angola"}>
                                              Angola
                                            </MenuItem>
                                            <MenuItem
                                              value={"Antigua and Barbuda"}
                                            >
                                              Antigua and Barbuda
                                            </MenuItem>
                                            <MenuItem value={"Argentina"}>
                                              Argentina
                                            </MenuItem>
                                            <MenuItem value={"Armenia"}>
                                              Armenia
                                            </MenuItem>
                                            <MenuItem value={"Australia"}>
                                              Australia
                                            </MenuItem>
                                            <MenuItem value={"Austria"}>
                                              Austria
                                            </MenuItem>
                                            <MenuItem value={"Austrian Empire"}>
                                              Austrian Empire
                                            </MenuItem>
                                            <MenuItem value={"Azerbaijan"}>
                                              Azerbaijan
                                            </MenuItem>

                                            <MenuItem value={"Bahamas"}>
                                              Bahamas
                                            </MenuItem>
                                            <MenuItem value={"Bahrain"}>
                                              Bahrain
                                            </MenuItem>
                                            <MenuItem value={"Bangladesh"}>
                                              Bangladesh
                                            </MenuItem>
                                            <MenuItem value={"Barbados"}>
                                              Barbados
                                            </MenuItem>
                                            <MenuItem value={"Belarus"}>
                                              Belarus
                                            </MenuItem>
                                            <MenuItem value={"Belgium"}>
                                              Belgium
                                            </MenuItem>
                                            <MenuItem value={"Belize"}>
                                              Belize
                                            </MenuItem>
                                            <MenuItem value={"Benin"}>
                                              Benin
                                            </MenuItem>
                                            <MenuItem value={"Bolivia"}>
                                              Bolivia
                                            </MenuItem>
                                            <MenuItem
                                              value={"Bosnia and Herzegovina"}
                                            >
                                              Bosnia and Herzegovina
                                            </MenuItem>
                                            <MenuItem value={"Botswana"}>
                                              Botswana
                                            </MenuItem>
                                            <MenuItem value={"Brazil"}>
                                              Brazil
                                            </MenuItem>
                                            <MenuItem value={"Brunei"}>
                                              Brunei
                                            </MenuItem>
                                            <MenuItem
                                              value={"Brunswick and Lüneburg"}
                                            >
                                              Brunswick and Lüneburg
                                            </MenuItem>
                                            <MenuItem value={"Bulgaria"}>
                                              Bulgaria
                                            </MenuItem>
                                            <MenuItem value={"Burkina Faso"}>
                                              Burkina Faso
                                            </MenuItem>
                                            <MenuItem value={"Burma"}>
                                              Burma
                                            </MenuItem>
                                            <MenuItem value={"Burundi"}>
                                              Burundi
                                            </MenuItem>
                                            <MenuItem value={"Cambodia"}>
                                              Cambodia
                                            </MenuItem>
                                            <MenuItem value={"Cameroon"}>
                                              Cameroon
                                            </MenuItem>
                                            <MenuItem value={"Canada"}>
                                              Canada
                                            </MenuItem>
                                            <MenuItem value={"Cape Verde"}>
                                              Cape Verde
                                            </MenuItem>
                                            <MenuItem
                                              value={"Central African Republic"}
                                            >
                                              Central African Republic
                                            </MenuItem>
                                            <MenuItem value={"Chad"}>
                                              Chad
                                            </MenuItem>
                                            <MenuItem value={"Chile"}>
                                              Chile
                                            </MenuItem>
                                            <MenuItem value={"China"}>
                                              China
                                            </MenuItem>
                                            <MenuItem value={"Colombia"}>
                                              Colombia
                                            </MenuItem>
                                            <MenuItem value={"Comoros"}>
                                              Comoros
                                            </MenuItem>
                                            <MenuItem
                                              value={"Congo, Republic of the"}
                                            >
                                              Congo, Republic of the
                                            </MenuItem>
                                            <MenuItem
                                              value={
                                                "Congo, Democratic Republic of the"
                                              }
                                            >
                                              Congo, Democratic Republic of the
                                            </MenuItem>
                                            <MenuItem value={"Costa Rica"}>
                                              Costa Rica
                                            </MenuItem>
                                            <MenuItem value={"Cote d'Ivoire"}>
                                              Cote d'Ivoire
                                            </MenuItem>
                                            <MenuItem value={"Croatia"}>
                                              Croatia
                                            </MenuItem>
                                            <MenuItem value={"Cuba"}>
                                              Cuba
                                            </MenuItem>
                                            <MenuItem value={"Cyprus"}>
                                              Cyprus
                                            </MenuItem>
                                            <MenuItem value={"Czech Republic"}>
                                              Czech Republic
                                            </MenuItem>
                                            <MenuItem value={"Denmark"}>
                                              Denmark
                                            </MenuItem>
                                            <MenuItem value={"Djibouti"}>
                                              Djibouti
                                            </MenuItem>
                                            <MenuItem value={"Dominica"}>
                                              Dominica
                                            </MenuItem>
                                            <MenuItem
                                              value={"Dominican Republic"}
                                            >
                                              Dominican Republic
                                            </MenuItem>
                                            <MenuItem value={"East Timor"}>
                                              East Timor
                                            </MenuItem>
                                            <MenuItem value={"Ecuador"}>
                                              Ecuador
                                            </MenuItem>
                                            <MenuItem value={"Egypt"}>
                                              Egypt
                                            </MenuItem>
                                            <MenuItem value={"El Salvador"}>
                                              El Salvador
                                            </MenuItem>
                                            <MenuItem
                                              value={"Equatorial Guinea"}
                                            >
                                              Equatorial Guinea
                                            </MenuItem>
                                            <MenuItem value={"Eritrea"}>
                                              Eritrea
                                            </MenuItem>
                                            <MenuItem value={"Estonia"}>
                                              Estonia
                                            </MenuItem>
                                            <MenuItem value={"Ethiopia"}>
                                              Ethiopia
                                            </MenuItem>
                                            <MenuItem value={"Fiji"}>
                                              Fiji
                                            </MenuItem>
                                            <MenuItem value={"Finland"}>
                                              Finland
                                            </MenuItem>
                                            <MenuItem value={"France"}>
                                              France
                                            </MenuItem>
                                            <MenuItem value={"Gabon"}>
                                              Gabon
                                            </MenuItem>
                                            <MenuItem value={"The Gambia"}>
                                              The Gambia
                                            </MenuItem>
                                            <MenuItem value={"Georgia"}>
                                              Georgia
                                            </MenuItem>
                                            <MenuItem value={"Germany"}>
                                              Germany
                                            </MenuItem>
                                            <MenuItem value={"Ghana"}>
                                              Ghana
                                            </MenuItem>
                                            <MenuItem value={"Greece"}>
                                              Greece
                                            </MenuItem>
                                            <MenuItem value={"Grenada"}>
                                              Grenada
                                            </MenuItem>
                                            <MenuItem value={"Guatemala"}>
                                              Guatemala
                                            </MenuItem>
                                            <MenuItem value={"Guinea"}>
                                              Guinea
                                            </MenuItem>
                                            <MenuItem value={"Guinea-Bissau"}>
                                              Guinea-Bissau
                                            </MenuItem>
                                            <MenuItem value={"Guyana"}>
                                              Guyana
                                            </MenuItem>
                                            <MenuItem value={"Haiti"}>
                                              Haiti
                                            </MenuItem>
                                            <MenuItem value={"Honduras"}>
                                              Honduras
                                            </MenuItem>
                                            <MenuItem value={"Hungary"}>
                                              Hungary
                                            </MenuItem>
                                            <MenuItem value={"Iceland"}>
                                              Iceland
                                            </MenuItem>
                                            <MenuItem value={"India"}>
                                              India
                                            </MenuItem>
                                            <MenuItem value={"Indonesia"}>
                                              Indonesia
                                            </MenuItem>
                                            <MenuItem value={"Iran"}>
                                              Iran
                                            </MenuItem>
                                            <MenuItem value={"Iraq"}>
                                              Iraq
                                            </MenuItem>
                                            <MenuItem value={"Ireland"}>
                                              Ireland
                                            </MenuItem>
                                            <MenuItem value={"Israel"}>
                                              Israel
                                            </MenuItem>
                                            <MenuItem value={"Italy"}>
                                              Italy
                                            </MenuItem>
                                            <MenuItem value={"Jamaica"}>
                                              Jamaica
                                            </MenuItem>
                                            <MenuItem value={"Japan"}>
                                              Japan
                                            </MenuItem>
                                            <MenuItem value={"Jordan"}>
                                              Jordan
                                            </MenuItem>
                                            <MenuItem value={"Kazakhstan"}>
                                              Kazakhstan
                                            </MenuItem>
                                            <MenuItem value={"Kenya"}>
                                              Kenya
                                            </MenuItem>
                                            <MenuItem value={"Kiribati"}>
                                              Kiribati
                                            </MenuItem>
                                            <MenuItem value={"Korea, North"}>
                                              Korea, North
                                            </MenuItem>
                                            <MenuItem value={"Korea, South<"}>
                                              Korea, South
                                            </MenuItem>
                                            <MenuItem value={"Kosovo"}>
                                              Kosovo
                                            </MenuItem>
                                            <MenuItem value={"Kuwait"}>
                                              Kuwait
                                            </MenuItem>
                                            <MenuItem value={"Kyrgyzstan"}>
                                              Kyrgyzstan
                                            </MenuItem>
                                            <MenuItem value={"Laos"}>
                                              Laos
                                            </MenuItem>
                                            <MenuItem value={"Latvia"}>
                                              Latvia
                                            </MenuItem>
                                            <MenuItem value={"Lebanon"}>
                                              Lebanon
                                            </MenuItem>
                                            <MenuItem value={"Lesotho"}>
                                              Lesotho
                                            </MenuItem>
                                            <MenuItem value={"Liberia"}>
                                              Liberia
                                            </MenuItem>
                                            <MenuItem value={"Libya"}>
                                              Libya
                                            </MenuItem>
                                            <MenuItem value={"Liechtenstein"}>
                                              Liechtenstein
                                            </MenuItem>
                                            <MenuItem value={"Lithuania"}>
                                              Lithuania
                                            </MenuItem>
                                            <MenuItem value={"Luxembourg"}>
                                              Luxembourg
                                            </MenuItem>
                                            <MenuItem value={"Macedonia"}>
                                              Macedonia
                                            </MenuItem>
                                            <MenuItem value={"Madagascar"}>
                                              Madagascar
                                            </MenuItem>
                                            <MenuItem value={"Malawi"}>
                                              Malawi
                                            </MenuItem>
                                            <MenuItem value={"Malaysia"}>
                                              Malaysia
                                            </MenuItem>
                                            <MenuItem value={"Maldives"}>
                                              Maldives
                                            </MenuItem>
                                            <MenuItem value={"Mali"}>
                                              Mali
                                            </MenuItem>
                                            <MenuItem value={"Malta"}>
                                              Malta
                                            </MenuItem>
                                            <MenuItem
                                              value={"Marshall Islands"}
                                            >
                                              Marshall Islands
                                            </MenuItem>
                                            <MenuItem value={"Mauritania"}>
                                              Mauritania
                                            </MenuItem>
                                            <MenuItem value={"Mauritius"}>
                                              Mauritius
                                            </MenuItem>
                                            <MenuItem value={"Mexico"}>
                                              Mexico
                                            </MenuItem>
                                            <MenuItem value={"Micronesia"}>
                                              Micronesia
                                            </MenuItem>
                                            <MenuItem value={"Moldova"}>
                                              Moldova
                                            </MenuItem>
                                            <MenuItem value={"Monaco"}>
                                              Monaco
                                            </MenuItem>
                                            <MenuItem value={"Mongolia"}>
                                              Mongolia
                                            </MenuItem>
                                            <MenuItem value={"Montenegro"}>
                                              Montenegro
                                            </MenuItem>
                                            <MenuItem value={"Morocco"}>
                                              Morocco
                                            </MenuItem>
                                            <MenuItem value={"Mozambique"}>
                                              Mozambique
                                            </MenuItem>
                                            <MenuItem value={"Myanmar"}>
                                              Myanmar
                                            </MenuItem>
                                            <MenuItem value={"Namibia"}>
                                              Namibia
                                            </MenuItem>
                                            <MenuItem value={"Nauru"}>
                                              Nauru
                                            </MenuItem>
                                            <MenuItem value={"Nepal"}>
                                              Nepal
                                            </MenuItem>
                                            <MenuItem value={"Netherlands"}>
                                              Netherlands
                                            </MenuItem>
                                            <MenuItem value={"New Zealand"}>
                                              New Zealand
                                            </MenuItem>
                                            <MenuItem value={"Nicaragua"}>
                                              Nicaragua
                                            </MenuItem>
                                            <MenuItem value={"Niger"}>
                                              Niger
                                            </MenuItem>
                                            <MenuItem value={"Nigeria"}>
                                              Nigeria
                                            </MenuItem>
                                            <MenuItem value={"Norway"}>
                                              Norway
                                            </MenuItem>
                                            <MenuItem value={"Oman"}>
                                              Oman
                                            </MenuItem>
                                            <MenuItem value={"Pakistan"}>
                                              Pakistan
                                            </MenuItem>
                                            <MenuItem value={"Palau"}>
                                              Palau
                                            </MenuItem>
                                            <MenuItem value={"Panama"}>
                                              Panama
                                            </MenuItem>
                                            <MenuItem
                                              value={"Papua New Guinea"}
                                            >
                                              Papua New Guinea
                                            </MenuItem>
                                            <MenuItem value={"Paraguay"}>
                                              Paraguay
                                            </MenuItem>
                                            <MenuItem value={"Peru"}>
                                              Peru
                                            </MenuItem>
                                            <MenuItem value={"Philippines"}>
                                              Philippines
                                            </MenuItem>
                                            <MenuItem value={"Poland"}>
                                              Poland
                                            </MenuItem>
                                            <MenuItem value={"Portugal"}>
                                              Portugal
                                            </MenuItem>
                                            <MenuItem value={"Qatar"}>
                                              Qatar
                                            </MenuItem>
                                            <MenuItem value={"Romania"}>
                                              Romania
                                            </MenuItem>
                                            <MenuItem value={"Russia"}>
                                              Russia
                                            </MenuItem>
                                            <MenuItem value={"Rwanda"}>
                                              Rwanda
                                            </MenuItem>
                                            <MenuItem
                                              value={"Saint Kitts and Nevis"}
                                            >
                                              Saint Kitts and Nevis
                                            </MenuItem>
                                            <MenuItem value={"Saint Lucia"}>
                                              Saint Lucia
                                            </MenuItem>
                                            <MenuItem
                                              value={
                                                "Saint Vincent and the Grenadines"
                                              }
                                            >
                                              Saint Vincent and the Grenadines
                                            </MenuItem>
                                            <MenuItem value={"Samoa"}>
                                              Samoa
                                            </MenuItem>
                                            <MenuItem value={"San Marino"}>
                                              San Marino
                                            </MenuItem>
                                            <MenuItem
                                              value={"Sao Tome and Principe"}
                                            >
                                              Sao Tome and Principe
                                            </MenuItem>
                                            <MenuItem value={"Saudi Arabia"}>
                                              Saudi Arabia
                                            </MenuItem>
                                            <MenuItem value={"Senegal"}>
                                              Senegal
                                            </MenuItem>
                                            <MenuItem value={"Serbia"}>
                                              Serbia
                                            </MenuItem>
                                            <MenuItem value={"Seychelles"}>
                                              Seychelles
                                            </MenuItem>
                                            <MenuItem value={"Sierra Leone"}>
                                              Sierra Leone
                                            </MenuItem>
                                            <MenuItem value={"Singapore"}>
                                              Singapore
                                            </MenuItem>
                                            <MenuItem value={"Slovakia"}>
                                              Slovakia
                                            </MenuItem>
                                            <MenuItem value={"Slovenia"}>
                                              Slovenia
                                            </MenuItem>
                                            <MenuItem value={"Solomon Islands"}>
                                              Solomon Islands
                                            </MenuItem>
                                            <MenuItem value={"Somalia"}>
                                              Somalia
                                            </MenuItem>
                                            <MenuItem value={"South Africa"}>
                                              South Africa
                                            </MenuItem>
                                            <MenuItem value={"South Sudan"}>
                                              South Sudan
                                            </MenuItem>
                                            <MenuItem value={"Spain"}>
                                              Spain
                                            </MenuItem>
                                            <MenuItem value={"Sri Lanka"}>
                                              Sri Lanka
                                            </MenuItem>
                                            <MenuItem value={"Sudan"}>
                                              Sudan
                                            </MenuItem>
                                            <MenuItem value={"Suriname"}>
                                              Suriname
                                            </MenuItem>
                                            <MenuItem value={"Swaziland"}>
                                              Swaziland
                                            </MenuItem>
                                            <MenuItem value={"Sweden"}>
                                              Sweden
                                            </MenuItem>
                                            <MenuItem value={"Switzerland"}>
                                              Switzerland
                                            </MenuItem>
                                            <MenuItem value={"Syria"}>
                                              Syria
                                            </MenuItem>
                                            <MenuItem value={"Taiwan"}>
                                              Taiwan
                                            </MenuItem>
                                            <MenuItem value={"Tajikistan"}>
                                              Tajikistan
                                            </MenuItem>
                                            <MenuItem value={"Tanzania"}>
                                              Tanzania
                                            </MenuItem>
                                            <MenuItem value={"Thailand"}>
                                              Thailand
                                            </MenuItem>
                                            <MenuItem value={"Togo"}>
                                              Togo
                                            </MenuItem>
                                            <MenuItem value={"Tonga"}>
                                              Tonga
                                            </MenuItem>
                                            <MenuItem
                                              value={"Trinidad and Tobago"}
                                            >
                                              Trinidad and Tobago
                                            </MenuItem>
                                            <MenuItem value={"Tunisia"}>
                                              Tunisia
                                            </MenuItem>
                                            <MenuItem value={"Turkey"}>
                                              Turkey
                                            </MenuItem>
                                            <MenuItem value={"Turkmenistan"}>
                                              Turkmenistan
                                            </MenuItem>
                                            <MenuItem value={"Tuvalu"}>
                                              Tuvalu
                                            </MenuItem>
                                            <MenuItem value={"Uganda"}>
                                              Uganda
                                            </MenuItem>
                                            <MenuItem value={"Ukraine"}>
                                              Ukraine
                                            </MenuItem>
                                            <MenuItem
                                              value={"United Arab Emirates"}
                                            >
                                              United Arab Emirates
                                            </MenuItem>
                                            <MenuItem value={"United Kingdom"}>
                                              United Kingdom
                                            </MenuItem>
                                            <MenuItem
                                              value={"United States of America"}
                                            >
                                              United States of America
                                            </MenuItem>
                                            <MenuItem value={"Uruguay"}>
                                              Uruguay
                                            </MenuItem>
                                            <MenuItem value={"Uzbekistan"}>
                                              Uzbekistan
                                            </MenuItem>
                                            <MenuItem value={"Vanuatu"}>
                                              Vanuatu
                                            </MenuItem>
                                            <MenuItem value={"Vatican City"}>
                                              Vatican City
                                            </MenuItem>
                                            <MenuItem value={"Venezuela"}>
                                              Venezuela
                                            </MenuItem>
                                            <MenuItem value={"Vietnam"}>
                                              Vietnam
                                            </MenuItem>
                                            <MenuItem value={"Yemen"}>
                                              Yemen
                                            </MenuItem>
                                            <MenuItem value={"Zambia"}>
                                              Zambia
                                            </MenuItem>
                                            <MenuItem value={"Zimbabwe"}>
                                              Zimbabwe
                                            </MenuItem>
                                          </TextField>
                                        </Grid>

                                        {/* <Grid item xs={12} sm={4}>
                                          <FormControl fullWidth>
                                            <InputLabel>Nationality</InputLabel>
                                            <Select>
                                              <MenuItem value={10}>
                                                India
                                              </MenuItem>
                                              <MenuItem value={20}>
                                                USA
                                              </MenuItem>
                                              <MenuItem value={30}>
                                                Canada
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Grid> */}

                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            name="pnumber"
                                            variant="outlined"
                                            fullWidth
                                            onChange={(event) =>
                                              setPassportNO(event.target.value)
                                            }
                                            label="Passport Number"
                                            type="text"
                                            value={passportNo}
                                            autoFocus
                                          />
                                        </Grid>

                                        {/* <Grid item xs={12} sm={4}>
          <h6 style={{marginBottom:0,}}>Marital Status</h6>
          <label>Single</label>
          <Radio variant="outlined" style={{color:'#3f8ccd'}}  fullWidth name="Single" type="radio"/>

         <label>Married</label>
         <Radio variant="outlined"   style={{color:'#3f8ccd'}}fullWidth name="Married" type="radio"/>
          </Grid> */}

                                        <Grid item xs={12}>
                                          {/* <MuiPhoneInput defaultCountry='in' regions={'asia'} variant="outlined" fullWidth  label="Phone"/> */}
                                        </Grid>

                                        <Grid
                                          item
                                          xs={12}
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <div className="blog-area">
                                            <div className="info">
                                              <div
                                                className="content"
                                                style={{
                                                  padding: 10,
                                                  position: "static",
                                                }}
                                              >
                                                <a
                                                  class="btn btn-next"
                                                  style={{
                                                    color: "#000",
                                                    textAlign: "center",
                                                    whiteSpace: "nowrap",
                                                    fontSize: 12,
                                                  }}
                                                  onClick={() => checkEmail()}
                                                  //onClick={handleCheck}
                                                >
                                                  Next{" "}
                                                  <i
                                                    className="fa fa-arrow-right"
                                                    style={{ color: "#000" }}
                                                  ></i>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </Grid>
                                        {/* <Grid item xs={12} sm={12}>
                                          <Typography>
                                              <center>{msg}</center>
                                            </Typography>
                                          </Grid> */}
                                      </Grid>

                                      {/* <button type="button" class="btn btn-next">Next</button> */}
                                    </div>
                                  </fieldset>

                                  {/* <fieldset style={{ display: "none" }}>
                                    <div class="form-bottom">
                                      

                                        <Grid
                                          item
                                          xs={12}
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <div className="blog-area">
                                            <div className="info">
                                              <div
                                                className="content"
                                                style={{
                                                  padding: 10,
                                                  position: "static",
                                                }}
                                              >
                                                <a
                                                  class="btn btn-previous"
                                                  style={{
                                                    color: "#000",
                                                    textAlign: "center",
                                                    whiteSpace: "nowrap",
                                                    fontSize: 12,
                                                  }}
                                                >
                                                  <i
                                                    className="fa fa-arrow-left"
                                                    style={{ color: "#000" }}
                                                  ></i>{" "}
                                                  Previous
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="blog-area">
                                            <div className="info">
                                              <div
                                                className="content"
                                                style={{
                                                  padding: 10,
                                                  position: "static",
                                                }}
                                              >
                                                <a
                                                  class="btn btn-next"
                                                  style={{
                                                    color: "#000",
                                                    textAlign: "center",
                                                    whiteSpace: "nowrap",
                                                    fontSize: 12,
                                                  }}
                                                >
                                                  Next{" "}
                                                  <i
                                                    className="fa fa-arrow-right"
                                                    style={{ color: "#000" }}
                                                  ></i>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>

                                      
                                    </div>
                                  </fieldset> */}

                                  {/* <fieldset style={{ display: "none" }}> */}
                                  {/* <div class="form-bottom">
                                      <h4>Emergency Contact Details</h4>

                                      <hr />
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                          <TextField
                                            name="emergencycontactname"
                                            variant="outlined"
                                            className="input-error"
                                            fullWidth
                                            label="Contact Person Name"
                                            type="text"
                                            value={emerPersonName}
                                            onChange={(event) =>
                                              setEmerPersonName(
                                                event.target.value
                                              )
                                            }
                                            autoFocus
                                          />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                          <TextField
                                            name="emergencyemail"
                                            variant="outlined"
                                            fullWidth
                                            label="Email id"
                                            type="email"
                                            value={emeremail}
                                            onChange={(event) =>
                                              setEmerEmail(event.target.value)
                                            }
                                            autoFocus
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <TextField
                                            name="emergencycontactno"
                                            variant="outlined"
                                            fullWidth
                                            label="Contact Number"
                                            type="text"
                                            value={emermobile}
                                            onChange={(event) =>
                                              setEmerMobile(event.target.value)
                                            }
                                            autoFocus
                                          />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                          <TextField
                                            name="emergencyrelation"
                                            variant="outlined"
                                            fullWidth
                                            label="Relationship To Applicant"
                                            type="text"
                                            value={emerrelation}
                                            onChange={(event) =>
                                              setEmerRelation(
                                                event.target.value
                                              )
                                            }
                                            autoFocus
                                          />
                                        </Grid>

                                        <Grid
                                          item
                                          xs={12}
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <div className="blog-area">
                                            <div className="info">
                                              <div
                                                className="content"
                                                style={{
                                                  padding: 10,
                                                  position: "static",
                                                }}
                                              >
                                                <a
                                                  class="btn btn-previous"
                                                  style={{
                                                    color: "#000",
                                                    textAlign: "center",
                                                    whiteSpace: "nowrap",
                                                    fontSize: 12,
                                                  }}
                                                >
                                                  <i
                                                    className="fa fa-arrow-left"
                                                    style={{ color: "#000" }}
                                                  ></i>{" "}
                                                  Previous
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="blog-area">
                                            <div className="info">
                                              <div
                                                className="content"
                                                style={{
                                                  padding: 10,
                                                  position: "static",
                                                }}
                                              >
                                                <a
                                                  class="btn btn-next"
                                                  style={{
                                                    color: "#000",
                                                    textAlign: "center",
                                                    whiteSpace: "nowrap",
                                                    fontSize: 12,
                                                  }}
                                                >
                                                  Next{" "}
                                                  <i
                                                    className="fa fa-arrow-right"
                                                    style={{ color: "#000" }}
                                                  ></i>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>

                                    </div> */}
                                  {/* </fieldset> */}

                                  <fieldset style={{ display: "none" }}>
                                    <div class="form-bottom">
                                      <h4>Address Detail</h4>
                                      <hr />
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                          <TextField
                                            name="address"
                                            variant="outlined"
                                            fullWidth
                                            label="Address"
                                            type="text"
                                            value={address}
                                            onChange={(event) =>
                                              setAddress(event.target.value)
                                            }
                                            autoFocus
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            name="citytown"
                                            variant="outlined"
                                            fullWidth
                                            label="City/Town"
                                            type="text"
                                            value={city}
                                            onChange={(event) =>
                                              setCity(event.target.value)
                                            }
                                            autoFocus
                                          />
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            name="pstate"
                                            variant="outlined"
                                            fullWidth
                                            label="Province/State"
                                            type="text"
                                            value={states}
                                            onChange={(event) =>
                                              setStates(event.target.value)
                                            }
                                            autoFocus
                                          />
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            name="pzc"
                                            variant="outlined"
                                            fullWidth
                                            label="Postal/Zip Code"
                                            type="text"
                                            value={zipCode}
                                            onChange={(event) =>
                                              setZipCode(event.target.value)
                                            }
                                            autoFocus
                                          />
                                        </Grid>

                                        {/* <Grid item xs={12} sm={4}>
          <TextField name="flanguage" variant="outlined"  fullWidth label="First Language" type="text" autoFocus />
          </Grid> */}
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            id="outlined-select-currency"
                                            select
                                            label="Country"
                                            fullWidth
                                            onChange={(event) =>
                                              setCountry(event.target.value)
                                            }
                                            value={country}
                                            //onChange={handleChange}
                                            //helperText="Please select your currency"
                                            variant="outlined"
                                          >
                                            <MenuItem value={"Afghanistan"}>
                                              Afghanistan
                                            </MenuItem>
                                            <MenuItem value={"Albania"}>
                                              Albania
                                            </MenuItem>
                                            <MenuItem value={"Algeria"}>
                                              Algeria
                                            </MenuItem>
                                            <MenuItem value={"Andorra"}>
                                              Andorra
                                            </MenuItem>
                                            <MenuItem value={"Angola"}>
                                              Angola
                                            </MenuItem>
                                            <MenuItem
                                              value={"Antigua and Barbuda"}
                                            >
                                              Antigua and Barbuda
                                            </MenuItem>
                                            <MenuItem value={"Argentina"}>
                                              Argentina
                                            </MenuItem>
                                            <MenuItem value={"Armenia"}>
                                              Armenia
                                            </MenuItem>
                                            <MenuItem value={"Australia"}>
                                              Australia
                                            </MenuItem>
                                            <MenuItem value={"Austria"}>
                                              Austria
                                            </MenuItem>
                                            <MenuItem value={"Austrian Empire"}>
                                              Austrian Empire
                                            </MenuItem>
                                            <MenuItem value={"Azerbaijan"}>
                                              Azerbaijan
                                            </MenuItem>

                                            <MenuItem value={"Bahamas"}>
                                              Bahamas
                                            </MenuItem>
                                            <MenuItem value={"Bahrain"}>
                                              Bahrain
                                            </MenuItem>
                                            <MenuItem value={"Bangladesh"}>
                                              Bangladesh
                                            </MenuItem>
                                            <MenuItem value={"Barbados"}>
                                              Barbados
                                            </MenuItem>
                                            <MenuItem value={"Belarus"}>
                                              Belarus
                                            </MenuItem>
                                            <MenuItem value={"Belgium"}>
                                              Belgium
                                            </MenuItem>
                                            <MenuItem value={"Belize"}>
                                              Belize
                                            </MenuItem>
                                            <MenuItem value={"Benin"}>
                                              Benin
                                            </MenuItem>
                                            <MenuItem value={"Bolivia"}>
                                              Bolivia
                                            </MenuItem>
                                            <MenuItem
                                              value={"Bosnia and Herzegovina"}
                                            >
                                              Bosnia and Herzegovina
                                            </MenuItem>
                                            <MenuItem value={"Botswana"}>
                                              Botswana
                                            </MenuItem>
                                            <MenuItem value={"Brazil"}>
                                              Brazil
                                            </MenuItem>
                                            <MenuItem value={"Brunei"}>
                                              Brunei
                                            </MenuItem>
                                            <MenuItem
                                              value={"Brunswick and Lüneburg"}
                                            >
                                              Brunswick and Lüneburg
                                            </MenuItem>
                                            <MenuItem value={"Bulgaria"}>
                                              Bulgaria
                                            </MenuItem>
                                            <MenuItem value={"Burkina Faso"}>
                                              Burkina Faso
                                            </MenuItem>
                                            <MenuItem value={"Burma"}>
                                              Burma
                                            </MenuItem>
                                            <MenuItem value={"Burundi"}>
                                              Burundi
                                            </MenuItem>
                                            <MenuItem value={"Cambodia"}>
                                              Cambodia
                                            </MenuItem>
                                            <MenuItem value={"Cameroon"}>
                                              Cameroon
                                            </MenuItem>
                                            <MenuItem value={"Canada"}>
                                              Canada
                                            </MenuItem>
                                            <MenuItem value={"Cape Verde"}>
                                              Cape Verde
                                            </MenuItem>
                                            <MenuItem
                                              value={"Central African Republic"}
                                            >
                                              Central African Republic
                                            </MenuItem>
                                            <MenuItem value={"Chad"}>
                                              Chad
                                            </MenuItem>
                                            <MenuItem value={"Chile"}>
                                              Chile
                                            </MenuItem>
                                            <MenuItem value={"China"}>
                                              China
                                            </MenuItem>
                                            <MenuItem value={"Colombia"}>
                                              Colombia
                                            </MenuItem>
                                            <MenuItem value={"Comoros"}>
                                              Comoros
                                            </MenuItem>
                                            <MenuItem
                                              value={"Congo, Republic of the"}
                                            >
                                              Congo, Republic of the
                                            </MenuItem>
                                            <MenuItem
                                              value={
                                                "Congo, Democratic Republic of the"
                                              }
                                            >
                                              Congo, Democratic Republic of the
                                            </MenuItem>
                                            <MenuItem value={"Costa Rica"}>
                                              Costa Rica
                                            </MenuItem>
                                            <MenuItem value={"Cote d'Ivoire"}>
                                              Cote d'Ivoire
                                            </MenuItem>
                                            <MenuItem value={"Croatia"}>
                                              Croatia
                                            </MenuItem>
                                            <MenuItem value={"Cuba"}>
                                              Cuba
                                            </MenuItem>
                                            <MenuItem value={"Cyprus"}>
                                              Cyprus
                                            </MenuItem>
                                            <MenuItem value={"Czech Republic"}>
                                              Czech Republic
                                            </MenuItem>
                                            <MenuItem value={"Denmark"}>
                                              Denmark
                                            </MenuItem>
                                            <MenuItem value={"Djibouti"}>
                                              Djibouti
                                            </MenuItem>
                                            <MenuItem value={"Dominica"}>
                                              Dominica
                                            </MenuItem>
                                            <MenuItem
                                              value={"Dominican Republic"}
                                            >
                                              Dominican Republic
                                            </MenuItem>
                                            <MenuItem value={"East Timor"}>
                                              East Timor
                                            </MenuItem>
                                            <MenuItem value={"Ecuador"}>
                                              Ecuador
                                            </MenuItem>
                                            <MenuItem value={"Egypt"}>
                                              Egypt
                                            </MenuItem>
                                            <MenuItem value={"El Salvador"}>
                                              El Salvador
                                            </MenuItem>
                                            <MenuItem
                                              value={"Equatorial Guinea"}
                                            >
                                              Equatorial Guinea
                                            </MenuItem>
                                            <MenuItem value={"Eritrea"}>
                                              Eritrea
                                            </MenuItem>
                                            <MenuItem value={"Estonia"}>
                                              Estonia
                                            </MenuItem>
                                            <MenuItem value={"Ethiopia"}>
                                              Ethiopia
                                            </MenuItem>
                                            <MenuItem value={"Fiji"}>
                                              Fiji
                                            </MenuItem>
                                            <MenuItem value={"Finland"}>
                                              Finland
                                            </MenuItem>
                                            <MenuItem value={"France"}>
                                              France
                                            </MenuItem>
                                            <MenuItem value={"Gabon"}>
                                              Gabon
                                            </MenuItem>
                                            <MenuItem value={"The Gambia"}>
                                              The Gambia
                                            </MenuItem>
                                            <MenuItem value={"Georgia"}>
                                              Georgia
                                            </MenuItem>
                                            <MenuItem value={"Germany"}>
                                              Germany
                                            </MenuItem>
                                            <MenuItem value={"Ghana"}>
                                              Ghana
                                            </MenuItem>
                                            <MenuItem value={"Greece"}>
                                              Greece
                                            </MenuItem>
                                            <MenuItem value={"Grenada"}>
                                              Grenada
                                            </MenuItem>
                                            <MenuItem value={"Guatemala"}>
                                              Guatemala
                                            </MenuItem>
                                            <MenuItem value={"Guinea"}>
                                              Guinea
                                            </MenuItem>
                                            <MenuItem value={"Guinea-Bissau"}>
                                              Guinea-Bissau
                                            </MenuItem>
                                            <MenuItem value={"Guyana"}>
                                              Guyana
                                            </MenuItem>
                                            <MenuItem value={"Haiti"}>
                                              Haiti
                                            </MenuItem>
                                            <MenuItem value={"Honduras"}>
                                              Honduras
                                            </MenuItem>
                                            <MenuItem value={"Hungary"}>
                                              Hungary
                                            </MenuItem>
                                            <MenuItem value={"Iceland"}>
                                              Iceland
                                            </MenuItem>
                                            <MenuItem value={"India"}>
                                              India
                                            </MenuItem>
                                            <MenuItem value={"Indonesia"}>
                                              Indonesia
                                            </MenuItem>
                                            <MenuItem value={"Iran"}>
                                              Iran
                                            </MenuItem>
                                            <MenuItem value={"Iraq"}>
                                              Iraq
                                            </MenuItem>
                                            <MenuItem value={"Ireland"}>
                                              Ireland
                                            </MenuItem>
                                            <MenuItem value={"Israel"}>
                                              Israel
                                            </MenuItem>
                                            <MenuItem value={"Italy"}>
                                              Italy
                                            </MenuItem>
                                            <MenuItem value={"Jamaica"}>
                                              Jamaica
                                            </MenuItem>
                                            <MenuItem value={"Japan"}>
                                              Japan
                                            </MenuItem>
                                            <MenuItem value={"Jordan"}>
                                              Jordan
                                            </MenuItem>
                                            <MenuItem value={"Kazakhstan"}>
                                              Kazakhstan
                                            </MenuItem>
                                            <MenuItem value={"Kenya"}>
                                              Kenya
                                            </MenuItem>
                                            <MenuItem value={"Kiribati"}>
                                              Kiribati
                                            </MenuItem>
                                            <MenuItem value={"Korea, North"}>
                                              Korea, North
                                            </MenuItem>
                                            <MenuItem value={"Korea, South<"}>
                                              Korea, South
                                            </MenuItem>
                                            <MenuItem value={"Kosovo"}>
                                              Kosovo
                                            </MenuItem>
                                            <MenuItem value={"Kuwait"}>
                                              Kuwait
                                            </MenuItem>
                                            <MenuItem value={"Kyrgyzstan"}>
                                              Kyrgyzstan
                                            </MenuItem>
                                            <MenuItem value={"Laos"}>
                                              Laos
                                            </MenuItem>
                                            <MenuItem value={"Latvia"}>
                                              Latvia
                                            </MenuItem>
                                            <MenuItem value={"Lebanon"}>
                                              Lebanon
                                            </MenuItem>
                                            <MenuItem value={"Lesotho"}>
                                              Lesotho
                                            </MenuItem>
                                            <MenuItem value={"Liberia"}>
                                              Liberia
                                            </MenuItem>
                                            <MenuItem value={"Libya"}>
                                              Libya
                                            </MenuItem>
                                            <MenuItem value={"Liechtenstein"}>
                                              Liechtenstein
                                            </MenuItem>
                                            <MenuItem value={"Lithuania"}>
                                              Lithuania
                                            </MenuItem>
                                            <MenuItem value={"Luxembourg"}>
                                              Luxembourg
                                            </MenuItem>
                                            <MenuItem value={"Macedonia"}>
                                              Macedonia
                                            </MenuItem>
                                            <MenuItem value={"Madagascar"}>
                                              Madagascar
                                            </MenuItem>
                                            <MenuItem value={"Malawi"}>
                                              Malawi
                                            </MenuItem>
                                            <MenuItem value={"Malaysia"}>
                                              Malaysia
                                            </MenuItem>
                                            <MenuItem value={"Maldives"}>
                                              Maldives
                                            </MenuItem>
                                            <MenuItem value={"Mali"}>
                                              Mali
                                            </MenuItem>
                                            <MenuItem value={"Malta"}>
                                              Malta
                                            </MenuItem>
                                            <MenuItem
                                              value={"Marshall Islands"}
                                            >
                                              Marshall Islands
                                            </MenuItem>
                                            <MenuItem value={"Mauritania"}>
                                              Mauritania
                                            </MenuItem>
                                            <MenuItem value={"Mauritius"}>
                                              Mauritius
                                            </MenuItem>
                                            <MenuItem value={"Mexico"}>
                                              Mexico
                                            </MenuItem>
                                            <MenuItem value={"Micronesia"}>
                                              Micronesia
                                            </MenuItem>
                                            <MenuItem value={"Moldova"}>
                                              Moldova
                                            </MenuItem>
                                            <MenuItem value={"Monaco"}>
                                              Monaco
                                            </MenuItem>
                                            <MenuItem value={"Mongolia"}>
                                              Mongolia
                                            </MenuItem>
                                            <MenuItem value={"Montenegro"}>
                                              Montenegro
                                            </MenuItem>
                                            <MenuItem value={"Morocco"}>
                                              Morocco
                                            </MenuItem>
                                            <MenuItem value={"Mozambique"}>
                                              Mozambique
                                            </MenuItem>
                                            <MenuItem value={"Myanmar"}>
                                              Myanmar
                                            </MenuItem>
                                            <MenuItem value={"Namibia"}>
                                              Namibia
                                            </MenuItem>
                                            <MenuItem value={"Nauru"}>
                                              Nauru
                                            </MenuItem>
                                            <MenuItem value={"Nepal"}>
                                              Nepal
                                            </MenuItem>
                                            <MenuItem value={"Netherlands"}>
                                              Netherlands
                                            </MenuItem>
                                            <MenuItem value={"New Zealand"}>
                                              New Zealand
                                            </MenuItem>
                                            <MenuItem value={"Nicaragua"}>
                                              Nicaragua
                                            </MenuItem>
                                            <MenuItem value={"Niger"}>
                                              Niger
                                            </MenuItem>
                                            <MenuItem value={"Nigeria"}>
                                              Nigeria
                                            </MenuItem>
                                            <MenuItem value={"Norway"}>
                                              Norway
                                            </MenuItem>
                                            <MenuItem value={"Oman"}>
                                              Oman
                                            </MenuItem>
                                            <MenuItem value={"Pakistan"}>
                                              Pakistan
                                            </MenuItem>
                                            <MenuItem value={"Palau"}>
                                              Palau
                                            </MenuItem>
                                            <MenuItem value={"Panama"}>
                                              Panama
                                            </MenuItem>
                                            <MenuItem
                                              value={"Papua New Guinea"}
                                            >
                                              Papua New Guinea
                                            </MenuItem>
                                            <MenuItem value={"Paraguay"}>
                                              Paraguay
                                            </MenuItem>
                                            <MenuItem value={"Peru"}>
                                              Peru
                                            </MenuItem>
                                            <MenuItem value={"Philippines"}>
                                              Philippines
                                            </MenuItem>
                                            <MenuItem value={"Poland"}>
                                              Poland
                                            </MenuItem>
                                            <MenuItem value={"Portugal"}>
                                              Portugal
                                            </MenuItem>
                                            <MenuItem value={"Qatar"}>
                                              Qatar
                                            </MenuItem>
                                            <MenuItem value={"Romania"}>
                                              Romania
                                            </MenuItem>
                                            <MenuItem value={"Russia"}>
                                              Russia
                                            </MenuItem>
                                            <MenuItem value={"Rwanda"}>
                                              Rwanda
                                            </MenuItem>
                                            <MenuItem
                                              value={"Saint Kitts and Nevis"}
                                            >
                                              Saint Kitts and Nevis
                                            </MenuItem>
                                            <MenuItem value={"Saint Lucia"}>
                                              Saint Lucia
                                            </MenuItem>
                                            <MenuItem
                                              value={
                                                "Saint Vincent and the Grenadines"
                                              }
                                            >
                                              Saint Vincent and the Grenadines
                                            </MenuItem>
                                            <MenuItem value={"Samoa"}>
                                              Samoa
                                            </MenuItem>
                                            <MenuItem value={"San Marino"}>
                                              San Marino
                                            </MenuItem>
                                            <MenuItem
                                              value={"Sao Tome and Principe"}
                                            >
                                              Sao Tome and Principe
                                            </MenuItem>
                                            <MenuItem value={"Saudi Arabia"}>
                                              Saudi Arabia
                                            </MenuItem>
                                            <MenuItem value={"Senegal"}>
                                              Senegal
                                            </MenuItem>
                                            <MenuItem value={"Serbia"}>
                                              Serbia
                                            </MenuItem>
                                            <MenuItem value={"Seychelles"}>
                                              Seychelles
                                            </MenuItem>
                                            <MenuItem value={"Sierra Leone"}>
                                              Sierra Leone
                                            </MenuItem>
                                            <MenuItem value={"Singapore"}>
                                              Singapore
                                            </MenuItem>
                                            <MenuItem value={"Slovakia"}>
                                              Slovakia
                                            </MenuItem>
                                            <MenuItem value={"Slovenia"}>
                                              Slovenia
                                            </MenuItem>
                                            <MenuItem value={"Solomon Islands"}>
                                              Solomon Islands
                                            </MenuItem>
                                            <MenuItem value={"Somalia"}>
                                              Somalia
                                            </MenuItem>
                                            <MenuItem value={"South Africa"}>
                                              South Africa
                                            </MenuItem>
                                            <MenuItem value={"South Sudan"}>
                                              South Sudan
                                            </MenuItem>
                                            <MenuItem value={"Spain"}>
                                              Spain
                                            </MenuItem>
                                            <MenuItem value={"Sri Lanka"}>
                                              Sri Lanka
                                            </MenuItem>
                                            <MenuItem value={"Sudan"}>
                                              Sudan
                                            </MenuItem>
                                            <MenuItem value={"Suriname"}>
                                              Suriname
                                            </MenuItem>
                                            <MenuItem value={"Swaziland"}>
                                              Swaziland
                                            </MenuItem>
                                            <MenuItem value={"Sweden"}>
                                              Sweden
                                            </MenuItem>
                                            <MenuItem value={"Switzerland"}>
                                              Switzerland
                                            </MenuItem>
                                            <MenuItem value={"Syria"}>
                                              Syria
                                            </MenuItem>
                                            <MenuItem value={"Taiwan"}>
                                              Taiwan
                                            </MenuItem>
                                            <MenuItem value={"Tajikistan"}>
                                              Tajikistan
                                            </MenuItem>
                                            <MenuItem value={"Tanzania"}>
                                              Tanzania
                                            </MenuItem>
                                            <MenuItem value={"Thailand"}>
                                              Thailand
                                            </MenuItem>
                                            <MenuItem value={"Togo"}>
                                              Togo
                                            </MenuItem>
                                            <MenuItem value={"Tonga"}>
                                              Tonga
                                            </MenuItem>
                                            <MenuItem
                                              value={"Trinidad and Tobago"}
                                            >
                                              Trinidad and Tobago
                                            </MenuItem>
                                            <MenuItem value={"Tunisia"}>
                                              Tunisia
                                            </MenuItem>
                                            <MenuItem value={"Turkey"}>
                                              Turkey
                                            </MenuItem>
                                            <MenuItem value={"Turkmenistan"}>
                                              Turkmenistan
                                            </MenuItem>
                                            <MenuItem value={"Tuvalu"}>
                                              Tuvalu
                                            </MenuItem>
                                            <MenuItem value={"Uganda"}>
                                              Uganda
                                            </MenuItem>
                                            <MenuItem value={"Ukraine"}>
                                              Ukraine
                                            </MenuItem>
                                            <MenuItem
                                              value={"United Arab Emirates"}
                                            >
                                              United Arab Emirates
                                            </MenuItem>
                                            <MenuItem value={"United Kingdom"}>
                                              United Kingdom
                                            </MenuItem>
                                            <MenuItem
                                              value={"United States of America"}
                                            >
                                              United States of America
                                            </MenuItem>
                                            <MenuItem value={"Uruguay"}>
                                              Uruguay
                                            </MenuItem>
                                            <MenuItem value={"Uzbekistan"}>
                                              Uzbekistan
                                            </MenuItem>
                                            <MenuItem value={"Vanuatu"}>
                                              Vanuatu
                                            </MenuItem>
                                            <MenuItem value={"Vatican City"}>
                                              Vatican City
                                            </MenuItem>
                                            <MenuItem value={"Venezuela"}>
                                              Venezuela
                                            </MenuItem>
                                            <MenuItem value={"Vietnam"}>
                                              Vietnam
                                            </MenuItem>
                                            <MenuItem value={"Yemen"}>
                                              Yemen
                                            </MenuItem>
                                            <MenuItem value={"Zambia"}>
                                              Zambia
                                            </MenuItem>
                                            <MenuItem value={"Zimbabwe"}>
                                              Zimbabwe
                                            </MenuItem>
                                          </TextField>
                                        </Grid>

                                     

                                        <Grid
                                          item
                                          xs={12}
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <div className="blog-area">
                                            <div className="info">
                                              <div
                                                className="content"
                                                style={{
                                                  padding: 10,
                                                  position: "static",
                                                }}
                                              >
                                                <a
                                                  class="btn btn-previous"
                                                  style={{
                                                    color: "#000",
                                                    textAlign: "center",
                                                    whiteSpace: "nowrap",
                                                    fontSize: 12,
                                                  }}
                                                >
                                                  <i
                                                    className="fa fa-arrow-left"
                                                    style={{ color: "#000" }}
                                                  ></i>{" "}
                                                  Previous
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="blog-area">
                                            <div className="info">
                                              <div
                                                className="content"
                                                style={{
                                                  padding: 10,
                                                  position: "static",
                                                }}
                                              >
                                                <a
                                                  class="btn btn-next"
                                                  style={{
                                                    color: "#000",
                                                    textAlign: "center",
                                                    whiteSpace: "nowrap",
                                                    fontSize: 12,
                                                  }}
                                                >
                                                  Next{" "}
                                                  <i
                                                    className="fa fa-arrow-right"
                                                    style={{ color: "#000" }}
                                                  ></i>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>

                                      {/* 
<button type="button" class="btn btn-previous">Previous</button>
<button type="button" class="btn btn-next">Next</button> */}
                                    </div>
                                  </fieldset>

                                  <fieldset style={{ display: "none" }}>
                                    <div class="form-bottom">
                                      <h4>Education Details</h4>
                                      <hr />
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                          <TextField
                                            variant="outlined"
                                            type="text"
                                            label="Current or most recent level Education"
                                            fullWidth
                                            value={recentEducation}
                                            onChange={(event) =>
                                              setRecentEducation(
                                                event.target.value
                                              )
                                            }
                                            variant="outlined"
                                          />
                                          {/* <MenuItem value={"Bachelors"}>
                                              Bachelors
                                            </MenuItem>
                                            <MenuItem value={"Masters"}>
                                              Masters
                                            </MenuItem> */}
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                          <TextField
                                            id="outlined-select-currency"
                                            select
                                            label="Country of Education"
                                            fullWidth
                                            value={countryEducation}
                                            onChange={(event) =>
                                              setCountryEducation(
                                                event.target.value
                                              )
                                            }
                                            //value={currency}
                                            //onChange={handleChange}
                                            //helperText="Please select your currency"
                                            variant="outlined"
                                          >
                                            <MenuItem value={"Afghanistan"}>
                                              Afghanistan
                                            </MenuItem>
                                            <MenuItem value={"Albania"}>
                                              Albania
                                            </MenuItem>
                                            <MenuItem value={"Algeria"}>
                                              Algeria
                                            </MenuItem>
                                            <MenuItem value={"Andorra"}>
                                              Andorra
                                            </MenuItem>
                                            <MenuItem value={"Angola"}>
                                              Angola
                                            </MenuItem>
                                            <MenuItem
                                              value={"Antigua and Barbuda"}
                                            >
                                              Antigua and Barbuda
                                            </MenuItem>
                                            <MenuItem value={"Argentina"}>
                                              Argentina
                                            </MenuItem>
                                            <MenuItem value={"Armenia"}>
                                              Armenia
                                            </MenuItem>
                                            <MenuItem value={"Australia"}>
                                              Australia
                                            </MenuItem>
                                            <MenuItem value={"Austria"}>
                                              Austria
                                            </MenuItem>
                                            <MenuItem value={"Austrian Empire"}>
                                              Austrian Empire
                                            </MenuItem>
                                            <MenuItem value={"Azerbaijan"}>
                                              Azerbaijan
                                            </MenuItem>

                                            <MenuItem value={"Bahamas"}>
                                              Bahamas
                                            </MenuItem>
                                            <MenuItem value={"Bahrain"}>
                                              Bahrain
                                            </MenuItem>
                                            <MenuItem value={"Bangladesh"}>
                                              Bangladesh
                                            </MenuItem>
                                            <MenuItem value={"Barbados"}>
                                              Barbados
                                            </MenuItem>
                                            <MenuItem value={"Belarus"}>
                                              Belarus
                                            </MenuItem>
                                            <MenuItem value={"Belgium"}>
                                              Belgium
                                            </MenuItem>
                                            <MenuItem value={"Belize"}>
                                              Belize
                                            </MenuItem>
                                            <MenuItem value={"Benin"}>
                                              Benin
                                            </MenuItem>
                                            <MenuItem value={"Bolivia"}>
                                              Bolivia
                                            </MenuItem>
                                            <MenuItem
                                              value={"Bosnia and Herzegovina"}
                                            >
                                              Bosnia and Herzegovina
                                            </MenuItem>
                                            <MenuItem value={"Botswana"}>
                                              Botswana
                                            </MenuItem>
                                            <MenuItem value={"Brazil"}>
                                              Brazil
                                            </MenuItem>
                                            <MenuItem value={"Brunei"}>
                                              Brunei
                                            </MenuItem>
                                            <MenuItem
                                              value={"Brunswick and Lüneburg"}
                                            >
                                              Brunswick and Lüneburg
                                            </MenuItem>
                                            <MenuItem value={"Bulgaria"}>
                                              Bulgaria
                                            </MenuItem>
                                            <MenuItem value={"Burkina Faso"}>
                                              Burkina Faso
                                            </MenuItem>
                                            <MenuItem value={"Burma"}>
                                              Burma
                                            </MenuItem>
                                            <MenuItem value={"Burundi"}>
                                              Burundi
                                            </MenuItem>
                                            <MenuItem value={"Cambodia"}>
                                              Cambodia
                                            </MenuItem>
                                            <MenuItem value={"Cameroon"}>
                                              Cameroon
                                            </MenuItem>
                                            <MenuItem value={"Canada"}>
                                              Canada
                                            </MenuItem>
                                            <MenuItem value={"Cape Verde"}>
                                              Cape Verde
                                            </MenuItem>
                                            <MenuItem
                                              value={"Central African Republic"}
                                            >
                                              Central African Republic
                                            </MenuItem>
                                            <MenuItem value={"Chad"}>
                                              Chad
                                            </MenuItem>
                                            <MenuItem value={"Chile"}>
                                              Chile
                                            </MenuItem>
                                            <MenuItem value={"China"}>
                                              China
                                            </MenuItem>
                                            <MenuItem value={"Colombia"}>
                                              Colombia
                                            </MenuItem>
                                            <MenuItem value={"Comoros"}>
                                              Comoros
                                            </MenuItem>
                                            <MenuItem
                                              value={"Congo, Republic of the"}
                                            >
                                              Congo, Republic of the
                                            </MenuItem>
                                            <MenuItem
                                              value={
                                                "Congo, Democratic Republic of the"
                                              }
                                            >
                                              Congo, Democratic Republic of the
                                            </MenuItem>
                                            <MenuItem value={"Costa Rica"}>
                                              Costa Rica
                                            </MenuItem>
                                            <MenuItem value={"Cote d'Ivoire"}>
                                              Cote d'Ivoire
                                            </MenuItem>
                                            <MenuItem value={"Croatia"}>
                                              Croatia
                                            </MenuItem>
                                            <MenuItem value={"Cuba"}>
                                              Cuba
                                            </MenuItem>
                                            <MenuItem value={"Cyprus"}>
                                              Cyprus
                                            </MenuItem>
                                            <MenuItem value={"Czech Republic"}>
                                              Czech Republic
                                            </MenuItem>
                                            <MenuItem value={"Denmark"}>
                                              Denmark
                                            </MenuItem>
                                            <MenuItem value={"Djibouti"}>
                                              Djibouti
                                            </MenuItem>
                                            <MenuItem value={"Dominica"}>
                                              Dominica
                                            </MenuItem>
                                            <MenuItem
                                              value={"Dominican Republic"}
                                            >
                                              Dominican Republic
                                            </MenuItem>
                                            <MenuItem value={"East Timor"}>
                                              East Timor
                                            </MenuItem>
                                            <MenuItem value={"Ecuador"}>
                                              Ecuador
                                            </MenuItem>
                                            <MenuItem value={"Egypt"}>
                                              Egypt
                                            </MenuItem>
                                            <MenuItem value={"El Salvador"}>
                                              El Salvador
                                            </MenuItem>
                                            <MenuItem
                                              value={"Equatorial Guinea"}
                                            >
                                              Equatorial Guinea
                                            </MenuItem>
                                            <MenuItem value={"Eritrea"}>
                                              Eritrea
                                            </MenuItem>
                                            <MenuItem value={"Estonia"}>
                                              Estonia
                                            </MenuItem>
                                            <MenuItem value={"Ethiopia"}>
                                              Ethiopia
                                            </MenuItem>
                                            <MenuItem value={"Fiji"}>
                                              Fiji
                                            </MenuItem>
                                            <MenuItem value={"Finland"}>
                                              Finland
                                            </MenuItem>
                                            <MenuItem value={"France"}>
                                              France
                                            </MenuItem>
                                            <MenuItem value={"Gabon"}>
                                              Gabon
                                            </MenuItem>
                                            <MenuItem value={"The Gambia"}>
                                              The Gambia
                                            </MenuItem>
                                            <MenuItem value={"Georgia"}>
                                              Georgia
                                            </MenuItem>
                                            <MenuItem value={"Germany"}>
                                              Germany
                                            </MenuItem>
                                            <MenuItem value={"Ghana"}>
                                              Ghana
                                            </MenuItem>
                                            <MenuItem value={"Greece"}>
                                              Greece
                                            </MenuItem>
                                            <MenuItem value={"Grenada"}>
                                              Grenada
                                            </MenuItem>
                                            <MenuItem value={"Guatemala"}>
                                              Guatemala
                                            </MenuItem>
                                            <MenuItem value={"Guinea"}>
                                              Guinea
                                            </MenuItem>
                                            <MenuItem value={"Guinea-Bissau"}>
                                              Guinea-Bissau
                                            </MenuItem>
                                            <MenuItem value={"Guyana"}>
                                              Guyana
                                            </MenuItem>
                                            <MenuItem value={"Haiti"}>
                                              Haiti
                                            </MenuItem>
                                            <MenuItem value={"Honduras"}>
                                              Honduras
                                            </MenuItem>
                                            <MenuItem value={"Hungary"}>
                                              Hungary
                                            </MenuItem>
                                            <MenuItem value={"Iceland"}>
                                              Iceland
                                            </MenuItem>
                                            <MenuItem value={"India"}>
                                              India
                                            </MenuItem>
                                            <MenuItem value={"Indonesia"}>
                                              Indonesia
                                            </MenuItem>
                                            <MenuItem value={"Iran"}>
                                              Iran
                                            </MenuItem>
                                            <MenuItem value={"Iraq"}>
                                              Iraq
                                            </MenuItem>
                                            <MenuItem value={"Ireland"}>
                                              Ireland
                                            </MenuItem>
                                            <MenuItem value={"Israel"}>
                                              Israel
                                            </MenuItem>
                                            <MenuItem value={"Italy"}>
                                              Italy
                                            </MenuItem>
                                            <MenuItem value={"Jamaica"}>
                                              Jamaica
                                            </MenuItem>
                                            <MenuItem value={"Japan"}>
                                              Japan
                                            </MenuItem>
                                            <MenuItem value={"Jordan"}>
                                              Jordan
                                            </MenuItem>
                                            <MenuItem value={"Kazakhstan"}>
                                              Kazakhstan
                                            </MenuItem>
                                            <MenuItem value={"Kenya"}>
                                              Kenya
                                            </MenuItem>
                                            <MenuItem value={"Kiribati"}>
                                              Kiribati
                                            </MenuItem>
                                            <MenuItem value={"Korea, North"}>
                                              Korea, North
                                            </MenuItem>
                                            <MenuItem value={"Korea, South<"}>
                                              Korea, South
                                            </MenuItem>
                                            <MenuItem value={"Kosovo"}>
                                              Kosovo
                                            </MenuItem>
                                            <MenuItem value={"Kuwait"}>
                                              Kuwait
                                            </MenuItem>
                                            <MenuItem value={"Kyrgyzstan"}>
                                              Kyrgyzstan
                                            </MenuItem>
                                            <MenuItem value={"Laos"}>
                                              Laos
                                            </MenuItem>
                                            <MenuItem value={"Latvia"}>
                                              Latvia
                                            </MenuItem>
                                            <MenuItem value={"Lebanon"}>
                                              Lebanon
                                            </MenuItem>
                                            <MenuItem value={"Lesotho"}>
                                              Lesotho
                                            </MenuItem>
                                            <MenuItem value={"Liberia"}>
                                              Liberia
                                            </MenuItem>
                                            <MenuItem value={"Libya"}>
                                              Libya
                                            </MenuItem>
                                            <MenuItem value={"Liechtenstein"}>
                                              Liechtenstein
                                            </MenuItem>
                                            <MenuItem value={"Lithuania"}>
                                              Lithuania
                                            </MenuItem>
                                            <MenuItem value={"Luxembourg"}>
                                              Luxembourg
                                            </MenuItem>
                                            <MenuItem value={"Macedonia"}>
                                              Macedonia
                                            </MenuItem>
                                            <MenuItem value={"Madagascar"}>
                                              Madagascar
                                            </MenuItem>
                                            <MenuItem value={"Malawi"}>
                                              Malawi
                                            </MenuItem>
                                            <MenuItem value={"Malaysia"}>
                                              Malaysia
                                            </MenuItem>
                                            <MenuItem value={"Maldives"}>
                                              Maldives
                                            </MenuItem>
                                            <MenuItem value={"Mali"}>
                                              Mali
                                            </MenuItem>
                                            <MenuItem value={"Malta"}>
                                              Malta
                                            </MenuItem>
                                            <MenuItem
                                              value={"Marshall Islands"}
                                            >
                                              Marshall Islands
                                            </MenuItem>
                                            <MenuItem value={"Mauritania"}>
                                              Mauritania
                                            </MenuItem>
                                            <MenuItem value={"Mauritius"}>
                                              Mauritius
                                            </MenuItem>
                                            <MenuItem value={"Mexico"}>
                                              Mexico
                                            </MenuItem>
                                            <MenuItem value={"Micronesia"}>
                                              Micronesia
                                            </MenuItem>
                                            <MenuItem value={"Moldova"}>
                                              Moldova
                                            </MenuItem>
                                            <MenuItem value={"Monaco"}>
                                              Monaco
                                            </MenuItem>
                                            <MenuItem value={"Mongolia"}>
                                              Mongolia
                                            </MenuItem>
                                            <MenuItem value={"Montenegro"}>
                                              Montenegro
                                            </MenuItem>
                                            <MenuItem value={"Morocco"}>
                                              Morocco
                                            </MenuItem>
                                            <MenuItem value={"Mozambique"}>
                                              Mozambique
                                            </MenuItem>
                                            <MenuItem value={"Myanmar"}>
                                              Myanmar
                                            </MenuItem>
                                            <MenuItem value={"Namibia"}>
                                              Namibia
                                            </MenuItem>
                                            <MenuItem value={"Nauru"}>
                                              Nauru
                                            </MenuItem>
                                            <MenuItem value={"Nepal"}>
                                              Nepal
                                            </MenuItem>
                                            <MenuItem value={"Netherlands"}>
                                              Netherlands
                                            </MenuItem>
                                            <MenuItem value={"New Zealand"}>
                                              New Zealand
                                            </MenuItem>
                                            <MenuItem value={"Nicaragua"}>
                                              Nicaragua
                                            </MenuItem>
                                            <MenuItem value={"Niger"}>
                                              Niger
                                            </MenuItem>
                                            <MenuItem value={"Nigeria"}>
                                              Nigeria
                                            </MenuItem>
                                            <MenuItem value={"Norway"}>
                                              Norway
                                            </MenuItem>
                                            <MenuItem value={"Oman"}>
                                              Oman
                                            </MenuItem>
                                            <MenuItem value={"Pakistan"}>
                                              Pakistan
                                            </MenuItem>
                                            <MenuItem value={"Palau"}>
                                              Palau
                                            </MenuItem>
                                            <MenuItem value={"Panama"}>
                                              Panama
                                            </MenuItem>
                                            <MenuItem
                                              value={"Papua New Guinea"}
                                            >
                                              Papua New Guinea
                                            </MenuItem>
                                            <MenuItem value={"Paraguay"}>
                                              Paraguay
                                            </MenuItem>
                                            <MenuItem value={"Peru"}>
                                              Peru
                                            </MenuItem>
                                            <MenuItem value={"Philippines"}>
                                              Philippines
                                            </MenuItem>
                                            <MenuItem value={"Poland"}>
                                              Poland
                                            </MenuItem>
                                            <MenuItem value={"Portugal"}>
                                              Portugal
                                            </MenuItem>
                                            <MenuItem value={"Qatar"}>
                                              Qatar
                                            </MenuItem>
                                            <MenuItem value={"Romania"}>
                                              Romania
                                            </MenuItem>
                                            <MenuItem value={"Russia"}>
                                              Russia
                                            </MenuItem>
                                            <MenuItem value={"Rwanda"}>
                                              Rwanda
                                            </MenuItem>
                                            <MenuItem
                                              value={"Saint Kitts and Nevis"}
                                            >
                                              Saint Kitts and Nevis
                                            </MenuItem>
                                            <MenuItem value={"Saint Lucia"}>
                                              Saint Lucia
                                            </MenuItem>
                                            <MenuItem
                                              value={
                                                "Saint Vincent and the Grenadines"
                                              }
                                            >
                                              Saint Vincent and the Grenadines
                                            </MenuItem>
                                            <MenuItem value={"Samoa"}>
                                              Samoa
                                            </MenuItem>
                                            <MenuItem value={"San Marino"}>
                                              San Marino
                                            </MenuItem>
                                            <MenuItem
                                              value={"Sao Tome and Principe"}
                                            >
                                              Sao Tome and Principe
                                            </MenuItem>
                                            <MenuItem value={"Saudi Arabia"}>
                                              Saudi Arabia
                                            </MenuItem>
                                            <MenuItem value={"Senegal"}>
                                              Senegal
                                            </MenuItem>
                                            <MenuItem value={"Serbia"}>
                                              Serbia
                                            </MenuItem>
                                            <MenuItem value={"Seychelles"}>
                                              Seychelles
                                            </MenuItem>
                                            <MenuItem value={"Sierra Leone"}>
                                              Sierra Leone
                                            </MenuItem>
                                            <MenuItem value={"Singapore"}>
                                              Singapore
                                            </MenuItem>
                                            <MenuItem value={"Slovakia"}>
                                              Slovakia
                                            </MenuItem>
                                            <MenuItem value={"Slovenia"}>
                                              Slovenia
                                            </MenuItem>
                                            <MenuItem value={"Solomon Islands"}>
                                              Solomon Islands
                                            </MenuItem>
                                            <MenuItem value={"Somalia"}>
                                              Somalia
                                            </MenuItem>
                                            <MenuItem value={"South Africa"}>
                                              South Africa
                                            </MenuItem>
                                            <MenuItem value={"South Sudan"}>
                                              South Sudan
                                            </MenuItem>
                                            <MenuItem value={"Spain"}>
                                              Spain
                                            </MenuItem>
                                            <MenuItem value={"Sri Lanka"}>
                                              Sri Lanka
                                            </MenuItem>
                                            <MenuItem value={"Sudan"}>
                                              Sudan
                                            </MenuItem>
                                            <MenuItem value={"Suriname"}>
                                              Suriname
                                            </MenuItem>
                                            <MenuItem value={"Swaziland"}>
                                              Swaziland
                                            </MenuItem>
                                            <MenuItem value={"Sweden"}>
                                              Sweden
                                            </MenuItem>
                                            <MenuItem value={"Switzerland"}>
                                              Switzerland
                                            </MenuItem>
                                            <MenuItem value={"Syria"}>
                                              Syria
                                            </MenuItem>
                                            <MenuItem value={"Taiwan"}>
                                              Taiwan
                                            </MenuItem>
                                            <MenuItem value={"Tajikistan"}>
                                              Tajikistan
                                            </MenuItem>
                                            <MenuItem value={"Tanzania"}>
                                              Tanzania
                                            </MenuItem>
                                            <MenuItem value={"Thailand"}>
                                              Thailand
                                            </MenuItem>
                                            <MenuItem value={"Togo"}>
                                              Togo
                                            </MenuItem>
                                            <MenuItem value={"Tonga"}>
                                              Tonga
                                            </MenuItem>
                                            <MenuItem
                                              value={"Trinidad and Tobago"}
                                            >
                                              Trinidad and Tobago
                                            </MenuItem>
                                            <MenuItem value={"Tunisia"}>
                                              Tunisia
                                            </MenuItem>
                                            <MenuItem value={"Turkey"}>
                                              Turkey
                                            </MenuItem>
                                            <MenuItem value={"Turkmenistan"}>
                                              Turkmenistan
                                            </MenuItem>
                                            <MenuItem value={"Tuvalu"}>
                                              Tuvalu
                                            </MenuItem>
                                            <MenuItem value={"Uganda"}>
                                              Uganda
                                            </MenuItem>
                                            <MenuItem value={"Ukraine"}>
                                              Ukraine
                                            </MenuItem>
                                            <MenuItem
                                              value={"United Arab Emirates"}
                                            >
                                              United Arab Emirates
                                            </MenuItem>
                                            <MenuItem value={"United Kingdom"}>
                                              United Kingdom
                                            </MenuItem>
                                            <MenuItem
                                              value={"United States of America"}
                                            >
                                              United States of America
                                            </MenuItem>
                                            <MenuItem value={"Uruguay"}>
                                              Uruguay
                                            </MenuItem>
                                            <MenuItem value={"Uzbekistan"}>
                                              Uzbekistan
                                            </MenuItem>
                                            <MenuItem value={"Vanuatu"}>
                                              Vanuatu
                                            </MenuItem>
                                            <MenuItem value={"Vatican City"}>
                                              Vatican City
                                            </MenuItem>
                                            <MenuItem value={"Venezuela"}>
                                              Venezuela
                                            </MenuItem>
                                            <MenuItem value={"Vietnam"}>
                                              Vietnam
                                            </MenuItem>
                                            <MenuItem value={"Yemen"}>
                                              Yemen
                                            </MenuItem>
                                            <MenuItem value={"Zambia"}>
                                              Zambia
                                            </MenuItem>
                                            <MenuItem value={"Zimbabwe"}>
                                              Zimbabwe
                                            </MenuItem>
                                          </TextField>
                                        </Grid>

                                        <br />
                                        <br />
                                        <Grid item xs={12}>
                                          <h4>Other Informations</h4>
                                          <hr />
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            id="outlined-select-currency"
                                            select
                                            label="Prefered Country"
                                            fullWidth
                                            onChange={(event) =>
                                              setPreferedCountry(
                                                event.target.value
                                              )
                                            }
                                            value={preferedCountry}
                                            //onChange={handleChange}
                                            //helperText="Please select your currency"
                                            variant="outlined"
                                          >
                                            <MenuItem value={"Afghanistan"}>
                                              Afghanistan
                                            </MenuItem>
                                            <MenuItem value={"Albania"}>
                                              Albania
                                            </MenuItem>
                                            <MenuItem value={"Algeria"}>
                                              Algeria
                                            </MenuItem>
                                            <MenuItem value={"Andorra"}>
                                              Andorra
                                            </MenuItem>
                                            <MenuItem value={"Angola"}>
                                              Angola
                                            </MenuItem>
                                            <MenuItem
                                              value={"Antigua and Barbuda"}
                                            >
                                              Antigua and Barbuda
                                            </MenuItem>
                                            <MenuItem value={"Argentina"}>
                                              Argentina
                                            </MenuItem>
                                            <MenuItem value={"Armenia"}>
                                              Armenia
                                            </MenuItem>
                                            <MenuItem value={"Australia"}>
                                              Australia
                                            </MenuItem>
                                            <MenuItem value={"Austria"}>
                                              Austria
                                            </MenuItem>
                                            <MenuItem value={"Austrian Empire"}>
                                              Austrian Empire
                                            </MenuItem>
                                            <MenuItem value={"Azerbaijan"}>
                                              Azerbaijan
                                            </MenuItem>

                                            <MenuItem value={"Bahamas"}>
                                              Bahamas
                                            </MenuItem>
                                            <MenuItem value={"Bahrain"}>
                                              Bahrain
                                            </MenuItem>
                                            <MenuItem value={"Bangladesh"}>
                                              Bangladesh
                                            </MenuItem>
                                            <MenuItem value={"Barbados"}>
                                              Barbados
                                            </MenuItem>
                                            <MenuItem value={"Belarus"}>
                                              Belarus
                                            </MenuItem>
                                            <MenuItem value={"Belgium"}>
                                              Belgium
                                            </MenuItem>
                                            <MenuItem value={"Belize"}>
                                              Belize
                                            </MenuItem>
                                            <MenuItem value={"Benin"}>
                                              Benin
                                            </MenuItem>
                                            <MenuItem value={"Bolivia"}>
                                              Bolivia
                                            </MenuItem>
                                            <MenuItem
                                              value={"Bosnia and Herzegovina"}
                                            >
                                              Bosnia and Herzegovina
                                            </MenuItem>
                                            <MenuItem value={"Botswana"}>
                                              Botswana
                                            </MenuItem>
                                            <MenuItem value={"Brazil"}>
                                              Brazil
                                            </MenuItem>
                                            <MenuItem value={"Brunei"}>
                                              Brunei
                                            </MenuItem>
                                            <MenuItem
                                              value={"Brunswick and Lüneburg"}
                                            >
                                              Brunswick and Lüneburg
                                            </MenuItem>
                                            <MenuItem value={"Bulgaria"}>
                                              Bulgaria
                                            </MenuItem>
                                            <MenuItem value={"Burkina Faso"}>
                                              Burkina Faso
                                            </MenuItem>
                                            <MenuItem value={"Burma"}>
                                              Burma
                                            </MenuItem>
                                            <MenuItem value={"Burundi"}>
                                              Burundi
                                            </MenuItem>
                                            <MenuItem value={"Cambodia"}>
                                              Cambodia
                                            </MenuItem>
                                            <MenuItem value={"Cameroon"}>
                                              Cameroon
                                            </MenuItem>
                                            <MenuItem value={"Canada"}>
                                              Canada
                                            </MenuItem>
                                            <MenuItem value={"Cape Verde"}>
                                              Cape Verde
                                            </MenuItem>
                                            <MenuItem
                                              value={"Central African Republic"}
                                            >
                                              Central African Republic
                                            </MenuItem>
                                            <MenuItem value={"Chad"}>
                                              Chad
                                            </MenuItem>
                                            <MenuItem value={"Chile"}>
                                              Chile
                                            </MenuItem>
                                            <MenuItem value={"China"}>
                                              China
                                            </MenuItem>
                                            <MenuItem value={"Colombia"}>
                                              Colombia
                                            </MenuItem>
                                            <MenuItem value={"Comoros"}>
                                              Comoros
                                            </MenuItem>
                                            <MenuItem
                                              value={"Congo, Republic of the"}
                                            >
                                              Congo, Republic of the
                                            </MenuItem>
                                            <MenuItem
                                              value={
                                                "Congo, Democratic Republic of the"
                                              }
                                            >
                                              Congo, Democratic Republic of the
                                            </MenuItem>
                                            <MenuItem value={"Costa Rica"}>
                                              Costa Rica
                                            </MenuItem>
                                            <MenuItem value={"Cote d'Ivoire"}>
                                              Cote d'Ivoire
                                            </MenuItem>
                                            <MenuItem value={"Croatia"}>
                                              Croatia
                                            </MenuItem>
                                            <MenuItem value={"Cuba"}>
                                              Cuba
                                            </MenuItem>
                                            <MenuItem value={"Cyprus"}>
                                              Cyprus
                                            </MenuItem>
                                            <MenuItem value={"Czech Republic"}>
                                              Czech Republic
                                            </MenuItem>
                                            <MenuItem value={"Denmark"}>
                                              Denmark
                                            </MenuItem>
                                            <MenuItem value={"Djibouti"}>
                                              Djibouti
                                            </MenuItem>
                                            <MenuItem value={"Dominica"}>
                                              Dominica
                                            </MenuItem>
                                            <MenuItem
                                              value={"Dominican Republic"}
                                            >
                                              Dominican Republic
                                            </MenuItem>
                                            <MenuItem value={"East Timor"}>
                                              East Timor
                                            </MenuItem>
                                            <MenuItem value={"Ecuador"}>
                                              Ecuador
                                            </MenuItem>
                                            <MenuItem value={"Egypt"}>
                                              Egypt
                                            </MenuItem>
                                            <MenuItem value={"El Salvador"}>
                                              El Salvador
                                            </MenuItem>
                                            <MenuItem
                                              value={"Equatorial Guinea"}
                                            >
                                              Equatorial Guinea
                                            </MenuItem>
                                            <MenuItem value={"Eritrea"}>
                                              Eritrea
                                            </MenuItem>
                                            <MenuItem value={"Estonia"}>
                                              Estonia
                                            </MenuItem>
                                            <MenuItem value={"Ethiopia"}>
                                              Ethiopia
                                            </MenuItem>
                                            <MenuItem value={"Fiji"}>
                                              Fiji
                                            </MenuItem>
                                            <MenuItem value={"Finland"}>
                                              Finland
                                            </MenuItem>
                                            <MenuItem value={"France"}>
                                              France
                                            </MenuItem>
                                            <MenuItem value={"Gabon"}>
                                              Gabon
                                            </MenuItem>
                                            <MenuItem value={"The Gambia"}>
                                              The Gambia
                                            </MenuItem>
                                            <MenuItem value={"Georgia"}>
                                              Georgia
                                            </MenuItem>
                                            <MenuItem value={"Germany"}>
                                              Germany
                                            </MenuItem>
                                            <MenuItem value={"Ghana"}>
                                              Ghana
                                            </MenuItem>
                                            <MenuItem value={"Greece"}>
                                              Greece
                                            </MenuItem>
                                            <MenuItem value={"Grenada"}>
                                              Grenada
                                            </MenuItem>
                                            <MenuItem value={"Guatemala"}>
                                              Guatemala
                                            </MenuItem>
                                            <MenuItem value={"Guinea"}>
                                              Guinea
                                            </MenuItem>
                                            <MenuItem value={"Guinea-Bissau"}>
                                              Guinea-Bissau
                                            </MenuItem>
                                            <MenuItem value={"Guyana"}>
                                              Guyana
                                            </MenuItem>
                                            <MenuItem value={"Haiti"}>
                                              Haiti
                                            </MenuItem>
                                            <MenuItem value={"Honduras"}>
                                              Honduras
                                            </MenuItem>
                                            <MenuItem value={"Hungary"}>
                                              Hungary
                                            </MenuItem>
                                            <MenuItem value={"Iceland"}>
                                              Iceland
                                            </MenuItem>
                                            <MenuItem value={"India"}>
                                              India
                                            </MenuItem>
                                            <MenuItem value={"Indonesia"}>
                                              Indonesia
                                            </MenuItem>
                                            <MenuItem value={"Iran"}>
                                              Iran
                                            </MenuItem>
                                            <MenuItem value={"Iraq"}>
                                              Iraq
                                            </MenuItem>
                                            <MenuItem value={"Ireland"}>
                                              Ireland
                                            </MenuItem>
                                            <MenuItem value={"Israel"}>
                                              Israel
                                            </MenuItem>
                                            <MenuItem value={"Italy"}>
                                              Italy
                                            </MenuItem>
                                            <MenuItem value={"Jamaica"}>
                                              Jamaica
                                            </MenuItem>
                                            <MenuItem value={"Japan"}>
                                              Japan
                                            </MenuItem>
                                            <MenuItem value={"Jordan"}>
                                              Jordan
                                            </MenuItem>
                                            <MenuItem value={"Kazakhstan"}>
                                              Kazakhstan
                                            </MenuItem>
                                            <MenuItem value={"Kenya"}>
                                              Kenya
                                            </MenuItem>
                                            <MenuItem value={"Kiribati"}>
                                              Kiribati
                                            </MenuItem>
                                            <MenuItem value={"Korea, North"}>
                                              Korea, North
                                            </MenuItem>
                                            <MenuItem value={"Korea, South<"}>
                                              Korea, South
                                            </MenuItem>
                                            <MenuItem value={"Kosovo"}>
                                              Kosovo
                                            </MenuItem>
                                            <MenuItem value={"Kuwait"}>
                                              Kuwait
                                            </MenuItem>
                                            <MenuItem value={"Kyrgyzstan"}>
                                              Kyrgyzstan
                                            </MenuItem>
                                            <MenuItem value={"Laos"}>
                                              Laos
                                            </MenuItem>
                                            <MenuItem value={"Latvia"}>
                                              Latvia
                                            </MenuItem>
                                            <MenuItem value={"Lebanon"}>
                                              Lebanon
                                            </MenuItem>
                                            <MenuItem value={"Lesotho"}>
                                              Lesotho
                                            </MenuItem>
                                            <MenuItem value={"Liberia"}>
                                              Liberia
                                            </MenuItem>
                                            <MenuItem value={"Libya"}>
                                              Libya
                                            </MenuItem>
                                            <MenuItem value={"Liechtenstein"}>
                                              Liechtenstein
                                            </MenuItem>
                                            <MenuItem value={"Lithuania"}>
                                              Lithuania
                                            </MenuItem>
                                            <MenuItem value={"Luxembourg"}>
                                              Luxembourg
                                            </MenuItem>
                                            <MenuItem value={"Macedonia"}>
                                              Macedonia
                                            </MenuItem>
                                            <MenuItem value={"Madagascar"}>
                                              Madagascar
                                            </MenuItem>
                                            <MenuItem value={"Malawi"}>
                                              Malawi
                                            </MenuItem>
                                            <MenuItem value={"Malaysia"}>
                                              Malaysia
                                            </MenuItem>
                                            <MenuItem value={"Maldives"}>
                                              Maldives
                                            </MenuItem>
                                            <MenuItem value={"Mali"}>
                                              Mali
                                            </MenuItem>
                                            <MenuItem value={"Malta"}>
                                              Malta
                                            </MenuItem>
                                            <MenuItem
                                              value={"Marshall Islands"}
                                            >
                                              Marshall Islands
                                            </MenuItem>
                                            <MenuItem value={"Mauritania"}>
                                              Mauritania
                                            </MenuItem>
                                            <MenuItem value={"Mauritius"}>
                                              Mauritius
                                            </MenuItem>
                                            <MenuItem value={"Mexico"}>
                                              Mexico
                                            </MenuItem>
                                            <MenuItem value={"Micronesia"}>
                                              Micronesia
                                            </MenuItem>
                                            <MenuItem value={"Moldova"}>
                                              Moldova
                                            </MenuItem>
                                            <MenuItem value={"Monaco"}>
                                              Monaco
                                            </MenuItem>
                                            <MenuItem value={"Mongolia"}>
                                              Mongolia
                                            </MenuItem>
                                            <MenuItem value={"Montenegro"}>
                                              Montenegro
                                            </MenuItem>
                                            <MenuItem value={"Morocco"}>
                                              Morocco
                                            </MenuItem>
                                            <MenuItem value={"Mozambique"}>
                                              Mozambique
                                            </MenuItem>
                                            <MenuItem value={"Myanmar"}>
                                              Myanmar
                                            </MenuItem>
                                            <MenuItem value={"Namibia"}>
                                              Namibia
                                            </MenuItem>
                                            <MenuItem value={"Nauru"}>
                                              Nauru
                                            </MenuItem>
                                            <MenuItem value={"Nepal"}>
                                              Nepal
                                            </MenuItem>
                                            <MenuItem value={"Netherlands"}>
                                              Netherlands
                                            </MenuItem>
                                            <MenuItem value={"New Zealand"}>
                                              New Zealand
                                            </MenuItem>
                                            <MenuItem value={"Nicaragua"}>
                                              Nicaragua
                                            </MenuItem>
                                            <MenuItem value={"Niger"}>
                                              Niger
                                            </MenuItem>
                                            <MenuItem value={"Nigeria"}>
                                              Nigeria
                                            </MenuItem>
                                            <MenuItem value={"Norway"}>
                                              Norway
                                            </MenuItem>
                                            <MenuItem value={"Oman"}>
                                              Oman
                                            </MenuItem>
                                            <MenuItem value={"Pakistan"}>
                                              Pakistan
                                            </MenuItem>
                                            <MenuItem value={"Palau"}>
                                              Palau
                                            </MenuItem>
                                            <MenuItem value={"Panama"}>
                                              Panama
                                            </MenuItem>
                                            <MenuItem
                                              value={"Papua New Guinea"}
                                            >
                                              Papua New Guinea
                                            </MenuItem>
                                            <MenuItem value={"Paraguay"}>
                                              Paraguay
                                            </MenuItem>
                                            <MenuItem value={"Peru"}>
                                              Peru
                                            </MenuItem>
                                            <MenuItem value={"Philippines"}>
                                              Philippines
                                            </MenuItem>
                                            <MenuItem value={"Poland"}>
                                              Poland
                                            </MenuItem>
                                            <MenuItem value={"Portugal"}>
                                              Portugal
                                            </MenuItem>
                                            <MenuItem value={"Qatar"}>
                                              Qatar
                                            </MenuItem>
                                            <MenuItem value={"Romania"}>
                                              Romania
                                            </MenuItem>
                                            <MenuItem value={"Russia"}>
                                              Russia
                                            </MenuItem>
                                            <MenuItem value={"Rwanda"}>
                                              Rwanda
                                            </MenuItem>
                                            <MenuItem
                                              value={"Saint Kitts and Nevis"}
                                            >
                                              Saint Kitts and Nevis
                                            </MenuItem>
                                            <MenuItem value={"Saint Lucia"}>
                                              Saint Lucia
                                            </MenuItem>
                                            <MenuItem
                                              value={
                                                "Saint Vincent and the Grenadines"
                                              }
                                            >
                                              Saint Vincent and the Grenadines
                                            </MenuItem>
                                            <MenuItem value={"Samoa"}>
                                              Samoa
                                            </MenuItem>
                                            <MenuItem value={"San Marino"}>
                                              San Marino
                                            </MenuItem>
                                            <MenuItem
                                              value={"Sao Tome and Principe"}
                                            >
                                              Sao Tome and Principe
                                            </MenuItem>
                                            <MenuItem value={"Saudi Arabia"}>
                                              Saudi Arabia
                                            </MenuItem>
                                            <MenuItem value={"Senegal"}>
                                              Senegal
                                            </MenuItem>
                                            <MenuItem value={"Serbia"}>
                                              Serbia
                                            </MenuItem>
                                            <MenuItem value={"Seychelles"}>
                                              Seychelles
                                            </MenuItem>
                                            <MenuItem value={"Sierra Leone"}>
                                              Sierra Leone
                                            </MenuItem>
                                            <MenuItem value={"Singapore"}>
                                              Singapore
                                            </MenuItem>
                                            <MenuItem value={"Slovakia"}>
                                              Slovakia
                                            </MenuItem>
                                            <MenuItem value={"Slovenia"}>
                                              Slovenia
                                            </MenuItem>
                                            <MenuItem value={"Solomon Islands"}>
                                              Solomon Islands
                                            </MenuItem>
                                            <MenuItem value={"Somalia"}>
                                              Somalia
                                            </MenuItem>
                                            <MenuItem value={"South Africa"}>
                                              South Africa
                                            </MenuItem>
                                            <MenuItem value={"South Sudan"}>
                                              South Sudan
                                            </MenuItem>
                                            <MenuItem value={"Spain"}>
                                              Spain
                                            </MenuItem>
                                            <MenuItem value={"Sri Lanka"}>
                                              Sri Lanka
                                            </MenuItem>
                                            <MenuItem value={"Sudan"}>
                                              Sudan
                                            </MenuItem>
                                            <MenuItem value={"Suriname"}>
                                              Suriname
                                            </MenuItem>
                                            <MenuItem value={"Swaziland"}>
                                              Swaziland
                                            </MenuItem>
                                            <MenuItem value={"Sweden"}>
                                              Sweden
                                            </MenuItem>
                                            <MenuItem value={"Switzerland"}>
                                              Switzerland
                                            </MenuItem>
                                            <MenuItem value={"Syria"}>
                                              Syria
                                            </MenuItem>
                                            <MenuItem value={"Taiwan"}>
                                              Taiwan
                                            </MenuItem>
                                            <MenuItem value={"Tajikistan"}>
                                              Tajikistan
                                            </MenuItem>
                                            <MenuItem value={"Tanzania"}>
                                              Tanzania
                                            </MenuItem>
                                            <MenuItem value={"Thailand"}>
                                              Thailand
                                            </MenuItem>
                                            <MenuItem value={"Togo"}>
                                              Togo
                                            </MenuItem>
                                            <MenuItem value={"Tonga"}>
                                              Tonga
                                            </MenuItem>
                                            <MenuItem
                                              value={"Trinidad and Tobago"}
                                            >
                                              Trinidad and Tobago
                                            </MenuItem>
                                            <MenuItem value={"Tunisia"}>
                                              Tunisia
                                            </MenuItem>
                                            <MenuItem value={"Turkey"}>
                                              Turkey
                                            </MenuItem>
                                            <MenuItem value={"Turkmenistan"}>
                                              Turkmenistan
                                            </MenuItem>
                                            <MenuItem value={"Tuvalu"}>
                                              Tuvalu
                                            </MenuItem>
                                            <MenuItem value={"Uganda"}>
                                              Uganda
                                            </MenuItem>
                                            <MenuItem value={"Ukraine"}>
                                              Ukraine
                                            </MenuItem>
                                            <MenuItem
                                              value={"United Arab Emirates"}
                                            >
                                              United Arab Emirates
                                            </MenuItem>
                                            <MenuItem value={"United Kingdom"}>
                                              United Kingdom
                                            </MenuItem>
                                            <MenuItem
                                              value={"United States of America"}
                                            >
                                              United States of America
                                            </MenuItem>
                                            <MenuItem value={"Uruguay"}>
                                              Uruguay
                                            </MenuItem>
                                            <MenuItem value={"Uzbekistan"}>
                                              Uzbekistan
                                            </MenuItem>
                                            <MenuItem value={"Vanuatu"}>
                                              Vanuatu
                                            </MenuItem>
                                            <MenuItem value={"Vatican City"}>
                                              Vatican City
                                            </MenuItem>
                                            <MenuItem value={"Venezuela"}>
                                              Venezuela
                                            </MenuItem>
                                            <MenuItem value={"Vietnam"}>
                                              Vietnam
                                            </MenuItem>
                                            <MenuItem value={"Yemen"}>
                                              Yemen
                                            </MenuItem>
                                            <MenuItem value={"Zambia"}>
                                              Zambia
                                            </MenuItem>
                                            <MenuItem value={"Zimbabwe"}>
                                              Zimbabwe
                                            </MenuItem>
                                          </TextField>
                                        </Grid>
                                        {/* 
                                        <Grid item xs={12} sm={4}>
                                          <FormControl fullWidth>
                                            <InputLabel>
                                              Prefered Country
                                            </InputLabel>
                                            <Select>
                                              <MenuItem value={10}>
                                                India
                                              </MenuItem>
                                              <MenuItem value={20}>
                                                USA
                                              </MenuItem>
                                              <MenuItem value={30}>
                                                Canada
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Grid> */}

                                        {/* <Grid item xs={12} sm={4}>
                                          <TextField
                                            name="flang"
                                            variant="outlined"
                                            fullWidth
                                            label="First Language"
                                            type="text"
                                            onChange={(event) =>
                                              setFirstLang(event.target.value)
                                            }
                                            value={firstLan}
                                            autoFocus
                                          />
                                        </Grid> */}

                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            name="pprogram"
                                            id="outlined-basic"
                                            variant="outlined"
                                            fullWidth
                                            label="Prefered Program"
                                            value={preferedProgram}
                                            onChange={(event) =>
                                              setPreferedProgram(
                                                event.target.value
                                              )
                                            }
                                          />
                                        </Grid>

                                        {/* <Grid item xs={12} sm={4}>
                                          <TextField
                                            name="application"
                                            variant="outlined"
                                            fullWidth
                                            label="Application Handling by"
                                            type="text"
                                            value={applicationHandling}
                                            onChange={(event) =>
                                              setApplicationHandling(
                                                event.target.value
                                              )
                                            }
                                            autoFocus
                                          />
                                        </Grid> */}

                                        <Grid item xs={12} sm={4}>
                                          {/* <TextField
                                            name="stage"
                                            
                                            variant="outlined"
                                            fullWidth
                                            label="Stage"
                                            type="text"
                                            value={stage}
                                            defaultValue="New"
                                            onChange={(event) =>
                                              setStage(event.target.value)
                                            }
                                            autoFocus
                                          /> */}

                                          <TextField
                                            id="outlined-read-only-input"
                                            label="Stage"
                                            variant="outlined"
                                            fullWidth
                                            //value={stage}
                                            defaultValue="New"
                                            helperText="Read Only"
                                            // onChange={(event) =>
                                            //   setStage(event.target.value)
                                            // }
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                          />
                                        </Grid>

                                        {/* <Grid item xs={12} sm={4}>
                                          <TextField
                                            name="accmanager"
                                            variant="outlined"
                                            fullWidth
                                            label="Account Manager"
                                            type="text"
                                            value={accmanager}
                                            onChange={(event) =>
                                              setAccManager(event.target.value)
                                            }
                                            autoFocus
                                          />
                                        </Grid> */}

                                        <Grid
                                          item
                                          xs={12}
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <div className="blog-area">
                                            <div className="info">
                                              <div
                                                className="content"
                                                style={{
                                                  padding: 10,
                                                  position: "static",
                                                }}
                                              >
                                                <a
                                                  class="btn btn-previous"
                                                  style={{
                                                    color: "#000",
                                                    textAlign: "center",
                                                    whiteSpace: "nowrap",
                                                    fontSize: 12,
                                                  }}
                                                >
                                                  <i
                                                    className="fa fa-arrow-left"
                                                    style={{ color: "#000" }}
                                                  ></i>{" "}
                                                  Previous
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="blog-area">
                                            <div className="info">
                                              <div
                                                className="content"
                                                style={{
                                                  padding: 10,
                                                  position: "static",
                                                }}
                                              >
                                                <a
                                                  class="btn btn-next"
                                                  style={{
                                                    color: "#000",
                                                    textAlign: "center",
                                                    whiteSpace: "nowrap",
                                                    fontSize: 12,
                                                  }}
                                                >
                                                  Next{" "}
                                                  <i
                                                    className="fa fa-arrow-right"
                                                    style={{ color: "#000" }}
                                                  ></i>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </fieldset>

                                  <fieldset style={{ display: "none" }}>
                                    <div class="form-bottom">
                                      <h4>Level of Education</h4>
                                      <hr />
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                          {/* <FormControl component="fieldset">
                                            <RadioGroup
                                              aria-label="gender"
                                              name="levelofeducation"
                                              row
                                              value={LevelOfEducation}
                                              onChange={(event) =>
                                                setLevelOfEducation(event.target.value)
                                              }
                                            > */}

                                              {/* <FormControlLabel
                                               checked={educationLevel}
                                                value={educationLevel}
                                                id="educationLevel"
                                                control={<Radio />}
                                                label="Secondary/High School"
                                                onChange={(event) =>
                                                  setEducationLevel(
                                                    event.target.checked
                                                  )
                                                }
                                              /> */}
                                          <FormGroup row>

                                          <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                        checked={educationLevel=="true" || educationLevel==true}
                                                        // value={educationLevel}
                                                        id="educationLevel"
                                                        onChange={(event) =>
                                                          setEducationLevel(
                                                            event.target.checked
                                                          )
                                                        }
                                                        />
                                                      }
                                                      label="Secondary/High School"
                                                      />  


                                              <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                        checked={ug=="true" || ug==true}
                                                        // value={ug}
                                                        // id="ug" 
                                                        label="Undergraduate/Bachelor"
                                                        color="primary"
                                                        onChange={(event) =>
                                                        setug(event.target.checked)
                                                          }
                                                        />
                                                      }
                                                      label="Undergraduate/Bachelor"
                                                      />
                                               <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                        checked={pg=="true" || pg==true}
                                                        // value={pg}
                                                        label="Master/Postgraduate"
                                                        // onChange={() =>handlePG()}
                                                        color="primary"
                                                        id="pg"
                                                        onChange={(event) =>
                                                          setpg(event.target.checked)
                                                        }
                                                        />
                                                      }
                                                      label="Master/Postgraduate"
                                                    />

                                               <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                        checked={doc=="true" || doc==true}
                                                        // value={doc}
                                                        id="doc"
                                                        label="Doctoral"
                                                        onChange={(event) =>
                                                            setdoc(event.target.checked)
                                                          }
                                                        />
                                                      }
                                                      label="Doctoral"
                                                    />

                                                  <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                        // value={certi}
                                                        checked={certi=="true" || certi==true}
                                                        label="Certificate"
                                                        id="certi"
                                                        onChange={(event) =>
                                                          setcerti(event.target.checked)
                                                        }
                                                        />
                                                      }
                                                      label="Certificate"
                                                    />

                                                  <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                        // value={dip}
                                                        id="dip"
                                                        checked={dip=="true" || dip==true}
                                                        label="Diploma"
                                                        onChange={(event) =>
                                                          setdip(event.target.checked)
                                                        }
                                                        />
                                                      }
                                                      label="Diploma"
                                                    />

                                                  <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                        // value={el}
                                                        id="el"
                                                        checked={el=="true" || el==true}
                                                        label="English Language"
                                                        onChange={(event) =>
                                                          setel(event.target.checked)
                                                        }
                                                        />
                                                      }
                                                      label="English Language"
                                                    />
                                                  
                                              </FormGroup>  
                                              {/* <FormControlLabel
                                              checked={ug=="true"}
                                              value={ug}
                                                control={<Radio />}
                                                label="Undergraduate/Bachelor"
                                                color="primary"
                                                onChange={(event) =>
                                                  setug(event.target.checked)
                                                }
                                              />

                                              <FormControlLabel
                                                checked={pg=="true"}
                                                value={pg}
                                                control={<Radio />}
                                                label="Master/Postgraduate"
                                                // onChange={() =>handlePG()}
                                                color="primary"
                                                id="pg"
                                                onChange={(event) =>
                                                  setpg(event.target.checked)
                                                }
                                                // onChange={(event) =>
                                                //   setGRE(!pg)
                                                // }
                                              />

                                              <FormControlLabel
                                              checked={doc=="true"}
                                              value={false}
                                                control={<Radio />}
                                                label="Doctoral"
                                                onChange={(event) =>
                                                  setdoc(event.target.checked)
                                                }
                                              />

                                              <FormControlLabel
                                               value={false}
                                                checked={certi=="true"}
                                                control={<Radio />}
                                                label="Certificate"
                                                onChange={(event) =>
                                                  setcerti(event.target.checked)
                                                }
                                              />

                                              <FormControlLabel
                                              value={false}
                                                checked={dip=="true"}
                                                control={<Radio />}
                                                label="Diploma"
                                                onChange={(event) =>
                                                  setdip(event.target.checked)
                                                }
                                              />

                                              <FormControlLabel
                                              value={false}
                                                checked={el=="true"}
                                                control={<Radio />}
                                                label="English Language"
                                                onChange={(event) =>
                                                  setel(event.target.checked)
                                                }
                                              />
                                            </RadioGroup>
                                          </FormControl> */}
                                        </Grid>

                                        {/* <Grid
                                          id="ShowPG"
                                          style={{
                                            display: "none",
                                            margin: 20,
                                          }}
                                        > */}
                                        <Grid item xs={12} sm={6}>
                                          <TextField
                                            name="highschoolmarks"
                                            variant="outlined"
                                            fullWidth
                                            label="High School Marks"
                                            type="text"
                                            value={highschoolmarks}
                                            onChange={(event) =>
                                              setHighSchoolMarks(
                                                event.target.value
                                              )
                                            }
                                            autoFocus
                                          />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                          <TextField
                                            name="highsecmarks"
                                            variant="outlined"
                                            fullWidth
                                            label="High Secondary Marks"
                                            type="text"
                                            value={highsecmarks}
                                            onChange={(event) =>
                                              setHighSecMarks(
                                                event.target.value
                                              )
                                            }
                                            autoFocus
                                          />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                          <TextField
                                            name="bachelormarks"
                                            variant="outlined"
                                            fullWidth
                                            label="Undergraduate Marks"
                                            type="text"
                                            value={ugmarks}
                                            onChange={(event) =>
                                              setUGMarks(event.target.value)
                                            }
                                            autoFocus
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <TextField
                                            name="mastermarks"
                                            variant="outlined"
                                            fullWidth
                                            label="Master / Postgraduate Marks"
                                            type="text"
                                            value={pgmarks}
                                            onChange={(event) =>
                                              setPGMarks(event.target.value)
                                            }
                                            autoFocus
                                          />
                                        </Grid>
                                        {/* </Grid> */}

                                        {/* <Grid
                                          id="ShowUG"
                                          style={{
                                            display: "none",
                                            margin: 20,
                                          }}
                                        >
                                          <Grid item xs={12} sm={6}>
                                            <TextField
                                              name="highschoolmarks"
                                              variant="outlined"
                                              fullWidth
                                              label="High School Marks"
                                              type="text"
                                              value={highschoolmarks}
                                              onChange={(event) =>
                                                setHighSchoolMarks(
                                                  event.target.value
                                                )
                                              }
                                              autoFocus
                                            />
                                          </Grid>

                                          <Grid item xs={12} sm={6}>
                                            <TextField
                                              name="highsecmarks"
                                              variant="outlined"
                                              fullWidth
                                              label="High Secondary Marks"
                                              type="text"
                                              value={highsecmarks}
                                              onChange={(event) =>
                                                setHighSecMarks(
                                                  event.target.value
                                                )
                                              }
                                              autoFocus
                                            />
                                          </Grid>

                                          <Grid item xs={12} sm={6}>
                                            <TextField
                                              name="bachelormarks"
                                              variant="outlined"
                                              fullWidth
                                              label="Undergraduate Marks"
                                              type="text"
                                              value={ugmarks}
                                              onChange={(event) =>
                                                setUGMarks(event.target.value)
                                              }
                                              autoFocus
                                            />
                                          </Grid>
                                         
                                        </Grid> */}

                                        <Grid
                                          item
                                          xs={12}
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <div className="blog-area">
                                            <div className="info">
                                              <div
                                                className="content"
                                                style={{
                                                  padding: 10,
                                                  position: "static",
                                                }}
                                              >
                                                <a
                                                  class="btn btn-previous"
                                                  style={{
                                                    color: "#000",
                                                    textAlign: "center",
                                                    whiteSpace: "nowrap",
                                                    fontSize: 12,
                                                  }}
                                                >
                                                  <i
                                                    className="fa fa-arrow-left"
                                                    style={{ color: "#000" }}
                                                  ></i>{" "}
                                                  Previous
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="blog-area">
                                            <div className="info">
                                              <div
                                                className="content"
                                                style={{
                                                  padding: 10,
                                                  position: "static",
                                                }}
                                              >
                                                <a
                                                  class="btn btn-next"
                                                  style={{
                                                    color: "#000",
                                                    textAlign: "center",
                                                    whiteSpace: "nowrap",
                                                    fontSize: 12,
                                                  }}
                                                >
                                                  Next{" "}
                                                  <i
                                                    className="fa fa-arrow-right"
                                                    style={{ color: "#000" }}
                                                  ></i>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>

                                      {/* <button type="button" class="btn btn-previous">Previous</button>
<button type="button" class="btn btn-next">Next</button> */}
                                    </div>
                                  </fieldset>

                                  <fieldset style={{ display: "none" }}>
                                    <div class="form-bottom">
                                      <h4>Institution Attended</h4>
                                      <hr />
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            name="institutionname"
                                            variant="outlined"
                                            fullWidth
                                            label="Name of Institution"
                                            type="text"
                                            value={instituteName}
                                            onChange={(event) =>
                                              setInstituteName(
                                                event.target.value
                                              )
                                            }
                                            autoFocus
                                          />
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            name="institutionlang"
                                            variant="outlined"
                                            fullWidth
                                            label="Language of Institution"
                                            type="text"
                                            value={instituteLang}
                                            onChange={(event) =>
                                              setInstituteLang(
                                                event.target.value
                                              )
                                            }
                                            autoFocus
                                          />
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            name="degreename"
                                            variant="outlined"
                                            fullWidth
                                            label="Degree Name"
                                            type="text"
                                            value={degreeName}
                                            onChange={(event) =>
                                              setDegreeName(event.target.value)
                                            }
                                            autoFocus
                                          />
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            // name="dob"
                                            // id="dob"
                                            type="date"
                                            value={attInstituteFrom}
                                            fullWidth
                                            label="Attended Institution From"
                                            variant="outlined"
                                            onChange={(event) =>
                                              setAttInstituteFrom(
                                                event.target.value
                                              )
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />

                                          {/* <MuiPickersUtilsProvider
                                            style={{ width: "100%" }}
                                            fullWidth
                                            utils={DateFnsUtils}
                                          >
                                            <KeyboardDatePicker
                                              autoOk
                                              variant="inline"
                                              inputVariant="outlined"
                                              fullWidth
                                              InputAdornmentProps={{
                                                position: "end",
                                              }}
                                              style={{ width: "100%" }}
                                              id="date-picker-dialog"
                                              label="Attended Institution From"
                                              // format="dd/MM/yyyy"
                                              format="yyyy/MM/dd"
                                              onChange={(event) =>
                                                setAttInstituteFrom(event)
                                              }
                                              value={attInstituteFrom}
                                              //onChange={handleDateChange}
                                              KeyboardButtonProps={{
                                                "aria-label": "change date",
                                              }}
                                            />
                                          </MuiPickersUtilsProvider> */}
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            // name="dob"
                                            // id="dob"
                                            type="date"
                                            value={attInstituteTO}
                                            fullWidth
                                            label="Attended Institution To"
                                            variant="outlined"
                                            onChange={(event) =>
                                              setAttInstituteTo(
                                                event.target.value
                                              )
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />

                                          {/* <MuiPickersUtilsProvider
                                            style={{ width: "100%" }}
                                            fullWidth
                                            utils={DateFnsUtils}
                                          >
                                            <KeyboardDatePicker
                                              autoOk
                                              variant="inline"
                                              inputVariant="outlined"
                                              fullWidth
                                              InputAdornmentProps={{
                                                position: "end",
                                              }}
                                              style={{ width: "100%" }}
                                              id="date-picker-dialog"
                                              label="Attended Institution To"
                                              // format="dd/MM/yyyy"
                                              format="yyyy/MM/dd"
                                              onChange={(event) =>
                                                setAttInstituteTo(event)
                                              }
                                              value={attInstituteTO}
                                              //onChange={handleDateChange}
                                              KeyboardButtonProps={{
                                                "aria-label": "change date",
                                              }}
                                            />
                                          </MuiPickersUtilsProvider> */}
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            // name="dob"
                                            // id="dob"
                                            type="date"
                                            fullWidth
                                            variant="outlined"
                                            label="Degree Awarded On"
                                            value={degreeAwardedOn}
                                            onChange={(event) =>
                                              setDegreeAwardedOn(event.target.value)
                                            }
                                            value={degreeAwardedOn}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />

                                          {/* <MuiPickersUtilsProvider
                                            style={{ width: "100%" }}
                                            fullWidth
                                            utils={DateFnsUtils}
                                          >
                                            <KeyboardDatePicker
                                              autoOk
                                              variant="inline"
                                              inputVariant="outlined"
                                              fullWidth
                                              InputAdornmentProps={{
                                                position: "end",
                                              }}
                                              style={{ width: "100%" }}
                                              id="date-picker-dialog"
                                              label="Degree Awarded On"
                                              // format="dd/MM/yyyy"
                                              format="yyyy/MM/dd"
                                              onChange={(event) =>
                                                setDegreeAwardedOn(event)
                                              }
                                              value={degreeAwardedOn}
                                              //onChange={handleDateChange}
                                              KeyboardButtonProps={{
                                                "aria-label": "change date",
                                              }}
                                            />
                                          </MuiPickersUtilsProvider> */}
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            name="major"
                                            variant="outlined"
                                            fullWidth
                                            label="Major"
                                            type="text"
                                            value={major}
                                            onChange={(event) =>
                                              setMajor(event.target.value)
                                            }
                                            autoFocus
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            name="grade"
                                            variant="outlined"
                                            fullWidth
                                            label="Grade Average"
                                            type="text"
                                            value={gradeAvg}
                                            onChange={(event) =>
                                              setGradeAvg(event.target.value)
                                            }
                                            autoFocus
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            name="schooladdress"
                                            variant="outlined"
                                            fullWidth
                                            label="School Address"
                                            type="text"
                                            value={schoolAdd}
                                            onChange={(event) =>
                                              setSchoolAdd(event.target.value)
                                            }
                                            autoFocus
                                          />
                                        </Grid>

                                        <Grid
                                          item
                                          xs={12}
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <div className="blog-area">
                                            <div className="info">
                                              <div
                                                className="content"
                                                style={{
                                                  padding: 10,
                                                  position: "static",
                                                }}
                                              >
                                                <a
                                                  class="btn btn-previous"
                                                  style={{
                                                    color: "#000",
                                                    textAlign: "center",
                                                    whiteSpace: "nowrap",
                                                    fontSize: 12,
                                                  }}
                                                >
                                                  <i
                                                    className="fa fa-arrow-left"
                                                    style={{ color: "#000" }}
                                                  ></i>{" "}
                                                  Previous
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="blog-area">
                                            <div className="info">
                                              <div
                                                className="content"
                                                style={{
                                                  padding: 10,
                                                  position: "static",
                                                }}
                                              >
                                                <a
                                                  class="btn btn-next"
                                                  style={{
                                                    color: "#000",
                                                    textAlign: "center",
                                                    whiteSpace: "nowrap",
                                                    fontSize: 12,
                                                  }}
                                                >
                                                  Next{" "}
                                                  <i
                                                    className="fa fa-arrow-right"
                                                    style={{ color: "#000" }}
                                                  ></i>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>
                                      {/* <br/>
          <button type="button" class="btn btn-previous">Previous</button>
<button type="button" class="btn btn-next">Next</button> */}
                                    </div>
                                  </fieldset>

                                  {/* <fieldset style={{ display: "none" }}> */}
                                  {/* <div class="form-bottom">
                                      <h4>Activities</h4>
                                      <hr />
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                          <TextField
                                            name="Activitytype"
                                            variant="outlined"
                                            fullWidth
                                            label="Activity Type"
                                            type="text"
                                            value={activityType}
                                            onChange={(event) =>
                                              setActivityType(
                                                event.target.value
                                              )
                                            }
                                            autoFocus
                                          />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                          <TextField
                                            name="orag"
                                            variant="outlined"
                                            fullWidth
                                            label="Oragnisation"
                                            type="text"
                                            value={organisation}
                                            onChange={(event) =>
                                              setOrganisation(
                                                event.target.value
                                              )
                                            }
                                            autoFocus
                                          />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                          <TextField
                                            name="description"
                                            variant="outlined"
                                            fullWidth
                                            label="Description"
                                            type="text"
                                            value={description}
                                            onChange={(event) =>
                                              setDescription(event.target.value)
                                            }
                                            autoFocus
                                          />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                          <TextField
                                            name="duration"
                                            variant="outlined"
                                            fullWidth
                                            label="Duration"
                                            type="text"
                                            value={duration}
                                            onChange={(event) =>
                                              setDuration(event.target.value)
                                            }
                                            autoFocus
                                          />
                                        </Grid>

                                        <Grid
                                          item
                                          xs={12}
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <div className="blog-area">
                                            <div className="info">
                                              <div
                                                className="content"
                                                style={{
                                                  padding: 10,
                                                  position: "static",
                                                }}
                                              >
                                                <a
                                                  class="btn btn-previous"
                                                  style={{
                                                    color: "#000",
                                                    textAlign: "center",
                                                    whiteSpace: "nowrap",
                                                    fontSize: 12,
                                                  }}
                                                >
                                                  <i
                                                    className="fa fa-arrow-left"
                                                    style={{ color: "#000" }}
                                                  ></i>{" "}
                                                  Previous
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="blog-area">
                                            <div className="info">
                                              <div
                                                className="content"
                                                style={{
                                                  padding: 10,
                                                  position: "static",
                                                }}
                                              >
                                                <a
                                                  class="btn btn-next"
                                                  style={{
                                                    color: "#000",
                                                    textAlign: "center",
                                                    whiteSpace: "nowrap",
                                                    fontSize: 12,
                                                  }}
                                                >
                                                  Next{" "}
                                                  <i
                                                    className="fa fa-arrow-right"
                                                    style={{ color: "#000" }}
                                                  ></i>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>

                                      
                                    </div> */}
                                  {/* </fieldset> */}

                                  <fieldset style={{ display: "none" }}>
                                    <div class="form-bottom">
                                      {/*Start Test Score */}

                                      <div className={classes.paper}>
                                        <h4>Select Test Scores</h4>
                                        <hr />

                                        <form
                                          className={classes.form}
                                          noValidate
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12}>
                                              <FormGroup row>
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={gre=="true"}
                                                      //onChange={handleChange}
                                                      name="GRE"
                                                      id="gre"
                                                      value={gre}
                                                      onClick={() =>
                                                        handleGRE()
                                                      }
                                                      color="primary"
                                                      onChange={(event) =>
                                                        setGRE(!gre)
                                                      }
                                                    />
                                                  }
                                                  label="GRE"
                                                />

                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={gmat=="true"}
                                                      //onChange={handleChange}
                                                      id="gmat"
                                                      value={gmat}
                                                      onClick={() =>
                                                        handleGMAT()
                                                      }
                                                      name="GMAT"
                                                      color="primary"
                                                      onChange={(event) =>
                                                        setGMAT(!gmat)
                                                      }
                                                    />
                                                  }
                                                  label="GMAT"
                                                />

                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={sat=="true"}
                                                      //onChange={handleChange}
                                                      name="SAT"
                                                      value={sat}
                                                      id="sat"
                                                      onClick={() =>
                                                        handleSAT()
                                                      }
                                                      color="primary"
                                                      onChange={(event) =>
                                                        setSAT(!sat)
                                                      }
                                                    />
                                                  }
                                                  label="SAT"
                                                />

                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={act=="true"}
                                                      //onChange={handleChange}
                                                      name="ACT"
                                                      value={act}
                                                      id="act"
                                                      onClick={() =>
                                                        handleACT()
                                                      }
                                                      color="primary"
                                                      onChange={(event) =>
                                                        setACT(!act)
                                                      }
                                                    />
                                                  }
                                                  label="ACT"
                                                />

                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={toefl=="true"}
                                                      //onChange={handleChange}
                                                      name="TOEFL"
                                                      id="toefl"
                                                      value={toefl}
                                                      onClick={() =>
                                                        handleTOEFL()
                                                      }
                                                      color="primary"
                                                      onChange={(event) =>
                                                        setTOEFL(!toefl)
                                                      }
                                                    />
                                                  }
                                                  label="TOEFL"
                                                />

                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={ielts=="true"}
                                                      //onChange={handleChange}
                                                      name="IELTS"
                                                      value={ielts}
                                                      id="ielts"
                                                      onClick={() =>
                                                        handleIELTS()
                                                      }
                                                      color="primary"
                                                      onChange={(event) =>
                                                        setIELTS(!ielts)
                                                      }
                                                    />
                                                  }
                                                  label="IELTS"
                                                />

                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={duo=="true"}
                                                      //onChange={handleChange}
                                                      name="Duolingo"
                                                      value={duo}
                                                      id="duolingo"
                                                      onClick={() =>
                                                        handleDuo()
                                                      }
                                                      color="primary"
                                                      onChange={(event) =>
                                                        setDuo(!duo)
                                                      }
                                                    />
                                                  }
                                                  label="Duolingo"
                                                />

                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={pte=="true"}
                                                      //onChange={handleChange}
                                                      value={pte}
                                                      onChange={(event) =>
                                                        setPTE(!pte)
                                                      }
                                                      name="PTE"
                                                      id="pte"
                                                      onClick={() =>
                                                        handlePTE()
                                                      }
                                                      color="primary"
                                                    />
                                                  }
                                                  label="PTE"
                                                />

                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={moi=="true"}
                                                      //onChange={handleChange}
                                                      name="MOI"
                                                      color="primary"
                                                      value={moi}
                                                      onChange={(event) =>
                                                        setMOI(!moi)
                                                      }
                                                    />
                                                  }
                                                  label="I have MOI"
                                                />

                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                    checked={provideLater=="true"}
                                                      //onChange={handleChange}
                                                      name="providelater"
                                                      color="primary"
                                                      value={provideLater}
                                                      onChange={(event) =>
                                                        setProvideLater(
                                                          !provideLater
                                                        )
                                                      }
                                                    />
                                                  }
                                                  label="I will provide later"
                                                />

                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                    checked={dontHave=="true"}
                                                      //onChange={handleChange}
                                                      name="donthave"
                                                      color="primary"
                                                      value={dontHave}
                                                      onChange={(event) =>
                                                        setDontHave(!dontHave)
                                                      }
                                                    />
                                                  }
                                                  label="I don't have it"
                                                />
                                              </FormGroup>
                                            </Grid>
                                            <br />

                                            <Grid
                                              container
                                              spacing={2}
                                              id="showGRE"
                                              style={{
                                                display: "none",
                                                margin: 20,
                                              }}
                                            >
                                              <Grid item xs={12} sm={12}>
                                                <h4>Appeared for GRE?</h4>
                                              </Grid>
                                              <br />

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  // name="dob"
                                                  // id="dob"
                                                  type="date"
                                                  fullWidth
                                                  variant="outlined"
                                                  label="GRE Appeared On"
                                                  onChange={(event) =>
                                                    setGREAppreadOn(
                                                      event.target.value
                                                    )
                                                  }
                                                  value={greAppreadOn}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                />
                                                {/* <MuiPickersUtilsProvider
                                                  style={{ width: "100%" }}
                                                  fullWidth
                                                  utils={DateFnsUtils}
                                                >
                                                  <KeyboardDatePicker
                                                    autoOk
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    fullWidth
                                                    InputAdornmentProps={{
                                                      position: "end",
                                                    }}
                                                    style={{ width: "100%" }}
                                                    id="date-picker-dialog"
                                                    label="Degree Awarded On"
                                                    format="yyyy/MM/dd"
                                                    // format="dd/MM/yyyy"
                                                    onChange={(event) =>
                                                      setGREAppreadOn(event)
                                                    }
                                                    value={greAppreadOn}
                                                    //onChange={handleDateChange}
                                                    KeyboardButtonProps={{
                                                      "aria-label":
                                                        "change date",
                                                    }}
                                                  /> */}
                                                {/* </MuiPickersUtilsProvider> */}
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="GREvs"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="GRE Verbal Score"
                                                  type="text"
                                                  onChange={(event) =>
                                                    setGREVS(event.target.value)
                                                  }
                                                  value={greVS}
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="GREqs"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="GRE Quantitative Score"
                                                  type="text"
                                                  onChange={(event) =>
                                                    setGREQS(event.target.value)
                                                  }
                                                  value={greQS}
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="GRE"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="GRE Writing Score"
                                                  type="text"
                                                  value={greWS}
                                                  onChange={(event) =>
                                                    setGREWS(event.target.value)
                                                  }
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="GREname1"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="GRE Subject 1 Name"
                                                  type="text"
                                                  value={greS1Name}
                                                  onChange={(event) =>
                                                    setGRES1Name(
                                                      event.target.value
                                                    )
                                                  }
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="GREscore1"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="GRE Subject 1 Score"
                                                  type="text"
                                                  value={greS1Score}
                                                  onChange={(event) =>
                                                    setGRES1Score(
                                                      event.target.value
                                                    )
                                                  }
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="GREname2"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="GRE Subject 2 Name"
                                                  type="text"
                                                  value={greS2Name}
                                                  onChange={(event) =>
                                                    setGRES2Name(
                                                      event.target.value
                                                    )
                                                  }
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="GREscore2"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="GRE Subject 2 Score"
                                                  type="text"
                                                  autoFocus
                                                  value={greS2Score}
                                                  onChange={(event) =>
                                                    setGRES2Score(
                                                      event.target.value
                                                    )
                                                  }
                                                />
                                              </Grid>
                                            </Grid>

                                            <br />
                                            <br />

                                            <Grid
                                              container
                                              spacing={2}
                                              id="showGMAT"
                                              style={{
                                                display: "none",
                                                margin: 20,
                                              }}
                                            >
                                              <Grid item xs={12} sm={12}>
                                                <h4>Appeared for GMAT?</h4>
                                              </Grid>
                                              <br />
                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  // name="dob"
                                                  // id="dob"
                                                  type="date"
                                                  fullWidth
                                                  variant="outlined"
                                                  label="GMAT Appeared On"
                                                  onChange={(event) =>
                                                    setGMATAppreadOn(
                                                      event.target.value
                                                    )
                                                  }
                                                  value={gmatAppreadOn}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                />
                                                {/* <MuiPickersUtilsProvider
                                                  style={{ width: "100%" }}
                                                  fullWidth
                                                  utils={DateFnsUtils}
                                                >
                                                  <KeyboardDatePicker
                                                    autoOk
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    fullWidth
                                                    InputAdornmentProps={{
                                                      position: "end",
                                                    }}
                                                    style={{ width: "100%" }}
                                                    id="date-picker-dialog"
                                                    label="Degree Awarded On"
                                                    format="yyyy/MM/dd"
                                                    // format="dd/MM/yyyy"
                                                    onChange={(event) =>
                                                      setGMATAppreadOn(event)
                                                    }
                                                    value={gmatAppreadOn}
                                                    //onChange={handleDateChange}
                                                    KeyboardButtonProps={{
                                                      "aria-label":
                                                        "change date",
                                                    }}
                                                  />
                                                </MuiPickersUtilsProvider> */}
                                              </Grid>
                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="GMATvs"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="GMAT Verbal Score"
                                                  type="text"
                                                  onChange={(event) =>
                                                    setGMATVS(
                                                      event.target.value
                                                    )
                                                  }
                                                  value={gmatVS}
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="GMATqs"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="GMAT Quantitative Score"
                                                  type="text"
                                                  onChange={(event) =>
                                                    setGMATQS(
                                                      event.target.value
                                                    )
                                                  }
                                                  value={gmatQS}
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="GMAT"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="GMAT Writing Score"
                                                  type="text"
                                                  autoFocus
                                                  value={gmatWS}
                                                  onChange={(event) =>
                                                    setGMATWS(
                                                      event.target.value
                                                    )
                                                  }
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="GMATos"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="GMAT Overall Score"
                                                  type="text"
                                                  autoFocus
                                                  value={gmatOS}
                                                  onChange={(event) =>
                                                    setGMAtOS(
                                                      event.target.value
                                                    )
                                                  }
                                                />
                                              </Grid>
                                            </Grid>

                                            <br />
                                            <br />

                                            <Grid
                                              container
                                              spacing={2}
                                              id="showSAT"
                                              style={{
                                                display: "none",
                                                margin: 20,
                                              }}
                                            >
                                              <Grid item xs={12} sm={12}>
                                                <h4>Appeared for SAT?</h4>
                                              </Grid>
                                              <br />
                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  // name="dob"
                                                  // id="dob"
                                                  type="date"
                                                  fullWidth
                                                  variant="outlined"
                                                  label="SAT Appeared On"
                                                  onChange={(event) =>
                                                    setSATAppreadOn(
                                                      event.target.value
                                                    )
                                                  }
                                                  value={satAppreadOn}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                />
                                                {/* <MuiPickersUtilsProvider
                                                  style={{ width: "100%" }}
                                                  fullWidth
                                                  utils={DateFnsUtils}
                                                >
                                                  <KeyboardDatePicker
                                                    autoOk
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    fullWidth
                                                    InputAdornmentProps={{
                                                      position: "end",
                                                    }}
                                                    style={{ width: "100%" }}
                                                    id="date-picker-dialog"
                                                    label="Degree Awarded On"
                                                    format="yyyy/MM/dd"
                                                    // format="dd/MM/yyyy"
                                                    onChange={(event) =>
                                                      setSATAppreadOn(event)
                                                    }
                                                    value={satAppreadOn}
                                                    //onChange={handleDateChange}
                                                    KeyboardButtonProps={{
                                                      "aria-label":
                                                        "change date",
                                                    }}
                                                  />
                                                </MuiPickersUtilsProvider> */}
                                              </Grid>
                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="SATrrs"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="SAT Reading-Writing Score"
                                                  type="text"
                                                  onChange={(event) =>
                                                    setSATRWS(
                                                      event.target.value
                                                    )
                                                  }
                                                  value={satRWS}
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="SATes"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="SAT Essay Score"
                                                  type="text"
                                                  onChange={(event) =>
                                                    setSATES(event.target.value)
                                                  }
                                                  value={satES}
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="SAT"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="SAT Math Score"
                                                  type="text"
                                                  value={satMS}
                                                  onChange={(event) =>
                                                    setSATMS(event.target.value)
                                                  }
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="SATname1"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="SAT Subject 1 Name"
                                                  type="text"
                                                  value={satS1Name}
                                                  onChange={(event) =>
                                                    setSATS1Name(
                                                      event.target.value
                                                    )
                                                  }
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="SATscore1"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="SAT Subject 1 Score"
                                                  type="text"
                                                  value={satS1Score}
                                                  onChange={(event) =>
                                                    setSATS1Score(
                                                      event.target.value
                                                    )
                                                  }
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="SATname2"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="SAT Subject 2 Name"
                                                  type="text"
                                                  value={satS2Name}
                                                  onChange={(event) =>
                                                    setSATS2Name(
                                                      event.target.value
                                                    )
                                                  }
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="SATscore2"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="SAT Subject 2 Score"
                                                  type="text"
                                                  autoFocus
                                                  value={satS2Score}
                                                  onChange={(event) =>
                                                    setSATS2Score(
                                                      event.target.value
                                                    )
                                                  }
                                                />
                                              </Grid>
                                            </Grid>

                                            <br />
                                            <br />
                                            <Grid
                                              container
                                              spacing={2}
                                              id="showACT"
                                              style={{
                                                display: "none",
                                                margin: 20,
                                              }}
                                            >
                                              <Grid item xs={12} sm={12}>
                                                <h4>Appeared for ACT?</h4>
                                              </Grid>
                                              <br />
                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  // name="dob"
                                                  // id="dob"
                                                  type="date"
                                                  fullWidth
                                                  variant="outlined"
                                                  label="ACT Appeared On"
                                                  onChange={(event) =>
                                                    setACTAppreadOn(
                                                      event.target.value
                                                    )
                                                  }
                                                  value={actAppreadOn}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                />
                                                {/* <MuiPickersUtilsProvider
                                                  style={{ width: "100%" }}
                                                  fullWidth
                                                  utils={DateFnsUtils}
                                                >
                                                  <KeyboardDatePicker
                                                    autoOk
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    fullWidth
                                                    InputAdornmentProps={{
                                                      position: "end",
                                                    }}
                                                    style={{ width: "100%" }}
                                                    id="date-picker-dialog"
                                                    label="Degree Awarded On"
                                                    // format="dd/MM/yyyy"
                                                    format="yyyy/MM/dd"
                                                    onChange={(event) =>
                                                      setACTAppreadOn(event)
                                                    }
                                                    value={actAppreadOn}
                                                    //onChange={handleDateChange}
                                                    KeyboardButtonProps={{
                                                      "aria-label":
                                                        "change date",
                                                    }}
                                                  />
                                                </MuiPickersUtilsProvider> */}
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="ACTcs"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="ACT Composite Score"
                                                  type="text"
                                                  onChange={(event) =>
                                                    setACTCS(event.target.value)
                                                  }
                                                  value={actCS}
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="ACTws"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="ACT Writing Score"
                                                  type="text"
                                                  onChange={(event) =>
                                                    setACTWS(event.target.value)
                                                  }
                                                  value={actWS}
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="ACTms"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="ACT Math Score"
                                                  type="text"
                                                  onChange={(event) =>
                                                    setACTMS(event.target.value)
                                                  }
                                                  value={actMS}
                                                  autoFocus
                                                />
                                              </Grid>
                                            </Grid>

                                            <br />
                                            <br />

                                            <Grid
                                              container
                                              spacing={2}
                                              id="showTOEFL"
                                              style={{
                                                display: "none",
                                                margin: 20,
                                              }}
                                            >
                                              <Grid item xs={12} sm={12}>
                                                <h4>Appeared for TOEFL?</h4>
                                              </Grid>
                                              <br />
                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  // name="dob"
                                                  // id="dob"
                                                  type="date"
                                                  fullWidth
                                                  variant="outlined"
                                                  label="TOEFL Appeared On"
                                                  onChange={(event) =>
                                                    setTOEFLAppreadOn(
                                                      event.target.value
                                                    )
                                                  }
                                                  value={toeflAppreadOn}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                />
                                                {/* <MuiPickersUtilsProvider
                                                  style={{ width: "100%" }}
                                                  fullWidth
                                                  utils={DateFnsUtils}
                                                >
                                                  <KeyboardDatePicker
                                                    autoOk
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    fullWidth
                                                    InputAdornmentProps={{
                                                      position: "end",
                                                    }}
                                                    style={{ width: "100%" }}
                                                    id="date-picker-dialog"
                                                    label="Degree Awarded On"
                                                    // format="dd/MM/yyyy"
                                                    format="yyyy/MM/dd"
                                                    onChange={(event) =>
                                                      setTOEFLAppreadOn(event)
                                                    }
                                                    value={toeflAppreadOn}
                                                    //onChange={handleDateChange}
                                                    KeyboardButtonProps={{
                                                      "aria-label":
                                                        "change date",
                                                    }}
                                                  />
                                                </MuiPickersUtilsProvider> */}
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="TOEFLts"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="TOEFL Test Score"
                                                  type="text"
                                                  onChange={(event) =>
                                                    setTOEFLTS(
                                                      event.target.value
                                                    )
                                                  }
                                                  value={toeflTS}
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="TOEFLls"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="TOEFL Listening Score"
                                                  type="text"
                                                  onChange={(event) =>
                                                    setTOEFLLS(
                                                      event.target.value
                                                    )
                                                  }
                                                  value={toeflLS}
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="TOEFLrs"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="TOEFL Reading Score"
                                                  type="text"
                                                  onChange={(event) =>
                                                    setTOEFLRS(
                                                      event.target.value
                                                    )
                                                  }
                                                  value={toeflRS}
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="TOEFLss"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="TOEFL Speaking Score"
                                                  type="text"
                                                  autoFocus
                                                  onChange={(event) =>
                                                    setTOEFLSS(
                                                      event.target.value
                                                    )
                                                  }
                                                  value={toeflSS}
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="TOEFLws"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="TOEFL Writing Score"
                                                  type="text"
                                                  value={toeflWS}
                                                  onChange={(event) =>
                                                    setTOEFLWS(
                                                      event.target.value
                                                    )
                                                  }
                                                  autoFocus
                                                />
                                              </Grid>
                                            </Grid>

                                            <br />
                                            <br />

                                            <Grid
                                              container
                                              spacing={2}
                                              id="showIELTS"
                                              style={{
                                                display: "none",
                                                margin: 20,
                                              }}
                                            >
                                              <Grid item xs={12} sm={12}>
                                                <h4>Appeared for IELTS?</h4>
                                              </Grid>
                                              <br />
                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  // name="dob"
                                                  // id="dob"
                                                  type="date"
                                                  fullWidth
                                                  variant="outlined"
                                                  label="IELTS Appeared On"
                                                  onChange={(event) =>
                                                    setIELTSAppreadOn(
                                                      event.target.value
                                                    )
                                                  }
                                                  value={ieltsAppreadOn}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                />
                                                {/* <MuiPickersUtilsProvider
                                                  style={{ width: "100%" }}
                                                  fullWidth
                                                  utils={DateFnsUtils}
                                                >
                                                  <KeyboardDatePicker
                                                    autoOk
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    fullWidth
                                                    InputAdornmentProps={{
                                                      position: "end",
                                                    }}
                                                    style={{ width: "100%" }}
                                                    id="date-picker-dialog"
                                                    label="Degree Awarded On"
                                                    // format="dd/MM/yyyy"
                                                    format="yyyy/MM/dd"
                                                    onChange={(event) =>
                                                      setIELTSAppreadOn(event)
                                                    }
                                                    value={ieltsAppreadOn}
                                                    //onChange={handleDateChange}
                                                    KeyboardButtonProps={{
                                                      "aria-label":
                                                        "change date",
                                                    }}
                                                  />
                                                </MuiPickersUtilsProvider> */}
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="IELTSts"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="IELTS Test Score"
                                                  type="text"
                                                  value={ieltsTS}
                                                  onChange={(event) =>
                                                    setIELTSTS(
                                                      event.target.value
                                                    )
                                                  }
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="IELTSls"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="IELTS Listening Score"
                                                  onChange={(event) =>
                                                    setIELTSLS(
                                                      event.target.value
                                                    )
                                                  }
                                                  type="text"
                                                  value={ieltsLS}
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="IELTSrs"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="IELTS Reading Score"
                                                  onChange={(event) =>
                                                    setIELTSRS(
                                                      event.target.value
                                                    )
                                                  }
                                                  type="text"
                                                  value={ieltsRS}
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="IELTSss"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="IELTS Speaking Score"
                                                  type="text"
                                                  onChange={(event) =>
                                                    setIELTSSS(
                                                      event.target.value
                                                    )
                                                  }
                                                  value={ieltsSS}
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="IELTSws"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="IELTS Writing Score"
                                                  type="text"
                                                  value={ieltsWS}
                                                  onChange={(event) =>
                                                    setIELTSWS(
                                                      event.target.value
                                                    )
                                                  }
                                                  autoFocus
                                                />
                                              </Grid>
                                            </Grid>
                                            <br />
                                            <br />

                                            <Grid
                                              container
                                              spacing={2}
                                              id="showPTE"
                                              style={{
                                                display: "none",
                                                margin: 20,
                                              }}
                                            >
                                              <Grid item xs={12} sm={12}>
                                                <h4>Appeared for PTE?</h4>
                                              </Grid>
                                              <br />
                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  // name="dob"
                                                  // id="dob"
                                                  type="date"
                                                  fullWidth
                                                  variant="outlined"
                                                  label="PTE Appeared On"
                                                  onChange={(event) =>
                                                    setPTEAppreadOn(
                                                      event.target.value
                                                    )
                                                  }
                                                  value={pteAppreadOn}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                />
                                                {/* <MuiPickersUtilsProvider
                                                  style={{ width: "100%" }}
                                                  fullWidth
                                                  utils={DateFnsUtils}
                                                >
                                                  <KeyboardDatePicker
                                                    autoOk
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    fullWidth
                                                    InputAdornmentProps={{
                                                      position: "end",
                                                    }}
                                                    style={{ width: "100%" }}
                                                    id="date-picker-dialog"
                                                    label="Degree Awarded On"
                                                    // format="dd/MM/yyyy"
                                                    format="yyyy/MM/dd"
                                                    onChange={(event) =>
                                                      setPTEAppreadOn(event)
                                                    }
                                                    value={pteAppreadOn}
                                                    //onChange={handleDateChange}
                                                    KeyboardButtonProps={{
                                                      "aria-label":
                                                        "change date",
                                                    }}
                                                  />
                                                </MuiPickersUtilsProvider> */}
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="PTEos"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="PTE Overall Score"
                                                  type="text"
                                                  value={pteOS}
                                                  onChange={(event) =>
                                                    setPTEOS(event.target.value)
                                                  }
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="PTEls"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="PTE Listening Score"
                                                  type="text"
                                                  value={pteLS}
                                                  onChange={(event) =>
                                                    setPTELS(event.target.value)
                                                  }
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="PTErs"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="PTE Reading Score"
                                                  onChange={(event) =>
                                                    setPTERS(event.target.value)
                                                  }
                                                  type="text"
                                                  value={pteRS}
                                                  autoFocus
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="PTEss"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="PTE Speaking Score"
                                                  type="text"
                                                  autoFocus
                                                  value={pteSS}
                                                  onChange={(event) =>
                                                    setPTESS(event.target.value)
                                                  }
                                                />
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="PTEws"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="PTE Writing Score"
                                                  type="text"
                                                  onChange={(event) =>
                                                    setPTEWS(event.target.value)
                                                  }
                                                  value={pteWS}
                                                  autoFocus
                                                />
                                              </Grid>
                                            </Grid>

                                            <br />
                                            <br />

                                            <Grid
                                              container
                                              spacing={2}
                                              id="showDuo"
                                              style={{
                                                display: "none",
                                                margin: 20,
                                              }}
                                            >
                                              <Grid item xs={12} sm={12}>
                                                <h4>Appeared for Duolingo?</h4>
                                              </Grid>
                                              <br />
                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  // name="dob"
                                                  // id="dob"
                                                  type="date"
                                                  fullWidth
                                                  variant="outlined"
                                                  label="Duolingo Appeared On"
                                                  onChange={(event) =>
                                                    setDuoAppreadOn(
                                                      event.target.value
                                                    )
                                                  }
                                                  value={duoAppreadOn}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                />
                                                {/* <MuiPickersUtilsProvider
                                                  style={{ width: "100%" }}
                                                  fullWidth
                                                  utils={DateFnsUtils}
                                                >
                                                  <KeyboardDatePicker
                                                    autoOk
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    fullWidth
                                                    InputAdornmentProps={{
                                                      position: "end",
                                                    }}
                                                    style={{ width: "100%" }}
                                                    id="date-picker-dialog"
                                                    label="Degree Awarded On"
                                                    // format="dd/MM/yyyy"
                                                    format="yyyy/MM/dd"
                                                    onChange={(event) =>
                                                      setDuoAppreadOn(event)
                                                    }
                                                    value={duoAppreadOn}
                                                    //onChange={handleDateChange}
                                                    KeyboardButtonProps={{
                                                      "aria-label":
                                                        "change date",
                                                    }}
                                                  />
                                                </MuiPickersUtilsProvider> */}
                                              </Grid>

                                              <Grid item xs={12} sm={6}>
                                                <TextField
                                                  name="Dos"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="Duolingo Overall Score"
                                                  type="text"
                                                  value={duoOS}
                                                  onChange={(event) =>
                                                    setDuoOS(event.target.value)
                                                  }
                                                  autoFocus
                                                />
                                              </Grid>
                                            </Grid>

                                            <Grid
                                              item
                                              xs={12}
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <div className="blog-area">
                                                <div className="info">
                                                  <div
                                                    className="content"
                                                    style={{
                                                      padding: 10,
                                                      position: "static",
                                                    }}
                                                  >
                                                    <a
                                                      class="btn btn-previous"
                                                      style={{
                                                        color: "#000",
                                                        textAlign: "center",
                                                        whiteSpace: "nowrap",
                                                        fontSize: 12,
                                                      }}
                                                    >
                                                      <i
                                                        className="fa fa-arrow-left"
                                                        style={{
                                                          color: "#000",
                                                        }}
                                                      ></i>{" "}
                                                      Previous
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="blog-area">
                                                <div className="info">
                                                  <div
                                                    className="content"
                                                    style={{
                                                      padding: 10,
                                                      position: "static",
                                                    }}
                                                  >
                                                    <a
                                                      class="btn btn-next"
                                                      style={{
                                                        color: "#000",
                                                        textAlign: "center",
                                                        whiteSpace: "nowrap",
                                                        fontSize: 12,
                                                      }}
                                                    >
                                                      Next{" "}
                                                      <i
                                                        className="fa fa-arrow-right"
                                                        style={{
                                                          color: "#000",
                                                        }}
                                                      ></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </Grid>
                                          </Grid>
                                        </form>
                                      </div>

                                      {/* End Test Score */}

                                      {/* <button type="button" class="btn btn-previous">Previous</button>
<button type="button" class="btn btn-next">Next</button> */}
                                    </div>
                                  </fieldset>
                                  <fieldset style={{ display: "none" }}>
                                    <div class="form-bottom">
                                      <h4>Immigration History</h4>

                                      <hr />
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                          <TextField
                                            name="lcyv"
                                            variant="outlined"
                                            className="input-error"
                                            fullWidth
                                            label="Last 5 year Country visited"
                                            type="text"
                                            value={lastCountryVisited}
                                            onChange={(event) =>
                                              setLastCountryVisited(
                                                event.target.value
                                              )
                                            }
                                            autoFocus
                                          />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                          <TextField
                                            name="acr"
                                            variant="outlined"
                                            fullWidth
                                            label="Any country refusal"
                                            type="text"
                                            onChange={(event) =>
                                              setCountryRefusal(
                                                event.target.value
                                              )
                                            }
                                            value={countryRefusal}
                                            autoFocus
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <TextField
                                            name="cvisa"
                                            variant="outlined"
                                            fullWidth
                                            label="Do you hold any country visa?"
                                            type="text"
                                            value={countryVisa}
                                            onChange={(event) =>
                                              setCountryVisa(event.target.value)
                                            }
                                            autoFocus
                                          />
                                        </Grid>

                                        <Grid
                                          item
                                          xs={12}
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <div className="blog-area">
                                            <div className="info">
                                              <div
                                                className="content"
                                                style={{
                                                  padding: 10,
                                                  position: "static",
                                                }}
                                              >
                                                <a
                                                  class="btn btn-previous"
                                                  style={{
                                                    color: "#000",
                                                    textAlign: "center",
                                                    whiteSpace: "nowrap",
                                                    fontSize: 12,
                                                  }}
                                                >
                                                  <i
                                                    className="fa fa-arrow-left"
                                                    style={{ color: "#000" }}
                                                  ></i>{" "}
                                                  Previous
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="blog-area">
                                            <div className="info">
                                              <div
                                                className="content"
                                                style={{
                                                  padding: 10,
                                                  position: "static",
                                                }}
                                              >
                                                <a
                                                  class="btn btn-next"
                                                  style={{
                                                    color: "#000",
                                                    textAlign: "center",
                                                    whiteSpace: "nowrap",
                                                    fontSize: 12,
                                                  }}
                                                >
                                                  Next{" "}
                                                  <i
                                                    className="fa fa-arrow-right"
                                                    style={{ color: "#000" }}
                                                  ></i>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>

                                      {/* <button type="button" class="btn btn-next">Next</button> */}
                                    </div>
                                  </fieldset>

                                  <fieldset style={{ display: "none" }}>
                                    <div class="form-bottom">
                                      {/*Start Background Information */}

                                      <Grid item xs={12} sm={12}>
                                        <h4>Document Required</h4>
                                        <p style={{ color: "red" }}>
                                          (Mandatory Section)
                                        </p>
                                      </Grid>
                                      <br />
                                      {/* <form
                                          className={classes.form}
                                          noValidate
                                        > */}
                                      {/* <Grid container spacing={2}> */}
                                      {/* <Grid item xs={12} sm={12}>
                                              <p style={{ marginBottom: 0 }}>
                                                Have you refused a visa from
                                                Canada, the USA, the United
                                                Kingdom, New Zealand or
                                                Australia?
                                              </p>

                                              <Radio
                                                variant="outlined"
                                                style={{ color: "#3f8ccd" }}
                                                fullWidth
                                                name="yes"
                                                type="radio"
                                              />
                                              <label>Yes</label>
                                              <Radio
                                                variant="outlined"
                                                style={{ color: "#3f8ccd" }}
                                                fullWidth
                                                name="no"
                                                type="radio"
                                              />
                                              <label>No</label>
                                            </Grid>

                                            <Grid item xs={12} sm={12}>
                                              <p style={{ marginBottom: 0 }}>
                                                {" "}
                                                Do you have a valid Study Permit
                                                / Visa?
                                              </p>
                                              <input
                                                variant="outlined"
                                                type="text"
                                                value="I don't have this"
                                                data-role="tagsinput"
                                              />
                                            </Grid>

                                            <Grid item xs={12} sm={12}>
                                              <p>
                                                {" "}
                                                If you answered "Yes" to any of
                                                the following questions above
                                                provide more details below:
                                              </p>
                                              <TextField
                                                name="PD"
                                                rows={2}
                                                rowsMax={4}
                                                variant="outlined"
                                                fullWidth
                                                label="Provide details"
                                                type="text"
                                                autoFocus
                                              />
                                            </Grid> */}

                                      <form className={classes.form} noValidate>
                                        <Grid container spacing={2}>
                                          <Grid item xs={12} sm={12}>
                                            <FormGroup row>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={resume=="true"}
                                                    //onChange={handleChange}
                                                    onClick={() =>
                                                      handleResume()
                                                    }
                                                    onChange={(event) =>
                                                      setResume(!resume)
                                                    }
                                                    name="resume"
                                                    id="resume"
                                                    color="primary"
                                                    value={resume}
                                                  />
                                                }
                                                label="Resume / CV"
                                                autoFocus
                                              />

                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={etd=="true"}
                                                    //onChange={handleChange}
                                                    onChange={(event) =>
                                                      setETD(!etd)
                                                    }
                                                    onClick={() => handleETD()}
                                                    name="ETD"
                                                    color="primary"
                                                    id="etd"
                                                    value={etd}
                                                  />
                                                }
                                                label="Education Transcript & Degree"
                                                autoFocus
                                              />

                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={passport=="true"}
                                                    //onChange={handleChange}
                                                    onChange={(event) =>
                                                      setpassport(!passport)
                                                    }
                                                    onClick={() =>
                                                      handlePassport()
                                                    }
                                                    name="passport"
                                                    color="primary"
                                                    id="passport"
                                                    value={passport}
                                                  />
                                                }
                                                label="Passport"
                                                autoFocus
                                              />

                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={tsc=="true"}
                                                    //onChange={handleChange}
                                                    onChange={(event) =>
                                                      setTSC(!tsc)
                                                    }
                                                    onClick={() => handleTSC()}
                                                    name="TSC"
                                                    id="tsc"
                                                    color="primary"
                                                    value={tsc}
                                                  />
                                                }
                                                label="Test Score Card"
                                                autoFocus
                                              />

                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={sop=="true"}
                                                    //onChange={handleChange}
                                                    name="SOP"
                                                    color="primary"
                                                    id="sop"
                                                    onChange={(event) =>
                                                      setSOP(!sop)
                                                    }
                                                    onClick={() => handleSOP()}
                                                    value={sop}
                                                  />
                                                }
                                                label="SOP's"
                                                autoFocus
                                              />

                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={ielts=="true"}
                                                    //onChange={handleChange}
                                                    name="IELTS2"
                                                    color="primary"
                                                    id="ielts2"
                                                    onChange={(event) =>
                                                      setIELTS2(!ielts2)
                                                    }
                                                    onClick={() =>
                                                      handleIELTS2()
                                                    }
                                                    value={ielts2}
                                                    autoFocus
                                                  />
                                                }
                                                label="IELTS"
                                              />

                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={fd=="true"}
                                                    //onChange={handleChange}
                                                    name="FD"
                                                    color="primary"
                                                    id="fd"
                                                    onClick={() => handleFD()}
                                                    onChange={(event) =>
                                                      setFD(!fd)
                                                    }
                                                    value={fd}
                                                  />
                                                }
                                                label="Finance Documents"
                                              />

                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={ad=="true"}
                                                    //onChange={handleChange}
                                                    name="AD"
                                                    color="primary"
                                                    id="ad"
                                                    onChange={(event) =>
                                                      setAD(!ad)
                                                    }
                                                    onClick={() => handleAD()}
                                                    value={ad}
                                                  />
                                                }
                                                label="Additional Documents"
                                              />
                                              <br />

                                              <Grid
                                                container
                                                spacing={2}
                                                id="showResume"
                                                style={{
                                                  display: "none",
                                                  margin: 20,
                                                }}
                                              >
                                                <input
                                                  type="file"
                                                  id="contained-button-file"
                                                  multiple
                                                  name="resumedoc"
                                                  //onChange={this.onFileChange}
                                                  onChange={(event) =>
                                                    handleResumeDoc(event)
                                                  }
                                                />
                                                <label htmlFor="contained-button-file">
                                                  <button
                                                  //onClick={this.onFileUpload}
                                                  >
                                                    Resume!
                                                  </button>
                                                </label>
                                              </Grid>

                                              <Grid
                                                container
                                                spacing={2}
                                                id="showETD"
                                                style={{
                                                  display: "none",
                                                  margin: 20,
                                                }}
                                              >
                                                <input
                                                  type="file"
                                                  id="contained-button-file"
                                                  multiple
                                                  name="etddoc"
                                                  //onChange={this.onFileChange}
                                                  onChange={(event) =>
                                                    handleETDDoc(event)
                                                  }
                                                />
                                                <label htmlFor="contained-button-file">
                                                  <button
                                                  //onClick={this.onFileUpload}
                                                  >
                                                    Education Transcript &
                                                    Degree!
                                                  </button>
                                                </label>
                                              </Grid>

                                              <Grid
                                                container
                                                spacing={2}
                                                id="showPassport"
                                                style={{
                                                  display: "none",
                                                  margin: 20,
                                                }}
                                              >
                                                <input
                                                  type="file"
                                                  multiple
                                                  name="passportdoc"
                                                  //onChange={this.onFileChange}
                                                  onChange={(event) =>
                                                    handlePassportDoc(event)
                                                  }
                                                />
                                                <button
                                                //onClick={this.onFileUpload}
                                                >
                                                  Passport!
                                                </button>
                                              </Grid>

                                              <Grid
                                                container
                                                spacing={2}
                                                id="showTSC"
                                                style={{
                                                  display: "none",
                                                  margin: 20,
                                                }}
                                              >
                                                <input
                                                  multiple
                                                  type="file"
                                                  name="tscdoc"
                                                  //onChange={this.onFileChange}
                                                  onChange={(event) =>
                                                    handleTSCDoc(event)
                                                  }
                                                />
                                                <button
                                                //onClick={this.onFileUpload}
                                                >
                                                  Test Score Card!
                                                </button>
                                              </Grid>

                                              <Grid
                                                container
                                                spacing={2}
                                                id="showSOP"
                                                style={{
                                                  display: "none",
                                                  margin: 20,
                                                }}
                                              >
                                                <input
                                                  multiple
                                                  type="file"
                                                  name="sopdoc"
                                                  //onChange={this.onFileChange}
                                                  onChange={(event) =>
                                                    handleSOPDoc(event)
                                                  }
                                                />
                                                <button
                                                //onClick={this.onFileUpload}
                                                >
                                                  SOP!
                                                </button>
                                              </Grid>

                                              <Grid
                                                container
                                                spacing={2}
                                                id="showIELTS2"
                                                style={{
                                                  display: "none",
                                                  margin: 20,
                                                }}
                                              >
                                                <input
                                                  type="file"
                                                  multiple
                                                  name="ieltsdoc"
                                                  //onChange={this.onFileChange}
                                                  onChange={(event) =>
                                                    handleIELTSDoc(event)
                                                  }
                                                />
                                                <button
                                                //onClick={this.onFileUpload}
                                                >
                                                  IELTS!
                                                </button>
                                              </Grid>

                                              <Grid
                                                container
                                                spacing={2}
                                                id="showFD"
                                                style={{
                                                  display: "none",
                                                  margin: 20,
                                                }}
                                              >
                                                <input
                                                  type="file"
                                                  multiple
                                                  name="fddoc"
                                                  //onChange={this.onFileChange}
                                                  onChange={(event) =>
                                                    handleFDDoc(event)
                                                  }
                                                />
                                                <button
                                                //onClick={this.onFileUpload}
                                                >
                                                  Finance Document!
                                                </button>
                                              </Grid>

                                              <Grid
                                                container
                                                spacing={2}
                                                id="showAD"
                                                style={{
                                                  display: "none",
                                                  margin: 20,
                                                }}
                                              >
                                                <input
                                                  multiple
                                                  type="file"
                                                  name="addoc"
                                                  //onChange={this.onFileChange}
                                                  onChange={(event) =>
                                                    handleADDoc(event)
                                                  }
                                                />
                                                <button
                                                //onClick={this.onFileUpload}
                                                >
                                                  Additional Documents!
                                                </button>
                                              </Grid>

                                              <Grid item xs={12} sm={12}>
                                                <TextField
                                                  name="sgj"
                                                  variant="outlined"
                                                  fullWidth
                                                  label="Study Gap Justification"
                                                  onChange={(event) =>
                                                    setStudyGapJust(
                                                      event.target.value
                                                    )
                                                  }
                                                  value={studygapjust}
                                                  type="text"
                                                  autoFocus
                                                />
                                              </Grid>
                                            </FormGroup>
                                          </Grid>

                                          <Grid
                                            item
                                            xs={12}
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <div className="blog-area">
                                              <div className="info">
                                                <div
                                                  className="content"
                                                  style={{
                                                    padding: 10,
                                                    position: "static",
                                                  }}
                                                >
                                                  <a
                                                    class="btn btn-previous"
                                                    style={{
                                                      color: "#000",
                                                      textAlign: "center",
                                                      whiteSpace: "nowrap",
                                                      fontSize: 12,
                                                    }}
                                                  >
                                                    <i
                                                      className="fa fa-arrow-left"
                                                      style={{
                                                        color: "#000",
                                                      }}
                                                    ></i>{" "}
                                                    Previous
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="blog-area">
                                              <div className="info">
                                                <div
                                                  className="content"
                                                  style={{
                                                    padding: 10,
                                                    position: "static",
                                                  }}
                                                >
                                                  <a
                                                    href="#"
                                                    class="btn"
                                                    // onClick={handleClick({
                                                    //   vertical: "bottom",
                                                    //   horizontal: "center",
                                                    // })}
                                                    style={{
                                                      color: "#000",
                                                      textAlign: "center",
                                                      whiteSpace: "nowrap",
                                                      fontSize: 12,
                                                    }}
                                                    onClick={() =>
                                                      handleSubmit()
                                                    }
                                                  >
                                                    {" "}
                                                    Submit{" "}
                                                    <i
                                                      className="fa fa-arrow-right"
                                                      style={{
                                                        color: "#000",
                                                      }}
                                                    ></i>
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                            <br />
                                            <br />
                                            <br />

                                            {/* <Snackbar
                                              open={open}
                                              autoHideDuration={3000}
                                              anchorOrigin={{
                                                vertical,
                                                horizontal,
                                              }}
                                              key={vertical + horizontal}
                                              onClose={handleClose}
                                            >
                                              <Alert
                                                onClose={handleClose}
                                                severity="success"
                                              >
                                                This is a success Submit!
                                              </Alert>
                                            </Snackbar> */}
                                          </Grid>
                                          <Grid item xs={12} sm={12}>
                                            <Typography>
                                              <center>{messages}</center>
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </form>
                                      {/* End Background Information */}

                                      {/* <button type="button" class="btn btn-previous">Previous</button>
                            <button type="submit" class="btn">Submit</button> */}
                                    </div>
                                  </fieldset>
                                </div>
                              </div>

                              {/* <form className={classes.form} noValidate>

          <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
          <TextField name="firstName" variant="outlined"  fullWidth label="First Name" type="text" autoFocus />
          </Grid>
          <Grid item xs={12} sm={4}>
          <TextField name="mname" variant="outlined" fullWidth label="Middle Name" type="text" autoFocus />
          </Grid>
          <Grid item xs={12} sm={4}>
          <TextField name="lname" variant="outlined"  fullWidth label="Last Name" type="text" autoFocus />
          </Grid>

       <Grid item xs={12} sm={4}>
      <MuiPickersUtilsProvider style={{width:'100%'}} fullWidth utils={DateFnsUtils}>
        <KeyboardDatePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          fullWidth
          InputAdornmentProps={{ position: "end" }}
          style={{width:'100%'}}
          id="date-picker-dialog"
          label="Date of Birth"
           format="dd/MM/yyyy"
           value={selectedDate}
           onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
    </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
          <TextField name="flanguage" variant="outlined"  fullWidth label="First Language" type="text" autoFocus />
          </Grid>
          <Grid item xs={12} sm={4}>


            
        <FormControl  fullWidth>
         <InputLabel >Country of Citizenship</InputLabel>
        <Select >
          <MenuItem  value={10}>India</MenuItem>
          <MenuItem  value={20}>USA</MenuItem>
          <MenuItem  value={30}>Canada</MenuItem>
        </Select>
      </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
          <TextField name="pnumber" variant="outlined"  fullWidth label="Passport Number" type="text" autoFocus />
          </Grid>
          <Grid item xs={12} sm={4}>
         <h6 style={{marginBottom:0,}}>Gender</h6>
          <label>Male</label>
          <Radio variant="outlined"  style={{color:'#3f8ccd'}}  fullWidth name="male" type="radio"/>

         <label>Female</label>
         <Radio variant="outlined"   style={{color:'#3f8ccd'}}fullWidth name="female" type="radio"/>

          </Grid>

          <Grid item xs={12} sm={4}>
          <h6 style={{marginBottom:0,}}>Marital Status</h6>
          <label>Singal</label>
          <Radio variant="outlined" style={{color:'#3f8ccd'}}  fullWidth name="Singal" type="radio"/>

         <label>Married</label>
         <Radio variant="outlined"   style={{color:'#3f8ccd'}}fullWidth name="Married" type="radio"/>
          </Grid>

<Grid item xs={12}>
 <MuiPhoneInput defaultCountry='in' regions={'asia'} variant="outlined" className={classes.formControl}label="Phone"/>
</Grid>
           
          
    

          </Grid>





        <Typography>
        {messages}
        </Typography>
 
        <br/>
         


        </form> */}
                            </div>

                            {/* Address Detail */}
                            {/* <hr/>
<div className={classes.paper}>
              <h4>Address Detail</h4>
        <form className={classes.form} noValidate>

          <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
          <TextField name="address" variant="outlined"  fullWidth label="Enter Address" type="text" autoFocus />
          </Grid>
          <Grid item xs={12} sm={4}>
          <TextField name="citytown" variant="outlined" fullWidth label="Enter City/Town" type="text" autoFocus />
          </Grid>
         

          
          <Grid item xs={12} sm={4}>
          <TextField name="flanguage" variant="outlined"  fullWidth label="First Language" type="text" autoFocus />
          </Grid>
          <Grid item xs={12} sm={4}>
        <FormControl  fullWidth>
         <InputLabel >Select Country</InputLabel>
        <Select>
          <MenuItem  value={10}>India</MenuItem>
          <MenuItem  value={20}>USA</MenuItem>
          <MenuItem  value={30}>Canada</MenuItem>
        </Select>
      </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
          <TextField name="pstate" variant="outlined"  fullWidth label="Enter Province/State"  type="text" autoFocus />
          </Grid>

          <Grid item xs={12} sm={4}>
          <TextField name="pzc" variant="outlined"  fullWidth label="Enter Postal/Zip Code" type="text" autoFocus />
          </Grid>

          <Grid item xs={12} sm={4}>
          <TextField name="email" variant="outlined"  fullWidth label="Enter Email"  type="text" autoFocus />
          </Grid>

          <Grid item xs={12} sm={4}>
          <MuiPhoneInput defaultCountry='in' regions={'asia'} variant="outlined" className={classes.formControl}label="Phone Number"/>
          </Grid>

          </Grid>
          </form>
          </div> */}

                            {/* End address details */}

                            {/*Start Education Summary */}
                            {/* <hr/>
<div className={classes.paper}>
              <h4>Education Summary</h4>
        <form className={classes.form} noValidate>

<Grid container spacing={2}>

<Grid item xs={12} sm={4}>
<FormControl  fullWidth>
<InputLabel >Country of Education</InputLabel>
<Select>
<MenuItem  value={10}>India</MenuItem>
<MenuItem  value={20}>USA</MenuItem>
<MenuItem  value={30}>Canada</MenuItem>
</Select>
</FormControl>
</Grid>

<Grid item xs={12} sm={4}>
<FormControl  fullWidth>
<InputLabel >Highest Level of Education</InputLabel>
<Select>
<MenuItem  value={10}>PhD</MenuItem>
<MenuItem  value={20}>MCA</MenuItem>
<MenuItem  value={30}>MSC</MenuItem>
</Select>
</FormControl>
</Grid>
         

<Grid item xs={12} sm={4}>
<FormControl  fullWidth>
<InputLabel >Grading Scheme</InputLabel>
<Select>
<MenuItem  value={10}>Scheme One</MenuItem>
<MenuItem  value={20}>Scheme Two</MenuItem>
<MenuItem  value={30}>Scheme Three</MenuItem>
</Select>
</FormControl>
 </Grid>

          <Grid item xs={12} sm={4}>
        <FormControl  fullWidth>
         <InputLabel >Grade Scale (out of)</InputLabel>
        <Select>
          <MenuItem  value={10}>India</MenuItem>
          <MenuItem  value={20}>USA</MenuItem>
          <MenuItem  value={30}>Canada</MenuItem>
        </Select>
      </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
          <TextField name="ga"   fullWidth label="Enter Grade Average"  type="text" autoFocus />
          </Grid>

          <Grid item xs={12} sm={4}>
          
         <Checkbox variant="outlined"   style={{color:'#3f8ccd'}}fullWidth name="Married" type="checkbox"/>
         <label>Graduated from most recent school</label>
          </Grid>

         

          </Grid>
          </form>
          </div> */}

                            {/* End Education Summary */}

                            {/*Start School Attended */}
                            {/* <hr/>
<div className={classes.paper}>
              <h4>School Attended</h4>
        <form className={classes.form} noValidate>

          <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <FormControl  fullWidth>
         <InputLabel >Select Level of Education</InputLabel>
        <Select>
          <MenuItem  value={10}>Level One</MenuItem>
          <MenuItem  value={20}>Level Two</MenuItem>
          <MenuItem  value={30}>Level Three</MenuItem>
        </Select>
      </FormControl>
          </Grid>
          
        <Grid item xs={12} sm={4}>
	          <FormControl  fullWidth>
	           <InputLabel >Select Country of Institution</InputLabel>
	          <Select>
	            <MenuItem  value={10}>India</MenuItem>
	            <MenuItem  value={20}>USA</MenuItem>
	            <MenuItem  value={30}>Canada</MenuItem>
	          </Select>
	        </FormControl>
          </Grid>
          
        <Grid item xs={12} sm={4}>
	          <FormControl  fullWidth>
	           <InputLabel >Enter Name Institution</InputLabel>
	          <Select>
	            <MenuItem  value={10}>Institution One</MenuItem>
	            <MenuItem  value={20}>Institution Two</MenuItem>
	            <MenuItem  value={30}>Institution Three</MenuItem>
	          </Select>
	        </FormControl>
          </Grid>
          
          <Grid item xs={12} sm={4}>
          <TextField name="citytown" variant="outlined" fullWidth label="Enter Primary Language of Instruction" type="text" autoFocus />
          </Grid>
         

          
          <Grid item xs={12} sm={4}>
          <MuiPickersUtilsProvider style={{width:'100%'}} fullWidth utils={DateFnsUtils}>
        <KeyboardDatePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          fullWidth
         
          InputAdornmentProps={{ position: "end" }}
          style={{width:'100%'}}
          id="date-picker-dialog"
          label="Attended Institution From"
           format="dd/MM/yyyy"
           value={selectedDate}
           onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
    </MuiPickersUtilsProvider>
          </Grid>
         

          <Grid item xs={12} sm={4}>
          <MuiPickersUtilsProvider style={{width:'100%'}} fullWidth utils={DateFnsUtils}>
        <KeyboardDatePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          fullWidth
         
          InputAdornmentProps={{ position: "end" }}
          style={{width:'100%'}}
          id="date-picker-dialog"
          label="Attended Institution To"
           format="dd/MM/yyyy"
           value={selectedDate}
           onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
    </MuiPickersUtilsProvider>
            </Grid>

          <Grid item xs={12} sm={4}>
          <TextField name="DA" variant="outlined"  fullWidth label="Enter Degree Awarded" type="text" autoFocus />
          </Grid>

          <Grid item xs={12} sm={4}>
          <TextField name="DAO" variant="outlined"  fullWidth label="Enter Degree Awarded On"  type="text" autoFocus />
          </Grid>

          </Grid>
          </form>
          </div> */}

                            {/* End School Attended */}

                            {/*Start School Address */}
                            {/* <hr/>
<div className={classes.paper}>
              <h4>School Address</h4>
        <form className={classes.form} noValidate>

          <Grid container spacing={2}>

          <Grid item xs={12} sm={4}>
          <TextField name="SA" variant="outlined" fullWidth label="Enter Address" type="text" autoFocus />
          </Grid>
         

          
          <Grid item xs={12} sm={4}>
          <TextField name="CT" variant="outlined"  fullWidth label="Enter City/Town" type="text" autoFocus />
          </Grid>
         

          <Grid item xs={12} sm={4}>
          <TextField name="EP" variant="outlined"  fullWidth label="Enter Province"  type="text" autoFocus />
          </Grid>

          <Grid item xs={12} sm={8}>
          <TextField name="PZC" variant="outlined"  fullWidth label="Enter Postal/Zip Code" type="text" autoFocus />
          </Grid>

          <Grid item xs={12} sm={2}>
          <div className="blog-area">
<div className="info">
<div className="content" style={{padding:0,position:'static'}}>
<a href="#" style={{color:'#000',textAlign:'center'}}><i className="fa fa-remove" style={{color:'#000'}}></i> Cancel</a>
</div>
</div>
</div> 
          </Grid>

          <Grid item xs={12} sm={2}>
          <div className="blog-area">
<div className="info">
<div className="content" style={{padding:0,position:'static'}}>
<a href="#" style={{color:'#000',textAlign:'center',}}><i className="fa fa-save" style={{color:'#000'}}></i> Save</a>
</div>
</div>
</div> 
          </Grid>

          </Grid>
          </form>
          </div> */}

                            {/* End School Address */}

                            {/*Start Test Score */}
                            {/* <hr/>
<div className={classes.paper}>
              <h4>Test Scores</h4>
              <p>English Exam</p>
              <p>Type</p>
        <form className={classes.form} noValidate>
        
          <Grid container spacing={2}>
     
       
          
          <Grid item xs={12} sm={12}>
          <FormControl  fullWidth>
	           <InputLabel >I don't have this</InputLabel>
	          <Select>
	            <MenuItem  value={10}>Institution One</MenuItem>
	            <MenuItem  value={20}>Institution Two</MenuItem>
	            <MenuItem  value={30}>Institution Three</MenuItem>
	          </Select>
	        </FormControl>
          </Grid>
         

        


          </Grid>
          </form>
          </div> */}

                            {/* End Test Score */}

                            {/*Start Additional Qualification */}
                            {/* <hr/>
<div className={classes.paper}>
              <h4>Additional Qualifications</h4>
              
        <form className={classes.form} noValidate>
        
          <Grid container spacing={2}>
     
       
          
          <Grid item xs={12} sm={12}>
            <label>I have GRE Exam scores</label>
          <Switch
          color="primary"
        name="checkedA"
        style={{color:'#3f8ccd'}}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
          </Grid>
          <Grid item xs={12} sm={12}>
          <label>I have GMAT Exam scores</label>
          <Switch
          color="primary"
        name="checkedB"
        style={{color:'#3f8ccd'}}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
          </Grid>

        


          </Grid>
          </form>
          </div> */}

                            {/* End Additional Qualification */}

                            {/*Start Background Information */}
                            {/* <hr/>
<div className={classes.paper}>
              <h4>Background Information</h4>
              
        <form className={classes.form} noValidate>
        
          <Grid container spacing={2}>
     

          <Grid item xs={12} sm={12}>
          <p style={{marginBottom:0,}}>Have you refused a visa from Canada, the USA, the United Kingdom, New Zealand or Australia?</p>
            
       <Radio variant="outlined"  style={{color:'#3f8ccd'}}  fullWidth name="yes" type="radio"/>
      <label>Yes</label>
      <Radio variant="outlined"  style={{color:'#3f8ccd'}}  fullWidth name="no" type="radio"/>
      <label>No</label>
          </Grid>
         
          <Grid item xs={12} sm={12}>
      <p style={{marginBottom:0,}}> Do you have a valid Study Permit / Visa?</p>
      <input variant="outlined"  type="text" value="I don't have this" data-role="tagsinput" />
    
          </Grid>


          <Grid item xs={12} sm={12}>
      <p> If you answered "Yes" to any of the following questions above provide more details below:</p>
      <TextField name="PD" multiline rows={2} rowsMax={4} variant="outlined"  fullWidth label="Provide details"  type="text" autoFocus />
          </Grid>



          </Grid>

 <div className="blog-area">
<div className="info">
<div className="content" style={{padding:10,position:'static'}}>
<a  href="/" style={{color:'#000',width:'100%',textAlign:'center',}}>Next <i className="fa fa-arrow-right" style={{color:'#000'}}></i></a>
</div>
</div>
</div> 
          </form>
          </div> */}

                            {/* End Background Information */}
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
