/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import { Text, StyleSheet } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { MDBContainer, MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,  MDBSideNav, MDBNavItem } from "mdbreact";

//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";

import color from "@material-ui/core/colors/amber";
import Header from "./Header";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [messages, setMessages] = React.useState("");
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus, setStatus] = React.useState("");
  const [showData,setShowData]=React.useState('USA')

  const handlePicture = (event) => {
    setPicturePath(URL.createObjectURL(event.target.files[0]));
    setPicture(event.target.files[0]);
  };

  const handleVerifyPassword = (e) => {
    setVerifyPassword(e.target.value);
    if (password === e.target.value) {
      setStatus("Password Matched");
    } else {
      setStatus("Password not matched");
    }
  };

  //   const addNewRecord=async()=>{
  // if(picture=='')
  // {
  //     var body={'firstName':firstName,
  //     'lastName':lastName,
  //     'emailId':emailId,
  //     'password':password,
  //     'status':"Not Approved",
  //     'picture':"Not Found"}
  //     var result=await postData('selfregister/addNewRecord',body)
  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}

  // }

  // else{
  //    var formData=new FormData()
  //    formData.append('firstName',firstName)
  //    formData.append('lastName',lastName)
  //    formData.append('emailId',emailId)
  //    formData.append('password',password)
  //    formData.append('picture',picture)
  //    formData.append('status', "Not Approve")

  //    var config={headers:{'content-type':'multipart/form-data'}}
  //     var result=await postDataAndImage('selfregister/addNewRecordPic',formData,config)

  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}
  //     }
  //     }

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />
      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 70,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">Visa</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="about-area default-padding">
                <div className="container">
                  <div className="row">
                    <div className="about-items">
                      <div className="col-md-8 about-info">
                        <p
                          style={{
                            fontSize: 14,
                            color: "#212121",
                            textAlign: "justify",
                          }}
                        >
                          As soon as you get your Admission Letter from the
                          preferred university and organize your capital to
                          study overseas, you may apply for your student
                          visa.Finding a student loan is rather challenging as a
                          great deal of instruction is included in the
                          procedure. Moreover, every nation has a diverse set of
                          requisites therefore that it's crucial to be well
                          familiar with the visa needs of the nation that you
                          would like to examine in.An in-depth comprehension of
                          the procedure in applying for your student visa is
                          able to help you plan ahead and be rest assured of
                          finishing the procedure with precision. This is where
                          we can assist you, we function as top study abroad
                          consultants in India.
                        </p>
                      </div>
                      <div className="col-md-4 features text-light">
                        <img
                          style={{ flex: 1, width: 250, height: 250 }}
                          class="login_image"
                          src="assets/img/visa.png"
                          alt="Login"
                          data-original-title=""
                          title=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="blog-area single full-blog left-sidebar full-blog default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="container"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <div className="row">
                    <div className="blog-items">
                        <div class="sidebar col-md-3">
                        <aside >
                          <div class="sidebar-item category"  style={{position:'sticky'}}>
                            <div class="title">
                              <h4>Country list</h4>
                            </div>
                            <div class="sidebar-info" >
                              <ul>
                                <li>
                                  <a onClick={()=>setShowData('USA')} style={{color: "#3f8ccb"}}><i class="flag-icon flag-icon-us"></i>&nbsp;&nbsp;USA</a>
                                </li>
                                <li>
                                  <a onClick={()=>setShowData('CANADA')} ><i class="flag-icon flag-icon-ca"></i>&nbsp;&nbsp;Canada </a>
                                </li>
                                <li>
                                  <a onClick={()=>setShowData('UK')}><i class="flag-icon flag-icon-gb"></i>&nbsp;&nbsp;UK </a>
                                </li>
                                <li>
                                  <a onClick={()=>setShowData('AUSTRALIA')}><i class="flag-icon flag-icon-au"></i>&nbsp;&nbsp;Australia </a>
                                </li>
                                <li>
                                  <a onClick={()=>setShowData('FRANCE')}><i class="flag-icon flag-icon-fr"></i>&nbsp;&nbsp;France </a>
                                </li>
                                <li>
                                  <a onClick={()=>setShowData('IRELAND')}><i class="flag-icon flag-icon-ie"></i>&nbsp;&nbsp;Ireland </a>
                                </li>
                                <li>
                                  <a onClick={()=>setShowData('GERMANY')}><i class="flag-icon flag-icon-de"></i>&nbsp;&nbsp;Germany </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </aside>
                      </div>
                      
                      <div className="blog-content col-md-9">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    {/* <p style={{ color: "#212121" }}>
                                      As soon as you get your Admission Letter
                                      from the preferred university and organize
                                      your capital to study overseas, you may
                                      apply for your student visa.Finding a
                                      student loan is rather challenging as a
                                      great deal of instruction is included in
                                      the procedure. Moreover, every nation has
                                      a diverse set of requisites therefore that
                                      it's crucial to be well familiar with the
                                      visa needs of the nation that you would
                                      like to examine in.An in-depth
                                      comprehension of the procedure in applying
                                      for your student visa is able to help you
                                      plan ahead and be rest assured of
                                      finishing the procedure with precision.
                                      This is where we can assist you, we
                                      function as top study abroad consultants
                                      in India.
                                    </p> */}
                                    {showData=='USA'?(
                                    <div id="usa">
                                      <h4>USA:</h4>
                                      <p style={{ color: "#212121" }}>
                                      <p style={{ color: "#212121", fontWeight: 'bold' }}>USA Study Visa:</p> The USA is still the
                                        best choice for students intending to
                                        study overseas. If you're one of the few
                                        who hope and have got entrance into your
                                        aspired college, using for Student Visa
                                        is the upcoming important step to
                                        understanding your Research in USA
                                        fantasy. This pupil's Visa Guide
                                        assesses briefly the several forms of
                                        visa and efforts to provide a thorough
                                        procedure of implementing for the F1
                                        Student Visa - related to students
                                        intending to take their bachelor's and
                                        master's at USA. Aside from that,
                                        candidates must also understand the
                                        reasons why their US Student Visa has
                                        refused?
                                      </p>
                                      <p style={{ color: "#212121", fontWeight: 'bold' }}>
                                        To apply for a US visa in India, You
                                        Need to follow these measures:
                                      </p>

                                      <p style={{ color: "#212121" }}>
                                        <ul>
                                          <li type='square'>Ascertain which kind of US visa you
                                        want. </li> 
                                        
                                        <li type='square'>Program a visa appointment. </li>
                                        <li type='square'>Gather the required files. </li>
                                        <li type='square'>Submit an program in the US Visa
                                        Application Centre.</li>
                                        </ul>

                                        <p style={{ color: "#212121", fontWeight: 'bold' }}>F1 Student Visa</p>
                                        Pupils applying for an application that
                                        needs over 18 hours of research per week
                                        demand a F1 visa. Including all
                                        undergraduate applications in addition
                                        to grad applications like MS, MBA, etc..
                                        Spouses or children accompanying F-1
                                        visa receivers will travel in an F-2
                                        visa. Please be aware that partners
                                        aren't able to operate but might follow
                                        and/or employ to their visa into the
                                        U.S. to study or work. By way of
                                        instance, Fulbright scholars and lots of
                                        pupils on short term study abroad
                                        programs from Indian colleges may go to
                                        the U.S. on a J-1 visa. <br />
                                        J1 visa is
                                        generally searched with a working pro
                                        who belongs to America within an
                                        exchange program, thus the title
                                        Exchange Visitor Visa. These can include
                                        some 10-month vocational training or any
                                        study fellowship, etc.. Whichever is
                                        true, the applicants will be advised for
                                        the same by the various associations.
                                        Please be aware that partners have the
                                        ability to work when consent is obtained
                                        beforehand. M-1 Vocational/ Non-Academic
                                        Student Visa. <br />
                                        The M1 visa is a form of
                                        student visa, and this can be earmarked
                                        for technical and vocational schools.
                                        While the procedure of implementing to
                                        the F1 visa and M-1 is comparable, the
                                        difference is that on enteringthe M1
                                        visas are time-stamped and pupils can't
                                        overstay their trip. Additionally, read
                                        about student visas for study abroad and
                                        student visa for notable global research
                                        destinations:
                                        <br /><br />
                                        <p style={{ color: "#212121", fontWeight: 'bold' }}>A obtained I-20 could be</p>
                                        <ul>
                                        <li type='square'>A scanned version of a poorly signed
                                        kind I-20, or </li> 
                                        <li type='square'>A digitally signed
                                        type I-20 that comprises a digitally
                                        duplicated copy using a physical touch.
                                        </li> 
                                        <li type='square'>A digitally signed type I-20
                                        utilizing digital signature program
                                        students may cover their I-901 fees, to
                                        ensure that if the embassies reopen,
                                        they will find the visa appointment at
                                        the oldest.</li>
                                        </ul>
                                        <br /> 
                                        US Faculties goes test-optional
                                        as SAT/ ACT get canceled as a result of
                                        coronavirus COVID-19 Stranded overseas
                                        students in US can use for off-campus
                                        work license The Way Coronavirus
                                        impacted Harvard? <br /><br />


                                        <p style={{ color: "#212121", fontWeight: 'bold' }}>
                                        Working on USA Student Visa </p>
                                        Throughout the first year whilst
                                        studying, students can't accept
                                        off-campus employment. They aren't
                                        permitted to operate over 20 hours each
                                        week. During breaks and vacations, they
                                        could work around 40 hours each week.
                                        The student can do the job for a
                                        commercial company that provides
                                        solutions to the school, like a
                                        bookstore or cafeteria. <br />
                                        <br />
                                        SEVIS fee that's at current at $350 (INR
                                        26,256)
                                        <br />
                                        Visa Application Fee (MRV Fee), that is
                                        currently at $160 (INR 12,003) <br />
                                        <br />
                                        This is the breakdown of different forms
                                        of Student Visas <br /><br />
                                      
                                        <p style={{ color: "#212121", fontWeight: 'bold' }}>Documents necessary for F1 Student Visa
                                        Program </p>
                                        USA Research Visa Processes: Student
                                        visa requires one to take a good deal
                                        more files than you would to get a
                                        tourist visa, in contrast. You will need
                                        the mandatory documents together with
                                        supporting files of academic and
                                        financial documents. Be certain that you
                                        refer to the checklist prior to visiting
                                        the consulate for your visa interview.{" "}
                                        <br />
                                        <li type='square'>A printed copy of DS-160, the online
                                        application type  </li>
                                        <li type='square'>Interview appointment correspondence
                                        (original and duplicate ) </li>
                                        <li type='square'>Form I-20 delivered by the faculty (in
                                        which you're likely to research ) </li>
                                        <li type='square'>Visa fees payment verification receipt </li>
                                        <li type='square'>Bank announcement for three or more
                                        years demonstrating that you have
                                        sufficient resources to cover the
                                        initial year (may be of parent or
                                        guardian) </li>
                                        <li type='square'>Pay/salary slips </li>
                                        <li type='square'>Initial mark sheets/provisional
                                        certificates  </li>
                                        <li type='square'>Score sheet of exams including TOEFL,
                                        GMAT, IELTS, etc.. </li>
                                        <br /><br />


                                        <p style={{ color: "#212121", fontWeight: 'bold' }}>
                                        Supporting Financial Documents necessary
                                        for F1 Visa </p>
                                        For the USA, pupils will need to
                                        demonstrate evidence of the number of
                                        funds they should pay for your initial
                                        year's total costs. It includes tuition
                                        fees, living costs, and other expenses
                                        (such as books). Aside from that,
                                        students should also show evidence of
                                        this easily available funds for the
                                        remaining portion of the tenure of the
                                        schooling. <br />
                                        <li type='square'>Proof of Financial Resources necessary
                                        for F1 Visa  </li>
                                        <li type='square'>Tax returns for the past 3 years (Form
                                        16) </li>
                                        <li type='square'>Bank statements/passbook for the past 3
                                        years </li>
                                        <li type='square'>Initial pay/salary slips and letters of
                                        job </li>
                                        <li type='square'>Record from accredited CA </li>
                                        <li type='square'>Scholarship correspondence (If the
                                        candidate has obtained it) </li>
                                        <li type='square'>The loan approval letter from the lender
                                        or the concerned jurisdiction </li><br />


                                        <p style={{ color: "#212121", fontWeight: 'bold' }}>How to Employ US Student Visa (F1 Visa){" "}</p>
                                        
                                        There Are Numerous Actions to apply for
                                        a USA Study Visa:
                                        <br /> <br />
                                        Your visa procedure begins when you Get
                                        a Form I-20 out of the own college.
                                        Although you are able to pay the SEVIS
                                        fee anytime throughout the program, it's
                                        strongly suggested that you pay the
                                        SEVIS I-901 charge before you begin your
                                        US visa program. <br />
                                        After paying the SEVIS fee and receiving
                                        a receipt, you may make an application
                                        to get a DS-160 visa kind. It's an
                                        online application form. <br />
                                        Printing the application form
                                        verification page to bring to your
                                        interview. <br />
                                        Pay the visa fee through NEFT or in
                                        approved AXIS bank/Citi bank places.{" "}
                                        <br />
                                        For Your Biometric appointment, you'll
                                        be asked to appear in person to receive
                                        your photograph clicked to your visa and
                                        receive your fingerprints scanned.{" "}
                                        <br />
                                        In the Personal interview, the
                                        interviewer will ask you questions
                                        regarding your choice, clearly, faculty,
                                        financing, and aim of coming back. If is
                                        convinced, they'll continue to keep your
                                        passport together to get the visa
                                        stamped.
                                        <br />
                                        Recall, F1 student visas could be issued
                                        up to 120 days prior to the beginning
                                        date of your course of research. But you
                                        won't be permitted to enter the USA on
                                        F1 status sooner than 30 days before
                                        your start date. <br />
                                        <br />

                                        <p style={{ color: "#212121", fontWeight: 'bold' }}>Dependents </p>
                                        Spouses and children under the age of 21
                                        who want to accompany the applicant at
                                        the united states for the length of
                                        their stay need F2 or M2 visas. A
                                        partner on F2 standing cannot register
                                        for a complete course of study but can
                                        attend courses which are recreational or
                                        vocational in character like following a
                                        hobby or interest, like tennis or
                                        cooking. Kids on F2 standing are
                                        permitted to attend elementary, middle,
                                        or higher school as a fulltime pupil.
                                        They may not enrol in a fulltime plan of
                                        study in a school or university.
                                        <br />
                                        <br />
                                        The spouse and children of pupils can't
                                        take employment at any time throughout
                                        their stay-period on the F2 Visa. This
                                        means that you may get the job done for
                                        a year once you complete your research.
                                        It's temporary employment
                                        permission-giving pupils the chance to
                                        acquire practical knowledge in their
                                        area of research. Following that, you'll
                                        be asked to submit an application for a
                                        work visa in case you need to keep on
                                        working in the united states. You are
                                        able to stay in the US around 60 days
                                        following the conclusion of your path,
                                        even in the event that you don't have a
                                        job offer or have not applied for OPT.
                                        <br />
                                        <br />


                                        <p style={{ color: "#212121", fontWeight: 'bold' }}>Program a US Visa Appointment at India
                                        </p>
                                        To program an US visa appointment in
                                        India, you need to make an account to
                                        the US's internet visa application
                                        service here. You Need to schedule two
                                        Distinct appointments: <br />
                                        <br />
                                        One in the Visa Program Center <br />
                                        One using all the US Embassy or
                                        Consulate. The appointment in the
                                        Embassy/Consulate must be one day
                                        following the VAC appointment. <br />
                                        To schedule your own appointments,
                                        You'll Need the following: 
                                        <br />
                                      <li type='square'> Your passport amount</li>
                                        <li type='square'>The visa application charge receipt
                                        number. </li>
                                      <li type='square'> The ten-digit barcode number from the
                                        DS-160 verification page</li>
                                      <li type='square'> Submit an Program in the US Visa
                                        Application Centre in India</li>
                                      <li type='square'> After you schedule your appointment to
                                        get US visa program in India, then you
                                        need to pick the location in which you
                                        need to employ (i.e. the Visa
                                        Application Centre).</li>
                                      <li type='square'> When you arrive in the Visa Application
                                        Centre about the date of your
                                        appointment, then the team will take
                                        your fingerprints and picture.</li>
                                      <li type='square'> Your passport, which has to be valid for
                                        at least a six months from the date you
                                        wish to leave the United States. </li>
                                      <li type='square'> Your DS-160 verification page.</li>
                                        <li type='square'>Your appointment verification page.</li>
                                      <li type='square'> One passport-size picture </li>
                                      <li type='square'> Gather the mandatory US visa records for
                                        Indians</li>
                                      <li type='square'> Your passport, which needs to be valid
                                        for at least a six weeks in the moment
                                        you wish to depart the United States.</li>
                                      <li type='square'> Past passports that have older US visas
                                        in them. </li>
                                        <li type='square'>2 passport-size images, which fulfill
                                        the US visa photograph demands.</li>
                                        <br />
                                        Social networking details:- A current
                                        need for US visa program is a summary of
                                        those societal websites which you use,
                                        and also the title of your accounts in
                                        each, in addition to your contact
                                        number, email and societal networking
                                        history in the five preceding years.
                                        <br />
                                        Traveling itinerary:- It must show
                                        exactly what you plan to do when you're
                                        in the united states. Proof of lodging,
                                        based on where you're staying. Hotel
                                        reservation, letter of invitation by a
                                        friend/relative etc.. <br />
                                        <br />
                                        Any other files dependent on the US visa
                                        that you would like to renew. For
                                        instance: Sponsorship documents (in case
                                        you've got a host ).
                                        <br />
                                        <br />
                                        <p style={{ color: "#212121", fontWeight: 'bold' }}>For US Work Visa:</p>
                                        Any other files
                                        showing your credentials in addition to
                                        a job offer/contract. For US Student
                                        Visa: Proof you've been admitted in the
                                        US educational establishment. The U.S.
                                        Embassy at New Delhi. The U.S. Consulate General at Hyderabad{" "}
                                        <br />
                                        <br />
                                        Keep in mind the US Embassies and
                                        Consulates in India don't permit you to
                                        bring electronic equipment, telephones,
                                        food, or massive bags indoors, therefore
                                        make arrangements to depart them
                                        everywhere until you arrive.
                                        <br />
                                        <br />

                                        <p style={{ color: "#212121", fontWeight: 'bold' }}>USA Visa Requirements</p>
                                        
                                        Visa Interview:  <br />
                                        
                                        American Consulate has introduced a
                                        method of previous appointment for Visa
                                        Interview. On the specified date, the
                                        applicant must look for a personal
                                        meeting. The applicant must convince the
                                        visa officer that he / she has the
                                        required capital to pay the expense of
                                        instruction. The applicant must show
                                        that he / she has strong ties with India
                                        and that he / she isn't an intending
                                        immigrant. While answering the
                                        questions, an individual ought to be
                                        short and to the point. <br />
                                        <br />
                                        An individual shouldn't be surprised or
                                        upset if the officer can't review all of
                                        the files which are carried from the
                                        applicant. Since officers have hardly
                                        any time for every candidate, they might
                                        not have the ability to check at several
                                        or any records occasionally. Therefore
                                        one ought to be ready to clarify one's
                                        scenario orally and to answer all
                                        queries immediately, patiently and
                                        confidently. <br />
                                        <br />
                                        The applicant will be advised if he or
                                        she was granted or denied a visa on
                                        precisely the exact same day. The title
                                        of the University whose I-20 was
                                        submitted to the consulate, are also
                                        mentioned on the passport. Thus it's
                                        highly advisable to create the final
                                        collection of the University really
                                        attentively. If, for any reason, your
                                        visa has been rejected from the first
                                        effort, you are able to reapply after 3
                                        working days.
                                        <br />
                                        <br />
                                        <p style={{ color: "#212121", fontWeight: 'bold' }}>Document Checklist</p>
                                        Listed below are a list of a Few of the
                                        files that You're supposed to carry with
                                        you about the afternoon of VISA
                                        interview: <br />
                                        <br />
                                        <li type='square'>Passport having legitimacy of six months{" "}
                                        </li>
                                        <li type='square'>Visa Fee Receipt Interview </li>
                                        <li type='square'>Appointment Letter</li>
                                        <li type='square'>I-20 and Entry Letter by the University
                                        / College mentioning the Entire cost of
                                        instruction in USA (If You've obtained
                                        Entry letters / Rejection letters from
                                        over one University, take All these )</li>
                                        <li type='square'>Proof of Scholarship / Support / Grant,
                                        if some </li>
                                        <li type='square'>Sponsorship Letter </li>
                                        <li type='square'>Work Experience Certification /
                                        Reference Letter from Employer, in case
                                        Employed</li>
                                        <li type='square'>Bank Statements / Pass Novels of the
                                        patrons for the past 3 Decades </li>
                                        <li type='square'>Income Tax documents of the host and his
                                        Family for the past 3 Decades </li>
                                        <li type='square'>Proof of movable and immovable assets{" "}
                                        </li>
                                        <br />
                                        For more updated info on Student Visa
                                        and also the Newest prices, kindly see
                                        the sites:
                                        <br />
                                        <a href="https://cgifederal.secure.force.com/">https://cgifederal.secure.force.com/</a>
                                        <br />
                                        <a href="http://www.ustraveldocs.com/in/in-niv-ds160info.asp">http://www.ustraveldocs.com/in/in-niv-ds160info.asp</a>
                                        <br />
                                        <br />
                                        <p style={{ color: "#212121", fontWeight: 'bold' }}>A Few of the Questions likely to be
                                        requested by the Visa officer: </p>
                                        Q. What's the objective of your journey?
                                        <br />
                                        <br />
                                        Q. Which university are you likely to
                                        visit?
                                        <br />
                                        <br />
                                        Q. Who's sponsoring you? <br />
                                        <br />
                                        Q. Why do you wish to do MS / MBA? <br />
                                        <br />
                                        Q. Why is it that you prefer to research in
                                        the united states rather than in India?{" "}
                                        <br />
                                        <br />
                                        Q. Why did you pick this University? <br />
                                        <br />
                                        Q. Have you got any relatives in the united
                                        states? <br /><br />
                                        Q. What are you plans after finishing your
                                        research? <br />
                                        <br />
                                        Q. What's your father's yearly income?{" "}
                                        <br />
                                        <br />
                                        Find our US Visa Interview Video :
                                        <a href="https://youtu.be/kLEr5VhWrvY">https://youtu.be/kLEr5VhWrvY</a>
                                        <br />
                                        <br />
                                        <br />
                                      </p>
                                    
                                    </div>
 
                                    ):<></>}
{showData=='CANADA'?(
                                    <div id="canada">
                                      <h4>Canada:</h4>
                                      <p style={{ color: "#212121" }}>
                                        Canada continues to attract a continuous
                                        quantity of students in India. While
                                        obtaining admission to Leading Colleges
                                        in Canada has been demand exceptional
                                        academic evaluation, the consent to
                                        research is supported by way of a Study
                                        Permit. Should you would like to take
                                        academic, professional, or vocational
                                        training in a college, school, or some
                                        other educational institution in Canada,
                                        then you'll require a study permit
                                        before you enter Canada. This analysis
                                        license is issued to pupils if they
                                        arrive in Canada. <br /> <br />
                                        
                                        Moreover, candidates
                                        must also understand the reasons why
                                        their Canada Visa has rejected? In the
                                        following guide, we'll inform you
                                        everything out of Canada student visa
                                        requirements for records needed for
                                        accessing it into Study Permit. <br /><br /> 


                                        <p style={{ color: "#212121", fontWeight: 'bold' }}>
                                        Searching for Abroad Counselling? </p>Get
                                        Free Profile Assessment from Shiksha
                                        Expert Counsellors - Sign Up Today
                                        What's a Study Permit? International
                                        students can't study in Canada without
                                        obtaining a valid study permit. Besides
                                        that, students who get a research permit
                                        are permitted to enroll in Designated
                                        Learning Institutions.
                                        <br /> <br />

                                        Obtaining the Study Permit: 
                                        <br />
                                        You Must take the Letter of Launch
                                        combined with the specified records to
                                        Canada. In the airport, you'd meet an
                                        Immigration Officer. Current the
                                        Passport along with the Letter of
                                        Introduction to the boundary management
                                        bureau officer who'd then affirm the
                                        exact same and if found sufficient,
                                        problem you your Study Permit.
                                        <br />
                                        <br />
                                        You want to show you have sufficient
                                        funds to cover your tuition fee and
                                        living expenses. You want to demonstrate
                                        you have a clean history without a
                                        criminal record. The aspirants will need
                                        to generate a police certificate to
                                        demonstrate this.
                                        <br />
                                        <br />
                                        You have to do a wellness checkup and
                                        generate a health certificate that
                                        certifies that you're in good health.
                                        <br />
                                        Also in the time of the visa interview,
                                        you want to convince the visa officer
                                        you will depart Canada after you finish
                                        your research.
                                        <br />
                                        
                                        <p style={{ color: "#212121", fontWeight: 'bold' }}>
                                        Which Records are needed for Canadian
                                        Visa?
                                        </p>
                                        You need to apply to your student visa
                                        once you have the school acceptance
                                        letter. You should start with the visa
                                        procedure around June if planning in the
                                        September intake. These are the files
                                        required to use For Canada Student Visa.
                                        <br /><br />

                                        <p style={{ color: "#212121", fontWeight: 'bold' }}>
                                        Which Records are needed for Canadian
                                        Visa?
                                        </p>
                                        You need to apply to your student visa
                                        once you have the school acceptance
                                        letter. You should start with the visa
                                        procedure around June if planning in the
                                        September intake. These are the files
                                        required to use For Canada Student Visa.
                                        <br />
                                        <br />
                                        You'd want the approval letter in the
                                        university/institute you're planning to
                                        attend. (here is a listing for the
                                        reference). In the event you're applying
                                        for Quebec, you would also require a CAQ
                                        that you would be notified.
                                        <br /><br />

                                        <p style={{ color: "#212121", fontWeight: 'bold' }}>
                                        Evidence of Money
                                        </p>
                                        In the time of program for your Study
                                        Permit, you may need to show evidence of
                                        funds. According to the current
                                        standards, you may need to verify that
                                        you'd have sufficient funds to cover our
                                        tuition fees in addition to treat living
                                        expenses. Aside from the aforementioned
                                        two, the student would also need to
                                        demonstrate that he/she has sufficient
                                        funds to get a return fare too.
                                        <br /><br />
                                        
                                        Notice:
                                        <br />
                                        Whilst completing the application form,
                                        you may need to demonstrate the evidence
                                        of fees paid in addition to the
                                        specified cost of residing @ CAD 10,000.
                                        If you're applying for Quebec you need
                                        at CAD 11,000 for each year of your
                                        stay.
                                        <br />
                                        <br />
                                        When you've chosen for offline program,
                                        you'd need two passport-sized photos
                                        that adapt to the specified standards.
                                        For online application, you have to
                                        procure an electronic copy of the
                                        picture that should not be over 4MB.
                                        
                                        <br />
                                        <br />
                                        Notice : Size of this picture needs to be at
                                        least 35 mm x 45 millimeter.
                                        <br />
                                        Picture ought to be current (not older
                                        than 6 weeks ).<br />
                                        <br />
                                        Canadian Immigration demands
                                        international students from India to get
                                        a mandatory Immigration Medical
                                        Examination by empanelled physicians.
                                        Students would need to reserve an
                                        appointment and see the listed
                                        physicians for a medical exam,
                                        preferably a week before they begin
                                        their Visa Application. This is to
                                        provide the doctor time to confirm and
                                        upload the required documents. Booking
                                        an appointment beforehand with the
                                        nearest centre / practitioner according
                                        to the list of empanelled physicians is
                                        always counseled.
                                        <br />
                                        <br />
                                        The panel doctor will conduct a complete
                                        medical examination and might refer you
                                        for torso x-rays and lab evaluations. As
                                        soon as your exam was completed, the
                                        doctor will deliver the results to CIC.
                                        <br />
                                        <br />
                                        <br />
                                        Although not compulsory to possess in
                                        the time of program, we strongly advise
                                        you've appeared for and receive your
                                        Language Language Proficiency Score
                                        before you begin your own Visa
                                        Application procedure. Since it is,
                                        you'd have needed to submit your English
                                        language proficiency rating to validate
                                        your entrance to the Canadian
                                        University. TOEFL, IELTS, etc., are
                                        acceptable.
                                        <br />
                                        <br />

                                        <p style={{ color: "#212121", fontWeight: 'bold' }}>
                                        Statement of Goal
                                        </p>
                                        When searching for a Canadian Study
                                        Permit, you'd be asked to submit an
                                        article stating the goal of your visit
                                        to Canada and you have selected the
                                        specific institution. This could be
                                        prompted as an optional file in the
                                        checklist but we strongly advise that
                                        you submit exactly the exact same.
                                        <br />
                                        <br />
                                        <p style={{ color: "#212121", fontWeight: 'bold' }}>
                                        Charge Card
                                        </p>
                                        In the event you're making an internet
                                        program, you would likewise call for a
                                        charge card to cover the program fee.
                                        Please keep in mind that the machine
                                        only takes credit cards rather than
                                        debit cards. Additionally, it isn't
                                        essential that it's your charge card.
                                        It's possible to use your parent's cards
                                        too, as long as you have the explicit
                                        consent to do so.
                                        <br />
                                        <br />
                                        Aside from the above mentioned, if
                                        applying online, you'd require access to
                                        an electronic scanner. But if you're
                                        applying offline, then you ought to have
                                        accurate copies of all of the
                                        above-mentioned files. It's necessary to
                                        remember that while using online, you'd
                                        be asked to put in a few files, fill
                                        themprint them, register themand then
                                        upload them. Accordingly, the access to
                                        the scanner has to be organized. The
                                        system also lets you upload a fantastic
                                        quality digital picture.
                                        <br />
                                        <br />
                                        Through the interview, additional
                                        documents may be asked by the
                                        interviewer. These might be documents to
                                        demonstrate proof of financial or
                                        academic standing. These can include:
                                        <br />
                                        There are numerous actions to make an
                                        application for a visa.
                                        <br />
                                        <br />
                                        It's suggested to experience the
                                        Canadian consulate site to be aware of
                                        the approximate time required to process
                                        the student visa. Please be aware the
                                        time shown isn't a warranty and is only
                                        an estimate.
                                        <br />
                                        Determine how you'll apply. There are
                                        two methods to use:
                                        <br />
                                        <br />
                                        To apply online you should have access
                                        to a scanner or camera to make digital
                                        copies of your files for uploading and
                                        also have a valid credit card for your
                                        payment. You will nevertheless be asked
                                        to offer a finger scan in the regional
                                        Visa Application Centre (VAC). Bear in
                                        mind, in the event of internet Program
                                        you want to go to the VFS offices to
                                        hand your passports and could need to
                                        supply the verification of form and
                                        payment too. The measures would just
                                        change regarding files you will need to
                                        carry into the VAC. The education guide
                                        includes important details regarding
                                        study permits and directions that will
                                        assist you complete your program. Read
                                        the manual carefully and utilize the
                                        file checklist. You will need this form
                                        to acquire the guidance of VFS solutions
                                        for your own visa filing procedure.
                                        <br />
                                        <br />
                                        Pay the processing fee - Notice that
                                        when submitting an application through
                                        the VFS, in person or by email, visa
                                        fees have to be paid along with VFS
                                        Global service fees. The processing fee
                                        is non refundable in all scenarios.
                                        <br />
                                        In case your spouse/common-law spouse or
                                        kids are accompanying you and you're
                                        applying for temporary resident visas,
                                        work permits, or research licenses for
                                        them, then you'll have to cover the
                                        right processing charges for them.
                                        <br />
                                        Submit an software and supporting files
                                        - you need to see the closest VFS
                                        office. When you're there, cover the
                                        service fees and hand your whole
                                        application to get a receipt. This
                                        reception contains your distinctive
                                        tracking number that you'll have to
                                        monitor the progress of your program on
                                        the web.
                                        <br />
                                        In the event your application is
                                        accepted you'll receive notification in
                                        the Government of Canada asking your
                                        passport. It's possible to submit an
                                        application and passport petition
                                        correspondence in person or via VFS.
                                        <br />
                                        <br />
                                        Canada is a nation in the North American
                                        continent situated right above the USA
                                        and is called a highly developed nation.
                                        The need of individuals to go to Canada
                                        has been steadily rising steadily the
                                        past couple of decades, but the majority
                                        of men and women wonder how they could
                                        reach Canada.
                                        <br />
                                        <br />
                                        <p style={{ color: "#212121", fontWeight: 'bold' }}>What's a Canada Visa?
                                        </p>
                                        A Canada visa is a stamp in your
                                        passport which permits you to go into
                                        the nation of Canada.
                                        <br />
                                        It's a permission that you go to the
                                        nation and lawfully have the ability to
                                        remain either permanently or
                                        temporarily. Finding a visa to Canada,
                                        usually means the Canadian Consulate or
                                        Embassy in your house state decided that
                                        you're qualified and meet the
                                        prerequisites for entrance. However, as
                                        soon as you're at the border and habits,
                                        it's all up to the officers in the edge
                                        to assess whether you're fit to input.
                                        <br />
                                        <br />
                                        Should you reply that the Canadian
                                        Border Services Officer (BSO) questions
                                        honestly and properly, they allow you to
                                        enter. Otherwise, if they guess that
                                        you're not qualified to enter, then they
                                        could deny you and ask you to return to
                                        your home country even in case you've
                                        got a Canada visa.
                                        <br />
                                        <br />
                                        <p style={{ color: "#212121", fontWeight: 'bold' }}>Who Requires a Canadian Visa?
                                        </p>
                                        Individuals from countries which don't
                                        have a visa exemption or Digital Travel
                                        Authorization (eTA) arrangement with
                                        Canada will require a visa to enter the
                                        nation. More especially, people from
                                        across 148 countries require a visa to
                                        see work, or immigrate to Canada.
                                        <br />
                                        <br />
                                        If you're among those applicants and you
                                        require a visa for Canada, you need to
                                        apply for a single. Based on the kind of
                                        visa you need, you'll have a way of
                                        employing, however there are a couple of
                                        general actions that you should take.
                                        <br />
                                        <br />
                                        Prior to applying for a Canadian visa,
                                        you have to first understand which visa
                                        you would like to apply for. Here are
                                        the Kinds of all Canada visas:
                                        <li type='square'>
                                        Function visas
                                        </li>
                                        <li type='square'>
                                        Permanent Home visas
                                        </li>
                                        You have to select the one which matches
                                        the purpose of why you would like to
                                        visit Canada.
                                        <br />
                                        This time is generally around 6 weeks.
                                        <br />
                                        <br />
                                        A single entrance visa allows the
                                        individual to just input Canada once,
                                        remain for 6 weeks and then return to
                                        their home nation. A multiple entry visa
                                        allows the individual to enter Canada
                                        multiple days before their visa expires
                                        and remain temporarily.
                                        <br />
                                        <br />
                                        Listed below are a Part of the program:
                                        <br />
                                        # Canadian Expertise Class
                                        <br />
                                        <br />
                                         # Are qualified tradespeople in jobs such
                                        as:
                                        <br />
                                        <li type='square'>Carpenters</li>
                                        <li type='square'>Aircraft Mechanics</li>
                                        <li type='square'>Crane Operators</li>
                                        <li type='square'>Heavy-duty Equipment Mechanics</li>
                                        <li type='square'>Electricians</li>
                                        <li type='square'>Machinists</li>
                                        <li type='square'>Iron workers</li>
                                        <li type='square'>Welders</li>
                                        <br />
                                        <br />
                                        Individuals who have professions which
                                        are needed in Canada. You have to assess
                                        what type of jobs Canada wants at the
                                        stage you're planning to employ and
                                        acquire the vital points. Low-skilled
                                        employees who can donate to the Canadian
                                        market through their job. The employees
                                        are set in various provinces of Canada
                                        in which the requirement for their job
                                        is greater.
                                        <br />
                                        <br />
                                        Canadian Expertise Class : 
                                        <br />
                                        The Canadian Experience Course permanent
                                        visa is granted to those who own A
                                        temporary student visa or a temporary
                                        employees visa and would like to change
                                        to some permanent resident status. They
                                        have a Canadian schooling or employment
                                        experience and have settled in to
                                        Canadian society.
                                        <p style={{ color: "#212121", fontWeight: 'bold' }}>Canada Visa Program : </p>
                                        <li type='square'>The Canada visa application procedure
                                        depends largely upon the visa type.</li>
                                        <li type='square'>Figure out when you're entitled to a
                                        Canada visa.</li>
                                        <li type='square'>Produce your internet account</li>
                                        <li type='square'>Compile the file</li>
                                        <li type='square'>Pay the charges</li>
                                        <li type='square'>Submit your passport and calculating
                                        charges</li>
                                        <li type='square'>Figure out If You're eligible for a
                                        Canada visa</li>
                                        <br />
                                        <br />
                                        It quite simple. They've established
                                        different tests that assess the
                                        eligibility of applicants by asking them
                                        to complete an online survey. Based on
                                        the kind of visa you need, you'll be
                                        asked to answer a few questions, and the
                                        machine will then inform you if you're
                                        able to apply or not. Furthermore, it is
                                        also going to send you the applicable
                                        directions and steps that you must take
                                        to meet each of the prerequisites.
                                        <br />
                                        <br />
                                        The way to browse through the Canadian
                                        government site?
                                        <br />
                                        In order to get these questionnaires and
                                        Discover when You're Qualified for your
                                        Canada visa, you have to visit their
                                        site . The site will show you different
                                        information and tabs which you could
                                        choose, but you have to visit the
                                        Immigration tab.
                                        <br />
                                        <br />
                                        After you hover or click the Immigration
                                        tab, then It Is Going to show you a
                                        Drop-down collection of alternatives.
                                        The first one is going to be My Program
                                        and then there'll be tabs for Visit,
                                        Immigrate, Work, Study, and many others.
                                        You have to pick the one which contrasts
                                        with the visa that you would like to
                                        apply for. As a continuation of this
                                        case we showed above, if you'd like to
                                        find a tourist visa, then you have to
                                        click the Visit tab.
                                        <br />
                                        <br />
                                        When You click the tab which matches the
                                        reason why you wish to Go to Canada as
                                        well as the visa that you would like to
                                        use for, you may notice more info there.
                                        Whichever tab you've clicked, one of
                                        those hyperlinks will state"Find out
                                        when you're able to use" or"Find out
                                        whether you're qualified". You have to
                                        click on this hyperlink.
                                        <br />
                                        <br />
                                        Answer many questions - They'll vary from
                                        your own personal info to reasons why
                                        you need to see Canada, and past travel
                                        history. In the close of the poll, the
                                        machine will demonstrate the results.
                                        
                                        <br />
                                        You Will Have the Ability to see three
                                        Distinct Kinds of outcomes which say one
                                        Of these:
                                        <br />
                                        You're entitled to the visa you've
                                        chosen.
                                        <br />
                                        You're eligible for a different Kind of
                                        visa which the machine will suit you
                                        with.
                                        <br />
                                        You aren't qualified for the visa.
                                        <br />
                                        If You're eligible for the visa You've
                                        chosen or another kind Of visa, the
                                        program will ask you to enter your
                                        contact info (normally your email), and
                                        they'll send you a set of files.
                                        <br />
                                        The files will Have a description of
                                        this visa, along with Directions and
                                        details regarding how to use.
                                        Furthermore, they can send you a
                                        reference code.
                                        <br />
                                        <br />
                                        The reference code is among the most
                                        important advice on that set Of files,
                                        since you'll need to utilize it to start
                                        your program. With no reference code,
                                        you won't be in a position to apply. The
                                        mention code tells the system the info
                                        you've given it throughout your poll and
                                        it is going to have the kind of visa
                                        you're applying for.
                                        <br />
                                        <br />
                                        Due to this, you have to store the email
                                        and all those files. You can Either
                                        compose the reference code in various
                                        ways, like sending it on your own, write
                                        it on your personal computer, or on a
                                        sheet of paper, however you shouldn't
                                        lose it. If you do, then you'll need to
                                        begin the questionnaire procedure all
                                        over again.
                                        <br />
                                        <br />
                                        Create your internet account
                                        <br />
                                        <br />
                                        Within the directions sent to you by
                                        email, It Is Going to show you if You
                                        have to make an application for a Canada
                                        visa on the internet or in person.
                                        Considering that the Canadian government
                                        is digitalizing its documents, most
                                        folks might need to use online. To do
                                        so, you have to create an account.
                                        <br />
                                        <br />
                                        Utilizing your online banking log
                                        because the Canadian Government has
                                        Partnered with lots of banks;
                                        <br />
                                        <br />
                                        You can use whatever one suits you
                                        better, because either way you may Need
                                        to use your banking information to pay
                                        the visa fees.
                                        <br />
                                        <br />
                                        Compile the file
                                        <br />
                                        <br />
                                        When you login into your accounts, you
                                        can begin your Canada visa program.
                                        Utilize your benchmark code to begin
                                        using for the visa you were deemed
                                        qualified for. As soon as you enter the
                                        benchmark code, then the machine will
                                        show you a listing of files for your
                                        Canadian visa program.
                                        <br />
                                        <br />
                                        For a complete list of files needed for
                                        Canada visa program, You are able to
                                        stop by the post here.
                                        <br />
                                        <br />
                                        You Have to submit All These forms on
                                        the Internet in the machine and then
                                        proceed To submit the program.
                                        <br />
                                        <br />
                                        Pay the fees
                                        <br />
                                        <br />
                                        When the system has confirmed that you
                                        have filed All of the necessary
                                        Documents, it is going to require you to
                                        the page. You'll need to pay the Canada
                                        visa charges based on the kind of visa
                                        you're applying for. When that's
                                        finished, your application will be
                                        filed.
                                        <br />
                                        <br />
                                        It'll take about 5 workdays for one to
                                        view the program in your Accounts, and
                                        if you apply in person, you are only
                                        going to need to take those records to
                                        the Canadian Embassy in your house
                                        country.
                                        <br />
                                        <br />
                                        A couple of weeks depending on the kind
                                        of visa. In addition, they may request
                                        more files, or for you to publish your
                                        biometrics and also have a meeting.
                                        <br />
                                        If they request your biometrics and a
                                        meeting, which means you will You'll
                                        need to make a scheduled appointment, or
                                        the Embassy will create one for you. In
                                        your appointment, you'll need to file
                                        your fingerprints and photos, in
                                        addition to answer questions from a
                                        Canadian Embassy official.
                                        <br />
                                        <br />
                                        The official will decide whether You're
                                        fit to get a Canadian visa or not. They
                                        can allow you to know of the choice
                                        immediately following your appointment
                                        or send you a notification after.
                                        <br />
                                        <br />
                                        All of Embassy notifications about your
                                        visa will be submitted in your own
                                        Account, which means you have to check
                                        it even when you didn't need to submit
                                        biometrics or undergo an interview.
                                        <br />
                                        <br />
                                        Submit an application and processing
                                        charges
                                        <br />
                                        <br />
                                        If you Get a notification that your
                                        Canada visa program was This may be
                                        achieved by mailing to the speech of the
                                        Embassy in your state, together with
                                        processing charges. The processing fees
                                        are contingent on the kind of visa
                                        you're applying for and will vary from
                                        $20 to $50, which you have to pay by
                                        check or money order. You should also
                                        pay for a return envelope to your
                                        passport.
                                        <br />
                                        <br />
                                        Visa in your passport, and then email it
                                        back to you. If you become informed of
                                        the choice right after the visa
                                        appointment, then you'll need to pay the
                                        processing charges there and receive the
                                        passport instantly.
                                        <br />
                                        Following a Thriving Canadian Visa
                                        Program
                                        <br />
                                        <br />
                                        As soon as you've your visa, you are
                                        able to travel to Canada. In the stage
                                        of Entrance, you'll be asked to show
                                        your passport and files. The officials
                                        will assess them and ask several
                                        questions about why you're likely to
                                        Canada.
                                        <br />
                                        <br />
                                        Once you answer, they will make the
                                        choice whether to allow you inside The
                                        nation or not. The officials in the
                                        point of entrance have the ability to
                                        not let you indoors Canada if they think
                                        you will commit crimes or never honor
                                        your visa limitations. Possessing a
                                        Canadian visa doesn't guarantee you will
                                        visit Canada, just the boundary
                                        officials are permitted to do that.
                                        <br />
                                        <br />
                                        How Much Time Does It Take for a Visa to
                                        Canada?
                                        <br />
                                        The Canada visa processing period varies
                                        for each particular case. It may Your
                                        Canadian visa Starts to process just
                                        after the Canadian government Have
                                        received your complete program.
                                        <br />
                                        If they want you to access them further
                                        files, the processing period Will be
                                        postponed.
                                        <br />
                                        <br />
                                        How many men and women reside in your
                                        home?
                                        <br />
                                        However, the Letter of Invitation
                                        Doesn't ensure they will get The visa
                                        will be processed on its own virtue, but
                                        the correspondence might help.
                                        <br />
                                        If You Would like to host a relative to
                                        join you in Canada eternally Or long
                                        term, you are able to do this throughout
                                        the Canada Family Sponsorship Program.
                                        <br />
                                        <br />
                                        How Do I Extend my Stay at Canada?
                                        <br />
                                        In case your Canada visitor visa is
                                        going to perish, and you also want to
                                        Remain This is known as a Canada Visitor
                                        Record.
                                        <br />
                                        Permit into a Visitor Record too. But a
                                        guest record is only accessible as
                                        you're inside Canada.
                                        <br />
                                        If You Wish to leave Canada and input
                                        again, you Won't be permitted Entry
                                        using a guest record.
                                        <br />
                                        In Case You Have remained in Canada
                                        beyond the expiry of your visa, then the
                                        following step Depends upon the length
                                        of time you've overstayed.
                                        <br />
                                        If you have overstayed for under 90
                                        days, then you can revive your visitor
                                        Standing by submitting an application
                                        for a Visitor Record and
                                        choosing"Restore my standing as a
                                        customer".
                                        <br />
                                        If you have overstayed for more than 90
                                        days, you Can't use to Restore your
                                        standing and need to leave Canada. You
                                        might be unable to receive another
                                        Canada visa.
                                        <br />
                                        But you should make an effort to not
                                        overstay a Canada visa. If you believe
                                        you Might want to remain longer than the
                                        length of your visa, apply for a visa
                                        extension until it expires.
                                        <br />
                                        The Canada visa fee is dependent upon
                                        the kind of visa you're applying for.
                                        Additionally, there are various kinds of
                                        fees you have to pay, like a processing
                                        fee plus a biometrics fee.
                                        <br />
                                        An ordinary Visitor Visa charge,
                                        however, is CAN100.
                                        <br />
                                        <br />
                                        Watch the Canada visa charges.
                                        <br />
                                        <br />
                                        In case your Canada visa application is
                                        refused, you are able to re-apply again
                                        at Anytime, given your rejection letter
                                        doesn't prohibit you by re-applying.
                                        <br />
                                        But you are able to re-apply only if
                                        your situation has changed or you've
                                        Additional information that might allow
                                        you to get a Canada visa acceptance.
                                        <br />
                                        <br />
                                        In Terms of appeals, There's no formal
                                        process to appeal a Canada tourist Visa
                                        choice.
                                        <br />
                                        <br />
                                        Canada visa charges are non refundable,
                                        even if your program is rejected. That
                                        is because you are paying a charge for
                                        your visa application to be processed,
                                        not to the visa itself.
                                        <br />
                                        <br />
                                        Nonetheless, in Some Instances, You
                                        Might Find a refund if you withdraw your
                                        Program until it begins to process. When
                                        it's begun processing, you aren't going
                                        to receive a refund.
                                        <br />
                                        <br />
                                        Furthermore, if your program is You
                                        Might Be Given a refund for your
                                        following:
                                        <br />
                                        <br />
                                        The fee for a Open Work Permit
                                        <br />
                                        <br />
                                        The fee for International Expertise
                                        Canada (IEC)
                                        <br />
                                        <br />
                                        But, you can still ask for a refund for
                                        some of your charges via the
                                        <br />
                                        <br />
                                        Sometimes, There's no Canadian embassy
                                        or Visa Program Center In your state of
                                        residence. In cases like this, you might
                                        need to go to a different country to
                                        submit an application for a Canada visa.
                                        <br />
                                        <br />
                                        But you should only apply for a visa in
                                        the embassy or VAC Accountable to your
                                        authority (ie. For the nation you're a
                                        legal resident of).
                                        <br />
                                        <br />
                                        Furthermore, If You're from Nation A and
                                        possess a residence permit for
                                        <br />
                                        <br />
                                        But If You're simply seeing Country B
                                        and Don't Have legal Residency there,
                                        you need to employ in the embassy or
                                        Visa Program Center accountable to your
                                        jurisdiction, on your country or
                                        differently.
                                        <br />
                                        <br />
                                        Do I Want to Submit Biometrics For my
                                        Canada Visa Program?
                                        <br />
                                        Yes, most visa applicants need to offer
                                        their biometrics (fingerprints And image
                                        ) when searching for a Canada visa,
                                        irrespective of the sort of visa being
                                        applied for.
                                        <br />
                                        <br />
                                        You may present your biometrics in a
                                        Canadian Visa Program center. The
                                        Canadian embassy will send you a letter
                                        telling you of if you need to present
                                        your biometrics. To get the letter, you
                                        have to cover the biometrics fee when
                                        you submit your Canada visa program.
                                        <br />
                                        <br />
                                        You will find Visa Application Centers
                                        globally where it is possible to submit
                                        your biometrics. Go to the web site of
                                        the one closest to you to figure out
                                        about the charges and services they
                                        supply.
                                        <br />
                                        <br />
                                        An Canadian visa is a stamp on the
                                        passport also contains a different
                                        appearance. It Usually doesn't contain a
                                        photo, but comprises the following
                                        advice:
                                        <br />
                                        <br />
                                        The Set the visa has been issued;
                                        <br />
                                        Date of issuance and expiry;
                                        <br />
                                        Amount of entries permitted;
                                        <br />
                                        Document number;
                                        <br />
                                        Visa class;
                                        <br />
                                        Visa kind;
                                        <br />
                                        The visa holder's first and last name;
                                        <br />
                                        <br />
                                        Canada Visitor Visa
                                        <br />
                                        <br />
                                        To Go to the US from Canada, then You
                                        May Be required to apply for a United
                                        States But if you're a citizen of a
                                        state from the Visa Waiver Program,
                                        you're not required to submit an
                                        application for a US visa from Canada,
                                        however you'll need to employ for ESTA
                                        rather than
                                        <br />
                                        <br />
                                        A research permit for Canada prices CAD
                                        150, which will be approximately equal
                                        to Considering that the foreign exchange
                                        rate keeps shifting, we'd suggest that
                                        you look at the exact same in the time
                                        of your visa program.
                                        <br />
                                        <br />
                                        Canadian Visa Software is available both
                                        offline and online. As Like the visa
                                        processing charges could be manufactured
                                        both online (via way of a charge card)
                                        or offline (by way of demand draft if
                                        paying in the High Commission of Canada)
                                        or by money in the VAC. This fee has to
                                        be paid when the application is filed or
                                        a charge receipt of online payment
                                        provided.
                                        <br />
                                        <br />
                                        Additionally, the visa fee said doesn't
                                        include VAC service fees. You Would need
                                        to pay the extra service charges as
                                        applicable to your area. Additionally, a
                                        receipt will be issued for every payment
                                        received. Please keep the receipt as
                                        evidence of payment.
                                        <br />
                                        <br />
                                        Post entry of your visa application,
                                        students Will Need to submit a Biometric
                                        charge of CAD 85. Candidates will need
                                        to submit this charge along with the
                                        normal visa fee. Next, your biometrics
                                        are going to be taken in a Visa
                                        Application Centre.
                                        <br />
                                        <br />
                                        Processing Time to Your Canada Student
                                        Visa
                                        <br />
                                        <br />
                                        application. It begins as soon as the
                                        aspirant documents the program until the
                                        last decision day. Therefore, there's
                                        absolutely no particular time interval
                                        cited for processing a Canada research
                                        visa program. Aspirants (both paper and
                                        online ) can see the status of the
                                        programs through their MyCIC account.
                                        <br />
                                        Study Permit Renewal
                                        <br />
                                        <br />
                                        For remaining in Canada, pupils will
                                        need to get a valid study permit. If in
                                        Case, your research permit expires
                                        before completion of your research
                                        (program) then you want to submit an
                                        application for renewal of a research
                                        permit. Ideally, you need to employ at
                                        least30 days before your current license
                                        expires. Candidates may submit their
                                        application via the mail or online.
                                        <br />
                                        <br />
                                        Get Free Profile Assessment from Shiksha
                                        <br />
                                        <br />
                                        Dependents
                                        <br />
                                        <br />
                                        Spouses can accompany full-time pupils
                                        on a Dependent visa. You only Need to
                                        demonstrate that sufficient funds are
                                        available for their service. Spouses may
                                        also work whole time in case his/her
                                        remain is for a season or even longer.
                                        <br />
                                        <br />
                                        Accompanying your partner or common-law
                                        spouse to Canada. Having the open work
                                        permit, you might find and take any
                                        occupation when you reach Canada. You
                                        don't require a job offer or a favorable
                                        labour market impact evaluation to apply
                                        for your work permit.
                                        <br />
                                        <br />
                                        Scholarships for studying at Canada
                                        <br />
                                        <br />
                                        International students, however,
                                        analyzing this isn't cheap for a few.
                                        Thus, we've listed out a few of the most
                                        popular scholarships for people who want
                                        financial aid for pursuing their
                                        education in Canada.
                                        <br />
                                        Ritchie-Jennings Memorial Scholarship
                                        <br />
                                        <br />
                                        OGS/QEII-GSST Scholarships for Global
                                        Students
                                        <br />
                                        <br />
                                        Candidates
                                        <br />
                                        Ontario Graduate Scholarship- Additional
                                        Master Programs
                                        <br />
                                        <br />
                                        Q. How many rings must study in Canada?
                                        <br />
                                        A. For an undergraduate diploma, the
                                        Canadian high commission needs In
                                        addition, for the postgraduate level,
                                        candidates need overall 6.5 rings with
                                        no band less than 6.0.
                                        <br />
                                        <br />
                                        Q. Just how many backlogs are permitted
                                        for Canada student visa?
                                        <br />
                                        A. Many universities in Canada take a
                                        max of 5 backlogs, using a Undergraduate
                                        level. Universities take a max of 8 or 7
                                        backlogs in the event the candidate has
                                        graduated 6.5 percent or over from the
                                        undergraduate curriculum.
                                        <br />
                                        <br />
                                      </p>
                                    </div>
                                    ):<></>}
                                    {showData=='UK'?(
                                    <div id="uk">
                                      <h4>UK : </h4>
                                      <p style={{ color: "#212121" }}></p>
                                      Documents Necessary to get a UK Study Visa<br /><br />

                                      UK Student Visa Requirements: Applying for
                                      a student visa requires one to take a good
                                      deal more files compared to your tourist
                                      visa, in contrast. Aspirants want the
                                      mandatory documents together with
                                      supporting files of academic and financial
                                      documents. Ensure that you consult with
                                      this UK Student Visa checklist prior to
                                      visiting the consulate for your visa
                                      interview. <br /><br />
                                      A finished student visa
                                      application form <br /><br />
                                      
                                      The visa correspondence
                                      should be issued no longer than six months
                                      prior to applying. The software in which
                                      the visa letters are somewhat older than
                                      six months will probably be denied. <br /><br />
                                      Using
                                      a visa letter doesn't ensure the
                                      application will succeed. The applicant
                                      must fulfill all of the prerequisites of
                                      the class and some additional requirements
                                      of regulations. <br /><br />
                                      
                                      Proof of capital - The
                                      cash that you want to reveal insures your
                                      course fees to the first year of study and
                                      living costs for up to a maximum of
                                      fourteen months. The sum that will need
                                      depends upon if you're applying as a kid
                                      or adult and whether you'll be analyzing
                                      in or outside of London. You have to
                                      demonstrate that you've held the cash for
                                      28 days. The conclusion of the 28 day
                                      period shouldn't be more than 1 month
                                      prior to the date of your program.<br /><br />

                                      Declaration of Purpose (SOP): All you have
                                      to know! <br /><br />

                                      Student Simulator for Study
                                      Abroad <br /><br />
                                      
                                      Indian Banks supplying Education
                                      Loans for Researching Abroad <br /><br /><br /><br />
                                      
                                      You have to
                                      demonstrate the next amount of cash to
                                      satisfy basic living expenses (not
                                      including fees) for a period of around
                                      eight months i.e. #11,385 for 2 months if
                                      you live in London and #9,135 for 2 months
                                      if you're living outside London. <br /><br />
                                      The visa
                                      charges payment verification receipt<br /><br />

                                      Initial mark sheets/provisional
                                      certificates You could also take a look at
                                      a movie on Visa counselling session on the
                                      united kingdom and Ireland research visa
                                      by in-house Shiksha counselors. This movie
                                      covers various subjects like forms of a
                                      student visa, step-by-step procedure to
                                      find a visa, file checklist, and source of
                                      financing, typically asked questions, from
                                      pupils. <br /><br />
                                      
                                      UK Student Visa Procedure: How to
                                      Employ <br /><br />
                                      UK Study Visa applications are
                                      processed in 3 weeks of this finished
                                      application being lodged. <br /><br />
                                      Step 1 - Step
                                      one would be to assess if you are eligible
                                      for the student visa or not. You Have to
                                      show that you've got: <br /><br />
                                      A verified location
                                      in the school, college or university in
                                      which you would like to research. It's
                                      known as a'verification of approval for
                                      research' (CAS). <br /><br />
                                      Enough cash (also
                                      called'funds or maintenance') to pay the
                                      course fees and living costs in the united
                                      kingdom. <br /><br />
                                      You'll have to register and make
                                      an account on the official UK Visa site.<br /><br />

                                      When you've finished your online
                                      application form, you'll have to print and
                                      sign your completed form. You have to use
                                      the online appointment calendar to reserve
                                      an appointment in the visa application
                                      centre. When you've completed your online
                                      application, you'll get an email message
                                      containing your program amount. <br /><br />
                                      
                                      Measure 4
                                      - Purchase your application fee. Payment
                                      at Standard Chartered Bank - It is
                                      possible to pay your commission at several
                                      branches of this Normal Chartered Bank.<br /><br />

                                      Demand draft by a nationalized or overseas
                                      exchange - It is possible to pay your
                                      commission by acquiring a demand draft
                                      issued by a nationalized or a foreign
                                      exchange. Bank fees for this service will
                                      be different. The demand draft has to have
                                      been in favor of this'The British High
                                      Commission'. <br /><br />
                                      
                                      Payment in the visa
                                      application centre - You are able to cover
                                      the visa application fee in the visa
                                      application centre if you submit your visa
                                      application. <br /><br />
                                      
                                      Pay online - You are able to
                                      pay your visa application fee online with
                                      a Visa or MasterCard debit or credit card.<br /><br />


                                      Measure 5 -- Visit the visa program centre
                                      on the date. You have to reserve and
                                      attend an appointment in the visa program
                                      centre to file your program and facial
                                      photo and fingerprints. <br /><br />
                                      
                                      Pupils should be
                                      advised that the UK Visa is a point-based
                                      visa program and it's crucial for
                                      applicants to fulfill all of the demands
                                      of the Immigration Rules and also have 40
                                      points in total. <br /><br />
                                      
                                      Factors <br /><br />
                                      
                                      Students Need to
                                      have a legal Proof of Acceptance for
                                      Research from a fully Accredited Tier 4
                                      host <br /><br />
                                      
                                      Pupils should Have Sufficient cash to
                                      pay for course fees and monthly living
                                      expenses (also Called funds or maintenance
                                      ) <br /><br />
                                      
                                      Top Colleges in UK 2019 <br />
                                      Best MBA
                                      Programs at London <br />
                                      Download this manual to
                                      see it offline <br /><br />

                                      When do you intend to
                                      begin? <br /><br />
                                      Have you given any research
                                      overseas examination? <br /><br />
                                      
                                      The spouse or kids
                                      receive a Dependent Visa to exactly the
                                      exact same duration as the principal
                                      applicant. This can be applicable to this
                                      Master's degree program only.
                                      Undergraduates can't take dependents
                                      together to the United Kingdom. <br /><br />
                                      
                                      When
                                      you've got a student visa, then your
                                      dependents employ as'reliant upon a Points
                                      Based System migrant'. Each dependent
                                      finishes another application form and pays
                                      another fee. <br /><br />
                                      
                                      Together with your
                                      maintenance budget, you have to reveal an
                                      extra #845 (in London) and #680 (outside
                                      London) for each dependent for every month
                                      which you're employing up to a maximum of
                                      9 weeks' maintenance capital. It is
                                      possible to use a scholarship to pay for
                                      the care funds for your dependents if you
                                      already've a scholarship. The scholarship
                                      letter should say that capital are also
                                      meant to the dependents. <br /><br />
                                      
                                      Additional Info<br /><br />

                                      If you would like to work there after
                                      finishing your research, then you have to
                                      submit an application for a Tier 2 visa
                                      that's intended for Skilled Workers. So
                                      together with your student loan, you do
                                      not receive a work permit. You have to
                                      apply for a work visa individually. <br /><br />
                                      
                                      Your
                                      partner may function in the united kingdom
                                      in case the grant of leave is for over 12
                                      months. If your visa has been granted for
                                      a period of over 12 weeks or you're
                                      engaging in a plan of study below degree
                                      level, your spouse won't be permitted to
                                      operate in the united kingdom. <br /><br />
                                      
                                      As a
                                      regular UK Study Visa may take 6 months to
                                      process, students may choose the Priority
                                      Visa Service or the Super Priority Visa
                                      Service to shorten the visa processing
                                      period for an extra charge. Underneath the
                                      Priority Visa Program, the UK Visas and
                                      Immigration intends to make a
                                      determination in your own Priority Visa
                                      application and contact you to inform you
                                      that your passport is about to be
                                      collected in 5 working days from when you
                                      submit your personal info. Underneath the
                                      Super Priority Visa Program, the exact
                                      same work is finished within 24 Hrs.
                                      Candidates must note that the price of the
                                      service is in addition to the visa
                                      application fee and can be non-refundable
                                      when the visa application is denied, or in
                                      extraordinary cases when it takes more
                                      time to process. <br /><br />
                                      
                                      A UK visa is the
                                      authorizing document that provides global
                                      citizens the right to go into, remain
                                      temporarily or reside forever in the land
                                      of the United Kingdom. A visa is generally
                                      either a postage you get on your passport
                                      or a document supplied by a UK consulate
                                      or embassy in your country of residence.<br /><br />


                                      If you're issued a visa, then it usually
                                      means that you're entitled to enter the UK
                                      which you satisfy the necessities of the
                                      specific visa which you've been given.
                                      There are different reasons why Folks wish
                                      to apply for a UK visa, for example: <br /><br />
                                      To
                                      operate and to perform business <br />
                                      To
                                      research <br />
                                      To combine family members That
                                      Are currently in the United Kingdom <br />
                                      To
                                      transit throughout the UK to another
                                      nation <br />
                                      To reside permanently <br />
                                      For a refugee
                                      or as somebody who desires humanitarian
                                      protection <br />
                                      As a stateless individual <br />
                                      As
                                      somebody who wants permission to Keep <br /><br />
                                      Who
                                      Wants a UK Visa? <br />
                                      Citizens of the European
                                      Economic Area, Switzerland and
                                      Commonwealth nations Aren't required to
                                      employ a UK visa Before entering the
                                      United Kingdom. For an entire list of
                                      nations whose citizens require a visa for
                                      UK see this page. <br /><br />
                                      
                                      The UK visa application
                                      procedure depends a good deal on the kind
                                      of visa which you're applying for.
                                      Particularly if your kind of visa proceeds
                                      to one of those Points-Based System
                                      category. Even though the majority of the
                                      requirements will be exactly the exact
                                      same for every class, the way they're
                                      assessed in the machine disagrees.
                                      Additionally, there are additional
                                      conditions that change from one to a
                                      different class. <br /><br />
                                      
                                      However, remember that
                                      the processing of your visa application
                                      may take around three weeks. <br /><br />
                                      
                                      Which are the
                                      Essential Records for UK Visa? <br /><br />
                                      There are a
                                      few normal UK visa conditions to use for
                                      any that each applicant must meet. <br /><br />
                                      What's
                                      your Points-Based System (PBS)? <br /><br />
                                      
                                      The PBS
                                      system assesses visa candidates depending
                                      on the things they could collect by
                                      fulfilling visa requirements. Each visa
                                      demand has its own points, and there's an
                                      appointed variety of factors that the
                                      candidate must collect so as to get the
                                      visa. <br /><br />
                                      
                                      There are various explanations for
                                      why folks wish to apply for a UK visa.
                                      That's the reason why the United Kingdom
                                      has a structured visa program dependent on
                                      the aim of visitor beneath multiple visa
                                      kinds. <br /><br />
                                      
                                      Here would be the UK visa forms:<br /><br />

                                      Business and work Visas<br /><br />
                                       Apart from its
                                      strict principles, not permitting to
                                      receive a job lots of the visa holders,
                                      the UK also offers Working Visas
                                      particularly for luxury professionals from
                                      different countries who want to work and
                                      reside in the united kingdom for shorter
                                      or longer intervals. <br /><br />
                                      
                                      Forms of job and
                                      business visas <br />
                                      Highly qualified burglars:<br />
                                      Skilled overseas employees with a job
                                      offer in a deficit area <br />
                                      Grade 2
                                      (Intra-company Transport ) Visa <br />
                                      Youth
                                      freedom and temporary foreign workers<br />
                                      Grade 5 (Youth Freedom Scheme) Visa<br /><br />


                                      Additional: <br /><br />
                                      
                                      National Workers at a Personal
                                      Household Visa <br />
                                      Measure 1 Entrepreneur Visa<br />

                                      Grade 1 Investor Visa <br />
                                      Student Simulator<br />
                                      These kinds of visas are issued to
                                      international students who wish to
                                      research in the united kingdom.<br />

                                       Forms of
                                      student visas <br /><br />

                                      Short Term Research Visa<br />
                                      Tourist and Seeing visas <br /><br />

                                      The UK is a
                                      remarkably popular tourism destination,
                                      even for shorter or longer intervals of
                                      visits. UK authorities seldom do problem a
                                      tourism visa for a longer duration than
                                      six months. The foreign nationals are
                                      banned from acquiring work within UK with
                                      this kind of visa, as with a number of
                                      different forms. <br /><br />
                                      
                                      Short company trips are
                                      managed form of the exact same way. <br /><br />
                                      
                                      Groups
                                      tourist and seeing visas <br /><br />
                                      Visa to get a
                                      Chinese Tour Group <br /><br />
                                      This Visa kind is for
                                      those that have family members living in
                                      the united kingdom and need to combine
                                      them for over half a year, as their own
                                      inheritance. The dependents may be husband
                                      or spouse (spouse), spouse, fiancé,
                                      fiancée or proposed civil partner, kids,
                                      parents, other relatives. <br /><br />
                                      
                                      Types of
                                      household visas <br /><br />
                                      
                                      This visa gives the chance
                                      to apply for a long-term transit visa when
                                      the applicant has sufficient proof he
                                      wants to travel frequently through UK to
                                      arrive at his destination, but does not
                                      have any intention to live or stay there
                                      indefinitely. Such long term transit visas
                                      may be granted from 1 to 10 decades long.<br /><br />


                                      Types of transit visas <br /><br />
                                      
                                      Direct Airside
                                      Transit Visa (DATV) -- for people who Need
                                      to change flights from the UK without
                                      departing the airport <br /><br />
                                      
                                      Visitor in Transit
                                      Visa -- for thieves who need to depart the
                                      airport and choose another way of
                                      traveling, of building part of the travel
                                      through the property. This visa may be
                                      valid for up to 4 times. <br /><br />
                                      Temporary Refugee
                                      Visa is for men and women that are just
                                      about to enter in the united kingdom and
                                      are in the boundaries because needed to
                                      leave their country because their life was
                                      severely threatened. The program for this
                                      visa is created from inside the united
                                      kingdom. <br /><br />
                                      
                                      The visa is temporary, before the
                                      visa holder isn't endangered anymore and
                                      certainly will return to their own nation.
                                      Visa for Stateless Persons is for
                                      foreigners residing in the united kingdom
                                      and therefore are not nationals in any
                                      other nation on the planet. <br /><br />
                                      
                                      The UK
                                      Settlement license also referred to as
                                      Indefinite Leave to Remain, is for people
                                      who've been residing in the united kingdom
                                      for a lengthy time using a temporary bond,
                                      and want to get a permanent one. The visa
                                      applies to those who have lived from the
                                      UK at least two decades and want to
                                      return, or their passport has been
                                      missing. <br /><br />
                                      
                                      Foreigners who live in the UK
                                      with electronic refugee or humanitarian
                                      status and need to settle there
                                      permanently or for family reunion. The
                                      majority of these visas state the
                                      applicant to have dwelt minimum five years
                                      in the uk. <br /><br />
                                      
                                      EEA family permit associated
                                      with the derivative right of home <br /><br />
                                      EEA
                                      family permit associated with this
                                      Surinder Singh <br /><br />
                                      EEA family permit
                                      associated with retained rights of home<br /><br />

                                      Other Home Permits <br /><br />
                                      
                                      UK Home allows serve to
                                      acquire temporary dwelling in the united
                                      kingdom. <br /><br />
                                      
                                      Groups <br /><br />
                                      Home License for
                                      Commonwealth Citizens <br /><br />
                                      Digital Visa Waiver<br /><br />

                                      The Digital Visa Waiver is created for
                                      citizens of Kuwait, Oman, Qatar and the
                                      United Arab Emirates that wish to visit
                                      the UK for greatest six weeks to conduct
                                      business, to sightseeresearch or to find
                                      medical attention. <br /><br />
                                      
                                      Citizens of those
                                      countries must use for additional visa
                                      types should they attempt to visit the UK
                                      for different reasons or for longer
                                      intervals. <br /><br />
                                      The way to Submit an
                                      Application for Digital Visa Waiver?<br /><br />
                                      
                                      The
                                      program online has to be accomplished
                                      here. <br /><br /><br />
                                      
                                      With this visa there's absolutely no
                                      option to add dependents, everybody must
                                      apply individually. <br /><br /><br />
                                      
                                      Passport info;<br /><br />
                                      
                                       Where
                                      the offender will probably be living in
                                      the UK; <br /><br />
                                      Travel itinerary with projected
                                      dates of entrance and depart <br /><br />
                                      There's a
                                      option to have change info within this
                                      visa when required, i.e. shift as terms of
                                      airporttrain station, bus station or
                                      comparable, alter as regards of this
                                      timing of entering the UK and comparable.<br /><br />


                                      Upon the application, the visa will be
                                      obtained in 1 day in a kind of a
                                      connection, at the email address of this
                                      candidate for downloading. Therefore, it
                                      has to be published to be revealed at the
                                      united kingdom boundaries when inputting.<br /><br />


                                      There may also be instances once the visa
                                      may be cancelled in the boundaries. <br /><br />
                                      
                                      Any
                                      kind of overstaying, or violating visa
                                      requirements, while being at the united
                                      kingdom or outside, is regarded as an act
                                      of crime. <br /><br /><br />
                                      
                                      
                                      Visas Provide the required
                                      answers on: <br /><br />
                                      
                                      Who to acknowledge to enter in
                                      the United Kingdom and , <br />
                                      Who to let living
                                      permanently or temporarily in the United
                                      Kingdom and , <br />
                                      Who matches the standards
                                      and who to prevent from entering in the
                                      United Kingdom, <br />
                                      Who wants to depart the
                                      UK, Once the visa expires or for other
                                      motives <br /><br />

                                      Visas occasionally can prohibit
                                      the holder performing many activities
                                      like: <br />

                                      Obtaining a job of additional
                                      specialist activity in the United Kingdom,<br /><br />

                                      Utilizing public funds; <br /><br />
                                      Remaining in the
                                      UK without registering with the
                                      authorities (without becoming police
                                      registration certificate); <br /><br />
                                      
                                      Study from the
                                      UK; <br /><br />
                                      
                                      Such limitations can be made known to
                                      the holder at the following manners: <br /><br />
                                      
                                      Found
                                      from the passport/travel record; <br /><br />
                                      
                                      From the
                                      visa; <br /><br />
                                      
                                      Is Getting a Booked Flight Necessary
                                      to Apply for a UK Visa? <br /><br />
                                      
                                      Booking a trip to
                                      get a UK visa program isn't compulsory.
                                      The UK government let you apply without
                                      needing one. You still need to submit a
                                      travel itinerary, where you include the
                                      planned entry and exit dates. <br /><br />
                                      
                                      How Much
                                      Time Does It Take for a Visa for the
                                      United Kingdom? <br /><br />
                                      
                                      The existing UK Visa
                                      processing period differs from 1 program
                                      to another based on many elements. <br /><br />
                                      
                                      The
                                      visa type You're applying for, <br /><br />
                                      
                                      The nation
                                      from where You're employing, <br />
                                      Your
                                      Particular scenario, <br />
                                      The amount of
                                      software the UK consulate/visa processing
                                      centre is getting at the moment, etc.. <br />
                                      To
                                      inspect the existing UK visa processing
                                      period for your visa you want to apply for
                                      on your state of residence, check here.<br /><br />


                                      Could I get my visa quicker? <br /><br />
                                      
                                      Based on the
                                      visa type you're applying for, then you
                                      might be qualified to utilize the'priority
                                      support' or perhaps the'super-priority
                                      support' when submitting your program.<br /><br />

                                      The'priority support' allows you to find a
                                      decision on your application within five
                                      working days for a further fee of 500.
                                      The'super priority agency' which will cost
                                      you 800 besides the visa fee, also allows
                                      you to acquire a determination by the end
                                      of the following working day. <br /><br />
                                      
                                      What's your
                                      Immigration Health Surcharge? <br /><br />
                                      The
                                      Immigration Health Surcharge is the
                                      quantity of cash you need to cover when
                                      you apply for a visa to the United
                                      Kingdom. As soon as you're in the UK using
                                      a visa, you're eligible to utilize
                                      services of the NHS the like other UK
                                      taxpayers. <br /><br />
                                      
                                      The wellness surcharge prices
                                      #300 annually for a pupil or Tier 5 (Youth
                                      Freedom Scheme) visa, and #400 annually
                                      for all other immigration and visa
                                      programs.<br /><br />
                                      
                                      What Are the Factors For UK Visa
                                      Denial? <br /><br />

                                      One of the most Frequent reasons
                                      why UK visas are denied are as follows:<br /><br />

                                      Missing files. This really is the most
                                      frequent cause applications are refused.
                                      One missing record and the embassy is
                                      going to be carried out with your program.
                                      You won't even get another opportunity to
                                      submit what's missing. You'll have to
                                      initiate a brand-new program. <br /><br />
                                      
                                      Incorrect
                                      and mismatching info on your files.<br /><br />

                                      Insufficient financial means to strengthen
                                      your stay in the united kingdom. <br /><br />
                                      
                                      Not
                                      fulfilling the English language
                                      requirement. <br /><br />
                                      
                                      Incorrect business of files.<br /><br />

                                      What Can I Do In The Event Of Visa Denial?<br /><br />

                                      If your program for a UK visa is refused,
                                      you can just give up, or Reapply for your
                                      own visa. If you snore, be certain you
                                      don't make the preceding error, or you
                                      also improve your position to be able to
                                      fit the requirements. <br /><br />
                                      
                                      You ought to have a
                                      solid basis to appeal a visa rejection
                                      choice. <br /><br />
                                      
                                      Challenge the conclusion via
                                      judicial review. You can accomplish this
                                      when you've got a powerful reason to feel
                                      that your application was rejected
                                      illegally or irrationally or there has
                                      been procedural unfairness through the
                                      processing of your program document. <br /><br />
                                      
                                      Can I
                                      get a refund? <br /><br />
                                      
                                      No, it's not feasible to get
                                      the commission you paid refunded. The fee
                                      to get a UK visa is needed so as to pay
                                      for the costs that come from this
                                      processing of your program, thus refunding
                                      isn't feasible if your visa is refused or
                                      you opt to withdraw your application
                                      whenever it's being processed.<br /><br />


                                      Additionally, when you reapply for a UK
                                      visa following your fist program was
                                      rejected, you'll have to cover the fee .<br /><br />


                                      Could I Obtain a UK Visa When I Have a
                                      Criminal Record? <br /><br />
                                      
                                      In case you've been
                                      involved in criminal activity before, the
                                      UK government will still receive and
                                      process your UK visa program. However, the
                                      Home Office will treat your application
                                      differently in how it treats software of
                                      individuals without a criminal past. <br /><br />
                                      
                                      The
                                      amount of the sentence and the period
                                      that's passed since then are extremely
                                      significant in these instances. <br /><br />
                                      
                                      4 decades or more. <br /><br />
                                      
                                      Between you to 4 decades, unless
                                      10 years have passed since the close of
                                      the sentence. <br /><br />
                                      
                                      Over 12 weeks, unless 5
                                      years have passed since the close of the
                                      sentence.<br /><br />
                                      
                                       Your program will also be
                                      reversed on the spot in the event the
                                      period of 12 weeks before the application
                                      is determined, You've been convicted of or
                                      declared a crime for which you obtained a
                                      non-custodial sentence or outside of court
                                      disposal That's listed in your criminal
                                      record,<br /><br />
                                      
                                       Do I Want to Register With the
                                      Authorities? When the visa sticker in your
                                      passport includes the words'Police
                                      enrollment' or'Register with authorities
                                      in seven days of entrance' then you'll
                                      have to register with the authorities as
                                      soon as you're in the UK using a legal
                                      visa. <br /><br />
                                      
                                      You've got seven days to visit the
                                      authorities for enrollment once you arrive
                                      in the united kingdom. Be aware that in
                                      the event you don't register with the
                                      authorities, your visa legitimacy is going
                                      to be shortened and you might want to
                                      depart the United Kingdom. <br /><br />
                                      
                                      Could I
                                      Traveling Abroad While I'm in the UK
                                      Having a Valid Visa? <br /><br />
                                      
                                      Holders of UK visas
                                      are permitted to travel overseas while at
                                      the united kingdom. But, there could be
                                      additional requirements they need to meet
                                      based upon their nationality and purpose
                                      of traveling. <br /><br />
                                      
                                      UK's education system is
                                      thought to be among the finest on earth.
                                      But the majority of the international
                                      students need financial aid to pursue
                                      their higher education from that point. To
                                      be able to satisfy the ambitions of
                                      international students, several kinds of
                                      scholarships are offered to research in
                                      the united kingdom. Below you'll see the
                                      listing of a few of the very best
                                      fellowships for studying in the united
                                      kingdom. <br /><br />
                                      
                                      
                                      Global Research Awards <br /><br />
                                      
                                      Nominees
                                      must have an unconditional offer from a
                                      recognized educational institute for
                                      following a class using a Certified Tier 4
                                      host <br /><br />
                                      Proper funds to support yourself and
                                      to get your Program <br /><br />
                                      Pupils from the other
                                      nationalities have to get a legitimate UK
                                      student visa to study in the united
                                      kingdom. <br /><br />
                                      If a pupil is moving just for a
                                      single semester, then also he'll need a
                                      visa. <br /><br />
                                      
                                      A. Yes, even when you're striving
                                      for a short-term state less than half a
                                      year, then you are able to apply for a
                                      Short Term Study Visa. <br /><br />
                                      
                                      In the latest when
                                      you may apply for a study visa? <br /><br />
                                      
                                      An
                                      individual may apply for 3 weeks prior to
                                      the beginning of the program.
                                      Additionally, candidates usually receive a
                                      decision in their visa within 3 months.<br /><br />

                                      How ancient you visit the UK before the
                                      app begins? And for how long you can stay
                                      here? <br /><br />
                                      
                                      If your course lasts 6 weeks or less
                                      then it's possible to visit the UK up to a
                                      week prior to the commencement of your
                                      program. <br /><br />
                                      
                                      If your class lasts, over 6 weeks
                                      afterward can visit the UK for up to a
                                      month before your class begins. <br /><br />
                                      
                                      What's a
                                      CAS record? <br /><br />
                                      
                                      A. Your instructional
                                      institute will send you a reference number
                                      referred to as a verification of approval
                                      for research (CAS) when they confessed you
                                      for the program. Candidates will need to
                                      input this amount on their visa program.<br /><br />
                                    </div>
                                    ):<></>}
                                    {showData=='AUSTRALIA'?(
                                    <div id="australia">
                                      <h4>Australia : </h4>
                                      <p style={{ color: "#212121" }}>
                                        Should you desire to Study in Australia,
                                        you would have to apply for and find an
                                        Australian Student Visa.
                                        <br />
                                        <br />
                                        Software before July 1, 2016, were
                                        approved under distinct subclasses. So,
                                        students which intend to apply to some
                                        of those industries will need to make an
                                        application for Student Visa beneath
                                        Subclass 500 w.e.f. July 1, 2016.
                                        <br />
                                        <br />
                                        Before, the visas were categorized below
                                        Immigration Risk wherein students using
                                        from particular nations were categorized
                                        under Assessment Level Framework. The
                                        new recommendations have a brand new
                                        combined nation and immigration hazard
                                        framework rather than the older
                                        Assessment Level Framework and newspaper
                                        programs have been permanently stopped.
                                        <br />
                                        <br />
                                        The New SSVF categorizes the pupils'
                                        foundation on the two :
                                        <br />
                                        <br />
                                        1. Nationality - 
                                        <br />
                                        Notice, a pupil's nationality is
                                        dependent on the passport. For Example,
                                        a Indian Student remaining in the United
                                        States and applying for a visa in the
                                        USA will be considered below the threat
                                        rates as stated for India from the
                                        Department of Immigration and Border
                                        Protection (DIBP).
                                        <br />
                                        <br />
                                        2. Education Provider's danger of
                                        default -
                                        <br />
                                        A few of the Colleges in Australia are
                                        indicated as compared to others.
                                        <br />
                                        <br />
                                        The table below suggests the
                                        classification of pupils under the new
                                        SSVF principle
                                        <br />
                                        <br />
                                        Nation Immigration Risk
                                        <br />
                                        <br />
                                        One Two Three
                                        <br />
                                        <br />
                                        2 Streamlined Streamlined Regular
                                        <br />
                                        <br />
                                        Three Streamlined Routine Regular
                                        <br />
                                        <br />
                                        DIBP includes a instrument foundation
                                        where a student would be recognized
                                        under the Streamlined/ Routine
                                        procedure. The application would
                                        consequently populate the record of
                                        files, pupils would be asked to furnish.
                                        Pupils are advised to have all of the
                                        files prepared as DIBP reserves the
                                        right to request either or each the
                                        files in the time of this program.
                                        <br />
                                        <br />
                                        <p style={{ color: "#212121", fontWeight:'bold'}}>
                                        Cost of Visa :
                                        </p>
                                        <br />
                                        All online services and applications are
                                        billed in Australian dollars.
                                        <br />
                                        When paying by credit card in Australian
                                        dollars you'll incur banks and currency
                                        conversion charges and you'll have to
                                        take the risk related to almost any
                                        currency changes.
                                        <br />
                                        Numerous Apps / Sectors Eligible for
                                        Student Visa beneath SubClass 500
                                        <br />
                                        <br />
                                        Following is a listing of the several
                                        sectors/ classes that have been united
                                        to collapse beneath the Subclass 500.
                                        <br />
                                        <br />
                                        Independent ELICOS Sector
                                        <br />
                                        <br />
                                        Students may travel to Australia to get
                                        a brief Language language program. The
                                        classes usually vary in duration.
                                        <br />
                                        <br />
                                        That basically covers pupil registered
                                        in either of these classes:
                                        <br />
                                        <br />
                                        VET Diploma
                                        <br />
                                        VET Advanced Diploma
                                        <br />
                                        Vocational Graduate Certificate
                                        <br />
                                        Vocational Graduate Diploma
                                        <br />
                                        <br />
                                        Higher Education Sector
                                        <br />
                                        <br />
                                        Higher Education Sector identifies
                                        pupils that are registered at a
                                        registered full-time higher education
                                        class in a University/ school or
                                        institutes. The next classes are insured
                                        under this kind of visa:
                                        <br />
                                        <br />
                                        Courses in the undergraduate degree in a
                                        University (Bachelor/Associate Degree)
                                        <br />
                                        <br />
                                        Courses to get a postgraduate level in a
                                        college (master's degree by coursework
                                        such as MBA, MS, etc..)
                                        <br />
                                        <br />
                                        Postgraduate classes offering a higher
                                        education degree or advanced diploma
                                        such as PGDBM, etc..
                                        <br />
                                        <br />
                                        Basically, these are classes that extend
                                        beyond a year.
                                        <br />
                                        <br />
                                        <br />
                                        Postgraduate Research Sector
                                        <br />
                                        <br />
                                        This business refers to pupils
                                        registered for
                                        <br />
                                        <br />
                                        Doctoral Level
                                        <br />
                                        <br />
                                        In accordance with the new guidelines in
                                        effect in July 1, 2016, the legitimacy
                                        of this visa will be in agreement with
                                        the length of the course that the
                                        student is registered in.
                                        <br />#
                                        Course Duration
                                        <br />
                                        #Visa Validity
                                        <br />
                                        <br />
                                        Until March 15 of the next year. For
                                        e.g. your path ends December 2018, your
                                        visa will be valid till March 15, 2019.
                                        <br />
                                        <br />
                                        Longer than 10 months however ending
                                        between January and October
                                        <br />
                                        <br />
                                        Your visa will be valid for 2 weeks
                                        longer than the length of your program.
                                        For eg. The course finishes in Feb 2018,
                                        and then your student visa could be
                                        allowed until April 2018.
                                        
                                        <br />
                                        <br />
                                        Your visa will be valid for a month more
                                        than the length of your program.
                                        <br />
                                        <br />
                                        Like in almost any visa, there are a
                                        number of basic conditions that a
                                        student must fulfill to qualify for a
                                        Student Visa for Australia.
                                        <br />
                                        <br />
                                        Full-Time Study
                                        <br />
                                        <br />
                                        Pupils enrolled in a fulltime study
                                        class are only qualified to apply for a
                                        Student Visa beneath Subclass 500.
                                        Part-time and distance education classes
                                        don't qualify.
                                        <br />
                                        <br />
                                        Finding a Australian Student Visa
                                        requires the pupil to have the ability
                                        to verify that he's a Genuine Temporary
                                        Entrant. Basically, that means you ought
                                        to have the ability to convince that you
                                        merely intend to go to Australia for
                                        research and would go back to a home
                                        country at the conclusion of the class
                                        or following a couple of years of
                                        expertise. Your Visa Software is so
                                        evaluated on various factors, namely;
                                        <br />
                                        <br />
                                        Your situation in your home state which
                                        includes your economic conditions.
                                        <br />
                                        <br />
                                        Worth of your chosen path to your future
                                        <br />
                                        <br />
                                        Your immigration history
                                        <br />
                                        <br />
                                        Any other relevant things
                                        <br />
                                        <br />
                                        To be able to be given a student visa
                                        for Australia, then you have to give
                                        evidence that you're in good health. You
                                        can do this by offering a certification
                                        of excellent health. The wellness
                                        checkup and certification can only be
                                        supplied by the impaneled physicians. It
                                        is possible to make an appointment with
                                        the one nearest to you for exactly the
                                        exact same. EMedical Facilities are
                                        offered in India and the documents are
                                        updated instantly. You'd be given with a
                                        HAP identification that you would have
                                        to supply in the time of completing the
                                        form. Additionally, Indian pupils also
                                        will need to offer a Chest X-Ray that
                                        demonstrates you don't suffer with
                                        Tuberculosis because India is regarded
                                        as a high-risk nation.
                                        <br />
                                        <br />
                                        It is possible to submit an application
                                        to get a health checkup before or after
                                        beginning the visa program. But, getting
                                        it ahead would ensure faster processing.
                                        Aside from a wellness checkup, you need
                                        a Health Insurance or a OSHC (Overseas
                                        Students Health Cover). Based upon the
                                        length of your health cover, your visa
                                        tenure will be settled accordingly
                                        ensure it covers the whole period of
                                        your program.
                                        <br />
                                        <br />
                                        <br />
                                        Fantastic Character
                                        <br />
                                        <br />
                                        Pupils have to give evidence of good
                                        character that inevitably suggests you
                                        don't have any criminal case from you
                                        and/ or you've not been deported from
                                        Australia before. Frequently, a simple
                                        declaration of the exact same is
                                        approved on the kind.
                                        <br />
                                        <br />
                                        In accordance with the tool on DIBP's
                                        site, students may determine the files
                                        which would be required. We're supplying
                                        you with all the probable files that may
                                        be necessary under Streamlines and
                                        Routine Evidentiary documents. Bear in
                                        mind, because the program is simply
                                        online, students have to make sure they
                                        have scanned copies of all of the files
                                        listed prepared before they begin their
                                        online program. Additionally, please
                                        keep in mind that the listing of
                                        Frequent Evidentiary Documents is along
                                        with the documents needed for the SSVF
                                        processing.
                                        <br />
                                        <br />
                                        Non-immigrant Visa Program
                                        <br />
                                        <br />
                                        The types are usually issued following
                                        the tuition fees are received.
                                        <br />
                                        Acceptance letter from the host
                                        university, this will comprise the
                                        suggested study plan
                                        <br />
                                        Overseas Medical Insurance reception
                                        (OSHC)
                                        <br />
                                        Recent Digital photos
                                        <br />
                                        Proof of Financial Capacity
                                        <br />
                                        Additionally, you may travel to
                                        Australia for a maximum of 90 days
                                        before the beginning of your program.
                                        <br />
                                        <br />
                                        Beneath the newest SSVF Guidelines. The
                                        Australian Student Visa procedure is
                                        totally online. Here are some steps you
                                        Want to follow :
                                        <br />
                                        <br />
                                        1. To begin an internet program, you'll
                                        have to produce and log in to your
                                        ImmiAccount.
                                        <br />
                                        <br />
                                        2. Applicants must satisfy out the visa
                                        application form corresponding to this
                                        visa subclass below which they are
                                        entitled to apply for. (Education
                                        brokers and Registered Migration Agents
                                        (RMAs) established in India that are
                                        enrolled to get the pupil online
                                        lodgement facility may pay student eVisa
                                        programs on the benefit of their pupil.)
                                        <br />
                                        <br />
                                        3. To monitor your program, you may again
                                        have to login to a ImmiAccount. You may
                                        use your accounts to attach files,
                                        upgrade passport information, change
                                        email, and address information, and
                                        check the progress of your program.
                                        <br />
                                        <br />
                                        4. You'll need this to monitor and manage
                                        your own program, identify if you will
                                        need to communicate with all the visa
                                        centre, and assess your visa
                                        entitlements.
                                        <br />
                                        <br />
                                        <p style={{ color: "#212121", fontWeight:'bold'}}>Dependents :
                                        </p>
                                        The dependents also employ under
                                        SubClass 500 and cover the extra fee
                                        required. You have to declare all
                                        household members in your visa
                                        application at the time of entry, even
                                        if they don't intend to travel with you
                                        to Australia. If you don't do so, in the
                                        future, your family will not qualify for
                                        the grant of hooked visas to join you in
                                        Australia. The spouse of the pupil
                                        receives a Dependent Visa along with a
                                        valid work permit for the exact same
                                        duration as the principal applicant.
                                        This is appropriate only for the
                                        Master's level program.
                                        <br />
                                        <br />
                                        <p style={{ color: "#212121", fontWeight:'bold'}}>What's the minimum IELTS ring Necessary
                                        for the Australian Student Visa?
                                        </p>
                                        There are a whole lot of questions that
                                        come in from students inquiring about
                                        the minimal IELTS band demand for the
                                        Australian Student Visa. Study overseas
                                        aspirants are advised that your IELTS
                                        score doesn't affect your Australia
                                        Student Visa as your IELTS score is a
                                        college requirement.
                                        <br />
                                        <br />
                                        So for entrance to an Australian
                                        University, applicants usually need an
                                        overall band score of 6.5 with no part
                                        band score under 6 for postgraduate
                                        programs as well as an overall band
                                        score of 6.5 with no part band score
                                        under , for undergraduate programs and a
                                        few programs like legislation, lab,
                                        nursing, etc., possess greater IELTS
                                        group requirement. Candidates must note
                                        the IELTS Band score is simply
                                        indicative in nature, the true value
                                        could change based upon your college of
                                        choice. However, your IELTS score on no
                                        account impacts your Australian Student
                                        Visa.
                                        <br />
                                        <br />
                                        The part-time job alternatives for
                                        students going to Australia have stayed
                                        unchanged. The only proviso is that the
                                        pupil can't begin working before the
                                        course starts.
                                        <br />
                                        <br />
                                        <p style={{ color: "#212121", fontWeight:'bold'}}>A Couple of items to remember:
                                        </p>
                                        Students can't work before the session
                                        starts. For example, say your class
                                        begins in January 2019 and you hit
                                        Australia from December 2018, then you
                                        can't take up employment until your
                                        session starts.
                                        <br />
                                        <br />
                                        Back in casework is a standard part of
                                        the program of this class you're
                                        registered for then this limitation
                                        doesn't apply. This is normally
                                        applicable in the event of mandatory
                                        internships of integrated classes.
                                        <br />
                                        <br />
                                        Additionally, students that fall beneath
                                        the Postgraduate Research Sector can get
                                        the job done for unlimited hours once
                                        the study function or doctoral thesis
                                        starts. In addition, in the event there
                                        are some preliminary classes the student
                                        has registered in, the pupil is
                                        qualified to work according to the 40
                                        hours a fortnight principle mentioned
                                        previously.
                                        <br />
                                        <br />
                                        Pupils completing the Australian
                                        analysis requirement may apply to get a
                                        Post-study Work Permit undergraduate
                                        Stream. However, students registered in
                                        an ELICOS Can't use for Post-Study Work
                                        Permit for Australia.
                                        <br />
                                        <br />
                                        <br />
                                      </p>
                                    </div>
                                     ):<></>}
{showData=='FRANCE'?(
                                    <div id="france">
                                      <h4>France: </h4>
                                      <p style={{ color: "#212121" }}>
                                        There Are Various Sorts of student visas
                                        for France:
                                        <br />
                                        This is acceptable for those registering
                                        on a language program or short-term
                                        applications.
                                        <br />
                                        <br /><p style={{ color: "#212121", fontWeight:'bold'}}>Visa de long séjour temporaire pour
                                        etudes (Temporary long-stay visa): </p> 
                                        This
                                        visa lets you research in France for
                                        between five and six weeks with no
                                        necessity to get a residence permit.
                                        It's non-renewable.
                                        <br />
                                        <br />
                                        <p style={{ color: "#212121", fontWeight:'bold'}}>Visa de long séjour etudes (long-stay
                                        visa): </p>
                                        In case you would like to study
                                        for more than six weeks, then you want
                                        to apply for this visa. This sort of
                                        research visa lasts for the length of
                                        your class in France i.e. three years to
                                        get bachelors, two years for masters,
                                        and four years to get a Ph.D.. Long-stay
                                        visas behave as residence permits and
                                        therefore are known as VLT-TS.
                                        <br />
                                        <br />
                                        Submission of Visa program and
                                        assortment of passports could be carried
                                        out exclusively by the applicant or via
                                        a travel agent approved by the Embassy
                                        of France, New Delhi. French Student
                                        Visa includes two different phases of
                                        application. One in the Campus France
                                        along with another in the embassy. The
                                        set of files can differ for both of
                                        these. You'd be asked to submit
                                        originals in Campus France and
                                        Photo-copies in the embassy/ consulate/
                                        / Visa Application Centres.
                                        <br />
                                        <br />
                                        <p style={{ color: "#212121", fontWeight:'bold'}}>Cost of Visa :</p>
                                      
                                        For double course applicants in addition
                                        to foreign exchange students, the fee is
                                        exactly the same. This amount has to be
                                        paid in the closest BNP Paribas Branch.
                                        You are able to find the branch out of
                                        this internet tool. As soon as you get
                                        to the branch, then you would have to
                                        carry you are IN Number.
                                        0906506003400137. We advise you to talk
                                        to the closest branch prior to going
                                        there to make the payment.
                                        <br />
                                        <br />
                                        Aside from this cost, you would also be
                                        asked to cover the Visa Processing cost
                                        of $50 that is currently at INR 4338
                                        also as the VFS Service prices and
                                        handling charges that sum to INR 1467
                                        (inclusive of taxes). Additionally, an
                                        optional courier center can be found at
                                        Rs 300 (inclusive of service tax) each
                                        program.
                                        <br />
                                        <br />
                                        Note: it's always a good idea to consult
                                        the embassy/ consulate before you submit
                                        your program concerning the Visa Fee as
                                        exchange rates are subject to change.
                                        <br />
                                        <br />
                                        You need to supply two sets of program
                                        photocopies comprising the following
                                        files:
                                        
                                        <li type='square'>Extended remain application form for the
                                        duration of over 90 days</li>
                                        <li type='square'>Two passport size photos</li>
                                        <li type='square'>A print from your CampusFrance NOC</li>
                                        <li type='square'>A letter of enrollment in the academic
                                        Institution</li>
                                        <li type='square'>Cover letter describing the research
                                        project and a CV/Resume</li>
                                        <li type='square'>Evidence of funds - lodging prices + at
                                        a minimum of 615 Euros/month, for your
                                        complete academic term</li>
                                        <li type='square'>Evidence of medical insurance with
                                        policy valid in France</li>
                                        <li type='square'>Copy your round-trip airline ticket (if
                                        You're studying for six weeks or not )
                                        or a one-way ticket If You're studying
                                        in France for more than six weeks )
                                        </li>
                                        <li type='square'>Evidence of residency in France like a
                                        rental, power bill, name of land, etc.
                                        for a minimum of 3 months or even a
                                        hotel reservation</li>
                                        <li type='square'>Copy of this CV</li>
                                        <li type='square'>Copies of this academic and degree
                                        diplomas, certificates</li>
                                        <br />
                                        <br />
                                        When you go to your interview, you'd be
                                        asked to carry all of your files in the
                                        original and a copy of all of the
                                        documents.
                                        <br />
                                        <br />
                                        <p style={{ color: "#212121", fontWeight:'bold'}}>The Way to apply :
                                        </p>
                                        <li type='square'>There are lots of actions to applying
                                        for a visa. First, You Have to look for
                                        a meeting with Campus France, and then
                                        You Have to submit your files with VFS</li>
                                        <li type='square'>In the"Register online" box click Create
                                        Your Account.</li>
                                        <li type='square'>Fill the Academic documents, Language
                                        abilities, and CV+SOP sections. Upload
                                        files, picture, and academic documents.
                                        Make sure that all areas in the
                                        Validation tab signify"Entire".</li>
                                        <li type='square'>Send your document number to the
                                        delegated Campus France office to
                                        receive your file examined, and also to
                                        ask an appointment.</li>
                                        <br />
                                        You'll need to appear in person to your
                                        academic meeting with the Campus France
                                        adviser. You also need to carry all of
                                        the compulsory documents plus one pair
                                        of photocopies together with the Campus
                                        France charge payment receipt.
                                        <br />
                                        <br />
                                        You are going to get an Interview
                                        completion certificate at the close of
                                        the interview.
                                        <br />
                                        <br />
                                        Following the academic meeting, schedule
                                        a consultation using VFS-France, to
                                        publish your records, photocopies,
                                        receipts, and passport.
                                        <br />
                                        <br />
                                        Notice: The Embassy of France, New Delhi
                                        has employed a change in agreements for
                                        its group of this processed passport out
                                        of visa program centres. With effect
                                        from 8th December 2014, it'll no more be
                                        possible for third parties to gather the
                                        files on behalf of an offender, but in
                                        the following instances:
                                        <li type='square'>1 member of a team amassing for the
                                        Whole group.</li>
                                        <li type='square'>Officials if approved on the Govt.
                                        Letterhead or department.</li>
                                        <li type='square'>An agent of a business - Authorization
                                        letter to the letterhead of the business
                                        and the official ID card of the agent.</li>
                                        <li type='square'>You May Be interested in these free
                                        downloads.</li>
                                        <br />
                                        <br />
                                        <p style={{ color: "#212121", fontWeight:'bold'}}>Dependents
                                        </p>
                                        Spouses and children may be allowed to
                                        live in France while you research, but
                                        that is conditional. These circumstances
                                        include having sufficient and proper
                                        funding and living area. Cases are
                                        decided on an individual basis in the
                                        France embassy, and particular
                                        consideration could be given to a
                                        situation if you can describe your
                                        position to the consulate efficiently.
                                        <br />
                                        <br />
                                        In the event that you were approved by a
                                        French University for a Bachelor's
                                        degree (that will be known as
                                        a"Licence"), your partner wouldn't be
                                        provided a visa to accompany one or be
                                        allowed to operate in France unless is a
                                        European citizen. The situation may be
                                        different for research or Accreditation
                                        students. They might be permitted to
                                        take along their partner or children,
                                        but that depends upon different rules
                                        and terms.
                                        <br />
                                        <br /><p style={{ color: "#212121", fontWeight:'bold'}}>
                                        Additional Details :
                                        </p>
                                        French law makes it possible for pupils
                                        to perform 964 hours of part-time job in
                                        a specific calendar year. International
                                        students will also be eligible for
                                        student jobs . Pupils are hired to give
                                        assistance to incoming students,
                                        assisting handicapped students,
                                        tutoring, IT support and help, working
                                        at the career centre, etc..
                                        <br />
                                        <br />
                                        Student workers should stick to a work
                                        schedule which won't interfere with
                                        their research. The terms and conditions
                                        of employment are accommodated to the
                                        needs of every student's schedule in
                                        order to guarantee academic achievement
                                        whilst supplying work experience.
                                        <br />
                                        <br /><p style={{ color: "#212121", fontWeight:'bold'}}>
                                        Work visa
                                        </p>
                                        It's valid for 24 weeks. Students who
                                        obtain a job related to their academic
                                        program can enter fulltime job by
                                        submitting a petition for change of
                                        status (from student to worker ) within
                                        their program.
                                        <br />
                                        <br />
                                        <p style={{ color: "#212121", fontWeight:'bold'}}>Scholarships for studying at France
                                        </p>
                                        France is thought to be among the very
                                        sought after destinations for pursuing
                                        higher education by international
                                        students. Moreover, those seeking to get
                                        knowledge and increase their abilities
                                        through quality instruction favor this
                                        global education hub. But, living costs
                                        and research in France could be an
                                        expensive affair, so, various
                                        fellowships are offered to aid students
                                        in completing their schooling. We've
                                        listed out high scholarships available
                                        to study in France.
                                        <br />
                                        <br />
                                      </p>
                                    </div>
                                     ):<></>}

{showData=='IRELAND'?(
                                    <div id="ireland">
                                      <h4>Ireland:</h4>
                                      <p style={{ color: "#212121" }}>
                                        The language of instruction employed in
                                        the universities is English and global
                                        students may gain from the relatively
                                        lower tuition costs and living expenses
                                        when compared with United Kingdom.
                                        <br />
                                        <br />
                                        International students wanting to study
                                        in Ireland and belonging into
                                        non-European nations are asked to submit
                                        an application for a Irish Student Visa.
                                        Just after being approved by the Irish
                                        college, may a student use for the
                                        Ireland analysis (student) visa, because
                                        their approval letter will be necessary
                                        through the visa application procedure.
                                        So Indian pupils will need to get a
                                        student visa to get into Ireland for the
                                        purpose of schooling. Keep reading to
                                        understand the full procedure for
                                        requesting an Irish student visa.
                                        <br />
                                        <br />
                                        Broadly speaking, students are able to
                                        apply to your' C Research Visa' or'D
                                        Study Visa.'
                                        <br />
                                        <br />
                                        <p style={{ color: "#212121", fontWeight:'bold' }}>Ireland'C Research Visa - </p> Ireland'C Research Visa is for
                                        research programs/courses which are for
                                        a entire length of 3 months or not. It's
                                        exceedingly improbable that candidates
                                        are permitted to extend this kind of
                                        visa, therefore, they ought to submit an
                                        application for a visa which covers
                                        their whole length of stay in a foreign
                                        nation.
                                        <br />
                                        <br />
                                        <p style={{ color: "#212121", fontWeight:'bold' }}>Ireland'D Study Visa - </p> Ireland'D Study Visa is for research
                                        programs/courses that extend more than
                                        three weeks. The visa fees are the exact
                                        same for the brief remain C Visa.
                                        <br />
                                        <br />
                                        <p style={{ color: "#212121", fontWeight:'bold' }}>Implementing for the Ireland Student
                                        Visa-</p>
                                        Here would be the program guidelines to
                                        your Ireland Study Visa:
                                        <br />
                                        <br />
                                        We strongly advise you to not affirm
                                        your travel arrangements till you have
                                        received your visa. As stated before,
                                        candidates are asked to pay a visit to
                                        the official site of the Irish
                                        Naturalisation and Immigration Service
                                        to finish their online student visa
                                        program.
                                        <br />
                                        Visa applicants will have to complete
                                        the online application form, submit
                                        supporting files, and follow directions
                                        on the summary application type produced
                                        by the computer system. The outline
                                        application sheet may also include your
                                        program transaction amount. This is
                                        vital, as you may need it afterwards.
                                        <br />
                                        <br />
                                        The outline program sheet will say if
                                        you're required to be current in the
                                        Visa Application Centre (VAC) for
                                        supplying your personal data and then
                                        submit your own documents.
                                        <br />
                                        Candidates will be subsequently required
                                        to cover the Ireland Visa Program fee.
                                        <br />
                                        As soon as you've completed the payment
                                        and appointment booking procedure, the
                                        payment receipt along with a
                                        consultation confirmation receipt will
                                        be delivered to your email address. You
                                        have to publish the receipts and bring
                                        them to your appointment.
                                        <br /><br />
                                        Note: Applicants should avoid supplying
                                        false or misleading data, as this could
                                        result in the immediate conclusion of
                                        your visa program. In some specific
                                        scenarios, the candidate may be
                                        prohibited from attractive to the visa
                                        choice for a period of five decades.
                                        <br />
                                        <br />
                                        <li type='square'>Two color passport-sized photos more
                                        than 6 weeks old (refer to the web site
                                        of the Irish Naturalisation and
                                        Immigration Service for photo
                                        specifications) your present passport
                                        along with a complete copy of previous
                                        passports.</li>
                                        <li type='square'>A signed letter of application such as
                                        your entire contact information.</li>
                                        <li type='square'>Proof which you're registered on a
                                        privately financed class.</li>
                                        <li type='square'>Proof accounting for almost any gaps in
                                        your educational background.</li>
                                        <li type='square'>Proof fees are paid into the faculty.</li>
                                        <li type='square'>Proof that you have the academic ability
                                        to trace your favorite course.</li>
                                        <li type='square'>Proof of your level of English
                                        (frequently in the shape of a TOEFL or
                                        IELTS or PTE evaluation score).</li>
                                        <li type='square'>Proof that you've got enough capital to
                                        support yourself while studying in
                                        Ireland which comprises, applicants
                                        should show evidence that they have
                                        immediate access to $7,000. They need to
                                        also demonstrate they or their host (s)
                                        have ready access to $7,000 for every
                                        subsequent year of the research along
                                        with course fees for all these years. In
                                        case the length of this program is less
                                        than 6 weeks then the applicant should
                                        get access to $500 a month in the stay
                                        or 3000 whichever is the lower.</li>
                                        <li type='square'>Private Medical Insurance.</li>
                                        <li type='square'>Past Visa Refusals.</li>
                                        <li type='square'>An arrangement saying that the offender
                                        plans to go back to their home country
                                        upon completing their route in Ireland.</li>
                                        <li type='square'>Added documentation for unaccompanied
                                        pupils under 18 years.</li>
                                        <li type='square'>The supporting documents which you
                                        submit are significant because They
                                        Supply Information Regarding your
                                        personal situation.</li>
                                        <li type='square'>Bring your files with you.</li>
                                        <br />
                                        If you arrive in the visa application
                                        centre you may collect a token. Wait
                                        till your token is known as to create
                                        your own application. When the visa
                                        filing method is completed you'll be
                                        issued using an acknowledgment
                                        reception.
                                        <br />
                                        <br />
                                      </p>
                                    </div>

):<></>}
{showData=='GERMANY'?(
                                    <div id="germany">
                                      <h4>Germany:</h4>
                                      <p style={{ color: "#212121" }}>
                                        To remain in Germany for over 90 days as
                                        a student, you have to find a residence
                                        permit. You'll have to prove to the visa
                                        officer that you have financing for a
                                        year. The sum will be $853 per month or
                                        $10,236 per annum (approx) stored at a
                                        Blocked Account. A residence permit
                                        issued to pupils is legal for a maximum
                                        of 2 decades. It has to be revived in
                                        the event the length of the program is
                                        more than the previous two decades.
                                        <br />
                                        <br />
                                        Researching in Germany: All you Want to
                                        understand.
                                        <p style={{ color: "#212121", fontWeight:'bold' }}>
                                        Price of Germany Student Visa :</p>

                                        Germany Student Visa would cost you
                                        about $75. The processing period could
                                        take around 25 times if all of your
                                        files are in order. A whole lot of times
                                        applicants have to offer more/additional
                                        documents. For such scenarios, the
                                        processing period for your Germany
                                        Student Visa can go around 12 weeks.
                                        Candidates seeking to apply for a
                                        Residence Permit on arrival to Germany
                                        will have to pay around $110. It may
                                        have a very long time to be issued,
                                        which can be anywhere between 8 to 12
                                        weeks. Since this roughly equates to 3
                                        weeks, international students are
                                        advised to apply as soon as you can.

                                        <p style={{ color: "#212121", fontWeight:'bold' }}>
                                        Documents necessary to Employ Germany
                                        Student (Study) Visa</ p>
                                        You are going to need to apply for your
                                        visa in person. Visa costs amount to $75
                                        (to be compensated in INR).
                                        Additionally, you need to supply two
                                        sets of program documents comprising
                                        these files:
                                        <br />
                                        <br />
                                        <li type='square'>Valid passport</li>
                                        <li type='square'>Copy of the passport's data page (A4
                                        size copy)</li>
                                        <li type='square'>Application form</li>
                                        <li type='square'>A statement saying that the information
                                        provided is accurate and complete info.</li>
                                        <li type='square'>Cover Letter clarifies the reason behind
                                        the visa application. You could have to
                                        submit a German translation of the
                                        document.</li>
                                        <li type='square'>Letter of Admission in the German
                                        University</li>
                                        <li type='square'>University Certificates and transcripts</li>
                                        <li type='square'>Degree certificates and mark sheets</li>
                                        <li type='square'>Evidence of Language Proficiency (German
                                        language test scores). Applicants for
                                        Ph.D. research and Erasmus Mundus
                                        Scholarship holders are exempt from this
                                        principle. Proof of capital</li>
                                        <li type='square'>3 passport images based on biometric
                                        specifications, not older than 6 weeks</li>
                                        <li type='square'>Demand Draft for your Student Visa Fee</li>
                                        <li type='square'>The best way to employ</li>
                                        <li type='square'>Candidates have to adhere to the
                                        subsequent German Visa program steps
                                        while applying to their German research
                                        Visa.</li>
                                        <li type='square'>The moment you've obtained the admission
                                        letter in your German college, please
                                        prepare the essential documentation to
                                        your visa program mentioned previously.</li>
                                        <li type='square'>Procure photographs that fulfill
                                        biometric photographs requirements as
                                        stated on the official site. Print it
                                        out, sign it, and apply it together with
                                        all the other files.</li>
                                        <li type='square'>Please print the Declaration on
                                        authentic and complete info also located
                                        on the official site, and register it.</li>
                                        <li type='square'>Schedule a consultation to your visa
                                        appointment in the Mission.</li>
                                        <li type='square'>Soon before your appointment, confirm
                                        that the current exchange rate to the
                                        own visa commission on the official
                                        German Mission site and acquire the
                                        Demand Draft.
                                        </li>
                                        <br />
                                        <p style={{ color: "#212121", fontWeight:'bold' }}>
                                        Dependents </p>
                                        Spouses and children may be allowed to
                                        live in Germany while you research, but
                                        that is conditional. These circumstances
                                        include having sufficient and proper
                                        funding and living area. Cases are
                                        decided on an individual basis in the
                                        embassy, and particular consideration
                                        could be given to a situation if you can
                                        describe your position to the Consulate
                                        efficiently.
                                        <br />
                                        <br />
                                        <p style={{ color: "#212121", fontWeight:'bold' }}>
                                        Additional Info </p>
                                        Based on German visa guidelines, a pupil
                                        can work 180 times halftime or even 90
                                        days full time in an academic year.
                                        Currently, Germany is the only state
                                        where a pupil can perform a part-time
                                        technical occupation from the associated
                                        industry. Some universities also offer
                                        you part-time tasks on campus named
                                        Hiwi. Pupils might need to get hold of
                                        the institute's pupil placement cell to
                                        enter this job.
                                        <br />
                                        <br />
                                        <p style={{ color: "#212121", fontWeight:'bold' }}>
                                        Where to employ</p>
                                        German Embassy, New Delhi
                                        <br />
                                        No. 6/50G, Shanti Path, Chanakyapuri,
                                        New Delhi 110021
                                        <br />
                                        <br />
                                        German Consulate General at Bengaluru
                                        <br />
                                        2nd & 3rd floor,"Cash Pharmacy Building"
                                        <br />
                                        Tel: +91-(0) 33-2479 1142/ 2439 8<br />
                                        Visa Hotline: +91-(0) 33-2479 1141
                                        <br />
                                        <br />
                                        Mon-Thu: 09:00-10:00 h and 13:00-14:00 h,
                                        <br />
                                        Fri: 09:00-10:00h
                                        <br />
                                        <br />
                                        German Consulate General in Mumbai
                                        <br />
                                        Hoechst House, 10th Floor, 193 Backbay
                                        Reclamation,
                                        <br />
                                        Nariman Point,
                                        <br />
                                        Mumbai 400 021
                                        <br />
                                        <br />
                                        Visa and Consular Department
                                        <br />
                                        Arcadia Building, Ground Floor,
                                        <br />
                                        NCPA Marg, 195 Nariman Point,
                                        <br />
                                        Mumbai 400 021
                                        <br />
                                        Tel: +91 222283 0301/2283 9834 - 35
                                        <br />
                                        <br />
                                      </p>
                                    </div>
                                    ):<></>}
                                  </div>
                                </div>
                              
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div className="col-md-12">
                                    <div
                                      className="content"
                                      style={{ paddingBottom: 0 }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area default-padding">
        <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="row" style={{ display: "block" }}>
            <div className="about-items">
              <div className="col-md-12">
                <div className="weekly-top-items">
                  <div className="top-courses" style={{ paddingRight: 0 }}>
                    <div className="heading" style={{ paddingBottom: 15 }}>
                      <h4 style={{ color: "#3f8ccb" }}>
                        ACCREDITATIONS AND MEMBERSHIPS <strong></strong>
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/EAIE.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/ICEF.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/NAFSA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/QISAN.jpeg"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIRC.gif"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIEA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
