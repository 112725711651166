/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import icon from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Header from './Header';
import Footer from './Footer';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';



const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
newbtn:{
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
},

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:12,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },

widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function Compare(props) {
  const classes = useStyles();
 


  return (
   <Container className={classes.widths}>
   <Header/>  <CssBaseline />
    <div className="login-area default-padding" style={{paddingTop:20,}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">
<div className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard" style={{backgroundImage: "url(" + "../assets/img/banner/6.jpg" + ")",padding:70}}>             

        <div className="container">
            <div className="row">
            <div className="double-items col-md-12 info">
                <h2 className="wow fadeInLeft">Germany</h2>
                </div>
             
            </div>
        </div>
    </div>


    <section class="video_sec blog_wrap">
<div class="container">
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <div class="blog_box faq">
                <h2>Frequently Asked Questions?</h2>
                <div class=" blog_box_content">
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is there a deadline for applying?</h3>
                        <p style={{color:'#333'}}>A: Yes, there are deadlines for applying for admissions May is the deadline for October intake, June for September intake, and December for March intake.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: IS GRE/GMAT or IELTS/TOEFL is required for admission?</h3>
                        <p style={{color:'#333'}}>A: Few universities require IELTS/ TOFEL score for admissions if you are going for the Free Education there is no requirement of IELTS/ TOFEL score for admissions but it is required at the time of the Visa interview.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much Time it will take to get the offer letter?</h3>
                        <p style={{color:'#333'}}>A: The issue of offer letter depends on the universities as few universities issue the offer letter within 2 weeks and few universities take a long time to issue the offer.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can i work on campus?</h3>
                        <p style={{color:'#333'}}>A: Yes, you can work both on-campus or off campus.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long will it take for me to obtain my internship employment?</h3>
                        <p style={{color:'#333'}}>A: The universities assist you in finding the internship jobs where you can find your employment in 1 or 2 months maximum.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q:  What type of employment will I get Initially?</h3>
                        <p style={{color:'#333'}}>A: You can get your employment into your concerned field of study program with the entry level jobs.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Must I find an internship job only in the field related to my major or is any job O.K.?</h3>
                        <p style={{color:'#333'}}>A: We recommend you to find internship jobs into the concerned field of your study program as internships are meant for international exposure and practical experience. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How may working hours will I probably work each week?</h3>
                        <p style={{color:'#333'}}>A: 20hrs a week a student can work legally.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I have to work overtime? Can I keep the overtime bonus?</h3>
                        <p style={{color:'#333'}}>A: No.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What if I can't find employment?</h3>
                        <p style={{color:'#333'}}>A: You can initially find a part time job till you find a suitable internship job into your profile.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Will I have to pay taxes?</h3>
                        <p style={{color:'#333'}}>A: No.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is there a way for me to work before actually starting to attend classes in order for me to save more money and add it to my current savings?</h3>
                        <p style={{color:'#333'}}>A: No.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Could the work authorization be possibly converted into a full time work permit upon completion of the masters program?</h3>
                        <p style={{color:'#333'}}>A: Yes, after the study program you can get post study work visa for 1.5 yrs duration.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much is the bank statement required for applying VISA?</h3>
                        <p style={{color:'#333'}}>A: You will have to show a bank statement of 8040 Euros i.e 13 to 14 lacs.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long I have to keep amount in my bank account for VISA?</h3>
                        <p style={{color:'#333'}}>A: 15days Old bank statement is required </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much tuition fee I have to pay before and after getting VISA/Offer letter?</h3>
                        <p style={{color:'#333'}}>A: You will have to pay the first term fees before visa interview and pay rest of the fees after visa. The fee should be fully paid before the student is flying.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can one apply for Dependent VISA?</h3>
                        <p style={{color:'#333'}}>A: Yes, Preferably Dependents are allowed to fly only after 3 months.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How can apply as a dependent VISA?</h3>
                        <p style={{color:'#333'}}>A: The additional fund of 8000 Euros has to be shown in the bank statement.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is required in order to bring my spouse and family?</h3>
                        <p style={{color:'#333'}}>A: You require showing additional funds to support your living expenses. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my spouse get the wok permit there?</h3>
                        <p style={{color:'#333'}}>A: Yes</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much duration work permit will spouse get there?</h3>
                        <p style={{color:'#333'}}>A: It usually depends on the type of visa may be part time or full time Work Permit.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I apply work permit after completing my studies?</h3>
                        <p style={{color:'#333'}}>A: Yes it depends on the Job Agreement.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much time will I get after my studies to search job there?</h3>
                        <p style={{color:'#333'}}>A: You are not entitled for work as you’re going to Germany on study program.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my spouse extend the work permit?</h3>
                        <p style={{color:'#333'}}>A: No</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my accompanying dependent attend school?</h3>
                        <p style={{color:'#333'}}>A: No.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is IELTS required for dependent?</h3>
                        <p style={{color:'#333'}}>A: No.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Will University secure job for me?</h3>
                        <p style={{color:'#333'}}>A: No.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Why Should I study in xyz Company?</h3>
                        <p style={{color:'#333'}}>A: Low Tuition Fees.
•	State funded universities.
•	Paid Part time internship jobs.
•	Assured internships for eligible students
•	English: Medium of instruction
•	Bologna process - Pinnacle in higher education reform
•	Cultural diversity
•	Leading Technical Universities
•	Top class Industry-University collaboration
•	Unlimited research opportunities
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much money do I need for my living expenses in Germany?</h3>
                        <p style={{color:'#333'}}>A: Compared to other European countries, the cost of living in Germany is quite reasonable. The prices for food, accommodation, clothing, cultural events, etc. are basically in line with the EU average. You will need around 670 euros per month to cover your living expenses. The largest expense is your monthly rent. In most cases, applicants have to prove that they have around 8,700 euros at their disposal for one year.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How high are the tuition fees at German universities?</h3>
                        <p style={{color:'#333'}}>A: The tuition at German universities is very low, and some universities charge no tuition fee at all. Most German universities are funded by the government. Normally students in bachelor’s programmes don’t have to pay tuition fees. Low tuition fees are charged, however, for master’s degree programmes. All students have to pay a semester contribution. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q:  How do I get a visa to study in Germany?</h3>
                        <p style={{color:'#333'}}>A: Some international students require an entry visa for Germany depending on where they come from and how long they plan to stay. You will find general information on visas and residence permits on our website. For more information about visa requirements, contact the German embassy or German consulate in your home country.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: I'm looking for the best university in my area of study. Where can I find it?</h3>
                        <p style={{color:'#333'}}>A: As all German universities offer very good education, it’s hard to say which university is the best. The best university for you is the one which best meets your expectations. When choosing a university, you should take several aspects into account, like the range of subjects, the size of the university and city, as well as the cost of living in that city. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: I want to study in Germany. What do I have to do?</h3>
                        <p style={{color:'#333'}}>A: The application procedure depends on which subject you would like to study and where you come from.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: I am currently pursuing a bachelor’s degree. I want to know whether you can get into a master’s programme at a German university with a three-year bachelor’s degree.</h3>
                        <p style={{color:'#333'}}>A: Unfortunately we cannot provide information about admission criteria because each German university is responsible for determining its admission policies. Nevertheless, you can check out the requirements of the programmes you wish to apply to on their website</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is a “Studienkolleg” (foundation course)?</h3>
                        <p style={{color:'#333'}}>A: If your school-leaving certificate is not recognised in Germany, you can still study at a German university by participating in a “Studienkolleg” (foundation course). Good German language skills are required. To gain admission to a foundation course, you must first pass an entrance examination. Participation in the course is usually free of charge. Foundation courses take one year to complete and conclude with the “Feststellungsprüfung” (university qualification assessment examination). </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I enrol in an English-language degree programme in Germany?</h3>
                        <p style={{color:'#333'}}>A: Yes, there are many courses and degree programmes taught in English, especially master’s courses. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How do I get a visa for a language course?</h3>
                        <p style={{color:'#333'}}>A: If you want to attend a language course, you can apply for a language course visa in many countries. It’s only valid for the duration of the course and cannot be converted into a student visa (“Visum zu Studienzwecken”). If you want to attend a language course in combination with university studies, you have to indicate this on your visa application form. In this case, make sure to apply for either a student applicant visa (“Visum zur Studienbewerbung”) or a student visa (“Visum zu Studienzwecken”).</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How and where can I get a language certificate?</h3>
                        <p style={{color:'#333'}}>A: There are two different tests you can take to demonstrate your language proficiency in German. One is called the “DSH” (German Language University Entrance Examination for Foreign Applicants) and the other is the “TestDaF” (Test of German as a Foreign Language). Language certificates are also issued by the Goethe-Institute.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I need to take an English test to apply for admission to a degree programme?</h3>
                        <p style={{color:'#333'}}>A: If you want to study in English, universities generally require an official English test such as IELTS or TOEFL. Exemptions are made for native speakers.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are my chances of finding a job after my studies in Germany?</h3>
                        <p style={{color:'#333'}}>A: With a degree from a German university, you have numerous job opportunities on the German labour market</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: I'm searching for master’s degree programmes in Dentistry. Where can I find them?</h3>
                        <p style={{color:'#333'}}>A: There are no master’s degree programmes in Dentistry in Germany because Dentistry is not split into bachelor’s and master’s degrees. Dentistry programmes usually takes six years to complete and conclude with what we call the “State Examination” (Staatsexamen).</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: I am interested in doing a postgraduate medical course in Germany. How should I apply to the university? Are there any specific requirements like German language skills?</h3>
                        <p style={{color:'#333'}}>A: There are no postgraduate courses for Medicine in Germany because Medicine is not split into bachelor’s and master’s degrees. Medical students in Germany have to complete a six-year programme which concludes with the State Examination (Staatsexamen). There are no courses offered in English, so you will need very good German skills to complete the programme and pass the examinations. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: I graduated and I have two years of work experience as a general physician. I would like to do my postgrad work in Germany. How do I know if my degree is recognised in Germany?</h3>
                        <p style={{color:'#333'}}>A: Like all special areas of medicine, Internal Medicine is not offered as a course of study at German universities. Candidates must complete a period of medical specialisation training. To be eligible for admission, you will need very good German skills and usually a degree similar to the German State Examination (six years of university study).</p>
                    </div>
                                   
                </div>
                
            </div>

        </div>
    </div>

</div>

</section>



    <div className="advisor-details-area default-padding" style={{padding:0,}}>
   
   </div>



</div>
</div>
</div>
</div>

<Footer/>
    </Container>
  );
}
