/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-concat */
import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import Header from "./Header";
import Footer from "./Footer";


const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    // margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function CourseDiscription(props) {
  const classes = useStyles();


  // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <div className="login-area default-padding" style={{ paddingTop: 20 }}>
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 70,
                }}
              >
                {/* <div className="banner-area inc-form bg-gradient slider-less text-light" style={{backgroundImage: "url(" + "../assets/img/event/1.jpg" + ")", padding:70}}> */}
                <div className="container">
                  <div className="row">
                    {/* <div className="double-items">
                    <div className="col-md-7 info">
                        <h2 className="wow fadeInLeft">Find your ideal study program.</h2>
                        <a className="btn day btn-light border btn-md wow fadeInDown" style={{color:'#3f8ccb'}} href="#">View Courses</a>
                    </div>
                    <div className="col-md-5 reg-form">
                        <form action="#">
                            <div className="row">
<img style={{flex:1}} class="login_image" src="assets/img/elearning.png" alt="Login" data-original-title="" title="" />

                            </div>
                        </form>
                    </div>
                </div> */}
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">
                        Courses that might interest you
                      </h2>
                    </div>
                    <div
                      class="col-lg-12"
                      style={{
                        padding: 15,
                        backgroundColor: "#FFF",
                        borderRadius: 50,
                        marginTop: 25,
                      }}
                    >
                      <div class="col-lg-10">
                        {/* <InputLabel for="browser" style={{fontSize:15,}}>What do you want to Study </InputLabel>
        <Select >
   <MenuItem  value="hello" style={{fontSize:13,}}>PhD</MenuItem>
   <MenuItem  value={20} style={{fontSize:13,}}>MCA</MenuItem>
   <MenuItem  value={30} style={{fontSize:13,}}>MSC</MenuItem>
</Select> */}

                        <TextField
                          label="Find your Course here..."
                          fullWidth
                          InputLabelProps={{ style: { fontSize: 13 } }}
                        />
                      </div>

                      <div
                        class="col-lg-2"
                        style={{ padding: 0, textAlign: "center" }}
                      >
                        {/* <MDBBtn color="yellow" rounded variant="contained" style={{fontSize:16,borderRadius:50,color:'#FFF',}}  className="btn-block z-depth-1a" fullWidth>
        Search  <i class="fa fa-search"></i>
        
        </MDBBtn> */}
                        <div className="blog-area">
                          <div className="info">
                            <div className="content" style={{ padding: 0 }}>
                              <a href="#">
                                <i className="fas fa-search"></i> Search
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="about-area default-padding">
                <div className="container">
                  <div className="row">
                    <div className="about-items">
                      <div className="col-md-12 about-info">
                        <h2>
                          Top Courses <span>to Study Abroad in 2021</span>
                        </h2>

                        <p
                          style={{
                            fontSize: 14,
                            color: "#212121",
                            textAlign: "justify",
                          }}
                        >
                          There are plenty of options available for Masters.
                          They can be specific towards one subject or even be
                          interdisciplinary. This is because of the open
                          approach to education in many major countries.
                        </p>
                        <p
                          style={{
                            fontSize: 14,
                            color: "#212121",
                            textAlign: "justify",
                          }}
                        >
                          While Technology related courses such as Computer
                          Science, Computer Engineering, Data Science, etc.
                          remain the most popular MS options in the USA and
                          Canada, Management courses rank a close second. MBA
                          being the most sought after management course is
                          growing slower as compared to more open study options
                          such as MS in Finance.
                        </p>
                        <p
                          style={{
                            fontSize: 14,
                            color: "#212121",
                            textAlign: "justify",
                          }}
                        >
                          Europe remains popular for students studying
                          Philosophy and Arts. However, it is rapidly picking up
                          interest from students from technical and management
                          fields as well.
                        </p>
                        <p
                          style={{
                            fontSize: 14,
                            color: "#212121",
                            textAlign: "justify",
                          }}
                        >
                          These trends keep changing every year and the best way
                          to find out what you really want to pursue your higher
                          education in is by doing a thorough research. Below is
                          a list of Graduate courses available. Click on the
                          links to know in detail about their course
                          descriptions and related specialisations. We have
                          included a list of Best Universities in the world for
                          every course along with the possible Job Opportunities
                          and salaries.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Container>
  );
}
