/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { getData } from "../FetchServices";

export default function Header() {
  const [, setTechnology] = React.useState([]);

  const readAllTechnology = async () => {
    let result = await getData("technology/displayall");
    // console.log(result);
    setTechnology(result);
  };

  return (
    <>
      <div>
        <footer
          class="bgdark text-light"
          style={{ backgroundColor: "#3f8ccb", color: "white", fontSize: 13 }}
        >
          <div class="footer-bottom">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
               
                  <ul class="brand_list">
                    <li>
                        <img width="150" height="150" src="assets/img/StudyMetroMobileApp.png" class="img-responsive" style={{width: "150px"}} />
                    </li>
                    <li>
                        <a href="https://play.google.com/store/apps/details?id=com.app.studymetro3534" title="Get It On Google Play Store" target="_blank">
                        <img width="157" height="57" src="assets/img/google_play_btn.png" class="img-responsive" /></a>
                      </li>
                      <li>
                        <a href="https://apps.apple.com/us/app/studymetro/id1582036437" title="Get It On Apple iTunes" target="_blank"><img width="157" height="57" src="assets/img/appstore_btn.png" class="img-responsive" /></a>
                      </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="f-items default-padding">
              <div class="row" style={{ display: "block" }}>
                <div class="col-md-2 col-sm-6 item equal-height">
                  <div class="f-item link">
                    <h4>Universities</h4>
                    <ul>
                      <li>
                        <a href="/Compare">
                          <i class="ti-angle-right"></i> Compare universities{" "}
                        </a>
                      </li>
                      <li>
                        <a target="_blank"  href="https://partner.studymetro.com/s/account/Account/00B2x000007f0clEAA">
                          <i class="ti-angle-right"></i> University Finder
                        </a>
                      </li>
                      <li>
                        <a target="_blank"  href="https://partner.studymetro.com/s/course-session/Course_Session__c/00B2x000006IXEjEAO">
                          <i class="ti-angle-right"></i> Course Finder{" "}
                        </a>
                      </li>
                      <li>
                        <a target="_blank"  href="https://partner.studymetro.com/s/account/Account/00B2x000007f0clEAA?Account-filterId=00B2x000007egRfEAI">
                          <i class="ti-angle-right"></i> Scholarships
                        </a>
                      </li>
                       <li>
                        <a target="_blank"  href="https://partner.studymetro.com/s/course-session/Course_Session__c/00B2x000006IXEjEAO?Course_Session__c-filterId=00B2x000007ei0HEAQ">
                          <i class="ti-angle-right"></i> Online programs{" "}
                        </a>
                      </li>
                      <li>
                        <a href="/Visa">
                          <i class="ti-angle-right"></i>Country VISA
                        </a>
                      </li>
                      <li>
                        <a href="/Intake">
                          <i class="ti-angle-right"></i> Intake
                        </a>
                      </li>
                      <li>
                        <a href="/FAQ">
                          <i class="ti-angle-right"></i> FAQ's
                        </a>
                      </li>
                      <li>
                        <a href="https://www.usajobs.gov/Search/Results?hp=student">
                          <i class="ti-angle-right"></i> Internships
                        </a>
                      </li>
                      <li>
                        <a href="https://www.apprenticeship.gov/">
                          <i class="ti-angle-right"></i> Apprenticeship
                        </a>
                      </li>
                      {/* <li>
                        <a href="#">
                          <i class="ti-angle-right"></i> Short Programs
                        </a>
                      </li> */}
                      {/* <li>
                        <a target="_blank"  href="#">
                          <i class="ti-angle-right"></i> TRENDING Universities
                        </a>
                      </li> */}
                      {/* <li>
                        <a target="_blank"  href="#">
                          <i class="ti-angle-right"></i> Ranking
                        </a>
                      </li> */}
                      <li>
                        <a target="_blank"  href="https://studymetro.org/isef-2021">
                          <i class="ti-angle-right"></i> Education Fair
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a href="https://www.usajobs.gov">
                          <i class="ti-angle-right"></i> USA Jobs
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a href="https://www.bls.gov/ooh/">
                          <i class="ti-angle-right"></i> ROI
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a href="https://studymetro.org/oeif-registration-students">
                          <i class="ti-angle-right"></i> OIEF
                        </a>
                      </li>
                      {/* <li>
                        {" "}
                        <a href="/CourseDiscription">
                          <i class="ti-angle-right"></i> Course Discription
                        </a>
                      </li> */}
                      <li>
                        {" "}
                        <a href="/TestPrep">
                          <i class="ti-angle-right"></i> Test Prep
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-2 col-sm-6 item equal-height">
                  <div class="f-item link">
                    <h4>Student</h4>
                    <ul>
                      <li>
                        <a href="/ProfileBuilder">
                          <i class="ti-angle-right"></i> Profile Builder
                        </a>
                      </li>
                      {/* <li>
                        {" "}
                        <a href="#">
                          <i class="ti-angle-right"></i> Study Material
                        </a>
                      </li> */}
                      <li>
                        {" "}
                        <a target="_blank"  href="https://studymetro.typeform.com/to/Xt1gKP">
                          <i class="ti-angle-right"></i> Free Profile Assessment
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a target="_blank"  href="https://studymetro.org/oief-university-event-itinerary">
                          <i class="ti-angle-right"></i> University Fee Waiver
                          Code
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a target="_blank"  href="https://studymetro.co.in/online-payment">
                          <i class="ti-angle-right"></i> Pay Online
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a href="/CreditTransfer">
                          <i class="ti-angle-right"></i> Credit Transfer
                        </a>
                      </li>
                      {/* <li>
                        {" "}
                        <a target="_blank"  href="/Login">
                          <i class="ti-angle-right"></i> Application
                        </a>
                      </li> */}
                      <li>
                        {" "}
                        <a href="/SimilarProfile">
                          <i class="ti-angle-right"></i> Similar profiles
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a href="/Majors">
                          <i class="ti-angle-right"></i> Majors
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a href="/Advising">
                          <i class="ti-angle-right"></i> Advising
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a target="_blank"  href="https://studymetro.blog/">
                          <i class="ti-angle-right"></i> Blogs
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a href="/Webinar">
                          <i class="ti-angle-right"></i> Events & Webinars{" "}
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a href="/pricing">
                          <i class="ti-angle-right"></i> Pricing
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a href="/CourseGuide">
                          <i class="ti-angle-right"></i> Course Guide
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a target="_blank"  href="https://www.gotostage.com/channel/48d69eace2b442bf8ef9413b6c9b22af">
                          <i class="ti-angle-right"></i> Online Demand Webinar
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a target="_blank"  href="https://studymetro.org/student-enrolment">
                          <i class="ti-angle-right"></i> Register with SM
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a target="_blank"  href="https://studymetro.org/oief-university">
                          <i class="ti-angle-right"></i> OIEF
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-2 col-sm-6 item equal-height">
                  <div class="f-item link">
                    <h4>Services</h4>
                    <ul>
                      <li>
                        <a href="/Adminssion">
                          <i class="ti-angle-right"></i> Students
                        </a>
                      </li>
                      <li>
                        <a href="/Selectuniversity">
                          <i class="ti-angle-right"></i> Universities
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a href="/Forex">
                          <i class="ti-angle-right"></i> Forex
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a href="/Appostile">
                          <i class="ti-angle-right"></i> Appostile
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a href="/Courier">
                          <i class="ti-angle-right"></i> Courier
                        </a>
                      </li>
                      <li>
                        <a href="/UGCScholarships">
                          <i class="ti-angle-right"></i> UGC Scholarships
                        </a>
                      </li>
                      <li>
                        <a href="/InternationalSIM">
                          <i class="ti-angle-right"></i> International SIM
                        </a>
                      </li>
                      {/* <li>
                        {" "}
                        <a href="/Visa">
                          <i class="ti-angle-right"></i> Visa
                        </a>
                      </li> */}
                      <li>
                        <a href="/Traval">
                          <i class="ti-angle-right"></i> Travel Insurance
                        </a>
                      </li>
                      <li>
                        <a target="_blank" 
                          href="https://studymetrohousing.4stay.com/"
                          target="blank"
                        >
                          <i class="ti-angle-right"></i> Housing / Accommodation
                        </a>
                      </li>
                      {/* <li> <a target="_blank"  href="#"><i class="ti-angle-right"></i> Test Prep</a></li> */}
                      <li>
                        {" "}
                        <a href="/Career">
                          <i class="ti-angle-right"></i> Career
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a target="_blank"  href="https://studymetro.org/oief-university-event-itinerary">
                          <i class="ti-angle-right"></i> Fee waiver code
                        </a>
                      </li>
                      {/* <li><a target="_blank"  href="/ProfileBuilder"><i class="ti-angle-right"></i> Profile Builder</a></li>
                      <li> <a target="_blank"  href="/CreditTransfer"><i class="ti-angle-right"></i> Credit Transfer</a></li>

                      <li> <a target="_blank"  href="https://studymetro.typeform.com/to/Xt1gKP"><i class="ti-angle-right"></i> Free Profile Assessment</a></li>
                      <li> <a target="_blank"  href="https://www.usajobs.gov"><i class="ti-angle-right"></i> USA Jobs</a></li>
                      <li> <a target="_blank"  href="https://www.bls.gov/ooh/"><i class="ti-angle-right"></i> ROI</a></li>
                      <li> <a target="_blank"  href="https://studymetro.org/oief-3"><i class="ti-angle-right"></i> OIEF</a></li>
                      <li> <a target="_blank"  href="http://page.studymetro.com/Franchise-Inquiry-Form"><i class="ti-angle-right"></i> Apply for Franchisee</a></li>
                      <li> <a target="_blank"  href="https://www.studymetro.com/front/registrationProcess"><i class="ti-angle-right"></i> Become a Counsellors</a></li> */}
                    </ul>

                    {/* <div class="f-item about" style={{marginTop:50,}}>
                              <h4>Get Socials</h4>
                              <div class="social">
                            <a target="_blank"  href="#"><i style={{fontSize:20,margin:10,}} class="fab fa-facebook-f"></i></a>
                            <a target="_blank"  href="#"><i style={{fontSize:20,margin:10,}} class="fab fa-twitter"></i></a>
                            <a target="_blank"  href="#"><i style={{fontSize:20,margin:10,}} class="fab fa-linkedin-in"></i></a>
                            <a target="_blank"  href="#"><i style={{fontSize:20,margin:10,}} class="fab fa-youtube"></i></a>
                            </div></div> */}
                  </div>
                </div>
                <div class="col-md-2 col-sm-6 item equal-height">
                  <div class="f-item link">
                    <h4>About</h4>
                    <ul>
                      {/* <li> <a target="_blank"  href="#"><i class="ti-angle-right"></i> Vision and</a></li> */}
                      <li>
                        <a href="/WhyChooses">
                          <i class="ti-angle-right"></i> Why Choose Us
                        </a>
                      </li>
                      <li>
                        <a href="/aboutus">
                          <i class="ti-angle-right"></i> About us
                        </a>
                      </li>
                      <li>
                        <a href="https://studymetro.org/partnership-model" target="_blank">
                          <i class="ti-angle-right"></i> Business Model
                        </a>
                      </li>
                      <li>
                        <a href="../assets/Study-Metro-SaaS-Based-Company-Profile-Confidential.pdf" target="_blank">
                          <i class="ti-angle-right"></i> Company Profile Download
                        </a>
                      </li>
                      <li>
                        <a href="/Careercounseling">
                          <i class="ti-angle-right"></i> Counseling
                        </a>
                      </li>
                      <li>
                        <a href="/PressMedia">
                          <i class="ti-angle-right"></i> Press Media
                        </a>
                      </li>
                      <li>
                        <a href="/NewPaper">
                          <i class="ti-angle-right"></i> News Paper
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-2 col-sm-6 item equal-height">
                  <div class="f-item link">
                    <h4>Partner</h4>
                    <ul>
                      <li>
                        {" "}
                        <a target="_blank"  href="https://studymetro.typeform.com/to/XSReTgjx">
                          <i class="ti-angle-right"></i>Work as Agent
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a target="_blank"  href="https://studymetro.org/franchise-with-us">
                          <i class="ti-angle-right"></i>Apply for Franchisee
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a href="/AdvertiseWithUs">
                          <i class="ti-angle-right"></i> Advertise with us
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a href="/StudyAbroadInsight">
                          <i class="ti-angle-right"></i> Study Abroad Insight
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a href="/Affiliates">
                          <i class="ti-angle-right"></i> Affiliates
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a href="/StudyAbroadAPI">
                          <i class="ti-angle-right"></i> Study Abroad APIs
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a href="https://studymetro.org/sm-widget" target="_blank">
                          <i class="ti-angle-right"></i> Search Widget
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a href="https://studymetro.org/client-portal1" target="_blank">
                          <i class="ti-angle-right"></i> White-label Client Portal
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a target="_blank"  href="https://partner.studymetro.com/s/">
                          <i class="ti-angle-right"></i> Log in to Partner
                          Portal
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a target="_blank"  href="https://studymetro.typeform.com/to/rgqxCjdp">
                          <i class="ti-angle-right"></i> Become a Counsellors
                        </a>
                      </li>
                    </ul>

                    <div class="f-item link" style={{ marginTop: 30 }}>
                      <h4>Explore</h4>
                      <ul>
                        <li>
                          <a href="/Countries">
                            <i class="ti-angle-right"></i> Countries
                          </a>
                        </li>
                        <li>
                          <a href="https://partner.studymetro.com/s/account/Account/00B2x000007f0clEAA" target="_blank">
                            <i class="ti-angle-right"></i> Universities
                          </a>
                        </li>
                        {/* <li>
                          <a href="#">
                            <i class="ti-angle-right"></i> Programs
                          </a>
                        </li> */}
                        <li>
                          <a href="/accreditations">
                            <i class="ti-angle-right"></i> ACCREDITATIONS AND
                            MEMBERSHIPS
                          </a>
                        </li>
                        <li>
                          <a href="/EducationLoans">
                            <i class="ti-angle-right"></i> Education Loans
                          </a>
                        </li>
                        <li>
                          <a href="/LoanProvider">
                            <i class="ti-angle-right"></i> Loan Providers
                          </a>
                        </li>
                        <li>
                          <a href="/LoanDocuments">
                            <i class="ti-angle-right"></i> Loan Documents
                          </a>
                        </li>
                        <li>
                          <a href="/PartnerBanks">
                            <i class="ti-angle-right"></i> Partner Banks
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-6 item equal-height">
                  <div class="f-item link">
                    <h4>Company</h4>
                    <ul>
                      <li>
                        <a href="/CookiePolicy">
                          <i class="ti-angle-right"></i> Cookie policy{" "}
                        </a>
                      </li>
                      <li>
                        <a href="/Privacy">
                          <i class="ti-angle-right"></i> Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a href="/TermsCondition">
                          <i class="ti-angle-right"></i> Term & Conditions
                        </a>
                      </li>
                      <li>
                        <a href="/Careercounseling">
                          <i class="ti-angle-right"></i> Our Counsellors
                        </a>
                      </li>
                      <li>
                        <a href="/NewPaper">
                          <i class="ti-angle-right"></i> Study Abroad News
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="f-item link">
                    <h4>Contact US</h4>
                    <ul>
                      <li>
                        <a href="/contact-us">
                          <i class="ti-angle-right"></i> Contact us
                        </a>
                      </li>
                      <li>
                        <a target="_blank"  href="https://www.youtube.com/channel/UC_EjChebrWmyKsId3cMt-Rw">
                          <i class="ti-angle-right"></i> Youtube channel
                        </a>
                      </li>
                      <li>
                        <a target="_blank"  href="https://calendly.com/studymetro/students">
                          <i class="ti-angle-right"></i> Schedule Meeting with
                          us
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="footer-bottom">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
               
                  <p style={{ textAlign: "center" }}>
                    &copy; Copyright 2020. Designed by{" "}
                    <a target="_blank"  href="https://www.plus91labs.com"> Plus91labs</a>
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </footer>
       {" "}
        <CssBaseline />
      </div>
    </>
  );
}
