/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-concat */
/* eslint-disable default-case */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {postDataAndImage,postData, getData} from '../FetchServices';
import BaseUrl from '../BaseUrl'
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Autocomplete from "@material-ui/lab/Autocomplete";
import GraduatePrograms from "./GraduatePrograms";
import UndergraduatePrograms from "./UndergraduatePrograms";
import CertificateDiploma from "./CertificateDiploma";
import Doctoral from "./Doctoral";
import Header from "./Header";
// import Header from "./Header2";
import Footer from "./Footer";
// import Slider from "react-slick";
import CardCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';


import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [messages, setMessages] = React.useState("");
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus, setStatus] = React.useState("");
  const [countryName, setCountryName] = React.useState([])
  const [uniName, setUniName] = React.useState([])
  const [programName, setProgramName] = React.useState([])
  const [getList, setList]=React.useState([]);
  const [getuniversity, setUniversity]=React.useState([])
  const [allProgramList,setAllProgramList]=useState([])

  
  var settings ={
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};


  const fetchAllUniversity = async () => {
    var result = await postData('applyApplication/displayuniversity')
    setUniversity(result.data)
  }

  const fetchAllCountryName=async()=>{
    var result=await getData("universities/DisplayCountry")
    console.log("=====>>>>>>",result)
    setCountryName(result)
  }

  React.useEffect(function(){
    fetchAllCountryName()
    fetchAllUniversity()
  },[])

  const fillcountryName=()=>{
      return countryName.map((item)=>{
          return(
              <MenuItem value={item.country}>{item.country}</MenuItem>
          )
      })
  }

  const handlePicture = (event) => {
    setPicturePath(URL.createObjectURL(event.target.files[0]));
    setPicture(event.target.files[0]);
  };

  const handleVerifyPassword = (e) => {
    setVerifyPassword(e.target.value);
    if (password === e.target.value) {
      setStatus("Password Matched");
    } else {
      setStatus("Password not matched");
    }
  };

  // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
  const top100Films = [
    { title: "Phd", year: 1994 },
    { title: "MCA", year: 1972 },
    { title: "MSC", year: 1974 },
  ];

  //  EXPLORE PROGRAMS IN ABROAD Fetch Service //
  const [getClick, setClick] = useState("");

  const handleUnderGraduate = async () => {
    //  const body={'course_type':getClick}
    props.history.push({ pathname: "/UndergraduatePrograms" });
  };

  const handleGraduatePrograms = async () => {
    //  const body={'course_type':getClick}
    props.history.push({ pathname: `/GraduatePrograms` });
  };

  const handleDoctoral = async () => {
    // const body={'course_type':getClick}
    props.history.push({ pathname: `/Doctoral` });
  };

  const handleCertificateDiploma = async () => {
    // const body={'course_type':getClick}
    props.history.push({ pathname: `/CertificateDiploma` });
  };

  const handleClick = async (opt) => {
    switch (opt) {
      case 0:
        // {handleUnderGraduate()}
        props.history.push({
          
          pathname: "/UndergraduatePrograms",
          state: { option: opt },
        });
        break;
      case 1:
        //   {handleGraduatePrograms()}
        props.history.push({
          pathname: "/GraduatePrograms",
          state: { option: opt },
        });
        break;
      case 2:
        //   {handleDoctoral()}
        props.history.push({ pathname: "/Doctoral", state: { option: opt } });
        break;
      case 3:
        //   {handleCertificateDiploma()}
        props.history.push({
          pathname: "/CertificateDiploma",
          state: { option: opt },
        });
        break;
    }
  };

  const handleUniversities = (opt) => {
    console.log(opt);
    props.history.push({
      pathname: "/UniversityDisplay",
      state: { option: opt },
    });
  };

  const handleClickUnivesity = (id) => {
    props.history.push({
   pathname: "/UniversityDisplay",
     state: {
       id: id,
     }
   });
};

  const handleCountries = (opt) => {
    props.history.push({
      pathname: "/SearchUniversity",
      state: { option: opt },
    });
  };

  // const [getId,setId]=useState('')
  // const [getid,setid]=useState('')
  const [getName, setName]=useState('')

    const handleShowData=(e,id)=>{
      
      setName(id.name)
      console.log("========>>>>>>>>>>>>ID",e.target.value,id)
      
    }

 const [cName,setcName]=React.useState();

   const handleFetchUniversity=async(event,Id)=>{
    // console.log("Event...............",e)
    console.log("val...............",Id.country)
    // setcName(val.country) 
    let body={
       cname:Id.country
     }
     let res = await postData('universities/fetchuniversity',body)
     setUniName(res)
    //  console.log("Result Aagya...",res);
   }

   const [pName,setpName]=React.useState(); 
   const [getProgramId,setProgramId] = React.useState();

   const handleFetchProgramId=async(event,Id)=>{
    // console.log("Event...............",e)
    console.log("Id...............>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",Id.id)
    // setcName(val.country) 

    setProgramId(Id.id)
    // console.log("or kuch Aagya...",res);
   }


   const handleFetchProgram=async(event,Id)=>{
    // console.log("Event...............",e)
    // console.log("val...............",Id)
    // setcName(val.country) 
    let body={
       pname:Id.name
     }
     let res = await postData('abroadcourses/fetchprogram',body)
     setProgramName(res)
    // console.log("or kuch Aagya...",res);
   }

   const handleCourse = (id) => {
    // alert("hello next Page");
    console.log('id>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>',id)
      props.history.push({
        pathname: "/DisplayCourses",
        state: {
          id: id,
         
        }
      });
    // console.log(event.currentTarget);
  };

  const handleClickSearch = async (id) => {
    props.history.push({
      pathname: "/ApplyScholarship",
      state: {
        u_id: id,
      },
    });
  };

  const showSlider=()=>{
    return(
      getuniversity.map((item)=>{
        return(
            <div>
                <img src= {item.image}/>
               
            </div>
            
        )
        })
    )
}


  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Helmet>
        <html lang="en" />
        <title>Abroad Study Overseas Education Consultant - Study Metro</title>
        <meta name="description" content="We are leading overseas education consultant. Want to study abroad? Find employment in the USA! Or, study and work in Canada!" />
        <meta name="theme-color" content="#000000" />
      </Helmet>
      <Header />

      <CssBaseline />
      <div className="login-area default-padding" style={{ paddingTop: 0 }}>
        <div className="">
          <div className="row">
            <div className="col-md-12">
              {/*
                <div
                  className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                  style={{
                    backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                    padding: 70,
                  }}
                >
                
                <div className="container">
                  <div className="row">
  
                    <div className="double-items col-md-11 info">
                      <h2 className="wow fadeInLeft">
                        Find your ideal study program
                      </h2>
                    </div>
                    <div
                      class="col-lg-11"
                      style={{
                        padding: 15,
                        backgroundColor: "#FFF",
                        borderRadius: 50,
                        marginTop: 25,
                        marginLeft:60,
                      }}
                    >
                      <div class="col-lg-3">
                        
                        <Autocomplete
                          style={{marginLeft:30}}
                          options={countryName}
                          value={countryName.country}
                          getOptionLabel={(option) => (option.country)}
                          onChange={(event,Id)=>handleFetchUniversity(event,Id)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // label="What do you want to Study"
                              label="Choose a country"
                              fullWidth
                              InputLabelProps={{ style: { fontSize: 13} }}
                            />
                          )}
                        />
                      </div>

                      <div class="col-lg-3">
                        
                        <Autocomplete
                          // options={top100Films}
                          options={uniName}
                          // value={uniName.name}
                          getOptionLabel={(option) => option.name}
                          onChange={(event,Id)=>handleFetchProgram(event,Id)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // label="What do you want to Study"
                              label="Choose a Institute"
                              fullWidth
                              InputLabelProps={{ style: { fontSize: 13 } }}
                            />
                          )}
                        />
                      </div>

                      <div class="col-lg-3">
                        <Autocomplete
                          // options={top100Films}
                          options={programName}
                          getOptionLabel={(option) => option.name}
                          onChange={(event,Id)=>handleFetchProgramId(event,Id)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // label="What do you want to Study"
                              label="Choose a Program"
                              fullWidth
                              InputLabelProps={{ style: { fontSize: 13 } }}
                            />
                          )}
                        />
                      </div>

                      <div
                        class="col-lg-2"
                        style={{ padding: 0, textAlign: "center" }}
                      >
                       
                        <div className="blog-area">
                          <div className="info">
                            <div className="content" style={{ padding: 0, marginLeft:25}}>

                              <a onClick={() => handleCourse(getProgramId)}>
                                <i className="fas fa-search"></i> Search
                              </a>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              */}

              <div className="about-area default-padding">
                <div className="container">
                  <div className="row">
                    <div className="about-items">
                      <div className="col-md-6 about-info">
                        <h2>
                          Welcome ! <span>Study Metro</span>
                        </h2>
                        <blockquote style={{ fontSize: 16, lineHeight: "1" }}>
                          A Study Metro platform empowers Universities and Students. One Stop Solutions for students to Study Abroad - Partner can apply directly to 12,000+ Universities and 5000K Abroad Programs.
                        </blockquote>
                        
                        <ul style={{ listStyle: 'disc', fontSize: 16}}>
                          <li>Students and Universities interact with each other where they can coordinate with most of them in one single platform.</li>
                          <li>Discuss the scholarships, Admission requirements with university representatives.</li>
                          <li>Students can connect directly with service providers such as Trainers, Education loan providers, Writers, Forex, and Travel agents</li>
                          <li>B2B agents can work with us and get the highest commissions.</li>
                          <li>Information Center for Parents, who are the decision-maker of their children’s further education, to communicate and interact with University officials.</li>
                          <li>Student's profiles sharing with university to onboard for their UG or Grad programs with highest scholarships. </li>
                          <li>Virtual events either webinars or one-to-one calls with students.</li>
                        </ul>
                      </div>
                      <div className="col-md-6 features text-light">
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/ysYihAAjAJk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <section class="membership_plan explore_sec">
                <div class="container">
                  <div class="row">
                    <div class="col-sm-12">
                      <div className="weekly-top-items">
                        <div className="col-md-12">
                          <div
                            className="top-courses"
                            style={{ paddingRight: 0 }}
                          >
                            <div
                              className="heading"
                              style={{ paddingBottom: 15 }}
                            >
                              <h4>
                                EXPLORE PROGRAMS IN ABROAD<strong></strong>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="features-area default-padding bottom-less shape-radius">
                        <div class="container">
                          <div class="row">
                            <div class="features text-center">
                              <div
                                class="equal-height col-md-3"
                                style={{ height: 304 }}
                              >
                                <div class="item mariner">
                                  <a 
                                    href=""
                                    // onClick={() => handleClick(0)}
                                    style={{ backgroundColor: "#3a448d" }}
                                  >
                                    <div class="icon">
                                      <icon
                                        class="fas fa-book-open"
                                        style={{
                                          color: "white",
                                          fontSize: "4rem",
                                          marginBottom: 10,
                                        }}
                                      ></icon>
                                    </div>
                                    <div class="info">
                                      <h5
                                        style={{
                                          fontSize: 20,
                                          fontWeight: "bold",
                                          color: "white",
                                        }}
                                      >
                                        Undergraduate Programs
                                      </h5>
                                    
                                      <div className="blog-area">
                                        <div className="info">
                                          <div
                                            className="content"
                                            style={{ padding: 0 }}
                                          >
                                            <a 
                                              href="https://partner.studymetro.com/s/course-session/Course_Session__c/00B2x000006IXEjEAO?Course_Session__c-filterId=00B2x000007ehFuEAI&t=1630996791170"
                                              style={{
                                                padding: "2px 10px",
                                                fontSize: 12,
                                              }}
                                              target="_blank"
                                            >
                                              {" "}
                                              Explore More
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div
                                class="equal-height col-md-3"
                                style={{ height: 304 }}
                              >
                                <div class="item brilliantrose">
                                  <a
                                    href=""
                                    // onClick={() => handleClick(1)}
                                    style={{ backgroundColor: "#e948ae" }}
                                  >
                                    <div class="icon">
                                      <icon
                                        class="fas fa-user-graduate"
                                        style={{
                                          color: "white",
                                          fontSize: "4rem",
                                          marginBottom: 10,
                                        }}
                                      ></icon>
                                    </div>
                                    <div class="info">
                                      <h5
                                        style={{
                                          fontSize: 20,
                                          fontWeight: "bold",
                                          color: "white",
                                        }}
                                      >
                                        {" "}
                                        Graduate Programs
                                      </h5>
                                   
                                      <div className="blog-area">
                                        <div className="info">
                                          <div
                                            className="content"
                                            style={{ padding: 0 }}
                                          >
                                            <a 
                                              href="https://partner.studymetro.com/s/course-session/Course_Session__c/00B2x000006IXEjEAO?Course_Session__c-filterId=00B2x000007egr9EAA&t=1630996791170"
                                              style={{
                                                padding: "2px 10px",
                                                fontSize: 12,
                                              }}
                                              target="_blank"
                                            >
                                              {" "}
                                              Explore More
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div
                                class="equal-height col-md-3"
                                style={{ height: 304 }}
                              >
                                <div class="item brilliantrose">
                                  <a
                                    href=""
                                    // onClick={() => handleClick(2)}
                                    style={{ backgroundColor: "#ff6d24" }}
                                  >
                                    <div class="icon">
                                      <icon
                                        class="fa fa-graduation-cap"
                                        style={{
                                          color: "white",
                                          fontSize: "4rem",
                                          marginBottom: 10,
                                        }}
                                      ></icon>
                                    </div>
                                    <div class="info">
                                      <h5
                                        style={{
                                          fontSize: 20,
                                          fontWeight: "bold",
                                          color: "white",
                                        }}
                                      >
                                        Doctoral Programs
                                      </h5>
                                     
                                      <div className="blog-area">
                                        <div className="info">
                                          <div
                                            className="content"
                                            style={{ padding: 0 }}
                                          >
                                            <a 
                                              href="https://partner.studymetro.com/s/course-session/Course_Session__c/00B2x000006IXEjEAO?Course_Session__c-filterId=00B2x000007ehNtEAI&t=1630996791170"
                                              style={{
                                                padding: "2px 10px",
                                                fontSize: 12,
                                              }}
                                              target="_blank"
                                            >
                                              {" "}
                                              Explore More
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div
                                class="equal-height col-md-3"
                                style={{ height: 304 }}
                              >
                                <div class="item casablanca">
                                  <a
                                    href=""
                                    // onClick={() => handleClick(3)}
                                    style={{ backgroundColor: "#3d83cd" }}
                                  >
                                    <div class="icon">
                                      <icon
                                        class="fas fa-file-alt"
                                        style={{
                                          color: "white",
                                          fontSize: "4rem",
                                          marginBottom: 10,
                                        }}
                                      ></icon>
                                    </div>
                                    <div class="info">
                                      <h5
                                        style={{
                                          fontSize: 20,
                                          fontWeight: "bold",
                                          color: "white",
                                        }}
                                      >
                                        Certificate/Diploma Programs
                                      </h5>
                                      {/* <p>
                                        attention say frankness intention out dashwoods now curiosity. Stronger ecstatic as no judgment daughter.
                                    </p> */}
                                      <div className="blog-area">
                                        <div className="info">
                                          <div
                                            className="content"
                                            style={{ padding: 0 }}
                                          >
                                            <a 
                                              href="https://partner.studymetro.com/s/course-session/Course_Session__c/00B2x000006IXEjEAO?Course_Session__c-filterId=00B2x000007egRVEAY&t=1630996791170" 
                                              style={{
                                                padding: "2px 10px",
                                                fontSize: 12,
                                              }}
                                              target="_blank"
                                            >
                                              {" "}
                                              Explore More
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>


              <div
                class="features-area default-padding-bottom fixed bottom-less bg-color text-light"
                style={{ marginTop: "50" }}
              >
                <div class="container">
                  <div className="weekly-top-items">
                    <div className="col-md-12">
                      <div className="top-courses" style={{ paddingRight: 0 }}>
                        <div className="heading" style={{ paddingBottom: 15 }}>
                          <h4 style={{ color: "#3f8ccb" }}>
                            STUDY ABROAD PROGRAMS & UNIVERSITY <strong></strong>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <div class="features">
                      <div
                        class="equal-height col-md-3 col-sm-6"
                        style={{ height: 330 }}
                      >
                        <div class="item mariner">
                          <a  href="#" onClick={() => handleUniversities(0)}>
                            <div class="icon">
                              <icon
                                style={{
                                  color: "white",
                                  fontSize: "4rem",
                                  marginBottom: 10,
                                }}
                                class="fa fa-university"
                              ></icon>
                            </div>
                            <div class="info">
                              <h1>12,000</h1>
                              <h4>Universities</h4>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div
                        class="equal-height col-md-3 col-sm-6"
                        style={{ height: 330 }}
                      >
                        <div class="item brilliantrose">
                          <a  href="#">
                            <div class="icon">
                              <icon
                                style={{
                                  color: "white",
                                  fontSize: "4rem",
                                  marginBottom: 10,
                                }}
                                class="fa fa-graduation-cap"
                              ></icon>
                            </div>
                            <div class="info">
                              <h1>5000000</h1>
                              <h4>Programs</h4>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div
                        class="equal-height col-md-3 col-sm-6"
                        style={{ height: 330 }}
                      >
                        <div class="item casablanca">
                          <a  href="#" onClick={() => handleCountries(1)}>
                            <div class="icon">
                              <icon
                                style={{
                                  color: "white",
                                  fontSize: "4rem",
                                  marginBottom: 10,
                                }}
                                class="fa fa-flag"
                              ></icon>
                            </div>
                            <div class="info">
                              <h1>22</h1>
                              <h4>Countries</h4>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div
                        class="equal-height col-md-3 col-sm-6"
                        style={{ height: 330 }}
                      >
                        <div class="item malachite">
                          <a  href="#">
                            <div class="icon">
                              <icon
                                style={{
                                  color: "white",
                                  fontSize: "4rem",
                                  marginBottom: 10,
                                }}
                                class="fa fa-users"
                              ></icon>
                            </div>
                            <div class="info">
                              <h1>28052</h1>
                              <h4>Students</h4>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="weekly-top-items carousel-shadow default-padding-bottom">
                <div className="container">
                  <div className="row" style={{ display: "block" }}>
                    <div className="col-md-12">
                      <div className="top-courses">
                        <div className="heading">
                          <h4>
                            Trending Programs <strong></strong>
                          </h4>
                        </div>
                        <div className="top-course-items top-courses-carousel owl-carousel owl-theme">
                          <div className="item">
                            <div className="thumb">
                              <span className="badge">New</span>
                              <img src="assets/img/courses/1.jpg" alt="Thumb" />
                              <div className="overlay">
                                <a href="#">
                                  <img
                                    src="assets/img/team/1.jpg"
                                    alt="Thumb"
                                  />
                                </a>
                                <ul>
                                  <li>
                                    <i className="fas fa-clock"></i> 04:15:38
                                  </li>
                                  <li>
                                    <i className="fas fa-list-ul"></i> 18
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="info">
                              <div className="meta">
                                <ul>
                                  <li>
                                    <a href="#">Education</a>
                                    <a href="#">Tech</a>
                                  </li>
                                  <li>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star-half-alt"></i>
                                    <span>(1k)</span>
                                  </li>
                                </ul>
                              </div>
                              <h4>
                                <a href="#">data science and software</a>
                              </h4>
                              <p>
                                Wisdom praise things she before. Be mother
                                itself vanity favour do me of. Begin sex was
                                power joy after had walls miles.
                              </p>
                              <div className="footer-meta">
                                <div className="blog-area">
                                  <div className="info">
                                    <div
                                      className="content"
                                      style={{ padding: 0 }}
                                    >
                                      <a
                                        href="https://partner.studymetro.com/s/course-session/Course_Session__c/00B2x000006IXEjEAO"
                                        style={{
                                          padding: "10px 30px",
                                          color: "#000",
                                        }}
                                        target="_blank"
                                      >
                                        <i className="fa fa-bookmark"></i> Book
                                        Now
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> 

                           <div className="item">
                            <div className="thumb">
                              <img src="assets/img/courses/2.jpg" alt="Thumb" />
                              <div className="overlay">
                                <a href="#">
                                  <img
                                    src="assets/img/team/2.jpg"
                                    alt="Thumb"
                                  />
                                </a>
                                <ul>
                                  <li>
                                    <i className="fas fa-clock"></i> 08:27:00
                                  </li>
                                  <li>
                                    <i className="fas fa-list-ul"></i> 65
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="info">
                              <div className="meta">
                                <ul>
                                  <li>
                                    <a href="#">Illustration</a>
                                    <a href="#">Design</a>
                                  </li>
                                  <li>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <span>(2k)</span>
                                  </li>
                                </ul>
                              </div>
                              <h4>
                                <a href="#">Social Science & Humanities</a>
                              </h4>
                              <p>
                                Wisdom praise things she before. Be mother
                                itself vanity favour do me of. Begin sex was
                                power joy after had walls miles.
                              </p>
                              <div className="footer-meta">
                                <div className="blog-area">
                                  <div className="info">
                                    <div
                                      className="content"
                                      style={{ padding: 0 }}
                                    >
                                      <a
                                        href="https://partner.studymetro.com/s/course-session/Course_Session__c/00B2x000006IXEjEAO"
                                        style={{
                                          padding: "10px 30px",
                                          color: "#000",
                                        }}
                                        target="_blank"
                                      >
                                        <i className="fa fa-bookmark"></i> Book
                                        Now
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="item">
                            <div className="thumb">
                              <span className="badge">Best Seller</span>
                              <img src="assets/img/courses/6.jpg" alt="Thumb" />
                              <div className="overlay">
                                <a href="#">
                                  <img
                                    src="assets/img/team/3.jpg"
                                    alt="Thumb"
                                  />
                                </a>
                                <ul>
                                  <li>
                                    <i className="fas fa-clock"></i> 04:15:38
                                  </li>
                                  <li>
                                    <i className="fas fa-list-ul"></i> 32
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="info">
                              <div className="meta">
                                <ul>
                                  <li>
                                    <a href="#">Science</a>
                                    <a href="#">Tech</a>
                                  </li>
                                  <li>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <span>(4.6k)</span>
                                  </li>
                                </ul>
                              </div>
                              <h4>
                                <a href="#">Programming MasterclassName</a>
                              </h4>
                              <p>
                                Wisdom praise things she before. Be mother
                                itself vanity favour do me of. Begin sex was
                                power joy after had walls miles.
                              </p>
                              <div className="footer-meta">
                                <div className="blog-area">
                                  <div className="info">
                                    <div
                                      className="content"
                                      style={{ padding: 0 }}
                                    >
                                      <a
                                        href="https://partner.studymetro.com/s/course-session/Course_Session__c/00B2x000006IXEjEAO"
                                        style={{
                                          padding: "10px 30px",
                                          color: "#000",
                                        }}
                                        target="_blank"
                                      >
                                        <i className="fa fa-bookmark"></i> Book
                                        Now
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
              </div>
                
              <div className="weekly-top-items carousel-shadow default-padding-bottom">
                <div className="container">
                  <div className="row" style={{ display: "block" }}>
                    <div className="col-md-12">
                      <div className="top-courses">
                        <div className="heading">
                          <h4>
                            Trending Universities <strong></strong>
                          </h4>
                        </div>

                        <div className="top-course-items top-courses-carousel owl-carousel owl-theme">
                          <div className="item"> 
                            <div className="thumb">
                                 <span className="badge">New</span>
                              <img src="assets/img/courses/4.jpg" alt="Thumb" />
                              <div className="overlay">
                                <a href="#">
                                  <img
                                    src="assets/img/team/4.jpg"
                                    alt="Thumb"
                                  />
                                </a>
                                <ul>
                                  <li>
                                    <i className="fas fa-clock"></i> 04:15:38
                                  </li>
                                  <li>
                                    <i className="fas fa-list-ul"></i> 18
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="info">
                              <div className="meta">
                                <ul>
                                  <li>
                                    <a href="#">Education</a>
                                    <a href="#">Tech</a>
                                  </li>
                                  <li>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star-half-alt"></i>
                                    <span>(1k)</span>
                                  </li>
                                </ul>
                              </div>
                              <h4>
                                <a href="#">Lewis University</a>
                              </h4>
                             
                              <p>University Pkwy, Romeoville, U.S.USA...</p>
                              <div className="footer-meta">
                                <div className="blog-area">
                                  <div className="info">
                                    <div
                                      className="content"
                                      style={{ padding: 0 }}
                                    >
                                      <a
                                        href="https://partner.studymetro.com/s/account/Account/00B2x000007f0clEAA"
                                        style={{
                                          padding: "10px 30px",
                                          color: "#000",
                                        }}
                                        target="_blank"
                                      >
                                        <i className="fa fa-bookmark"></i>{" "}
                                        Contact
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> 
                          </div>

                          <div className="item">
                            <div className="thumb">
                              <img src="assets/img/courses/5.jpg" alt="Thumb" />
                              <div className="overlay">
                                <a href="#">
                                  <img
                                    src="assets/img/team/5.jpg"
                                    alt="Thumb"
                                  />
                                </a>
                                <ul>
                                  <li>
                                    <i className="fas fa-clock"></i> 08:27:00
                                  </li>
                                  <li>
                                    <i className="fas fa-list-ul"></i> 65
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="info">
                              <div className="meta">
                                <ul>
                                  <li>
                                    <a href="#">Illustration</a>
                                    <a href="#">Design</a>
                                  </li>
                                  <li>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <span>(2k)</span>
                                  </li>
                                </ul>
                              </div>
                              <h4>
                                <a href="#">Florida International University</a>
                              </h4>
                              <p>University Park, Florida,U.S.USA...</p>
                              <div className="footer-meta">
                                <div className="blog-area">
                                  <div className="info">
                                    <div
                                      className="content"
                                      style={{ padding: 0 }}
                                    >
                                      <a
                                        href="https://partner.studymetro.com/s/account/Account/00B2x000007f0clEAA"
                                        style={{
                                          padding: "10px 30px",
                                          color: "#000",
                                        }}
                                        target="_blank"
                                      >
                                        <i className="fa fa-bookmark"></i>{" "}
                                        Contact
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="item">
                            <div className="thumb">
                              <img src="assets/img/courses/3.jpg" alt="Thumb" />
                              <div className="overlay">
                                <a href="#">
                                  <img
                                    src="assets/img/team/6.jpg"
                                    alt="Thumb"
                                  />
                                </a>
                                <ul>
                                  <li>
                                    <i className="fas fa-clock"></i> 04:15:38
                                  </li>
                                  <li>
                                    <i className="fas fa-list-ul"></i> 32
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="info">
                              <div className="meta">
                                <ul>
                                  <li>
                                    <a href="#">Science</a>
                                    <a href="#">Tech</a>
                                  </li>
                                  <li>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <span>(4.6k)</span>
                                  </li>
                                </ul>
                              </div>
                              <h4>
                                <a href="#">Webster University</a>
                              </h4>
                              <p>Lockwood Ave, Webster Groves, U.S.USA...</p>
                              <div className="footer-meta">
                                <div className="blog-area">
                                  <div className="info">
                                    <div
                                      className="content"
                                      style={{ padding: 0 }}
                                    >
                                      <a
                                        href="https://partner.studymetro.com/s/account/Account/00B2x000007f0clEAA"
                                        style={{
                                          padding: "10px 30px",
                                          color: "#000",
                                        }}
                                        target="_blank"
                                      >
                                        <i className="fa fa-bookmark"></i>{" "}
                                        Contact
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> 
                          </div>
                        </div>

                        <CardCarousel
                            swipeable={true}
                            draggable={true}
                            showDots={true}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={2000}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={1000}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            itemClass="carousel-item-padding-40-px"
                          >
                            {getuniversity.map((item) => (
                              <div className="top-course-items">
                                <div className="item" style={{ margin: 20 }}>
                                  <div className="thumb">
                                    <img src= {item.image} width="99%" height="320" alt="Thumb" />
                                    <div className="overlay">
                                      {/* <a href="#">
                                        <img src={item.imgsrc} alt="Thumb" />
                                      </a> */}
                                    </div>
                                  </div>
                                  <div className="info">
                                    <h4
                                      style={{
                                        textAlign: "center",
                                        fontSize: 17,
                                      }}
                                    >
                                      <a href="/UniversityDisplay"> {item.universityUniqueName}</a>
                                    </h4>
                                    <div className="footer-meta">
                                      <div className="blog-area">
                                        <div className="info">
                                          <div
                                            className="content"
                                            style={{ padding: 0 }}
                                          >
                                            {/* <a
                                              download
                                              href={item.downloadurl}
                                              style={{
                                                color: "#000",
                                                width: "50%",
                                                textAlign: "center",
                                              }}
                                            >
                                              <i className="fa fa-download"></i>{" "}
                                              Contact
                                            </a> */}

                                        <a
                                        href="#"
                                        style={{
                                          padding: "10px 30px",
                                          color: "#000",                                   
                                        }}
                                      >
                                        <i className="fa fa-bookmark"></i>{" "}
                                        Contact
                                      </a>
                                            
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </CardCarousel>

                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>

              <div
                className="fun-factor-area default-padding text-center bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/20.jpg" + ")",
                }}
              >
                <div className="container" style={{ padding: 100 }}>
                  <div className="row" style={{ display: "block" }}>
                    <div className="col-md-2 ">
                      <div className="fun-fact">
                      <a href="https://www.facebook.com/studymetro.abroad" target="_blank">
                        <div className="icon">
                          <i class="fab fa-facebook-f"></i>
                        </div>
                        <div className="info">
                          <span
                            className="timer"
                            data-to="116179"
                            data-speed="5000"
                          ></span>
                          <span className="medium">Facebook</span>
                        </div>
                      </a>  
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-6 item">
                      <div className="fun-fact">
                        <a href="https://twitter.com/studymetro" target="_blank">
                        <div className="icon">
                          <i className="fab fa-twitter" aria-hidden="true"></i>
                        </div>
                        <div className="info">
                          <span
                            className="timer"
                            data-to="116000"
                            data-speed="5000"
                          ></span>
                          <span className="medium">Twitter</span>
                        </div>
                        </a>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-6 item">
                      <div className="fun-fact">
                        <a href="https://www.instagram.com/studymetro01" target="_blank">
                        <div className="icon">
                          <i className="fab fa-instagram"></i>
                        </div>
                        <div className="info">
                          <span
                            className="timer"
                            data-to="37300"
                            data-speed="5000"
                          ></span>
                          <span className="medium">Instagram</span>
                        </div>
                        </a>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-6 item">
                      <div className="fun-fact">
                        <a href="https://www.youtube.com/channel/UC_EjChebrWmyKsId3cMt-Rw" target="_blank">
                        <div className="icon">
                          <i className="fab fa-youtube"></i>
                        </div>
                        <div className="info">
                          <span
                            className="timer"
                            data-to="11184"
                            data-speed="5000"
                          ></span>
                          <span className="medium">Youtube</span>
                        </div>
                        </a>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-6 item">
                      <div className="fun-fact">
                        <a href="https://www.facebook.com/htir.india" target="_blank">
                        <div className="icon">
                          <i className="fab fa-facebook-f"></i>
                        </div>
                        <div className="info">
                          <span
                            className="timer"
                            data-to="880"
                            data-speed="5000"
                          ></span>
                          <span className="medium">Facebook Review</span>
                        </div>
                        </a>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-6 item">
                      <div className="fun-fact">
                        <a href=" http://bit.ly/2tIhbxb" target="_blank">
                        <div className="icon">
                          <i className="fab fa-google-plus"></i>
                        </div>
                        <div className="info">
                          <span
                            className="timer"
                            data-to="100"
                            data-speed="5000"
                          ></span>
                          <span className="medium">Google Review</span>
                        </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Container>
  );
}
