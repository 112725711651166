/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function GMAT(props) {
  const classes = useStyles();

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 25,
                  marginTop: 30,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">GMAT</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="blog-area single full-blog left-sidebar full-blog default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="container"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <div className="row">
                    <div className="blog-items" style={{ width: "100%" }}>
                      <div className="blog-content col-md-12">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      //marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      This test is accepted to find entry to a
                                      graduate management application, such as
                                      MBA and Masters in Finance related classes
                                      in leading business schools around the
                                      world.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      This evaluation is required to find
                                      entrance to a graduate management
                                      application, such as MBA and Masters in
                                      Finance related classes in leading
                                      business schools around the world.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Why choose the GMAT Exam?</h6>It's the
                                      most commonly accepted test for MBA
                                      admissions globally. No other examination
                                      has as broad acceptability as GMAT, in
                                      accordance with the GMAC, internationally
                                      9 from 10 MBA enrollments are created
                                      through GMAT Score. Further, 2,300 +
                                      colleges accept GMAT Scores for providing
                                      entrance.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        What's the Qualification criteria for
                                        the GMAT examination?
                                      </h6>
                                      Therefore, there's absolutely not any
                                      established eligibility standards set by
                                      GMAC, the human body running GMAT for
                                      emerging for the GMAT examination. But, an
                                      individual needs to always meet the
                                      eligibility criteria decided by the
                                      university/college one succeeds to enter
                                      after committing the GMAT.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        GMAT Exam Fee: Just how much the
                                        examination Price?
                                      </h6>
                                      The program charge for the GMAT
                                      examination is $250, which will translate
                                      to INR 18,700 approximately. Furthermore,
                                      in the event the applicants wish to alter
                                      the middle or reschedule the exam then
                                      they'll be charged additional. Candidates
                                      who don't appear for the exam will be
                                      billed a complete GMAT exam fee.
                                      <br />
                                      GMAT Cancellation Fee: If you cancel the
                                      examination 1 to 14 weeks prior to the
                                      exam date then you'll be billed $200 and
                                      $50 will be reimbursed. In the event you
                                      cancel the examination 15 to 60 days ahead
                                      of the appointment then you'll be billed
                                      $175 and $75 will be reimbursed. And
                                      should you cancel the exam over 60 days
                                      before the consultation then you are going
                                      to be billed $150 and will get $100 as a
                                      refund. In addition, the evaluation
                                      appointment can't be canceled or altered
                                      within a day of the scheduled exam and
                                      time.
                                      <br />
                                      GMAT Rescheduling Fee: If you reschedule
                                      the examination 1 to 14 weeks prior to the
                                      exam date, then you need to cover US$150.
                                      Furthermore, if you reschedule the
                                      examination 15 to 60 days before the
                                      appointment then you've got to cover
                                      US$100. Should you reschedule the
                                      examination over 60 days prior to the exam
                                      date then you need to cover US$50.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>GMAT Exam Dates</h6>
                                      there are not any fixed official GMAT
                                      dates, so you may pick any date depending
                                      on your convenience and accessibility. In
                                      the event you want to retake the GMAT
                                      examination you are able to do this after
                                      16 days. You are able to take or retake
                                      the GMAT examination following every 16
                                      days. You can look for the examination a
                                      maximum of five times annually. Ideally,
                                      candidates are suggested to enroll
                                      themselves two to 3 months prior to the
                                      examination date. If you enroll online or
                                      by phone, you may get yourself enrolled as
                                      late as 24 hours prior to the examination
                                      date. Nonetheless, it is safer to adhere
                                      to early enrollment as you'll have a set
                                      schedule to get ready for the examination
                                      accordingly. The training institutes urge
                                      you to enroll at the first available date
                                      so you get a vast window of time to
                                      prepare.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        Further, the GMAT examination has four
                                        segments:
                                      </h6>
                                      <i class="fa fa-check-circle"></i>&nbsp;
                                      Analytical Writing Assessment--steps
                                      applicants ability to think critically and
                                      to convey their thoughts
                                      <br />
                                      <i class="fa fa-check-circle"></i>&nbsp;
                                      Integrated Reasoning--steps applicants
                                      ability to analyze information and
                                      evaluate data presented in multiple
                                      formats
                                      <br />
                                      <i class="fa fa-check-circle"></i>&nbsp;
                                      Quantitative Reasoning--steps aspirant's
                                      ability to analyze data and draw
                                      conclusions using reasoning abilities
                                      <br />
                                      <i class="fa fa-check-circle"></i>&nbsp;
                                      Verbal Reasoning--measures the ability of
                                      the applicants to read and comprehend
                                      written content, to assess arguments and
                                      also to fix written content to conform to
                                      conventional written English
                                    </p>
                                  </div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div className="col-md-12">
                                    <div
                                      className="content"
                                      style={{ paddingBottom: 0 }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area default-padding">
        <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="row" style={{ display: "block" }}>
            <div className="about-items">
              <div className="col-md-12">
                <div className="weekly-top-items">
                  <div className="top-courses" style={{ paddingRight: 0 }}>
                    <div className="heading" style={{ paddingBottom: 15 }}>
                      <h4 style={{ color: "#3f8ccb" }}>
                        ACCREDITATIONS AND MEMBERSHIPS <strong></strong>
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/EAIE.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/ICEF.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/NAFSA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/QISAN.jpeg"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIRC.gif"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIEA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
