/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import icon from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Header from './Header';
import Footer from './Footer';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';



const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
newbtn:{
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
},

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:12,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },

widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function Compare(props) {
  const classes = useStyles();
 


  return (
   <Container className={classes.widths}>
   <Header/>  <CssBaseline />
    <div className="login-area default-padding" style={{paddingTop:20,}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">
<div className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard" style={{backgroundImage: "url(" + "../assets/img/banner/6.jpg" + ")",padding:70}}>             

        <div className="container">
            <div className="row">
            <div className="double-items col-md-12 info">
                <h2 className="wow fadeInLeft">New Zealand</h2>
                </div>
             
            </div>
        </div>
    </div>


    <section class="video_sec blog_wrap">
<div class="container">
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <div class="blog_box faq">
                <h2>Frequently Asked Questions?</h2>
                <div class=" blog_box_content">
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is there a deadline for applying?</h3>
                        <p style={{color:'#333'}}>A: Mostly, 2 months before starting intake date.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: IS GRE/GMAT or IELTS/TOEFL is required for admission?</h3>
                        <p style={{color:'#333'}}>A: Yes, IELTS score is mandatory for admissions in New Zealand.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much Time it will take to get the offer letter?</h3>
                        <p style={{color:'#333'}}>A: Maximum 1 to 2 weeks.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can i work on campus?</h3>
                        <p style={{color:'#333'}}>A: Yes, students can work both, on campus or off campus.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long will it take for me to obtain my internship employment?</h3>
                        <p style={{color:'#333'}}>A: It depends on the student’s communication skills and prior experience.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What type of employment will I get initially?</h3>
                        <p style={{color:'#333'}}>A: Initially students get internship jobs into their profile. For business related students job opportunities are into auditor, business management, hr management, supply chain manager, business analyst etc.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Must I find an internship job only in the field related to my major or is any job O.K.?</h3>
                        <p style={{color:'#333'}}>A: We recommend to take internship job into the concerned field of your major for international explosure.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How may working hours will I probably work each week?</h3>
                        <p style={{color:'#333'}}>A: 20hrs per week.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What if I can't find employment?</h3>
                        <p style={{color:'#333'}}>A: You can find part time jobs till you find your internship job.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Could the work authorization be possibly converted into a full time work permit upon completion of the masters program?</h3>
                        <p style={{color:'#333'}}>A: Yes, you will get a post study visa after completing your studies for 1yr.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much is the bank statement required for applying VISA?</h3>
                        <p style={{color:'#333'}}>A: NZ$ 15000 towards living expenses for 1yr and 1st yr fees.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long I have to keep amount in my bank account for VISA?</h3>
                        <p style={{color:'#333'}}>A: 6months old bank statement should be shown for visa.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much tuition fee I have to pay before and after getting VISA/Offer letter?</h3>
                        <p style={{color:'#333'}}>A: 1st year fees has to be paid before visa.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can one apply for Dependent VISA?</h3>
                        <p style={{color:'#333'}}>A: Dependents can apply for visa only if the student is going for level 8 or 9.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How can apply as a dependent VISA?</h3>
                        <p style={{color:'#333'}}>A: Additional fund in the bank which supports their living expenses.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is required in order to bring my spouse and family?</h3>
                        <p style={{color:'#333'}}>A: You will need to produce the documents needed to show your spouse’s or civil partner's status and evidence of your marital or civil partner status (for example, birth or naturalization certificate of Irish spouse, marriage or civil registration certificate).</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my spouse get the wok permit there?</h3>
                        <p style={{color:'#333'}}>A: Yes.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much duration work permit will spouse get there?</h3>
                        <p style={{color:'#333'}}>A: It depends on the student’s course duration.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I apply work permit after completing my studies?</h3>
                        <p style={{color:'#333'}}>A: Yes, you get a post study work visa of 1yr after completing the studies.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much time will I get after my studies to search job there?</h3>
                        <p style={{color:'#333'}}>A: 1 Year.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my spouse extend the work permit?</h3>
                        <p style={{color:'#333'}}>A: No.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my accompanying dependent attend school?</h3>
                        <p style={{color:'#333'}}>A: No.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can i work on campus?</h3>
                        <p style={{color:'#333'}}>A: Yes. You can work 20 hours per week, while studying in school. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is IELTS required for dependent?</h3>
                        <p style={{color:'#333'}}>A: Yes, dependents need IELTS for job purpose.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Will University secure job for me?</h3>
                        <p style={{color:'#333'}}>A: University assist you in finding the job.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much does it cost to study in New Zealand?</h3>
                        <p style={{color:'#333'}}>A: New Zealand’s flexible education system makes it easier to find a programme and course to fit your budget, from diplomas to postgraduate study. Tuition fees for international students depend on what you study, where you study and how long you study for.

At schools level, annual fees for state schools start at about $11,000 for primary schools and $13,000 for secondary schools. Annual fees for private primary and secondary schools start at about $25,000.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the cost of living in New Zealand?</h3>
                        <p style={{color:'#333'}}>A: Your living costs will depend on your lifestyle and which part of New Zealand you choose to live in, as accommodation and living costs vary between regions.
If you will be studying in New Zealand for more than one year, you’ll need to be able to show you have at least $15,000 to support yourself each year.
As an example of how much to budget for, Victoria University of Wellington recommends students allow $18,000-$27,000 each year, the University of Auckland recommends $20,000-$25,000, the University of Otago recommends $15,000-$17,000 and Massey University recommends $15,000-$18,000.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much money can I bring into New Zealand?</h3>
                        <p style={{color:'#333'}}>A: There is no restriction on how much foreign currency you can bring into or take out of New Zealand, but if you arrive at an airport carrying more than NZ$10,000 in cash you will have to declare it to the New Zealand Customs Service.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How can I get a scholarship to study in New Zealand?</h3>
                        <p style={{color:'#333'}}>A: Many scholarships for international students – including PhD scholarships and scholarships for Masters’ degrees – are offered by the New Zealand Government, other governments, educational institutions and philanthropists.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much does transport cost in New Zealand?</h3>
                        <p style={{color:'#333'}}>A: All New Zealand cities and most towns have buses, and Auckland and Wellington have train services to outer suburbs. Prices vary depending on where you live.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How can I save money travelling around New Zealand?</h3>
                        <p style={{color:'#333'}}>A: From basking in natural thermal springs in Rotorua to stargazing up at one of the world’s most spectacular night skies, New Zealand is full of once-in-a-lifetime experiences that are cheap or free.
Explore our awe-inspiring beaches, mountains, forests and lakes for free. Other free or cheap entertainment options include live music, outdoor movies, parks, gardens, museums and galleries.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q:  How can I choose a school/institution?</h3>
                        <p style={{color:'#333'}}>A: Whether you’re looking for a caring school that encourages creative thinking and personalised learning, high-quality vocational training or degree-level study at one of our world-ranked universities, studying in New Zealand can help you achieve your goals.  

New Zealand’s flexible education system gives you a huge range of institutions to choose from in every part of the country. Start exploring our schools, universities, institutes of technology and polytechnics, private training establishments or English language schools.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are the English language requirements to study in New Zealand?</h3>
                        <p style={{color:'#333'}}>A: Different institutions and courses will have different English language requirements, so check with the institution you’re considering applying to.
The New Zealand Qualifications Authority (NZQF) sets English language entry requirements for international students.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How can I get a visa to study in New Zealand? </h3>
                        <p style={{color:'#333'}}>A: If you’re planning to study in New Zealand for more than three months, you may need to apply for a student visa. The application process will be cheaper, faster and easier if you apply online.

If you plan to study more than one course in New Zealand, the Pathway Student Visa may be the only visa you need. It covers up to three consecutive programmes of study over five years with one or more qualifying education providers.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the lifestyle like in New Zealand?</h3>
                        <p style={{color:'#333'}}>A: New Zealand is famous for its relaxed pace of life, outdoor lifestyle and excellent work/life balance. We work and study hard, but make time for family, friends, hobbies and enjoying our beautiful natural environment.
Ranked as one of the most peaceful and least corrupt countries in the world, New Zealand is safe, politically stable and culturally diverse.
Your education provider should have signed a government code aimed at making sure you have a care and support you need to thrive in New Zealand.
Each area in New Zealand has its own unique attractions, from the Northland’s rich M?ori heritage to Wellington’s funky cafe scene to the wild Otago coastline – maybe you’ll be the first among your group of friends to spot a penguin or a seal.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Am I entitled to public health services in New Zealand?</h3>
                        <p style={{color:'#333'}}>A: Most international students are not entitled to publicly funded health services while in New Zealand, so you will need medical insurance to cover the costs of any treatment.
You’ll need to have approved medical insurance before applying for a student visa. Contact your education provider for help with arranging medical insurance, and to see what medical facilities are available at your institution.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How safe is New Zealand?</h3>
                        <p style={{color:'#333'}}>A: New Zealand is friendly, politically stable, multicultural and tolerant of all types of diversity. Our crime rates are lower than in many countries, and we have low levels of personal violence and strife between communities.

New Zealand is the second most peaceful country in the world, according to the Global Peace Index, and the least corrupt country in the world, according to Transparency International’s Corruption Perception Index.

By international standards, New Zealand is a safe and easy country for students wanting to study overseas. You’ll find a warm welcome here.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the weather like in New Zealand?</h3>
                        <p style={{color:'#333'}}>A: Our weather varies from warm and subtropical in the far north to cool and temperate in the far south.
The New Zealand summer runs from December to February, while winter is from June to August.
Our weather can change fast, so be prepared when you’re packing for a bush walk, a trip to the mountains, a sea kayak, a walk around the beach or any other adventure in our fabulous outdoors.
Find out more about our climate, the average temperatures each season, and what the temperature is today in the part of New Zealand where you’re planning to study.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Where can I live in New Zealand? </h3>
                        <p style={{color:'#333'}}>A: You’ll have lots of accommodation options, from staying in a hall of residence (hostel) to going flatting (sharing an apartment). International school students usually stay in a homestay (private boarding), and are looked after by a friendly Kiwi family.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How can I work while I’m studying in New Zealand?</h3>
                        <p style={{color:'#333'}}>A: Working part-time can help you pay your living expenses while you’re studying abroad in New Zealand. It also expands your circle of friends and enables you to develop valuable employability skills such as independence, communication, teamwork, timekeeping and workplace-relevant English language skills.
If you have a student visa, you may be able to work up to 20 hours a week during term time and full-time during scheduled holidays.
If you are a Masters by research or PhD student, you may be able to work full-time during term time as well as in scheduled holidays.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How can I work in New Zealand after I’ve finished studying? </h3>
                        <p style={{color:'#333'}}>A: A New Zealand education sets you up for success, whether you plan to return home, stay on in New Zealand or see where you could go in the global jobs marketplace.
If you’ve set your sights on working in New Zealand, you may be able to stay on after you’ve completed your studies.
You can apply for a 12-month post-study work visa to find a job. If you do find a job, you can apply for a further two-year post-study work visa.
After that, you may qualify for a New Zealand resident visa under the skilled migrant category.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What skills are in demand in New Zealand?</h3>
                        <p style={{color:'#333'}}>A: There may be lots of competition for jobs after you graduate, from both international and domestic students. Studying in an area where there are skills shortages may increase your chances of getting a job.
Use the skills shortage list checker to find out if the job you’re planning to study is currently in demand in New Zealand. Having the right skills, qualifications and experience may make it easier to get a work or residence visa.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much will it cost?</h3>
                        <p style={{color:'#333'}}>A: To study in New Zealand you only need the following:
For 1 year course – Approximate fees of Rs.5 - 6 lakhs + living expenses of Rs.3 lakhs = Rs.8 - 9 lakhs = VISA!
For 2 years course – simply double the above equation. Now the good thing is that you don’t have to really take the living expenses with you. That is only to show the visa officer at the time of application that you have enough to survive on just in case you do not find a part time job after you arrive in New Zealand. And if you cannot find a part time job in 2 - 3 months time, don’t get us wrong, but you probably were never meant to be there in the first place anyway. So if you are the smart one and can find a part time job in a few weeks after you land, you only need to have the fees in place.
Besides the fees, an initial amount of Rs.1 lakh will cover your first 2 months living expense + ticket cost + visa cost, etc.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Am I allowed to work while studying?</h3>
                        <p style={{color:'#333'}}>A: All students on a student visa can work up to 20 hours per week during semester and full time during vacations.
Many New Zealand institutions offer a student employment service called ‘Student Job Search’ to help you find work. Upon the successful completion of their studies they are allowed to apply for a 12 months Graduate Job search Visa and subsequently for permanent resident status.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long will it take to process my application?</h3>
                        <p style={{color:'#333'}}>A: All undergraduate applications take upto two weeks to finalise the enrolment. Postgraduate applications may take upto four weeks. During the busy season over the months of May-June and November-January all Postgraduate applications may take upto six weeks to process.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q:  Can I apply for Permanent Residence after my studies in New Zealand?</h3>
                        <p style={{color:'#333'}}>A: You don’t have to come back to India if you successfully complete your studies in New Zealand. You become eligible for a guaranteed 12 months job search visa. This visa allows you to stay in New Zealand and work full time in any job. But the important thing is that in these 12 months you have to find a job relevant to the course you just completed.
Once you secure this you can apply for a longer work permit which is generally issued for 2 years. At this stage you will have enough points to apply for Permanent Residency. The points are based on age, qualifications and subject, how long you studied in New Zealand, work experience and industry, spouse qualifications and any blood relatives who are citizens or permanent residents of NZ and willing to support your application. Once you are a Permanent Resident of New Zealand, you are eligible for all social benefits from the government. 5 years after becoming a Permanent Resident you can apply for Citizenship after which a New Zealand passport can be applied for. This allows you visa free travel to many countries including UK, Australia, Canada and almost all European countries
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q:  How much funding do I need to show to get a Student Visa?</h3>
                        <p style={{color:'#333'}}>A: To study in New Zealand you only need the following: For 1 year course – Approximate fees of Rs.5 - 6 lakhs + living expenses of Rs.3 lakhs = Rs.8 - 9 lakhs = VISA! For 2 years course – simply double the above equation. Now the good thing is that you don’t have to really take the living expenses with you. That is only to show the visa officer at the time of application that you have enough to survive on just in case you do not find a part time job after you arrive in New Zealand. And if you cannot find a part time job in 2 - 3 months time, don’t get us wrong, but you probably were never meant to be there in the first place anyway. So if you are the smart one and can find a part time job in a few weeks after you land, you only need to have the fees in place. Besides the fees, an initial amount of Rs.1 lakh will cover your first 2 months living expense + ticket cost + visa cost, etc.
Now the big question is where to get the required money from? We always encourage students to be honest when it comes to money matters. If your family does not have the required funds, there are many ways to raise the funds required for study in New Zealand. Some of them are:
Educational Loans: Many banks now offer education loans liberally for overseas study. If you have a property that can be pledged as a security, the chances of a getting an education loan are very high.
Sponsorship: Sponsors for study can include family members and friends. So funds can be shown in a legitimate manner from various sources. Don’t get conned by some ‘consultants’ who will scare you and say you have to show 30 – 40 lakhs and will then help you ‘manage’ the funds by taking 10%. Bloody thieves! Discuss with us and we will show you clearly how you can raise the funds in a proper and genuine manner. Hopefully we have answered all your questions about studying with Study Plus and you are ready to take your first step. It’s simple. Contact us. We look forward to seeing you soon.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the minimum wage?</h3>
                        <p style={{color:'#333'}}>A: The minimum wage (at the time of printing this information) is NZ$13 per hour.
How much are the tuition fees in New Zealand?
Type of Tuition Fees ($NZ)
English Language Courses approximately NZ$320 - NZ$400 per week
Secondary School normal tuition fees are from NZ$8,000 - NZ$10,500 per year for most schools but some private schools are more expensive
Polytechnic Courses these range from NZ$15,000 - NZ$19,000 per year depending on the course or degree
University Degrees from NZ$18,500 - NZ$25,000 per year for most under-graduate and post graduate degrees
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the cost to live in New Zealand?</h3>
                        <p style={{color:'#333'}}>A: The total cost depends on each students spending habits. However a student should be able to live in New Zealand for a total cost of living of between NZ$10,000 and NZ$15,000 a year including accommodation, transport and entertainment.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is GST Tax?</h3>
                        <p style={{color:'#333'}}>A: GST stands for Goods &amp; Services Tax. All goods and services in New Zealand including tuition fees have GST Tax of 15% included in the fee. There are some items that do not have GST and these include bank fees and residential rental payments.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is there an age limit to study in New Zealand?</h3>
                        <p style={{color:'#333'}}>A: There is no limit on the age of a person wishing to study in New Zealand however the New Zealand Immigration Service needs to be satisfied that the applicant is a genuine student when they process the student visa application.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How many education institutions are there in New Zealand?</h3>
                        <p style={{color:'#333'}}>A: New Zealand has 8 Universities, 25 Polytechnics and/or Institutes of Technology and about 440 secondary schools.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the difference between Universities and Polytechnics and Institutes of Technology?</h3>
                        <p style={{color:'#333'}}>A: Historically the main difference had been that Universities focussed on academic programmes and the Polytechnics focussed on practical training. Over recent years both types of institutions have expanded their range of programmes and now there is significant overlap between Universities and Polytechnics. Over recent years the Polytechnic programmes have been expanded to include a wide range and number of degree programmes. A number of Polytechnics have also merged together and have changed their names from Polytechnics to Institutes of Technology to better reflect their focus. Cross-credits for courses completed at the Polytechnic can be credited to ongoing University study in most instances.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is a tertiary institution?</h3>
                        <p style={{color:'#333'}}>A: This is the collective name given to all institutions like Universities and Polytechnics who offer course for students to continue their studies after secondary school.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long does it take to get a Degree in New Zealand?</h3>
                        <p style={{color:'#333'}}>A: Most degrees in New Zealand are for 3 years but there are also a number of degrees which take 4 years.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Are New Zealand degrees recognised internationally?</h3>
                        <p style={{color:'#333'}}>A: New Zealand degrees are recognised internationally and will be accepted when applying for post-graduate study in most countries.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much can I earn when I work in New Zealand?</h3>
                        <p style={{color:'#333'}}>A: Those students who are able to work under their visa will probably work in casual jobs either tutoring at their institution or working in restaurants or other service industries. The normal hourly wage rate will be from NZ$13.00 (minimum wage) - NZ$15.00 per hour and you will have tax deducted from your wages before you receive your payment. On these amounts the tax will normally work out at about 20 cents per $ and you should be able to obtain a full tax refund at the end of the financial year in April.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What happens when I finish my degree?</h3>
                        <p style={{color:'#333'}}>A: Students who have completed their degree in the normal time and have a job offer related to their studies can obtain a work visa for another 2 years to work in the job they have been offered as may qualify for residence.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I extend my student visa in New Zealand?</h3>
                        <p style={{color:'#333'}}>A: There is no problem in extending your student visa in New Zealand and this should be done in 1 or 2 days. You need to obtain a new school offer of place and pay the tuition fee, complete the student visa application and show that you have sufficient money or a financial sponsor to cover your living expenses in New Zealand.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my student visa be cancelled?</h3>
                        <p style={{color:'#333'}}>A: Yes. Your student visa can be cancelled and you requested to leave to New Zealand if you do not meet the requirements of your student visa. The main requirements are that you must attend the classes and do not commit any crime or unlawful acts in New Zealand. If you have your student visa cancelled this is very serious as it will be noted in your passport and you will have difficulty travelling out of your home country in the future.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What happens if my student visa expires?</h3>
                        <p style={{color:'#333'}}>A: This is very serious. You should make every effort to extend your student visa before the expiry date. If your student visa expires you have to make a special submission to the New Zealand Immigration Service which they may or may not approve. There is total responsibility on you to extend your student visa and keep the visa current at all times.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I change my student visa in New Zealand?</h3>
                        <p style={{color:'#333'}}>A: If you have completed your course of study and you wish to remain in New Zealand as a visitor or apply for residence this is possible providing you have not got a Limited Purpose Visa.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is a Limited Purpose Visa?</h3>
                        <p style={{color:'#333'}}>A: A Limited Purpose Visa is issued when the New Zealand Immigration Service considers the applicant does not meet the normal criteria to study in New Zealand but is prepared to allow the applicant the opportunity to show they are a genuine student or a genuine visitor as the case may be. Limited Purpose Student Visas are issued for a specific period of time to allow the applicant to study a particular course. The applicant must meet the requirements of the visa and return to their home country before the expiry of the visa. If their course has not been completed they are able to obtain a short-term extension to complete their course. In some special circumstance it may be possible to extend or change a Limited Purpose Visa to a normal visa.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I apply for New Zealand Residence?</h3>
                        <p style={{color:'#333'}}>A: If you meet the requirements of the current immigration policy in New Zealand it is possible for you to apply for residence at any time. In particular the immigration policy encourages students who have completed their degree in New Zealand to proceed to apply for permanent residence.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What happens if I lose my passport?</h3>
                        <p style={{color:'#333'}}>A: In this situation you should report the loss to the local Police Station and then forward a copy of the Police Report your Consulate or Embassy to complete the requirements to have a new passport issued.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my parents visit me in New Zealand?</h3>
                        <p style={{color:'#333'}}>A: It should be no problem for parents to apply for a visitor visa and to visit their children in New Zealand providing they meet normal visitor visa policy requirements.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long can I study in New Zealand?</h3>
                        <p style={{color:'#333'}}>A: Providing you pass your course and continue to meet the requirements of your student visa you can continue to extend your visa for as long as you wish. There is no limit on the time you can spend in New Zealand as a student.</p>
                    </div>
                                   
                </div>
                
            </div>

        </div>
    </div>

</div>

</section>



    <div className="advisor-details-area default-padding" style={{padding:0,}}>
   
   </div>



</div>
</div>
</div>
</div>

<Footer/>
    </Container>
  );
}
