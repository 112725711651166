/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import icon from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Header from './Header';
import Footer from './Footer';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';



const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
newbtn:{
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
},

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:12,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },

widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function Compare(props) {
  const classes = useStyles();
 


  return (
   <Container className={classes.widths}>
   <Header/>  <CssBaseline />
    <div className="login-area default-padding" style={{paddingTop:20,}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">
<div className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard" style={{backgroundImage: "url(" + "../assets/img/banner/6.jpg" + ")",padding:70}}>             

        <div className="container">
            <div className="row">
            <div className="double-items col-md-12 info">
                <h2 className="wow fadeInLeft">Canada</h2>
                </div>
             
            </div>
        </div>
    </div>

    <section class="video_sec blog_wrap">
<div class="container">
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <div class="blog_box faq">
                <h2>Frequently Asked Questions?</h2>
                <div class=" blog_box_content">
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do colleges abroad offer scholarship grants?</h3>
                        <p style={{color:'#333'}}>A: A. Scholarship grants are competitive and granted towards the most deserving students. In the graduate level you will get funding by means of graduate assistantships in which you work or research with professors. As a swap you're going to get your whole tuition costs waived, and obtain a regular monthly stipend to pay for your bills. Naturally, such honors are extremely competitive. Colleges abroad consider various factors for example previous academics, entrance test scores, experience, application documents and so forth while assessing a credit card application for admission and scholarship grants hence, it's imperative to possess a well-rounded outstanding profile before you decide to apply. Such honors can be found generally in the Master's level only.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I do an MBA without work experience?</h3>
                        <p style={{color:'#333'}}>A: A. Master of business administration is really a larger, practical-oriented degree covering every aspect of a company (marketing, finance, human assets, procedures) after which, permitting you to definitely specialize inside your preferred major. Leading business schools worldwide require the GMAT and TOEFL / IELTS for admission additionally to full-time experience with a minimum of 3 years. Hence, if you're interested in going after your Master of business administration, attempt to gain great work-experience after your Bachelor's and strengthen your profile that will boost your odds of admission inside a good B-school. You shouldn't attempt a Master of business administration without getting full-time experience.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is there a deadline for applying?</h3>
                        <p style={{color:'#333'}}>A: Yes, there is deadline for every intake. The main intakes for Canada admissions are January, May and September.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: IS GRE/GMAT or IELTS/TOEFL is required for admission?</h3>
                        <p style={{color:'#333'}}>A: Yes, IELTS/TOEFL score is mandatory for admission and visa approval.   At least 6.0 with no band less than 5.5 are required.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much Time it will take to get the offer letter?</h3>
                        <p style={{color:'#333'}}>A: - It takes around 2 weeks to get the offer letter</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I work on campus?</h3>
                        <p style={{color:'#333'}}>A: After 6 month’s students can work part-time after getting the Tax number.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much is the bank statement required for applying VISA?</h3>
                        <p style={{color:'#333'}}>A: You need to show bank funds equivalent to your 1st year tuition fee and 1year living expenses. This will come up to $12000-$15000 for tuition fee and $10000 for Living expenses.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long I have to keep amount in my bank account for VISA?</h3>
                        <p style={{color:'#333'}}>A: - You need to keep that amount into your bank until you reach their. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much tuition fee I have to pay before and after getting VISA/Offer letter?</h3>
                        <p style={{color:'#333'}}>A: After getting the offer letter you need to pay 1st Semester tuition fee. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can one apply for Dependent VISA?</h3>
                        <p style={{color:'#333'}}>A:  Yes</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How can apply as a dependent VISA?</h3>
                        <p style={{color:'#333'}}>A: The student can go there and then send a request for their dependents visa.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is required in order to bring my spouse and family?</h3>
                        <p style={{color:'#333'}}>A: You need to have a proof of relationship and sufficient funds to sponsor them.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my spouse get the wok permit there?</h3>
                        <p style={{color:'#333'}}>A: Yes</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much duration work permit will spouse get there?</h3>
                        <p style={{color:'#333'}}>A: Yes, in most cases, your spouse or common-law partner can work in Canada. However, they will usually need a work permit to work in Canada. ... In other cases, your spouse or common-law partner must apply for a work permit for a specific employer. The employer may have to get a Labour Market Impact Assessment (LMIA).</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I apply work permit after completing my studies?</h3>
                        <p style={{color:'#333'}}>A: - Yes, you will get around 3 year of work permit after completion of your course if you have applied for Bachelor’s course and for Master's course you will get around 2 year of work permit.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much time will I get after my studies to search job there?</h3>
                        <p style={{color:'#333'}}>A: You will get around 3 year to search for a job there.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my spouse extend the work permit?</h3>
                        <p style={{color:'#333'}}>A: yes</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my spouse extend the work permit?</h3>
                        <p style={{color:'#333'}}>A: yes</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my accompanying dependent attend school?</h3>
                        <p style={{color:'#333'}}>A: No</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I allowed to work on campus?</h3>
                        <p style={{color:'#333'}}>A: Depend on university </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Will University secure job for me?</h3>
                        <p style={{color:'#333'}}>A: They will assist you for job but they don’t guarantee the job, its dependents upon your skills and experience.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Why Should I study in Canada?</h3>
                        <p style={{color:'#333'}}>A: Canada Universities offer co-op program in Canada, Where university will allow you to study and participate in internship on-campus/ off-campus. After study students are eligible to get 3 years work permit to work full time in Canada and can also apply for PR.  
There are varieties program available of Diploma, Undergraduate, Graduate, Master, PhD and lot of summer and exchange programs.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: what type of employment will I get initially?</h3>
                        <p style={{color:'#333'}}>A: That depends upon your skills and experience. But it is always better to work in the field in which you are studying there to gain beneficial experience.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I need to find an internship job only in the field related to my major or is any job O.K.?</h3>
                        <p style={{color:'#333'}}>A: No, It’s not mandatory but this is preferred to take internship in the course related to your studies.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How many working hours will I probably work each week?</h3>
                        <p style={{color:'#333'}}>A: During the studies you can work only as a part-time and after completion of your studies you will get 3 year of work permit.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I have to work overtime, Can I keep the overtime bonus?</h3>
                        <p style={{color:'#333'}}>A: You can't work overtime during your academic session. But you can work in the duration of your summer vacation.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is there a way for me to work before actually starting to attend classes in order for me to save more money and add it to my current savings?</h3>
                        <p style={{color:'#333'}}>A: No, there is no way to work before you start the study program.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Could the work authorization be possibly converted into a full time work permit upon completion of the masters program?</h3>
                        <p style={{color:'#333'}}>A: Yes, once you complete your studies and have worked 3 year after completion of your course then you can apply for PR.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the procedure to study in Canada</h3>
                        <p style={{color:'#333'}}>A: : In order to study and remain in Canada for a period of six months or longer, the first step is to get admission to a Canadian Designated Learning Institution (DLI) recognized by the government of Canada. Only designated institutions can be used to support a study permit application. Once an individual receives a letter of acceptance from a DLI, he or she may be able to apply for a study permit. Applications may be made online or by mail, from outside of Canada.
All new study permits are issued at a Canadian port of entry. An applicant who has submitted an application to a foreign Canadian Visa Office will be issued a letter of approval advising him or her to travel to a Canadian port of entry to have the study permit issued in his or her passport. A study permit should be issued for the duration of the person’s studies.
For overseas applications, applicants from countries whose citizens require a Temporary Resident Visa (TRV) in order to enter Canada will be issued a TRV automatically if the application for a study permit is approved. These applicants do not need to submit a separate application for a TRV and there is no separate fee. The same procedure applies to applicants from countries whose citizens need an Electronic Travel Authorization (eTA) in order to enter Canada by air.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What’s the difference between a Study Permit and a Temporary Resident Visa?  </h3>
                        <p style={{color:'#333'}}>A: While a study permit authorizes international students to pursue their studies while in Canada, a Temporary Resident Visa (TRV) allows a person to enter Canada. Depending on the country of citizenship, an international student may need a visa for entry.
For overseas applications, applicants from countries whose citizens require a Temporary Resident Visa (TRV) in order to enter Canada will be issued a TRV automatically if the application for a study permit is approved. These applicants do not need to submit a separate application for a TRV and there is no separate fee. The same procedure applies to applicants from countries whose citizens need an Electronic Travel Authorization (eTA) in order to enter Canada by air.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much does it cost to study in Canada? </h3>
                        <p style={{color:'#333'}}>A: In addition to showing he or she has sufficient funds to cover tuition fees, an applicant for a study permit will need to prove the following funds to support his or her studies in Canada:

Number of people	All provinces except Quebec
Single student	Tuition plus $10,000 for a 12-month period (or $833 per month)
For one accompanying family member add:	$4,000 for a 12-month period (or $333 per month)
For each additional family member, add:	$3,000 for a 12-month period per dependent child of any age (or $255 per month)
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Are Scholarships available to International students in Canada? </h3>
                        <p style={{color:'#333'}}>A: A small number of Canadian government awards programs are offered to exceptional students each year. Similarly, a number of Canadian institutions offer needs-based entrance scholarships for international students of high academic distinction. Information can be obtained through the financial aid office of the individual universities and colleges. The Ministry of Education in the applicant's home country may also have information on scholarships.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Are IELTS or CELPIP required in order to submit a study permit application?</h3>
                        <p style={{color:'#333'}}>A: Generally, no. Proof of English ability is not required in a study permit application. Applicants may, however, need to submit proof of language ability in order to gain acceptance to a Canadian school, college, or university.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are the processing times for a study permit? </h3>
                        <p style={{color:'#333'}}>A: Paper applications are processed in an average of seven weeks, with processing times ranging from two to 19 weeks, depending on the visa office. Processing times are usually significantly lower using online methods.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the procedure to apply to study in Quebec? </h3>
                        <p style={{color:'#333'}}>A: Applicants interested in studying in Quebec need to first apply for a Certificat d’acceptation de Quebec (Certificate of Acceptance for Quebec, or CAQ) and then apply for a study permit. Since January 1, 2017, the application fee for a CAQ is $111 CAD.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is a study permit required for short courses? </h3>
                        <p style={{color:'#333'}}>A: There is a study permit exemption for short courses that conclude within six months or less. However, if a course is longer than six months, the potential student will need to apply for a study permit.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Why could a study permit application be refused?</h3>
                        <p style={{color:'#333'}}>A: 9) Why could a study permit application be refused?
When assessing study permit applications, visa officers determine whether an applicant is a bona fide student. This is determined through a number of factors, including but not limited to:
•	The length of time the student plans to spend in Canada;
•	The means by which the student will support themselves while studying;
•	The student’s obligations and ties to their home country;
•	The likelihood of the applicant leaving Canada after their temporary status ends; and
•	General compliance to government regulations.
An individual may be refused a study permit if the visa officer reviewing their file determines that they are not a bona fide student. Reasons for refusal can include, but are not limited to, the following:
•	The visa officer does not believe the applicant will leave Canada after their status ends. This can be determined because of lack of ties to the applicant’s home country, the political/economic state of their country at the time of review, or if the applicant has overstayed previous temporary visas in any country;
•	The visa officer does not feel the applicant’s acceptance to a Canadian institution is genuine;
•	The visa officer does not believe the applicant has the means to support themselves while studying in Canada.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What happens next if a study permits application is refused? </h3>
                        <p style={{color:'#333'}}>A: If an application is refused, the individual has two options. It may be possible to either re-apply with a fresh application or contest the decision with an appeal in court. Both of these options may take several months. Learn more at the Study Permit Refusals and Appeals page.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: It is possible to work during studies? </h3>
                        <p style={{color:'#333'}}>A: Yes. If an international student has a valid study permit and is studying full-time at a DLI, he or she may work both on- and off-campus without a separate work permit. Study permit holders are allowed to work for up to 20 hours per week during regular academic sessions and full time during scheduled breaks.  
There is an important exemption to this regulation: students enrolled in an English as a Second Language (ESL) or French as a Second Language (FSL) program are not authorized to work with a study permit.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: is it possible to stay in Canada after graduation? </h3>
                        <p style={{color:'#333'}}>A: An individual who has studied full-time at a qualifying institution for at least eight months may apply for a Post-Graduation Work Permit (PGWP) within 90 days of receiving the final marks. The study permit must be valid at the time of the application. The PGWP is generally issued for the same duration as the applicant's studies, but for a minimum of eight months and a maximum of three years.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is a medical exam required for a study permit? </h3>
                        <p style={{color:'#333'}}>A: An applicant may need to do a medical exam if:
•	he or she plans to remain in Canada for more than six months; and/or
•	he or she lived for six or more consecutive months in a country or territory designated as high risk for certain diseases during the year immediately before the date he or she wants to enter Canada.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Are police clearance certificate required for a study permit application? </h3>
                        <p style={{color:'#333'}}>A: An applicant may need a criminal record check if he or she intends to come to Canada as a student. If required, he or she will have to obtain a police certificate from each country or territory where he or she has lived for six or more months consecutively since the age of 18. Police certificates are required to determine if applicants have a criminal record. They also help visa officers make sure applicants are not a security risk to Canada.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: If a student changes institution, is a new study permit required? </h3>
                        <p style={{color:'#333'}}>A: Most post-secondary students may change their learning institution, program, and/or field of study without needing to apply for a new study permit. The same applies for post-secondary students changing their level of study (i.e. from a bachelor’s to master’s program). Please check the conditions listed on the study permit to verify whether the study permit restricts the holder to studying at a particular institution or program.
If a student transfers to a school, college, or university in Quebec, he or she will need to apply for a CAQ and, if necessary, a new study permit.
Primary students who are entering high school, as well as high school students who will move on to post-secondary education, must apply to modify their study permit. Students who are changing learning institutions must inform IRCC of their institution changes.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can a spouse be included on the application? </h3>
                        <p style={{color:'#333'}}>A: If a potential student plans to study full-time, his or her spouse or common-law partner may be eligible for an open work permit. He or she must pass a medical exam. An offer of employment is not required. This open work permit is appropriate if the spouse is accompanying the student but is not a student himself or herself. In the study permit application, the applicant should indicate that the spouse will accompanying him or her to Canada. The applicant could mention in the application cover letter that he or she want an open work permit for his or her spouse.
If the accompanying spouse wishes to study here, he or she should apply for his or her own study permit. 
Learn more about how international students can bring their family to Canada.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can children accompany an international student to Canada?</h3>
                        <p style={{color:'#333'}}>A: Yes. Furthermore, an applicant may submit an application for a study permit for his or her accompanying minor children. This can be done when the applicants submits his or her own study permit application. A letter of acceptance from a Canadian DLI will not be required for accompanying minor children who intend to study in Canada.
If an individual is already in Canada on a study or work permit, his or her accompanying minor child may study without a study permit.
The age of majority is different in each province and territory, although it is usually 18 or 19 years of age. Anyone under the age of majority is considered to be a minor.
Learn more about how international students can bring their family to Canada.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is it possible to come to Canada for pre-study visit? </h3>
                        <p style={{color:'#333'}}>A: Yes, a potential international student can explore Canada as a visitor before studies begin. Individuals should verify whether they need a Temporary Resident Visa (TRV) to enter Canada. Citizens of certain countries require a visa to visit Canada. To verify whether a visa is required, click here.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is it possible to submit an application without all the required documents. </h3>
                        <p style={{color:'#333'}}>A: No. A study permit application should be complete upon submission. If a document is missing, a visa officer may make a decision on the application without giving the applicant a chance to submit the missing document.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are the alternatives to post-secondary education in Canada?</h3>
                        <p style={{color:'#333'}}>A: In Canada, tertiary education includes every type of formal teaching program past secondary schools, be it academic, vocational, technical, or the professional education offered primarily by universities, colleges, and special institutes.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What type of a high school diploma is accepted if applying in a Canadian post-secondary educational institution?</h3>
                        <p style={{color:'#333'}}>A: If you are concerned if the high school diploma you’ve earned in your home-country will be considered legit in Canada, worry no more since any student possessing a government-accredited high school diploma from their home country may apply for admission to a Canadian University.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the main difference between Universities and Colleges in Canada?</h3>
                        <p style={{color:'#333'}}>A: 
Universities get to deal independently with academic matters regarding the curricula, methods and teaching strategies. Universities have the power to set academic, admission, and graduation policies and standards, appoint staff, and undertake academic planning, meanwhile Private Universities are out-of-province institutions permitted to provide degrees after a meticulous quality assessment process by the designated governmental authorities which give the green light to certain degrees.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are the common requirements into getting accepted in a Canadian University/College?</h3>
                        <p style={{color:'#333'}}>A: In order to get admitted in a Canadian tertiary education institution there are requirements students will have to meet, whether it I an undergraduate or a postgraduate program. Depending on the field of studies, programs and other relevant factors competition can vary. The two essential criteria are however, high school grades/performance as well as language skills be it English (in most of the provinces) or French in francophone ones.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are the chances of getting a postgraduate degree after you’ve graduated from a Canadian post-secondary educational institution?</h3>
                        <p style={{color:'#333'}}>A: Once you’ve completed a BA degree in a Canadian tertiary institute you are more than welcome to continue your postgraduate studies in this country, having full legal support from the Canadian authorities regarding migration policies.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long does it take to get a Master degree in Canada?</h3>
                        <p style={{color:'#333'}}>A: Commonly, Master degrees last for a whole academic year including an obligatory internship added to the overall duration. Sometimes a Master degree can last for two academic years, depending on the curricula and the sensitivity of the program.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long does it take to get a PhD degree in Canada?</h3>
                        <p style={{color:'#333'}}>A: PhD degree programs are commonly two to three years in duration, are separately funded and considered as a job position (not always), in the majority of the cases.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the difference between private and public post-secondary educational institutions in Canada?</h3>
                        <p style={{color:'#333'}}>A: The public universities are commonly independent from governmental monitoring and don’t require accreditation meanwhile private universities are commonly monitored by the state gathered education council.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What type of language skill does one need in order to get accepted in a Canadian post-secondary institution?</h3>
                        <p style={{color:'#333'}}>A: TOEFL (Test of English as a Foreign Language) scores are the most common language requirement for American universities. IELTS (International English Language Testing System) exam scores are the most common language requirement for Canadian universities. But most universities will accept either test as proof of your English language ability. You can take TOEFL and IELTS exams at testing centers worldwide.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is there any additional requirement for international students willing to apply in a Canadian post-secondary institution?</h3>
                        <p style={{color:'#333'}}>A: International students are required to submit an essay and a personal statement of what they think they are fit for enrolling in the designated program together with documents stating their work experience (if any) up to the date. Of course documents like reference letters, extracurricular activity certificates and other proof of skills is always a bonus and very much appreciated.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is getting accepted in a Canadian tertiary institution based on a competitive basis only?</h3>
                        <p style={{color:'#333'}}>A: In order to get admitted in a Canadian tertiary education institution there are requirements students will have to meet, whether it Is an undergraduate or a postgraduate program. Depending on the field of studies, programs and other relevant factors competition can vary. Commonly professional fields such as medicine and engineering are way more competitive, as well as applicative arts that require talent as the basic requirement (arts, music, sports).</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How do I apply in a Canadian University/College?</h3>
                        <p style={{color:'#333'}}>A: By submitting all the necessary documentation and additional obligatory procedures via online routes. The answer will also be sent to you via mail.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I have to speak both French and English in order to get accepted in a Canadian post-secondary educational institution?</h3>
                        <p style={{color:'#333'}}>A: Not necessarily, if your target province is one of the English speaking provinces you must provide proof of sufficiency in English language yet if you are looking forward to studying in a francophone province just the same, you must submit proof of proficiency in French Language. *Exceptions are always present.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is it mandatory to have a graduate degree in order to pursuit a Master degree or PhD?</h3>
                        <p style={{color:'#333'}}>A: Although an undergraduate degree is commonly mandatory to continue the postgraduate studies this might not always be the case. Commonly graduate degrees involve research upon a specific subject not having a degree doesn’t make you unfit for the program as long as you have all the necessary theoretical and literature knowledge packed. Normally in a postgraduate program you have a mentor that supervises all your doings just as they are charged to evaluate via interviews and other means if you are suitable for engaging in the program.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much does tuition cost in a Canadian University for an international student?</h3>
                        <p style={{color:'#333'}}>A: As Canada is divided into individually governed provinces the tuition fees for an academic year of tertiary education vary</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Where can I look for scholarships in order to study in Canada?</h3>
                        <p style={{color:'#333'}}>A: Scholarships for international students willing to study in Canada are difficult to be put in one place, as there are limitless opportunities granted by different entities around the world. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What type of documentation will I need in order to apply for a student visa in Canada?</h3>
                        <p style={{color:'#333'}}>A: Applicants are generally required:
•	Proof of acceptance and enrolment to an accredited academic institution in Canada;
•	Possess a valid passport/travel document, and provide proof of financial support;
•	Ties to their home country and evidence of their intention to leave Canada at the end of their studies.
Additionally, students have to have proof of no criminal history or endangering medical conditions of any sort and willingly undergo medical testing as required.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long will I have to wait to be issued a Canadian student visa/ study permit?</h3>
                        <p style={{color:'#333'}}>A: Processing times vary. It depends on the type of application you submit, and where it is processed.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I work while studying in Canada?</h3>
                        <p style={{color:'#333'}}>A: Working in Canada as a student is only possible if you apply for a work permit; otherwise international students can take up solely on-campus jobs.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I work without a work permit, while studying in Canada?</h3>
                        <p style={{color:'#333'}}>A: Canada is rather forthcoming when it comes to its international students. For those who are entirely focused in their studies, there are some small jobs that don’t require a work permit yet are rather poorly paid.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How do I get the residency permit once in Canada?</h3>
                        <p style={{color:'#333'}}>A: You must apply for a study permit from outside Canada, at the visa office responsible for the country or region where you live in.
Along with the application form which you will download online, you have to attach the following document in order to apply for a study permit.
•	Your letter of acceptance;
•	Proof of your identity;
•	Proof that you have sufficient funds for your stay in Canada.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much will I have to pay for accommodation in Canada, a rough estimation?</h3>
                        <p style={{color:'#333'}}>A: Accommodation varies depending on the region you will be living in Canada as well as the type of accommodation you choose to go with. However, a rough estimate would be $7300 – $14 000 per year.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the typical lifestyle of a Canadian?</h3>
                        <p style={{color:'#333'}}>A: For most of those who’ve never been before, Canada is commonly seen as some nature jewel- picturesque landscapes, dramatic mountains and scary forests. On the other side of the wilderness, one will notice that in fact Canada has lots of soul and lots of style; Canadians are bold, open and transparent towards its fellow expats.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are the alternatives for short-term accommodation in Canada?</h3>
                        <p style={{color:'#333'}}>A: Numerous hotels are at ones disposal once you arrive at your Canadian destination. Opposed to more expensive hotels, a hostel is the perfect low-budget temporary accommodation in a big city. Another alternative for a short stay would be a Bed-and-Breakfast entity.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are the benefits of private accommodation in Canada?</h3>
                        <p style={{color:'#333'}}>A: Renting is a more private alternative for students who awe for silence and intimacy. Rents vary greatly due to the location, quality and availability. Living alone in an apartment is quite a luxury; therefore students tend to share accommodation in order to keep the expenses low.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What kind of a job could I find as an international student in Canada?</h3>
                        <p style={{color:'#333'}}>A: Commonly international students go for part time job like, cleaning, bartending, volunteer work or live-in-care types of work. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I go to Canada on a work and travel experience?</h3>
                        <p style={{color:'#333'}}>A: Travelling and working abroad is becoming more and more popular each year with thousands of youngsters engaging in such an insightful experience throughout the world. Regarding the great number of expats travelling to Canada, it seems like the country has something to offer, something the international youth is craving for.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is it possible to get the Canadian citizenship as an international student?</h3>
                        <p style={{color:'#333'}}>A: If you want to make Canada your permanent home, there are a number of ways to apply. In most cases, you will not need to leave Canada. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Am I eligible for a work and travel program in Canada if I have a previous criminal record?</h3>
                        <p style={{color:'#333'}}>A: A previous criminal record can interfere with the visa process depending on the gravity of the deed and the time that has passes since then.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Am I able to study in English in the Canadian province of Quebec?</h3>
                        <p style={{color:'#333'}}>A: Yes, most certainly you can. There are several Universities that teach in English as a primary language.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Will I be able to work in Canada if I am not fluent in English?</h3>
                        <p style={{color:'#333'}}>A: Depends on the line/type of work you will be doing. However it is recommendable that if you do, you use the time to improve your English language skills.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Will I be able to work in Canada if I am not fluent in French?</h3>
                        <p style={{color:'#333'}}>A: Depends on the province you will be living in; if you are fluent in English you won’t have any trouble residing and working in either the English provinces or the French ones, however if you can speak either it can be a problem.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I have to pay taxes for my earning in Canada?</h3>
                        <p style={{color:'#333'}}>A: Of course, accordingly to your earnings.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Will I get paid in cash, or do I need a bank account if I want to work in Canada?</h3>
                        <p style={{color:'#333'}}>A: Normally the first thing you will need to do before landing a job is get a bank account and a phone number which insinuates that you will get paid via bank transfers. However, it can be possible for some types of daily jobs to be paid in cash too.</p>
                    </div>
                                   
                </div>
                
            </div>

        </div>
    </div>

</div>

</section>

    <div className="advisor-details-area default-padding" style={{padding:0,}}>
   
   </div>



</div>
</div>
</div>
</div>

<Footer/>
    </Container>
  );
}
