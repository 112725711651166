/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import icon from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";
import { getData, postData } from "../FetchServices";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function Compare(props) {
  const classes = useStyles();
  const [getUniversitiesList, setUniversitiesList] = useState([]);
  const [getUniversityInputValue1, setUniversityInputValue1] = useState("");
  const [getUniversityInputValue2, setUniversityInputValue2] = useState("");
  const [getUniversityInputValue3, setUniversityInputValue3] = useState("");
  const [getUniversityInputValue4, setUniversityInputValue4] = useState("");
  const [getUniversityInputValue5, setUniversityInputValue5] = useState("");
  const[getList, setList]=React.useState([]);

  const top100Films = [
    { title: "University of  Study Metro", year: 1994 },
    { title: "University at  Study Metro", year: 1972 },
    { title: "University of  Study Metro", year: 1974 },
  ];

  useEffect(function () {
    fetchUniversityAll();
  }, []);

  const location = useLocation();

  // console.log("compare")
  // console.log(location.state)

  const fetchUniversityAll = async () => {
    let list = await getData("universities/DisplayAllUniversity");
    setUniversitiesList(list);
  };

  const handleUniversity = (id) => {
    // alert("hello next Page");
      props.history.push({
        pathname: "/UniversityDisplay",
        state: {
          id: id,
        }
      });
    // console.log(event.currentTarget);
  };


  const beforeSearch =  <>
                        <div
                        className="about-area default-padding"
                        style={{ padding: 10 }}
                        >
                        <div className="container">
                        <div className="row">
                          <div className="about-items">
                        <div
                          className="col-md-8 about-info"
                          style={{ paddingTop: 50 }}
                        >
                          <h2>Compare universities</h2>


                          <p
                            style={{
                              fontSize: 14,
                              color: "#212121",
                              textAlign: "justify",
                            }}
                          >
                            To create a comparison, simply select up to five
                            universities in the ranking table, and then click on
                            ‘Compare’. The university comparison displays data on
                            each institution side by side. You can quickly compare
                            several universities you are interested in , and then
                            click through to a university profile page for more
                            details.
                          </p>
                        </div>
                         <div className="col-md-4 features text-light">
                         <img
                           style={{
                             flex: 1,
                             borderRadius: "50%",
                             width: 320,
                             height: 320,
                           }}
                           class="login_image"
                           src="assets/img/compare.png"
                           alt="Login"
                           data-original-title=""
                           title=""
                         />
                         {/* <i class="fa fa-balance-scale" style={{fontSize:100,}}></i>  */}
                       </div>
                       </div>
                       </div>
                       </div>
                       </div>
                       </>

const stateValues =location.state? Object.values(location.state):[]


const afterSearch =<>
                                <div
                                    className="about-area default-padding"
                                    style={{ padding: 10 }}
                                >
                                    <div className="container">
                                        <div className="row">
                                            <div className="about-items">

                                                <div className="col-md-12 about-info">
                                                    <h2>Compare universities</h2>
                                                     <p
                                                        style={{
                                                            fontSize: 14,
                                                            color: "#212121",
                                                            textAlign: "justify",
                                                        }}
                                                    >
                                                        A comprehensive rating about the Universities on
                                                        noteworthy parameters can easily narrow down an
                                                        choice from the pool of universities. Study Metro
                                                        university comparer helps in making informed
                                                        decision about universities.
                          </p>
                                                </div>
                                           
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="advisor-details-area default-padding"
                                    style={{ padding: 0 }}
                                >
                                    <div className="container">
                                        <div className="row">
                                            <div className="advisor-info">
                                                <div className="col-md-8 content">
                                                   

                                                    <div className="tab-info">
                                                        <ul className="nav nav-pills">
                                                            <li className="active">
                                                                <a
                                                                    data-toggle="tab"
                                                                    href="#tab1"
                                                                    aria-expanded="true"
                                                                >
                                                                    OVERVIEW
                                </a>
                                                            </li>
                                                            <li className="">
                                                                <a
                                                                    data-toggle="tab"
                                                                    href="#tab2"
                                                                    aria-expanded="false"
                                                                >
                                                                    FINANCE
                                </a>
                                                            </li>
                                                            <li className="">
                                                                <a
                                                                    data-toggle="tab"
                                                                    href="#tab3"
                                                                    aria-expanded="false"
                                                                >
                                                                    CONDITIONS
                                </a>
                                                            </li>
                                                        </ul>

    

                                                        <div className="tab-content tab-content-info">

{/* OverView tab Data   */}
                                                            <div id="tab1" className="tab-pane  active in">
                                                                <div className="info title">


                                                                    {
                                                                        stateValues.map((item,index)=>{
                                                                            return(
                                                                               item !==""? 
                                                                               <>
                                                                                    <div className="course-info-list" key={index}>
                                                                                        <ul key={index}>

                                                                                            {/* college name and country */}
                                                                                            <li key={item.id+"li"}>
                                                                                                <h2
                                                                                                    style={{
                                                                                                        fontSize: 13,
                                                                                                        color: "#3f8ccb",
                                                                                                    }}
                                                                                                >
                                                                                                  
                                                                                                    <a 
                                                                                                     onClick={() => handleUniversity(item.id)}
                                                                                                     >
                                                                                                    {item.name}
                                                                                                    </a>
                                                                                                   
                                                                                                 
                                                                                                </h2>
                                                                                                <h5
                                                                                                    style={{
                                                                                                        fontSize: 12,
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {item.country}
                                                                                                </h5>
                                                                                            </li>

                                                                                            {/* Founder and year  */}

                                                                                            <li key={item.id+"li1"}>
                                                                                                <h2
                                                                                                    style={{
                                                                                                        fontSize: 13,
                                                                                                        color: "#3f8ccb",
                                                                                                    }}
                                                                                                >
                                                                                                    Founded
                                                                                                </h2>
                                                                                                <h5
                                                                                                    style={{
                                                                                                        fontSize: 12,
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {item.founded}
                                                                                                </h5>
                                                                                            </li>

                                                                                            {/* email id */}

                                                                                            {/* <li key={item.id + "li2"}>
                                                                                                <h2
                                                                                                    style={{
                                                                                                        fontSize: 13,
                                                                                                        color: "#3f8ccb",
                                                                                                    }}
                                                                                                >
                                                                                                    Email
                                                                                                </h2>
                                                                                                <h5
                                                                                                    style={{
                                                                                                        fontSize: 12,
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {item.email}
                                                                                                </h5>
                                                                                            </li> */}

                                                                                            {/* enrollment  */}
                                                                                            <li key={item.id+"li3"}>
                                                                                                <h2
                                                                                                    style={{
                                                                                                        fontSize: 13,
                                                                                                        color: "#3f8ccb",
                                                                                                    }}
                                                                                                >
                                                                                                    Enrollment
                                                                                               </h2>
                                                                                                <h5
                                                                                                    style={{
                                                                                                        fontSize: 12,
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        item
                                                                                                            .total_students
                                                                                                    }
                                                                                                </h5>
                                                                                            </li>

                                                                                            {/* Avg Count  */}
                                                                                            <li key={item.id+"li4"}>
                                                                                                <h2
                                                                                                    style={{
                                                                                                        fontSize: 13,
                                                                                                        color: "#3f8ccb",
                                                                                                    }}
                                                                                                >
                                                                                                    Avg. Quant
                                                                                                 </h2>
                                                                                                <h5
                                                                                                    style={{
                                                                                                        fontSize: 12,
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    NA
                                                                                                </h5>
                                                                                            </li>


                                                                                        </ul>
                                                                                   </div>
                                                                                   <hr />
                                                                                   </>
                                                                                   :null
                                                                            )
                                                                        })
                                                                    }


                                                                </div>
                                                            </div>

{/* Finance tab data  */}
                                                            <div id="tab2" className="tab-pane ">
                                                                <div className="info title">

                                                                {
                                                                        stateValues.map((item,index)=>{
                                                                            return(
                                                                               item !==""? 
                                                                               <>
                                                                               <div className="course-info-list" key={index}>
                                                                                    <ul key={index}>
                                                                                        <li key={item.id+"li"}>
                                                                                            <h2
                                                                                                style={{
                                                                                                    fontSize: 13,
                                                                                                    color: "#3f8ccb",
                                                                                                }}
                                                                                            >
                                                                                              
                                                                                                  <a 
                                                                                                     onClick={() => handleUniversity(item.id)}
                                                                                                     >
                                                                                                    {item.name}
                                                                                                    </a>
                                                                                            </h2>
                                                                                            <h5
                                                                                                style={{
                                                                                                    fontSize: 12,
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                {item.country}
                                                                                            </h5>
                                                                                        </li>
                                                                                        <li key={item.id+"li1"}>
                                                                                            <h2
                                                                                                style={{
                                                                                                    fontSize: 13,
                                                                                                    color: "#3f8ccb",
                                                                                                }}
                                                                                            >
                                                                                                Tution Fees
                                                                                            </h2>
                                                                                            <h5
                                                                                                style={{
                                                                                                    fontSize: 12,
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                <i class="fas fa-pound-sign"></i>{" "}
                                                                                                {item.tution_fee}
                                                                                            </h5>
                                                                                        </li>
                                                                                        <li key={item.id+"li2"}>
                                                                                            <h2
                                                                                                style={{
                                                                                                    fontSize: 13,
                                                                                                    color: "#3f8ccb",
                                                                                                }}
                                                                                            >
                                                                                                Expenses/yr
                                                                                            </h2>
                                                                                            <h5
                                                                                                style={{
                                                                                                    fontSize: 12,
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                <i class="fas fa-pound-sign"></i>{" "}
                                                                                            4,200
                                                                                            </h5>
                                                                                        </li>
                                                                                        <li key={item.id+"li3"}>
                                                                                            <h2
                                                                                                style={{
                                                                                                    fontSize: 13,
                                                                                                    color: "#3f8ccb",
                                                                                                }}
                                                                                            >
                                                                                                Cashback
                                                                                            </h2>
                                                                                            <h5
                                                                                                style={{
                                                                                                    fontSize: 12,
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                11,000 Rs
                                                                                            </h5>
                                                                                        </li>
                                                                                        <li key={item.id+"li4"}>
                                                                                            <h2
                                                                                                style={{
                                                                                                    fontSize: 13,
                                                                                                    color: "#3f8ccb",
                                                                                                }}
                                                                                            >
                                                                                                Jobs
                                                                                            </h2>
                                                                                            <h5
                                                                                                style={{
                                                                                                    fontSize: 12,
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                NA
                                                                                             </h5>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div> 
                                                                                <hr/>
                                                                                </>
                                                                                   :null

                                                                                   )
                                                                                })
                                                                            }
                                                                </div>
                                                            </div>

{/* conditions tab data  */}

                                                            <div id="tab3" className="tab-pane">
                                                                <div className="info title">
                                                                {
                                                                        stateValues.map((item,index)=>{
                                                                            return(
                                                                               item !==""? 
                                                                               <>
                                                                                <div className="course-info-list">
                                                                                        <ul>
                                                                                            <li key={item.id+"li"}>
                                                                                                <h2
                                                                                                    style={{
                                                                                                        fontSize: 13,
                                                                                                        color: "#3f8ccb",
                                                                                                    }}
                                                                                                >
                                                                                                   <a 
                                                                                                     onClick={() => handleUniversity(item.id)}
                                                                                                     >
                                                                                                    {item.name}
                                                                                                    </a>
                                                                                                    
                                                                                                    Compare universities                           </h2>
                                                                                                <h5
                                                                                                    style={{
                                                                                                        fontSize: 12,
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {item.country}
                                                                                                </h5>
                                                                                            </li>
                                                                                            <li key={item.id+"li1"}>
                                                                                                <h2
                                                                                                    style={{
                                                                                                        fontSize: 13,
                                                                                                        color: "#3f8ccb",
                                                                                                    }}
                                                                                                >
                                                                                                    Type
                                                                                                 </h2>
                                                                                                <h5
                                                                                                    style={{
                                                                                                        fontSize: 12,
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {" "}
                                                                                                    {
                                                                                                        item
                                                                                                            .institution
                                                                                                    }
                                                                                                </h5>
                                                                                            </li>
                                                                                            <li key={item.id+"li2"}>
                                                                                                <h2
                                                                                                    style={{
                                                                                                        fontSize: 13,
                                                                                                        color: "#3f8ccb",
                                                                                                    }}
                                                                                                >
                                                                                                    Address
                                                                                                  </h2>
                                                                                                <h5
                                                                                                    style={{
                                                                                                        fontSize: 12,
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {item.address}
                                                                                                
                                                                                                </h5>
                                                                                            </li>
                                                                                        
                                                                                        </ul>
                                                                                    </div>

                                                                                <hr/>
                                                                                </>
                                                                                   :null

                                                                                   )
                                                                                })
                                                                            }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="thumb">
                                                        <img
                                                            src="assets/img/compare.png"
                                                            style={{ borderRadius: "50%" }}
                                                            alt="Thumb"
                                                        />
                                                     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                </>

  return (
    <Container className={classes.widths}>
      <Header /> <CssBaseline />
      <div className="login-area default-padding" style={{ paddingTop: 20 }}>
        <div className="">
          <div className="row">
            <div className="col-md-12">
              {/*
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/6.jpg" + ")",
                  padding: 70,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">
                        <i
                          class="fa fa-balance-scale"
                          style={{ fontSize: 30 }}
                        ></i>{" "}
                        Search and add universities to compare
                      </h2>
                    </div>
                    <div
                      class="col-lg-12"
                      style={{
                        padding: 15,
                        backgroundColor: "#FFF",
                        borderRadius: 50,
                        marginTop: 25,
                      }}
                    >
                      <div class="col-lg-6">
                        <Autocomplete
                          options={getUniversitiesList}
                          getOptionLabel={(option) => option.name}
                          value={getUniversityInputValue1}
                          onChange={(event, newValue) => {
                            setUniversityInputValue1(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search University 1"
                              fullWidth
                              InputLabelProps={{ style: { fontSize: 13 } }}
                            />
                          )}
                        />
                      </div>
                      <div class="col-lg-6">
                        <Autocomplete
                          options={getUniversitiesList}
                          getOptionLabel={(option) => option.name}
                          value={getUniversityInputValue2}
                          onChange={(event, newValue) => {
                            setUniversityInputValue2(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search University 2"
                              fullWidth
                              InputLabelProps={{ style: { fontSize: 13 } }}
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div
                      class="col-lg-12"
                      style={{
                        padding: 15,
                        backgroundColor: "#FFF",
                        borderRadius: 50,
                        marginTop: 25,
                      }}
                    >
                      <div class="col-lg-6">
                        <Autocomplete
                          options={getUniversitiesList}
                          getOptionLabel={(option) => option.name}
                          value={getUniversityInputValue3}
                          onChange={(event, newValue) => {
                            setUniversityInputValue3(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search University 3"
                              fullWidth
                              InputLabelProps={{ style: { fontSize: 13 } }}
                            />
                          )}
                        />
                      </div>
                      <div class="col-lg-6">
                        <Autocomplete
                          options={getUniversitiesList}
                          getOptionLabel={(option) => option.name}
                          value={getUniversityInputValue4}
                          onChange={(event, newValue) => {
                            setUniversityInputValue4(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search University 4"
                              fullWidth
                              InputLabelProps={{ style: { fontSize: 13 } }}
                            />
                          )}
                        />
                      </div>
                  
                    </div>
                    <div
                      class="col-lg-12"
                      style={{
                        padding: 15,
                        backgroundColor: "#FFF",
                        borderRadius: 50,
                        marginTop: 25,
                      }}
                    >
                      <div class="col-lg-12">
                        <Autocomplete
                          options={getUniversitiesList}
                          getOptionLabel={(option) => option.name}
                          value={getUniversityInputValue5}
                          onChange={(event, newValue) => {
                            setUniversityInputValue5(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search University 5"
                              fullWidth
                              InputLabelProps={{ style: { fontSize: 13 } }}
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div
                      class="col-lg-12"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 10,
                      }}
                    >
                      <div className="blog-area">
                        <div className="info">
                          <div className="content" style={{ padding: 0 }}>
                            <a
                              // href="#"
                              onClick={() => {
                                console.log('XXXXX',getUniversityInputValue1)
                                props.history.push({
                                  pathname: "/Compare",
                                  state: {
                                    clg1: getUniversityInputValue1,
                                    clg2: getUniversityInputValue2,
                                    clg3: getUniversityInputValue3,
                                    clg4: getUniversityInputValue4,
                                    clg5: getUniversityInputValue5,
                                  },
                                });
                              }}
                              style={{
                                backgroundColor: "#FFF",
                                borderRadius: 25,
                              }}
                            >
                              <i className="fas fa-search"></i> Search{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
              */}
             
                
                    

                    {location.state?afterSearch:beforeSearch}
                     
                    
                  </div>
                </div>
              </div>

              <div
                className="advisor-details-area default-padding"
                style={{ padding: 0 }}
              >
        </div>
      </div>
      <Footer />
    </Container>
  );
}
