/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import icon from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Header from './Header';
import Footer from './Footer';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';



const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
newbtn:{
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
},

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:12,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },

widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function Compare(props) {
  const classes = useStyles();
 


  return (
   <Container className={classes.widths}>
   <Header/>  <CssBaseline />
    <div className="login-area default-padding" style={{paddingTop:20,}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">
<div className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard" style={{backgroundImage: "url(" + "../assets/img/banner/6.jpg" + ")",padding:70}}>             

        <div className="container">
            <div className="row">
            <div className="double-items col-md-12 info">
                <h2 className="wow fadeInLeft">FAQ's</h2>
                </div>
             
            </div>
        </div>
    </div>


    <section class="video_sec blog_wrap">
<div class="container">
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <div class="blog_box faq">
                <h2>Frequently Asked Questions?</h2>
                <div class=" blog_box_content">
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Why Must I study Abroad?</h3>
                        <p style={{color:'#333'}}>A: A: Everybody has different encounters and causes of studying abroad. Some students will study abroad to uncover a new job path or to look great in the interview, while other may want to gain social abilities. Why don't you hear the other students have stated on the recommendations page.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How do I get started?</h3>
                        <p style={{color:'#333'}}>A: A: Which means you think you need to study abroad, great! Getting began look just like a large process. We are here to assist. Let us begin with these steps.
Determine if study abroad fits your needs
Decide where and when to review abroad
Find study abroad programs
Speak to your experts and fogeys
Fall into line financial assets</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I use financial aid to study abroad?</h3>
                        <p style={{color:'#333'}}>A: Every student and situation differs. We recommend that you simply begin by speaking for a consultant at your house. Institution you never know both you and your school's guidelines. They might have the ability to assist you to determine whether any educational funding or scholarship grants can be found.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q:  How can i have more information?</h3>
                        <p style={{color:'#333'}}>A: Speak with our Education Counselor expert by giving them a call or live chat with them.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How do i convince my parents to allow me study abroad?</h3>
                        <p style={{color:'#333'}}>A:  Sometimes parents prefer to hear the details consider getting all of your information so as before speaking for them. We have come up with a couple of pointers for both you and your parents to go over.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are the exams needed to be cleared to study abroad?</h3>
                        <p style={{color:'#333'}}>A: Colleges in America require the GRE and TOEFL for admittance to non-business programmes in the Master's level. In the Bachelor's level students have to appear for that Sitting and Sitting subject tests. United Kingdom colleges require IELTS / TOEFL for admissions. All leading business schools around the globe require the GMAT for admissions (a number of them will accept the GRE too). ?</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Will I require a 16th year of education to study abroad?</h3>
                        <p style={{color:'#333'}}>A: USA and Canadian colleges need students to possess 16 many years of undergraduate education for admission, although a couple of colleges have waived this requirement now. It is best to pursue a minimum of one year of the Master's degree course and fulfill this requirement before you decide to apply (some schools might even require the second year from the Master's degree). United kingdom and European colleges, however, stick to the fifteen years of undergraduate study pattern which is comparable to the Indian system of your practice and therefore do not need a 16th year.?</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What's the new GRE?</h3>
                        <p style={{color:'#333'}}>A: The brand new GRE offers to lessen the concentrate on vocabulary, getting rid of antonyms and analogies completely. Within the Verbal section, the main focus will change from vocabulary to reading through comprehension, and quantitative reasoning will concentrate on data interpretation and real existence situations. Overall, the brand new GRE is going to be such as the GMAT in lots of ways. Have minimum three several weeks of preparation here we are at the GRE. I recommend that you simply make reference to books for example The Official Help guide to GRE in addition to Barons Help guide to the brand new GRE. Nevertheless, with effective preparation you ought to be fine using the new GRE. A lot of the brand new GRE can be obtained at world wide web.gre.org. Each section within the new GRE is going to be obtained on the scale of 130-170, making 340 our prime score and 260 the reduced score.?</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I work in the UK after I finish my education?</h3>
                        <p style={{color:'#333'}}>A: Remember, no-one can ever guarantee you jobs once you graduate. Should you network well, you can find a job once you graduate. However, job prospects are more effective should you finish a common college. If you're searching to operate within the United Kingdom once you finish your education, then you'll have issues. Using the new visa rules, don't rely on obtaining a job within the United Kingdom.?</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What's OPT?</h3>
                        <p style={{color:'#333'}}>A: The United States system offers Optional Practical Training (or OPT) which enables students to dedicate yourself 12 to 29 several weeks (with respect to the course) within their area of study completing the program. The OPT belongs to a student visa (F1) itself. Throughout the OPT period if your company want to retain students for full-time work, they'd sponsor his/her work (H1) visa that is generally for 3 years. The organization may then extend the job visa for the next 3 years. Thus, the risk of acquiring employment after graduation is much better in America as in comparison with other nations.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What's Curricular Practical Training?</h3>
                        <p style={{color:'#333'}}>A: Curricular Practical Training (CPT) may be the legal description for that compensated internship employment that students participate in during a graduate assignment work-study program. The objective of CPT would be to give students working experience within the place of work to enhance what they're learning within the class.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What does it cost to study abroad??</h3>
                        <p style={{color:'#333'}}>A: A typical tuition of the US College is all about US 30,000 per-year and average bills are about US 10,000 per-years. Average costs at United Kingdom colleges are about 15,000 pounds per-year and average costs at Canadian colleges is about $ 20,000 each year. Costs will be different from college to college and nation to nation, hence you should check with every college individually for his or her fee structure. Costs for Master of business administration courses might be greater.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What financial loans and scholarship grants from India can one reach study abroad?</h3>
                        <p style={{color:'#333'}}>A:  Students, who're financially weak but interested in studying abroad, can acquire funding assets for example need based grants or loans from colleges, grants or loans from trusts, and bank financial loans. Remember, it's not all college gives educational funding and therefore you can examine the educational funding rules before you decide to affect colleges. Furthermore, in India, you can look at approaching various trusts for example Lady Meherbai D. Tata Education Trust, Aga Khan Foundation, Jawaharlal Nehru Memorial trust scholarship grants and Nanabhoy Jejeebhoy Charitable organization Trust that offer loan and grants or loans to students seeking education abroad. Please contact these individual institutes for more particulars.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q:  Do colleges abroad offer scholarship grants?</h3>
                        <p style={{color:'#333'}}>A: Scholarship grants are competitive and granted towards the most deserving students. In the graduate level you will get funding by means of graduate assistantships in which you work or research with professors. As a swap you're going to get your whole tuition costs waived, and obtain a regular monthly stipend to pay for your bills. Naturally, such honors are extremely competitive. Colleges abroad consider various factors for example previous academics, entrance test scores, experience, application documents and so forth while assessing a credit card application for admission and scholarship grants hence, it's imperative to possess a well-rounded outstanding profile before you decide to apply. Such honors can be found generally in the Master's level only.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I do an MBA without work experience?</h3>
                        <p style={{color:'#333'}}>A: Master of business administration is really a larger, practical-oriented degree covering every aspect of a company (marketing, finance, human assets, procedures) after which, permitting you to definitely specialize inside your preferred major. Leading business schools worldwide require the GMAT and TOEFL / IELTS for admission additionally to full-time experience with a minimum of 3 years. Hence, if you're interested in going after your Master of business administration, attempt to gain great work-experience after your Bachelor's and strengthen your profile that will boost your odds of admission inside a good B-school. You shouldn't attempt a Master of business administration without getting full-time experience.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are the SAT subject tests? How are they different from SAT?</h3>
                        <p style={{color:'#333'}}>A: Most top rated US schools will need the Sitting subject tests together with the Sitting. The Sitting has three sections - Math, Writing and demanding Reading through the entire test score is 2400 points. The Sitting II has various subject tests, the most typical ones being Math IC, Math IIC, Physics, Chemistry and Biology (each is from 800 points). Students generally choose two or three Sitting II subject tests. A score of 2000 and above is recognized as a great score in Sitting. You can refer to the Official Study Guide for SAT Subject Tests and Real SAT II: Subject Tests to help you prepare for the exams.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What's the GRE Information Technology subject test?</h3>
                        <p style={{color:'#333'}}>A: Some top US colleges will require students to look for that GRE Subject test in Information Technology. This is a test which will test your Computer Science knowledge. For particulars on GRE Information Technology Test, please make reference to the next link http://www.ets.org/gre/subject/about/content/computer_science</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What books can one buy to study for that GRE, GMAT, Sitting, TOEFL, and IELTS?</h3>
                        <p style={{color:'#333'}}>A: For that GRE you are able to make reference to the ETS GRE Modified Test, Barons and Kaplan GRE books. For GMAT, you are able to make reference to books such GMAT Official Guide and also the Manhattan Study Guides. For that TOEFL, you are able to make reference to the Barrons and Kaplan books in addition to www.toefl.org. For IELTS, you are able to collect your study material in the British Council. For additional info on the IELTS you are able to make reference to Mike Mc Carter and Judith Ash.?</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is the IELTS and the TOEFL the same?</h3>
                        <p style={{color:'#333'}}>A: Both TOEFL and IELTS are British proficiency tests needed to become taken by all worldwide students. Both exams have speaking, reading through, writing and listening sections. However, US colleges like the TOEFL whereas United Kingdom colleges prefer IELTS. For additional info on these exams make reference to the 'Prep Talks' and 'Test Drives' sections on www.educationtimes.com</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What does the admission committee look for while granting admissions?</h3>
                        <p style={{color:'#333'}}>A: Colleges abroad consider various factors for example previous academics, entrance test scores, experience, application documents and so forth while assessing a credit card application for admission and scholarship grants hence, it's imperative to possess a balanced profile.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How do you make an application for an undergraduate course for United Kingdom colleges?</h3>
                        <p style={{color:'#333'}}>A: Undergraduate programs to United Kingdom colleges are quite simple. You'll be needed to complete the UCAS form at www.ucas.com. United Kingdom colleges generally don't require any supporting documents to be delivered to them during the time of application. You'll be needed to go in your marks around the UCAS form itself in addition to upload instructions of recommendation from the teacher along with a personal statement. When a college has recognized you, you might be needed to forward the ultimate transcripts and financial documents while accepting your offer of admission.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What's the distinction between BBA and Master of business administration?</h3>
                        <p style={{color:'#333'}}>A: The BBA means Bachelor's running a business Administration and also the Master of business administration means Master's running a business Administration. Students chase the BBA following the twelfth grade and also the Master of business administration following the Bachelor's degree.??Both levels help students gain specialized understanding in the industry arena. However, a Master of business administration is really a degree in the publish graduate level, whereas a BBA is definitely an undergraduate degree.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How difficult is to buy a visa to review abroad?</h3>
                        <p style={{color:'#333'}}>A: For that student visa, as lengthy as you've an admission offer from a certified college and can show sufficient funds to pay for your education and living, it should not be any problem. You need to be honest whenever you make an application for your visa.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much cash does RBI permit study abroad?</h3>
                        <p style={{color:'#333'}}>A: The Reserve Bank of India (RBI) allows students to take up to US$ 100,000 for his or her education abroad. In some instances, the RBI may allow a greater limit when the course so demands it. Visit www.rbi.org.in for the latest updates.?</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How do you pay my college costs?</h3>
                        <p style={{color:'#333'}}>A: You may make the payment of the tuition costs through wire transfer. With this, you'll need your college banking account information. Alternatively, you may also create a demand draft from the amount and make it along with you. The wire transfer may be the preferred approach to having to pay costs.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Who can go abroad?</h3>
                        <p style={{color:'#333'}}>A: Any student can study abroad, but most programs have a GPA requirement (2.0 or higher), and the better your GPA, the better your chances of getting into the program. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Where can I go?</h3>
                        <p style={{color:'#333'}}>A: There are programs all over the world. Pick any country, and you'll probably find a program suited for you to study there.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: When can I go?</h3>
                        <p style={{color:'#333'}}>A: Study abroad for a semester, full school year or go for a summer session. Summer sessions are usually just a few weeks long can be on your own or with a group from your school. On your own is more likely to be for a language course in a foreign country. Group trips will be with a school professor and focus on a specific subject, like English, Art History, Archaeology, etc.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I get school credit?</h3>
                        <p style={{color:'#333'}}>A: You will work this out in advance to make sure you get course and hour equivalency so that you don't fall behind in your degree plan. Classes may count toward your major or may just count as electives. Your study abroad office will help you get this approved with your professors before you go.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q:  How much does it cost?</h3>
                        <p style={{color:'#333'}}>A: If you study through your university for one semester or full year, you'll pay tuition to your university because you're still enrolled there - just attending the foreign institution. You'll pay to house to the university at which you're studying and that cost will vary (based on proximity to the university, self-catering or catered). The cost of living will also vary depending on the country and city to which you're going.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How can I better deal with culture shock before I leave?</h3>
                        <p style={{color:'#333'}}>A: Chances are you picked where you're going based on the interest you already have in being there. Read about the city's and country's history, customs, food, tourism, and language (if needed). Also, definitely ask your study abroad office to set up an informal gathering with current "inbound" exchange students. They can tell you anything you want to know about the differences in campus life there and abroad, how their home city is different, where to find the best food, their favorite bars and clubs at home, etc.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Where will I live?</h3>
                        <p style={{color:'#333'}}>A: Housing usually depends on your program. For semester and year-long students, you'll most likely choose from the regular student housing - dorms, flats, apartments. If you're going on a summer session, the accommodation will be pre-arranged.</p>
                    </div>
                                   
                </div>
                
            </div>

        </div>
    </div>

</div>

</section>



    <div className="advisor-details-area default-padding" style={{padding:0,}}>
   
   </div>



</div>
</div>
</div>
</div>

<Footer/>
    </Container>
  );
}
