/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";



import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';
import Header from './Header';
import Footer from './Footer';


const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
newbtn:{
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
},

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:12,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },

widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function TermsCondition(props) {
  const classes = useStyles();
  const [firstName,setFirstName]=React.useState('')
  const [lastName,setLastName]=React.useState('')
  const [emailId, setEmailId]=React.useState('')
  const [password,setPassword]=React.useState('')
  const [verifyPassword,setVerifyPassword]=React.useState('')
  const [messages, setMessages ]=React.useState('')
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus,setStatus]=React.useState('') 

  const handlePicture=(event)=>{
    setPicturePath(URL.createObjectURL(event.target.files[0]))
    setPicture(event.target.files[0])
   }

   const handleVerifyPassword=(e)=>{
    setVerifyPassword(e.target.value)
    if(password===e.target.value){
      setStatus("Password Matched")
    }
    else{
      setStatus("Password not matched")
    }
  }


//   const addNewRecord=async()=>{
// if(picture=='')
// {
//     var body={'firstName':firstName,
//     'lastName':lastName, 
//     'emailId':emailId, 
//     'password':password,
//     'status':"Not Approved",
//     'picture':"Not Found"}
//     var result=await postData('selfregister/addNewRecord',body)
//     if(result){
//       await setMessages("Agent Succefully Created")

//       var body={
//         'emailid':emailId,
//         'password':password
//        }
// var resultt = await  postData('selfregister/checkagentsignin',body)

// if (resultt.RESULT)
// {

// localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
// props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
// }
// else
// {
// alert("Failed To Created Account")
// }

// await setFirstName('')
// await setLastName('')
// await setEmailId('')
// await setPassword('')
// await setVerifyPassword('')
// await setPicturePath('')
// }
// else{setMessages("Faild to Submit")}

// }

// else{
//    var formData=new FormData()
//    formData.append('firstName',firstName)
//    formData.append('lastName',lastName)
//    formData.append('emailId',emailId)
//    formData.append('password',password)
//    formData.append('picture',picture)
//    formData.append('status', "Not Approve")
  
//    var config={headers:{'content-type':'multipart/form-data'}}  
//     var result=await postDataAndImage('selfregister/addNewRecordPic',formData,config)


//     if(result){
//       await setMessages("Agent Succefully Created")

//       var body={
//         'emailid':emailId,
//         'password':password
//        }
// var resultt = await  postData('selfregister/checkagentsignin',body)

// if (resultt.RESULT)
// {

// localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
// props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
// }
// else
// {
// alert("Failed To Created Account")
// }

// await setFirstName('')
// await setLastName('')
// await setEmailId('')
// await setPassword('')
// await setVerifyPassword('')
// await setPicturePath('')
// }
// else{setMessages("Faild to Submit")}
//     }
//     }
   
   
    console.log("History ==  ",props)
  return (
   <Container className={classes.widths}>
   <Header/> <CssBaseline />
    <div className="login-area default-padding" style={{paddingTop:20,}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">
<div className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard" style={{backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",padding:70}}>             
{/* <div className="banner-area inc-form bg-gradient slider-less text-light" style={{backgroundImage: "url(" + "../assets/img/event/1.jpg" + ")", padding:70}}> */}
        <div className="container">
            <div className="row">
                {/* <div className="double-items">
                    <div className="col-md-7 info">
                        <h2 className="wow fadeInLeft">Find your ideal study program.</h2>
                        <a className="btn day btn-light border btn-md wow fadeInDown" style={{color:'#3f8ccb'}} href="#">View Courses</a>
                    </div>
                    <div className="col-md-5 reg-form">
                        <form action="#">
                            <div className="row">
<img style={{flex:1}} class="login_image" src="assets/img/elearning.png" alt="Login" data-original-title="" title="" />

                            </div>
                        </form>
                    </div>
                </div> */}
                <div className="double-items col-md-12 info">
                <h2 className="wow fadeInLeft">Terms & Condition</h2>
                </div>
             </div>
        </div>
    </div>

 
    <div className="blog-area single full-blog left-sidebar full-blog default-padding">
        <div className="container" style={{paddingLeft:0,paddingRight:0,}}>
     
            <div className="row">
                <div className="blog-items">
                    <div className="blog-content col-md-12">

                        <div className="content-items">
                            <div className="single-item">
                                <div className="item">
                                    <div className="info">



<div className="content" style={{paddingBottom:0,}}>
<h4>Public universities-pavement to better career study abroad with us. Ride-guide. </h4>

<div style={{textAlign:'justify'}}>
<p style={{color:'#212121',}}>Admissions requirements-MS/MBA/Mtech/BS-USA study abroad, Top univerisities-deadlines, rolling admission, affordability. Mock & e-visa perfect prepration</p>
<p style={{color:'#212121',}}>Study Metro study abroad guidance helps to select best degree MS/MBA/Mtech/BS/Hotel Management/Aviation with an ease. For us it is easier done than said. Top program in top schools with set up of high profile evaluation. Visa guidance the best.</p>

</div>

</div>

<div className="content" style={{paddingBottom:0,}}>
<h4>Terms and Conditions</h4>
</div>
<div className="content" style={{paddingBottom:0,}}>
<h4 style={{fontSize:14,}}>1 - Registration and confirmation</h4>
<div style={{textAlign:'justify'}}>
<p style={{color:'#212121',}}>
All registrations received by Study Metro Pvt Ltd  by online, e-mail, walk in or telephone will be deemed to be valid. Registration will be confirmed within a maximum of 2 working days following receipt of a valid registration. The contract will be deemed to be finalised as soon as Study Metro has confirmed the registration, and will then be legally enforceable. Where the registered person is an adult, that person will become a contract partner of Study Metro. Where the registered person is not an adult, his/her legal representative will become the contract partner of Study Metro. In that event, the legal representative must sign the contract and give his/her exact address. The person who is contractually registered to a Study Abroad course will become a participant, designated as such below. The person who signs the contract (either the person him/herself or, where the person is a minor, his/her legal representative) will be identified as a contract partner, designated as such below.  
</p>
</div>

<h4 style={{fontSize:14,}}>2 - Information provided at the time of registration</h4>
<div style={{textAlign:'justify'}}>
<p style={{color:'#212121',}}>
The contract partner expressly declares that the information provided at the time of registration for a Study Abroad course is true and accurate. Any inaccurate information or omission may lead to immediate expulsion from the course. In that event, the costs of the course will not be refundable, either in whole or in part. 
</p>
</div>
<h4 style={{fontSize:14,}}>3 - Registration fees</h4>
<div style={{textAlign:'justify'}}>
<p style={{color:'#212121',}}>
Study metro  will not charge any extra fee on the award of financial aid/Scholarship from the student.
</p>
</div>

<h4 style={{fontSize:14,}}>4 - Agreement modification fees</h4>
<div style={{textAlign:'justify'}}>
<p style={{color:'#212121',}}>
In the event that a participant wishes the type of course in different countries conditions to be varied following confirmation of registration by Study Metro , a Processing Fee will  be charged and will be payable with the request for variation. No additional charge will be required if the participant decides to extend his/her Study Abroad course.
</p>
</div>

<h4 style={{fontSize:14,}}>5 - Conditions of payment</h4>
<div style={{textAlign:'justify'}}>
<p style={{color:'#212121',}}>
Fees will become payable immediately on receipt of confirmation of registration as follows:
<br/>
Depend on the student Package.
</p>
</div>

<h4 style={{fontSize:14,}}>6 - Payment period</h4>
<div style={{textAlign:'justify'}}>
<p style={{color:'#212121',}}>
The contract partner will be held liable in the event that the payment periods specified by Study Metro in the confirmation are not complied with. In such circumstances, Study Metro reserves the right to refuse entry to the Abroad course. No claims will be accepted by Study Metro.
</p>
</div>


<h4 style={{fontSize:14,}}>7 - Proof of payment</h4>
<div style={{textAlign:'justify'}}>
<p style={{color:'#212121',}}>
Participants may be asked to provide documentary proof of payment of the full fee for their processing fee. In the case of late registrations, Study Metro will ask for proof before sending any documentation information.
</p>
</div>

<h4 style={{fontSize:14,}}>8 - Cancellation prior to commencement of the course</h4>
<div style={{textAlign:'justify'}}>
<p style={{color:'#212121',}}>
I agree to the clause that the processing fee paid for admission into a particular University or country is a non-refundable amount in any circumstances.I am aware of the fact that of applying for abroad education through Study Metro Edu. Pvt Ltd Company includes the throughout service provided by the consultants from admission to visa processing. So they are liable to keep the processing fee payment for my application.
</p>
</div>

<h4 style={{fontSize:14,}}>9 - Obligations of participants</h4>
<div style={{textAlign:'justify'}}>
<p style={{color:'#212121',}}>
Participants are required to attend the course and to arrive on time. They are responsible for choosing a course which is appropriate for their level of capability. Failure to attend the course, or irregular attendance, or any disruption to lessons caused by an individual participant’s attitude will entitle Study Metro  to expel the participant. Study Metro will not reimburse or pay compensation for any part of the missed course or any additional travel costs incurred.
</p>
</div>

<h4 style={{fontSize:14,}}>10 - Late arrival</h4>
<div style={{textAlign:'justify'}}>
<p style={{color:'#212121',}}>
In order to avoid delay joining in abroad university , any participant who enroll for next intake are eligible to pay application fee or processing fee to university again.
</p>
</div>

<h4 style={{fontSize:14,}}>11 - Claims</h4>
<div style={{textAlign:'justify'}}>
<p style={{color:'#212121',}}>
In the event that a participant wishes to bring a claim, he/she must notify the head of the centre immediately. Any claim for compensation must be notified in writing to Study Metro, and may not arrive more than four weeks after the contractual termination of the Study Abroad course. After that time, any action taken by the contract partner will be deemed to be null and void.
</p>
</div>

<h4 style={{fontSize:14,}}>12 - Discipline</h4>
<div style={{textAlign:'justify'}}>
<p style={{color:'#212121',}}>
In the event that a participant is badly behaved, undisciplined, or demonstrates a poor attitude, Study Metro reserves the right to expel him/her immediately. In that event, Study Metro will not reimburse or pay compensation for any part of the missed course or any additional travel costs incurred.
</p>
<p style={{color:'#212121',}}>13) I confirm that all the supporting documents submitted by me along with are genuine and legally valid.</p>
<p style={{color:'#212121',}}>14) I will-fully confirm and allow Study Metro Edu Cons. Pvt Ltd to act as agents on my behalf for my admission into the appropriate educational institution. I agree that the services of any other agents or similar organizations used would be voided and the responsibility of my admittance to an educational institution to be solely performed by Study Metro Edu Cons Pvt Ltd.</p>
<p style={{color:'#212121',}}>15) I agree to the clause that the processing/enrollment fee paid for admission into a particular University or country is a non- refundable amount in any circumstances. I am aware of the fact that of applying for abroad education through Study Metro Edu Cons Pvt Ltd Company includes the throughout service provided by the consultants from admission to visa processing.

So they are liable to keep the processing fee payment for my application.</p>
<p style={{color:'#212121',}}>16) I authorize study metro Edu Cons Pvt Ltd to share and retain all my original/photocopies of documents required during the college application procedure. I also confirm that I authorize study metro to share and retain photocopies of my passport/travel document and all other documents used in the college/university application and General visa application.</p>
<p style={{color:'#212121',}}>17) I understand that Study Metro Edu Cons Pvt Ltd is not responsible for the outcome of the admission confirmation and or visa application/interview. In such circumstances, no claim of any nature whatsoever will be entertained. procedure for their records..</p>
<p style={{color:'#212121',}}>18) I authorize study Metro Edu Cons Pvt Ltd to send me updates via SMS/Email and or post.</p>
<p style={{color:'#212121',}}>19) I confirm that study metro Edu Cons Pvt Ltd does not take any responsibility of any General visa rule changes following submission of my application or any rule changes by the College/University following my admission. I confirm to pay university tuition fee within one week after receiving visa.</p>
<p style={{color:'#212121',}}>20) I confirm to provide testimonial, visa copy & flight detail before leaving from India.</p>
<p style={{color:'#212121',}}>21) I confirm that I have read and understood the above terms and conditions and I wish to offer my case to Study Metro Edu Cons Pvt Ltd.</p>
<p style={{color:'#212121',}}>22) We do not charge any further processing fee for getting scholarships from our partner abroad universities. </p>
<p style={{color:'#212121',}}>23) Comments about Study Metro Service here - https://www.Studymetro.com</p>
</div>





</div>


                                       

 <div className="content" style={{paddingBottom:0,}}>
                                         

     <div className="col-md-12">
   <div className="content" style={{paddingBottom:0,}} >
                                
                          </div>
                                </div>
                          
                                        </div>




                                    </div>
                                </div>
                            </div>

                            </div>

                      
                    </div>
                  
  
                </div>
            </div>
        </div>
    </div>
  

</div>
</div>
</div>
</div>



<Footer/>
    </Container>
  );
}
