/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { postDataAndImage, postData, getData } from "../FetchServices";
import { postDataAndImageLocal, postDataLocal, getDataLocal } from "../FetchServicesLocal";
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Autocomplete from "@material-ui/lab/Autocomplete";
import mobiscroll from "@mobiscroll/react-lite";
import "@mobiscroll/react-lite/dist/css/mobiscroll.min.css";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";
import Header from "./Header";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },

  input: {
    padding: "0px 0px",
  },
  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 16,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    // margin: 'center',
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },
  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#002147",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function ApplyProgram(props) {
  const classes = useStyles();
  const [messages, setMessages] = React.useState("");
  const [getStatus, setStatus] = React.useState("");

  // New update state
  const [getUniversitiesList, setUniversitiesList] = useState([]);
  const [getProgramsList, setProgramsList] = useState([]);

  const [getStudentUniqueId, setStudentUniqueId] = useState('')
  const [getStudent_Uni_ID, setstudent_Uni_ID] = useState('')
  const [getStudentUniqueName, setStudentUniqueName] = useState('')

  const [getUniversityUniqueId, setUniversityUniqueId] = useState('')
  const [getUniversityUniqueName, setUniversityUniqueName] = useState('')

  const [getProgramUniqueId, setProgramUniqueId] = useState('')
  const [getProgramUniqueName, setProgramUniqueName] = useState('')

  const [getApplyDate, setApplyDate] = useState('')
  const [getCountry, setCountry] = useState('')
  const [getCampusName, setCampusName] = useState('')
  const [getAccountManager, setAccountManager] = useState('')
  const [getIntake, setIntake] = useState('')
  const [getStage, setStage] = useState('')
  const [getStudentNameProgram, setStudentNameProgram] = useState('')


const fetchusername = async (sid) => {
    var body={sid : sid}
    var list = await postDataLocal("profilebuilder/displayinfopb", body);
    // distribute(list.data)
    setStudentUniqueName(list.data[0].username)
  };

  const initDate = () => {
    var cd = new Date();
    var d = "";
    var m = "";
    if (cd.getDate() <= 9) {
      d = "0" + cd.getDate();
    } else {
      d = cd.getDate();
    }
    if (cd.getMonth() <= 8) {
      m = "0" + (cd.getMonth() + 1);
    } else {
      m = cd.getMonth();
    }
    var setcd = cd.getFullYear() + "-" + m + "-" + d;
    setApplyDate(setcd)
  }

  useEffect(function () {
    initDate();
    // localStorageData();
  }, []);

  const localStorageData = async () => {
    try {
      let data = await localStorage.getItem("student");
    if(data){
      let result = JSON.parse(data);
      setstudent_Uni_ID(result.student_ID);
      fetchusername(result.student_ID);
    } else{
      props.history.replace({pathname:'/Login'})
    }
    } catch (error) {
      props.history.replace({pathname:'/Login'})
    }
    
    
  };

  // fetching universities data 

const Country=(e)=>{
setCountry(e.target.value)
fetchUniversityAll(e.target.value);
}

  const fetchprogbyuni=async(a)=>{
    var body={ID:a}
    let result = await postData("abroad/fetchbyuniversityid",body);
    setProgramsList(result.data);
  }


  const fetchUniversityAll = async (a) => {
    var body={'scountry':a}
    let list = await postData("universities/DisplayAllUniversityInAN",body);
    // console.log('CCCCCCCCCC',list)
    setUniversitiesList(list);
  };

  // fetching all programs data 
  // const fetchProgramAll = async () => {
  //   let list = await getData("abroad/DisplayAllPrograms");
  //   setProgramsList(list);
  // };

  // console.log(getProgramsList)

  // const universities = [
  //   { title: "ACPE - Australian College of Physical Education", year: 1994 },
  //   { title: "Australian National Airline College", year: 1972 },
  //   { title: "Cambridge International College", year: 1974 },
  // ];


  // ACPE - university

  // const student = [
  //   { id: 16904, title: "Rohit Pal", year: 1994 },
  //   { id: 16904, title: "Suresh", year: 1972 },
  //   { id: 16904, title: "Lorem Ispum", year: 1974 },
  // ];

  // const program = [
  //   { id: 10, title: "Australian National University", year: 1994 },
  //   { id: 10, title: "Australian Academy of Management & Science", year: 1972 },
  //   { id: 10, title: "Barkly International College", year: 1974 },
  // ];

  const countries = [
    { id: 1, title: "India" },
    { id: 2, title: "USA"},
    { id: 3, title: "Singapore" },
  ];

  const stageList = [
    { id: 1, title: "Draft 1" },
    { id: 2, title: "Draft 2"},
    { id: 3, title: "Draft 3" },
  ];

  const intakeList = [
    { id: 1, title: "January" },
    { id: 2, title: "February"},
    { id: 3, title: "March" },
    { id: 4, title: "April" },
    { id: 5, title: "May"},
    { id: 6, title: "June" },
    { id: 7, title: "July" },
    { id: 8, title: "August"},
    { id: 9, title: "September" },
    { id: 10, title: "October" },
    { id: 11, title: "November"},
    { id: 12, title: "December" },
  ];

  const addNewRecord = async () => {
     setMessages(<font color="black">Please wait..</font>);
  var body = {
    student_Uni_ID: JSON.parse(localStorage.getItem("student")).student_ID,
    studentUniqueName:getStudentUniqueName,
    university_Uni_ID: getUniversityUniqueId,
    universityUniqueName:getUniversityUniqueName,
    program_Uni_ID: getProgramUniqueId,
    programUniqueName:getProgramUniqueName,
    applyDate: getApplyDate,
    country: getCountry,
    campusName: getCampusName,
    account_Manager:"Sakshi",
    student_Name_Program:getStudentNameProgram,
    intake:getIntake,
    stage:getStage
      };

      // console.log(body)

      var result = await postDataLocal("applyApplication/fillApplicationData", body);
      console.log(result)
      // alert(result[0])
      if(result[0].status){
        await setMessages(<font color="green">Application Submitted Successfully..</font>);
      }
      else{
        await setMessages(<font color="red">Failed to submit Application..</font>);
      }

    // These comment data are previous used code to submit form 

    // if (picture == "") {
    //   var body = {
    //     firstName: firstName,
    //     lastName: lastName,
    //     emailId: emailId,
    //     password: password,
    //     status: "Not Approved",
    //     picture: "Not Found",
    //   };
    //   var result = await postData("selfregister/addNewRecord", body);
      // if (result) {
      //   await setMessages("Agent Succefully Created");

      //   var body = {
      //     emailid: emailId,
      //     password: password,
      //   };
      //   var resultt = await postData("selfregister/checkagentsignin", body);

      //   if (resultt.RESULT) {
      //     localStorage.setItem("AGENT", JSON.stringify(resultt.RESULT)); // JSON.stringify use for change json to string
      //     props.history.push({ pathname: "/Dashboard" }); // we can use push(back) or replace(not back)
      //   } else {
      //     alert("Failed To Created Account");
      //   }

    //     await setFirstName("");
    //     await setLastName("");
    //     await setEmailId("");
    //     await setPassword("");
    //     await setVerifyPassword("");
    //     await setPicturePath("");
    //   } else {
    //     setMessages("Faild to Submit");
    //   }
    // } else {
    //   var formData = new FormData();
    //   formData.append("firstName", firstName);
    //   formData.append("lastName", lastName);
    //   formData.append("emailId", emailId);
    //   formData.append("password", password);
    //   formData.append("picture", picture);
    //   formData.append("status", "Not Approve");

      // var config = { headers: { "content-type": "multipart/form-data" } };
      // var result = await postDataAndImage(
      //   "selfregister/addNewRecordPic",
      //   formData,
      //   config
      // );

      // if (result) {
      //   await setMessages("Agent Succefully Created");

      //   var body = {
      //     emailid: emailId,
      //     password: password,
      //   };
      //   var resultt = await postData("selfregister/checkagentsignin", body);

      //   if (resultt.RESULT) {
      //     localStorage.setItem("AGENT", JSON.stringify(resultt.RESULT)); // JSON.stringify use for change json to string
      //     props.history.push({ pathname: "/Dashboard" }); // we can use push(back) or replace(not back)
      //   } else {
      //     alert("Failed To Created Account");
      //   }

      //   await setFirstName("");
      //   await setLastName("");
      //   await setEmailId("");
      //   await setPassword("");
      //   await setVerifyPassword("");
      //   await setPicturePath("");
      // } else {
      //   setMessages("Faild to Submit");
      // }
    // }
  };

  // console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />
      <div
        className="login-area default-padding"
        style={{ backgroundColor: "#f3f3f3" }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div className="col-md-6 col-md-offset-1">
                <img
                  style={{ flex: 1, marginTop: 100 }}
                  class="login_image"
                  src="assets/img/job.png"
                  alt="Login"
                  data-original-title=""
                  title=""
                />
              </div>
              <div className="col-md-5">
                <Container>
                  <CssBaseline />
                  <MDBContainer>
                    <MDBRow>
                      <MDBCol md="12">
                        <MDBCard>
                          <MDBCardBody>
                            <MDBCardHeader
                              className="form-header deep-blue-gradient rounded"
                              style={{
                                color: "white",
                                textAlign: "center",
                                padding: "2px 2px",
                              }}
                            >
                              <h4 className="my-3">
                                <MDBIcon icon="building" /> Apply Application
                              </h4>
                            </MDBCardHeader>
                            <div className={classes.paper}>
                              <form className={classes.form} noValidate>
                                <Grid container spacing={2}>
                               
                                  <Grid item xs={12}>
                                  <TextField
                                            name="firstName"
                                            //error={firstNameError}
                                            variant="outlined"
                                            className="input-error"
                                            fullWidth
                                            // onChange={(event)=>setStudentUniqueName(event.target.value)}
                                            value={getStudentUniqueName}
                                            label="Student Name"
                                            type="text"
                                            autoFocus
                                          />
                                
                                  </Grid>
                                  <Grid item xs={12}>
                                    {/* <Autocomplete
                                      options={countries}
                                      // value={getCountry}
                                      getOptionLabel={(option) => option.title}
                                      onChange={(event,value)=> setCountry(value.title)}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          
                                          variant="outlined"
                                          id="demo-simple-select-outlined"
                                          label=" Search Country"
                                          fullWidth
                                          InputLabelProps={{
                                            style: { fontSize: 13 },
                                          }}
                                        />
                                      )}
                                    /> */}

                                  <TextField
                                            id="outlined-select-currency"
                                            select
                                            label="Search Country"
                                            fullWidth
                                            onChange={(event) =>
                                             Country(event)
                                            }
                                            // value={getCountry}
                                            variant="outlined"
                                          >
                                            <MenuItem value={"Afghanistan"}>
                                              Afghanistan
                                            </MenuItem>
                                            <MenuItem value={"Albania"}>
                                              Albania
                                            </MenuItem>
                                            <MenuItem value={"Algeria"}>
                                              Algeria
                                            </MenuItem>
                                            <MenuItem value={"Andorra"}>
                                              Andorra
                                            </MenuItem>
                                            <MenuItem value={"Angola"}>
                                              Angola
                                            </MenuItem>
                                            <MenuItem
                                              value={"Antigua and Barbuda"}
                                            >
                                              Antigua and Barbuda
                                            </MenuItem>
                                            <MenuItem value={"Argentina"}>
                                              Argentina
                                            </MenuItem>
                                            <MenuItem value={"Armenia"}>
                                              Armenia
                                            </MenuItem>
                                            <MenuItem value={"Australia"}>
                                              Australia
                                            </MenuItem>
                                            <MenuItem value={"Austria"}>
                                              Austria
                                            </MenuItem>
                                            <MenuItem value={"Austrian Empire"}>
                                              Austrian Empire
                                            </MenuItem>
                                            <MenuItem value={"Azerbaijan"}>
                                              Azerbaijan
                                            </MenuItem>

                                            <MenuItem value={"Bahamas"}>
                                              Bahamas
                                            </MenuItem>
                                            <MenuItem value={"Bahrain"}>
                                              Bahrain
                                            </MenuItem>
                                            <MenuItem value={"Bangladesh"}>
                                              Bangladesh
                                            </MenuItem>
                                            <MenuItem value={"Barbados"}>
                                              Barbados
                                            </MenuItem>
                                            <MenuItem value={"Belarus"}>
                                              Belarus
                                            </MenuItem>
                                            <MenuItem value={"Belgium"}>
                                              Belgium
                                            </MenuItem>
                                            <MenuItem value={"Belize"}>
                                              Belize
                                            </MenuItem>
                                            <MenuItem value={"Benin"}>
                                              Benin
                                            </MenuItem>
                                            <MenuItem value={"Bolivia"}>
                                              Bolivia
                                            </MenuItem>
                                            <MenuItem
                                              value={"Bosnia and Herzegovina"}
                                            >
                                              Bosnia and Herzegovina
                                            </MenuItem>
                                            <MenuItem value={"Botswana"}>
                                              Botswana
                                            </MenuItem>
                                            <MenuItem value={"Brazil"}>
                                              Brazil
                                            </MenuItem>
                                            <MenuItem value={"Brunei"}>
                                              Brunei
                                            </MenuItem>
                                            <MenuItem
                                              value={"Brunswick and LÃ¼neburg"}
                                            >
                                              Brunswick and LÃ¼neburg
                                            </MenuItem>
                                            <MenuItem value={"Bulgaria"}>
                                              Bulgaria
                                            </MenuItem>
                                            <MenuItem value={"Burkina Faso"}>
                                              Burkina Faso
                                            </MenuItem>
                                            <MenuItem value={"Burma"}>
                                              Burma
                                            </MenuItem>
                                            <MenuItem value={"Burundi"}>
                                              Burundi
                                            </MenuItem>
                                            <MenuItem value={"Cambodia"}>
                                              Cambodia
                                            </MenuItem>
                                            <MenuItem value={"Cameroon"}>
                                              Cameroon
                                            </MenuItem>
                                            <MenuItem value={"Canada"}>
                                              Canada
                                            </MenuItem>
                                            <MenuItem value={"Cape Verde"}>
                                              Cape Verde
                                            </MenuItem>
                                            <MenuItem
                                              value={"Central African Republic"}
                                            >
                                              Central African Republic
                                            </MenuItem>
                                            <MenuItem value={"Chad"}>
                                              Chad
                                            </MenuItem>
                                            <MenuItem value={"Chile"}>
                                              Chile
                                            </MenuItem>
                                            <MenuItem value={"China"}>
                                              China
                                            </MenuItem>
                                            <MenuItem value={"Colombia"}>
                                              Colombia
                                            </MenuItem>
                                            <MenuItem value={"Comoros"}>
                                              Comoros
                                            </MenuItem>
                                            <MenuItem
                                              value={"Congo, Republic of the"}
                                            >
                                              Congo, Republic of the
                                            </MenuItem>
                                            <MenuItem
                                              value={
                                                "Congo, Democratic Republic of the"
                                              }
                                            >
                                              Congo, Democratic Republic of the
                                            </MenuItem>
                                            <MenuItem value={"Costa Rica"}>
                                              Costa Rica
                                            </MenuItem>
                                            <MenuItem value={"Cote d'Ivoire"}>
                                              Cote d'Ivoire
                                            </MenuItem>
                                            <MenuItem value={"Croatia"}>
                                              Croatia
                                            </MenuItem>
                                            <MenuItem value={"Cuba"}>
                                              Cuba
                                            </MenuItem>
                                            <MenuItem value={"Cyprus"}>
                                              Cyprus
                                            </MenuItem>
                                            <MenuItem value={"Czech Republic"}>
                                              Czech Republic
                                            </MenuItem>
                                            <MenuItem value={"Denmark"}>
                                              Denmark
                                            </MenuItem>
                                            <MenuItem value={"Djibouti"}>
                                              Djibouti
                                            </MenuItem>
                                            <MenuItem value={"Dominica"}>
                                              Dominica
                                            </MenuItem>
                                            <MenuItem
                                              value={"Dominican Republic"}
                                            >
                                              Dominican Republic
                                            </MenuItem>
                                            <MenuItem value={"East Timor"}>
                                              East Timor
                                            </MenuItem>
                                            <MenuItem value={"Ecuador"}>
                                              Ecuador
                                            </MenuItem>
                                            <MenuItem value={"Egypt"}>
                                              Egypt
                                            </MenuItem>
                                            <MenuItem value={"El Salvador"}>
                                              El Salvador
                                            </MenuItem>
                                            <MenuItem
                                              value={"Equatorial Guinea"}
                                            >
                                              Equatorial Guinea
                                            </MenuItem>
                                            <MenuItem value={"Eritrea"}>
                                              Eritrea
                                            </MenuItem>
                                            <MenuItem value={"Estonia"}>
                                              Estonia
                                            </MenuItem>
                                            <MenuItem value={"Ethiopia"}>
                                              Ethiopia
                                            </MenuItem>
                                            <MenuItem value={"Fiji"}>
                                              Fiji
                                            </MenuItem>
                                            <MenuItem value={"Finland"}>
                                              Finland
                                            </MenuItem>
                                            <MenuItem value={"France"}>
                                              France
                                            </MenuItem>
                                            <MenuItem value={"Gabon"}>
                                              Gabon
                                            </MenuItem>
                                            <MenuItem value={"The Gambia"}>
                                              The Gambia
                                            </MenuItem>
                                            <MenuItem value={"Georgia"}>
                                              Georgia
                                            </MenuItem>
                                            <MenuItem value={"Germany"}>
                                              Germany
                                            </MenuItem>
                                            <MenuItem value={"Ghana"}>
                                              Ghana
                                            </MenuItem>
                                            <MenuItem value={"Greece"}>
                                              Greece
                                            </MenuItem>
                                            <MenuItem value={"Grenada"}>
                                              Grenada
                                            </MenuItem>
                                            <MenuItem value={"Guatemala"}>
                                              Guatemala
                                            </MenuItem>
                                            <MenuItem value={"Guinea"}>
                                              Guinea
                                            </MenuItem>
                                            <MenuItem value={"Guinea-Bissau"}>
                                              Guinea-Bissau
                                            </MenuItem>
                                            <MenuItem value={"Guyana"}>
                                              Guyana
                                            </MenuItem>
                                            <MenuItem value={"Haiti"}>
                                              Haiti
                                            </MenuItem>
                                            <MenuItem value={"Honduras"}>
                                              Honduras
                                            </MenuItem>
                                            <MenuItem value={"Hungary"}>
                                              Hungary
                                            </MenuItem>
                                            <MenuItem value={"Iceland"}>
                                              Iceland
                                            </MenuItem>
                                            <MenuItem value={"India"}>
                                              India
                                            </MenuItem>
                                            <MenuItem value={"Indonesia"}>
                                              Indonesia
                                            </MenuItem>
                                            <MenuItem value={"Iran"}>
                                              Iran
                                            </MenuItem>
                                            <MenuItem value={"Iraq"}>
                                              Iraq
                                            </MenuItem>
                                            <MenuItem value={"Ireland"}>
                                              Ireland
                                            </MenuItem>
                                            <MenuItem value={"Israel"}>
                                              Israel
                                            </MenuItem>
                                            <MenuItem value={"Italy"}>
                                              Italy
                                            </MenuItem>
                                            <MenuItem value={"Jamaica"}>
                                              Jamaica
                                            </MenuItem>
                                            <MenuItem value={"Japan"}>
                                              Japan
                                            </MenuItem>
                                            <MenuItem value={"Jordan"}>
                                              Jordan
                                            </MenuItem>
                                            <MenuItem value={"Kazakhstan"}>
                                              Kazakhstan
                                            </MenuItem>
                                            <MenuItem value={"Kenya"}>
                                              Kenya
                                            </MenuItem>
                                            <MenuItem value={"Kiribati"}>
                                              Kiribati
                                            </MenuItem>
                                            <MenuItem value={"Korea, North"}>
                                              Korea, North
                                            </MenuItem>
                                            <MenuItem value={"Korea, South<"}>
                                              Korea, South
                                            </MenuItem>
                                            <MenuItem value={"Kosovo"}>
                                              Kosovo
                                            </MenuItem>
                                            <MenuItem value={"Kuwait"}>
                                              Kuwait
                                            </MenuItem>
                                            <MenuItem value={"Kyrgyzstan"}>
                                              Kyrgyzstan
                                            </MenuItem>
                                            <MenuItem value={"Laos"}>
                                              Laos
                                            </MenuItem>
                                            <MenuItem value={"Latvia"}>
                                              Latvia
                                            </MenuItem>
                                            <MenuItem value={"Lebanon"}>
                                              Lebanon
                                            </MenuItem>
                                            <MenuItem value={"Lesotho"}>
                                              Lesotho
                                            </MenuItem>
                                            <MenuItem value={"Liberia"}>
                                              Liberia
                                            </MenuItem>
                                            <MenuItem value={"Libya"}>
                                              Libya
                                            </MenuItem>
                                            <MenuItem value={"Liechtenstein"}>
                                              Liechtenstein
                                            </MenuItem>
                                            <MenuItem value={"Lithuania"}>
                                              Lithuania
                                            </MenuItem>
                                            <MenuItem value={"Luxembourg"}>
                                              Luxembourg
                                            </MenuItem>
                                            <MenuItem value={"Macedonia"}>
                                              Macedonia
                                            </MenuItem>
                                            <MenuItem value={"Madagascar"}>
                                              Madagascar
                                            </MenuItem>
                                            <MenuItem value={"Malawi"}>
                                              Malawi
                                            </MenuItem>
                                            <MenuItem value={"Malaysia"}>
                                              Malaysia
                                            </MenuItem>
                                            <MenuItem value={"Maldives"}>
                                              Maldives
                                            </MenuItem>
                                            <MenuItem value={"Mali"}>
                                              Mali
                                            </MenuItem>
                                            <MenuItem value={"Malta"}>
                                              Malta
                                            </MenuItem>
                                            <MenuItem
                                              value={"Marshall Islands"}
                                            >
                                              Marshall Islands
                                            </MenuItem>
                                            <MenuItem value={"Mauritania"}>
                                              Mauritania
                                            </MenuItem>
                                            <MenuItem value={"Mauritius"}>
                                              Mauritius
                                            </MenuItem>
                                            <MenuItem value={"Mexico"}>
                                              Mexico
                                            </MenuItem>
                                            <MenuItem value={"Micronesia"}>
                                              Micronesia
                                            </MenuItem>
                                            <MenuItem value={"Moldova"}>
                                              Moldova
                                            </MenuItem>
                                            <MenuItem value={"Monaco"}>
                                              Monaco
                                            </MenuItem>
                                            <MenuItem value={"Mongolia"}>
                                              Mongolia
                                            </MenuItem>
                                            <MenuItem value={"Montenegro"}>
                                              Montenegro
                                            </MenuItem>
                                            <MenuItem value={"Morocco"}>
                                              Morocco
                                            </MenuItem>
                                            <MenuItem value={"Mozambique"}>
                                              Mozambique
                                            </MenuItem>
                                            <MenuItem value={"Myanmar"}>
                                              Myanmar
                                            </MenuItem>
                                            <MenuItem value={"Namibia"}>
                                              Namibia
                                            </MenuItem>
                                            <MenuItem value={"Nauru"}>
                                              Nauru
                                            </MenuItem>
                                            <MenuItem value={"Nepal"}>
                                              Nepal
                                            </MenuItem>
                                            <MenuItem value={"Netherlands"}>
                                              Netherlands
                                            </MenuItem>
                                            <MenuItem value={"New Zealand"}>
                                              New Zealand
                                            </MenuItem>
                                            <MenuItem value={"Nicaragua"}>
                                              Nicaragua
                                            </MenuItem>
                                            <MenuItem value={"Niger"}>
                                              Niger
                                            </MenuItem>
                                            <MenuItem value={"Nigeria"}>
                                              Nigeria
                                            </MenuItem>
                                            <MenuItem value={"Norway"}>
                                              Norway
                                            </MenuItem>
                                            <MenuItem value={"Oman"}>
                                              Oman
                                            </MenuItem>
                                            <MenuItem value={"Pakistan"}>
                                              Pakistan
                                            </MenuItem>
                                            <MenuItem value={"Palau"}>
                                              Palau
                                            </MenuItem>
                                            <MenuItem value={"Panama"}>
                                              Panama
                                            </MenuItem>
                                            <MenuItem
                                              value={"Papua New Guinea"}
                                            >
                                              Papua New Guinea
                                            </MenuItem>
                                            <MenuItem value={"Paraguay"}>
                                              Paraguay
                                            </MenuItem>
                                            <MenuItem value={"Peru"}>
                                              Peru
                                            </MenuItem>
                                            <MenuItem value={"Philippines"}>
                                              Philippines
                                            </MenuItem>
                                            <MenuItem value={"Poland"}>
                                              Poland
                                            </MenuItem>
                                            <MenuItem value={"Portugal"}>
                                              Portugal
                                            </MenuItem>
                                            <MenuItem value={"Qatar"}>
                                              Qatar
                                            </MenuItem>
                                            <MenuItem value={"Romania"}>
                                              Romania
                                            </MenuItem>
                                            <MenuItem value={"Russia"}>
                                              Russia
                                            </MenuItem>
                                            <MenuItem value={"Rwanda"}>
                                              Rwanda
                                            </MenuItem>
                                            <MenuItem
                                              value={"Saint Kitts and Nevis"}
                                            >
                                              Saint Kitts and Nevis
                                            </MenuItem>
                                            <MenuItem value={"Saint Lucia"}>
                                              Saint Lucia
                                            </MenuItem>
                                            <MenuItem
                                              value={
                                                "Saint Vincent and the Grenadines"
                                              }
                                            >
                                              Saint Vincent and the Grenadines
                                            </MenuItem>
                                            <MenuItem value={"Samoa"}>
                                              Samoa
                                            </MenuItem>
                                            <MenuItem value={"San Marino"}>
                                              San Marino
                                            </MenuItem>
                                            <MenuItem
                                              value={"Sao Tome and Principe"}
                                            >
                                              Sao Tome and Principe
                                            </MenuItem>
                                            <MenuItem value={"Saudi Arabia"}>
                                              Saudi Arabia
                                            </MenuItem>
                                            <MenuItem value={"Senegal"}>
                                              Senegal
                                            </MenuItem>
                                            <MenuItem value={"Serbia"}>
                                              Serbia
                                            </MenuItem>
                                            <MenuItem value={"Seychelles"}>
                                              Seychelles
                                            </MenuItem>
                                            <MenuItem value={"Sierra Leone"}>
                                              Sierra Leone
                                            </MenuItem>
                                            <MenuItem value={"Singapore"}>
                                              Singapore
                                            </MenuItem>
                                            <MenuItem value={"Slovakia"}>
                                              Slovakia
                                            </MenuItem>
                                            <MenuItem value={"Slovenia"}>
                                              Slovenia
                                            </MenuItem>
                                            <MenuItem value={"Solomon Islands"}>
                                              Solomon Islands
                                            </MenuItem>
                                            <MenuItem value={"Somalia"}>
                                              Somalia
                                            </MenuItem>
                                            <MenuItem value={"South Africa"}>
                                              South Africa
                                            </MenuItem>
                                            <MenuItem value={"South Sudan"}>
                                              South Sudan
                                            </MenuItem>
                                            <MenuItem value={"Spain"}>
                                              Spain
                                            </MenuItem>
                                            <MenuItem value={"Sri Lanka"}>
                                              Sri Lanka
                                            </MenuItem>
                                            <MenuItem value={"Sudan"}>
                                              Sudan
                                            </MenuItem>
                                            <MenuItem value={"Suriname"}>
                                              Suriname
                                            </MenuItem>
                                            <MenuItem value={"Swaziland"}>
                                              Swaziland
                                            </MenuItem>
                                            <MenuItem value={"Sweden"}>
                                              Sweden
                                            </MenuItem>
                                            <MenuItem value={"Switzerland"}>
                                              Switzerland
                                            </MenuItem>
                                            <MenuItem value={"Syria"}>
                                              Syria
                                            </MenuItem>
                                            <MenuItem value={"Taiwan"}>
                                              Taiwan
                                            </MenuItem>
                                            <MenuItem value={"Tajikistan"}>
                                              Tajikistan
                                            </MenuItem>
                                            <MenuItem value={"Tanzania"}>
                                              Tanzania
                                            </MenuItem>
                                            <MenuItem value={"Thailand"}>
                                              Thailand
                                            </MenuItem>
                                            <MenuItem value={"Togo"}>
                                              Togo
                                            </MenuItem>
                                            <MenuItem value={"Tonga"}>
                                              Tonga
                                            </MenuItem>
                                            <MenuItem
                                              value={"Trinidad and Tobago"}
                                            >
                                              Trinidad and Tobago
                                            </MenuItem>
                                            <MenuItem value={"Tunisia"}>
                                              Tunisia
                                            </MenuItem>
                                            <MenuItem value={"Turkey"}>
                                              Turkey
                                            </MenuItem>
                                            <MenuItem value={"Turkmenistan"}>
                                              Turkmenistan
                                            </MenuItem>
                                            <MenuItem value={"Tuvalu"}>
                                              Tuvalu
                                            </MenuItem>
                                            <MenuItem value={"Uganda"}>
                                              Uganda
                                            </MenuItem>
                                            <MenuItem value={"Ukraine"}>
                                              Ukraine
                                            </MenuItem>
                                            <MenuItem
                                              value={"United Arab Emirates"}
                                            >
                                              United Arab Emirates
                                            </MenuItem>
                                            <MenuItem value={"United Kingdom"}>
                                              United Kingdom
                                            </MenuItem>
                                            <MenuItem
                                              value={"United States of America"}
                                            >
                                              United States of America
                                            </MenuItem>
                                            <MenuItem value={"Uruguay"}>
                                              Uruguay
                                            </MenuItem>
                                            <MenuItem value={"Uzbekistan"}>
                                              Uzbekistan
                                            </MenuItem>
                                            <MenuItem value={"Vanuatu"}>
                                              Vanuatu
                                            </MenuItem>
                                            <MenuItem value={"Vatican City"}>
                                              Vatican City
                                            </MenuItem>
                                            <MenuItem value={"Venezuela"}>
                                              Venezuela
                                            </MenuItem>
                                            <MenuItem value={"Vietnam"}>
                                              Vietnam
                                            </MenuItem>
                                            <MenuItem value={"Yemen"}>
                                              Yemen
                                            </MenuItem>
                                            <MenuItem value={"Zambia"}>
                                              Zambia
                                            </MenuItem>
                                            <MenuItem value={"Zimbabwe"}>
                                              Zimbabwe
                                            </MenuItem>

                                            </TextField>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <Autocomplete
                                      options={getUniversitiesList}
                                      getOptionLabel={(option) => option.name}
                                      onChange={(event,value)=> {
                                        setUniversityUniqueId(value.id)
                                        setUniversityUniqueName(value.name)
                                        fetchprogbyuni(value.id)
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="outlined"
                                          id="demo-simple-select-outlined"
                                          label=" Search University"
                                          fullWidth
                                          InputLabelProps={{
                                            style: { fontSize: 13 },
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Autocomplete
                                      options={getProgramsList}
                                      getOptionLabel={(option) => option.name}
                                      onChange={(event,value)=> {
                                        // alert(value.id)
                                        setProgramUniqueId(value.id)                                   
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="outlined"
                                          id="demo-simple-select-outlined"
                                          label=" Search Program"
                                          fullWidth
                                          InputLabelProps={{
                                            style: { fontSize: 13 },
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                  </Grid>

                                
                                  <Grid item xs={12}>
                                    <TextField
                                      id="date"
                                      label="Apply Date"
                                      value={getApplyDate}
                                      type="date"
                                      onChange={(event)=>setApplyDate(event.target.value)}
                                      defaultValue="DD-MM-YYYY"
                                      className={classes.formControl}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                  </Grid>
                                  
                                </Grid>

                                <div className="text-center mt-3">
                                  <MDBBtn
                                    gradient="blue"
                                    rounded
                                    variant="contained"
                                    onClick={addNewRecord}
                                    color="primary"
                                    className="btn-block z-depth-1a"
                                    fullWidth
                                  >
                                    Submit
                                  </MDBBtn>
                                </div>
                                <br/>
                                <Typography>{messages}</Typography>

                                <br />
                              </form>
                            </div>
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
