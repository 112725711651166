/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from './Header';
import Footer from './Footer';


import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';



const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
newbtn:{
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
},

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:12,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },

widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const [firstName,setFirstName]=React.useState('')
  const [lastName,setLastName]=React.useState('')
  const [emailId, setEmailId]=React.useState('')
  const [password,setPassword]=React.useState('')
  const [verifyPassword,setVerifyPassword]=React.useState('')
  const [messages, setMessages ]=React.useState('')
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus,setStatus]=React.useState('') 

  const handlePicture=(event)=>{
    setPicturePath(URL.createObjectURL(event.target.files[0]))
    setPicture(event.target.files[0])
   }

   const handleVerifyPassword=(e)=>{
    setVerifyPassword(e.target.value)
    if(password===e.target.value){
      setStatus("Password Matched")
    }
    else{
      setStatus("Password not matched")
    }
  }


//   const addNewRecord=async()=>{
// if(picture=='')
// {
//     var body={'firstName':firstName,
//     'lastName':lastName, 
//     'emailId':emailId, 
//     'password':password,
//     'status':"Not Approved",
//     'picture':"Not Found"}
//     var result=await postData('selfregister/addNewRecord',body)
//     if(result){
//       await setMessages("Agent Succefully Created")

//       var body={
//         'emailid':emailId,
//         'password':password
//        }
// var resultt = await  postData('selfregister/checkagentsignin',body)

// if (resultt.RESULT)
// {

// localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
// props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
// }
// else
// {
// alert("Failed To Created Account")
// }

// await setFirstName('')
// await setLastName('')
// await setEmailId('')
// await setPassword('')
// await setVerifyPassword('')
// await setPicturePath('')
// }
// else{setMessages("Faild to Submit")}

// }

// else{
//    var formData=new FormData()
//    formData.append('firstName',firstName)
//    formData.append('lastName',lastName)
//    formData.append('emailId',emailId)
//    formData.append('password',password)
//    formData.append('picture',picture)
//    formData.append('status', "Not Approve")
  
//    var config={headers:{'content-type':'multipart/form-data'}}  
//     var result=await postDataAndImage('selfregister/addNewRecordPic',formData,config)


//     if(result){
//       await setMessages("Agent Succefully Created")

//       var body={
//         'emailid':emailId,
//         'password':password
//        }
// var resultt = await  postData('selfregister/checkagentsignin',body)

// if (resultt.RESULT)
// {

// localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
// props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
// }
// else
// {
// alert("Failed To Created Account")
// }

// await setFirstName('')
// await setLastName('')
// await setEmailId('')
// await setPassword('')
// await setVerifyPassword('')
// await setPicturePath('')
// }
// else{setMessages("Faild to Submit")}
//     }
//     }
   
   
    console.log("History ==  ",props)
  return (
   <Container className={classes.widths}>
    
    <Header/> <CssBaseline />
    <div className="login-area default-padding" style={{paddingTop:0,paddingBottom:0,}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">
<div className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard" style={{backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",padding:70}}>             
{/* <div className="banner-area inc-form bg-gradient slider-less text-light" style={{backgroundImage: "url(" + "../assets/img/event/1.jpg" + ")", padding:70}}> */}
        <div className="container">
            <div className="row">
                {/* <div className="double-items">
                    <div className="col-md-7 info">
                        <h2 className="wow fadeInLeft">Find your ideal study program.</h2>
                        <a className="btn day btn-light border btn-md wow fadeInDown" style={{color:'#3f8ccb'}} href="#">View Courses</a>
                    </div>
                    <div className="col-md-5 reg-form">
                        <form action="#">
                            <div className="row">
<img style={{flex:1}} class="login_image" src="assets/img/elearning.png" alt="Login" data-original-title="" title="" />

                            </div>
                        </form>
                    </div>
                </div> */}
                <div className="double-items col-md-12 info">
                <h2 className="wow fadeInLeft">Press & Media</h2>
                </div>
             </div>
        </div>
    </div>

    <div className="about-area default-padding" style={{paddingBottom:0,}}>
        <div className="container">
            <div className="row">
                <div className="about-items">
                    <div className="col-md-6 about-info">
                        <h2>Telling the</h2>
                        <h2>Study Metro Story !</h2>
                        {/* <blockquote style={{fontSize:15, lineHeight:'1', }}>
                        As overseas education consultants, we help students achieve their goals.
                        </blockquote>
                        <p style={{fontSize:14,color:'#212121',textAlign:'justify'}}>
Whether you want to earn your MS in the US, study and work in Canada, or find employment in the USA, we can match you with the right program.
Study Metro is a professional education consultancy firm, extending excellent support and service to students all over India and gives unlimited study opportunities across the Globe. Our aim is to improve educational standards. Scores of parents have sought our advice to find suitable Universities for their children.

                        </p> */}
                       
                    </div>
                    <div className="col-md-6 features text-light">
                       <img style={{flex:1}} class="login_image" src="assets/img/learn.png" alt="Login" data-original-title="" title="" />

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="blog-area single full-blog left-sidebar full-blog default-padding" style={{paddingBottom:0,}}>
        <div className="container" style={{paddingLeft:0,paddingRight:0,}}>
     
            <div className="row">
                <div className="blog-items" style={{width:'100%'}}>
                    <div className="blog-content col-md-12">

                        <div className="content-items">
                            <div className="single-item">
                                <div className="item">
                                    <div className="info">
                                        <div className="content" style={{paddingBottom:0,}}>
<h4>News Room 
Stay up to dat with press Release and latest news:
</h4>
                                        </div>



<div className="content" style={{paddingBottom:0,}}>


<div style={{marginLeft:35,textAlign:'justify'}}>
<h6 style={{fontSize:13,}}><li><a href="http://chhattisgarhprimetime.com/?p=4005">http://chhattisgarhprimetime.com/?p=4005</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="http://sambhavana.com/index.php/component/content/article/84-news/532-200-7-200-7-2020?Itemid=539">http://sambhavana.com/index.php/component/content/article/84-news/532-200-7-200-7-2020?Itemid=539</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="http://sambhavana.com/index.php/national-news/534-more-than-7-thousand-students-associated-with-200-universities-to-dream-of-studying-abroad">http://sambhavana.com/index.php/national-news/534-more-than-7-thousand-students-associated-with-200-universities-to-dream-of-studying-abroad</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="https://milestoneexpress.com/msexpress/1993/">https://milestoneexpress.com/msexpress/1993/</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="https://milestoneexpress.com/msexpress/1997/">https://milestoneexpress.com/msexpress/1997/</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="http://indoremirror.in/students-from-three-countries-participated-in-online-international-education-fair/">http://indoremirror.in/students-from-three-countries-participated-in-online-international-education-fair/</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="http://indoremirror.in/more-than-7-thousand-students-associated-with-200-universities-to-dream-of-studying-abroad/">http://indoremirror.in/more-than-7-thousand-students-associated-with-200-universities-to-dream-of-studying-abroad/</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="http://www.updatenownews.com/study-metro-overseas-education-consultant/">http://www.updatenownews.com/study-metro-overseas-education-consultant/</a></li></h6>

</div>
</div>

<div className="content" style={{paddingBottom:0,}}>
<h4><li>English :</li></h4>

<div style={{marginLeft:35,textAlign:'justify'}}>
<h6 style={{fontSize:13,}}><li><a href="http://sambhavana.com/index.php/national-news/522-over-2500-students-participated-in-the-free-online-international-education-fair-on-the-first-day">http://sambhavana.com/index.php/national-news/522-over-2500-students-participated-in-the-free-online-international-education-fair-on-the-first-day</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="https://milestoneexpress.com/msexpress/1306/">https://milestoneexpress.com/msexpress/1306/</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="http://ibc7news.blogspot.com/2020/04/over-2500-students-participated-in-free.html">http://ibc7news.blogspot.com/2020/04/over-2500-students-participated-in-free.html</a></li></h6>
</div>

</div>

<div className="content" style={{paddingBottom:0,}}>
<h4><li>Hindi :</li></h4>
<div style={{marginLeft:35,}}>
<h6 style={{fontSize:13,}}><li><a href="http://sambhavana.com/index.php/component/content/article/84-news/517-2500?Itemid=539">http://sambhavana.com/index.php/component/content/article/84-news/517-2500?Itemid=539</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="http://chhattisgarhprimetime.com/?p=3855">http://chhattisgarhprimetime.com/?p=3855</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="https://milestoneexpress.com/msexpress/1173">https://milestoneexpress.com/msexpress/1173/</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="https://madhyapradeshdarshan.com/22139/">https://madhyapradeshdarshan.com/22139/</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="http://rameshpbh.blogspot.com/2020/04/online-international-education-fair.html">http://rameshpbh.blogspot.com/2020/04/online-international-education-fair.html</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="https://www.ontimenews.in/it-will-be-held-for-the-first-time-in-the-country-due-to-corona-online-international-education-fair-2020">https://www.ontimenews.in/it-will-be-held-for-the-first-time-in-the-country-due-to-corona-online-international-education-fair-2020/</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="http://dainikaamsabha.com/2020/04/15/corona-will-organize-first-in-the-country-online-international-education-fair-2020/">http://dainikaamsabha.com/2020/04/15/corona-will-organize-first-in-the-country-online-international-education-fair-2020/</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="http://indoremirror.in/online-international-education-fair-2020-to-be-held-for-the-first-time-in-the-country-2/">http://indoremirror.in/online-international-education-fair-2020-to-be-held-for-the-first-time-in-the-country-2/</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="http://www.updatenownews.com/online-international-education-fair-2020/">http://www.updatenownews.com/online-international-education-fair-2020/</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="http://indoremirror.in/online-international-education-fair-2020-to-be-held-for-the-first-time-in-the-country/">http://indoremirror.in/online-international-education-fair-2020-to-be-held-for-the-first-time-in-the-country/</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="https://milestoneexpress.com/msexpress/784/">https://milestoneexpress.com/msexpress/784/</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="https://madhyapradeshdarshan.com/22113/">https://madhyapradeshdarshan.com/22113/</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="http://cnin.co.in/single.php?id=9554">http://cnin.co.in/single.php?id=9554</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="http://sambhavana.com/index.php/national-news/510-2020">http://sambhavana.com/index.php/national-news/510-2020</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="http://keshardeep.com/?p=7627">http://keshardeep.com/?p=7627</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="http://hariyarexpress.com/?p=12506">http://hariyarexpress.com/?p=12506</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="https://bit.ly/2wDbfbY">https://bit.ly/2wDbfbY</a></li></h6>
<h6 style={{fontSize:13,}}><li><a href="https://breakingnews.page/article/korona-ke-chalate-desh-me-pahalee-baar-aayojeet-hoga-on-lain-intaraneshanal-ejukeshan-pheyar-2020-/MKDtX4.html">https://breakingnews.page/article/korona-ke-chalate-desh-me-pahalee-baar-aayojeet-hoga-on-lain-intaraneshanal-ejukeshan-pheyar-2020-/MKDtX4.html</a></li></h6>
</div>
</div>


                                       

 <div className="content" style={{paddingBottom:0,}}>
                                         

  <div className="col-md-12">
   <div className="content" style={{paddingBottom:0,}} >
                                
                          </div>
                                </div>
                          
                                        </div>




                                    </div>
                                </div>
                            </div>

                            </div>

                      
                    </div>
                  
  
                </div>
            </div>
        </div>
    </div>
  

</div>
</div>
</div>
</div>
<div className="about-area default-padding">
        <div className="container" style={{paddingLeft:0,paddingRight:0,}}>
            <div className="row" style={{display:'block'}}>
                <div className="about-items">
                    <div className="col-md-12">
                    
                    <div className="weekly-top-items">
                    <div className="top-courses" style={{paddingRight:0}}>
                        <div className="heading" style={{paddingBottom:15,}}>
                            <h4 style={{color:'#3f8ccb'}}>ACCREDITATIONS AND MEMBERSHIPS <strong></strong></h4>
                        </div>
                    </div>
                    </div>
<div class="col-md-2"><img src="assets/img/footer/EAIE.png" class="logo" alt="Logo" /></div>
<div class="col-md-2"><img src="assets/img/footer/ICEF.png" class="logo" alt="Logo" /></div>
<div class="col-md-2"><img src="assets/img/footer/NAFSA.png" class="logo" alt="Logo" /></div>
<div class="col-md-2"><img src="assets/img/footer/QISAN.jpeg" class="logo" alt="Logo" /></div>
<div class="col-md-2"><img src="assets/img/footer/AIRC.gif" class="logo" alt="Logo" /></div>
<div class="col-md-2"><img src="assets/img/footer/AIEA.png" class="logo" alt="Logo" /></div>


                       
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
    </Container>
  );
}
