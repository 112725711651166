/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function GRE(props) {
  const classes = useStyles();

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 25,
                  marginTop: 30,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">GRE</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="blog-area single full-blog left-sidebar full-blog default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="container"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <div className="row">
                    <div className="blog-items" style={{ width: "100%" }}>
                      <div className="blog-content col-md-12">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      //   marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      The GRE General Test is among the world's
                                      biggest Commonly Known as the GRE, the
                                      Complete type of GRE is your Graduate
                                      Record Examinations. More than
                                      half-a-million people from over 160
                                      nations take the GRE General Test
                                      annually, across 1,000 tests centres setup
                                      by ETS. GRE scores of applicants have been
                                      accepted at thousands of graduate programs
                                      across the globe, for masters and
                                      doctorate levels.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      Over 1,200 business schools across the
                                      globe take that the In this guide, we're
                                      going to speak about every aspect linked
                                      to the GRE Assessment.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      Candidates enrolling from India following
                                      October 7, 2020, will be asked to cover
                                      the newest GRE Registration Fee of US
                                      $213. All other fees for example altering
                                      of GRE Test Centre and Rescheduling of
                                      their GRE examination Stay unchanged.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      ETS includes a series of webinars on its
                                      official Site that familiarizes the
                                      candidates for this new attribute. The
                                      home edition of the GRE test will be
                                      accessible to students until evaluation
                                      centers restart working in total capacity
                                      in the many nations. Candidates must
                                      remember that as a result of continuing
                                      outbreak there would be no hard copy of
                                      the scorecard accessible to the pupil.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      There's been several safety measures
                                      including Artificial Intelligence
                                      technologies and reside proctors which
                                      were embraced by ETS for its smooth
                                      performance of this test in the home.
                                      Accommodation for test-takers having a
                                      handicap can be accessible to students who
                                      need the exact same and on petition. No
                                      other significant changes are caused by
                                      the ETS whilst running the GRE test in the
                                      home. Candidates are asked to go to the
                                      official site for a thorough comprehension
                                      of exactly the exact same. ETS has also
                                      briefly waived off rescheduling fees for
                                      all of the test takers due to the present
                                      catastrophe.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Why choose the GRE Exam?</h6>
                                      Aside from being among the most commonly
                                      accepted examinations by Additionally,
                                      students look for the GRE examination to
                                      procure a merit-based scholarship for
                                      graduate school. The GRE Exam is approved
                                      by thousands of grad colleges throughout
                                      the world. Aside from that, various
                                      company and Law colleges and distinct
                                      branches within those colleges accept GRE
                                      test scores.
                                      <br />
                                      Kinds of GRE Subject Test.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>What's GRE General Test?</h6>Students
                                      seeking entrance to MS classes in
                                      different fields In the united states and
                                      several other states look for GRE General
                                      Test. The GRE Assessment is conducted
                                      round the calendar year, and applicants
                                      can look for the GRE General Test
                                      according to their convenience. A high
                                      number of pupils opt for this evaluation
                                      since nearly all universities throughout
                                      the globe accept GRE scores.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>What's GRE Subject Test?</h6>
                                      The GRE Subject Test assesses the
                                      candidates' capability on a Specific
                                      subject. Typically, this test is needed
                                      for gaining admission to technical
                                      classes. The GRE General Test is provided
                                      year-round as a computer-delivered
                                      evaluation in many locations around the
                                      globe. The Paper-delivered GRE General
                                      Test is provided around 2 times annually
                                      in regions of the planet in which
                                      computer-delivered testing isn't offered.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        What are the Qualification criteria for
                                        GRE Exam?
                                      </h6>
                                      There are no particular eligibility
                                      criteria for your GRE examination.
                                      Everyone can enroll for this examination,
                                      no matter age or credentials. The only
                                      thing a candidate must bear in mind is
                                      that he/she will be asked to make their
                                      initial passport as evidence of identity
                                      in the examination centre, so candidates
                                      have to have a valid passport before they
                                      enroll for the GRE examination.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Age Criteria</h6>There's no age limit
                                      set for applicants wanting to look For
                                      their GRE examination.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Educational Qualification</h6>ETS
                                      hasn't announced any official announcement
                                      regarding Eligibility necessary to look
                                      for GRE.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>GRE Exam Fee</h6>
                                      GRE Subject Test prices $150 globally,
                                      which will cost Indian pupils Rs. 10,756
                                      approximately. Furthermore, in the event
                                      the applicants wish to alter the middle or
                                      reschedule the exam they then would be
                                      asked to pay an excess fee to the same.
                                      <br />
                                      Exam registration must do this no more
                                      than 4 days prior to the exam date or the
                                      GRE examination fee will be forfeited.
                                      Aside from that, candidates are billed $50
                                      if they wish to alter a topic from the GRE
                                      Subject Test.
                                      <br />
                                      Candidates desiring to avail extra
                                      services would be Required to pay an
                                      excess fee to the same. Charges for
                                      specific handling requests for solutions
                                      are cited below.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Services</h6>
                                      GRE. Popular manners whereby aspirants can
                                      enroll for GRE are all online and on the
                                      telephone. Aside from that, aspirants may
                                      also enroll through the email or even by
                                      facsimile. For reserving a slot for your
                                      GRE test applicants would demand a debit
                                      or charge card to pay a registration fee
                                      of US$213 along with a valid passport.
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Tactics to enroll for GRE
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Online
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Telephone
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Mail
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Fax
                                        </li>
                                      </ul>
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>The Way to enroll for GRE online?</h6>
                                      To enroll for GRE through the internet
                                      approach. Candidates will need to make an
                                      ETS account.
                                      <br />
                                      Pick the Kind of GRE test They Wish to
                                      choose - the GRE
                                      <br />
                                      Choose the date when They Wish to look for
                                      the GRE Exam And locate the nearest test
                                      centre.
                                      <br />
                                      Offer their academic particulars.
                                      <br />
                                      Proceed ahead and cover the enrollment fee
                                      of $213.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>GRE Exam Centers</h6>In India, GRE is
                                      ran in nearly 22 cities. All these are
                                      Nearly All them provide computer-based and
                                      a Number of Them provide a paper-based
                                      test
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>GRE Exam Pattern</h6>
                                      GRE examination pattern is as follows -
                                      three segments, namely, Together with the
                                      time period gap, the routine also differs
                                      for paper-based and online examinations.
                                      Candidates appearing to look for the
                                      paper-based structure of the GRE
                                      Assessment may go to the official site as
                                      well.
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Analytical Writing
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Verbal Reasoning
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Quantitative Reasoning
                                        </li>
                                      </ul>
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>GRE Syllabus</h6>The GRE syllabus
                                      Differs for both GRE tests, GRE Students
                                      who would like to pursue technical topics,
                                      have to take the Subject Test which may be
                                      necessary by the college/university
                                      they're seeking entrance for, as Subject
                                      Test concentrates on estimating the
                                      candidate's experience in particular
                                      fields.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>When are GRE effects accessible?</h6>
                                      Accounts on the GRE site 10-15 days after
                                      your exam date. They'll be delivered to
                                      the universities of your choice in just
                                      three weeks of your exam date. After the
                                      results are outside, you'll get an email
                                      from ETS your official GRE outcome can be
                                      found on your accounts. Click here to find
                                      out more on GRE results and scores.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Just how long is GRE score valid?</h6>
                                      Candidates who have appeared to their GRE
                                      examination Ought to Be
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>GRE Cut-Off</h6>GRE is mainly composed
                                      of 3 segments, Analytical Writing, Even
                                      though there's absolutely no blanket
                                      cut-off, each faculty and class accepting
                                      GRE scores could have their very own
                                      cut-off recorded on the official site.
                                      Candidates wanting to have entrance into
                                      high Graduate Faculties are needed to lean
                                      towards the greater margin.
                                    </p>
                                  </div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div className="col-md-12">
                                    <div
                                      className="content"
                                      style={{ paddingBottom: 0 }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area default-padding">
        <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="row" style={{ display: "block" }}>
            <div className="about-items">
              <div className="col-md-12">
                <div className="weekly-top-items">
                  <div className="top-courses" style={{ paddingRight: 0 }}>
                    <div className="heading" style={{ paddingBottom: 15 }}>
                      <h4 style={{ color: "#3f8ccb" }}>
                        ACCREDITATIONS AND MEMBERSHIPS <strong></strong>
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/EAIE.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/ICEF.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/NAFSA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/QISAN.jpeg"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIRC.gif"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIEA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
