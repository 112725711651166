/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function PersonalOnlineMentor(props) {
  const classes = useStyles();

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 25,
                  marginTop: 30,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">Personal Online Mentor</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="blog-area single full-blog left-sidebar full-blog default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="container"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <div className="row">
                    <div className="blog-items" style={{ width: "100%" }}>
                      <div className="blog-content col-md-12">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      //marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      <h6>Personal Online Mentor</h6>
                                      Learn from the very best IELTS and other
                                      courses coaches in India and get your
                                      fantasy band. We supply 1-to-1 training
                                      sessions on Gotomeeting and Skype.
                                      <br />
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Individual Care
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Doubt Solving Sessions
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Normal evaluation and
                                          continuous comments
                                        </li>
                                      </ul>
                                      Flexible and superior instruction is
                                      currently possible with our incredible
                                      video assignments.
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Endless live Lecture for
                                          Improved comprehension
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Videos replete with diagrams
                                          and examples
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Could be accessed anytime
                                        </li>
                                      </ul>
                                      Customized Research Strategy for Each and
                                      Each Student
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Pupils are trained according to
                                          their flaws and strengths through a
                                          tailor-made research Program
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Goal 8 Band Training for Each
                                          section
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Comprehensive Mock Test
                                          Collection
                                        </li>
                                      </ul>
                                      Comprehensive mock evaluations after
                                      conclusion of each module.
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Module, Section and Total mock
                                          tests
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Assessment and feedback after
                                          each evaluation
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Performance based actions plan
                                        </li>
                                      </ul>
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        Book the One to one Meeting with us :
                                        <a href="https://calendly.com/studymetro/ielts-counselling">
                                          https://calendly.com/studymetro/ielts-counselling
                                        </a>
                                      </h6>
                                    </p>
                                  </div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div className="col-md-12">
                                    <div
                                      className="content"
                                      style={{ paddingBottom: 0 }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area default-padding">
        <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="row" style={{ display: "block" }}>
            <div className="about-items">
              <div className="col-md-12">
                <div className="weekly-top-items">
                  <div className="top-courses" style={{ paddingRight: 0 }}>
                    <div className="heading" style={{ paddingBottom: 15 }}>
                      <h4 style={{ color: "#3f8ccb" }}>
                        ACCREDITATIONS AND MEMBERSHIPS <strong></strong>
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/EAIE.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/ICEF.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/NAFSA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/QISAN.jpeg"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIRC.gif"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIEA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
