/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from './Header';
import Footer from './Footer';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';
  





const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
 
input:{
  padding:'0px 0px',
},
  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:16,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },
widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#002147',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function AboutSection(props) {
  const classes = useStyles();
  const [firstName,setFirstName]=React.useState('')
  const [lastName,setLastName]=React.useState('')
  const [emailId, setEmailId]=React.useState('')
  const [password,setPassword]=React.useState('')
  const [verifyPassword,setVerifyPassword]=React.useState('')
  const [messages, setMessages ]=React.useState('')
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus,setStatus]=React.useState('') 

  const handlePicture=(event)=>{
    setPicturePath(URL.createObjectURL(event.target.files[0]))
    setPicture(event.target.files[0])
   }

   const handleVerifyPassword=(e)=>{
    setVerifyPassword(e.target.value)
    if(password===e.target.value){
      setStatus("Password Matched")
    }
    else{
      setStatus("Password not matched")
    }
  }


  const addNewRecord=async()=>{
if(picture=='')
{
    var body={'firstName':firstName,
    'lastName':lastName, 
    'emailId':emailId, 
    'password':password,
    'status':"Not Approved",
    'picture':"Not Found"}
    var result=await postData('selfregister/addNewRecord',body)
    if(result){
      await setMessages("Agent Succefully Created")

      var body={
        'emailid':emailId,
        'password':password
       }
var resultt = await  postData('selfregister/checkagentsignin',body)

if (resultt.RESULT)
{

localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
}
else
{
alert("Failed To Created Account")
}

await setFirstName('')
await setLastName('')
await setEmailId('')
await setPassword('')
await setVerifyPassword('')
await setPicturePath('')
}
else{setMessages("Faild to Submit")}

}

else{
   var formData=new FormData()
   formData.append('firstName',firstName)
   formData.append('lastName',lastName)
   formData.append('emailId',emailId)
   formData.append('password',password)
   formData.append('picture',picture)
   formData.append('status', "Not Approve")
  
   var config={headers:{'content-type':'multipart/form-data'}}  
    var result=await postDataAndImage('selfregister/addNewRecordPic',formData,config)


    if(result){
      await setMessages("Agent Succefully Created")

      var body={
        'emailid':emailId,
        'password':password
       }
var resultt = await  postData('selfregister/checkagentsignin',body)

if (resultt.RESULT)
{

localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
}
else
{
alert("Failed To Created Account")
}

await setFirstName('')
await setLastName('')
await setEmailId('')
await setPassword('')
await setVerifyPassword('')
await setPicturePath('')
}
else{setMessages("Faild to Submit")}
    }
    }
   
   
    console.log("History ==  ",props)
  return (
   <Container className={classes.widths}>
      <div className="top-bar-area bgdark text-light" style={{backgroundColor:'#3f8ccb',color:'white',fontSize:13,}}>
        <div className="container" >
            <div className="row">
            
            <div className="col-md-11 address-info text-left">
                    <div className="info">
                        <ul style={{marginBottom:0,}}>
                            <li>
                                <i className="fas fa-graduation-cap"></i><strong>Study Metro</strong>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-md-1 link text-right">
                    <ul style={{marginBottom:0,textAlign:'center'}}>
                        <li>
                            <a href="/Login">Login</a>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
    <header id="home">
        <nav className="navbar navbar-default attr-border navbar-sticky bootsnav">
            <div className="container">
                <div className="row">

                </div>
            </div>


            <div className="container">


    <div className="navbar-header">
    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                        <i class="fa fa-bars"></i>
                    </button>
                    <a class="navbar-brand" href="/">
                        <img src="assets/img/logo.png" class="logo" alt="Logo" />
                    </a>

  <a class="call-support show-mobile" href="/Login">Login</a>

</div>

 <div className="collapse navbar-collapse" id="navbar-menu">
<ul className="nav navbar-nav navbar-right" style={{display: 'block',}} data-in="#" data-out="#">
<li className="dropdown"><a href="/"  className="dropdown" >Home</a></li>
 <li className="dropdown">
<a href="#" className="dropdown-toggle" data-toggle="dropdown" >Services</a>
<ul className="dropdown-menu">
<li className="dropdown">
<a href="#" className="dropdown-toggle"  data-toggle="dropdown" >Student</a>
<ul className="dropdown-menu">
<li className="dropdown"><a href="/CreateAccount">Create Account</a></li>
<li className="dropdown"><a href="#">Application</a></li>
<li className="dropdown"><a href="/ProfileBuilder">Profile Builder</a></li>
</ul>

</li>
<li className="dropdown">
<a href="#" className="dropdown-toggle"  data-toggle="dropdown" >Pages</a>
<ul className="dropdown-menu">
<li className="dropdown"><a href="/WhyChooses">Why Choose Us</a></li>
<li className="dropdown"><a href="/NewPaper">NewPaper</a></li>
<li className="dropdown"><a href="/PressMedia">PressMedia</a></li>
</ul>

</li>
</ul>
</li>

 <li class="dropdown">
                            <a href="#" class="dropdown-toggle" data-toggle="dropdown" > Universities</a>
                            <ul class="dropdown-menu">
                                <li><a href="/SearchUniversity">University Finder</a></li>
                                <li><a href="/SearchCourse">Course Finder</a></li>
                                <li><a href="/Compare">Compare Universities</a></li>
                                <li><a href="/Scholarship">Scholarships</a></li>
                                <li><a href="/SearchUniversity">View All Universities</a></li>
                            </ul>
                        </li>
<li className="dropdown"><a href="/Webinar" className="dropdown"  > Webinars</a> </li>
<li className="dropdown"><a href="/Blog" className="dropdown"  > Blogs</a></li>
<li className="dropdown"><a href="/aboutus" className="dropdown"  > About Us</a></li>
<li className="dropdown"><a href="/contact-us" className="dropdown"  > Contact Us</a></li>
</ul>           
</div>
</div>
</nav>
</header>
    <div className="login-area default-padding" style={{backgroundColor:'#ffab4b'}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">
                <div className="col-md-6 col-md-offset-1">
                <img style={{flex:1,marginTop:100,}} class="login_image" src="assets/img/job.png" alt="Login" data-original-title="" title="" />

                </div>
                <div className="col-md-5">
                  
                <Container>
      
      <CssBaseline />
      <MDBContainer>
      <MDBRow>
        <MDBCol md="12">
          <MDBCard>
            <MDBCardBody>
              <MDBCardHeader className="form-header deep-blue-gradient rounded" style={{color: "white", textAlign:"center",padding:'2px 2px',}}>
                <h3 className="my-3">
                  <MDBIcon icon="building" /> School Detail
                </h3>
              </MDBCardHeader>
              <div className={classes.paper}>
       
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
           
            
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="schoolname"
               
                label="School Name"
                name="schoolname"
                autoComplete="schoolname"
                onChange={(event)=>setEmailId(event.target.value)}
              />
            </Grid>
          
   

      <Grid item xs={12}>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-controlled-open-select-label">Boards</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select" >
          <MenuItem value={10}>CBSC</MenuItem>
          <MenuItem value={20}>DIPLOMA</MenuItem>
          <MenuItem value={20}>ICSC</MenuItem>
          <MenuItem value={20}>HSC</MenuItem>
          <MenuItem value={20}>IB</MenuItem>
          <MenuItem value={20}>Other</MenuItem>
        </Select>
      </FormControl>
            </Grid>

            <Grid item xs={12}>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-controlled-open-select-label">Grade</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select" >
          <MenuItem value={10}>8</MenuItem>
          <MenuItem value={20}>9</MenuItem>
          <MenuItem value={20}>10</MenuItem>
          <MenuItem value={20}>11</MenuItem>
          <MenuItem value={20}>12</MenuItem>
         
        </Select>
      </FormControl>
            </Grid>

     <Grid item xs={12}>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-controlled-open-select-label">GPA</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select" >
          <MenuItem value={10}>A*</MenuItem>
          <MenuItem value={20}>A</MenuItem>
          <MenuItem value={20}>B</MenuItem>
          <MenuItem value={20}>C</MenuItem>
          <MenuItem value={20}>D</MenuItem>
          <MenuItem value={20}>E</MenuItem>
          <MenuItem value={20}>F</MenuItem>
        </Select>
      </FormControl>
            </Grid>

            <Grid item xs={12}>
            <h4 style={{color: "#333", textAlign:"center",marginTop:30,marginBottom:10,}}>Appeared for SAT/ACT ?</h4>
          <div style={{borderWidth:1,borderColor:'#949494',borderStyle:"dotted"}}></div>
</Grid>
        
          <Grid item xs={12}>
               <label>SAT</label>
               <Checkbox
                   variant="outlined"
                   required
                   fullWidth
                   name="city"
                   label="City"
                   type="checkbox"
   
                 />

<label>ACT</label>
               <Checkbox
                   variant="outlined"
                   required
                   fullWidth
                   name="city"
                   label="City"
                   type="checkbox"
   
                 />
        
              </Grid>
              <Grid item xs={12}>
           
           <TextField
             id="date"
             label="SAT Appearing on"
             type="date"
             variant="outlined"
             defaultValue=""
             className={classes.formControl}
             InputLabelProps={{
               shrink: true,
             }}
           />
         
                       
                     </Grid>

<Grid item xs={12}>
  <TextField
             id="date"
             label="SAT maths score"
             type="text"
             variant="outlined"
             defaultValue=""
             className={classes.formControl}
             
           />
</Grid>
<Grid item xs={12}>
  <TextField
             id="date"
             label="SAT Reading-Writing Score"
             type="text"
             variant="outlined"
             defaultValue=""
             className={classes.formControl}
             
           />
</Grid>
<Grid item xs={12}>
  <TextField
             id="date"
             label="SAT Essay Score"
             type="text"
             variant="outlined"
             defaultValue=""
             className={classes.formControl}
            
           />
</Grid>
<Grid item xs={12}>
  <TextField
             id="date"
             label="SAT Subject 1 Score"
             type="text"
             variant="outlined"
             defaultValue=""
             className={classes.formControl}
            
           />
</Grid>
<Grid item xs={12}>
  <TextField
             id="date"
             label="SAT Subject 2 Score"
             type="text"
             variant="outlined"
             defaultValue=""
             className={classes.formControl}
            
           />
</Grid>

<Grid item xs={12}>
<TextField
             id="date"
             label="ACT Appearing on"
             type="date"
             variant="outlined"
             defaultValue=""
             className={classes.formControl}
             InputLabelProps={{
               shrink: true,
             }}
           />
</Grid>
<Grid item xs={12}>
  <TextField
             id="date"
             label="ACT Composite Score"
             type="text"
             variant="outlined"
             defaultValue=""
             className={classes.formControl}
            
           />
</Grid>
<Grid item xs={12}>
  <TextField
             id="date"
             label="ACT Writing Score"
             type="text"
             variant="outlined"
             defaultValue=""
             className={classes.formControl}
            
           />
</Grid>
          
<Grid item xs={12}>
            <h4 style={{color: "#333", textAlign:"center",marginTop:30,marginBottom:10,}}>Appeared for TOEFL/IELTS ?</h4>
          <div style={{borderWidth:1,borderColor:'#949494',borderStyle:"dotted"}}></div>
</Grid>
          

          <Grid item xs={12}>
<TextField
             id="date"
             label="TOEFL appearing on ?"
             type="date"
             variant="outlined"
             defaultValue=""
             className={classes.formControl}
             InputLabelProps={{
               shrink: true,
             }}
           />
</Grid>

<Grid item xs={12}>
<label>TOEFL</label>
<Checkbox
variant="outlined"
required
fullWidth
name="city"
label="City"
type="checkbox"
/>
<label>IELTS</label>
<Checkbox
variant="outlined"
required
fullWidth
name="city"
label="City"
type="checkbox"
 />
</Grid>
<Grid item xs={12}>
  <TextField
             id="date"
             label="TOEFL Test Score "
             type="text"
             variant="outlined"
             defaultValue=""
             className={classes.formControl}
            
           />
</Grid>
<Grid item xs={12}>
  <TextField
             id="date"
             label="IELTS Test Score"
             type="text"
             variant="outlined"
             defaultValue=""
             className={classes.formControl}
            
           />
</Grid>

<Grid item xs={12}>
  <TextField
             id="date"
             label="IELTS appearing on ?"
             type="date"
             variant="outlined"
             defaultValue=""
             className={classes.formControl}
             InputLabelProps={{
                shrink: true,
              }}
           />
</Grid>

<Grid item xs={12}>
            <h4 style={{color: "#333", textAlign:"center",marginTop:30,marginBottom:10,}}>INTERESTS FOR UNDERGRAD Section</h4>
          <div style={{borderWidth:1,borderColor:'#949494',borderStyle:"dotted"}}></div>
</Grid>


<Grid item xs={12}>
 <FormControl className={classes.formControl}>
 <InputLabel id="demo-controlled-open-select-label">Course Category</InputLabel>
  <Select
    labelId="demo-controlled-open-select-label"
    id="demo-controlled-open-select"
>
<MenuItem value={10}>Engineering</MenuItem>
<MenuItem value={20}>Technology</MenuItem>
<MenuItem value={30}>Business</MenuItem>
<MenuItem value={30}>Finance</MenuItem>
<MenuItem value={30}>Social Sciences</MenuItem>
<MenuItem value={30}>Liberals arts</MenuItem>
<MenuItem value={30}>Pure Science</MenuItem>
<MenuItem value={30}>Math</MenuItem>
<MenuItem value={30}>Other</MenuItem>
</Select>
</FormControl>
</Grid>
<Grid item xs={12}>
 <FormControl className={classes.formControl}>
 <InputLabel id="demo-controlled-open-select-label">Course Group</InputLabel>
  <Select
    labelId="demo-controlled-open-select-label"
    id="demo-controlled-open-select"
>
<MenuItem value={10}>Government</MenuItem>
<MenuItem value={20}>Administrative</MenuItem>
<MenuItem value={30}>Pure Sciences</MenuItem>
<MenuItem value={30}>Arts and Media</MenuItem>
<MenuItem value={30}>Education</MenuItem>
<MenuItem value={30}>Language</MenuItem>
<MenuItem value={30}>Counselling,Humanities</MenuItem>
<MenuItem value={30}>Journalism</MenuItem>
<MenuItem value={30}>Other</MenuItem>
</Select>
</FormControl>
</Grid>
<Grid item xs={12}>
 <FormControl className={classes.formControl}>
 <InputLabel id="demo-controlled-open-select-label">Intrested Country </InputLabel>
  <Select
    labelId="demo-controlled-open-select-label"
    id="demo-controlled-open-select"
>
<MenuItem value={10}>USA</MenuItem>
<MenuItem value={20}>CANADA</MenuItem>
<MenuItem value={30}>Austalia</MenuItem>
<MenuItem value={30}>NewZealand</MenuItem>
<MenuItem value={30}>Asia</MenuItem>
<MenuItem value={30}>China</MenuItem>
<MenuItem value={30}>Europe</MenuItem>
<MenuItem value={30}>France</MenuItem>
<MenuItem value={30}>Ireland</MenuItem>
<MenuItem value={30}>Medical</MenuItem>
<MenuItem value={30}>UK</MenuItem>
<MenuItem value={30}>Others</MenuItem>
</Select>
</FormControl>
</Grid>
<Grid item xs={12}>
 <FormControl className={classes.formControl}>
 <InputLabel id="demo-controlled-open-select-label">Study Metro Partner University</InputLabel>
  <Select
    labelId="demo-controlled-open-select-label"
    id="demo-controlled-open-select"
>
<MenuItem value={10}></MenuItem>

</Select>
</FormControl>
</Grid>


<Grid item xs={12}>
 <FormControl className={classes.formControl}>
 <InputLabel id="demo-controlled-open-select-label">Main source of finance for masters</InputLabel>
  <Select
    labelId="demo-controlled-open-select-label"
    id="demo-controlled-open-select"
   
>
<MenuItem value={10}>Self</MenuItem>
<MenuItem value={20}>Family</MenuItem>
<MenuItem value={20}>Education</MenuItem>
<MenuItem value={20}>Loan</MenuItem>
<MenuItem value={20}>Scholaships</MenuItem>
<MenuItem value={20}>AiD</MenuItem>
</Select>
</FormControl>
</Grid>
<Grid item xs={12}>
 <FormControl className={classes.formControl}>
 <InputLabel id="demo-controlled-open-select-label">Term</InputLabel>
  <Select
    labelId="demo-controlled-open-select-label"
    id="demo-controlled-open-select"
>
<MenuItem value={10}>Spring</MenuItem>
<MenuItem value={20}>Fall</MenuItem>

</Select>
</FormControl>
</Grid>

<Grid item xs={12}>
<TextField
variant="outlined"
required
fullWidth
name="year"
label="Year"
type="text"
/>
</Grid>
<Grid item xs={12}>
<TextField
variant="outlined"
required
fullWidth
name="DreamUniversity"
label="Dream University"
type="text"
/>
</Grid>



</Grid>

          <div className="text-center mt-3">
        <MDBBtn gradient="blue" rounded variant="contained"  onClick={addNewRecord}color="primary" className="btn-block z-depth-1a" fullWidth>
        Submit
        </MDBBtn>
       </div>
        <Typography>
        {messages}
        </Typography>
 
        <br/>
         


        </form>
      </div>
             
            </MDBCardBody>
          </MDBCard>
       </MDBCol>
       </MDBRow>
    </MDBContainer>
     
    </Container>

                </div>

</div>
</div>
</div>
</div>
<Footer/>
    </Container>
  );
}
