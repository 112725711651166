/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-dupe-keys */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { postDataAndImage, postData } from "../FetchServices";
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
import Footer from "./Footer";
import swal from "@sweetalert/with-react"

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    // margin: 'center',
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function ApplyScholarship(props) {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [messages, setMessages] = React.useState("");
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus, setStatus] = React.useState("");
const [getFresh,setFresh] = React.useState(true)
const [getLocal , setLocal] = React.useState('')

  const handlePicture = (event) => {
    setPicturePath(URL.createObjectURL(event.target.files[0]));
    setPicture(event.target.files[0]);
  };

  const handleVerifyPassword = (e) => {
    setVerifyPassword(e.target.value);
    if (password === e.target.value) {
      setStatus("Password Matched");
    } else {
      setStatus("Password not matched");
    }
  };

  console.log("History ==  ", props);

  const [getClgName, setClgName] = useState({});

  useEffect(function () {
    var  emaill = JSON.parse(localStorage.getItem('emaill'))
    setLocal(emaill)
    // console.log('localstorageItems>>>>>>>>>>>>>>>>',usseerr);
    if (props.history.location.state) {
      fetchUniversityById();
    } else {
      props.history.replace("/Scholarship");
    }
    // if (!location.state) {
    //
    // } else {

    // }
  }, []);

  const fetchUniversityById = async () => {
    // console.log(props.history.location.state.u_id);
    let body = { ID: props.history.location.state.u_id };
    let result = await postData("universities/fetchById", body);
    setClgName(result[0]);
  };

 const applySchol=()=>{
  
  swal({
    title: "You are Successfully Applied for Scholarship",
    icon: "success",
    dangerMode: true,
  })
  // .then(localStorage.setItem('set_schol',JSON.stringify(getLocal))) 
  setFresh(false)
 }

  return (
    <Container className={classes.widths}>
      <Header />
      <CssBaseline />
      <div className="login-area default-padding" style={{ paddingTop: 20 }}>
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/5.jpg" + ")",
                  padding: 70,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">Scholarship</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="event-area default-padding"
                style={{
                  padding: 0,
                  backgroundColor: "white",
                  position: "sticky",
                  top: 0,
                  zIndex: 9999,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="event-items">
                        <div className="item" style={{ marginTop: 10 }}>
                          <div className="col-md-12">
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={2}>
                                <p
                                  style={{
                                    backgroundColor: "#3f8ccb",
                                    marginTop: 10,
                                    borderRadius: "100",
                                    width: 70,
                                    height: 70,
                                    borderRadius: 100,
                                  }}
                                >
                                  <icon
                                    class="fa fa-university"
                                    style={{
                                      color: "#FFF",
                                      fontSize: "3rem",
                                      margin: 10,
                                    }}
                                  ></icon>
                                </p>
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <div
                                  className="content"
                                  style={{ width: "-webkit-fill-available" }}
                                >
                                  <h4 style={{ marginTop: 30 }}>
                                    <a href="#" style={{ color: "#000" }}>
                                      {getClgName.name}
                                      {/* StudyMetro Scholarship for India -
                                      University of StudyMetro */}
                                    </a>
                                  </h4>
                                </div>
                              </Grid>
                              {/* <Grid item xs={12} sm={2} >
            <h5 style={{color:'3f8ccb',padding:10,}}>hello</h5>
            </Grid> */}
                            </Grid>
                            <div>
                              <div
                                className="content"
                                style={{ width: "-webkit-fill-available" }}
                              >
                                <hr />
                                <MDBRow>
                                  <MDBCol>
                                    <strong>LEVEL</strong> <br />
                                    <h5 style={{ color: "3f8ccb" }}>Masters</h5>
                                  </MDBCol>
                                  <MDBCol>
                                    <strong>TYPE</strong> <br />
                                    <h5 style={{ color: "3f8ccb" }}>
                                      Merit based
                                    </h5>
                                  </MDBCol>
                                  <MDBCol>
                                    <strong>COUNTRIES</strong> <br />
                                    <h5 style={{ color: "3f8ccb" }}>
                                      {getClgName.country}
                                    </h5>
                                  </MDBCol>
                                  <MDBCol>
                                    <strong>DEADLINE</strong> <br />
                                    <h5 style={{ color: "3f8ccb" }}>
                                      Jul 31, 2020
                                    </h5>
                                  </MDBCol>
                                </MDBRow>
                                <hr />

                              { getFresh
                               ? ( <div className="blog-area">
                                  <div className="info">
                                    <div
                                      className="content"
                                      style={{
                                        padding: 10,
                                        position: "static",
                                      }}
                                    >
                                      <a                                                                       
                                        onClick={() => applySchol()}                           
                                        style={{
                                          padding: "10px 70px",
                                          color: "#000",
                                          width: "100%",
                                          textAlign: "center",
                                        }}                                      
                                      >
                                        <i
                                          className="fa fa-bookmark"
                                          style={{ color: "#000" }}
                                        > 
                                          </i>{" "}                                                                      
                                        Apply for Scholarship                               
                                      </a>
                                    </div>
                                  </div>
                                </div>): <center><div>You have Successfully Applied for Scholarship </div></center>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="blog-area single full-blog left-sidebar full-blog default-padding">
                <div className="container">
                  <div className="row">
                    <div className="blog-items">
                      <div className="blog-content col-md-12">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div className="content">
                                  <h4>
                                    <a href="#">About:</a>
                                  </h4>
                                  <p>
                                    Having an existence of over 100 years, the
                                    StudyMetroscholarship is given to students
                                    across 22 designated constituencies out of
                                    which India is a prominent name. The
                                    StudyMetroScholarship India is applicable
                                    for students having Indian citizenship or a
                                    valid Indian passport. The students who have
                                    a PIO or OCI card are not eligible to apply
                                    for the scholarship.
                                  </p>
                                </div>

                                <div className="content">
                                  <h4>
                                    <a href="#">Scholarship Requirements:</a>
                                  </h4>
                                  <p>
                                    These qualities, the key eligibility
                                    criterion for the scholarship include –
                                    <div style={{ margin: 10 }}>
                                      <li>
                                        The students must have taken their
                                        formal education at an Indian
                                        institution for a minimum of 4 years of
                                        the last 10 years.
                                      </li>
                                      <li>
                                        The students who have either completed
                                        class 12 exam or have received/pursuing
                                        the final year of an undergraduate
                                        degree can also apply for the
                                        scholarship.
                                      </li>
                                      <li>
                                        The age of the applicant should be above
                                        19 years and below 25 years as on 1
                                        October 2020 (for academic the
                                        StudyMetro Scholarship 2020). This
                                        means, the students born between 30
                                        September 1994 and on or before 1
                                        October 2000 are eligible to apply for
                                        the StudyMetro scholarship 2019.
                                      </li>
                                      <li>
                                        The applicants must have completed (or
                                        will complete by June/July 2020) their
                                        undergraduate degree with a First class
                                        or equivalent.
                                      </li>
                                      <li>
                                        The students must also intend to enroll
                                        in a full-time postgraduate programme at
                                        the University of StudyMetro.
                                      </li>
                                    </div>
                                  </p>
                                </div>

                                <div className="content">
                                  <h4>
                                    <a href="#">
                                      Courses Applicable for the StudyMetro
                                      Scholarship
                                    </a>
                                  </h4>
                                  <p>
                                    Find below the details of degree courses or
                                    degree combinations which are permissible
                                    for students to obtain the StudyMetro
                                    scholarship –
                                    <div style={{ margin: 10 }}>
                                      <li>
                                        A BA programme with senior status, i.e.,
                                        done over two years
                                      </li>
                                      <li>
                                        A one-year taught Master’s course like
                                        BCL, MSt or MSc which is followed by
                                        another one-year taught Master’s course
                                        in the second year
                                      </li>
                                      <li>
                                        A one-year taught Master’s course like
                                        BCL, MSt or MSc which may progress to
                                        D.Phil. In this combination, the
                                        students can apply for the scholarship
                                        for the third year. (
                                        <strong>Note</strong>: This movement is
                                        not applicable for students in Physical
                                        &amp; Life Sciences and Maths division).
                                      </li>
                                      <li>
                                        A two-year BPhil or MPhil or BCL
                                        programme that progresses to DPhil. In
                                        this scenario too, the students can
                                        apply for the third&nbsp;year of the
                                        scholarship.
                                      </li>
                                      <li>
                                        A two-year MLitt or MSc by research. (
                                        <strong>Note</strong>: No scholarship
                                        will be given for the third year in this
                                        scenario).
                                      </li>
                                      <li>A three-year DPhil programme.</li>
                                    </div>
                                  </p>
                                </div>

                                <div className="content">
                                  <h4>
                                    <a href="#">How to Apply:</a>
                                  </h4>
                                  <h4>
                                    <strong>
                                      Step 1: Fulfilling the StudyMetro
                                      Eligibility
                                    </strong>
                                  </h4>
                                  <p>
                                    Prior to starting the application process,
                                    the students need to make sure that they
                                    fulfill the specified eligibility criteria.
                                  </p>
                                  <h4>
                                    <strong>
                                      Step 2: Selecting an Oxford Course
                                    </strong>
                                  </h4>
                                  <p>
                                    Once an applicant fulfills all the
                                    eligibility conditions for the scholarship,
                                    he/she can move further to the second step.
                                    It involves them to select a suitable
                                    postgraduate degree course or degree
                                    combinations for which the scholarship is
                                    applicable.
                                  </p>
                                  <h4>
                                    <strong>
                                      Step 3: Prepare the Personal Statement
                                    </strong>
                                  </h4>
                                  <p>
                                    The scholarship application involves the
                                    students to prepare a personal statement of
                                    not more than 1,000 words that highlight
                                    their story.
                                  </p>
                                  <h4>
                                    <strong>
                                      Step 4: Prepare the List of Referees
                                    </strong>
                                  </h4>
                                  <p>
                                    The students are also required to provide a
                                    list of six referees who can submit
                                    reference letters on their behalf at the
                                    time of application. Prepare the list of
                                    referees in advance and take their
                                    permission beforehand to avoid any chaos
                                    while applying.
                                  </p>
                                  <h4>
                                    <strong>
                                      Step 5: Gather All Relevant Documents
                                    </strong>
                                  </h4>
                                  <p>
                                    The applicants are required to ascertain
                                    that they have all the relevant documents
                                    with them in order to complete their online
                                    application.
                                  </p>
                                  <h4>
                                    <strong>
                                      Step 6: Start the Application
                                    </strong>
                                  </h4>
                                  <p>
                                    <div style={{ margin: 10 }}>
                                      <li>
                                        Visit the&nbsp;
                                        <a href="/" target="_blank">
                                          official website
                                        </a>
                                        &nbsp;of the StudyMetro Trust.
                                      </li>
                                      <li>Select the country.</li>
                                      <li>
                                        Click on “Application for StudyMetro
                                        Scholarships 2020”.
                                      </li>
                                      <li>
                                        Click on “Register” button to start.
                                      </li>
                                      <li>
                                        Once registered, log in to your account
                                        to complete the application.
                                      </li>
                                      <li>
                                        Provide all the necessary details and
                                        upload all supporting documents.
                                      </li>
                                      <li>
                                        Submit the online application form.
                                      </li>
                                    </div>
                                  </p>
                                  <h4>
                                    <strong>
                                      Step 7: Prepare for the Final Interview
                                    </strong>
                                  </h4>
                                  <p>
                                    In case the student is long listed, the
                                    students have to appear for a personal
                                    interview at regional centres in Delhi or
                                    Bangalore (Note: For students residing
                                    outside of India, the interview shall be
                                    conducted over the telephone or through
                                    video conference). Also, the shortlisted
                                    candidates have to appear for an interview
                                    to be conducted in Delhi in the month of
                                    November on the basis of which they will get
                                    the scholarship.
                                  </p>
                                  <h4>
                                    <strong>Destinations Countries:</strong>
                                  </h4>
                                  <p>India</p>

                                  <div className="blog-area">
                                    <div className="info">
                                      <div
                                        className="content"
                                        style={{
                                          padding: 10,
                                          position: "static",
                                        }}
                                      >
                                        <a
                                          href="#"
                                          style={{
                                            padding: "10px 70px",
                                            color: "#000",
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          <i
                                            className="fa fa-bookmark"
                                            style={{ color: "#000" }}
                                          ></i>{" "}
                                          Apply for Scholarship
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Container>
  );
}
