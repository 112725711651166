/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {postDataAndImage,postData} from '../FetchServices';
import { Select, } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Header from './Header';
import Footer from './Footer';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBIcon,
    MDBCardHeader,
    MDBBtn  } from "mdbreact";
  





const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
input:{
  padding:'0px 0px',
},
  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:16,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    // margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },
widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#002147',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function AboutSection(props) {
  const classes = useStyles();
  const [firstName,setFirstName]=React.useState('')
  const [lastName,setLastName]=React.useState('')
  const [emailId, setEmailId]=React.useState('')
  const [password,setPassword]=React.useState('')
  const [,setVerifyPassword]=React.useState('')
  const [messages, setMessages ]=React.useState('')
  const [picture, setPicture] = React.useState("");
  const [, setPicturePath] = React.useState("");
  const [,setStatus]=React.useState('') 




  const addNewRecord=async()=>{
if(picture=='')
{
    var body={'firstName':firstName,
    'lastName':lastName, 
    'emailId':emailId, 
    'password':password,
    'status':"Not Approved",
    'picture':"Not Found"}
    var result=await postData('selfregister/addNewRecord',body)
    if(result){
      await setMessages("Agent Succefully Created")

      var body={
        'emailid':emailId,
        'password':password
       }
var resultt = await  postData('selfregister/checkagentsignin',body)

if (resultt.RESULT)
{

localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
}
else
{
alert("Failed To Created Account")
}

await setFirstName('')
await setLastName('')
await setEmailId('')
await setPassword('')
await setVerifyPassword('')
await setPicturePath('')
}
else{setMessages("Faild to Submit")}

}

else{
   var formData=new FormData()
   formData.append('firstName',firstName)
   formData.append('lastName',lastName)
   formData.append('emailId',emailId)
   formData.append('password',password)
   formData.append('picture',picture)
   formData.append('status', "Not Approve")
  
   var config={headers:{'content-type':'multipart/form-data'}}  
    var result=await postDataAndImage('selfregister/addNewRecordPic',formData,config)


    if(result){
      await setMessages("Agent Succefully Created")

      var body={
        'emailid':emailId,
        'password':password
       }
var resultt = await  postData('selfregister/checkagentsignin',body)

if (resultt.RESULT)
{

localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
}
else
{
alert("Failed To Created Account")
}

await setFirstName('')
await setLastName('')
await setEmailId('')
await setPassword('')
await setVerifyPassword('')
await setPicturePath('')
}
else{setMessages("Faild to Submit")}
    }
    }
   
   
    console.log("History ==  ",props)
  return (
   <Container className={classes.widths}>
   <Header/>
    <div className="login-area default-padding" style={{backgroundColor:'#3D8ECD'}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">
                <div className="col-md-6 col-md-offset-1">
                <img style={{flex:1,marginTop:80,}} class="login_image" src="assets/img/computer.png" alt="Login" data-original-title="" title="" />

                </div>
                <div className="col-md-5">
                  
                <Container>
      
      <CssBaseline />
      <MDBContainer>
      <MDBRow>
        <MDBCol md="12">
          <MDBCard>
            <MDBCardBody>
              <MDBCardHeader className="form-header deep-blue-gradient rounded" style={{color: "white", textAlign:"center",padding:'2px 2px',}}>
                <h3 className="my-3">
                  <MDBIcon icon="about" /> About Section
                </h3>
              </MDBCardHeader>
              <div className={classes.paper}>
       
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
           
            
<Grid item xs={12}>
<TextField
variant="outlined"
required
fullWidth
id="foundedin"
label="Founded In"
name="foundedin"
autoComplete="foundedin"
onChange={(event)=>setEmailId(event.target.value)}
/>
</Grid>

<Grid item xs={12}>
<TextField
variant="outlined"
multiline
rows={2}
rowsMax={4}
required
fullWidth
id="location"
label="Location"
name="location"
autoComplete="location"
onChange={(event)=>setEmailId(event.target.value)}
/>
</Grid>

<Grid item xs={12}>
<TextField
variant="outlined"
multiline
rows={2}
rowsMax={4}
required
fullWidth
id="address"
label="Address"
name="address"
autoComplete="address"
onChange={(event)=>setEmailId(event.target.value)}
/>
</Grid>
<Grid item xs={12}>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-controlled-open-select-label">Institution Type</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select" >
          <MenuItem value={10}>Private</MenuItem>
          <MenuItem value={20}>Government</MenuItem>
         
        </Select>
      </FormControl>
       </Grid>         
   
 <Grid item xs={12}>
<TextField
variant="outlined"
required
fullWidth
id="Estimated"
label="Estimated Cost of living"
name="Estimated"
autoComplete="Estimated"
onChange={(event)=>setEmailId(event.target.value)}
/>
</Grid>
<Grid item xs={12}>
<TextField
variant="outlined"
required
fullWidth
id="TuitionFee"
label="Tuition Fee"
name="TuitionFee"
autoComplete="TuitionFee"
onChange={(event)=>setEmailId(event.target.value)}
/>
</Grid>
<Grid item xs={12}>
<TextField
variant="outlined"
required
fullWidth
id="ApplicationFee"
label="Application Fee"
name="ApplicationFee"
autoComplete="ApplicationFee"
onChange={(event)=>setEmailId(event.target.value)}
/>
</Grid>

<Grid item xs={12}>
<TextField
variant="outlined"
required
fullWidth
id="tns"
label="Total Number of Students"
name="tns"
autoComplete="tns"
type="number"
onChange={(event)=>setEmailId(event.target.value)}
/>
</Grid>
<Grid item xs={12}>
<TextField
variant="outlined"
required
fullWidth
id="nois"
label="Number of International Students"
name="nois"
autoComplete="nois"
type="number"
onChange={(event)=>setEmailId(event.target.value)}
/>
</Grid>
<Grid item xs={12}>
<FormControl className={classes.formControl}>
<InputLabel id="demo-controlled-open-select-label">Type of Accommodation</InputLabel>
<Select
labelId="demo-controlled-open-select-label"
id="demo-controlled-open-select" >
<MenuItem value={10}>On Campus</MenuItem>
<MenuItem value={20}>Off Campus</MenuItem>
</Select>
</FormControl>
</Grid> 
<Grid item xs={12}>
<TextField
variant="outlined"
required
fullWidth
id="cashback"
label="Cash Back"
name="cashback"
autoComplete="cashback"
type="text"
onChange={(event)=>setEmailId(event.target.value)}
/>
</Grid>
<Grid item xs={12}>
<TextField
variant="outlined"
required
multiline
rows={4}
rowsMax={4}
fullWidth
id="about"
label="About"
name="about"
autoComplete="about"
type="text"
onChange={(event)=>setEmailId(event.target.value)}
/>
</Grid>

     



</Grid>

          <div className="text-center mt-3">
        <MDBBtn gradient="blue" rounded variant="contained"  onClick={addNewRecord}color="primary" className="btn-block z-depth-1a" fullWidth>
        Submit
        </MDBBtn>
       </div>
        <Typography>
        {messages}
        </Typography>
 
        <br/>
         


        </form>
      </div>
             
            </MDBCardBody>
          </MDBCard>
       </MDBCol>
       </MDBRow>
    </MDBContainer>
      
     
    </Container>

                </div>

</div>
</div>
</div>
</div>
<Footer/>
    </Container>
  );
}
