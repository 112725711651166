/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import icon from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Header from './Header';
import Footer from './Footer';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';



const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
newbtn:{
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
},

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:12,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },

widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function Compare(props) {
  const classes = useStyles();
 


  return (
   <Container className={classes.widths}>
   <Header/>  <CssBaseline />
    <div className="login-area default-padding" style={{paddingTop:20,}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">
<div className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard" style={{backgroundImage: "url(" + "../assets/img/banner/6.jpg" + ")",padding:70}}>             

        <div className="container">
            <div className="row">
            <div className="double-items col-md-12 info">
                <h2 className="wow fadeInLeft">UK</h2>
                </div>
             
            </div>
        </div>
    </div>

    <section class="video_sec blog_wrap">
<div class="container">
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <div class="blog_box faq">
                <h2>Frequently Asked Questions?</h2>
                <div class=" blog_box_content">
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are the documents required for admission?</h3>
                        <p style={{color:'#333'}}>A: We need scanned copies of 10th, 12th and degree mark lists for Masters courses. In addition, we need a copy of the passport (if the student got it), a one page 'personal statement/ statement of purpose' , 2 academic reference letters (for normal masters courses) and one academic and one employer reference for MBA courses and a copy of the IELTS/TOEFL certificate</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the eligibility Criteria for admissions in UK universities?</h3>
                        <p style={{color:'#333'}}>A: Minimum 55% marks for degree course and 6.5 overall for IELTS with no individual score below 5.5. We could offer pre-sessional English courses for student who haven't got the above IELTS score
 
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Any scholarship provided by the university for international students?</h3>
                        <p style={{color:'#333'}}>A: Most universities offer partial scholarships as deductions from fees. Full scholarships are very rare but some do offer it.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do the universities accept the students if they have completed their previous education from Distance Education?</h3>
                        <p style={{color:'#333'}}>A: Some universities will accept these but some won't. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Does a student have to show financial statement? If so, one day’s bank statement is accepted or 3 months bank statement is required</h3>
                        <p style={{color:'#333'}}>A: The students need to show the money in their account for 28 days before they apply for the visa if they are showing money in bank as evidence, however if they are taking a student loan they can apply for the visa the next day they get the loan letter from the bank
 
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Does the dependents require any entry level test like IELTS/ TOFEL?</h3>
                        <p style={{color:'#333'}}>A: No</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can the dependent fly along with the student?</h3>
                        <p style={{color:'#333'}}>A: Yes</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is there any requirement of Bank Statement to be shown by the dependents?</h3>
                        <p style={{color:'#333'}}>A: No, but the amount to be shown in the applicant's account will increase if there is a dependent, they will need to show another £4050 in the account towards the living expense of the dependant. This can be in the account of the dependent too.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do the student have to be physically present for the Visa interview?</h3>
                        <p style={{color:'#333'}}>A: They need to submit the application in person at the VFS visa processing centre. A small sample of students will be invited for interviews at the British Consulate and the student will have to go in person for that too.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the processing time required from admission process to visa approval?</h3>
                        <p style={{color:'#333'}}>A: It varies, the longer the better, but it could take up to three weeks to get an offer (time varies between universities) and then assuming the finances are already in place, they can make the fee deposit and get a CAS in another week. Then if they don't have to wait for the 28 days for the money to mature in the account (i.e. if they already got it in the account for that period) they can apply for the visa and it could take up to 5 weeks to get the visa. So altogether about 2 months minimum from application to visa approval.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can the dependents get work authority on the same Dependents visa or he will have to transfer the visa status?</h3>
                        <p style={{color:'#333'}}>A: No they can work on the same status</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: University assists the students for their internship jobs?</h3>
                        <p style={{color:'#333'}}>A: The universities which offer internships as part of the course will offer assistance to the student. The students are allowed to work part-time for upto 20 hours a week in addition to the internships.
 
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do the university provide Accommodation for the students at the time of arrival?</h3>
                        <p style={{color:'#333'}}>A: Yes</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the food and living expenditure?</h3>
                        <p style={{color:'#333'}}>A: For visa purpose the students need to show £7,200 towards food and living expense (£9,000, if the university is in London) but the actual expenses of Indian students will be in the region of £4,000 per year. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do the university require students to pay the Course fees before they fly?</h3>
                        <p style={{color:'#333'}}>A: The students will need to pay a deposit, which varies between the universities the minimum being £3500. They can pay the rest after reaching UK</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the validity of the Student visa?</h3>
                        <p style={{color:'#333'}}>A: Visa is usually issued for course duration plus 4 months so for normal masters it will be 12+4, 16 months</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do the University offer Phd Programs?</h3>
                        <p style={{color:'#333'}}>A: Yes, most universities do, it is a three year programme. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is there a deadline for applying?</h3>
                        <p style={{color:'#333'}}>A: There is no Universities deadline for receipt of applications. The academic year in the UK runs from September to July. The majority of university courses in London begin in September, although some courses also begin in January, April and November. You can check the start date of your course using our course search.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: IS GRE/GMAT or IELTS/TOEFL is required for admission?</h3>
                        <p style={{color:'#333'}}>A: Universities in UK doesn’t require students to under go GRE for the admission into science or other masters programs. But some top business schools might require students to submit GMAT scores . IELTS or TOEFL scores are accepted as a proof of English language proficiency (Please note that students with very good marks like 65% and above in English in their 10th and 12th standard might get an English requirement waiver from the universities)</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much Time it will take to get the offer letter?</h3>
                        <p style={{color:'#333'}}>A: We hope to give you a reply to your application within the following times:
For Masters – three weeks
For research degrees – six weeks
These times are counted from when we receive your completed application, that is to say your application form AND official transcript(s), in English or certified translation, of your previous degree results showing marks and grades, and two references (recommendations) on letterhead, at least one from an academic referee, in sealed envelopes with the signature of the referee on the back of each.
If required by the department to which you submit your application, you should also include a sample of written work or any other material and a research proposal if you are applying for a research degree.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can i work on campus?</h3>
                        <p style={{color:'#333'}}>A: Yes, Student can work on campus. The University has a JobShop on campus, which can help find students part-time work in the local area and offer advice on employment.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long will it take for me to obtain my internship employment?</h3>
                        <p style={{color:'#333'}}>A: Minimum 2 to 3 weeks </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What type of employment will I get Initially?</h3>
                        <p style={{color:'#333'}}>A: Student can get an internship job related field, if Student find it difficult then can do any part time job mean while till you get your intern job into your major.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Must I find an internship job only in the field related to my major or is any job O.K.?</h3>
                        <p style={{color:'#333'}}>A: If you Student find internship job into the field related to major will be recommended as internship job is for the international explorer.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How many working hours will I probably work each week?</h3>
                        <p style={{color:'#333'}}>A: Work up to 20 hours per week  and can work full time i.e 40hrs per week in the off sessions.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What if I can't find employment? </h3>
                        <p style={{color:'#333'}}>A: You can do any part time job mean while till you get your intern job into your major</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is there a way for me to work before actually starting to attend classes in order for me to save more money and add it to my current savings?</h3>
                        <p style={{color:'#333'}}>A: No</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Could the work authorization be possibly converted into a full time work permit upon completion of the masters program?</h3>
                        <p style={{color:'#333'}}>A: Yes, after completion of the study program a student gets a 3 months post study visa.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much is the bank statement required for applying VISA?</h3>
                        <p style={{color:'#333'}}>A: 50% of the fess should be paid, and the other 50% of the fees + 1yr living expenses should be shown in the bank statement. Bank statement. Require at the time of visa process.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long  I have to keep amount in my bank account for VISA?</h3>
                        <p style={{color:'#333'}}>A: You need to show a Bank statement which is Minimum 1 month old.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much tuition fee I have to pay before and after getting VISA/Offer letter?</h3>
                        <p style={{color:'#333'}}>A: After offer letter 50% of the fees must be paid to the university and show the bank statement of 50% of the fees + 1 yr living expense for visa purpose.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How can apply as a dependent VISA?</h3>
                        <p style={{color:'#333'}}>A: Additional fund in the bank which supports their living expenses.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is required in order to bring my spouse and family?</h3>
                        <p style={{color:'#333'}}>A: You will need to produce the documents needed to show your spouse’s or civil partner's status and evidence of your marital or civil partner status (for example, birth or naturalization certificate of Irish spouse, marriage or civil registration certificate).</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my spouse get the wok permit there?</h3>
                        <p style={{color:'#333'}}>A: Yes</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much duration work permit will spouse get there?</h3>
                        <p style={{color:'#333'}}>A: Dependents can work full time.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much time will I get after my studies to search job there?</h3>
                        <p style={{color:'#333'}}>A: The time for searching jobs is included in the 3 months stay back option.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What documents do I need to submit with my application for the UK?</h3>
                        <p style={{color:'#333'}}>A: When you submit your application, you'll need to provide these documents: Copy(ies) of your academic transcripts and certificates from the last three years of study; A copy of your most recent Secure English Language Test (SELT) certificate. A UKVI approved IELTS test may be required for some courses.</p>
                    </div>
                                   
                </div>
                
            </div>

        </div>
    </div>

</div>

</section>

    <div className="advisor-details-area default-padding" style={{padding:0,}}>
   
   </div>



</div>
</div>
</div>
</div>

<Footer/>
    </Container>
  );
}
