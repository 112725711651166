/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import createSvgIcon from '@material-ui/core';
import Header from './Header';
import Footer from './Footer';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";

  





const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
input:{
  padding:'0px 0px',
},
  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:16,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },
widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function ForgetPassword(props) {
  const classes = useStyles();
  const [firstName,setFirstName]=React.useState('')
  const [lastName,setLastName]=React.useState('')
  const [emailId, setEmailId]=React.useState('')
  const [password,setPassword]=React.useState('')
  const [verifyPassword,setVerifyPassword]=React.useState('')
  const [messages, setMessages ]=React.useState('')
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus,setStatus]=React.useState('') 

  const [GetReEmail,SetReEmail] = React.useState('')

  const handlePicture=(event)=>{
    setPicturePath(URL.createObjectURL(event.target.files[0]))
    setPicture(event.target.files[0])
   }

   const handleVerifyPassword=(e)=>{
    setVerifyPassword(e.target.value)
    if(password===e.target.value){
      setStatus("Password Matched")
    }
    else{
      setStatus("Password not matched")
    }
  }


  const addNewRecord=async()=>{
if(picture=='')
{
    var body={'firstName':firstName,
    'lastName':lastName, 
    'emailId':emailId, 
    'password':password,
    'status':"Not Approved",
    'picture':"Not Found"}
    var result=await postData('selfregister/addNewRecord',body)
    if(result){
      await setMessages("Agent Succefully Created")

      var body={
        'emailid':emailId,
        'password':password
       }
var resultt = await  postData('selfregister/checkagentsignin',body)

if (resultt.RESULT)
{

localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
}
else
{
alert("Failed To Created Account")
}

await setFirstName('')
await setLastName('')
await setEmailId('')
await setPassword('')
await setVerifyPassword('')
await setPicturePath('')
}
else{setMessages("Faild to Submit")}

}

else{
   var formData=new FormData()
   formData.append('firstName',firstName)
   formData.append('lastName',lastName)
   formData.append('emailId',emailId)
   formData.append('password',password)
   formData.append('picture',picture)
   formData.append('status', "Not Approve")
  
   var config={headers:{'content-type':'multipart/form-data'}}  
    var result=await postDataAndImage('selfregister/addNewRecordPic',formData,config)


    if(result){
      await setMessages("Agent Succefully Created")

      var body={
        'emailid':emailId,
        'password':password
       }
var resultt = await  postData('selfregister/checkagentsignin',body)

if (resultt.RESULT)
{

localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
}
else
{
alert("Failed To Created Account")
}

await setFirstName('')
await setLastName('')
await setEmailId('')
await setPassword('')
await setVerifyPassword('')
await setPicturePath('')
}
else{setMessages("Faild to Submit")}
    }
    }
    const [state, setState] = React.useState({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });

  
    const { vertical, horizontal, open } = state;
  
    const handleClick = (newState) => async() => {
      setState({ open: true, ...newState });

      console.log('inHabdleClick>>>>>>>>>>>>>>>>>>>>')

      let result = await postData('email/checkEmailInDb',{email : GetReEmail})
        console.log('Result>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>',result.data)
        var username = result.data[0].username ;
        
        if(result.message)
        { 
            let  body = {email : GetReEmail, username : username }
           console.log('Result After checkEmailInDb')
            let sendMailCall = await postData('email/sendeMailForGotPassword',body)


        }
    };
  
    const handleClose = () => {
    
      setState({ ...state, open: false });
    };
    console.log("History ==  ",props)
  return (
   <Container className={classes.widths}>
    
    <Header/>
    <div className="login-area default-padding" style={{backgroundColor:'#FFF',paddingBottom:100,}}>
        <div className="container">
            <div className="row" >
                <div className="col-md-12" style={{display: 'block',  justifyContent:'center', alignItems:'center', }}>
              
           
                <div className="col-md-6">
                  
                <Container style={{paddingLeft:0,paddingRight:0,}}>
      
      <CssBaseline />
      
      <MDBContainer style={{paddingLeft:0,paddingRight:0,}}>
      <MDBRow>
        <MDBCol md="9" >
          <MDBCard>
            <MDBCardBody>
              <div  style={{textAlign:"center",padding:'2px 2px',}}>
                <h3 className="my-3">
                  <MDBIcon icon="unlock" /> Forgot Password
                </h3>
              </div>
              <hr/>
              <div className={classes.paper}>
       
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <p style={{marginLeft:10,}}>Enter your email address to reset your password</p>
            <Grid item xs={12}>
              <TextField
             // InputLabelProps={{style: {fontSize: 12}}}
                variant="outlined"
                // required
                fullWidth
                id="emailid"
                value={GetReEmail}
                label="Email"
                name="email"
                autoComplete="email"
                // onChange={(event)=>setEmailId(event.target.value)}
                onChange={(event)=>SetReEmail(event.target.value)}
                placeholder="name@example.com"
              />
            </Grid>
           


          </Grid>
      
<div className="text-center mt-3">
<div className="blog-area">
<div className="info">
<div className="content" style={{padding:10,position:'static'}}>
<a  href="#" onClick={handleClick({ vertical: 'bottom', horizontal: 'center' })} style={{color:'#000',width:'100%',textAlign:'center',}}><i className="fa fa-unlock" style={{color:'#000'}}></i> Reset Password</a>
</div>
</div>
</div>  

<Snackbar open={open} autoHideDuration={2000} 
anchorOrigin={{ vertical, horizontal }} 
key={vertical + horizontal} 
onClose={handleClose}>
<Alert onClose={handleClose} severity="success">This is a success Reset Password!</Alert>
</Snackbar>
       </div>
        <Typography>
        {messages}
        </Typography>
 
        <br/>

   


        </form>
      </div>
             
            </MDBCardBody>
          </MDBCard>
       </MDBCol>
       </MDBRow>
    </MDBContainer>
      
     
    </Container>

                </div>
                <div className="col-md-6">
                <img style={{flex:1}} class="login_image" src="assets/img/ForgotPassword.png" alt="ForgotPassword" data-original-title="" title="" />
             </div>
</div>
</div>
</div>
</div>
<Footer/>
    </Container>
  );
}
