/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function CookiePolicy(props) {
  const classes = useStyles();

  return (
    <Container className={classes.widths}>
      <Header /> <CssBaseline />
      <div className="login-area default-padding" style={{ paddingTop: 20 }}>
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/22.jpg" + ")",
                  padding: 30,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">Cookie Policy</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="blog-area single full-blog left-sidebar full-blog default-padding">
                <div className="container">
                  <div className="row">
                    <div className="blog-items">
                      <div className="blog-content col-md-12">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <h4>Cookie Policy</h4>
                                  <div style={{ textAlign: "justify" }}>
                                    <p style={{ color: "#212121" }}>
                                      These terms and conditions, Together with
                                      all other Binding agreement ("Agreement")
                                      between the consumer that visits the
                                      Website in connection with use of their
                                      solutions, or whose advice Company
                                      differently receives in relation to the
                                      services offered via its education
                                      business ("You") and the Company
                                      regulating Your access to and use of this
                                      Platform, for example any subdomains
                                      thereof, along with some other sites by
                                      the site makes its services accessible
                                      (collectively,"Website"), our mobile,
                                      tablet along with other smart device
                                      software, and application program
                                      interfaces (together,"Program"). The
                                      Program and Website are collectively
                                      called as"Platform". From it's determined
                                      by Your acceptance of and compliance with
                                      all these Conditions and Privacy Policy.
                                      The headings of the many articles and
                                      subdivisions of the statements are
                                      inserted solely for the ease of reference
                                      and will have no additional significance,
                                      effect or force. Furthermore, foundation
                                      services selected, certain Item Specific
                                      conditions can apply. <br />
                                      1. ELIGIBILITY <br />
                                      2. REGISTRATION <br />
                                      3. PURPOSE <br />
                                      4. CONFORMITY WITH THE PURPOSE
                                      <br /> 5. USE OF PLATFORM <br />
                                      6. RESTRICTION REGARDING MATERIALS <br />
                                      7. DISCLAIMER <br />
                                      8. PRIVACY <br />
                                      9. PAYMENT TERMS <br />
                                      10. DISCLAIMER OF WARRANTY <br />
                                      11. CONTENT AND LIABILITY DISCLAIMER{" "}
                                      <br />
                                      12. LICENSE DISCLAIMER <br />
                                      13. DISCLAIMER OF <br />
                                      14. PURCHASING <br />
                                      15. YOUR <br />
                                      16. INDEMNIFICATION
                                      <br />
                                      17. LIMITATION OF LIABILITY <br />
                                      18. TAXES <br />
                                      19. TERMINATION/SUSPENSION <br />
                                      20. MISCELLANEOUS
                                      <br /> a. AMENDMENT TO THESE TERMS
                                      <br /> b. DISPUTESETTLEMENT
                                      <br /> c. LOCAL LAWS
                                      <br /> d. GOVERNING
                                      <br /> e. ENTIRE AGREEMENT
                                      <br /> f. SEVERABILITY
                                      <br /> g. ASSIGNMENT AND DELEGATION
                                      <br /> h. AVAILABILITY OF SERVICES
                                      <br /> i. DATA PROTECTION AMENDMENT
                                      <br /> 21. HOW YOU MAY CONTACT US
                                      Qualification You warrant and represent
                                      that: <br />
                                      1. You're Competent to enter a legal
                                      binding contract according to the
                                      applicable legislation. If you're a
                                      resident of the European Union the minimal
                                      age for these functions will be 16,
                                      nevertheless if local legislation demand
                                      that you have to be elderly in order for
                                      your Platform to legally offer the
                                      services from the Platform to then older
                                      age will employ as the applicable minimum
                                      era. That You match the need for minimum
                                      age and possess the legal capacity and
                                      authority to enter into a contract
                                      according to the related laws. Your
                                      capacity to follow these Conditions or to
                                      set up and use the goods that you buy with
                                      minimal risk of injury to You or others.
                                      You further represent that You aren't
                                      buying the products/services for resale to
                                      other people and won't do this without the
                                      organization's prior written approval.{" "}
                                      <br />
                                      2. You're Registration On enrollment, you
                                      consent to: • Make Your Contact
                                      information available to partners/business
                                      partners of this Platform, you might be
                                      reached by Platform or its partners to get
                                      further info and enrollment procedure
                                      through email, phone and SMS. • Receive
                                      Promotional mails/special provides from
                                      Machine or some of its spouse
                                      websites/applications. If you don't want
                                      to be reached by Platform, please alter
                                      the preferences in the time of enrollment
                                      or at the"Accounts & Settings" section on
                                      the Platform. • Be contacted By Study
                                      Metro Pvt Ltd in compliance with the
                                      settings you set. Goal The Platform is
                                      made readily available to be used to
                                      assist pupils get Details, find & study on
                                      schools, classes and assessments of the
                                      attention and also for allied services
                                      associated thereto. Platform also will
                                      help join colleges/educational
                                      institutes/coaching centers etc. with
                                      potential pupils who might be of interest
                                      ? Study Abroad segment of Platform also
                                      helps students in completing and
                                      submitting their programs to universities
                                      overseas. ("Goal"). Make an effort to
                                      maintain conformity with the Objective The
                                      Platform (like the Platform and related
                                      goods ) Or Service or product which You
                                      subscribe to or use (whether the exact
                                      same is compensated for by You or not) is
                                      intended for the Target and just Your
                                      private use. In case you're shown to be
                                      copying or transmitting, scratching or
                                      crawling any info or photos or images or
                                      any info that can be found on the Platform
                                      or Support of this Product for any purpose
                                      other than being a bonafide function, we
                                      reserve the right to take such actions we
                                      deem fit like stopping accessibility and
                                      claiming compensation. 1. Truth and
                                      Correctness of the data given by You •
                                      Whilst using This Platform a
                                      responsibility is cast upon You to simply
                                      offer accurate and proper advice and in
                                      the event of making a profile You tackle
                                      to always keep the info current. • We book
                                      The best to remove/suspend any profile or
                                      content supplying false/incorrect or
                                      incomplete or obsolete info or
                                      masquerading/impersonating as somebody
                                      else. • Maintain Your Contact information
                                      current, wrong and untrue details by
                                      themselves may be a cause for termination
                                      of services. Also as an outcome we might
                                      not have the ability to achieve You. 2.
                                      Safety of • Don't discuss Passwords, be
                                      liable in everything You post • in Case
                                      You Have Enrolled on the Platform, the
                                      security and security of Your log in
                                      qualifications is Your obligation, don't
                                      share them with anyone. • You're It's
                                      assumed that all action occurring through
                                      an individual's accounts on the Platform
                                      have been done after having got
                                      appropriate authorizations and all legal
                                      permissions as may be related. • The
                                      Platform The use of this Platform 1. The
                                      Platform As well as the products and
                                      services provided through the Platform are
                                      intended for just for valid and legal uses
                                      which fall inside the reach of the Goal
                                      and therefore are intended just for the
                                      exclusive use. The business has the sole
                                      and absolute right to ascertain if or not
                                      a particular sort of activity or use falls
                                      within the reach of the Goal or not. 2.
                                      The Subsequent activities will inter alia
                                      constitute a misuse of this Platform and
                                      therefore are strictly illegal: a.
                                      Copying, Extractingdownloading, sharing,
                                      altering, selling, storing, distributing,
                                      creating derivative works from or
                                      otherwise exploiting any material,
                                      information, information, including
                                      profiles, photos and/or images, on the
                                      Platform or some other products or
                                      services of the business, in any way or
                                      for any function that's not, in agreement
                                      with the Goal or relative with these
                                      Conditions. B. Utilizing or Trying to use
                                      any automatic program, system or software
                                      or any equivalent or similar process (such
                                      as robots, spiders, crawlers, browser
                                      plug-ins/extensions/add-ons, iframes on
                                      third party websites, mirroring, HTML
                                      parsers etc.) to gain access, browse,
                                      search, copy, track, download, scratch,
                                      crawl or otherwise extract in any fashion,
                                      any content or data in your Platform; c.
                                      Modifying Platform their look with any
                                      tech or overlay any extra offering in
                                      addition to Platform simulate or services
                                      Platform solutions or its capabilities in
                                      any way whatsoever without explicit
                                      permission by the corporation. d. Gaining
                                      or Trying to obtain unauthorized access
                                      (inter alia by hacking, password"mining"
                                      or some other way ) to: (a) any part or
                                      feature of this Platform or some of those
                                      products or services offered on or via the
                                      Platform that aren't meant for You; (b)
                                      any machine, Platform, application or
                                      computer systems of the business or
                                      another third parties or Users; e.
                                      Accessing the Platform through ports Aside
                                      from those explicitly provided by the
                                      Business; f. Attempting g. Scraping,
                                      Downloading (including majority -
                                      downloading), copying or extracting any
                                      data or information in your Machine (by
                                      any procedure, whether manual or automatic
                                      ) to provide any services or products that
                                      are very similar to or can in any way
                                      contend with the products or services of
                                      the Business; h. Reverse Engineering,
                                      decompiling, disassembling, deciphering or
                                      attempting to perform some one of the
                                      aforesaid or deriving the source code to
                                      the website or any associated technology
                                      or any component thereof; i. Circumventing
                                      Or trying to circumvent any technical
                                      protections utilized or used by the
                                      business or from any third party so as to
                                      shield the content onto the Platform or to
                                      exclude robots, spiders etc., from
                                      crawling or scratching material in the
                                      Platform. j. Interfering With or
                                      interrupting or trying to interfere with
                                      or interrupt (like by employing any
                                      device, routine or software ), the usage
                                      of the Network or some other computer
                                      networks attached to this Platform, by
                                      almost any other User; k. Impersonating
                                      Any other individual or thing, or making
                                      any misrepresentation regarding Your
                                      employment or affiliation with any person
                                      or thing; l. Forging Headers or in any way
                                      manipulating identifiers so as to disguise
                                      the origin of any consumer info; m.
                                      Stalking, Threatening, or in any way
                                      harassing another User; n. Imposing an o.
                                      Engaging in p. Spamming the q. Using the
                                      Request and Answer segment not in
                                      conformity with these Conditions as well
                                      as the Community Guidelines offered at
                                      https://www.studymetro.com r. Hosting, i.
                                      Violates ii. Belongs to Another individual
                                      and to that do not have any right; iii.
                                      Infringes, iv. Includes Computer viruses,
                                      or any other computer code, files or
                                      programs designed to interrupt, destroy or
                                      interfere with or restrict the performance
                                      of this Platform, or some other computer
                                      program or source; v. Is grossly vi. Which
                                      vii. That Deceives or misleads the
                                      addressee concerning the source of these
                                      messages or communicates any information
                                      that is grossly threatening or offensive
                                      in character; viii. That injuries Minors
                                      whatsoever; ix. That Restrictions
                                      Regarding Materials 1. All No component of
                                      this Platform, such as logos, images,
                                      sounds or graphics, may be reproduced or
                                      retransmitted at all, or by any means,
                                      without the prior express written consent
                                      of Business. 2. Nothing on 3. Permission
                                      4. Materials Disclaimer for sites we link
                                      to • that the Websites/apps we choose for
                                      inclusion in the Machine work, and
                                      continue to operate properly; • their
                                      Content remains appropriate and helpful;
                                      and • their Operation won't be injurious
                                      to our customers' devices. We explicitly
                                      disclaim and Won't accept any Liability
                                      for any of these in regard to the websites
                                      that we relate to: • Infection by computer
                                      viruses • Damage Due to downloaded
                                      applications • Technical Issues, failures
                                      and rate of operation • Libelous or
                                      Illegal substance • The caliber Or truth
                                      of any substance, or information that's
                                      provided Privacy The Privacy Policy of
                                      this Platform describes how we can use
                                      Your private information, we shall always
                                      respect and ensure adherence to the
                                      privacy policy also various settings are
                                      supplied to assist you to have the ability
                                      to control the way others might have the
                                      ability to look at Your data as preferred
                                      by You to be exhibited in Your profile
                                      along with the way you might have selected
                                      to be contacted. Any comments supplied by
                                      a user will be deemed as non-confidential
                                      to this consumer. Terms of conditions
                                      special for instructional • You may Comply
                                      with applicable data protection laws with
                                      regard to the processing of private data;
                                      rather than process personal data in a
                                      criminal fashion and excessive in terms of
                                      agreed purposes as described in the
                                      privacy policy and All these terms and
                                      conditions • You will • You consent to
                                      Provide adequate assistance as is required
                                      to ease the management of any Data
                                      Security Breach (as important below GDPR
                                      or another privacy legislation as
                                      applicable) in an expeditious and
                                      compliant fashion • You agree • You
                                      warrant And signify the institution will
                                      not disclose or transfer Personal Data
                                      accessed in the Business to some
                                      sub-processors without making sure
                                      adequate and equal safeguards to the
                                      Personal Data. • You will Retain or
                                      procedure shared Personal Data for no more
                                      than is essential to perform the agreed
                                      functions. Platform as well as the
                                      educational institutions etc., (as For
                                      More Information, please see the
                                      information protection amendment Payment
                                      Provisions
                                    </p>
                                  </div>
                                  <h4>Company</h4>
                                  <div style={{ textAlign: "justify" }}>
                                    <p style={{ color: "#212121" }}>
                                      On a 100% progress foundation. The payment
                                      for your service when subscribed to you
                                      personally isn't refundable and any sum
                                      paid will stand appropriated. As gentle
                                      copy through mails. The Business Provides
                                      no warranties whatsoever for the precision
                                      Or timeliness of those refunds reaching
                                      the Clients card/bank accounts. Its sole
                                      discretion and without prejudice to other
                                      rights and remedies it may possess under
                                      the applicable legislation, shall be
                                      entitled to specify the sum surplus paid
                                      by a subscriber/user against any sum (s)
                                      payable from the User to Business under
                                      any agreement or industrial connection
                                      towards additional products/services. The
                                      business provides no warranties of host
                                      uptime or software working correctly. The
                                      User or client will be required to
                                      immediately provide Replica of TDS
                                      certificate to the Business as mandated
                                      under legislation for tax deducted at
                                      source in the obligations made to the
                                      firm. Any reduction due to non availment
                                      or disallowance of these TDS charge in
                                      perspective of any lapse according to this
                                      clause or as required under applicable
                                      legislation on the part of User or client
                                      will entitle the company to seek
                                      compensation or indemnification Users,
                                      purchasing the goods on the internet are
                                      redirected to third Celebration gateways
                                      for completing payment arrangements. These
                                      trades occur on third party community and
                                      therefore not controlled by Company. A
                                      User via his client identification and
                                      will be completely Responsible for
                                      carrying out any on-line or offline trade
                                      between credit cards / debit cards or
                                      these other kinds of documents or
                                      instruments for making such trades and the
                                      Company assumes no obligation or liability
                                      due to their improper usage of information
                                      concerning these use of credit cards /
                                      debit cards utilized by the subscriber
                                      on-line / off Disclaimer of warranty The
                                      Business expressly disclaims warranties of
                                      any type for Any use of any access to the
                                      Platform, to any other material, data,
                                      links, or any articles presented on the
                                      webpages in the Platform, to any outside
                                      site connected thereto, and also to any
                                      external substance, info, links, or
                                      articles connected thereto. The business
                                      doesn't have any control over any outside
                                      site or over any outside substance, data,
                                      links, and articles connected to the
                                      Platform. The whole risk regarding the
                                      operation of, or even non-performance of,
                                      or arising from their utilization of, or
                                      the accessibility of, or even the absence
                                      of accessibility to the Platform, to some
                                      material, data, links, or material
                                      presented on the webpages in the Platform,
                                      to some outside site linked thereto, or to
                                      some outside substance, info, links, or
                                      articles connected thereto, is borne by
                                      the consumer, visitor, client, or other
                                      individual. Platform is a intermediary as
                                      described under sub-clause (w) Company
                                      will not be responsible for any mistakes
                                      or omissions All organization and third
                                      party information provided on almost any
                                      Business Platform is offered in an"as is"
                                      basis. Ltd doesn't support exactly the
                                      same. No claim Regarding the accuracy and
                                      correctness of The info on the website is
                                      created, although every effort is made to
                                      guarantee The material isn't misleading.
                                      In the event any inaccuracy is otherwise
                                    </p>
                                  </div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div className="col-md-12">
                                    <div
                                      className="content"
                                      style={{ paddingBottom: 0 }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
