/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useEffect, Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {postDataCompleteURL } from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
import Footer from "./Footer";
import swal from 'sweetalert';


import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";
import ReCAPTCHA from "react-google-recaptcha"




const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
  addressCard: {
    maxWidth: "18rem",
  },
}));

export default function Contactus(props) {
  const classes = useStyles();
  const [name, setName] = React.useState("");
  // const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  // const [password, setPassword] = React.useState("");
  // const [verifyPassword, setVerifyPassword] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessages] = React.useState("");
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus, setStatus] = React.useState("");
  const [captch, setCaptch] = React.useState("");
  const [addressData, setAddressData] = React.useState([]);

  const handlePicture = (event) => {
    setPicturePath(URL.createObjectURL(event.target.files[0]));
    setPicture(event.target.files[0]);
  };

  useEffect(() => {
    // Update the document title using the browser API
     async function fetchMyAPI() {
      var result= await postDataCompleteURL('https://studymetro.co.in/api/get_offices','')

      if(result.code === 200){
        setAddressData(result.response)
        // swal("Submitted", result.message, "success"); 
        // setName('')
        // setEmail('')
        // setPhone('')
        // setMessages('')
      }else{
        swal("Error", result.message, "error"); 
      }
    }

    fetchMyAPI()
  }, []);

  const onChange = (value) => {
    // console.log("Captcha value:", value)
    if(value){
      setCaptch(true)
    }else{
      setCaptch(false)
    }
  }

  // const handleVerifyPassword = (e) => {
  //   setVerifyPassword(e.target.value);
  //   if (password === e.target.value) {
  //     setStatus("Password Matched");
  //   } else {
  //     setStatus("Password not matched");
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault()
    if(!captch){
      swal("Error", "Please Enter Captcha", "error"); 
      return false
    }
    var formData=new FormData()
      formData.append('name',name)
      formData.append('email',email)
      formData.append('phone',phone)
      formData.append('message',message)

      var result= await postDataCompleteURL('https://studymetro.co.in/api/contact_us',formData)

      if(result.code === 200){
        swal("Submitted", result.message, "success"); 
        setName('')
        setEmail('')
        setPhone('')
        setMessages('')
      }else{
        swal("Error", result.message, "error"); 
      }
        
  }

  //   const addNewRecord=async()=>{
  // if(picture=='')
  // {
  //     var body={'firstName':firstName,
  //     'lastName':lastName,
  //     'emailId':emailId,
  //     'password':password,
  //     'status':"Not Approved",
  //     'picture':"Not Found"}
  //     var result=await postData('selfregister/addNewRecord',body)
  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}

  // }

  // else{
  //    var formData=new FormData()
  //    formData.append('firstName',firstName)
  //    formData.append('lastName',lastName)
  //    formData.append('emailId',emailId)
  //    formData.append('password',password)
  //    formData.append('picture',picture)
  //    formData.append('status', "Not Approve")

  //    var config={headers:{'content-type':'multipart/form-data'}}
  //     var result=await postDataAndImage('selfregister/addNewRecordPic',formData,config)

  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}
  //     }
  //     }

  // console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header /> <CssBaseline />
      <div className="login-area default-padding" style={{ paddingTop: 20 }}>
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/12.jpg" + ")",
                  padding: 35,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">Contact Us</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="contact-info-area default-padding">
                <div className="container">
                  <div className="row contact-bottom-info">
                    <div className="maps-form">
                      <div className="col-md-6 maps">
                        <h4>Our Location</h4>
                        <div className="google-maps">
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2585595.394203046!2d76.31782447631163!3d12.577280036438662!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae167ff568a92f%3A0x616a72fb191d4417!2sStudy%20Metro!5e0!3m2!1sen!2sin!4v1595407802633!5m2!1sen!2sin"
                            width="600"
                            height="450"
                            frameborder="0"
                            allowfullscreen=""
                            aria-hidden="false"
                            tabindex="0"
                          ></iframe>
                        </div>
                      </div>
                      <div className="col-md-6 form">
                        <div className="heading">
                          <h4>Contact Us</h4>
                        </div>
                        <form onSubmit={e => handleSubmit(e)} className="contact-form">
                          <div className="col-md-12">
                            <div className="">
                              <div className="form-group">
                                <input
                                  className="form-control"
                                  id="name"
                                  name="name"
                                  placeholder="Name"
                                  type="text"
                                  onChange={(e) => setName(e.target.value)}
                                  required
                                />
                                <span className="alert-error"></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="">
                              <div className="form-group">
                                <input
                                  className="form-control"
                                  id="email"
                                  name="email"
                                  placeholder="Email*"
                                  type="email"
                                  onChange={(e) => setEmail(e.target.value)}
                                  required
                                />
                                <span className="alert-error"></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="">
                              <div className="form-group">
                                <input
                                  className="form-control"
                                  id="phone"
                                  name="phone"
                                  placeholder="Phone"
                                  type="number"
                                  onChange={(e) => setPhone(e.target.value)}
                                  required
                                />
                                <span className="alert-error"></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="">
                              <div className="form-group comments">
                                <textarea
                                  className="form-control"
                                  id="comments"
                                  name="comments"
                                  placeholder="Tell Me About Courses *"
                                  onChange={(e) => setMessages(e.target.value)}
                                  required
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="">
                              <ReCAPTCHA sitekey="6LeRxxIcAAAAAHvBXZxo5t69zrWCUtDbMjMUuC81" onChange={onChange} />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="">
                              <button type="submit" >
                                Send Message{" "}
                                <i className="fa fa-paper-plane"></i>
                              </button>
                            </div>
                          </div>
                          <div className="col-md-12 alert-notification">
                            <div id="message" className="alert-msg"></div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <br />
                  {/* <div className="col-md-12 alert-notification">
                 <div id="message" className="alert-msg"></div>
            </div> */}
                  {/* hello */}

                  <div className="row">
                    {/* 1st */}
                    {addressData.length > 0 && addressData.map(row=>{
                      return(
                        <div className="col-md-4">
                          <div class="card text-dark mb-3 addressCard">
                            <div class="card-header">{row.title}</div>
                            <div class="card-body">
                              <p class="card-text">
                                {row.address}
                              </p>
                              <p class="card-text">Tel: {row.telephone},</p>
                              <p class="card-text ">Mob: {row.mobile}</p>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
