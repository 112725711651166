/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment,useState,useEffect } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import { UncontrolledCollapse, CardBody, Card } from 'reactstrap';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Header from './Header';
import Footer from './Footer';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';
import { FormInput } from 'semantic-ui-react';
import { getData } from '../FetchServices';



const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
newbtn:{
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
},

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:12,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },

widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function SearchCourses(props) {
  const classes = useStyles();
  const[list,setList] = useState([]);
  const[filterList,setFilterList] = useState([]);

  useEffect(()=>{
   getAllData()
  },[])

  const getAllData=async()=>{
   let res = await getData('abroadcourses/fetchallcourses')
     setList(res.result)
     setFilterList(res.result)
  }

  const handleClick = async (opt) => {
  switch (opt) {
    case 0:
      // {handleUnderGraduate()}
      props.history.push({
        
        pathname: "/UndergraduatePrograms",
        state: { option: opt },
      });
      break;
    case 1:
      //   {handleGraduatePrograms()}
      props.history.push({
        pathname: "/GraduatePrograms",
        state: { option: opt },
      });
      break;
    case 2:
      //   {handleDoctoral()}
      props.history.push({ pathname: "/Doctoral", state: { option: opt } });
      break;
    case 3:
      //   {handleCertificateDiploma()}
      props.history.push({
        pathname: "/CertificateDiploma",
        state: { option: opt },
      });
      break;
  }
};

 

  const top100Films = [
    { title: 'Phd', year: 1994 },
    { title: 'MCA', year: 1972 },
    { title: 'MSC', year: 1974 },
  ];


  const[getSearchData, setSearchData]=useState('')
  
  var colors=['colr1','colr2','colr3','colr4','colr5','colr6','colr7','colr8','colr9','colr10','colr11','colr12','colr13','colr14','colr15','colr16','colr17','colr18','colr19'];




const[temp,setTemp]=useState(false)
  
const handleSearch=()=>{
var data = filterList.filter(item =>{
 return item.name.toLowerCase().includes(getSearchData.toLowerCase())
})
if(getSearchData == ''){
  setTemp(false)
}
else{
setList(data)
setTemp(true);
}
}

const handleSetSearch=(event)=>{
 setSearchData(event.target.value)
 if(event.target.value == ''){
  setList([]) 
  setTemp(false)
 }
}

const handleCourse = (id) => {
  // alert("hello next Page");
    props.history.push({
      pathname: "/DisplayCourses",
      state: {
        id: id,
      }
    });
  // console.log(event.currentTarget);
};

// console.log("Set Hogyi value...",getSearchData)
  return (
   <Container className={classes.widths}>
    
    <Header/> <CssBaseline />
    <div className="login-area default-padding" style={{paddingTop:20,}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">
<div className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard" style={{backgroundImage: "url(" + "../assets/img/banner/9.jpg" + ")",padding:70}}>             

        <div className="container">
            <div className="row">
      
                <div className="double-items col-md-12 info">
                <h2 className="wow fadeInLeft">Top Courses</h2>
                </div>
            
                <div class="col-lg-12" style={{padding:15,backgroundColor:'#FFF',borderRadius:50, marginTop:25,}}>
                   
                   <div class="col-lg-10">
                   
               <TextField
                fullWidth
                // name="study"
                label="Search Courses"
                type="text"
                InputLabelProps={{style: {fontSize: 13}}}
                onChange={handleSetSearch}
               
              />
              
                   </div>
                 
                 
                   <div class="col-lg-2" style={{padding:0,textAlign:'center'}}>
{/* <MDBBtn color="yellow" rounded variant="contained" style={{fontSize:16,borderRadius:50,color:'#FFF',}}  className="btn-block z-depth-1a" fullWidth>
        Search  <i class="fa fa-search"></i>
        
        </MDBBtn> */}
      <div className="blog-area">
         <div className="info">
              <div className="content" style={{padding:0,}}>
                 <a onClick={()=>handleSearch()} ><i className="fas fa-search"></i> Search</a>
                                </div>
                            </div>
                        </div>


                      
               </div>
                </div>
            </div>
        </div>
    </div>
   

    <div className="blog-area single full-blog left-sidebar full-blog default-padding">

    <div className="container">
    {
                           temp ? (

                         
                        <div class="explore_box"> 
                                  
                    
                                  <ul class="explore_list clearfix">
                                     {list.map((item)=>(<li class={colors[Math.floor((Math.random()*15))]}>
                                      {/* <a href="https://studymetro.com/search-programs?program=Accounting&amp;country=USA&amp;id=&amp;course=Graduate+Course"> */}
                                      <a 
                                      style={{borderColor:'#FFF', borderWidth:1, borderStyle:'solid'}}
                                     onClick={() => handleCourse(item.id)}
                               >
                                        {item.name}
                                      </a>
                                      </li>))}
                                                                
                                                                    
                                  </ul>
                        </div>
                            ) :<></>}

            <div className="row">
                <div className="blog-items">
                    <div className="blog-content col-md-8">
                    <div className="event-area flex-less default-padding" style={{padding:0,}}>
      
            {/* <div className="row">
                <div className="event-items">
       
                    <div className="col-md-6 col-sm-6 equal-height" style={{height:690,}}>
                    <div className="item">
                                <div className="thumb">
                                <span className="badge" style={{backgroundColor:'#3f97f6',position:'absolute',margin:10,padding:8, fontSize:12, borderTopRightRadius:20,borderBottomRightRadius:20,}}>
                                    <icon class="fas fa-star"></icon> Top 10</span>
                                    <img src="assets/img/event/2.jpg" alt="Thumb" />
                                   
                                </div>
                                <div className="info">
                                    
                                    <h4 style={{padding:10,fontSize:20,textAlign:'center'}}>
                                        <a href="#">Massachusetts Institute of Technology (MIT)</a>
                                    </h4>
                                   
                                    <div className="meta" style={{padding:'5px 10px',borderBottom:'none',backgroundColor:'white'}}>
                                        <ul style={{marginBottom:0,}}>
                                            <li>
                                                <a href="#" style={{color:'#3f8ccb',fontSize:16,}}>Estd.</a>
                                                <a href="#" style={{color:'#3f8ccb',fontSize:16,}}>1861</a>
                                            </li>
                                           
                                            <li style={{float:'right'}}>67 Courses</li>
                                        </ul>
                                    </div>
                                    <hr/>

                                    <div className="meta" style={{padding:'5px 10px',borderBottom:'none',backgroundColor:'white',}}>
                                        <ul style={{marginBottom:0,textAlign:'center',fontSize:11,}}>
                                            
                                            <li style={{textTransform:'none',float:'left'}}>
                                            <icon class="fas fa-university fa-2x"></icon><br/>
                                            Private University
                                               
                                            </li>
                                            <li style={{textTransform:'none'}}>
                                            <icon class="fas fa-money-bill-alt fa-2x"></icon><br/>
                                            Rs49,580 Tuition*
                                            </li>
                                            <li style={{textTransform:'none',float:'right'}}><icon class="fas fa-percentage fa-2x"></icon><br/>
                                            17.8% 
                                            <br/>
                                            Admits
                                            </li>
                                        </ul>
                                    </div>

                                    <hr/>
                                   
                                    <p style={{fontSize:14,textAlign:'center',paddingBottom:10,color:'#000'}}>
                                        <a href="#">Massachusetts, United States</a>
                                    </p>
                                  
                                </div>
                            </div>
                    </div>
                    <div className="col-md-6 col-sm-6 equal-height" style={{height:690,}}>
                    <div className="item">
                                <div className="thumb">
                                    <img src="assets/img/event/1.jpg" alt="Thumb" />
                                   
                                </div>
                                <div className="info">
                                    
                                    <h4 style={{padding:10,fontSize:20,textAlign:'center'}}>
                                        <a href="#">Massachusetts Institute of Technology (MIT)</a>
                                    </h4>
                                   
                                    <div className="meta" style={{padding:'5px 10px',borderBottom:'none',backgroundColor:'white'}}>
                                        <ul style={{marginBottom:0,}}>
                                            <li>
                                                <a href="#" style={{color:'#3f8ccb',fontSize:16,}}>Estd.</a>
                                                <a href="#" style={{color:'#3f8ccb',fontSize:16,}}>1861</a>
                                            </li>
                                           
                                            <li style={{float:'right'}}>67 Courses</li>
                                        </ul>
                                    </div>
                                    <hr/>

                                    <div className="meta" style={{padding:'5px 10px',borderBottom:'none',backgroundColor:'white',}}>
                                        <ul style={{marginBottom:0,textAlign:'center',fontSize:11,}}>
                                            
                                            <li style={{textTransform:'none',float:'left'}}>
                                            <icon class="fas fa-university fa-2x"></icon><br/>
                                            Private University
                                               
                                            </li>
                                            <li style={{textTransform:'none'}}>
                                            <icon class="fas fa-money-bill-alt fa-2x"></icon><br/>
                                            Rs49,580 Tuition*
                                            </li>
                                            <li style={{textTransform:'none',float:'right'}}><icon class="fas fa-percentage fa-2x"></icon><br/>
                                            17.8% 
                                            <br/>
                                            Admits
                                            </li>
                                        </ul>
                                    </div>

                                    <hr/>
                                   
                                    <p style={{fontSize:14,textAlign:'center',paddingBottom:10,color:'#000'}}>
                                        <a href="#">Massachusetts, United States</a>
                                    </p>
                                  
                                </div>
                            </div>
                    </div>
                  
                </div>
               
            </div> */}

<section class="membership_plan explore_sec">
                <div class="container">
                  <div class="row">
                    <div class="col-sm-12">
                      <div className="weekly-top-items">
                        <div className="col-md-12">
                          <div
                            className="top-courses"
                            style={{ paddingRight: 0 }}
                          >
                            {/* <div
                              className="heading"
                              style={{ paddingBottom: 15 }}
                            >
                              <h4>
                                EXPLORE PROGRAMS IN ABROAD<strong></strong>
                              </h4>
                            </div> */}

                          </div>
                        </div>
                      </div>
                      <div class="features-area default-padding bottom-less shape-radius">
                        <div class="container">
                          <div class="row">
                            <div class="features text-center">
                              <div
                               
                                style={{ height: 304 }}
                              >
                                <div class="item mariner">
                                  <a 
                                    href=""
                                    onClick={() => handleClick(0)}
                                    style={{ backgroundColor: "#3a448d" }}
                                  >
                                    <div class="icon">
                                      <icon
                                        class="fas fa-book-open"
                                        style={{
                                          color: "red",
                                          fontSize: "4rem",
                                          marginBottom: 10,
                                        }}
                                      ></icon>
                                    </div>
                                    <div class="info">
                                      <h5
                                        style={{
                                          fontSize: 20,
                                          fontWeight: "bold",
                                          color: "white",
                                        }}
                                      >
                                        Undergraduate Programs
                                      </h5>
                                      {/* <p>
                                                                        attention say frankness intention out dashwoods now curiosity. Stronger ecstatic as no judgment daughter.
                                                                    </p> */}
                                      <div className="blog-area">
                                        <div className="info">
                                          <div
                                            className="content"
                                            style={{ padding: 0 }}
                                          >
                                            <a 
                                              href="#"
                                              style={{
                                                padding: "2px 10px",
                                                fontSize: 12,
                                              }}
                                            >
                                              {" "}
                                              Explore More
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div
                                // class="equal-height col-md-3"
                                style={{ height: 304 }}
                              >
                                <div class="item brilliantrose">
                                  <a
                                    href=""
                                    onClick={() => handleClick(1)}
                                    style={{ backgroundColor: "#e948ae" }}
                                  >
                                    <div class="icon">
                                      <icon
                                        class="fas fa-user-graduate"
                                        style={{
                                          color: "white",
                                          fontSize: "4rem",
                                          marginBottom: 10,
                                        }}
                                      ></icon>
                                    </div>
                                    <div class="info">
                                      <h5
                                        style={{
                                          fontSize: 20,
                                          fontWeight: "bold",
                                          color: "white",
                                        }}
                                      >
                                        {" "}
                                        Graduate Programs
                                      </h5>
                                   
                                      <div className="blog-area">
                                        <div className="info">
                                          <div
                                            className="content"
                                            style={{ padding: 0 }}
                                          >
                                            <a 
                                              href="#"
                                              style={{
                                                padding: "2px 10px",
                                                fontSize: 12,
                                              }}
                                            >
                                              {" "}
                                              Explore More
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              
                              <div
                              // class="equal-height col-md-3"
                               
                                style={{ height: 304, width:700 }}
                              >
                                <div class="item brilliantrose">
                                  <a
                                    href=""
                                    onClick={() => handleClick(2)}
                                    style={{ backgroundColor: "#ff6d24" }}
                                  >
                                    <div class="icon">
                                      <icon
                                        class="fa fa-graduation-cap"
                                        style={{
                                          color: "white",
                                          fontSize: "4rem",
                                          marginBottom: 10,
                                        }}
                                      ></icon>
                                    </div>
                                    <div class="info">
                                      <h5
                                        style={{
                                          fontSize: 20,
                                          fontWeight: "bold",
                                          color: "white",
                                        }}
                                      >
                                        Doctoral Programs
                                      </h5>
                                     
                                      <div className="blog-area">
                                        <div className="info">
                                          <div
                                            className="content"
                                            style={{ padding: 0 }}
                                          >
                                            <a 
                                              href="#"
                                              style={{
                                                padding: "2px 10px",
                                                fontSize: 12,
                                              }}
                                            >
                                              {" "}
                                              Explore More
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>

                              <div
                                // class="equal-height col-md-3"
                                style={{ height: 304 }}
                              >
                                <div class="item casablanca">
                                  <a
                                    href=""
                                    onClick={() => handleClick(3)}
                                    style={{ backgroundColor: "#3d83cd" }}
                                  >
                                    <div class="icon">
                                      <icon
                                        class="fas fa-file-alt"
                                        style={{
                                          color: "white",
                                          fontSize: "4rem",
                                          marginBottom: 10,
                                        }}
                                      ></icon>
                                    </div>
                                    <div class="info">
                                      <h5
                                        style={{
                                          fontSize: 20,
                                          fontWeight: "bold",
                                          color: "white",
                                        }}
                                      >
                                        Certificate/Diploma Programs
                                      </h5>
                                      {/* <p>
                                        attention say frankness intention out dashwoods now curiosity. Stronger ecstatic as no judgment daughter.
                                    </p> */}
                                      <div className="blog-area">
                                        <div className="info">
                                          <div
                                            className="content"
                                            style={{ padding: 0 }}
                                          >
                                            <a 
                                              href="#" 
                                              style={{
                                                padding: "2px 10px",
                                                fontSize: 12,
                                              }}
                                            >
                                              {" "}
                                              Explore More
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

      
    </div>

                        {/* <div className="row">
                            <div className="col-md-12 pagi-area">
                                <nav aria-label="navigation">
                                    <ul className="pagination">
                                        <li><a href="#"><i className="fas fa-angle-double-left"></i></a></li>
                                        <li className="active"><a href="#">1</a></li>
                                        <li><a href="#">2</a></li>
                                        <li><a href="#">3</a></li>
                                        <li><a href="#"><i className="fas fa-angle-double-right"></i></a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div> */}
                        
                    </div>
                    <div className="sidebar col-md-4">
                        <aside>
                            {/* <div className="sidebar-item search" style={{padding:0,marginBottom:10,}}>
                                <div className="">
                                    <h4>Search</h4>
                                </div>
                                <div className="sidebar-info">
                                    <form>
                                        <input style={{minHeight:'60px',}} type="text" placeholder="Search" className="form-control" />
                                        <input type="submit" style={{padding:20,}} value="search" />
                                      
                                    </form>
                                </div>
                            </div> */}
<div className="sidebar-item category" style={{padding:15,}}>
      <div className="sidebar-info" >  
     <a color="primary" id="togglerU"   fullWidth>                        
       <i class="fa fa-university" style={{marginRight:10,color:'#3f8ccb',}}></i>  UNIVERSITIES <span style={{float:'right'}}><i class="fa fa-angle-down"></i></span>
    </a> 
        <UncontrolledCollapse toggler="#togglerU" >
        <ul style={{margin:10, backgroundColor: '#fcfcfc',}}>
         <Grid item xs={12}>   
         <p style={{backgroundColor: '#fcfcfc',lineHeight: 3,borderBottom: '1px solid #eee',marginBottom:0,}}>
          <a style={{margin:10,}} href="#">Top <span></span></a>
        </p>
         <p style={{backgroundColor: '#fcfcfc',lineHeight: 3,borderBottom: '1px solid #eee',marginBottom:0,}}>
           <a style={{margin:10,}} href="#">Popular <span></span></a>
         </p>
          </Grid>          
         </ul>
        </UncontrolledCollapse>
       </div>        
</div>
       <div className="sidebar-item category" style={{padding:15,}}>
      <div className="sidebar-info" >  
     <a color="primary" id="togglerUT"   fullWidth>                        
       <i class="fa fa-university" style={{marginRight:10,color:'#3f8ccb',}}></i>  UNIVERSITY TYPE <span style={{float:'right'}}><i class="fa fa-angle-down"></i></span>
    </a> 
        <UncontrolledCollapse toggler="#togglerUT" >
        <ul style={{margin:10, backgroundColor: '#fcfcfc',}}>
         <Grid item xs={12}>   
         <p style={{backgroundColor: '#fcfcfc',lineHeight: 3,borderBottom: '1px solid #eee',marginBottom:0,}}>
          <a style={{margin:10,}} href="#">Private <span></span></a>
        </p>
         <p style={{backgroundColor: '#fcfcfc',lineHeight: 3,borderBottom: '1px solid #eee',marginBottom:0,}}>
           <a style={{margin:10,}} href="#">Public <span></span></a>
         </p>
          </Grid>      
         </ul>
        </UncontrolledCollapse>
       </div>
</div>


                            {/* <div className="sidebar-item category">
                                <div className="title">
                                    <h4 style={{marginBottom: '0px',marginTop: '-5px',paddingBottom: 0,color:'#656464'}}>UNIVERSITIES</h4>
                                </div>
                                <div className="sidebar-info">
                                    <ul>
                                        <p style={{backgroundColor: '#fcfcfc',lineHeight: 3,borderBottom: '1px solid #eee',marginBottom:0,}}>
                                            <a style={{margin:10,color:'#3f8ccb',}} href="#">Top <span></span></a>
                                        </p>
                                        <p style={{backgroundColor: '#fcfcfc',lineHeight: 3,borderBottom: '1px solid #eee',marginBottom:0,}}>
                                            <a style={{margin:10,color:'#3f8ccb',}} href="#">Popular <span></span></a>
                                        </p>
                                      
                                    </ul>
                                </div>
                            </div> */}
                            {/* <div className="sidebar-item category">
                                <div className="title">
                                    <h4 style={{marginBottom: '0px',marginTop: '-5px',paddingBottom: 0,color:'#656464'}}>UNIVERSITY TYPE</h4>
                                </div>
                                <div className="sidebar-info">
                                <ul>
                                        <p style={{backgroundColor: '#fcfcfc',lineHeight: 3,borderBottom: '1px solid #eee',marginBottom:0,}}>
                                            <a style={{margin:10,color:'#3f8ccb',}} href="#">Private <span></span></a>
                                        </p>
                                        <p style={{backgroundColor: '#fcfcfc',lineHeight: 3,borderBottom: '1px solid #eee',marginBottom:0,}}>
                                            <a style={{margin:10,color:'#3f8ccb',}} href="#">Public <span></span></a>
                                        </p>
                                      
                                    </ul>
                                </div>
                            </div> */}



<div className="sidebar-item category" style={{padding:15,}}>                             
  <div className="sidebar-info" >  
 <a color="primary" id="toggler"   fullWidth>                        
   <i class="fa fa-flag" style={{marginRight:10,color:'#3f8ccb',}}></i>  COUNTRY <span style={{float:'right'}}><i class="fa fa-angle-down"></i></span>
</a> 
    <UncontrolledCollapse toggler="#toggler">
    <ul style={{margin:10, backgroundColor: '#fcfcfc',}}>
     <Grid item xs={12}>    

<p style={{marginBottom:0,marginLeft:20,}}><a href="/universities-in-US"><i class="flag-icon flag-icon-us"></i> United States</a></p>
<p style={{marginBottom:0,marginLeft:20,}}><a href="/universities-in-germany"><i class="flag-icon flag-icon-de"></i> Germany</a></p>
<p style={{marginBottom:0,marginLeft:20,}}><a href="/universities-in-canada"><i class="flag-icon flag-icon-ca"></i> Canada</a></p>
<p style={{marginBottom:0,marginLeft:20,}}><a href="/universities-in-australia"><i class="flag-icon flag-icon-au"></i> Australia</a></p>
<p style={{marginBottom:0,marginLeft:20,}}><a href="/universities-in-singapore"><i class="flag-icon flag-icon-sg"></i> Singapore</a></p>
<p style={{marginBottom:0,marginLeft:20,}}><a href="/universities-in-new-zealand"><i class="flag-icon flag-icon-nz"></i> New Zealand</a></p>
<p style={{marginBottom:0,marginLeft:20,}}><a href="/universities-in-france"><i class="flag-icon flag-icon-fr"></i> France</a></p>
<p style={{marginBottom:0,marginLeft:20,}}><a href="/universities-in-hong-kong"><i class="flag-icon flag-icon-hk"></i> Hong Kong</a></p>
<p style={{marginBottom:0,marginLeft:20,}}><a href="/universities-in-japan"><i class="flag-icon flag-icon-jp"></i> Japan</a></p>
<p style={{marginBottom:0,marginLeft:20,}}><a href="/universities-in-united-kingdom"><i class="flag-icon flag-icon-gb"></i> United Kingdom</a></p>
<p style={{marginBottom:0,marginLeft:20,}}><a href="/universities-in-netherlands"><i class="flag-icon flag-icon-nl"></i> Netherlands</a></p>
<p style={{marginBottom:0,marginLeft:20,}}><a href="/universities-in-denmark"><i class="flag-icon flag-icon-dk"></i> Denmark</a></p>
<p style={{marginBottom:0,marginLeft:20,}}><a href="/universities-in-ireland"><i class="flag-icon flag-icon-ie"></i> Ireland</a></p>
<p style={{marginBottom:0,marginLeft:20,}}><a href="/universities-in-sweden"><i class="flag-icon flag-icon-se"></i> Sweden</a></p>
<p style={{marginBottom:0,marginLeft:20,}}><a href="/universities-in-switzerland"><i class="flag-icon flag-icon-ch"></i> Switzerland</a></p>

      </Grid>
         
     </ul>
    </UncontrolledCollapse>
   </div>
   </div>

   <div className="sidebar-item category" style={{padding:15,}}>
      
                                <div className="sidebar-info" >  
                               <a color="primary" id="togglerC"   fullWidth>                        
                                 <i class="fas fa-book-open" style={{marginRight:10,color:'#3f8ccb',}}></i>  COURSES <span style={{float:'right'}}><i class="fa fa-angle-down"></i></span>
                              </a> 
                                  <UncontrolledCollapse toggler="#togglerC" >
         

                                  <ul style={{margin:10, backgroundColor: '#fcfcfc',}}>
                               
                                   <Grid item xs={12}>   
                                   <div class="container my-4">

<input class="form-control" style={{borderRadius:5,marginTop:10,marginBottom:10,}} id="listSearch" type="text" placeholder="Search.."/>

<div style={{height:346,overflowY:'auto',}}>
<ul class="list-group" id="myList">
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Aerospace Engineering">Aerospace Engineering</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Architecture">Architecture</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Arts and Media">Arts and Media</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Biological / Agricultural">Biological / Agricultural</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
 <a href="#" title="Biomedical Engineering">Biomedical Engineering</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Business">Business</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Business Analytics">Business Analytics</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Chemical / Petroleum">Chemical / Petroleum</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Civil and Structural Engineering">Civil and Structural Engineering</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Commerce / Finance / Actuarial">Commerce / Finance / Actuarial</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Computer Science and Engineering">Computer Science and Engineering</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#">Data Science and Analytics</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Dental">Dental</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Education / Languages / Counselling">Education / Languages / Counselling</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Electrical / Electronics / Telecomm">Electrical / Electronics / Telecomm</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Engineering Management (MEM)">Engineering Management (MEM)</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Environmental / Mining">Environmental / Mining</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Financial Engineering">Financial Engineering</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="General Law / International Law">General Law / International Law</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Government / Administrative">Government / Administrative</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Healthcare">Healthcare</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Human Resources">Human Resources</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Humanities / Journalism">Humanities / Journalism</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Industrial Engineering and Operations">Industrial Engineering and Operations</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="International Management">International Management</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Management Information System (MIS)">Management Information System (MIS)</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Marketing">Marketing</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Material Science and Engineering">Material Science and Engineering</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="MBA / Management">MBA / Management</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Mechanical and Automobile Engineering">Mechanical and Automobile Engineering</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Nano / Nuclear / Power">Nano / Nuclear / Power</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Nursing">Nursing</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Pharmacy">Pharmacy</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Pure Sciences">Pure Sciences</a> </li>
<li style={{padding:10,border: '1px solid #ccc',}}>
<a href="#" title="Veterinary Science">Veterinary Science</a> </li>

</ul>  
</div>



</div>            
            {/* <Autocomplete style={{paddingRight:0,}}
           options={top100Films}
          getOptionLabel={option => option.title}
          renderInput={params => (
            <TextField
              {...params}
              label=""
              
              style={{width:'100%',}}
            //   InputLabelProps={{style: {fontSize: 15}}}
            />
          )}
        
        /> */}
                             
                                    </Grid>
                                       
                                   </ul>
                                  </UncontrolledCollapse>
                                 </div>
                                  
                                 </div>

   
                         </aside>
                    </div>
                </div>
            </div>
        </div>
    </div>












</div>
</div>
</div>
</div>

<Footer/>
    </Container>
  );
}
