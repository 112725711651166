/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import icon from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Header from './Header';
import Footer from './Footer';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';



const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
newbtn:{
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
},

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:12,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },

widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function Compare(props) {
  const classes = useStyles();
 


  return (
   <Container className={classes.widths}>
   <Header/>  <CssBaseline />
    <div className="login-area default-padding" style={{paddingTop:20,}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">
<div className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard" style={{backgroundImage: "url(" + "../assets/img/banner/6.jpg" + ")",padding:70}}>             

        <div className="container">
            <div className="row">
            <div className="double-items col-md-12 info">
                <h2 className="wow fadeInLeft">USA</h2>
                </div>
             
            </div>
        </div>
    </div>


    <section class="video_sec blog_wrap">
<div class="container">
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <div class="blog_box faq">
                <h2>Frequently Asked Questions?</h2>
                <div class=" blog_box_content">
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is the GRE or GMAT score required for admission?</h3>
                        <p style={{color:'#333'}}>A: Some school will not require that you have taken either the GMAT or the GRE exams. However, we recommend you take the GMAT or GRE exam. We suggest this because, having taken the test, you are more likely to be viewed as a more serious student by the U.S. visa officer when you go to apply for your visa.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is the GRE or GMAT score required for admission?</h3>
                        <p style={{color:'#333'}}>A: Some school will not require that you have taken either the GMAT or the GRE exams. However, we recommend you take the GMAT or GRE exam. We suggest this because, having taken the test, you are more likely to be viewed as a more serious student by the U.S. visa officer when you go to apply for your visa.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: When should I begin to apply to schools?" </h3>
                        <p style={{color:'#333'}}>A: Start early! Start now! Because the complete admissions process is long, begin the application process soon. Do not wait until the required TOEFL score is obtained. The student can apply and be admitted as a "conditional admit" at many schools. This means that the school has admitted the student on the student's past academic record from the student's home country and is waiting for the student to obtain the minimum TOEFL score.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is required in order to bring my spouse and family?</h3>
                        <p style={{color:'#333'}}>A: In order for your spouse and/or children to accompany you, the university must issue a separate I-20 form. Your family can apply for an F-2 (dependant) visa. The university will need the following information for each family member (including spouse): full name, date of birth, country of birth, and country of citizenship. The schools will also require birth certificates for children, marriage license, and copies of all dependent passports. The school will also need an additional $6,000 per dependent showing on the bank statement.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I take a break during summer quarter to return to my country then return back to school for fall quarter?</h3>
                        <p style={{color:'#333'}}>A: Most schools will allow a school break. Students must attend school full time for 2 consecutive semesters (9 months and 18 credit hours) before they are eligible to take a vacation from their studies. However, some schools do not have summer breaks scheduled into their curriculum.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is part time work opportunity available?</h3>
                        <p style={{color:'#333'}}>A: Allowed to work 20 hours per week during term time and full time during holidays.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are the prerequisite to get an admission in USA?</h3>
                        <p style={{color:'#333'}}>A: For a Masters: completions of 16 yrs of education, TOEFL, GRE/GMAT are prerequisite.  Very handful of universities offers programs for those students who have completed 15 years of education, subject to certain conditions. But for the univs offering 15 years education the visa could anytime be an issue.
For Pursuing Bachelors: You should have completed Class 12, TOEFL and SAT.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are the reasons to study in USA?</h3>
                        <p style={{color:'#333'}}>A: World class learning institutions
• Endless study choices
• Worldwide recognition
• Supporting industries, training &amp; research
• People from Varied Cultures
• Latest Technology
• Flexible Education System
• Comprehensive and Global Curriculum
• Campus Life Worth Experiencing 
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: IS GRE/GMAT or IELTS/TOEFL is required for admission?</h3>
                        <p style={{color:'#333'}}>A: Yes, its Require.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can i work on campus?</h3>
                        <p style={{color:'#333'}}>A: Yes, you can work part time on campus.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I apply work permit after completing my studies?</h3>
                        <p style={{color:'#333'}}>A: Yes, you can apply for it.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can one apply for Dependent VISA?  Can they work?</h3>
                        <p style={{color:'#333'}}>A: Yes Dependent can apply. The spouse of a student cannot work if they have travelled to the USA on a dependent visa. If seeking employment, the appropriate work visa will be required.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I work while studying?</h3>
                        <p style={{color:'#333'}}>A: Students are allowed to work 20 hours a week on- campus during the term and full-time during the vacation period.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I get Financial assistance?</h3>
                        <p style={{color:'#333'}}>A: Yes definitely, but getting financial assistance in the first semester is extremely competitive and you need to have an extremely good academic and test score to be qualified. There are number of good cases where we have helped students secure full financial assistance. If you have an outstanding academic background and test scores we will try their best to get you financial aid.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Post study work rights</h3>
                        <p style={{color:'#333'}}>A: Student visa [F-1] holders are eligible for a total of 12 months Optional Practical Training [OPT] per educational level completed.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: When can I commence studies in the U.S.?</h3>
                        <p style={{color:'#333'}}>A: The major intakes are Fall (September) and Spring (January) but many institutions have Summer intakes (May or June) as well.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What’s the difference between a college and a university?</h3>
                        <p style={{color:'#333'}}>A: Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Are there age limitations to attend U.S. universities? </h3>
                        <p style={{color:'#333'}}>A: In general, you must have completed high school and you must be at least 17 years of age.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the academic calendar for universities in the United States?</h3>
                        <p style={{color:'#333'}}>A: The academic year usually runs from August through May with breaks for holidays. Most universities use either the semester system (two terms), the quarter system (students attend three out of four total terms), or the trimester system (three terms).</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the difference between "Undergraduate" and "Graduate" degrees?</h3>
                        <p style={{color:'#333'}}>A: Undergraduate programs follow high school and lead to an associate (two-year) degree or a bachelor (four-year) degree. Graduate programs follow a bachelor’s degree and lead to a master’s or doctoral degree.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are the different types of undergraduate degrees?</h3>
                        <p style={{color:'#333'}}>A: Associate: a two-year program that either leads to a specific vocation or transitions to a bachelor program. Bachelor: a four or five-year program where students earn credits in a wide variety of courses.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are the different types of  graduate degrees?</h3>
                        <p style={{color:'#333'}}>A: Masters: two-year degree providing additional specialization. Doctorate: five to eight-year program certifying the student as a trained research scholar and/or professor.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is it possible to take a professional degree program without first earning a bachelor's degree?</h3>
                        <p style={{color:'#333'}}>A: Yes, but they are highly selective and require a heavy courseload across a total of six years of study.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is it possible to obtain a bachelor's degree and a master's degree at the same time?</h3>
                        <p style={{color:'#333'}}>A: In a joint-degree program, students begin a graduate program in their fourth year of college, earning both degrees upon graduation.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the length of study for MBA programs in the U.S.?</h3>
                        <p style={{color:'#333'}}>A: MBA programs typically last one to two years.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can you work while studying in the United States?</h3>
                        <p style={{color:'#333'}}>A: With permission of the International Student Office, international students may work on campus up to 20 hours/week their first year and can apply to work off-campus in subsequent years.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the difference between online studies and distance studies?</h3>
                        <p style={{color:'#333'}}>A: Essentially there is no difference.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is distance education?</h3>
                        <p style={{color:'#333'}}>A: Distance education occurs when a student and an instructor are in different places. Learning occurs by mail, telephone, internet, or by other means.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I transfer to a U.S. university from a university outside of the United States?</h3>
                        <p style={{color:'#333'}}>A: Yes, although you may lose some credits and require extra time to complete your degree.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the transfer application process?</h3>
                        <p style={{color:'#333'}}>A: You must fulfill the requirements of a freshman applicant, as well as any supplemental information required by the transfer institution.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is a community college?</h3>
                        <p style={{color:'#333'}}>A: Community colleges are typically state-supported and provide the first two years of a four-year undergraduate degree.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Why should I attend community college?</h3>
                        <p style={{color:'#333'}}>A: Community colleges offer lower costs, easier admission policies, close ties to state schools,and many of the required courses connected to a degree.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How do you transfer from a community college to a four-year university? </h3>
                        <p style={{color:'#333'}}>A: The transfer process varies for each school. It is best to target the four-year institution early and determine what is needed to transfer.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How can I find out if an institution is accredited?</h3>
                        <p style={{color:'#333'}}>A: Search the U.S. Department of Education's Office of Post-secondary Education website to see if an institution is accredited.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How can I find out if a specialized program of study is accredited?</h3>
                        <p style={{color:'#333'}}>A: For specialized program accreditation, see “Accredited Institutions of Postsecondary Education,” available from American Council on Education.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How can I find out which universities are rated best for a specific academic major?</h3>
                        <p style={{color:'#333'}}>A: Refer to college and university guides to find which institutions are known for excellence in different fields of study.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are English language proficiency requirements?</h3>
                        <p style={{color:'#333'}}>A: U.S. universities require an English language proficiency test before admission to ensure you can read, write, and speak fluently.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: I want to study in the United States, but my English proficiency isn’t good enough yet. What can I do?</h3>
                        <p style={{color:'#333'}}>A: There are a number of programs for English language study in the United States and online, as well as local possibilities.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do students have to study a fixed set of subjects or can they individually form their major?</h3>
                        <p style={{color:'#333'}}>A: It depends: Some degree programs are highly structured. Bachelors' degrees are very flexible and sometimes allow you to create your own program.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: When do you declare a major?</h3>
                        <p style={{color:'#333'}}>A: You do not declare a major until the end of you second year of school.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is a liberal arts college?</h3>
                        <p style={{color:'#333'}}>A: A liberal arts college offers courses in humanities, languages, math, social and natural sciences, and students take 25-50% of their courses in their major.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are the benefits of a liberal arts education?</h3>
                        <p style={{color:'#333'}}>A: A liberal arts college offers a smaller setting and teaches critical thinking and communication skills necessary in an ever-changing job market.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the credit system in U.S. universities?</h3>
                        <p style={{color:'#333'}}>A: A credit is a value assigned to each course which reflects the number of hours the class will meet with the professor each week.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is a GPA?</h3>
                        <p style={{color:'#333'}}>A: Grade Point Average (GPA) is a numeric indicator for a student's academic performance, calculated on a scale of 4.0.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the U.S. grading system?</h3>
                        <p style={{color:'#333'}}>A: Letter grades indicate a student's academic performance. Each letter grade has a numeric value which is used to calculate a GPA, on a scale of 4.0.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How are grades determined in U.S. universities?</h3>
                        <p style={{color:'#333'}}>A: Grades are typically determined by quizzes, midterms, final exams, papers, projects, class attendance, and class participation.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the difference between state and private universities?</h3>
                        <p style={{color:'#333'}}>A: State universities are funded by the state and are generally larger and less expensive than private universities.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Are there opportunities for university exchange programs?</h3>
                        <p style={{color:'#333'}}>A: Contact the office responsible for international programs at your institution to ask if your school has exchange agreements with U.S. universities.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How can an international student find out what academic subjects from their country are acceptable for a U.S. university?</h3>
                        <p style={{color:'#333'}}>A: Each U.S. university will want to review an international student's subjects and may ask the student to contact a credential evaluation agency.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How can I apply for vocational training in the United States? </h3>
                        <p style={{color:'#333'}}>A: U.S. institutions cannot issue I-20 forms for non-degree study, including vocational training. Community colleges offer technical/vocational study for an associate's degree.</p>
                    </div>
                                   
                </div>
                
            </div>

        </div>
    </div>

</div>

</section>



    <div className="advisor-details-area default-padding" style={{padding:0,}}>
   
   </div>



</div>
</div>
</div>
</div>

<Footer/>
    </Container>
  );
}
