/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import icon from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Header from './Header';
import Footer from './Footer';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput
} from "mdbreact";
import color from '@material-ui/core/colors/amber';



const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: 'row',

  },
  newbtn: {
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
  },

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize: 12,

  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop: "10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',


  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: '100%',

  },
  bgdark: {
    backgroundColor: '#3f8ccb',
  },
  day: {
    "&:hover": {
      background: "#efefef"
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc"
    }
  },
}));

export default function Compare(props) {
  const classes = useStyles();



  return (
    <Container className={classes.widths}>
      <Header />  <CssBaseline />
      <div className="login-area default-padding" style={{ paddingTop: 20, }}>
        <div className="">
          <div className="row" >
            <div className="col-md-12">
              <div className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard" style={{ backgroundImage: "url(" + "../assets/img/banner/6.jpg" + ")", padding: 70 }}>

                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">FAQ's</h2>
                    </div>

                  </div>
                </div>
              </div>


              <section class="video_sec blog_wrap">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12 col-sm-12">
                      <div class="blog_box faq">
                        <h2>Frequently Asked Questions?</h2>
                        <div class="blog_box_content">
                          <div class="list_faq_main">
                            <ul>
                              <li><a style={{ fontSize: 16, }} href="/General">General FAQ</a></li>
                              <li><a style={{ fontSize: 16, }} href="/UK"><span><img src="assets/img/flag/uk_flag_thumb.png" /></span> UK FAQ</a></li>
                              <li><a style={{ fontSize: 16, }} href="/Canada"><span><img src="assets/img/flag/canada_flag.png" /></span> Canada  FAQ</a></li>
                              <li><a style={{ fontSize: 16, }} href="/NewZealand"><span><img src="assets/img/flag/New_zealand_flag.png" /></span> New Zealand FAQ</a></li>
                              <li><a style={{ fontSize: 16, }} href="/Ireland"><span><img src="assets/img/flag/Ireland_flag.png" /></span> Ireland FAQ</a></li>
                              <li><a style={{ fontSize: 16, }} href="/France"><span><img src="assets/img/flag/Flag_of_France.png" /></span> France FAQ</a></li>
                              <li><a style={{ fontSize: 16, }} href="/Germany"><span><img src="assets/img/flag/germony_flag.png" /></span> Germany FAQ</a></li>
                              <li><a style={{ fontSize: 16, }} href="/Australia"><span><img src="assets/img/flag/aus_flag.png" /></span> Australia FAQ</a></li>
                              <li><a style={{ fontSize: 16, }} href="/Europe"><span><img src="assets/img/flag/europe_flag.png" /></span> Europe FAQ</a></li>
                              <li><a style={{ fontSize: 16, }} href="/USA"><span><img src="assets/img/flag/usa_flag.png" /></span> USA FAQ</a></li>
                              <li><a style={{ fontSize: 16, }} href="/Singapore"><span><img src="assets/img/flag/singapore.jpg" /></span> Singapore FAQ</a></li>
                              <li><a style={{ fontSize: 16, }} href="/Malaysia"><span><img src="assets/img/flag/Malaysia_flag.png" /></span> Malaysia FAQ</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>



              <div className="advisor-details-area default-padding" style={{ padding: 0, }}>

              </div>



            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Container>
  );
}
