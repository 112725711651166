
import React, { Fragment } from "react";
import { Text, StyleSheet } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
  MDBSideNav,
  MDBNavItem,
} from "mdbreact";

//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";

import color from "@material-ui/core/colors/amber";
import Header from "./Header";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
  category: {
    position: "sticky",
  },
  category: {
    a: {
      display: "block",
    },
  },
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [messages, setMessages] = React.useState("");
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus, setStatus] = React.useState("");

  const handlePicture = (event) => {
    setPicturePath(URL.createObjectURL(event.target.files[0]));
    setPicture(event.target.files[0]);
  };

  const handleVerifyPassword = (e) => {
    setVerifyPassword(e.target.value);
    if (password === e.target.value) {
      setStatus("Password Matched");
    } else {
      setStatus("Password not matched");
    }
  };
  console.log("History ==  ", props);



  
  return (
    <Container className={classes.widths}>
      <Header />
      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 70,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">Test Prepartion</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-area single full-blog left-sidebar full-blog default-padding">
                <div className="container">
                  <div className="row">
                    <div className="blog-items">
                      <div className="blog-content col-md-12">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      marginLeft: 5,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <h3>ACT Prep You Can Trust</h3>
                                    <p>
                                      Comprehensive ACT strategies and practice
                                      to help you earn your best score. Get all
                                      the ACT prep you want (plus SAT, AP, &
                                      more!) for a low monthly price!
                                    </p>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 20,
                                        color: "black",
                                      }}
                                    >
                                      Included in your subscription:
                                    </p>
                                    <a>
                                      <img src="/images/ACT.png"></img>
                                    </a>
                                    <br />
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Our most
                                    comprehensive option with customized test
                                    prep strategy, instructional content, and
                                    ample opportunity for practice.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Flashcards
                                    to help test your knowledge of key terms and
                                    definitions!
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;3 practice
                                    tests, all fully aligned with the most
                                    recent ACT exam.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Completely
                                    master each subject on the ACT with over 20
                                    exclusively written subject-based lessons
                                    from Peterson’s test prep experts.
                                    <br />
                                    <i class="fa fa-check"></i>
                                    &nbsp;Pre-Assessments test to assess your
                                    strengths and weaknesses, allowing you to
                                    focus only on the areas you need to study.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp; Learn
                                    where you want, when you want. Dynamic
                                    delivery means you can practice at your own
                                    pace for the ACT on your mobile device,
                                    tablet, or computer.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp; The ACT
                                    Online Prep Course is designed for test
                                    takers wanting guided instruction and
                                    desiring the highest score increase.
                                    <br />
                                    <br />
                                    <a >
                                      Learn More
                                    </a>
                                    <br />
                                    <br />
                                    <a
                                      
                                      style={{
                                        color: "white",
                                        background: "orange",
                                      }}
                                    >
                                      Subscribe Now!
                                    </a>
                                    <p>
                                      Starting at <br />
                                      $39 / month
                                    </p>

                                    <a >
                                        <img
                                      src="/images/ACT.png"></img>
                                    </a>

                                    <br />
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;7 online
                                    practice tests, all fully aligned with the
                                    updated ACT exam.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp; Detailed
                                    explanations for EVERY question.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp; Practice
                                    tests broken down by sub-content level to
                                    familiarize you with ACT format; break
                                    studying into chunks; and help you review
                                    content.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp; Learn
                                    where you want, when you want. Dynamic
                                    delivery means you can practice at your own
                                    pace for the ACT on your mobile device,
                                    tablet, or computer.
                                    <br />
                                    <br />
                                    <a>
                                      Learn More
                                    </a>
                                    <br />
                                    <br />
                                    <a
                                      
                                      style={{
                                        color: "white",
                                        background: "orange",
                                      }}
                                    >
                                      Subscribe Now!
                                    </a>
                                    <p>
                                      Starting at <br />
                                      $39 / month
                                    </p>
                                    
                                    <br />
                                    <br />
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Already
                                    bought the book and need access to your
                                    online tests?
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Add this to
                                    your cart and use the provided coupon code
                                    at checkout for complimentary access.
                                    <br />
                                    <br />
                                    <a >
                                      Learn More
                                    </a>
                                    <br />
                                    <br />
                                    <a
                                      
                                      style={{
                                        color: "white",
                                        background: "orange",
                                      }}
                                    >
                                      Subscribe Now!
                                    </a>
                                    <p>
                                      Starting at <br />
                                      $39 / month
                                    </p>
                                    <br />
                                    <hr/>
                                    <br />
                                    <h3
                                    >
                                      SAT Prep: Guidance You Can Trust
                                    </h3>
                                    <p>
                                      Comprehensive SAT strategies and practice
                                      to help you earn your best score. Get all
                                      the SAT prep you want (plus ACT, AP, &
                                      more!) for a low monthly price!
                                    </p>
                                    <a >
                                        <img
                                      src="/images/ACT.png"></img>
                                    </a>
                                    <br/>
                                    <br/>

                                    <i class="fa fa-check"></i>&nbsp;Our
                                    comprehensive SAT prep course helps you
                                    study effectively and efficiently with
                                    customized strategy sessions and over 18
                                    content reviews.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;3 practice
                                    tests, all fully aligned with the updated
                                    SAT exam.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Flashcards
                                    to help test your knowledge of key terms and
                                    definitions!
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;
                                    Pre-Assessment test to assess your strengths
                                    and weaknesses, allowing you to focus only
                                    on the areas you need to study.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp; Learn
                                    where you want, when you want. Dynamic
                                    delivery means you can practice at your own
                                    pace for the SAT on your mobile device,
                                    tablet, or computer.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;The SAT
                                    Online Prep Course is designed for test
                                    takers wanting guided instruction and
                                    desiring the highest score increase.
                                    <br />
                                    <br />
                                    <a >
                                      Learn More
                                    </a>
                                    <br />
                                    <br />
                                    <a
                                      
                                      style={{
                                        color: "white",
                                        background: "orange",
                                      }}
                                    >
                                      Subscribe Now!
                                    </a>
                                    <p>
                                      Starting at <br />
                                      $39 / month
                                    </p>

                                    <a >
                                        <img
                                      src="/images/ACT.png"></img>
                                    </a>


                                    <br />
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Online
                                    access to three practice tests with detailed
                                    explanations for EVERY question.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;3 practice
                                    tests, all fully aligned with the updated
                                    SAT exam.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Practice
                                    tests broken down by sub-content level
                                    familiarize you with SAT format, break
                                    studying into chunks, and help you review
                                    content.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Learn where
                                    you want, when you want. Dynamic delivery
                                    means you can practice at your own pace for
                                    the SAT on your mobile device, tablet, or
                                    computer.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp; Start here
                                    if you are already comfortable with the
                                    covered content, but need practice to
                                    perfect your skills and pacing for test day.
                                    <br />
                                    <br />
                                    <a >
                                      Learn More
                                    </a>
                                    <br />
                                    <br />
                                    <a
                                     
                                      style={{
                                        color: "white",
                                        background: "orange",
                                      }}
                                    >
                                      Subscribe Now!
                                    </a>
                                    <p>
                                      Starting at <br />
                                      $39 / month
                                    </p>

                                    <a >
                                        <img
                                      src="/images/ACT.png"></img>
                                    </a>

                                    <br />
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Already
                                    bought the book and need access to the
                                    online components?
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Add this to
                                    your cart and use your coupon code at
                                    checkout.
                                    <br />
                                    <br />
                                    <a >
                                      Learn More
                                    </a>
                                    <br />
                                    <br />
                                    <a
                                      
                                      style={{
                                        color: "white",
                                        background: "orange",
                                      }}
                                    >
                                      Subscribe Now!
                                    </a>
                                    <p>
                                      Starting at <br />
                                      $39 / month
                                    </p>
                                    <br />
                                    <hr/>
                                    <br />

                                    <h3>
                                      TOEFL Guidance You Can Trust
                                    </h3>
                                    <p>
                                      All the strategies and practice you need
                                      to achieve your ideal TOEFL score. Get all
                                      our TOEFL prep (plus ACT, SAT, GRE, and
                                      more!) for a low monthly price!
                                    </p>
                                    <a >
                                        <img
                                      src="/images/toefl_icon.png"></img>
                                    </a>

                                    
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Your
                                    step-by-step guide to mastering the exam.
                                    Peterson’s in-depth winning strategies +
                                    your work ethic = huge success!
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp; 3 practice
                                    tests
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp; In-depth
                                    answer explanations for each question
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Detailed
                                    results for continued improvement
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Learn where
                                    you want, when you want. Dynamic delivery
                                    means you can practice for the TOEFL iBT on
                                    your mobile device, tablet, or computer.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;
                                    <strong>Best for: </strong>test takers
                                    already comfortable with the covered
                                    content, but need{" "}
                                    <strong>practice to perfect </strong>their
                                    skills and pacing for test day.
                                    <br />
                                    <br />
                                    <a >
                                      Learn More
                                    </a>
                                    <br />
                                    <br />
                                    <a
                                     
                                      style={{
                                        color: "white",
                                        background: "orange",
                                      }}
                                    >
                                      Subscribe Now!
                                    </a>
                                    <p>
                                      Starting at <br />
                                      $39 / month
                                    </p>

                                    <a >
                                        <img
                                      src="/images/openbook2.png" height='150' weight='150'></img>
                                    </a>
                                    <br />
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Test your
                                    reading comprehension with practice tests
                                    that are fully aligned with the updated
                                    TOEFL Reading test.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;3 practice
                                    tests
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Each test
                                    contains 5 passages and 50 questions
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp; In-depth
                                    answer explanations for each question
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp; Learn
                                    where you want, when you want. Dynamic
                                    delivery means you can practice for the
                                    TOEFL Reading Comprehension on your mobile
                                    device, tablet, or computer.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;{" "}
                                    <strong>Best for: </strong> test takers who
                                    need a little extra{" "}
                                    <strong>
                                      practice on the Reading Comprehension
                                      section{" "}
                                    </strong>{" "}
                                    of the TOEFL.
                                    <br />
                                    <br />
                                    <a >
                                      Learn More
                                    </a>
                                    <br />
                                    <br />
                                    <a
                                      
                                      style={{
                                        color: "white",
                                        background: "orange",
                                      }}
                                    >
                                      Subscribe Now!
                                    </a>
                                    <p>
                                      Starting at <br />
                                      $39 / month
                                    </p>
                                    <a >
                                        <img
                                      src="/images/openbook2.png" height='150' weight='150'></img>
                                    </a>
                                    <br />
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp; Practice
                                    tests focused on the Structure & Written
                                    Expression portion of the TOEFL familiarize
                                    you with the format and question types
                                    involved in this section.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;120
                                    questions across 3 practice tests
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Diagnostic
                                    test to pinpoint strengths and weaknesses
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;In-depth
                                    answer explanations for each question
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Learn where
                                    you want, when you want. Dynamic delivery
                                    means you can practice for the TOEFL Writing
                                    and Grammar on your mobile device, tablet,
                                    or computer.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Start here
                                    if you need a little extra practice on the
                                    Writing & Grammar section of the TOEFL.
                                    <br />
                                    <br />
                                    <a >
                                      Learn More
                                    </a>
                                    <br />
                                    <br />
                                    <a
                                      
                                      style={{
                                        color: "white",
                                        background: "orange",
                                      }}
                                    >
                                      Subscribe Now!
                                    </a>
                                    <p>
                                      Starting at <br />
                                      $39 / month
                                    </p>
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Interactive
                                    flashcards help you memorize and use
                                    vocabulary words you will see on the TOEFL
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp; 400
                                    vocabulary words across 20 decks of
                                    flashcards
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Audio of
                                    each word plus definition
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;In-depth
                                    answer explanations for each question
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Learn where
                                    you want, when you want. Dynamic delivery
                                    means you can practice for the TOEFL
                                    Vocabulary on your mobile device, tablet, or
                                    computer.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Shuffle
                                    feature lets you ensure you know the word
                                    whenever you see it
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Understand
                                    words in context with helpful examples that
                                    show the meaning of each word.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;
                                    <strong>Best for:</strong> test takers who
                                    need a little extra practice on the
                                    Vocabulary section of the TOEFL.
                                    <br />
                                    <br />
                                    <a >
                                      Learn More
                                    </a>
                                    <br />
                                    <br />
                                    <a
                                      
                                      style={{
                                        color: "white",
                                        background: "orange",
                                      }}
                                    >
                                      Subscribe Now!
                                    </a>
                                    <p>
                                      Starting at <br />
                                      $39 / month
                                    </p>
                                    <br />
                                    <hr/>
                                    <br />
                                    <h3>GRE Guidance You Can Trust</h3>
                                    <p>
                                      Get all the GRE practice you need for a
                                      low monthly price!
                                    </p>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 20,
                                        color: "black",
                                      }}
                                    >
                                      Included in your subscription:
                                    </p>
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Learn where
                                    you want, when you want. Dynamic delivery
                                    means you can practice at your own pace for
                                    the GRE on your mobile device, tablet, or
                                    computer.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;3 practice
                                    tests, all fully aligned with the updated
                                    GRE exam
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Detailed
                                    answer explanations for every question
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Practice
                                    test results broken down by sub-content
                                    level
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;
                                    Exclusively written content from Peterson’s
                                    test prep experts
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Start here
                                    if you are already comfortable with the
                                    covered content, but need practice to
                                    perfect your skills and pacing for test day
                                    <br />
                                    <br />
                                    <a >
                                      Learn More
                                    </a>
                                    <br />
                                    <br />
                                    <a
                                      
                                      style={{
                                        color: "white",
                                        background: "orange",
                                      }}
                                    >
                                      Subscribe Now!
                                    </a>
                                    <p>
                                      Starting at <br />
                                      $39 / month
                                    </p>
                                    <br />
                                    <hr/>
                                    <br />
                                    <h3>GMAT Guidance You Can Trust</h3>
                                    <p>
                                      Get all the GMAT practice you need for a
                                      low monthly price!
                                    </p>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 20,
                                        color: "black",
                                      }}
                                    >
                                      Included in your subscription:
                                    </p>
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Learn where
                                    you want, when you want. Dynamic delivery
                                    means you can practice at your own pace for
                                    the GMAT on your mobile device, tablet, or
                                    computer.
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;3 practice
                                    sets
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Detailed
                                    answer explanations for every question
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp; Practice
                                    test results broken down by sub-content
                                    level
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Exclusively
                                    written content from Peterson’s test prep
                                    experts
                                    <br />
                                    <i class="fa fa-check"></i>&nbsp;Start here
                                    if you are already comfortable with the
                                    covered content, but need practice to
                                    perfect your skills and pacing for test day.
                                    <br />
                                    <br />
                                    <a >
                                      Learn More
                                    </a>
                                    <br />
                                    <br />
                                    <a
                                     
                                      style={{
                                        color: "white",
                                        background: "orange",
                                      }}
                                    >
                                      Subscribe Now!
                                    </a>
                                    <p>
                                      Starting at <br />
                                      $39 / month
                                    </p>
                                    <br />
                                    <h3>Test Prep Subscription</h3>
                                    <p>
                                      Our subscription plans allow you to study
                                      as quickly as you can, or as slowly as
                                      you’d like.
                                    </p>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 20,
                                        color: "black",
                                      }}
                                    >
                                      How does it work?
                                    </p>
                                    <ul style={{ listStyleType: "square" }}>
                                      <li>
                                        Unlimited usage of our entire test prep
                                        catalogue of over 185 exams (and
                                        growing!)
                                      </li>
                                      <li>
                                        Full-length, content-aligned practice
                                        tests for EVERY exam
                                      </li>
                                      <li>
                                        24/7 access to instructional courses,
                                        supplemental videos, flashcards, and
                                        quizzes{" "}
                                      </li>
                                    </ul>
                                    <br />
                                    <p style={{ fontSize: 15 }}>
                                      What is your preferred payment plan?
                                      <br />
                                      <br />
                                      Pay every month
                                      <br />
                                      $49/ month
                                      <br />
                                      <a >
                                        Subscribe Now
                                      </a>
                                      <br />
                                      Pay every 3 months
                                      <br />
                                      $45/ month
                                      <br />
                                    </p>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      Expert guidance
                                    </p>
                                    <p>
                                      We wrote the book on test prep--literally.
                                      Since 1966, we’ve been at the forefront of
                                      college search, admissions, and of course,
                                      test prep.
                                    </p>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      Study your way
                                    </p>
                                    <p>
                                      The only right way to study is the right
                                      way for you. From courses to practice
                                      tests to videos to flashcards, we offer a
                                      wide range of materials to fit your unique
                                      learning style.
                                    </p>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      Value
                                    </p>
                                    <p>
                                      For an affordable price, you’ll get
                                      unlimited test prep for all the exams you
                                      plan on taking. The cost of our
                                      subscription plans are significantly less
                                      than what competitors charge for a single
                                      course!
                                    </p>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      Prep in your pocket
                                    </p>
                                    <p>
                                      Convenient, 24/7 online access on desktop
                                      and mobile allows you to prep on the go.
                                      With Peterson’s, you can study where you
                                      want, when you want.
                                    </p>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      Depth
                                    </p>
                                    <p>
                                      Peterson’s has one of the largest, most
                                      robust test prep catalogues anywhere.
                                      Whether you’re taking a test to get into
                                      college or grad school, or to land your
                                      dream job, we’ve got you covered.{" "}
                                      <a >
                                        Take a peek!
                                      </a>
                                    </p>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      Personalized platform
                                    </p>
                                    <p>
                                      Stay on track by creating your own
                                      individual study plan, ranking confidence
                                      levels on test questions, and taking notes
                                      as you work through the material.
                                    </p>
                                    
                                    <br />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Container>
  );
}
