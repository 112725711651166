/* eslint-disable no-useless-concat */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { postDataAndImage, postData, getData } from "../FetchServices";
import { Select } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import { UncontrolledCollapse, CardBody, Card } from "reactstrap";
import Checkbox from "@material-ui/core/Checkbox";
import Header from "./Header";
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },

  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function Scholarships(props) {
  const classes = useStyles();
  const [getCountry, setCountry] = useState("");
  const [getUniversitiesList, setUniversitiesList] = useState([])
  const [student_ID, setstudent_ID] = React.useState("");


  const handleCountry = async (country) => {
    // alert("Hello");
    // console.log(JSON.stringify(country));
    let body = { country: country };
    let list = await postData("universities/fetchByCountry", body);
    // console.log(list);
    setUniversitiesList(list);
  };

  
  const localStorageData = async () => {
    try {
      let data = await localStorage.getItem("student");
    if(data){
      let result = JSON.parse(data);
      setstudent_ID(result.student_ID);
    } else{
      props.history.replace({pathname:'/Login'})
    }
    } catch (error) {
      props.history.replace({pathname:'/Login'})
    }   
  };

  useEffect(function () {
    fetchUniversityAll()
    // localStorageData()
  }, []);

  const fetchUniversityAll = async () => {
    // alert('list')
    let list = await getData("universities/DisplayAllUniversity");
    setUniversitiesList(list);
  };

  /*---------show University card Functiom------------- */
  const UniversitiesItem = () => {
    return getUniversitiesList.map((item) => {
      // console.log(JSON.stringify(item));
      return (
        <div className="container">
          <div className="row">
            <div
              className="col-md-12"
              style={{ boxShadow: "0 0 5px #cccccc", marginBottom: "5px" }}
            >
              <div className="event-items">
                <div className="item" style={{ marginBottom: 10 }}>
                  <div className="col-md-12">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={2}>
                        <icon
                          class="fa fa-university"
                          style={{
                            color: "black",
                            fontSize: "4rem",
                          }}
                        ></icon>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <div
                          className="content"
                          style={{
                            width: "-webkit-fill-available",
                          }}
                        >
                          <h4>
                            {" "}
                            <a
                              href="#"
                              style={{
                                color: "#008000",
                                fontSize: 12,
                              }}
                            >
                              Offered by University of StudyMetro
                            </a>
                          </h4>
                          
                          <h6>
                            <a href="#">{item.name}</a>
                          </h6>

                  {/* {localStorage.getItem("Scholarship")?<></>:(
               
                    <a href="/Login" >
                    {item.name}
                    </a>
              
                  )}  */}
                          
                        </div>
                      </Grid>
                    </Grid>
                    <div>
                      <div
                        className="content"
                        style={{
                          width: "-webkit-fill-available",
                        }}
                      >
                        <hr />
                        <MDBRow>
                          <MDBCol>
                            <strong>LEVEL</strong> <br />
                            Masters
                          </MDBCol>
                          <MDBCol>
                            <strong>TYPE</strong> <br />
                            Merit based
                          </MDBCol>
                          <MDBCol>
                            <strong>COUNTRIES</strong> <br />
                            {item.country}
                          </MDBCol>
                          <MDBCol>
                            <strong>DEADLINE</strong> <br />
                            Jul 31, 2020
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <p
                          style={{
                            fontSize: 14,
                            textAlign: "center",
                            paddingBottom: 0,
                            color: "rgb(0, 0, 0)",
                          }}
                        >
                          <a onClick={() => handleClickUnivesity(item.id)}>
                            View scholarship and apply
                          </a>
                          
                          {/* <a href="/ApplyScholarship">
                            View scholarship and apply
                          </a> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  /*---------passing Props By using on Click---------------*/

  const handleClickUnivesity = async (id) => {
    props.history.push({
      pathname: "/ApplyScholarship",
      state: {
        u_id: id,
      },
    });
  };

  return (
    <Container className={classes.widths}>
      <Header /> <CssBaseline />
      <div className="login-area default-padding" style={{ paddingTop: 20 }}>
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/6.jpg" + ")",
                  padding: 70,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <div class="col-lg-12">
                        <h2
                          className="wow fadeInLeft"
                          style={{ textAlign: "center" }}
                        >
                          Scholarship Opportunities for International Students
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="blog-area single full-blog left-sidebar full-blog default-padding">
                <div className="container">
                  <div className="">
                    <div className="blog-items">
                      <div
                        className="blog-content col-md-8"
                        style={{ boxShadow: "0 0 10 #cccccc" }}
                      >
                        {UniversitiesItem()}
                      </div>
                      <div className="sidebar col-md-4">
                        <aside>
                          <div
                            className="sidebar-item category"
                            style={{ padding: 15 }}
                          >
                            <div className="title">
                              <h4 style={{ fontSize: 14 }}>
                                Scholarships Filters
                              </h4>
                            </div>

                            <div className="sidebar-info">
                              <a color="primary" id="toggler" fullWidth>
                                {/* <Button color="primary" id="toggler" style={{ marginBottom: '1rem',textTransform:'unset', }}> */}
                                <i
                                  class="fa fa-flag"
                                  style={{
                                    marginRight: 9,
                                    color: "#3f8ccb",
                                    textDecoration: "uppercase",
                                  }}
                                ></i>{" "}
                                Country{" "}
                                <span style={{ float: "right" }}>
                                  <i class="fa fa-angle-down"></i>
                                </span>
                                {/* </Button> */}
                              </a>
                              <UncontrolledCollapse toggler="#toggler">
                                <ul
                                  style={{
                                    margin: 10,
                                    backgroundColor: "#fcfcfc",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ cursor: "pointer", margin: 5 }}
                                  >
                                    <i
                                      class="flag-icon flag-icon-us"
                                      style={{ marginRight: 5 }}
                                    ></i>
                                    <a onClick={() => handleCountry("USA")}>
                                      United States
                                    </a>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ cursor: "pointer", margin: 5 }}
                                  >
                                    <i
                                      class="flag-icon flag-icon-de"
                                      style={{ marginRight: 5 }}
                                    ></i>
                                    <a onClick={() => handleCountry("Germany")}>
                                      Germany
                                    </a>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ cursor: "pointer", margin: 5 }}
                                  >
                                    <i
                                      class="flag-icon flag-icon-ca"
                                      style={{ marginRight: 5 }}
                                    ></i>
                                    <a onClick={() => handleCountry("Canada")}>
                                      Canada
                                    </a>
                                    {/* <label
                                      onclick={() => handleCountry("Canada")}
                                    >
                                      Canada
                                    </label> */}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ cursor: "pointer", margin: 5 }}
                                  >
                                    <i
                                      class="flag-icon flag-icon-au"
                                      style={{ marginRight: 5 }}
                                    ></i>
                                    <a
                                      onClick={() => handleCountry("Australia")}
                                    >
                                      Australia
                                    </a>
                                    {/* <label
                                      onclick={() => handleCountry("Australia")}
                                    >
                                      Australia
                                    </label> */}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ cursor: "pointer", margin: 5 }}
                                  >
                                    <i
                                      class="flag-icon flag-icon-sg"
                                      style={{ marginRight: 5 }}
                                    ></i>
                                    <a
                                      onClick={() => handleCountry("Singapore")}
                                    >
                                      Singapore
                                    </a>
                                    {/* <label
                                      onclick={() => handleCountry("Singapore")}
                                    >
                                      Singapore
                                    </label> */}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ cursor: "pointer", margin: 5 }}
                                  >
                                    <i
                                      class="flag-icon flag-icon-nz"
                                      style={{ marginRight: 5 }}
                                    ></i>
                                    <a
                                      onClick={() =>
                                        handleCountry("New Zealand")
                                      }
                                    >
                                      New Zealand
                                    </a>
                                    {/* <label
                                      onclick={() =>
                                        handleCountry("New Zealand")
                                      }
                                    >
                                      New Zealand
                                    </label> */}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ cursor: "pointer", margin: 5 }}
                                  >
                                    <i
                                      class="flag-icon flag-icon-fr"
                                      style={{ marginRight: 5 }}
                                    ></i>
                                    <a onClick={() => handleCountry("France")}>
                                      France
                                    </a>
                                    {/* <label
                                      onclick={() => handleCountry("France")}
                                    >
                                      France
                                    </label> */}
                                  </Grid>
                                </ul>
                              </UncontrolledCollapse>
                            </div>
                            <div className="sidebar-info">
                              <a color="primary" id="toggler1" fullWidth>
                                {/* <Button color="primary" id="toggler" style={{ marginBottom: '1rem',textTransform:'unset', }}> */}
                                <i
                                  class="fa fa-sliders-h"
                                  style={{
                                    marginRight: 9,
                                    color: "#3f8ccb",
                                    textDecoration: "uppercase",
                                  }}
                                ></i>{" "}
                                Level of Education
                                <span style={{ float: "right" }}>
                                  <i class="fa fa-angle-down"></i>
                                </span>
                                {/* </Button> */}
                              </a>
                              <UncontrolledCollapse toggler="#toggler1">
                                <ul
                                  style={{
                                    margin: 10,
                                    backgroundColor: "#fcfcfc",
                                  }}
                                >
                                  <Grid item xs={12}>
                                    <label>Undergrad</label>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <label>Master</label>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <label>Phd</label>
                                  </Grid>
                                </ul>
                              </UncontrolledCollapse>
                            </div>
                            {/* <div className="sidebar-info">
                              <a color="primary" id="toggler2" fullWidth>
                             
                                <i
                                  class="fa fa-thumbtack"
                                  style={{
                                    marginRight: 10,
                                    color: "#3f8ccb",
                                    textDecoration: "uppercase",
                                  }}
                                ></i>{" "}
                                Type of Scholarship
                                <span style={{ float: "right" }}>
                                  <i class="fa fa-angle-down"></i>
                                </span>
                           
                              </a>
                              <UncontrolledCollapse toggler="#toggler2">
                                <ul
                                  style={{
                                    margin: 10,
                                    backgroundColor: "#fcfcfc",
                                  }}
                                >
                                  <Grid item xs={12}>
                                    <label>Need based</label>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <label>Merit based</label>
                                  </Grid>
                                </ul>
                              </UncontrolledCollapse>
                            </div> */}

                            {/* <div className="sidebar-info">
                              <a color="primary" id="toggler3" fullWidth>
                           
                                <i
                                  class="fa fa-hand-holding-usd"
                                  style={{
                                    marginRight: 9,
                                    color: "#3f8ccb",
                                    textDecoration: "uppercase",
                                  }}
                                ></i>
                                Type of Provider
                                <span style={{ float: "right" }}>
                                  <i class="fa fa-angle-down"></i>
                                </span>
                             
                              </a>
                              <UncontrolledCollapse toggler="#toggler3">
                                <ul
                                  style={{
                                    margin: 10,
                                    backgroundColor: "#fcfcfc",
                                  }}
                                >
                                  <Grid item xs={12}>
                                    <label>Government</label>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <label>University</label>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <label>Third party</label>
                                  </Grid>
                                </ul>
                              </UncontrolledCollapse>
                            </div> */}
                          </div>
                        </aside>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
