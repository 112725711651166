/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import icon from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Header from './Header';
import Footer from './Footer';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';



const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
newbtn:{
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
},

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:12,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },

widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function Compare(props) {
  const classes = useStyles();
 


  return (
   <Container className={classes.widths}>
   <Header/>  <CssBaseline />
    <div className="login-area default-padding" style={{paddingTop:20,}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">
<div className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard" style={{backgroundImage: "url(" + "../assets/img/banner/6.jpg" + ")",padding:70}}>             

        <div className="container">
            <div className="row">
            <div className="double-items col-md-12 info">
                <h2 className="wow fadeInLeft">France</h2>
                </div>
             
            </div>
        </div>
    </div>


    <section class="video_sec blog_wrap">
<div class="container">
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <div class="blog_box faq">
                <h2>Frequently Asked Questions?</h2>
                <div class=" blog_box_content">
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is there a deadline for applying?</h3>
                        <p style={{color:'#333'}}>A: No its always better apply maximum before 90 days of the Intake date.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: IS GRE/GMAT or IELTS/TOEFL is required for admission?</h3>
                        <p style={{color:'#333'}}>A: No Does not required for 90 % of the Schools.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much Time it will take to get the offer letter?</h3>
                        <p style={{color:'#333'}}>A: 15 to 20 Days Approx.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long will it take for me to obtain my internship employment?</h3>
                        <p style={{color:'#333'}}>A: Internship is available for 6 to 12 months , you can avail mostly in 2nd Sem.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Must I find an internship job only in the field related to my major or is any job O.K.?</h3>
                        <p style={{color:'#333'}}>A: Internship is a part of Practical Education.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How many working hours will I probably work each week?</h3>
                        <p style={{color:'#333'}}>A: Students are entitled to work part time up to 20 hours a week off campus.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much is the bank statement required for applying VISA?</h3>
                        <p style={{color:'#333'}}>A: Need to show the entire tuition fees and the living expenses for the entire length of program (615 Euro Per month Living expenses)</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long I have to keep amount in my bank account for VISA?</h3>
                        <p style={{color:'#333'}}>A: 3 Months Approx.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much tuition fee I have to pay before and after getting VISA/Offer letter?</h3>
                        <p style={{color:'#333'}}>A: After Offer Letter you need to pay around 20 to 30 % fees the remaining after the Visa.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can one apply for Dependent VISA?</h3>
                        <p style={{color:'#333'}}>A: Yes.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I apply work permit after completing my studies?</h3>
                        <p style={{color:'#333'}}>A: Students who have earned at least a master’s degree or the equivalent may apply for a one-time (non-renewable) temporary residency authorization valid for 6 months beyond the date of expiration of the student’s residency permit.
Other students may also accept employment under certain circumstances. They must obtain a change of status (from student to employee) and follow a special procedure.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much time will I get after my studies to search job there?</h3>
                        <p style={{color:'#333'}}>A: Approx 1 Year</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Why Should I study in France?</h3>
                        <p style={{color:'#333'}}>A: a)	Reasons to study in France
b)	Quality Education
c)	Developed Country
d)	Sixth largest economy
e)	World's 3rd leading host country for higher education
f)	Reasonable Fees
g)	Part time Job opportunities
h)	Internship opportunities with top companies of the world
i)	Work permit opportunities after internship
j)	Accommodation help by 40% to 50% by French government
k)	Low transportation expenses
l)	Free French Language courses provided by French Government Organization
m)	European Union Visa.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I need to know French to Study in France?</h3>
                        <p style={{color:'#333'}}>A: No, several courses especially in engineering (all branches), management, hospitality, fashion studies, economics, etc are available in English.  it is a good idea to start on French to make your stay more rewarding and fruitful.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Where can I learn French?</h3>
                        <p style={{color:'#333'}}>A: You will be taught French as a subject in the school in which you are enrolling for your bachelors or masters. Moreover French Govt. has set up various organizations where you can learn French for free.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What kind of scholarships are available to study in France?</h3>
                        <p style={{color:'#333'}}>A: In sheer applicant to beneficiary ratio terms France offers several scholarships. Almost 1 in 7 applicants receives some form of financial aid. The French Embassy offers the incentive scholarships, the French ministry of External affairs offers the Eiffel grants of excellence, the institutions in France themselves may waive certain fees, there are corporate scholarships offered by Thales and so many more.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Why study in France ?</h3>
                        <p style={{color:'#333'}}>A: For the quality of the educational system: Internationally recognized diplomas, degrees, and certificates; 400 internationally renowned institutions of higher education and research; a system of accreditation that ensures quality education.
France devotes almost 20 percent of its annual national budget to education–about 10,000 Euros per student! Domestic and international students are treated equally at French institutions of higher education.
For scientific, industrial, and economic know-how: France is the source of technological marvels such as the high-speed train, the smart card, the Ariane rocket, and Airbus planes.
France is also the world's fourth largest economy, home to world-class manufacturing groups such as Renault, Michelin, L'Oréal, Total, Orange and Carrefour, and many thousands of promising start-ups and thriving smaller businesses.
For the quality of French life : a superb system of public transportation, modern and affordable health care, cities in which you feel safe and secure.
For culture and savoir-vivre: France has a long and rich cultural heritage, and a lively cultural scene featuring festivals, theater, fashion, and good eating. France produces 120 - 150 films a year, and Paris has the world's highest density of cinemas.
For the language: French – the official language of almost 200 million people – opens doors in 47 countries all over the world. It is also the only languague with English to be spoken on the 5 continents.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much does it cost ?</h3>
                        <p style={{color:'#333'}}>A: Studying in France is relatively inexpensive because the government funds a significant share of the cost. Annual tuition in a public university is between 175 and 570 Euros, depending on the program. A meal in a university restaurant costs 3 Euros. Costs in private institutions are higher. Remember, international students are treated just like French students.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What about the cost of living in France ?</h3>
                        <p style={{color:'#333'}}>A: Most students need 600 to 800 Euros each month to cover the costs of food, transportation, and housing. The amount you will need depends on where in France you live and on what type of housing you select.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: May I work in France ?</h3>
                        <p style={{color:'#333'}}>A: International students may work half-time as long as they are registered at an institution approved by the French social security system. Even first-year students and students coming to France for the first time have the right to work half-time.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How to get a visa? I have just arrived in France and I hold a Long Stay Visa valid VLS-TS, how do I validate it?</h3>
                        <p style={{color:'#333'}}>A: International students coming from countries outside the European Union must obtain a long-term student visa from a French consulate.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Health insurance is mandatory. Why ?</h3>
                        <p style={{color:'#333'}}>A: France's system of social protection is one of the world's best. The system covers all or part of the health-care costs of insured parties.
Students under the age of 28 are covered by social security when they register with an approved educational institution.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q:  Do I have to be able to speak French ?</h3>
                        <p style={{color:'#333'}}>A: A knowledge of French will enable you to benefit more fully from your stay and to get more out of life in France.
If your French isn't as good as you'd like, take some courses in France or in your home country.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q:  Have equivalences between established between French degrees and degrees in other countries ?</h3>
                        <p style={{color:'#333'}}>A: France recognizes no formal equivalences between its postsecondary degrees and degrees in other countries. Each French institution makes its own admission decisions based on the applicant's background and the demands of the program.
To enter the French university system, international students must show that they are eligible to enroll in a university program in their country of residence.
</p>
                    </div>
                                   
                </div>
                
            </div>

        </div>
    </div>

</div>

</section>



    <div className="advisor-details-area default-padding" style={{padding:0,}}>
   
   </div>



</div>
</div>
</div>
</div>

<Footer/>
    </Container>
  );
}
