/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import icon from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Header from './Header';
import Footer from './Footer';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';



const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
newbtn:{
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
},

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:12,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },

widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function Compare(props) {
  const classes = useStyles();
 


  return (
   <Container className={classes.widths}>
   <Header/>  <CssBaseline />
    <div className="login-area default-padding" style={{paddingTop:20,}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">
<div className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard" style={{backgroundImage: "url(" + "../assets/img/banner/6.jpg" + ")",padding:70}}>             

        <div className="container">
            <div className="row">
            <div className="double-items col-md-12 info">
                <h2 className="wow fadeInLeft">Ireland</h2>
                </div>
             
            </div>
        </div>
    </div>

    <section class="video_sec blog_wrap">
<div class="container">
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <div class="blog_box faq">
                <h2>Frequently Asked Questions?</h2>
                <div class=" blog_box_content">
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is there a deadline for applying?</h3>
                        <p style={{color:'#333'}}>A: Mostly, 2 months before starting intake date. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: IS GRE/GMAT or IELTS/TOEFL is required for admission?</h3>
                        <p style={{color:'#333'}}>A: Yes, IELTS/TOFEL is required for admissions 5.0 band all over for Diplomas, and Post  diploma 5.5 bands.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much Time it will take to get the offer letter?</h3>
                        <p style={{color:'#333'}}>A: Within 1 or 2 working weeks after submitting all the documents required for admission. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I work on campus?</h3>
                        <p style={{color:'#333'}}>A: Yes, student can work on campus or off campus.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long will it take for me to obtain my internship employment?</h3>
                        <p style={{color:'#333'}}>A: Minimum 2 to 3 weeks.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What type of employment will I get initially?</h3>
                        <p style={{color:'#333'}}>A: You can get an internship job into your field as an  if you find it difficult then can do any   part time job mean while till you get your intern job into your major.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Must I find an internship job only in the field related to my major or is any job O.K.?</h3>
                        <p style={{color:'#333'}}>A: If you find a internship job into the field related to your major will be recommended as internship job is for the international explorer.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How many working hours will I probably work each week?</h3>
                        <p style={{color:'#333'}}>A: 20 hrs per week, and can work full time i.e 40hrs per week in the off sessions.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What if I can't find employment?</h3>
                        <p style={{color:'#333'}}>A: You can do any part time job mean while till you get your intern job into your major.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Will I have to pay taxes?</h3>
                        <p style={{color:'#333'}}>A: If you're a student and you have a job, you'll have to pay Income Tax and National Insurance if you earn over a certain amount. This still applies if you work abroad during your holidays and if you're a foreign student working.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Could the work authorization be possibly converted into a full time work permit upon completion of the masters program?</h3>
                        <p style={{color:'#333'}}>A: Yes, after completion of the study program a student gets a 1yr post study visa.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much is the bank statement required for applying VISA?</h3>
                        <p style={{color:'#333'}}>A: 50% of the fess should be paid, and the other 50% of the fees + 1yr living expenses should be shown in the bank statement.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long I have to keep amount in my bank account for VISA?</h3>
                        <p style={{color:'#333'}}>A: You need to show a Bank statement which is 6months old.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much tuition fee I have to pay before and after getting VISA/Offer letter?</h3>
                        <p style={{color:'#333'}}>A: After offer letter 50% of the fees must be paid to the university and show the bank statement of 50% of the fees + 1 yr living expense for visa purpose.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can one apply for Dependent VISA?</h3>
                        <p style={{color:'#333'}}>A: No, Only If students are going for their Maters dependents can fly.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much fund required for as a dependent VISA?</h3>
                        <p style={{color:'#333'}}>A: Additional fund in the bank which supports their living expenses.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is required in order to bring my spouse and family?</h3>
                        <p style={{color:'#333'}}>A: You will need to produce the documents needed to show your spouse’s or civil partner's status and evidence of your marital or civil partner status (for example, birth or naturalization certificate of Irish spouse, marriage or civil registration certificate).</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my spouse get the wok permit there?</h3>
                        <p style={{color:'#333'}}>A: No.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much duration work permit will spouse get there?</h3>
                        <p style={{color:'#333'}}>A: Dependents are not allowed to work in Ireland</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I apply work permit after completing my studies?</h3>
                        <p style={{color:'#333'}}>A: Yes, after completion of the studies student is getting 1yr of stay back option for the purpose of finding job for full time.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much time will I get after my studies to search job there?</h3>
                        <p style={{color:'#333'}}>A: The time for searching jobs is included in the 1yr stay back option.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Why Should I study in Ireland?</h3>
                        <p style={{color:'#333'}}>A: •	Ireland is a friendly and safe environment.
•	Student can get paid internship for international exposure.
•	A student gets a post study visa of 1yr.
•	Opportunities for PR.
•	Students are allowed to work full time during the off session.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q:  Can I show my sponsor bank statement?</h3>
                        <p style={{color:'#333'}}>A: Yes, you can show bank statement of even more than 2 sponsors. Even 3rd party bank statement is accepted.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I need to attend the visa interview personally?</h3>
                        <p style={{color:'#333'}}>A: No , only the documents should be sent to the immigration. Student is not required to be physically present for the visa interview.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q:  What is the cost of living?</h3>
                        <p style={{color:'#333'}}>A: Living expenses will differ depending upon the location of the institution, the type of accommodation preferred and on the personal expenditure of the student. On average, we estimate that a student will spend between €6,000 and €11,000 per year depending on location and lifestyle.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I need to register with the police?</h3>
                        <p style={{color:'#333'}}>A: All non-Irish nationals, who are not citizens of the EU/EEA or Switzerland, must register in person with the Garda National Immigration Bureau (GNIB) after arrival and after completion of registration at your third level institution. The GNIB will issue you with a residence permit/GNIB card.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How do I get a visa?</h3>
                        <p style={{color:'#333'}}>A: If you are an EU citizen, there are no visa requirements when entering Ireland. If you are not an EU citizen, please contact your nearest Irish Embassy or Consulate to find out whether or not you require an entry visa or you may enquire from the Department of Foreign Affairs. Those who do not have an Irish diplomatic mission in their home country should download a visa application form well in advance of their departure.   Please note that it is possible for international students who require a visa and are residing in certain countries to apply directly to the Department of Foreign Affairs online. Click here for a full list of these countries and the link to the online application form.

Note: You will be required to have accepted an offer of a recognised programme of study in Ireland and to have a receipt of payment letter from a college in Ireland before you can begin the process of applying for your visa overseas to study in Ireland.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I have to pay for medical insurance?</h3>
                        <p style={{color:'#333'}}>A: Students from EU member states who are in possession of the European Health Insurance Card (EHIC) are entitled to public health services in Ireland.  This card is available from health authorities in your home country.  Further information to your rights on healthcare while here can be obtained on the European Health Insurance Card website.

For students from non-EU countries, you will be required to have medical insurance in place in order to be granted a visa.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I study part time?</h3>
                        <p style={{color:'#333'}}>A: If you are from a non-EU country, you must complete a minimum of 15 hours scheduled contact time per week in order to be eligible to be eligible for a visa to study in Ireland. Please see the following Department of Justice web site for more information. If you are an English language study wishing to study for less than 90 days in Ireland and combine learning with other tourist pursuits, you will be classed as an educational tourist and subject to visit/holiday visa rules.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What will the weather be like?</h3>
                        <p style={{color:'#333'}}>A: Ireland's climate can be summed up as being mild, moist and changeable with abundant rainfall and a lack of temperature extremes. Because the island is hugged all year round by the warm influence of the Gulf Stream, Ireland is much warmer than other countries that share its latitude. The Gulf Stream also ensures that the Irish coastline remains ice-free throughout winter. Extreme winters are rare but they do happen on the odd occasion when Ireland's temperatures plummet.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q:  How do I open a bank account?</h3>
                        <p style={{color:'#333'}}>A: In order to open a bank account, you will need two forms of identification. Irish banks are obliged to establish your identity and verify your address. You cannot use the same document as proof of both your identity and your address. These are minimum requirements and it is at the discretion of the bank to look for further information.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I work during or after my studies?</h3>
                        <p style={{color:'#333'}}>A: Non-EU students who are approved to study in Ireland with higher education institutions listed on the ILEP, can avail of casual work to help support themselves while in Ireland. Students are allowed to work part-time (up to 20 hours a week) or full-time (up to 40 hours a week) during holiday periods. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Are there scholarships?</h3>
                        <p style={{color:'#333'}}>A: Yes. A small number of scholarships for overseas students are available from the universities and colleges. These are awarded solely at the discretion of the individual institutions who set down their own criteria for eligibility. Students are advised to contact the institution of their choice directly, to obtain information.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much money should I bring to Ireland for long-term study?</h3>
                        <p style={{color:'#333'}}>A: If you are from a country outside of the EEA, Norway, Iceland, Switzerland and Liechtenstein, and studying on our Academic Year programme you will be required to show evidence that you access to a minimum of €3,000 to cover your expenses while living in Ireland. You’ll need to provide eivdence of this when you register with local imigration on arrival in Ireland (GNIB). You can show evidence of this in a number of ways:
a)             A statement from an Irish bank account in your name showing €3,000
b)            A current foreign bank statement less than one month old showing €3,000, together with a past statement within the last 6 months, for an account in the your name (as in your passport) accompanied by a debit card in the same name and same bank account. The bank statement does not have to be in English once the text is in the Roman alphabet and the your name and currency are clear. If the text is in a different script it must be professionally translated and certified/stamped. Students may also use their parent’s bank statement if they are funding them. Any statement from a parental account must share the same surname of the student and be accompanied by a colour copy of their passport.
c)             Documentary evidence that money is held in trust for a student by a financial services body regulated in Ireland by the Central Bank of Ireland.
d)            A pre-paid credit or debit card(s) with a verification of the amount(s) in credit.
We can help you open a bank account however due to increased fraud compliance it currently takes an average of 4 weeks for international students to open an Irish bank account. Students are advised to use an alternative means to show proof of funds to GNIB. 
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I need medical insurance?</h3>
                        <p style={{color:'#333'}}>A: If you are a student from within the EU you should apply for the European Health Card and bring this with you in Ireland.
If you are a Non EU student you must purchase travel insurance for the duration of your course in Ireland. We supply suitable insurance that cover for € 125 per year in case you have an accident or suffer an illness that incurs medical costs. Download the policy below.

</p>
                    </div>
                                   
                </div>
                
            </div>

        </div>
    </div>

</div>

</section>

    <div className="advisor-details-area default-padding" style={{padding:0,}}>
   
   </div>



</div>
</div>
</div>
</div>

<Footer/>
    </Container>
  );
}
