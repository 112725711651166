/* eslint-disable no-useless-concat */
import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import Header from "./Header";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    // margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function LandingPage(props) {
  const classes = useStyles();

  //   const addNewRecord=async()=>{
  // if(picture=='')
  // {
  //     var body={'firstName':firstName,
  //     'lastName':lastName,
  //     'emailId':emailId,
  //     'password':password,
  //     'status':"Not Approved",
  //     'picture':"Not Found"}
  //     var result=await postData('selfregister/addNewRecord',body)
  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}

  // }

  // else{
  //    var formData=new FormData()
  //    formData.append('firstName',firstName)
  //    formData.append('lastName',lastName)
  //    formData.append('emailId',emailId)
  //    formData.append('password',password)
  //    formData.append('picture',picture)
  //    formData.append('status', "Not Approve")

  //    var config={headers:{'content-type':'multipart/form-data'}}
  //     var result=await postDataAndImage('selfregister/addNewRecordPic',formData,config)

  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}
  //     }
  //     }

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 70,
                }}
              >
                {/* <div className="banner-area inc-form bg-gradient slider-less text-light" style={{backgroundImage: "url(" + "../assets/img/event/1.jpg" + ")", padding:70}}> */}
                <div className="container">
                  <div className="row">
                    {/* <div className="double-items">
                    <div className="col-md-7 info">
                        <h2 className="wow fadeInLeft">Find your ideal study program.</h2>
                        <a className="btn day btn-light border btn-md wow fadeInDown" style={{color:'#3f8ccb'}} href="#">View Courses</a>
                    </div>
                    <div className="col-md-5 reg-form">
                        <form action="#">
                            <div className="row">
<img style={{flex:1}} class="login_image" src="assets/img/elearning.png" alt="Login" data-original-title="" title="" />

                            </div>
                        </form>
                    </div>
                </div> */}
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">Credit Transfer</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="about-area default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div className="container">
                  <div className="row">
                    <div className="about-items">
                      <div className="col-md-6 about-info">
                        <h2>
                          Credit <span>Transfer</span>
                        </h2>

                        <p
                          style={{
                            fontSize: 14,
                            color: "#212121",
                            textAlign: "justify",
                          }}
                        >
                          Academic credits or college credits are also an
                          significant part global education you'll have to find
                          out more about, since it will help you understand how
                          your progress is assessed during your research or the
                          way you are able to move credits points to research in
                          a university overseas.
                        </p>
                      </div>
                      <div
                        className="col-md-6 features text-light"
                        style={{ textAlign: "end" }}
                      >
                        <img
                          style={{ flex: 1 }}
                          class="login_image"
                          src="assets/img/moneytr.jpg"
                          alt="Login"
                          data-original-title=""
                          title=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="blog-area single full-blog left-sidebar full-blog default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="container"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <div className="row">
                    <div className="blog-items">
                      <div className="blog-content col-md-12">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                {/* <div className="content" style={{paddingBottom:0,}}>
<h4>Travel Insurance Consultants</h4>
                                        </div> */}

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    {/* <p style={{ color: "#212121" }}>
                                      Academic credits or college credits are
                                      also an significant part global education
                                      you'll have to find out more about, since
                                      it will help you understand how your
                                      progress is assessed during your research
                                      or the way you are able to move credits
                                      points to research in a university
                                      overseas.
                                    </p> */}
                                  </div>
                                </div>
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      What's a instructional credit system? It
                                      is important to realize how credits
                                      operate and the way that credit points out
                                      of one instructional system are converted
                                      into credits from additional charge
                                      systems (if at all possible ). Sometimes
                                      students will need to take prep courses so
                                      as to meet beginning credit demands
                                      required for college entrance.
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      Compare Experts worldwide
                                      <br />
                                      The very important academic credits for
                                      Global students are:
                                      <br />
                                      <br />
                                      How? You'll be evaluated by your professor
                                      concerning the total amount of knowledge
                                      and abilities you will achieve as soon as
                                      you finish that program. Typical Types of
                                      evaluation are a mix of:
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      Real attendance
                                      <br />
                                      evaluations obtained during the class
                                      <br />
                                      projects/research perform
                                      <br />
                                      oral/written evaluation
                                      <br />
                                      Mostly, every class is worth a certain
                                      number of credit points, depending on
                                      different criteria such as student's
                                      workload, learning result and contact
                                      hours. Normally, the more effort and work
                                      a student is needed to put to a program,
                                      the credits which path is worth. The
                                      proposed workload is a quote for a normal
                                      student.
                                      <br />
                                      <br />
                                      Academic credit programs in Australia
                                      Australian colleges do not have a unified
                                      credit system. Each college calculates the
                                      credits based on workload and amount of
                                      research hours per each program.
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      Academic credit programs from the U.S.
                                      <br />
                                      At the U.S., pupils get semester credit
                                      hours, that can be based on the amount of
                                      contact hours accumulated during a
                                      semester. Mainly, you may need to take
                                      approximately 5 classes each session,
                                      where each class is worth 3 semester
                                      credit hours, the equivalent of 45-48
                                      contact hours. These would include up to
                                      30 credits Each Year, the mandatory amount
                                      to successfully complete a diploma from
                                      the U.S.
                                      <br />
                                      <br />
                                      Top universities in the U.S.
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      Academic charge system
                                      <br />
                                      <br />
                                      Thus, the number of credits for a
                                      Bachelor's or Master's diploma in Europe?
                                      In ECTS, a complete research year
                                      generally consists of 60 credits,
                                      therefore finishing a Bachelor's level
                                      would necessitate earning 180 credits and
                                      a Master's level would require 120 credit
                                      points.
                                      <br />
                                      <br />
                                      Top universities out of global
                                      destinations at the EU
                                      <br />
                                      Faculties in Germany
                                      <br />
                                      Faculties in Spain
                                      <br />
                                      Faculties in Ireland
                                      <br />
                                      Faculties in the Netherlands
                                      <br />
                                      Faculties in Finland
                                      <br />
                                      Have a look at top Experts in Europe
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      Through instructional credits, pupils
                                      receive a consistent and clear method of
                                      assessing their learning accomplishments.
                                      The given credits are listed in a charge
                                      transcript which may result in a
                                      qualification.
                                      <br />
                                      <br />
                                      Main advantages of academic credit
                                      programs:
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      Credits support your entrance to a higher
                                      education programme
                                      <br />
                                      They keep an eye on student progress and
                                      decide when he's fulfilled study
                                      requirements
                                      <br />
                                      They gauge that the workload of a
                                      programme
                                      <br />
                                      it's possible to move to another
                                      university programme whilst maintaining
                                      all or part previously earned credit
                                      issues
                                      <br />
                                      Use the credit you got to study overseas
                                      -- academic credit is utilized and
                                      recognised globally Academic credits
                                      behave as evidence of earlier research if
                                      you're trying to find a work Some
                                      universities utilize academic analysis
                                      credits to establish amount prices How is
                                      academic charge spread between classes?
                                      <br />
                                      Take the instance of the ECTS system
                                      utilized in EU/EEA nations. The needed
                                      quantity of credits through a year is 60
                                      credits, so which means 30 credits each
                                      session. Generally, you might have about
                                      four compulsory courses during a session,
                                      with each class worth a mean of 7.5
                                      credits. But, there could be instances of
                                      courses earning you 9 credits, along with
                                      others 5 or 4, but the term complete
                                      always contributes to 30 credits.
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      What's a student's workload?
                                      <br />
                                      Workload describes this particular
                                      quantity of time that it requires to an
                                      ordinary pupil to achieve the desirable
                                      learning outcome. The workload comprises
                                      most academic tasks such as seminars,
                                      lectures, individual study, examinations,
                                      etc..
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      What does learning result imply?
                                      <br />
                                      Learning results denotes the degree of
                                      understanding students are expected to
                                      gain as a way to apply after completing a
                                      process of studying. This might also
                                      incorporate skills obtained they are able
                                      to apply in future livelihood.
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      What's a contact hour?
                                      <br />A touch is generally equivalent to
                                      50 minutes also identifies a lecture or a
                                      laboratory, therefore essentially a
                                      teaching course.
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  ></div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div className="col-md-12">
                                    <div
                                      className="content"
                                      style={{ paddingBottom: 0 }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area default-padding">
        <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="row" style={{ display: "block" }}>
            <div className="about-items">
              <div className="col-md-12">
                <div className="weekly-top-items">
                  <div className="top-courses" style={{ paddingRight: 0 }}>
                    <div className="heading" style={{ paddingBottom: 15 }}>
                      <h4 style={{ color: "#3f8ccb" }}>
                        ACCREDITATIONS AND MEMBERSHIPS <strong></strong>
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/EAIE.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/ICEF.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/NAFSA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/QISAN.jpeg"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIRC.gif"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIEA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
