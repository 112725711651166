/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import icon from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Header from './Header';
import Footer from './Footer';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';



const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
newbtn:{
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
},

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:12,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },

widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function Compare(props) {
  const classes = useStyles();
 


  return (
   <Container className={classes.widths}>
   <Header/>  <CssBaseline />
    <div className="login-area default-padding" style={{paddingTop:20,}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">
<div className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard" style={{backgroundImage: "url(" + "../assets/img/banner/6.jpg" + ")",padding:70}}>             

        <div className="container">
            <div className="row">
            <div className="double-items col-md-12 info">
                <h2 className="wow fadeInLeft">Malaysia</h2>
                </div>
             
            </div>
        </div>
    </div>


    <section class="video_sec blog_wrap">
<div class="container">
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <div class="blog_box faq">
                <h2>Frequently Asked Questions?</h2>
                <div class=" blog_box_content">
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is there a deadline for applying?</h3>
                        <p style={{color:'#333'}}>A: No deadline for admissions.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: IS GRE/GMAT or IELTS/TOEFL is required for admission?</h3>
                        <p style={{color:'#333'}}>A:  IELTS/ TOFEL not required for admission</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much Time it will take to get the offer letter?</h3>
                        <p style={{color:'#333'}}>A: It takes minimum 3 weeks and maximum 1 month to get offer letter.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can i work on campus?</h3>
                        <p style={{color:'#333'}}>A: Yes, you can work both on-campus or off campus</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long will it take for me to obtain my internship employment?</h3>
                        <p style={{color:'#333'}}>A: To obtain an internship employment it will take around 1 or 2 months maximum.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What type of employment will I get Initially?</h3>
                        <p style={{color:'#333'}}>A: Employment is depending on your skills and experience.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Must I find an internship job only in the field related to my major or is any job O.K.?</h3>
                        <p style={{color:'#333'}}>A: We recommend you to find internship jobs into the concerned field of your study program as internships are meant for international exposure and practical experience. 

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How may working hours will I probably work each week?</h3>
                        <p style={{color:'#333'}}>A: 20hrs per week a student can work legally.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I have to work overtime? Can I keep the overtime bonus?</h3>
                        <p style={{color:'#333'}}>A: No. You are not allowed to work over time. But you can work full time during the off sessions / semester holidays.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What if I can't find employment?</h3>
                        <p style={{color:'#333'}}>A: You can initially find a part time job till you find a suitable internship job into your profile.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Will I have to pay taxes?</h3>
                        <p style={{color:'#333'}}>A: Yes</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is there a way for me to work before actually starting to attend classes in order for me to save more money and add it to my current savings?</h3>
                        <p style={{color:'#333'}}>A: No</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Could the work authorization be possibly converted into a full time work permit upon completion of the masters program?</h3>
                        <p style={{color:'#333'}}>A: Yes, after the study program you can get post study work visa for 1 yrs duration.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much is the bank statement required for applying VISA?</h3>
                        <p style={{color:'#333'}}>A: Bank statement of minimum Rs. 100,000 sufficient funds to cover expenses of individual 

tmc not required for Bank statement as the student will be paying the whole fees before they fly.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long I have to keep amount in my bank account for VISA?</h3>
                        <p style={{color:'#333'}}>A: Minimum 28 days Old bank statement is required </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much tuition fee I have to pay before and after getting VISA/Offer letter?</h3>
                        <p style={{color:'#333'}}>A: You will have to pay the first term fees before visa interview </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can one apply for Dependent VISA?</h3>
                        <p style={{color:'#333'}}>A: Yes, Preferably Dependents are allowed to fly only after 3 months.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How can apply as a dependent VISA?</h3>
                        <p style={{color:'#333'}}>A: You will require showing additional fund in the bank which supports their living expenses.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is required in order to bring my spouse and family?</h3>
                        <p style={{color:'#333'}}>A: You will need to produce the documents needed to show your spouse’s or civil partner's status and evidence of your marital or civil partner status (for example, birth or naturalization certificate of Irish spouse, marriage or civil registration certificate).and also you require additional funds to support living Expenses.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my spouse get the wok permit there?</h3>
                        <p style={{color:'#333'}}>A: Yes</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much duration work permit will spouse get there?</h3>
                        <p style={{color:'#333'}}>A: Your spouse can work till the duration of the study program of the student.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I apply work permit after completing my studies?</h3>
                        <p style={{color:'#333'}}>A: Yes, student who has completed his Bachelors or Masters program are entitled for 2 yrs work permit after the study program</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much time will I get after my studies to search job there?</h3>
                        <p style={{color:'#333'}}>A: The time to search job is included in 2yrs work permit</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my spouse extend the work permit?</h3>
                        <p style={{color:'#333'}}>A: No.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my accompanying dependent attend school?</h3>
                        <p style={{color:'#333'}}>A: No.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is IELTS required for dependent?</h3>
                        <p style={{color:'#333'}}>A: No.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Will University secure job for me?</h3>
                        <p style={{color:'#333'}}>A: No</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Why Should I study in xyz Company?</h3>
                        <p style={{color:'#333'}}>A: •		Low Tuition Fees.
•	State funded universities.
•	Paid Part time internship jobs.
•	Assured internships for eligible students
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What do I require to pursue my education in Malaysia?</h3>
                        <p style={{color:'#333'}}>A: Along with academic qualification which may vary from university to university, you are required to have a student pass to pursue your education in Malaysia.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How can I apply for a student pass?</h3>
                        <p style={{color:'#333'}}>A: You can only apply for a student pass after receiving an offer from an institution or a university. The institution/university, then will submit student pass application to Education Malaysia Global Services (owned subsidiary of the Ministry of Education of Malaysia) on your behalf.
 
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I apply for a student pass directly?</h3>
                        <p style={{color:'#333'}}>A: No, you can not apply directly for a student pass.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I require to pass the IELTS/TOEFL examination to get admission in Malaysian educational institution?</h3>
                        <p style={{color:'#333'}}>A: The IELTS/TOEFL requirement varies from institution to institution and courses to courses. Many foreign universities which have their campuses in Malaysia may ask for English proficiency.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I work in Malaysia during the course of my study?</h3>
                        <p style={{color:'#333'}}>A: As an international student you will be allowed to work part-time for a maximum of 20 hours per week only during semester breaks or holidays of more than 7 days at restaurants, petrol kiosks, mini markets and hotels. You may not be able to work as cashiers, singers, masseurs, musician or GROs.
 
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are the documents required while applying for a visa to Malaysia?</h3>
                        <p style={{color:'#333'}}>A: •	An offer letter or letter of acceptance from the educational institution.
•	Student pass application form (IMM 14) in duplicate.
•	Two photocopies of the student’s passport/travel document.
•	Two passport sized photographs of the student.
•	The educational institution is required to sign a ‘Personal Bond’ on behalf of the students. No fee is charged for the Personal Bond.
•	The Malaysian Immigration Department charges all the students a fee of RM60 per year for student passes.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much would I be spending during my stay in Malaysia?</h3>
                        <p style={{color:'#333'}}>A: Malaysia country not only offers affordable and quality education but also provides the opportunity to achieve degree from foreign universities through their branch campuses. Cost will come 10,000 USD per year

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Why do I have to go under screening?</h3>
                        <p style={{color:'#333'}}>A: Medical screening is mandatory requirement which every international student has to undergo. The Ministry of Higher Education (MOHE) has laid down criteria for the assessment of the health condition of international students which must be compiled with.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I study in Malaysia on a tourist visa?</h3>
                        <p style={{color:'#333'}}>A: Yes, you can pursue a part-time course for short periods on a social visa.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Does every international student need a student pass to study in Malaysia?</h3>
                        <p style={{color:'#333'}}>A: Yes, this is a mandatory requirement under the laws and regulations of the Government of Malaysia.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I apply for a student pass directly or do I have to apply through my chosen educational institution?</h3>
                        <p style={{color:'#333'}}>A: Depending on your Institution type, you will have two options to do this:
•	i. Apply for your Visa online.


Students who have received offer letters from Public Universities and Private Higher Education Institutions (IPTS) only will have the option to apply for their student Visa directly through the Education Malaysia website.


•	ii. Apply for your visa through your Institution.


The application can also be done through the institution. Once you submit the payment and all required documents to the institution, they will begin the process of applying for your Visa Approval Letter (VAL).


Student applying for language centres and skill centres will have to submit application via the institution.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are the entry requirements to study at institutes of higher education in Malaysia?</h3>
                        <p style={{color:'#333'}}>A: International students must have at least twelve (12) years of education and have undergone a national/international examination recognized by the Ministry of Higher Education for the purpose of admission to diploma or degree programmes.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I need to pass the IELTS or TOEFL examinations in order to obtain admission to a degree programme in Malaysia?</h3>
                        <p style={{color:'#333'}}>A: This requirement varies, depending on the course(s)/programmes(s) that you are interested in. Please clarify the course/programme specific entry requirements with the educational institution directly.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: When should I apply for my student pass?</h3>
                        <p style={{color:'#333'}}>A: Generally, education institutions have different intake dates for various courses/programmes. Kindly refer to your institution for information pertaining to intake dates, recommended application submission dates, fees as well as document requirements.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I open a bank account?</h3>
                        <p style={{color:'#333'}}>A: Yes, once you get your student pass you can open a bank account.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is it advisable to take hostel accommodation or private accommodation?</h3>
                        <p style={{color:'#333'}}>A: This solely depends upon your personal choices, although most Malaysian educational institutions offer quality accommodation services at affordable rates.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: If my student pass application is rejected, can I get a refund for the application processing fee?</h3>
                        <p style={{color:'#333'}}>A: No, the student pass application processing fee is non-refundable.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q:  Can I work while on a student pass?</h3>
                        <p style={{color:'#333'}}>A: All international students studying at public or private higher education institutions are allowed to work part-time for a maximum of 20 hours per week ONLY during semester breaks or holidays of more than 7 days at restaurants, petrol kiosks, mini markets and hotels as long as their student passes remain valid. Please note that prior approval from the Department of Immigration is required.


International students are NOT permitted to work as cashiers. In addition, in the hotel sector, international students are NOT allowed to work as singers, masseurs, musicians or GROs (Guest Relation Officers). International students are not allowed to engage in any job or activity deemed to be immoral.


Applications to work part time must be made through the educational institution at which the international students are enrolled. The international student needs to be present with the representative of the education institution at the Immigration Department of Malaysia in order to submit an application for part time work.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I pursue two degrees at the same time?</h3>
                        <p style={{color:'#333'}}>A: No, you are only allowed to study one full-time degree at any one time.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do you offer any scholarships/Can I get a scholarship?</h3>
                        <p style={{color:'#333'}}>A: You will need to check with the college/university that has accepted you as a student on this matter. The Malaysian government also offers scholarship opportunities to talented students. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are the living expenses for international students?</h3>
                        <p style={{color:'#333'}}>A: Cost of living in Malaysia will depend on your location and lifestyle choices.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: I have lost my iKad, what must I do?</h3>
                        <p style={{color:'#333'}}>A: Please submit a police report immediately. You will then be required to submit a copy of the police report to your institution and inform them to apply for a new iKad. Please note that there will be a fee charged for the issuance of a new iKad.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How can I check whether my passport was submitted to EMGS or collected by the institution?</h3>
                        <p style={{color:'#333'}}>A: You can track your application status on our website to check whether EMGS has received your passport. You can also download the EMGS mobile app to your mobile phone in order to get automatic notifications.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I need an entry visa to Malaysia?</h3>
                        <p style={{color:'#333'}}>A: A visa is required to gain entry into Malaysia. Some countries are exempted from this requirement. Please check with the nearest Malaysian Embassy/Mission.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long will it take me to get a Visa Approval Letter (VAL)?</h3>
                        <p style={{color:'#333'}}>A: It should take 14 working days upon receipt of a complete application and payment. Since the Visa Approval Letter (VAL) is issued by the Immigration Department, the 14 working days is at the discretion of the Immigration Department.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How does one get an entry visa into Malaysia?</h3>
                        <p style={{color:'#333'}}>A: To obtain an entry visa, you are required to visit the nearest Malaysian Embassy in your home country along with
•	your offer letter from the Malaysian education institution
•	the Visa Approval Letter issued by the Immigration Department of Malaysia
•	all other relevant documents and forms duly completed
•	payment as outlined by the Malaysian Embassy for the application of an entry visa.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are the documents required to apply for a student pass?</h3>
                        <p style={{color:'#333'}}>A: You will need to liaise directly with your university for the submission of a complete set of documents. Should you decide to make an online application on the website, you will be able to upload your
•	Offer Letter
•	Passport Pages
•	Passport photo
•	Academic Transcripts
•	Medical Examination Report
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: When and how can I pay for my student pass application and processing fee?</h3>
                        <p style={{color:'#333'}}>A: There are 2 payment options
•	Through your education institution: Contact your institution to advice on the correct way to send the payment to them in order for the payment to be made to EMGS.
•	Online payment using a Visa or MasterCard on the educationmalaysia.gov.my website. Please note that if you select this payment method, there will be a 3% Surcharge added to the total amount requested for your application. Online payment method is only applicable for students enrolling in Public or Private Universities and have made an online application via EMGS website.


Please make sure that if you are applying online, you obtain advice from your institution about the correct selection for the following items;
•	Type of Insurance
•	iKad delivery method
•	EMGS Endorsement or Self Endorsement
•	VAL delivery method
•	Institution Name and Campus
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is a student pass?</h3>
                        <p style={{color:'#333'}}>A: A student pass is a multiple entry visa which gives you permission to remain in Malaysia for the purposes of study at an approved institution on an approved course.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the validity of a student pass?</h3>
                        <p style={{color:'#333'}}>A: For students who are making new applications to Public &amp; Private Higher Education institutions -, students have the option to apply for a Student Pass that covers the entire duration of their course (subject to sufficient passport validity). For Renewal applications, the student pass will be issued on an annual basis. When making an application, it is recommended that the passport validity should be [duration sought + 12 months].


For students enrolling in Language Schools and Skills Training Centres, the Department of Immigration issues Student Passes for a maximum of 12 months or, if the course duration is less than 12 months the Pass will be for the duration of the course.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is a Multiple Entry Visa (MEV)?</h3>
                        <p style={{color:'#333'}}>A: A MEV is issued to allow you to make any number of journeys / entries into Malaysia.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I need to submit a security bond deposit in order to obtain a student pass?</h3>
                        <p style={{color:'#333'}}>A: Yes, you will be required to submit a security bond deposit known as a “personal bond”. Please note that this amount is set by the Government and varies depending on your country of origin. This fee is paid to your educational institution as a form of security. EMGS does not collect this amount, however, we do require a form from your institution showing that the amount has been paid to them. Please contact your college/university for further details.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long does it take to get an entry visa from the Malaysian Embassy?</h3>
                        <p style={{color:'#333'}}>A: This depends upon the Malaysian Embassy in your home country. However, it typically does not take more than 3 working days.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: When do I get a student pass /sticker endorsed into my passport?</h3>
                        <p style={{color:'#333'}}>A: The education institution shall receive you at the airport upon your arrival. You will be required to attend post arrival medical screening within 7 days. The education institution will accept your passport for the purposes of arranging for the student pass/sticker endorsement. From the day your passport is submitted at our front desk, the process should take 7 working days. Please note that this timeline is at the discretion of the Immigration Department.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What happens if I overstay?</h3>
                        <p style={{color:'#333'}}>A: You are required to communicate with your institution to clear your overstay at the Malaysian Immigration Department. Penalty fees may apply, and these fees are at the discretion of the Immigration Department. Subsequently, you will need to apply for a special pass to ensure that you have a valid pass throughout your stay in Malaysia.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: The website tracker shows me that EMGS requires me to submit my passport. How do I submit the passport? Should I courier it to you?</h3>
                        <p style={{color:'#333'}}>A: You are only required to submit your passport once you are in Malaysia. EMGS will only accept passport submissions from education institutions. Once you arrive in Malaysia, please submit your passport to your institution. Kindly ensure that you have a valid pass that allows you to remain in Malaysia while we process the endorsement of your Student Pass.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: My institution said that they cannot create a new application for me as my previous application is still active.</h3>
                        <p style={{color:'#333'}}>A: Please communicate with your previous institution to request for a cancellation of your previous application.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: My course will end a few months after my student pass expires. Since I need to be in Malaysia for those last few months, do I need to apply for a student pass for the remaining period?</h3>
                        <p style={{color:'#333'}}>A: Yes, you are required to apply for a student pass. Students are only permitted to study in Malaysia if they are holding a valid student pass. You can request your institution to apply for an extension (renewal) of the student pass to cover the remaining period.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I need to exit the country if I want to change from employment pass/dependent pass/MM2H to student pass?</h3>
                        <p style={{color:'#333'}}>A: No, you are not required to exit the country; however you are required to shorten the pass and apply for a special pass.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: I am married to a Malaysia citizen, do I need a student pass?</h3>
                        <p style={{color:'#333'}}>A: Please check your pass and see if you are exempted from the student pass requirement. If not, you are required to apply for a student pass. Kindly contact the Immigration Department for further information.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: My Visa Approval Letter (VAL) has expired and I did not come to Malaysia. Now I want to come and study. What do I do?</h3>
                        <p style={{color:'#333'}}>A: The Immigration Department does not extend the validity of the VAL if it is expired. We can only extend the validity of the VAL if you apply for an extension BEFORE the previous VAL expires. As such you are required to apply for a new VAL prior to your entry into Malaysia. Kindly contact your institution to cancel the expired VAL and request EMGS to close the current application and submit a new application for VAL.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: I do not want to study anymore, how can I cancel my application?</h3>
                        <p style={{color:'#333'}}>A: We only accept cancelation requests from institutions. Please contact your institution to request further assistance.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I need to exit the country if I change from a Language Centre(IPS) to a Private Institute of Higher Education(IPTS)? My institute asked me to exit the country.</h3>
                        <p style={{color:'#333'}}>A: You are not required to exit the country as long as you hold a valid Special Pass.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I study on my social visit / tourist visa?</h3>
                        <p style={{color:'#333'}}>A: No, this is not permitted. International students are only permitted to study full time courses in Malaysia with a valid student pass issued by the Immigration Department.


If you enter Malaysia with a social pass, you will be asked to exit Malaysia and re-enter with a Visa Approval Letter.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I stay in Malaysia after performing my Pre-VAL Medical Screening in Malaysia?</h3>
                        <p style={{color:'#333'}}>A: It is not recommended for you to stay in Malaysia under a Social Pass while you are waiting for your Visa Approval Letter. This may cause delays in your Visa processing.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: I am on a social pass. Can I be given a special pass once my social pass expires during my VAL application?</h3>
                        <p style={{color:'#333'}}>A: It is not recommended for students to be in Malaysia on a Social Pass during the VAL application process. If your social pass expires while you are in Malaysia, EMGS cannot process a special pass for you. We can only process a Special Pass for students if their Student Pass expires.


If you are on a Social Pass and are planning to obtain a Student Pass, you will be asked to exit Malaysia once your Visa Approval Letter is issued. You can then re-enter Malaysia with your Visa Approval Letter and Single Entry Visa (if an SEV is required for your country).
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I bring my dependants with me?</h3>
                        <p style={{color:'#333'}}>A: You are allowed to bring your dependants only if you are studying full time in Malaysia on a course/programme that is more than twelve (12) months long. Approval of your dependant pass application is at the discretion of the Immigration Department of Malaysia.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Who can be my dependants?</h3>
                        <p style={{color:'#333'}}>A: Your parents, spouse and your children can be your dependants.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the application process for a dependant pass?</h3>
                        <p style={{color:'#333'}}>A: The dependant pass application should be made through your education institution. The student pass application will be made through EMGS and the dependant pass process can only commence once the Principal’s student pass has been issued. You need to submit a separate application for each dependant.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What would a dependant pass cost?</h3>
                        <p style={{color:'#333'}}>A: The processing fee for a dependant pass application is RM 250 (+6% Government Tax). The dependant pass fee is RM90 and the Multiple Entry Visa fee varies depending on the nationality of the applicant. A Journey Performed Fee of RM500 is also applicable.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I change my mind and transfer to another education institution?</h3>
                        <p style={{color:'#333'}}>A: If you wish to transfer to another education institution, you will need:
•	a release letter from the current college/university and
•	an offer letter from the new college/univers


These documents are required to support your application for a new student pass sponsored by the new college/university. Your current college/university will arrange for your existing pass to be shortened prior to the issuance of the release letter. You are allowed to change university or course only twice.


The student pass is not transferable to the new institution. This means that you have to apply for a new student pass.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is ‘Variation’?</h3>
                        <p style={{color:'#333'}}>A: Please refer to the institution for further information - adding or dropping classes does not always mean a change of course. If it does then the institution will refer to EMGS on your behalf.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: I am studying CAT (Chartered Accountant Technician) and I want to continue my studies on ACCA (Association of Chartered Certified Accountants): Do I need to apply for a new application?</h3>
                        <p style={{color:'#333'}}>A: Yes, you need to apply for a new student pass because this is considered to be a change of course.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How many times can a student change their institution or course?</h3>
                        <p style={{color:'#333'}}>A: A student is allowed to change a university or course twice. A 3rd application by the student would be rejected.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the process to change institute/course?</h3>
                        <p style={{color:'#333'}}>A: You are required to obtain an offer letter from the new institution/faculty; and a release letter from the previous institution/faculty. The new institution/faculty will then submit these and other application documents to EMGS.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I need to exit the country if I want to change my institute/course?</h3>
                        <p style={{color:'#333'}}>A: The requirement to leave the country depends on the type of the pass you are holding at the time of application, as well as the type of institution you are transferring to or from. For further clarification please contact us via email or call.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: I want to change to another institute, do I need to obtain a release letter?</h3>
                        <p style={{color:'#333'}}>A: A release letter is required for all international students applying to change institute.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: My old institute is closed, how do I submit a release letter?</h3>
                        <p style={{color:'#333'}}>A: Please refer to the Enforcement Division Department of Higher Education (Bahagian Penguatkuasaan Dan Inspektoran Jabatan Pengajian Tinggi) to obtain a supporting letter.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I choose to receive a Single Entry Visa from a Malaysian Embassy in a country where I am not a citizen?</h3>
                        <p style={{color:'#333'}}>A: Applicants are required to obtain a Single Entry Visa (SEV) from their home country. In the event that they plan to obtain the SEV from a country other than their home country, they need to provide a document to show that they hold a long term pass in that country.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: My special pass is expired and I have overstayed, what do I do now?</h3>
                        <p style={{color:'#333'}}>A: Please contact your institution and request that they apply for an overstay Special Pass for you.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: I am changing from institute A to B. Who has to apply for a Special Pass for me?</h3>
                        <p style={{color:'#333'}}>A: The new institution is required to apply for the Special Pass for you.

</p>
                    </div>
                                   
                </div>
                
            </div>

        </div>
    </div>

</div>

</section>



    <div className="advisor-details-area default-padding" style={{padding:0,}}>
   
   </div>



</div>
</div>
</div>
</div>

<Footer/>
    </Container>
  );
}
