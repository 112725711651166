/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment,} from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Header from './Header';
import Footer from './Footer';
import { postData, getData } from '../FetchServices';


import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';



const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
newbtn:{
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
},

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:12,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',   
  },

widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  },
  large:{
    width: theme.spacing(7),
    height: theme.spacing(7),
  }
},


}));

export default function LandingPage(props) {
  const classes = useStyles();
  const [firstName,setFirstName]=React.useState('')
  const [lastName,setLastName]=React.useState('')
  const [emailId, setEmailId]=React.useState('')
  const [password,setPassword]=React.useState('')
  const [verifyPassword,setVerifyPassword]=React.useState('')
  const [messages, setMessages ]=React.useState('')
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus,setStatus]=React.useState('') 
  const [countryName, setCountryName] = React.useState([])
  const [uniName, setUniName] = React.useState([])
  const [programName, setProgramName] = React.useState([])
  const [getProgramId,setProgramId] = React.useState();


  const handleFetchProgramId=async(event,Id)=>{
    // console.log("Event...............",e)
    console.log("Id...............>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",Id.id)
    // setcName(val.country) 

    setProgramId(Id.id)
    // console.log("or kuch Aagya...",res);
   }

   const handleCourse = (id) => {
    // alert("hello next Page");
    console.log('id>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>',id)
      props.history.push({
        pathname: "/DisplayCourses",
        state: {
          id: id,
         
        }
      });
    // console.log(event.currentTarget);
  };

  const handlePicture=(event)=>{
    setPicturePath(URL.createObjectURL(event.target.files[0]))
    setPicture(event.target.files[0])
   }

   const handleVerifyPassword=(e)=>{
    setVerifyPassword(e.target.value)
    if(password===e.target.value){
      setStatus("Password Matched")
    }
    else{
      setStatus("Password not matched")
    }
  }

  const handleFetchUniversity=async(event,Id)=>{
    // console.log("Event...............",e)
    console.log("val...............",Id.country)
    // setcName(val.country) 
    let body={
       cname:Id.country
     }
     let res = await postData('universities/fetchuniversity',body)
     setUniName(res)
    //  console.log("Result Aagya...",res);
   }

   const handleFetchProgram=async(event,Id)=>{
    // console.log("Event...............",e)
    // console.log("val...............",Id)
    // setcName(val.country) 
    let body={
       pname:Id.name
     }
     let res = await postData('abroadcourses/fetchprogram',body)
     setProgramName(res)
    // console.log("or kuch Aagya...",res);
   }

   const fetchAllCountryName=async()=>{
    var result=await getData("universities/DisplayCountry")
    console.log("=====>>>>>>",result)
    setCountryName(result)
  }

  React.useEffect(function(){
    fetchAllCountryName()
  },[])

  // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
  // const top100Films = [
  //   { title: 'Phd', year: 1994 },
  //   { title: 'MCA', year: 1972 },
  //   { title: 'MSC', year: 1974 },
  // ];
   
    console.log("History ==  ",props)
  return (
   <Container className={classes.widths}>
    
    <Header/> <CssBaseline />
    <div className="login-area default-padding" >
        <div className="">
            <div className="row" >
                <div className="col-md-12">

    <div className="about-area default-padding">
        <div className="container">
            <div className="row">
                <div className="about-items">

 <div className="col-md-3 features text-light">
                    <div className="sidebar-item category" style={{padding:15,}}>
      <div className="sidebar-info" >  
     <a color="primary" id="togglerU"  style={{color:'#000',fontWeight:'bold'}}  fullWidth>                        
       Inquiry Form <span style={{float:'right'}}><i class="fa fa-envelope"></i></span>
    </a> 
       
       </div>  
       <hr/>  
       <p style={{fontSize:12,color:'#212121',textAlign:'justify',lineHeight:'inherit'}}>
       Our education counselors and campus teams help you every step of the way, from application to graduation, at no additional cost to you.
    </p>  
<div className="blog-area">
<div className="info">
<div className="content" style={{paddingBottom:0,}} style={{padding:0,position:'static'}}>
<a href=" https://calendly.com/studymetro/students" style={{color:'#000',borderRadius:5,textAlign:'center'}}> Talk to an Advisor</a>
</div>
</div>
</div>  

</div>

  <div className="sidebar-item category" style={{padding:15,}}>
   <div className="sidebar-info" >  
     <a color="primary" id="togglerU"  style={{color:'#000',fontWeight:'bold'}}  fullWidth>                        
       Videos <span style={{float:'right'}}><i class="fa fa-video-camera"></i></span>
    </a> 
  </div>  
       <hr/>  
<p style={{fontSize:12,color:'#212121',textAlign:'justify',lineHeight:'inherit'}}>
 Our education counselors and campus teams help you every step of the way, from application to graduation, at no additional cost to you.
</p>  
<div className="">
    <a className="videoOverlapper" href="https://player.vimeo.com/video/149001780" data-featherlight="iframe">
        <i style={{position:'absolute',padding:90,fontSize:40,}} class="fa fa-play-circle"></i>
        <img src="https://www.studymetro.com/static/uploads/videos/150169731959821527c711a.jpg" />
            </a>
            </div>

</div>
<div className="sidebar-item category" style={{padding:15,}}>
   <div className="sidebar-info" >  
     <a color="primary" id="togglerU"  style={{color:'#000',fontWeight:'bold'}}  fullWidth>                        
       Event <span style={{float:'right'}}><i class="fa fa-flag"></i></span>
    </a> 
  </div>  
       <hr/>  

</div>

</div>

<div className="col-md-9 about-info">
<h2 style={{fontSize:26,marginTop:20,}}>Why do you need career counseling for higher education?</h2>
                      
<p style={{fontSize:14,color:'#212121',textAlign:'justify'}}>
Study Metro primary focus of our career counseling is to understand and explore the career requirement of students who are looking for higher studies. Thus providing professional assistance and helping students organize their thoughts and ideas about career choices and professional goals.
</p>
<p style={{fontSize:14,color:'#212121',textAlign:'justify'}}>
Study Metro provides updated information's dealing with world over admission criteria acceptance rates, admission committee expectations and merit scale. Each student is hand-held and constantly counseled throughout the entire process. We want you to study at the best school you wish for and your aspiration is our promise.
</p>
<p style={{fontSize:14,color:'#212121',textAlign:'justify'}}>
Our Education Expert, provide students a meaningful global exposure and a platform for showcasing their knowledge and skills in the field of education. So here is the opportunity for the students and working professionals where they can come to know all the details about their higher education. We help the student make right career choices. We also help the student plan his or her education.
</p>
<p style={{fontSize:14,color:'#212121',textAlign:'justify'}}>
In addition, we help the student set career goals and plan a career path that has a higher predictability of career success. As we are aware everyone needs sincere and competent advice for their career options they have. This becomes even more critical in case of students and working professionals looking for higher studies abroad.
</p>
<p style={{fontSize:14,color:'#212121',textAlign:'justify'}}>
This would be a generalized and personalized counseling on one to one basis which will help the students to build the platform to start their career. By attending our counseling session students and working employees can have all their queries answered by the industry experts as the guidance would be given as per your financial, personal and academic requirements.
</p>
<p style={{fontSize:14,color:'#212121',textAlign:'justify'}}>
Hence to accomplish our mission, we would like to draw your support by getting into an understanding for the precious guidance for the right students for the right course at the right time through counseling and in all our future endeavors.   
</p>

                       
                    </div>
                </div>
            </div>

            <div className="row">

{/* 1 */}
                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'540px'}}>
                    <div class="row g-0">
                      <div class="col-md-4">
                        <img src="assets/img/counsellors/Abhinandan-Dadhich.jpeg" alt="Abhinandan Dadhich"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Abhinandan Dadhich</h5>
                          <p class="card-text text-justify">Hi, this is Abhinandan , It's a great experience to be an abroad Edu. 
                          Counselor in Study metro. As serving in this industry for two year I have deal with so many 
                          students and guided them for their bright future depending on their profile and desired destination.
                           Especially for European continent, Canada and USA.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
{/* 2 */}
<div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'540px'}}>
                    <div class="row g-0">
                      <div class="col-md-4">
                        <img src="assets/img/counsellors/Suruchi-Kalode.jpg" alt="Suruchi Kalode"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Suruchi Kalode</h5>
                          <p class="card-text text-justify">Hii, this is Suruchi Kalode working at the Nagpur office of Study metro.
                           I have experience of 1 year and mainly deals with students counseling and Digital promotions. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                
{/* 3 */}
                <div className="col-md-12">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                    <div class="row g-0">
                      <div class="col-md-4">
                        <img src="assets/img/counsellors/Praveen-Chouksey.jpg" alt="Praveen Chouksey"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Praveen Chouksey</h5>
                          <p class="card-text text-justify">I am Praveen Chouksey and I am a Senior Education 
                          for the Europe Continent with the demonstrated history of working in the Overseas
                           Education Management Industry. I am assisting  the agents and students to get admission
                            in any country in Europe and Asia. I Liaison between student and university for MBBS and any 
                            medical related program in all the countries  since very beginning of the company . In particular, 
                            I assess the profile, shortlist the countries which match the profile and fit in budget, 
                            VISA applications for all countries, Preparing students for their VISA interview ,post VISA approval 
                            assistance as well. ALong with it, I assist for getting fully funded scholarship in Italy and Germany 
                            with high rate of VISA acceptance.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
{/* 4 */}
                <div className="col-md-12">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                    <div class="row g-0">
                      <div class="col-md-4">
                        <img src="assets/img/counsellors/Sagar-Agrawal.jpg" alt="Sagar Agrawal"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Sagar Agrawal</h5>
                          <p class="card-text text-justify">I am Sagar Agrawal. I am working as an Education & Placement Consultant
                           from Last 8 Years in Pune. It’s a Pleasure to be Associated with the Best in the Industry “ STUDYMETRO ” 
                           for Foreign Education.  At Studymetro I am having a great experience in Interaction and Counseling Students,
                            helping them with Getting Enrolled in their Dream Universities at their Desired Destinations around the World. 
                          </p>
                          <p class="card-text">I welcome you all at Study Metro Aundh, Pune Office for any kind of Help, Guidance, Counseling 
                          ,Suggestions you need regarding Studies Abroad in any Countries like USA , UK , Canada, Australia, Germany, New-Zealand Etc. 
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
{/* 5 */}

<div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'540px'}}>
                    <div class="row g-0">
                      <div class="col-md-4">
                        <img src="assets/img/counsellors/Yogita-Chopra.jpg" alt="Yogita Chopra"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Yogita Chopra</h5>
                          <p class="card-text text-justify">Hello, I am Yogita Chopra and I am an Overseas Education Counselor with the experience of 4.5 years. 
                          I  Mainly look after USA profiles.  Apart from that, I take care of profiles of all countries. I assist with profile 
                          assistance, option shortlisting, followup with universities, VISA interview preparation. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                
{/* 6 */}
<div className="col-md-6">
                  <div class="card mb-3" style={{maxWidth:'540px'}}>
                    <div class="row g-0">
                      <div class="col-md-4">
                        <img src="assets/img/counsellors/Neha-Khatri.jpg" alt="Neha Khatri"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Neha Khatri</h5>
                          <p class="card-text text-justify">An experienced Overseas  Education Counselor with over  
                          4 years of experience. I have contributed to various Student profiles for Canada and Dubai 
                          with VISA success. In particular, I have helped students/ Agents from profile building, profile 
                          assessment, option shortlisting, application process, GIC creation, SOP assistance,
                           Document checklisting for VISA  and VISA filing. During the COVID </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>   
{/* 7 */}
              <div className="col-md-12">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="assets/img/counsellors/Sonal-Upmanyu.jpg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Sonal Upmanyu</h5>
                          <p class="card-text text-justify">Hi, I am Sonal Upmanyu, Senior Overseas Counselor and Branch Manager, Study Metro Indore - Head Office. 
                          Being at Study Metro Since 8 years, I have been taking care of Canada Applications, Training sessions for IELTS,PTE,GRE,GMAT, 
                          SAT and Duolingo etc, Training to counselors from staff, franchise offices and agents. In extent, I help with the profile assessments,
                           content writing assistance, helping students for GIC, CAQ, WES and Visa applications after accepting the offer letters. For agents
                            and university representatives, I arrange sessions and training for counselors, education fairs and many events where we provide 
                            opportunities to student to know about the institutions. I also promote institutions among agent network about the availability of
                             the programs and updates as per IRCC regulations.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 

                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/f537cb07cfe677a1a38fb1d058c6c01d6c4699a91b7a822f3788dd373151afe0/_addressing_a_seminar.jpg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Ajitesh Sarangi</h5>
                          <p class="card-text text-justify">I am good in counselling students for medical and technical education in India and abroad.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 



                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/e770625fb67cd2829d268ec0a211e3a0e353769c12495fb6d15c6d6e24463eee/sush.jpg.jfif" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Sushant</h5>
                          <p class="card-text text-justify">I am interested in study abroad freelancer or as a partner and wants to gain exp and like to jion you.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 




                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/71bcc53603f86a1b31626b56d8946874f9f196458b90337128cde6ba8c5ae5fe/Image.jpg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Amrita Kandhari</h5>
                          <p class="card-text text-justify">I am interested in student recruitment an dhence i attended the webinars by Study Metro so that i can ultimately work with Study metro</p>
                              </div>
                      </div>
                    </div>
                  </div>
                </div> 



                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/bd9d1f5bee13103060225b256a09992b0a23ad7aac873091ad4922a83fc5d7eb/IMG_20210510_WA0005.jpg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">MANORANJAN BHAGHLAANIA</h5>
                          <p class="card-text text-justify">No I haven't , but i have assisted two of my friends .</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 

                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/b48aed9efd03c400b2618dd0652dc01cfae49ff60b78d246021c675bfb11801c/Arish_Pic.jpg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Arish Rehman</h5>
                          <p class="card-text text-justify">Managing study abroad quires</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                 
                
                
                <div className="col-md-12">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        
                        <img src="assets/img/counsellors/avatar2.jpg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                        {/* <Avatar alt="Sonal Upmanyu" src="assets/img/counsellors/noimagecouncellor.png" width='100%' height="400px"  /> */}
                
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Mehjabeen Mujawar</h5>
                          <p class="card-text text-justify">Evaluation of profile understand the student’s profile and then provide them with the best program possibilities and other career suitable for them.
Helping the students find the best university having worldwide recognition and help a student take a final decision about the university.  
Helping students in filling and in applying for a visa.
Guide students to assemble each document as per the checklist
provide detailed information regarding the visa process 
Counseling and explaining applicant about the format of the documents to be submitted to the visa authorities.
Solving queries related to Visa process on call
Also filing applications for other visa categories like visit visa, business visa.
Calling clients to sign the agreement after registration
Sending checklist of documents to registered clients.
Counseling and explaining clients about the format of the documents to be submitted to the visa authorities.
Collecting all the required documents from the client in given format.
Building clients resume as per NOC and ANZSCO codes for Canada and Australia filing respectively.
Getting resume and job duties approved from the Head Office.
Providing checklist of the documents and related forms as per the PR visa requirement for Australia and Canada
To ensure the client avoids any legal issues that may occur in relation to their relocation by assisting them to obtain visas and other related immigration documentation..
Assisting applicants during the process of applying by providing information on visa procedures
Collecting background information on applicants including information based on knowledge of the particular country.
Responsibility in handling confidential / sensitive applicant information and documentation.
Verified required information provided with visa applicant.
To note any discrepancies or omissions on visa applications.
To inform applicants of any issues with their visa applications.
Responding to in-person and phone inquiries.
Handling questions and provided additional information as requested
Processing visa applications and notified applicants when decisions were made.
Responding to enquiries and queries concerning immigration and visa matters.
Collecting documents & filled up forms from clients.
Maintaining the data manually and in the system.
Retaining Customer Satisfaction by giving assuring them about confidential things like documents.
Filing visa applications online on behalf of clients for Australia & Canada</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/791f56112a0abe7c0c965212d15be144c290df1434076ab571e5ac31c99ad95c/WIN_20200428_10_28_08_Pro.jpg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">NIKHIL BANSAL</h5>
                          <p class="card-text text-justify">We work as a Training company across various campuses in North Inda</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                

                <div className="col-md-12">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/a8282455c8f7d761398edd4fa183c996c16376d0430b5c11b46ea7f5a20d812f/SK_Ola_Pic.jpg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Surendra Kumar Ola</h5>
                          <p class="card-text text-justify">I’m writing about the freelancer overseas educational consultant position. As a brief overview 
I have a graduate degree with English Lit, History, Pub. Adm. & History, M.A. in History and B.Ed.
( A teaching degree). As an educator I have sixteen years of teaching experience. I have taught 
thousands of students from class 10th to 12th in some schools. I have worked as school principal,
as an educational consultant, as a trainer, as an operation manager, as a voice over artist, as a 
teachers training coordinator, as an actor in regional movies. I have my own channel on youtube.
I possess expertise in education, along with excellent management skill. I attended 3 workshops 
of PTE Academic to be a trainer. Due to Covid Pandemic I can’t take classes. So I want to start 
work from home as a freelancer overseas education consultant for your organization. 
Kindly pay attention on my application. I hope you may think positively in my favour.
Sincerely 
Surendra Kumar Ola</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/7e452bff6c8cd58c6a8d2dc4754315cbfcecfe89159cac899a6a087299e1ec72/pic.jpg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Tarandeep Singh Pannu</h5>
                          <p class="card-text text-justify">I have relevant qualification and experience, I have done MBA in International Business from an American University having campus in the UK. So being an international student my self I am counseling and guiding students aspiring to study abroad from past 5 years as a freelancer.
Although I was working in tourism company in Dubai from past two years I am providing consultation for Canada Immigration to clients mainly from India, Pakistan, Bangladesh and Sri Lanka.
Due to current pandemic situation worldwide tourism industry has been badly affected. So in lieu to the current situation I would like to work full time as a freelancer in study overseas.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/df91e06dcaebf4d50540b9ce65aaf74c31b37869c736876b6e8c31f6a31e01d5/472812.jpg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Sherif Alphonse</h5>
                          <p class="card-text text-justify">Business Development</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                

                <div className="col-md-12">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/09c28d7ac44fced91575d2628ba13252dbea836f7d819fdc4df64045cf9f67c3/103.jpg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Brajendra Rawat</h5>
                          <p class="card-text text-justify">In past, I have worked in, Technology, strategic Planning and business research roles utilising my data analysis skills. I have worked as part of various multicultural teams spread across the globe, which provides me an ability to lead a multicultural team in a better way. 
Currently I am working as a project manager for Global enterprises, where I am leading a project for the launch of an ecommerce platform for skincare products. In this role, I am responsible for charting a digital marketing plan, developing the platform and vendor management for the business.  
In past I have also worked as a project consultant for various UK based companies such as Arena Instrumentation and Lloyds Banking Group, where I worked on a project for identifying areas for revenue growth. I have also worked as a sales manager for a telecom company, achieving targets given. I have been fortunate enough to secure funding for my start-up and I ran my business alongside my regular job for a period of four years, achieving success and targets I had sat for myself and earning entrepreneurial experience. 
In addition to Project Management, I have been involved in the foreign university admission since 2012 and have helped various students get admission into various UK universities. Since I was based in the UK.
I believe that my skills, experiences and interpersonal skills would help me establish a mutually beneficial partnership with the Study Metro. 
Thank you for your time and consideration.
Sincerely
Brajendra Rawat</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-md-12">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/db79492df3a4c9b24381fbcb0173a0f1af7c6e3564b044e9389355f519e91b25/Siddharth_Photo.JPG" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Siddharth Shrihari Kulkarni</h5>
                          <p class="card-text text-justify">My Name is Siddharth, i am from Nagpur. With reference to my education and experience in counseling students for study abroad, I would like to begin with my education. I have successfully completed my Masters in HR/ International Hospitality from Leeds Beckett University, LEEDS,UK.   (https://www.leedsbeckett.ac.uk/). SInce 2016, I have been guiding students in personal reference in study abroad. I have also started IELTS Coaching in Nagpur in this lockdown. So far I have been successfully sent 4 students to the UK and 2 students to Germany. Since I have graduated from the UK it gives me an extra edge to talk, explain things about the UK.
I am also coming up with a module about How to be Successful in Study abroad and live, navigate through important tasks in the UK such as - Getting Job, preparing for your Job Interview, how to apply, how to travel, how to buy your food, how to find best place to stay etc.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/fae6724d57b8220c910ba35d7065b8312a78a5e84daaa662205410cd9386a14e/IMG_0107.JPG" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Sahil Bansal</h5>
                          <p class="card-text text-justify">Finding leads and making cold calls</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="assets/img/counsellors/avatar2.jpg" height="300px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Dr. Brijesh</h5>
                          <p class="card-text text-justify">I am a Social Media Influencer in Kerala with around 4.5lakh Subscribers in Youtube alone.I promote lot of study abroad options for students</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/fea64497e9529eb9aa977355308951abf2b9e13871e916ee75c9831c3b33ec8b/Screenshot_20210506_211722.png" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Akshit Mehra</h5>
                          <p class="card-text text-justify">Well , I am working in this field from last one year mainly U.S and canada . I can counsel student and help them making the right choice for there diploma or degree as per their stream of education and also I can give training to students for their interview wheather it is telephonic or physical</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="assets/img/counsellors/avatar2.jpg" height="300px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">G Sathish</h5>
                          <p class="card-text text-justify">Respected sir/ma'am
   As an experienced private school teacher and Soft Skills Trainer, I'm interested to work as freelencer or partner with Studymetro. While going through the website I was excited to find my qualifications and personal strengths align with your needs.
    As a Soft Skills Trainer I honed my abilities and provide a firm foundation.  
   I'm excited to contribute my talents towards achievement in enrolling students for Abroad Studies.
   Thank You.
Sincerely
G. Sathish</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/bf5193ec89fe3185d99358c41bf9a003a70e5815bb1f4e7680e06a8966f186e2/1539340498751.jpeg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Suchit Arora</h5>
                          <p class="card-text text-justify">Worked on recruiting students from Africa and other asian nations. Formed articulated programs with foreign universities. Plenty of university collaborations. Plenty of webinars among students. Hosted foreign universities Education fair, etc.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-md-6">
                <div class="card mb-3" >
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="assets/img/counsellors/avatar2.jpg" height="300px" width="150%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Prajakt sonawane</h5>
                          <p class="card-text text-justify">I used to make cold calls ,and convience students for the desire to study abroad . Moreover give them options too according to their profile .I used to coordinate with the agents to get them suitable colleges and make sure they get conditonal or unconditional offer letter as soon as possible .Check Lor and sop of.the students and given them feedback for.the same .To arrange loan for.the student if.they require any.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                 

                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="assets/img/counsellors/avatar2.jpg" height="300px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Navpreet Singh Gill</h5>
                          <p class="card-text text-justify">I prepare aspiring students for IELTS exam and during their preparation my students also seek advice from me regarding the Study Abroad options which I give them happily as per my knowledge and experience. Now I wish to convert them into potential clients for  Study Abroad Consultancy by having thorough knowledge about Study Abroad process and options. Thanks.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/7d2bd6b731b4eeb2e24c6338552203e21bb78bb5fce78def96320c2b5a30a8a6/passport_pic.jpeg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Rakesh Beerakayala</h5>
                          <p class="card-text text-justify">Primary responsibiity is to understand the student profile and then provide them with best possible programs and suitable career path , help the student select the best universities having worldwide reputation and offer excellent education, help student to take final decision on selection of university , facilitate students to fill application forms, prepare finanacial documents to apply for visa .Facilitation includes conveying correct set of shortlisted documents , help students to assemble all documents before hand ,take care of process with atmost care in every aspect of the students.Convey all information regarding educational loan and financial aid.Help students to write excellent and honest Statement of Purpose with awareness of course ,subjects and goal behind studying abroad and undestand the scope of study .Provide detailed information regarding visa process and prepare student for visa interview , update with latest immigration policies and visa checklist. Impart awareness on study abroad , travel , managing finance overall help smooth function of process</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

 


                <div className="col-md-12">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/451258097c52beab8e0af7f43ce7413a7912f6032b452aef38013ce5b91d7b6d/IMG_20200809_010654.jpg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Aditya Kumar Mahapatra</h5>
                          <p class="card-text text-justify">I have been part of immigration industry since 2014 and have worked with different countries and different positions with team handling at senior management positions in these entire duration. My core competencies doesn't limit only for study abroad counselling, I have strong expertise in sales as well as processing of PR applications to Aus, Can, NZ etc. skilled work visas to Germany, South africa etc. Study visa countries like Aus, Can, UK. In study abroad option i have closed the sales individually as well as as a team. For college coordination and visa application filing we had a separate team. Whereas I am aware of the process how it works.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                 

                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/d73727da0afb5d33dca6e56820cce0b3862b5f80036da6ccb39282b0888ba2ad/IMG20200828195805.jpg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Subhajit Ganguly</h5>
                          <p class="card-text text-justify">I have no experience in Study Abroad field. But recently I got certified as International Studies Career Counselor Program (ISCCP). Certificate issued by Bodhami (Member of IAAP and APCDA)</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/bd841b2aaa2526a4a665ef4595f99b61fb238f12d6fdfb2d1326ce13eebae623/IMG_20160214_174749.jpg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Fatema Yusuf Gandhi</h5>
                          <p class="card-text text-justify">Its my passion to guide students in the best course curriculum to brighten their future. So I am looking forward to send my students abroad  to build their career prospect</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                


                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/cd8ca08897826f2d95faa4b137ea36fe61c266f30cc20175b2de2aed8a895845/Milan_Visa_photojpg.jpg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Milan Debnath</h5>
                          <p class="card-text text-justify">I am working as a freelance Edu Advisor with Map my study. I do counselling of students, advising correct course in India and Abroad. I prepare the documents and submit the application to the institute. I also help the student to submit Student Visa. Apart from this, I help the student to get education loan.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                


                <div className="col-md-12">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="assets/img/counsellors/avatar2.jpg" height="300px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Deepak wadhwa</h5>
                          <p class="card-text text-justify">Actually it is demand of hour in Punjab, as millions are interested for that due to being presentable and having passion for that, I believe that with proper guidance I will be able to serve society at the best..moreover , I grasp things easily,to my perspective it is due to company's reputation and satisfaction of customers that attracts customers .being open as a open book not anything hidden to coustomers works.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="assets/img/counsellors/avatar2.jpg" height="300px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Pawan Kumar</h5>
                          <p class="card-text text-justify">I have no time to write an essay</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/23a60bedbd559df18f93ff8f9136d066764f3ee5fc1663833f30d438d208f73d/passport.jpg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Jitender Singh</h5>
                          <p class="card-text text-justify">Inform applicant about there profile, what the best we can do if applicantis having a weak profile, guide the applicant about relevant courses and university/college available according to his course also provide information on marriage cases if applicant to want to accompany his/her spouse according the evaluation on sucess ratio</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="assets/img/counsellors/avatar2.jpg" height="300px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Nisha</h5>
                          <p class="card-text text-justify">I have not worked in study abroad field. I have worked as an IELTS trainer in an institute for sometime.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-md-12">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/817ac1ad89e8a7e271bae32e950474836ce7ab7813f1ceecc9ae03ca5e4d717f/2021_05_05_13_35_Office_Lens__2_.jpg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Sirtaj Singh Sangha</h5>
                          <p class="card-text text-justify">I started this work in Jan 2020. I Started with IELTS and then in 1 month put my foot in Immigration field. Did tie ups with some consulting companies and started learning about this industry. My main focus is Canada only. I took cases with 6 bands each not less than 5.5 and starts filling. Took information from consulting companies and then councel the inquiries. My first case was totally filled by 1 consulting agent and got success in that. Then next tried myself, gaining more knowledge from YouTube, internet, college sites. Hired one person for SOP. But in between Lockdown happens...Now again trying to start the same but without investment.I have a basic knowledge of courses suggesting for different streams. How to search ranking of college, course, seeking preferences of students.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/bfe17f9c809ce42c0bd99bdbb1682f379b7265058a50dc3f1014e490d979bd79/20200623_082045.jpg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Dinesh Dayal</h5>
                          <p class="card-text text-justify">I m new for this industry and I have just started to learn for this industry but the name where I m looking for enrolled my name that is very professional and supportive especially if I talk about abhishek bajaj ji many a time I dailed home and he responses me with well mannered.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                
                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="assets/img/counsellors/avatar2.jpg" height="300px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Amith Kumar</h5>
                          <p class="card-text text-justify">Actually I do not have experience in Study abroad fields but having vast experience and exposure in Global Mobility compensation activity, Accounting and audit. But, want to learn more on this field if you give me opportunity</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-md-12">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/2b9621ddefa4ddb66513b31db03bc023fb67f73a95ec8f4afaab15f2a8973f3d/WhatsApp_Image_2021_05_01_at_12.00.15_AM.jpeg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">RANJEETA BHATIA</h5>
                          <p class="card-text text-justify">A teacher by profession I have worked towards embellishment of my profession, widening the concept of teacher not just helping school going students but helping students in fulfilling their dreams through seeking admissions in International Universities and pursuing their higher education abroad. I have helped prepare students for IELTS EXAM and have also worked as Counsellor guiding students for admissions in Universities in Australia.As a correspondent have interacted with Uni Rep seeking admission letters for the students. Keeping track of applications and coordinating with the other departments of Consultancy and Students. Irrespective of their schooling background I have always tried to help  students honing their communication skills so that they manage to achieve decent scores.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

            
 

                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/7b3fc07cefef9795a5bf621263571eb265461fa8003881318638fe8fec12ce06/Digital_Photo.jpg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">NISHANT BARTHWAL</h5>
                          <p class="card-text text-justify">I have a fair knowledge about visa documentation and countries. I have already traveled to more then 8 countries as well .  As I'm coming from sales background I know how to talk to guests and how to convince them . Being a sales professional I'm well aware of creating urgency and being true to your client as well .  . I'm a balanced human being , who loves to take responsibility and always push hard to finish the assigned task on or before time</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/9d07a6c417524d3e1b53b2923d719ba7e0d29e031d6f81115e92be202428e373/1_Shiva_latest_photo_11012021.jpeg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Shivashanker Pollishetti</h5>
                          <p class="card-text text-justify">I have Basic knowledge about Immigration as i worked in the same department in my previous company for 4 years</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/0f74e0c4c35d175c8f20fcd7684784c84de2586cfad9ee3fda3111356d6151e7/photo.jpg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Ashish Gupta</h5>
                          <p class="card-text text-justify">Started with Travel Immigrate Brand, where we have all the solutions for travel desk and immigration like study abroad, work visas.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                 

                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/419363041014ae1db8ae817c6b517e2ad6b6db3856697f26247ae703d84dd951/2.jpg" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Abhinav Purushottam</h5>
                          <p class="card-text text-justify">Not done any work related to study abroad</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                <div class="card mb-3" style={{maxWidth:'100%'}}>
                   <div class="row g-0">
                      <div class="col-md-4">
                        <img src="https://api.typeform.com/responses/files/1dfa435404e3f814d1c3e9c5a5e698150a260f9f31fce4d7f653831360caf621/userPhoto.png" height="400px" width="100%" alt="Sonal Upmanyu"/>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">ASHISH DHAR DIWAN</h5>
                          <p class="card-text text-justify">Till now I haven't worked at Study Abroad products, but as an education counselor I meet various leads while school and college visits which will allow me to explain about this product and get more leads to you.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



            </div>
            
        </div>
    </div>

</div>
</div>
</div>
</div>

<Footer/>

    </Container>
  );


}
