/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function SAT(props) {
  const classes = useStyles();

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 25,
                  marginTop: 30,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">SAT</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="blog-area single full-blog left-sidebar full-blog default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="container"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <div className="row">
                    <div className="blog-items" style={{ width: "100%" }}>
                      <div className="blog-content col-md-12">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      //marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      SAT examination was developed to assess
                                      the written, mathematical and verbal
                                      abilities of their candidates. Applicants
                                      hope to pursue undergraduate classes,
                                      especially in america and Canada, need to
                                      take the SAT examination. In the event the
                                      pupil is seeking to find admission to a
                                      specific class, s/he may take the SAT
                                      subject tests to reveal that his wisdom
                                      and comprehension of that specific
                                      subject.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      SAT examination was developed to assess
                                      the written, mathematical and verbal
                                      abilities of their candidates. Applicants
                                      hope to pursue undergraduate classes,
                                      especially in the united states and
                                      Canada, need to take the SAT examination.
                                      In the event the pupil is seeking to find
                                      admission to a specific class, s/he may
                                      take the SAT subject tests to reveal that
                                      his wisdom and comprehension of that
                                      specific subject.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      Many universities overseas are moving
                                      test-optional as SAT has been canceled.
                                      Exactly what it means for pupils and if
                                      this arrangement would be here to remain?
                                      Continue reading to get all of your
                                      questions answered.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      Lately, the College Board has launched SAT
                                      at India. Students seeking to pursue
                                      higher education in schools that are
                                      premier in India will be very happy to
                                      understand that in 2018, the College Board
                                      has brought in effect an India Higher
                                      Education Alliance with many different
                                      academic institutions. Each of the college
                                      affiliates from the Alliance are accepting
                                      SAT scores for entry from Indian pupils
                                      and about the globe. Thus, students who
                                      wish to study overseas and apply to
                                      universities may look for the SAT exam. To
                                      get a listing of Indian academic
                                      universities accepting SAT scores, then
                                      click on here.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Why is it that people take SAT?</h6>
                                      Students seeking to enroll at the
                                      undergraduate programs in the united
                                      states look because of their SAT. The
                                      Majority of the schools in the United
                                      States demand SAT for admission to the
                                      undergraduate classes.
                                      <br />
                                      SAT 1 is an overall test that's been
                                      created to assess the written,
                                      mathematical and verbal abilities of their
                                      applicants. Students seeking to get entry
                                      to a specific class need to take the SAT
                                      Subject Test to show their understanding
                                      of that specific subject. So, if, you're
                                      in a problem when considering SAT1 or
                                      SAT2, then see that which SAT 2020 test is
                                      likely for you.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>SAT vs ACT</h6>
                                      the majority of the schools in the united
                                      states accept either SAT or American
                                      College Testing (ACT) for admissions for
                                      their undergraduate applications,
                                      therefore, students interested in getting
                                      in these classes are needed to take these
                                      examinations. But, it's very important
                                      that you know which examination you should
                                      take. Primarily, check the need for the
                                      school you're applying to if they need the
                                      SAT or ACT, then choose which examination
                                      you need to go for. If you're fortunate
                                      enough to possess both the choices okay
                                      from your choice of faculty, you might
                                      find this article useful: SAT vs. ACT:
                                      Which Test is simpler?
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>SAT 2020 Eligibility</h6>
                                      The College Board, that's the running body
                                      of the SAT hasn't laid down any particular
                                      SAT eligibility criteria for pupils
                                      attempting to look for the SAT
                                      examination. Age Limit to Look for SAT
                                      Nominees should be educated that there's
                                      not any minimum or maximum age standards
                                      fixed for pupils wanting to look for the
                                      examination. General studies have proven
                                      that pupils belonging to this age group of
                                      17 to 19 are one of the highest to look
                                      for SAT.
                                      <br />
                                      How often can you take the SAT
                                      examinations? SAT is administered 5 times
                                      annually in India. SAT is administered 5
                                      times annually in India. There have been a
                                      few changes in the government dates of
                                      their SAT and SAT Subject Tests in 2020
                                      because of COVID-19.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        What's the educational eligibility
                                        standards for SAT?
                                      </h6>
                                      There are no particular eligibility
                                      criteria determined by the College Board,
                                      the body which conducts and oversees the
                                      SAT examination. But, it may be obtained
                                      by students that are at high school.
                                      Students who wish to apply for
                                      undergraduate studies overseas must have
                                      completed their high school education to
                                      proceed into the next level of their
                                      schooling.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        What abilities does the SAT Exam
                                        evaluation?
                                      </h6>
                                      SAT examination investigations
                                      Mathematical, Critical Reading and Writing
                                      skills of their candidates. According to
                                      the College Board, they examine the
                                      abilities that pupils have discovered in
                                      their own schools. They examine the
                                      abilities that are needed for the academic
                                      achievement of their students in faculty.
                                      <br />
                                      Because there are a limited number of
                                      chairs in each designated SAT Test Centre.
                                      Candidates are suggested to reserve their
                                      SAT test slot beforehand to prevent any
                                      unnecessary hassle.
                                      <br />
                                      Candidates must note that however early
                                      you cancel your SAT Test, you wouldn't be
                                      qualified for a complete refund.
                                      Candidates who have registered for your
                                      examination and are needed to cancel
                                      exactly the exact same will be qualified
                                      for a $10 refund in the event the
                                      candidate accomplishes the exam 5 times
                                      beforehand.
                                      <br />
                                      Nominees need to pay an additional charge
                                      of $30 for SAT Exam date shift. They must
                                      log in to their SAT accounts and set in a
                                      request to get a date change.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>SAT Admission Ticket</h6>when the
                                      candidate has registered for the
                                      examination, he'd be asked to log in to
                                      his account on the official site and
                                      download the SAT Admission Ticket. It's
                                      important that the candidate must assess
                                      the credentials to the entrance ticket and
                                      find any error exerted at the first.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>SAT Exam Charges</h6>Nominees
                                      appearing to look for their SAT need to
                                      pay a commission to the same. Candidates
                                      may have a look at the comprehensive SAT
                                      Fee enforced below.
                                      <br />
                                      So, the entire fee for the SAT examination
                                      with Essay is $68 + $49 = 117
                                      <br />
                                      For topic evaluations, candidates need to
                                      pay an extra $26 as a simple topic
                                      evaluation fee
                                      <br />
                                      For SAT subject tests, some extra fee may
                                      be demanded
                                      <br />
                                      SAT Cancellation Fees
                                      <br />
                                      Canceling your SAT Registration is a
                                      costly affair and pupils are constantly
                                      advised to go to get a date change instead
                                      of canceling the examination entirely.
                                      Candidates must bear in mind the
                                      Registrations can't be reimbursed later
                                      than five times prior to test day, and
                                      applicants are just refunded $10 if
                                      they've chosen for SAT or the SAT as well
                                      as Essay.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>SAT Rescheduling Charges</h6>Nominees
                                      that are wanting to modify their own SAT
                                      Test Centre or SAT Test Date or switching
                                      between the SAT and SAT Subject Tests or
                                      vice versa are expected to pay an
                                      additional $30 to avail services that are
                                      similar. Candidates may check out
                                      rescheduling charges below:
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        The way to Acquire fee-waiver to SAT?
                                      </h6>
                                      Pupils belonging to the 11th and 12th
                                      grades in the United States or US
                                      territories qualify for SAT Fee Waiver. US
                                      Citizens living outside the country may
                                      also obtain their fee waived off.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>SAT Scholarships</h6>Among the
                                      greatest drawbacks of studying overseas is
                                      having to cover tuition fees in foreign
                                      exchange money with higher living
                                      expenses. As a result of these reasons, a
                                      great deal of instances, many parts of
                                      pupils are unavailable to proceed overseas
                                      and wind up settling for different
                                      options. But, together with the
                                      availability of numerous scholarships and
                                      education loans for students may
                                      eventually create their dream come true.
                                      Scholarships are offered on the official
                                      site of every faculty alongside other
                                      fellowships and grants which are given to
                                      school students on a regular basis.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>SAT 2020 Exam Dates</h6>Nominees
                                      appearing to look for their SAT should
                                      enroll for the same. Candidates are
                                      advised to enroll for their SAT
                                      examination ahead of the SAT registration
                                      deadline to avoid any last-minute strain.
                                      The table below gives the evaluation dates
                                      to the SAT General Test in Addition to SAT
                                      Subject Test.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        That's the very best time to provide the
                                        SAT examination?
                                      </h6>
                                      The SAT Assessment is holding numerous
                                      occasions round-the-year. Students
                                      appearing to look because of their SAT are
                                      advised to opt for an examination date 3-4
                                      months prior to their application
                                      deadline, to prevent any last-minute
                                      mistake. Candidates may select from the
                                      many SAT Test Centres situated across
                                      major cities in India.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>SAT 2020 Syllabus</h6>SAT syllabus
                                      includes classes that the students have
                                      studied in their faculty through recent
                                      years. Therefore, if you're good at
                                      research at college, you should not have
                                      some problems preparing for the SAT
                                      Syllabus. The syllabus contains:
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Composing Section</h6>
                                      <i class="fa fa-check-circle"></i>&nbsp;
                                      SAT composing has three segments
                                      <br />
                                      <i class="fa fa-check-circle"></i>&nbsp;
                                      scoring segment - 25 mins
                                      <br />
                                      <i class="fa fa-check-circle"></i>&nbsp;
                                      Multiple choice segments - 25 mins and 10
                                      mins
                                      <br />
                                      <i class="fa fa-check-circle"></i>&nbsp;
                                      Critical Reading Part
                                      <br />
                                      <br />
                                      Additionally, there's an optional
                                      50-minute essay. The entire testing period
                                      after including the composition arrives to
                                      3 hours and 50 minutes.
                                      <br />
                                      Complete: 154 Queries (155 with
                                      composition )
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Total score: 1600</h6>
                                      You will find additional sub scores
                                      reported to a 20-80 scale. The scores of a
                                      student reflect how he is compared with
                                      all other pupils who've looked for the
                                      evaluation. See SAT Score Reporting to get
                                      a comprehensive comprehension of how
                                      scores are computed in SAT.
                                      <br />
                                      SAT Score Range: What is a Good SAT score
                                      for Faculties? Anything over 1200 is
                                      regarded as a great SAT score. The most
                                      everyone can score on the SAT is the best
                                      rating of 1600. As you get scaled scores,
                                      then you need to have some comprehension
                                      of SAT scaled scores and percentiles. To
                                      get a in depth comprehension of What a
                                      great SAT score is: see this.
                                      <br />
                                      There are just two means of sending the
                                      scores to schools, one while registering
                                      for the exam or later releasing the
                                      official scores. Candidates get four free
                                      score references while registering for the
                                      SAT test. Candidates may send the scores
                                      to schools around nine days of accepting
                                      the exam once they must pay $12 for
                                      sending the score reports to every
                                      university/program they select.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        Fee Waivers through SAT Score Reporting
                                        into Colleges
                                      </h6>
                                      It permits a pupil to send their SAT score
                                      into the schools of their choice to get
                                      free of charge. On the other hand, the
                                      qualification differs according to the
                                      newest changes. You may get in touch with
                                      the representative of the College Board or
                                      see about the standards on their site and
                                      use accordingly.
                                      <br />
                                      Click 'Send Categories'. Next thing would
                                      be to select'Send Accessible Scores Now'
                                      (for sending scores instantly ) or click
                                      'Send Scores When Accessible' (if scores
                                      are not yet been announced or you're yet
                                      to sit the examination ) Insert recipients
                                      by picking your chosen schools. You are
                                      able to search them by title and by
                                      condition. Be sure to double-check all of
                                      the receivers and individual details, then
                                      just pay the last number SAT rating
                                      Validity Period The SAT scores are valid
                                      within a span of five decades, unless or
                                      until there's been a shift in the
                                      standards for SAT. Candidates mostly don't
                                      utilize their SAT scores until five
                                      decades or so. They generally require new
                                      tests and they then apply to the desirable
                                      colleges. Students may also purchase
                                      another copy of the scorecards with the
                                      intention of sending it into more schools
                                      and may pay a sum of 9 (433.224 INR).
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>SAT Score Verification</h6>
                                      SAT score affirmation is only a
                                      revaluation of your test scores, to
                                      confirm you've received scores in most
                                      sections and nothing was skipped.
                                      Consequently, if you believe the SAT score
                                      which you received is different than what
                                      you anticipated, you might have missed or
                                      made an error in marking your replies.
                                      Another motive for different SAT score
                                      than anticipated is that your article is
                                      illegible or sterile when one sees it
                                      online. Under SAT score affirmation you've
                                      got three choices:
                                      <br />
                                      Multiple-choice hand score affirmation
                                      scoring score affirmation Or possibly,
                                      Multiple-choice and Statistics score
                                      you're able to set in this petition within
                                      the subsequent five months following the
                                      exam day by filling in a SAT score
                                      confirmation form. Before going further,
                                      it's recommended to read the directions
                                      carefully provided about the form. You'll
                                      also have to submit a dent confirmation
                                      fee. For pupils with a fee score, this
                                      score confirmation fee will be decreased.
                                      You'll get a letter with all the newest
                                      and verified results within five weeks, as
                                      soon as you cover the confirmation fee and
                                      submit your form. Whether there aren't any
                                      changes in the SAT scores article
                                      confirmation because of an irregularity in
                                      the grading or scanning procedure, the
                                      confirmation fee will be reimbursed.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Nations accepting SAT Score</h6>Every
                                      nation has its own selection criteria for
                                      global students. The SAT Assessment is
                                      approved in a variety of schools globally.
                                      It's popular for undergraduate classes in
                                      the united states and Canada. Even though
                                      it's also accepted by several universities
                                      in the united kingdom and Australia. Have
                                      a Look at the states accepting SAT Score.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Faculties accepting SAT Score</h6>
                                      As mentioned previously, the SAT
                                      Assessment is expected by the
                                      undergraduate aspirants to have registered
                                      in universities overseas. Have a look at
                                      the listing of top engineering schools
                                      abroad that take SAT Score.
                                      <br />
                                      They're also able to sustain the strain of
                                      answering inquiries in a shorter time
                                      period since the evaluation necessitates
                                      dealing with numerous sections. For the
                                      total prep, SAT is comparable to the other
                                      examinations in the sense that applicants
                                      may take the path of self-studying or
                                      attend training classes.
                                      <br />
                                      For self-study you will need inspiration
                                      and self-discipline, to keep with the
                                      regular. This method will surely help you
                                      to save a lot of money and time. But
                                      should you believe you learn the very best
                                      in a classroom environment, afterward
                                      attending training classes isn't a
                                      terrible thing. Bear in mind you could opt
                                      to study by yourself or via specialist
                                      assistance, there's no wrong or right way.
                                      <br />
                                      Candidates appearing to prepare
                                      section-wise can take a look at our
                                      writing prep suggestions, reading homework
                                      ideas and mathematics prep recommendations
                                      to have the ability to do their best
                                      during the time of this examination.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        Which are the top books for SAT Exam
                                        2020?
                                      </h6>
                                      Whether you choose to study by yourself or
                                      to join a training class, you'd always
                                      require a listing of the greatest books to
                                      get ready for the SAT Exam 2020. This
                                      collection ought to be in a position to
                                      assist you cover the whole syllabus
                                      together with a string of sample
                                      newspapers or clinic tests. Practicing on
                                      those questions will provide you a good
                                      notion of the total sense of this
                                      examination paper together with letting
                                      you find out how much you've attained.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>SAT Question Paper / Sample Paper</h6>
                                      Among the most convenient Procedures of
                                      preparing for SAT is via solving SAT
                                      Sample newspapers. Solving SAT Test Papers
                                      not merely allows the candidate to
                                      self-evaluate his operation but also
                                      prepare him to the examination day. For
                                      the sake of our subscribers, we've the
                                      hottest SAT Practice Papers for aspirants
                                      to resolve and perform their best to the
                                      day of their examination.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>SAT Test Centers</h6>The SAT is
                                      conducted in multiple Significant cities
                                      around India for the sake of pupils.
                                      Pupils are constantly advised to reserve a
                                      Sat Testing Centre that's nearest to their
                                      place and is convenient. The SAT test is
                                      held at more than 50 institutes and
                                      disperse throughout 30 big cities in
                                      India. To find the SAT test center that's
                                      nearest to you, see the whole collection
                                      of all SAT Test Centers, here.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>SAT Exam Day Tips</h6>
                                      Reaching your evaluation center beforehand
                                      is always a fantastic idea. It gives you
                                      the time to unwind and prepare for
                                      confronting the examination. Possessing
                                      time in spare helps you handle anxiety
                                      that makes anxiety before entering the
                                      examination hall.
                                      <br />
                                      Aspirants appearing to look for the
                                      examination could check out SAT Exam Day
                                      Tips for his or her advantage.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>Significant FAQs about SAT</h6>
                                      However, have more questions regarding SAT
                                      examination? Take a Look at the
                                      comprehensive SAT FAQs to your own
                                      benefit.
                                      <br />
                                      The validity of your SAT score will be for
                                      a period of five decades, from the
                                      afternoon of the announcement of your SAT
                                      Results.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        Q. Could I take the SAT examination
                                        after 12th?
                                      </h6>
                                      There are no particular age standards laid
                                      down by The College Board for looking to
                                      your SAT examination. But as SAT is for
                                      entrance to undergraduate applications
                                      applicants are advised to look to their
                                      SAT while at regular 11 or 12.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        Q. Is your SAT examination in the United
                                        States and Asia exactly the same?
                                      </h6>
                                      A. The SAT examination held in the United
                                      States and Asia are in English and include
                                      the Specific amount of queries. However,
                                      The College Board utilizes different
                                      variations of this exam within a moment.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        Q. Does Indian schools take entry
                                        through SAT scores?
                                      </h6>
                                      demo
                                    </p>
                                  </div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div className="col-md-12">
                                    <div
                                      className="content"
                                      style={{ paddingBottom: 0 }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area default-padding">
        <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="row" style={{ display: "block" }}>
            <div className="about-items">
              <div className="col-md-12">
                <div className="weekly-top-items">
                  <div className="top-courses" style={{ paddingRight: 0 }}>
                    <div className="heading" style={{ paddingBottom: 15 }}>
                      <h4 style={{ color: "#3f8ccb" }}>
                        ACCREDITATIONS AND MEMBERSHIPS <strong></strong>
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/EAIE.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/ICEF.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/NAFSA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/QISAN.jpeg"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIRC.gif"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIEA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
