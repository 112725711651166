/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from './Header';
import Footer from './Footer';


import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';



const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
newbtn:{
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
},

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:12,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },

widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function Blog(props) {
  const classes = useStyles();
  const [firstName,setFirstName]=React.useState('')
  const [lastName,setLastName]=React.useState('')
  const [emailId, setEmailId]=React.useState('')
  const [password,setPassword]=React.useState('')
  const [verifyPassword,setVerifyPassword]=React.useState('')
  const [messages, setMessages ]=React.useState('')
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus,setStatus]=React.useState('') 

  const handlePicture=(event)=>{
    setPicturePath(URL.createObjectURL(event.target.files[0]))
    setPicture(event.target.files[0])
   }

   const handleVerifyPassword=(e)=>{
    setVerifyPassword(e.target.value)
    if(password===e.target.value){
      setStatus("Password Matched")
    }
    else{
      setStatus("Password not matched")
    }
  }


//   const addNewRecord=async()=>{
// if(picture=='')
// {
//     var body={'firstName':firstName,
//     'lastName':lastName, 
//     'emailId':emailId, 
//     'password':password,
//     'status':"Not Approved",
//     'picture':"Not Found"}
//     var result=await postData('selfregister/addNewRecord',body)
//     if(result){
//       await setMessages("Agent Succefully Created")

//       var body={
//         'emailid':emailId,
//         'password':password
//        }
// var resultt = await  postData('selfregister/checkagentsignin',body)

// if (resultt.RESULT)
// {

// localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
// props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
// }
// else
// {
// alert("Failed To Created Account")
// }

// await setFirstName('')
// await setLastName('')
// await setEmailId('')
// await setPassword('')
// await setVerifyPassword('')
// await setPicturePath('')
// }
// else{setMessages("Faild to Submit")}

// }

// else{
//    var formData=new FormData()
//    formData.append('firstName',firstName)
//    formData.append('lastName',lastName)
//    formData.append('emailId',emailId)
//    formData.append('password',password)
//    formData.append('picture',picture)
//    formData.append('status', "Not Approve")
  
//    var config={headers:{'content-type':'multipart/form-data'}}  
//     var result=await postDataAndImage('selfregister/addNewRecordPic',formData,config)


//     if(result){
//       await setMessages("Agent Succefully Created")

//       var body={
//         'emailid':emailId,
//         'password':password
//        }
// var resultt = await  postData('selfregister/checkagentsignin',body)

// if (resultt.RESULT)
// {

// localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
// props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
// }
// else
// {
// alert("Failed To Created Account")
// }

// await setFirstName('')
// await setLastName('')
// await setEmailId('')
// await setPassword('')
// await setVerifyPassword('')
// await setPicturePath('')
// }
// else{setMessages("Faild to Submit")}
//     }
//     }
   
   
    console.log("History ==  ",props)
  return (
   <Container className={classes.widths}>
 <Header/> <CssBaseline />
    <div className="login-area default-padding" style={{paddingTop:20,}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">
<div className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard" style={{backgroundImage: "url(" + "../assets/img/banner/5.jpg" + ")",padding:70}}>             
        <div className="container">
            <div className="row">
                <div className="double-items col-md-12 info">
                <h2 className="wow fadeInLeft" style={{fontSize:'3rem',}}>STUDY METRO BLOG</h2>
                </div>
             </div>
        </div>
    </div>

    <div class="popular-courses-area weekly-top-items default-padding bottom-less">
        <div class="container">
            <div class="row">
                <div class="top-course-items">
              
                    <div class="col-md-4 col-sm-6 equal-height" style={{height: 576}}>
                        <div class="item">
                            <div class="thumb">
                                <img src="assets/img/courses/1.jpg" alt="Thumb" />
                                
                            </div>
                            <div class="info">
                               
                                <h6>
                                    <a href="/OpenBlog">Student Perspective: 5 Things I Learned Taking Online Courses</a>
                                </h6>
                                <div class="footer-meta">
                                    <h6 style={{marginTop:10,fontSize:12,}}>July 17, 2020</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 equal-height" style={{height: 576}}>
                        <div class="item">
                            <div class="thumb">
                                <img src="assets/img/courses/2.jpg" alt="Thumb" />
                                
                            </div>
                            <div class="info">
                               
                                <h6>
                                    <a href="/OpenBlog">What Has Reopened During Stage 2 in Ontario: A Guide For Students</a>
                                </h6>
                                <div class="footer-meta">
                                    <h6 style={{marginTop:10,fontSize:12,}}>July 10, 2020</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 equal-height" style={{height: 576}}>
                        <div class="item">
                            <div class="thumb">
                                <img src="assets/img/courses/3.jpg" alt="Thumb" />
                                
                            </div>
                            <div class="info">
                               
                                <h6>
                                    <a href="/OpenBlog">Top Questions About Studying in Canada During COVID-19</a>
                                </h6>
                                <div class="footer-meta">
                                    <h6 style={{marginTop:10,fontSize:12,}}>July 15, 2020</h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 col-sm-6 equal-height" style={{height: 576}}>
                        <div class="item">
                            <div class="thumb">
                                <img src="assets/img/courses/4.jpg" alt="Thumb" />
                                
                            </div>
                            <div class="info">
                               
                                <h6>
                                    <a href="/OpenBlog">Top Questions About Studying in Canada During COVID-19</a>
                                </h6>
                                <div class="footer-meta">
                                    <h6 style={{marginTop:10,fontSize:12,}}>Aug 5, 2020</h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 col-sm-6 equal-height" style={{height: 576}}>
                        <div class="item">
                            <div class="thumb">
                                <img src="assets/img/courses/5.jpg" alt="Thumb" />
                                
                            </div>
                            <div class="info">
                               
                                <h6>
                                    <a href="/OpenBlog">Top Questions About Studying in Canada During COVID-19</a>
                                </h6>
                                <div class="footer-meta">
                                    <h6 style={{marginTop:10,fontSize:12,}}>July 5, 2020</h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 col-sm-6 equal-height" style={{height: 576}}>
                        <div class="item">
                            <div class="thumb">
                                <img src="assets/img/courses/6.jpg" alt="Thumb" />
                                
                            </div>
                            <div class="info">
                               
                                <h6>
                                    <a href="/OpenBlog">Top Questions About Studying in Canada During COVID-19</a>
                                </h6>
                                <div class="footer-meta">
                                    <h6 style={{marginTop:10,fontSize:12,}}>Aug 15, 2020</h6>
                                </div>
                            </div>
                        </div>
                    </div>



                    
                 </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>
</div>

<Footer/>
    </Container>
  );
}
