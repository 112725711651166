/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [messages, setMessages] = React.useState("");
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus, setStatus] = React.useState("");

  const handlePicture = (event) => {
    setPicturePath(URL.createObjectURL(event.target.files[0]));
    setPicture(event.target.files[0]);
  };

  const handleVerifyPassword = (e) => {
    setVerifyPassword(e.target.value);
    if (password === e.target.value) {
      setStatus("Password Matched");
    } else {
      setStatus("Password not matched");
    }
  };

  //   const addNewRecord=async()=>{
  // if(picture=='')
  // {
  //     var body={'firstName':firstName,
  //     'lastName':lastName,
  //     'emailId':emailId,
  //     'password':password,
  //     'status':"Not Approved",
  //     'picture':"Not Found"}
  //     var result=await postData('selfregister/addNewRecord',body)
  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}

  // }

  // else{
  //    var formData=new FormData()
  //    formData.append('firstName',firstName)
  //    formData.append('lastName',lastName)
  //    formData.append('emailId',emailId)
  //    formData.append('password',password)
  //    formData.append('picture',picture)
  //    formData.append('status', "Not Approve")

  //    var config={headers:{'content-type':'multipart/form-data'}}
  //     var result=await postDataAndImage('selfregister/addNewRecordPic',formData,config)

  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}
  //     }
  //     }

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 70,
                }}
              >
                {/* <div className="banner-area inc-form bg-gradient slider-less text-light" style={{backgroundImage: "url(" + "../assets/img/event/1.jpg" + ")", padding:70}}> */}
                <div className="container">
                  <div className="row">
                    {/* <div className="double-items">
                    <div className="col-md-7 info">
                        <h2 className="wow fadeInLeft">Find your ideal study program.</h2>
                        <a className="btn day btn-light border btn-md wow fadeInDown" style={{color:'#3f8ccb'}} href="#">View Courses</a>
                    </div>
                    <div className="col-md-5 reg-form">
                        <form action="#">
                            <div className="row">
<img style={{flex:1}} class="login_image" src="assets/img/elearning.png" alt="Login" data-original-title="" title="" />

                            </div>
                        </form>
                    </div>
                </div> */}
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">Career</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="blog-area single full-blog left-sidebar full-blog default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="container"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <div className="row">
                    <div className="blog-items">
                      <div className="col-md-6 about-info">
                        <h2>
                          The Journey Of Earning As A Study Overseas Expert
                          Available With Us. <span></span>
                        </h2>

                        <p
                          style={{
                            fontSize: 14,
                            color: "#212121",
                            textAlign: "justify",
                          }}
                        >
                          In Study Metro, we believe that empowering advisors,
                          professors, agents, and organizations, educated &
                          mentored by TOP universities, is a superb way to
                          produce positive results in society and also to make
                          the planet a better place. We have been helping our
                          clients to locate thousands and thousands of
                          educational institutes, attending webinars, using
                          online for tens of thousands of applications in
                          developing nations, and assisting to secure
                          scholarships and internships.
                          <br />
                          Join us at the journey of creating Study Abroad
                          universally available for all.
                        </p>
                      </div>
                      <div className="col-md-6 features text-light">
                        <img
                          style={{ flex: 1 }}
                          class="login_image"
                          src="assets/img/career.png"
                          alt="Login"
                          data-original-title=""
                          title=""
                        />
                      </div>

                      <div className="blog-content col-md-12">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <h4>The challenges we are up against.</h4>
                                    <p style={{ color: "#212121" }}>
                                      Our obligation isn't simply to deliver
                                      chunks of universities to our pupils. It's
                                      letting them best-match their budget and
                                      profile, while at, precisely, what we are
                                      more focused towards is that they should
                                      get the best return on their investment.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      So, to manage all this, we are searching
                                      for ambitious people with the Perfect kind
                                      of ambition similar to ours. We are
                                      searching for people who are responsible
                                      to take ownership and not just employment.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      We feel that individuals push limitations
                                      and flourish way beyond their abilities
                                      for the bigger-cause assignments they are
                                      engaged with. The ideal type of vision is
                                      a dream for the organization's success
                                      with the individual's own success just
                                      coming as a by-product of their
                                      organization's success.
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                      Generalists with specialties
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                      Even though a large part of our jobs
                                      requires expertise and experience, there
                                      is always ambiguity and doubt,
                                      particularly if what we're attempting to
                                      accomplish is interrupting. We would like
                                      those that are specialists in their chosen
                                      areas but in precisely the exact
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                      same moment, get excited about the areas
                                      they have never exploited in and implement
                                      them competently. This empowers incredible
                                      growth for individuals joining Study
                                      Metro.
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                      Every inch counts
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                      At Study Metrowe think that human growth
                                      is the basis of organizational expansion,
                                      therefore we care for people's career
                                      paths and advancement. The folks we are
                                      searching for are the people who search
                                      relentlessly anyplace for an excess inch
                                      of expansion. We encourage an increase
                                      mindset by offering external and internal
                                      mentorship, promote audacious thoughts,
                                      and effort against the anxiety of
                                      collapse.
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                      Ownership and Effect
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                      We consciously search for techniques to
                                      interpret and comprehend the consequences
                                      of each job of scales and experience
                                      locations. We would like individuals who
                                      take possession in what they do, that are
                                      enthusiastic about creating things from
                                      scratch, goal to advance the state of the
                                      art in them, and also enjoy the effect of
                                      their goods.
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                      We'd Love to Speak with you
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                      Fantastic teams have been brought together
                                      by discussing the exact same assignment,
                                      such as the Fellowship of the Ring, the
                                      Straw Hat Pirates and tens of thousands of
                                      clubs that built great businesses. Should
                                      you find the above mentioned words
                                      intriguing, we're excited about exploring
                                      the opportunities for you personally. Do
                                      not be concerned about the places or
                                      places. After we're clicked, the remainder
                                      is going to probably be worked out.
                                    </p>

                                    <p style={{ color: "#212121" }}>
                                      Should you find the above mentioned words
                                      intriguing, we're excited about exploring
                                      the opportunities for you personally. Do
                                      not be concerned about the places or
                                      places. After we're clicked, the remainder
                                      is going to probably be worked out.
                                    </p>

                                    <div style={{width:250}}>
                                      <a href='https://studymetro.typeform.com/to/rgqxCjdp'>
                                    <MDBBtn
                                    gradient="blue"                                    
                                    rounded
                                    variant="contained"
                                    // onClick={addNewRecord}
                                    color="primary"
                                    className="btn-block z-depth-1a"
                                    // fullWidth
                                  >
                                    Apply Here
                                  </MDBBtn>
                                  </a>
                                  </div>
                                  </div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div className="col-md-12">
                                    <div
                                      className="content"
                                      style={{ paddingBottom: 0 }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area default-padding">
        <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="row" style={{ display: "block" }}>
            <div className="about-items">
              <div className="col-md-12">
                <div className="weekly-top-items">
                  <div className="top-courses" style={{ paddingRight: 0 }}>
                    <div className="heading" style={{ paddingBottom: 15 }}>
                      <h4 style={{ color: "#3f8ccb" }}>
                        ACCREDITATIONS AND MEMBERSHIPS <strong></strong>
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/EAIE.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/ICEF.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/NAFSA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/QISAN.jpeg"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIRC.gif"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIEA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
