/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from './Header';
import Footer from './Footer';


import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';



const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
  VikramButton:{
    border: '2px solid #e7e7e7',
    padding: '10px 35px',
    // textTransform: 'uppercase',
    fontWeight: 600,
    letterSpacing: '0.5px',
    color: '#3f8ccb',
    background: 'transparent',
    borderRadius: 5,
    float:'right',
  },
newbtn:{
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
},

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:12,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },

widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function OpenBlog(props) {
  const classes = useStyles();
  const [firstName,setFirstName]=React.useState('')
  const [lastName,setLastName]=React.useState('')
  const [emailId, setEmailId]=React.useState('')
  const [password,setPassword]=React.useState('')
  const [verifyPassword,setVerifyPassword]=React.useState('')
  const [messages, setMessages ]=React.useState('')
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus,setStatus]=React.useState('') 

  const handlePicture=(event)=>{
    setPicturePath(URL.createObjectURL(event.target.files[0]))
    setPicture(event.target.files[0])
   }

   const handleVerifyPassword=(e)=>{
    setVerifyPassword(e.target.value)
    if(password===e.target.value){
      setStatus("Password Matched")
    }
    else{
      setStatus("Password not matched")
    }
  }

   
    console.log("History ==  ",props)
  return (
   <Container className={classes.widths}>
   <Header/> <CssBaseline />
    <div className="login-area default-padding" style={{paddingTop:20,}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">
<div className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard" style={{backgroundImage: "url(" + "../assets/img/banner/5.jpg" + ")",padding:20}}>             
        <div className="container">
            <div className="row">
                <div className="double-items col-md-12 info">
                <h2 className="wow fadeInLeft">GRE Home Edition</h2>
                <h6 className="wow fadeInLeft">Study Metro Editorial Team</h6>
                <h6 className="wow fadeInLeft">Updated on 30 June, 2020</h6>
                </div>
             </div>
        </div>
    </div>


    <div className="event-area default-padding" style={{paddingTop:10,paddingBottom:0,}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                   
                         
                            <div class="thumb">
                                    <a href="#">
                                        <img src="assets/img/blog/21.jpg" alt="Thumb"/>
                                    </a>
                                              
                    </div>
                </div>
             
            </div>
        </div>
    </div>


    <div className="blog-area single full-blog left-sidebar full-blog default-padding">
        <div className="container">
     
            <div className="row">
                <div className="blog-items">
                    <div className="blog-content col-md-12">

                        <div className="content-items">
                            <div className="single-item">
                                <div className="item">
                                    <div className="info">
                                        <div className="content" style={{paddingBottom:0,}}>
                                            <h4>
                                               
                                            </h4>
                                       <p>GRE has been one of the most in-demand exams for Master’s aspirants. GRE is required for most of the schools in the USA and in many Universities worldwide. But, during these times of uncertainties and lockdown, the physical centers where the tests were taken have been shut down. But, ETS came up with a way out for all the aspirants by introducing GRE Home Edition which as the name suggests can be taken from home!&nbsp;</p>
                                       <p>It’s definitely intriguing but overwhelming at the same time when you are introduced with a new test-type and you have to take it. So, what are the differences between GRE at the test center and at home and how to take the GRE test from home? Let’s check out the key takeaways of the GRE Home Edition.</p>
                                        </div>



<div className="content" style={{paddingBottom:0,}}>
<h4><li>GRE Home Testing Availability</li></h4>
<p>
<div style={{marginLeft:35,}}>
The GRE Home Edition was introduced because many students were unable to take the test and hence, ETS was keen on making the GRE Home Edition available as soon as possible. The GRE Home Test is available for 4 days a week till 20th June (Friday, Saturday, Sunday, and Monday). Starting from 1st July - 30th September, due to an increase in demand to take the test, ETS is going to make the GRE Home Edition test available 7 days throughout the week.
</div>
</p>
</div>

<div className="content" style={{paddingBottom:0,}}>
<h4><li>About Offline Test Centers</li></h4>
<p>
<div style={{marginLeft:35,}}>
In some countries and cities, the offline Prometric test centers have opened. But, as advised by many countries not to get out as much as possible, even more aspirants are unable to take the exam due to lockdown. Hence, GRE Home Edition can be really helpful for these aspirants.
</div>
</p>
</div>

<div className="content" style={{paddingBottom:0,}}>
<h4><li>Similarities in the conventional GRE test and GRE Home Edition</li></h4>
<p>
<div style={{marginLeft:35,}}>
Almost everything is similar to the conventional GRE test when it comes to scoring, cost, format, features, and content. 
</div>
</p>
</div>

<div className="content" style={{paddingBottom:0,}}>
<h4><li>Major differences between the offline GRE test and GRE Home Edition</li></h4>
<p>
<div style={{marginLeft:35,}}>
The major differences between both these versions of the test are that in GRE Home Edition you need to take the test from home, use your own equipment, and that you are monitored online by a human proctor.
</div>
</p>
</div>

<div className="content" style={{paddingBottom:0,}}>
<h4><li>Payment of the GRE test</li></h4>
<p>
<div style={{marginLeft:35,}}>
The cost of GRE still remains $205 (for most of the countries) and can only be paid with a Credit Card while booking the date for your exam.
</div>
</p>
</div>

<div className="content" style={{paddingBottom:0,}}>
<h4><li>System Requirement for the exam</li></h4>
<p>
<div style={{marginLeft:35,}}>
ETS has allowed taking the GRE test only on a laptop or PC. The specifications of the system are as follows:
</div>
<div style={{marginLeft:50,}}>
<li>Operating System - Windows 7,8 or 10 Web Browser - Chrome or Firefox is allowed</li>
<li>MAC is allowed but only with Windows operating system and Chrome or Firefox as the web browser</li>
<li>Wireless earphones or headphones are not allowed, only wired earphones with microphones are allowed</li>
<li>Built-in camera or a separate webcam is allowed</li>
<li>Stable Internet</li>
<li>Download ETS browser which will be used for the test</li>
<li>You can check your system compatibility by going to the official Home Edition website where your system is auto-checked based on 5 parameters
 </li>
</div>
</p>
</div>


<div className="content" style={{paddingBottom:0,}}>
<h4><li>Just before the test</li></h4>
<p>
<div style={{marginLeft:35,}}>
You have to show 360 degrees around the room and your table surface with your webcam so that the human proctor can visually see everything around you. 
</div>
</p>
</div>
<div className="content" style={{paddingBottom:0,}}>
<h4><li>Special needs for some aspirants</li></h4>
<p>
<div style={{marginLeft:35,}}>
Aspirants with disabilities or special needs can get extended time, breaks, screen magnification, and selected colors too; provided they intimate it while booking the test. 
</div>
</p>
</div>
<div className="content" style={{paddingBottom:0,}}>
<h4><li>Reschedule the GRE test</li></h4>
<p>
<div style={{marginLeft:35,}}>
You can reschedule a test with no extra fee from the Home Edition to the offline edition or vice versa. 
</div>
</p>
</div>
<div className="content" style={{paddingBottom:0,}}>
<h4><li>Dressing Details</li></h4>
<p>
<div style={{marginLeft:35,}}>
Dress professionally, your ears shouldn’t be covered with a hat or hair, etc. In case of any religious wear, it’s subject to be inspected. You should not have a watch, anything on the desk, and anything that’s not required for the test. 
</div>
</p>
</div>
<div className="content" style={{paddingBottom:0,}}>
<h4><li>Note-taking material for rough work</li></h4>
<p>
<div style={{marginLeft:35,}}>
White Paper or board with wiping or erasing material. (you have to show that you have erased/wiped everything on the paper or board at the end of the test)
</div>
</p>
</div>
<div className="content" style={{paddingBottom:0,}}>
<h4><li>ID and Basic Requirement </li></h4>
<p>
<div style={{marginLeft:35,}}>
Your Passport is a must and a mirror or a mobile phone’s screen will be needed at the start and after the breaks to show what’s on your screen with the help of reflection.
</div>
<div style={{marginLeft:35,}}>
These are some key takeaways that will help you get your desired score by staying at home and nailing the exam. Don’t forget to always stay ahead of the crowd and be ready for the exam beforehand! We know that you will do amazing in your exam. Stay at home and clear the test with flying colors. Yocket is always here to assist you with your education abroad journey so why not have a safe flight to your dream destination by booking a free consultation call.
</div>
</p>


</div>

                       

                                       

                                        <div className="content" style={{paddingBottom:0,}}>
                                         

                                        <div className="col-md-12">
   <div className="content" style={{paddingBottom:0,}} >
                                        <hr/>
                                         <MDBRow>
        <MDBCol><i class="fa fa-thumbs-up fa-2x"></i><br/><strong>Login to recommend</strong> <br/><h5 style={{color:'3f8ccb',fontSize:12,}}>2 recommended</h5></MDBCol>
        <MDBCol><i class="fa fa-eye fa-lg fa-2x"></i><br/><strong>170 views</strong> <br/><h5 style={{color:'3f8ccb',}}></h5></MDBCol>
        <MDBCol>
<div className="blog-area">
<div className="info">
<div className="content" style={{paddingBottom:0,}} style={{padding:0,position:'static'}}>
<a href="#" style={{color:'#000',borderRadius:5,textAlign:'center'}}><i className="fab fa-facebook fa-lg fa-2x" style={{fontSize:18,}}></i> Facebook</a>
</div>
</div>
</div> 

        </MDBCol>
       
      </MDBRow>
      <hr/>
                          </div>
                                </div>
                          
                                        </div>



<div className="content">

<p>
<div style={{marginLeft:35,}} >
<h6 style={{marginLeft:20,color:'#000'}}>NO COMMENTS YET</h6>
<form action="#" method="POST" className="contact-form"> 
                            <div className="col-md-12">
                                <div className="">
                                    <div className="form-group comments">
                                        <textarea className="form-control" id="comments" name="comments" placeholder="Write a comment..."></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="">
                                    <button className={classes.VikramButton} type="submit" name="submit" id="submit">
                                        Post comment <i className="fa fa-paper-plane"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-12 alert-notification">
                                <div id="message" className="alert-msg"></div>
                            </div>
                        </form>
</div>
</p>


</div>


                                    </div>
                                </div>
                            </div>

                            </div>

                      
                    </div>
                  
  
                </div>
            </div>
        </div>
    </div>

</div>
</div>
</div>
</div>

<Footer/>
    </Container>
  );
}
