/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import icon from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Header from './Header';
import Footer from './Footer';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';



const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
newbtn:{
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
},

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:12,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },

widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function Compare(props) {
  const classes = useStyles();
 


  return (
   <Container className={classes.widths}>
   <Header/>  <CssBaseline />
    <div className="login-area default-padding" style={{paddingTop:20,}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">
<div className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard" style={{backgroundImage: "url(" + "../assets/img/banner/6.jpg" + ")",padding:70}}>             

        <div className="container">
            <div className="row">
            <div className="double-items col-md-12 info">
                <h2 className="wow fadeInLeft">Australia</h2>
                </div>
             
            </div>
        </div>
    </div>


    <section class="video_sec blog_wrap">
<div class="container">
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <div class="blog_box faq">
                <h2>Frequently Asked Questions?</h2>
                <div class=" blog_box_content">
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 1)	Is there a deadline for applying?</h3>
                        <p style={{color:'#333'}}>A: No deadlines for admissions.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 2)	IS GRE/GMAT or IELTS/TOEFL is required for admission?</h3>
                        <p style={{color:'#333'}}>A: Yes IELTS/ TOFEL is mandatory for the admission.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 3)	How much Time it will take to get the offer letter</h3>
                        <p style={{color:'#333'}}>A: It takes minimum 1week and maximum 2 to 3 weeks to get offer letter. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 4)	Can i work on campus?</h3>
                        <p style={{color:'#333'}}>A: Yes, you can work both on-campus or off-campus.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 5)	How long will it take for me to obtain my internship employment?</h3>
                        <p style={{color:'#333'}}>A: To obtain a internship employment it will take around 1 or 2 months maximum.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 6)	What type of employment will I get initially?</h3>
                        <p style={{color:'#333'}}>A: You will get a entry level job in the initial stage into your concerned profile.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 7)	Must I find an internship job only in the field related to my major or is any job O.K.?</h3>
                        <p style={{color:'#333'}}>A: We recommend you to find internship jobs into the concerned field of your study program as internships are meant for international exposure and practical experience. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 8)	How many working hours will I probably work each week?</h3>
                        <p style={{color:'#333'}}>A: You are entitled to work 20hrs a week.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 9)	Do I have to work overtime? Can I keep the overtime bonus?</h3>
                        <p style={{color:'#333'}}>A: No. You are not allowed to work over time. But you can work full time during the off sessions / semester holidays.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 10)	What if I can't find employment?</h3>
                        <p style={{color:'#333'}}>A: You can find a part time work till you find your internship job into your profile.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 11)	Will I have to pay taxes?</h3>
                        <p style={{color:'#333'}}>A: Yes</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 12)	Is there a way for me to work before actually starting to attend classes in order for me to save more money and add it to my current savings?</h3>
                        <p style={{color:'#333'}}>A: No as you will be flying only before 3 or 4 days of your intake date.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 13)	Could the work authorization be possibly converted into a full time work permit upon completion of the masters program?</h3>
                        <p style={{color:'#333'}}>A: Yes, once you complete you Bachelors or Masters Program you are entitled for 2 years post study work visa where you can work full time.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 14)	How much is the bank statement required for applying VISA?</h3>
                        <p style={{color:'#333'}}>A: 50% of the fess should be paid, and the other 50% of the fees + 1yr living expenses should be shown in the bank statement.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 15)	How long I have to keep amount in my bank account for VISA?</h3>
                        <p style={{color:'#333'}}>A: 3months old bank statement should be provided for the visa interview.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 16)	How much tuition fee I have to pay before and after getting VISA/Offer letter?</h3>
                        <p style={{color:'#333'}}>A: 1st year fees has to be paid before visa.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 17)	Can one apply for Dependent VISA?</h3>
                        <p style={{color:'#333'}}>A: Yes. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 18)	How can apply as a dependent VISA?</h3>
                        <p style={{color:'#333'}}>A: You will require showing additional fund in the bank which supports their living expenses.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 19)	What is required in order to bring my spouse and family?</h3>
                        <p style={{color:'#333'}}>A: You will need to produce the documents needed to show your spouse’s or civil partner's status and evidence of your marital or civil partner status (for example, birth or naturalization certificate of Irish spouse, marriage or civil registration certificate).</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 20)	Can my spouse get the wok permit there?</h3>
                        <p style={{color:'#333'}}>A: Yes.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 21)	How much duration work permit will spouse get there?</h3>
                        <p style={{color:'#333'}}>A: Your spouse can work till the duration of the study program of the student.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 22)	Can I apply work permit after completing my studies?</h3>
                        <p style={{color:'#333'}}>A: Yes, student who has completed his Bachelors or Masters program are entitled for 2 yrs work permit after the study program.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 23)	How much time will I get after my studies to search job there?</h3>
                        <p style={{color:'#333'}}>A: The time to search job is included in 2yrs work permit.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 24)	Can my spouse extend the work permit?</h3>
                        <p style={{color:'#333'}}>A: If you are married to an Australian citizen, permanent resident or eligible New Zealand citizen, or have been in a defacto relationship (common law) with one for at least 12 months, you may be eligible for immigration by obtaining a Spouse Visa to Australia. Your spouse may sponsor you for a period of two years. After this, if the relationship is still genuine and continuing, you may be eligible for permanent residence.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: 25)	Can my accompanying dependent attend school?</h3>
                        <p style={{color:'#333'}}>A: No.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is IELTS required for dependent?</h3>
                        <p style={{color:'#333'}}>A: No</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Will University secure job for me?</h3>
                        <p style={{color:'#333'}}>A: No. University can assist you in finding your internship jobs.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Why Should I study in xyz Company?</h3>
                        <p style={{color:'#333'}}>A: •	Education system is internationally renowned. 
•	Throughout the curriculum take an international perspective. 
•	Australia has a strong multicultural spirit.
•	Work permit up to 2 years after your education in Australia
•	Simpler Visa Process and Students are allowed to work Part-time i.e. 20 hrs/week.
•	Opportunities of PR. 
•	Dependents can fly along with the student.
•	Dependents can work full time.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Why should I study in Australia?</h3>
                        <p style={{color:'#333'}}>A: Over 600,000 international students across 140 countries choose to study in Australia each year. Recent surveys conducted by Studies in Australia indicate four main reasons why international students choose to study in Australia:
•	Globally recognised courses and institutions
•	Lively and multicultural lifestyle
•	Reasonable study costs
•	Beautiful weather and landscapes
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What English language tests are acceptable for Australian student visas?</h3>
                        <p style={{color:'#333'}}>A: There are five different tests accepted for Australian student visa applications: 
•	Test of English as a Foreign Language (TOEFL) 
•	International English Language Testing System (IELTS) 
•	Cambridge English: Advanced (CAE) 
•	Occupational English Test (OET) 
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How can I apply for scholarships in Australia?</h3>
                        <p style={{color:'#333'}}>A: Most institutions in Australia offer scholarships and many of these are available to prospective international students. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I work during my studies in Australia? </h3>
                        <p style={{color:'#333'}}>A: Yes, you can work while you’re studying in Australia. International students are permitted to work a maximum of 40 hours per fortnight during study periods and unlimited hours during study breaks. There are no work restrictions for students completing a masters by research or doctoral degree</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I change my course or institution if I don’t like it?</h3>
                        <p style={{color:'#333'}}>A: Yes, you can — although there are strict conditions and steps set by the Department of Immigration and Border Protection that you will need to meet and follow. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is so good about Australian education and training compared to other countries?</h3>
                        <p style={{color:'#333'}}>A: Australia is an internationally recognized source of high quality education as well as research excellence. Our universities, vocational and technical education colleges and schools are world-class. And Australia is a world leader in English language courses for international students.Australian Government legislation requires quality assurance agencies and codes of conduct to be in place so that international students in Australia and their parents enjoy a level of service and financial protection that is unrivaled.

Multicultural Australia is a safe, friendly, sophisticated and harmonious society in which students can learn and travel in an English speaking country. Australia also offers excellent value for money and a high standard of living.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are the entry requirements to study at Australian institutions?</h3>
                        <p style={{color:'#333'}}>A: Students must meet minimum academic requirements and need a sufficient level of English language proficiency for entry to Australian education and training institutions. Institutions will assess whether you meet the selection criteria set for your proposed course of study. They will look at the level and content of the study you have completed in Australia or your home country.

To be accepted to study on a student visa in Australia you will need to demonstrate a sufficient level of English language proficiency, and meet minimum academic requirements. English language skills are a requirement for receiving an Australian student visa. IELTS is the only test accepted by the Australian Department of Immigration and Citizenship (DIAC) for visa purposes.

Institutions set their own English language requirements for course entry, and the IELTS score needed for your course may be different to the score needed to secure your visa. You should check entry requirements with your institution.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the visa process for study in Australia?</h3>
                        <p style={{color:'#333'}}>A: To be granted a student visa you must complete a visa application form, lodge it with the evidence required, pay the application charge and satisfy the student visa requirements. You can be granted a student visa only if you intend to study a registered course on a full-time basis and comply with a number of visa conditions after you have entered Australia.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is the minimum IETS score required to get into a Australia Institution?</h3>
                        <p style={{color:'#333'}}>A: Min. overall band score of 6.0 IELTS or Overall band score of 5.0 IELTS plus max. 30 weeks ELICOS.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much are the living expenses for International Student?</h3>
                        <p style={{color:'#333'}}>A: An Australian Government survey showed that in terms of total costs—including tuition fees and living expenses—Australia was consistently less expensive than the USA and UK. Tuition fees will vary depending on the course or institution this website provides average costs as a guide. Living costs too will vary by location, course and lifestyle.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are the entry criteria for studies in Australia?</h3>
                        <p style={{color:'#333'}}>A: •	Undergraduate
To gain entry into an Australian undergraduate course you will need to have an Australia Senior Secondary Certificate of Education (Year 12), or the overseas equivalent. Some undergraduate courses may also have specific per-requisite subjects.
•	Postgraduate
As well as the satisfactory completion of at least one degree at undergraduate level, your institution may take research ability or relevant work experience into consideration.
•	Vocational education and training
Generally there are no other entrance exams for VET institutions. However some courses may have specific per-requisite subjects or work experience requirements.
•	Schools
Entry requirements can vary between schools in Australia, depending on the state or territory you will be studying in. Academic performance and ability is considered during the selection process.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How are Australian institutions ranked?</h3>
                        <p style={{color:'#333'}}>A: Australian institutions are not officially ranked. However, there are some commercial publications available that compare achievements of universities and institutions in meeting certain criteria such as use of technology or student support services.

All of Australia's education and training institutions offer high quality, internationally relevant courses because Australian Government legislation requires them to be registered and meet quality assurance measures. Excellence in teaching, research, facilities and support services is found across Australia's diversified education and training system. Prospective students should focus on finding institutions that offer the course most suited to their particular needs and aspirations.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I study for an Australian qualification by distance or on-line?</h3>
                        <p style={{color:'#333'}}>A: Distance education courses are offered by Australian universities and vocational and technical education institutions. You will need to check directly with the institution about what courses are offered in distance mode.
•	Distance education
If you want to study with an Australian institution but can’t leave home for all or part of your course, distance education could be the perfect solution for you. Distance education programs allow you to obtain a qualification from an Australian institution in your home country, or to combine study at home with study in Australia. Australia leads the world in the delivery of distance education programs. Institutions use leading technologies and innovative approaches to deliver programs that can be tailored to meet your needs.
•	Entry requirements
You should check with the institution of your choice about which courses are offered in distance mode, and their entry requirements. You will probably need an Australian Senior Secondary Certificate of Education (Year 12) or an equivalent certificate from another country for admission to undergraduate distance education in Australia. Institutions may also take into account your maturity, motivation, or work experience in a relevant area.
</p>
                    </div>
                                   
                </div>
                
            </div>

        </div>
    </div>

</div>

</section>



    <div className="advisor-details-area default-padding" style={{padding:0,}}>
   
   </div>



</div>
</div>
</div>
</div>

<Footer/>
    </Container>
  );
}
