/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function IELTS(props) {
  const classes = useStyles();

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />

      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 25,
                  marginTop: 30,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">IELTS</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="blog-area single full-blog left-sidebar full-blog default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="container"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <div className="row">
                    <div className="blog-items" style={{ width: "100%" }}>
                      <div className="blog-content col-md-12">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      //   marginLeft: 35,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <p style={{ color: "#212121" }}>
                                      IELTS is an English language test that's
                                      required to be obtained by international
                                      candidates contemplating working or
                                      studying in a country where English is the
                                      major language of communication. The
                                      examination mainly measures the capability
                                      of test-takers to convey from the four
                                      basic English language skills - listening,
                                      reading, talking, and writing. Together
                                      with the amount of IELTS tests climbed
                                      into a list of 3.5 million from the year
                                      2018, it has grown into a pioneer in the
                                      region of global higher education. It's
                                      approved in 100 percent of universities in
                                      the United Kingdom and Australia.
                                      Additionally, it's accepted in over 3,400
                                      associations in the united states and
                                      thousands of institutions in English
                                      speaking nations.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6> Recent upgrades in IELTS</h6>
                                      IELTS Exam Fee Update: IELTS Exam fee has
                                      been revised to INR 14,000 to get
                                      registrations from 1st-April-2020.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      It's the sole English Language Evaluation
                                      approved by UK Visas and Immigration
                                      (UKVI) for visa applicants employing both
                                      outside and within the United Kingdom. The
                                      easy reply to this is that overseas visa
                                      and universities awarding authorities will
                                      need to make confident you won't have
                                      communication problems while remaining in
                                      the nation. You have to demonstrate a
                                      fantastic understanding and strong control
                                      of the English language and that's
                                      precisely why your general IELTS scores
                                      are so significant. Another frequent doubt
                                      pupils have is if IELTS is a compulsory
                                      examination or not. No, IELTS is not
                                      mandatory in most college admissions. Many
                                      universities may not even need IELTS
                                      scores for entry purposes. But keep in
                                      mind that in the event you do not give
                                      IELTS, your odds of getting the student
                                      visa may suffer since the visa officers
                                      might not be confident about your English
                                      competence without IELTS scores. So it's
                                      safer to look for IELTS and intention to
                                      score at 6 rings overall.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      You can take the IELTS examination
                                      together with the British Council or IDP
                                      around after a week (four times per
                                      month). The British Council and IDP
                                      worldwide program for test dates will be
                                      48 times annually.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6> Kinds of IELTS</h6>
                                      Listening and speaking segments will be
                                      the exact same for these two evaluations,
                                      but the subject matter for your reading
                                      and writing sections are somewhat
                                      different depending on which test one
                                      chooses. On the other hand, the Speaking
                                      part could be performed either a week
                                      before or after another evaluations up to
                                      now. This advice test could be obtained
                                      from your evaluation centre.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        {" "}
                                        IELTS Academic is accepted by people who
                                        apply for higher education or skilled
                                        enrollment overseas.
                                      </h6>
                                      IELTS General Test is accepted by people
                                      who would like to migrate into important
                                      English speaking countries like Canada,
                                      Australia, and the united kingdom. This
                                      evaluation can also be taken by people who
                                      wish to register for training programs or
                                      secondary schooling or wishes to acquire
                                      work experience in a country where English
                                      is the major language used for
                                      communicating.
                                    </p>{" "}
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6>IELTS Qualification</h6>
                                      IELTS may be obtained by anybody
                                      regardless of age, sex, race, nationality
                                      or faith, but the IELTS test isn't
                                      suggested for people under 16 decades old.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        What's the age limit to look for the
                                        IELTS examination?
                                      </h6>
                                      Minimum age requirement is reduced for
                                      speech demands for Canadian citizenship:
                                      Because of changes in the Citizenship Act
                                      in Canada, the age that applicants need to
                                      show their language proficiency for
                                      Canadian citizenship was lowered.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        {" "}
                                        How many efforts can be awarded for
                                        IELTS?
                                      </h6>
                                      There is absolutely not any limit
                                      determined by the running bodies of IELTS
                                      for taking the IELTS examination. But you
                                      need to pay the IELTS test fee for every
                                      single effort.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        {" "}
                                        What's the instructional eligibility to
                                        provide IELTS examination?
                                      </h6>
                                      Therefore, there aren't any minimum
                                      eligibility standards for IELTS set from
                                      the running bodies of IELTS. Anyone who
                                      wants to pursue higher studies overseas or
                                      wish to work overseas can try the IELTS
                                      examination (both Academic and General
                                      Training). But, candidates must always
                                      check the eligibility standards decided by
                                      the educational institution or business
                                      where they're employing.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6>How can IELTS work?</h6>
                                      IELTS test was created to evaluate the
                                      English language abilities of somebody.
                                      The people are evaluated on the
                                      foundations of the Listening, Reading,
                                      Speaking and Writing skills.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; IELTS for Research: Selecting
                                          IELTS for research can be of enormous
                                          advantage for applicants since IELTS
                                          is accepted by over 10,000 education
                                          institutions internationally.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; IELTS for Function: Various
                                          professional associations, and
                                          companies throughout the globe accept
                                          IELTS to pick the ideal candidate. The
                                          significance of communicating felt in
                                          the time of functioning. There are
                                          various businesses which take the
                                          IELTS test for analyzing the language
                                          proficiency of their candidates.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; IELTS for immigration: Each
                                          nation has its own set of tips for
                                          IELTS. Like in Australia it's approved
                                          for permanent residency and assorted
                                          Visa categories. Those using to Canada
                                          to get a work visa, permanent
                                          residency or specialist purpose are
                                          expected to accept IELTS General Test.
                                        </li>
                                      </ul>{" "}
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      Nominees have this uncertainty about who's
                                      ideal for IELTS, British Council or IDP?
                                      But, there's absolutely no gap between the
                                      two the bureaus, whether you choose IELTS
                                      from British Council or IDP. Thus, you can
                                      take the IELTS examination conducted by
                                      some of this bureau.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      Make certain to keep your passport handy
                                      in the right time of enrollment as you
                                      will need passport amount in the time of
                                      enrollment. Aside from that, you also will
                                      need to take your passport to the exam
                                      day. IELTS Registration may be done in
                                      simple steps:
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Create a account together with
                                          the British Council.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Locate your nearest test centre
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; Register for your IELTS test
                                          and cover online
                                        </li>
                                      </ul>
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      Those under 18 decades old need their
                                      parent or guardian to reserve the exam for
                                      them. When the registration is done, your
                                      exam centre will deliver a written
                                      confirmation to you telling about the time
                                      and date of your IELTS exam.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6> IELTS Exam Charges</h6>
                                      The enrollment fee for IELTS is Rs. 14,000
                                      to get registrations out of 1st-Apr-2020.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6>What's IELTS Cancellation Fees?</h6>
                                      <ul style={{ marginLeft: 20 }}>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; in the event that you cancel
                                          your IELTS program over five weeks
                                          before the exam date, you'll obtain
                                          the refund minus 25% administrative
                                          fee.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; in the event that you cancel
                                          the IELTS examination less than five
                                          months before the exam date, you'll
                                          get no refund.
                                        </li>
                                        <li>
                                          <i class="fa fa-check-circle"></i>
                                          &nbsp; In the event you don't appear
                                          for the exam, then you also won't get
                                          any compensation. This is also thought
                                          of as a cancelation.
                                        </li>
                                      </ul>
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      People who can create a health certificate
                                      within 5 days of the exam date can obtain
                                      the refund after deducting the regional
                                      administrative price.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6> What's IELTS Rescheduling Fees?</h6>
                                      If you would like to change your test
                                      date, then it is possible to ask to get a
                                      test day move a minimum of five weeks
                                      prior to your already booked evaluation
                                      date after deducting your
                                      cancellation/postponement charge.
                                      Additionally, be certain that the new test
                                      date must be within three weeks of their
                                      initial test date. Candidates will need to
                                      pay an administrative fee of INR 3,300
                                      inclusive of taxes for transport of
                                      evaluation date.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      Among the greatest drawbacks about
                                      studying overseas is having to cover
                                      tuition fees in foreign exchange money
                                      with higher living expenses. As a result
                                      of such reasons, a great deal of
                                      instances, many parts of pupils are
                                      unavailable to proceed overseas and wind
                                      up settling for different options. But
                                      with the access to various IELTS
                                      Scholarships and instruction loans,
                                      students may eventually make their dream
                                      come true. Scholarships are offered on the
                                      official site of every faculty alongside
                                      other fellowships and grants which are
                                      given to school students on a regular
                                      basis. Students may also go for schooling
                                      loan from financial institutions for
                                      studying overseas.
                                    </p>
                                    <p style={{ color: "#212121" }}>
                                      British Council and IDP IELTS will be both
                                      official running figures of their IELTS
                                      Exam. The IELTS test can be obtained
                                      multiple occasions during the year.
                                      Candidates are advised to reserve an IELTS
                                      Exam Date that's most suitable to them,
                                      remembering the university entrance
                                      timeline. Students appearing to look for
                                      your IELTS test are advised to opt for an
                                      examination date 3-4 months prior to their
                                      application deadline, to prevent any last
                                      minute mistake. The IELTS test can be
                                      found across various significant cities in
                                      India.
                                    </p>
                                    <br />
                                    <p style={{ color: "#212121" }}>
                                      <h6>
                                        IELTS Assessment Layout and Syllabus
                                      </h6>
                                      It's essential for applicants seeking to
                                      score well in their IELTS Exam to
                                      comprehend that the IELTS Exam Pattern and
                                      Syllabus in detail. You get marked with
                                      those four segments and you receive the
                                      IELTS complete score too. The entire score
                                      is the sum of these scores from the four
                                      skill areas. The entire test duration is
                                      two hours and 45 minutes.
                                    </p>
                                  </div>
                                </div>

                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div className="col-md-12">
                                    <div
                                      className="content"
                                      style={{ paddingBottom: 0 }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area default-padding">
        <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="row" style={{ display: "block" }}>
            <div className="about-items">
              <div className="col-md-12">
                <div className="weekly-top-items">
                  <div className="top-courses" style={{ paddingRight: 0 }}>
                    <div className="heading" style={{ paddingBottom: 15 }}>
                      <h4 style={{ color: "#3f8ccb" }}>
                        ACCREDITATIONS AND MEMBERSHIPS <strong></strong>
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/EAIE.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/ICEF.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/NAFSA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/QISAN.jpeg"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIRC.gif"
                    class="logo"
                    alt="Logo"
                  />
                </div>
                <div class="col-md-2">
                  <img
                    src="assets/img/footer/AIEA.png"
                    class="logo"
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
