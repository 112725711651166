import React, { Fragment } from "react";
import { Text, StyleSheet } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
  MDBSideNav,
  MDBNavItem,
} from "mdbreact";

//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";

import color from "@material-ui/core/colors/amber";
import Header from "./Header";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
  category: {
    position: "sticky",
  },
  category: {
    a: {
      display: "block",
    },
  },
  
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [messages, setMessages] = React.useState("");
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus, setStatus] = React.useState("");

  const handlePicture = (event) => {
    setPicturePath(URL.createObjectURL(event.target.files[0]));
    setPicture(event.target.files[0]);
  };

  const handleVerifyPassword = (e) => {
    setVerifyPassword(e.target.value);
    if (password === e.target.value) {
      setStatus("Password Matched");
    } else {
      setStatus("Password not matched");
    }
  };
  console.log("History ==  ", props);

  return (
    <Container className={classes.widths}>
      <Header />
      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 70,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">Loan Provider</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-area single full-blog left-sidebar full-blog default-padding">
                <div className="container">
                  <div className="row">
                    <div className="blog-items">
                      <div className="blog-content col-md-12">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div
                                    style={{
                                      marginLeft: 5,
                                      textAlign: "justify",
                                    }}
                                  >
                                    <div>
                                      <div>
                                        <h1><center>
                                          All ABOUT
                                        
                                          OVERSEAS
                                        
                                          EDUCATION LOAN</center>
                                        </h1>
                                      </div>
                                      <div
                                        style={{
                                          paddingTop: "50px",
                                          paddingLeft: "30px",
                                        }}
                                      >
                                        <img
                                          src="/images/book.png"
                                          width="200px"
                                          height="150px"
                                        />
                                      </div>
<br/>
<br/><br/>
<br/>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "white",
                                            backgroundColor: "rgb(63, 140, 203)",
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          Topics Covered
                                        </h1>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: 10,
                                        }}
                                      >
                                        <p>
                                        <ul>
                                          <li type="1" style={{ padding: 3 }}>
                                            Our Partenered Financial
                                            Institutions
                                          </li>
                                          <li type="1" style={{ padding: 3 }}>
                                            Types of education loans
                                          </li>
                                          <li type="1" style={{ padding: 3 }}>
                                            Quantum of Finance
                                          </li>
                                          <li type="1" style={{ padding: 3 }}>
                                            Consideration on the basis of
                                            Interest Rate
                                          </li>
                                          <li type="1" style={{ padding: 3 }}>
                                            Loan Margin / Margin Money
                                          </li>
                                          <li type="1" style={{ padding: 3 }}>
                                            Repayment
                                          </li>
                                          <li type="1" style={{ padding: 3 }}>
                                            Disbursement
                                          </li>
                                          <li type="1" style={{ padding: 3 }}>
                                            Processing Fees and Other Charges
                                            for UL and SL
                                          </li>
                                          <li type="1" style={{ padding: 3 }}>
                                            Criteria of Loans
                                          </li>
                                          <li type="1" style={{ padding: 3 }}>
                                            Things to remember for getting UL /
                                            SL
                                          </li>
                                        
                                          <li
                                            type="1"
                                            style={{ padding: 3 }}
                                          >
                                            Documents related to the University
                                            / College
                                          </li>
                                          <li type="1" style={{ padding: 3 }}>
                                            Income Related Documents
                                          </li>
                                          <li type="1" style={{ padding: 3 }}>
                                            Property Related Documents
                                          </li>
                                          <li type="1" style={{ padding: 3 }}>
                                            Things to be checked before applying
                                            for loan
                                          </li>
                                          <li type="1" style={{ padding: 3 }}>
                                            Cibil Score / Credit Score
                                          </li>
                                          <li type="1" style={{ padding: 3 }}>
                                            PAN card
                                          </li>
                                        </ul>
                                        </p>
                                      </div>
                                      <br/>
<br/><br/>
<br/>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "white",
                                            backgroundColor: "rgb(63, 140, 203)",
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          Our Partnered Banks offering Loans
                                        </h1>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: 20,
                                        }}
                                      >
                                        <Table
                                          border="1"
                                          style={{ width: "70%" }}
                                        >
                                          <tr>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>UNSECURED LOAN</th>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>SECURED LOAN</th>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  NBFC’s, Private Banks,
                                                  International Lenders &amp;
                                                  Nationalised Banks provide
                                                  Unsecured Loans
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Nationalised Banks, Private
                                                  Bank &amp; NBFC’s provide
                                                  Secured Loans
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>
                                        </Table>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          paddingTop: "20px",
                                        }}
                                      >
                                        <img
                                          src="/images/bank.png"
                                          width="400px"
                                          height="150px"
                                        />
                                      </div>
                                      <br/>
<br/><br/>
<br/>
                                      
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "white",
                                            backgroundColor: "rgb(63, 140, 203)",
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          TYPES OF EDUCATION LOANS
                                        </h1>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: 20,
                                        }}
                                      >
                                        <Table
                                          border="1"
                                          style={{ width: "70%" }}
                                        >
                                          <tr>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>UNSECURED LOAN (Non‐Collateral Loan) :</th>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>SECURED LOAN ( Collateral Loan) :</th>
                                          </tr>

                                          <tr>
                                            <td>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  padding: 20,
                                                }}
                                              >
                                                <img src="/images/loan1.png" />
                                              </div>
                                              <ul>
                                              <li style={{ paddingLeft: 10 }} >
                                                The student needs to declare
                                                Academic Performance, Aspiring
                                                Overseas Country &amp; Aspiring
                                                Overseas University along with
                                                their parent’s annual income
                                                proof according to requirement.
                                                The student does not need
                                                collateral for this type of
                                                loan.
                                              </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  padding: 20,
                                                }}
                                              >
                                                <img src="/images/loan2.png" />
                                              </div>
                                              <ul>
                                              <li style={{ paddingLeft: 10 }}>
                                                The student needs to place an
                                                eligible property whose title
                                                deed is clear for mortgage or
                                                other liquid assets such as LIC,
                                                Govt. Bonds, Post Office
                                                deposits, Term deposits and take
                                                the loan against it.
                                              </li>
                                              </ul>
                                            </td>
                                          </tr>
                                        </Table>
                                      </div>
                                      <br/>
<br/><br/>
<br/>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "white",
                                            backgroundColor: "rgb(63, 140, 203)",
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          Quantum of Finance
                                        </h1>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: 20,
                                        }}
                                      >
                                        <Table
                                          border="1"
                                          style={{ width: "70%" }}
                                        >
                                          <tr>
                                            <th  style={{ textAlign: "center", fontWeight:'bold'}}>UNSECURED LOAN</th>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>SECURED LOAN</th>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  NBFC’s upto ₹ 40 lakhs for USA
                                                  and other country upto 20 lacs
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>NBFC’s upto ₹ 50 lacs</li>
                                              </ul>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Private Bank : Prime A – 40
                                                  lacs : Prime B – 25 lacs
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>Axis Bank upto 75 lacs</li>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>ICICI Bank upto 1 Cr</li>
                                              </ul>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>BOB upto ₹ 7.5 lacs</li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  BOB upto ₹ 80.00 lacs –
                                                  Premier
                                                </li>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  BOB upto ₹ 60.00 lacs – Non
                                                  Premier
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>SBI upto ₹ 7.5 lacs</li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>SBI upto ₹ 1.50 Cr</li>
                                              </ul>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>MPower upto $ 50,000</li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  MPower – No Secured loan
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>
                                        </Table>
                                      </div>
                                      <br/>
<br/><br/>
<br/>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "white",
                                            backgroundColor: "rgb(63, 140, 203)",
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          Rate of Interest
                                        </h1>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: 20,
                                        }}
                                      >
                                        <Table
                                          border="1"
                                          style={{ width: "70%" }}
                                        >
                                          <tr>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>UNSECURED LOAN</th>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>SECURED LOAN</th>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  NBFC’s – 11.50% to 13 % (USA)
                                                  <br />
                                                  13 to 15% (Other country)
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>NBFC’s – 11% to 12.50%</li>
                                              </ul>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Private Banks –11.50% to
                                                  12.75%
                                                  <br />
                                                  (USA), 13 to 15% (Other
                                                  country)
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Axis Bank &amp; ICICI Bank –
                                                  <br />
                                                  11% to 12%
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  State Bank of India – 7.90%
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  State Bank of India – 9.7%
                                                  <br />
                                                  (0.50% for Girl’s concession)
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Bank of Baroda – Same as SL
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Bank of Baroda –<br />
                                                  Premier ‐ 8.75 %<br />
                                                  Non Premier – 9.50 %<br />
                                                  (0.50% for Girl’s concession)
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>MPower : 10 – 11%</li>
                                              </ul>
                                            </td>
                                            <td></td>
                                          </tr>
                                        </Table>
                                      </div>
                                      <br/>
<br/><br/>
<br/>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "white",
                                            backgroundColor: "rgb(63, 140, 203)",
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          Loan Margin
                                        </h1>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: 20,
                                        }}
                                      >
                                        <Table
                                          border="1"
                                          style={{ width: "70%" }}
                                        >
                                          <tr>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>UNSECURED LOAN</th>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>SECURED LOAN</th>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>100 % funding by NBFC</li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Marginal funding :<br />
                                                   85% + 15%
                                                  <br />
                                                   90% + 10%
                                                  <br /> 80% + 20%
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Private Banks –11.50% to
                                                  12.75%
                                                  <br />
                                                  (USA), 13 to 15% (Other
                                                  country)
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Axis Bank &amp; ICICI Bank –
                                                  <br />
                                                  11% to 12%
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Private Banks – 85% + 15% of
                                                  funding
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>Ex :</li>
                                                <br />

                                                <li style={{paddingLeft: 15, paddingRight: 15}}>Loan amt – ₹ 40.00 lacs</li>
                                                <br />
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Parents Margin – ₹ 6.00 lacs
                                                  (15%)
                                                </li>
                                                <br />
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Bank Margin – ₹ 34.00 lacs
                                                  (85%)
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  International Lenders – 100%
                                                  Funding
                                                </li>
                                              </ul>
                                            </td>
                                            <td></td>
                                          </tr>
                                        </Table>
                                      </div>
                                      <br/>
<br/><br/>
<br/>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "white",
                                            backgroundColor: "rgb(63, 140, 203)",
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          Repayment
                                        </h1>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: 20,
                                        }}
                                      >
                                        <Table
                                          border="1"
                                          style={{ width: "70%" }}
                                        >
                                          <tr>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>UNSECURED LOAN</th>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>SECURED LOAN</th>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Compulsory to pay Simple Int.
                                                  or
                                                  <br />
                                                  Partial Int, once loan is
                                                  disbursed
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Optional to pay Simple
                                                  Interest
                                                  <br />
                                                  during Moratorium Period
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>Study Period - 2 years</li>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Job Search Period - 12 months
                                                </li>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  After Moratorium Period -
                                                </li>
                                                <li  style={{paddingLeft: 15, paddingRight: 15}}>EMI begins</li>
                                              </ul>
                                            </td>

                                            <td>
                                              <ul>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>Study Period - 2 years</li>
                                              <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Job Search Period - 6/12
                                                  months
                                                </li>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  After Moratorium Period -
                                                </li>
                                                <li  style={{paddingLeft: 15, paddingRight: 15}}>EMI begins</li>
                                              </ul>
                                            </td>
                                          </tr>
                                        </Table>
                                      </div>
                                      <br/>
<br/><br/>
<br/>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "white",
                                            backgroundColor: "rgb(63, 140, 203)",
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          Disbursement
                                        </h1>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: 20,
                                        }}
                                      >
                                        <Table
                                          border="1"
                                          style={{ width: "70%" }}
                                        >
                                          <tr>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>UNSECURED LOAN</th>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>SECURED LOAN</th>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Once loan is sanctioned, the
                                                  Loan amount will be fully
                                                  disbursed.
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Loan amount will be disbursed
                                                  in instalments as demanded by
                                                  the University and living
                                                  expenses will be disbursed as
                                                  per visa norms
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Amount disbursed in student
                                                  account or Parent account
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Amount disbursed in Joint Loan
                                                  Account of Student &amp;
                                                  Parents
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>
                                        </Table>
                                      </div>
                                      <br/>
<br/><br/>
<br/>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "white",
                                            backgroundColor: "rgb(63, 140, 203)",
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          Processing Fees
                                        </h1>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: 20,
                                        }}
                                      >
                                        <Table
                                          border="1"
                                          style={{ width: "70%" }}
                                        >
                                          <tr>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>UNSECURED LOAN</th>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>SECURED LOAN</th>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  NBFC’s : 1.5 % – 2% of loan
                                                  amount + GST
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>NB : ₹ 10,000/‐ + GST</li>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>PB : ₹ 15,000/‐ + GST</li>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  NBFC’s : 1.5 % – 2% + GST
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  MPower : 5% of loan amount
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  VR + SR Fees : Around ₹
                                                  10,000/-
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Private Banks : ₹ 15,000/-
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Stamp Duty Fees : 0.20% of
                                                  loan amount sanction
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Nationalised Banks : ₹
                                                  10,000/-
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Stamp Paper for Mortgage
                                                  Process
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Loan Insurance to be issued of
                                                  Student, Father/Mother
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Loan Insurance to be issued of
                                                  Student, Father/Mother
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>
                                        </Table>
                                      </div>
                                      <br/>
<br/><br/>
<br/>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "white",
                                            backgroundColor: "rgb(63, 140, 203)",
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          Criteria of Loans
                                        </h1>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: 20,
                                        }}
                                      >
                                        <Table
                                          border="1"
                                          style={{ width: "70%" }}
                                        >
                                          <tr>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>UNSECURED LOAN</th>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>SECURED LOAN</th>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  GRE : 300 |TOEFL : 90 | IELTS
                                                  : 6.5
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Academic : 55% (Less Backlogs)
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Parents Annual Income : Above
                                                  ₹5 - ₹ 6 lacs
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Loan sanctioned : 75 – 80%
                                                  against Prop. Value
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  MS /PG Level – Stem &amp; Mgmt
                                                  course
                                                </li>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  UG Diploma / Bachelors ‐ No
                                                  loan
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Loan for all level of courses
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Academic : 60% (Less Backlogs
                                                  with no GAP)
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Average Annual income : ₹ 2.0
                                                  lacs
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>Cibil Score : 700 +</li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>Cibil Score : 650 +</li>
                                              </ul>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Age Criteria : upto 28 years
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>No Age Criteria</li>
                                              </ul>
                                            </td>
                                          </tr>
                                        </Table>
                                      </div>

                                      {/*

//                     
//
//
//
//

*/}<br/>
<br/><br/>
<br/>

                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "white",
                                            backgroundColor: "rgb(63, 140, 203)",
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          Things to remember for getting UL / SL
                                        </h1>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          To avail overseas unsecured loan or
                                          secured loan, the following points
                                          should be considered, due to which
                                          there are chances of not getting the
                                          loan
                                        </p>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: 20,
                                        }}
                                      >
                                        <Table
                                          border="1"
                                          style={{ width: "70%" }}
                                        >
                                          <tr>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>UNSECURED LOAN</th>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>SECURED LOAN</th>
                                          </tr>

                                          <tr>
                                            <td>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  width: "100%",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "flex-start",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <ul>
                                                    <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                      If Parents are
                                                      Agriculturist
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    width: "100%",
                                                    padding: "15px 5px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      paddingRight: "30px",
                                                    }}
                                                  >
                                                    No Loan
                                                  </span>
                                                </div>
                                              </div>
                                            </td>
                                            <td style={{ paddingLeft: 10 }}>
                                              Will get loan
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  width: "100%",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "flex-start",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <ul>
                                                    <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                      If Parents are getting
                                                      rental income
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    width: "100%",
                                                    padding: "15px 5px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      paddingRight: "30px",
                                                    }}
                                                  >
                                                    No Loan
                                                  </span>
                                                </div>
                                              </div>
                                            </td>
                                            <td style={{ paddingLeft: 10 }}>
                                              Will get loan
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  width: "100%",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "flex-start",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <ul>
                                                    <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                      If Parents income is less
                                                      than 5,00,000/-
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    width: "100%",
                                                    padding: "15px 5px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      paddingRight: "30px",
                                                    }}
                                                  >
                                                    No Loan
                                                  </span>
                                                </div>
                                              </div>
                                            </td>
                                            <td style={{ paddingLeft: 10 }}>
                                              Will get loan
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  width: "100%",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "flex-start",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <ul>
                                                    <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                      If Parents are not having
                                                      own residential house
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    width: "100%",
                                                    padding: "15px 5px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      paddingRight: "30px",
                                                    }}
                                                  >
                                                    No Loan
                                                  </span>
                                                </div>
                                              </div>
                                            </td>
                                            <td style={{ paddingLeft: 10 }}>
                                              Will get loan
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  width: "100%",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "flex-start",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <ul>
                                                    <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                      If Student's age is above
                                                      28 years
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    width: "100%",
                                                    padding: "15px 5px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      paddingRight: "30px",
                                                    }}
                                                  >
                                                    No Loan
                                                  </span>
                                                </div>
                                              </div>
                                            </td>
                                            <td style={{ paddingLeft: 10 }}>
                                              Will get loan
                                            </td>
                                          </tr>
                                        </Table>
                                      </div>
                                      <br/>
<br/><br/>
<br/>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "white",
                                            backgroundColor: "rgb(63, 140, 203)",
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          Things to remember for getting UL / SL
                                        </h1>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: 20,
                                        }}
                                      >
                                        <Table
                                          border="1"
                                          style={{ width: "70%" }}
                                        >
                                          <tr>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>UNSECURED LOAN</th>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>SECURED LOAN</th>
                                          </tr>

                                          <tr>
                                            <td>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  width: "100%",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "flex-start",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <ul>
                                                    <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                      If Student's is having
                                                      number of backlogs and
                                                      academic gap between the
                                                      studies
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    width: "100%",
                                                    padding: "15px 5px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      paddingRight: "30px",
                                                      paddingTop: "10px",
                                                    }}
                                                  >
                                                    No Loan
                                                  </span>
                                                </div>
                                              </div>
                                            </td>
                                            <td style={{ paddingLeft: 10 }}>
                                              Will get loan
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  width: "100%",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "flex-start",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <ul>
                                                    <li style={{paddingLeft: 15, paddingRight: 15}}>Cibil Issue</li>
                                                  </ul>
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    width: "100%",
                                                    padding: "15px 5px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      paddingRight: "30px",
                                                    }}
                                                  >
                                                    No Loan
                                                  </span>
                                                </div>
                                              </div>
                                            </td>
                                            <td style={{ paddingLeft: 10 }}>
                                              No loan
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  width: "100%",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "flex-start",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <ul>
                                                    <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                      If Student/C0-borrower is
                                                      not having PAN card
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    width: "100%",
                                                    padding: "15px 5px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      paddingRight: "30px",
                                                    }}
                                                  >
                                                    No Loan
                                                  </span>
                                                </div>
                                              </div>
                                            </td>
                                            <td style={{ paddingLeft: 10 }}>
                                              No loan
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  width: "100%",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "flex-start",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <ul>
                                                    <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                      If Student is planning for
                                                      Bachelors Degree/UG
                                                      Diploma or Certificate
                                                      course
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    width: "100%",
                                                    padding: "15px 5px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      paddingRight: "30px",
                                                    }}
                                                  >
                                                    No Loan
                                                  </span>
                                                </div>
                                              </div>
                                            </td>
                                            <td style={{ paddingLeft: 10 }}>
                                              Will get loan
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  width: "100%",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "flex-start",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <ul>
                                                    <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                      If Student is planning for
                                                      Bachelors/Masters Degree
                                                      course leading to pathway
                                                      or Foundation or
                                                      Integrated course{" "}
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    width: "100%",
                                                    padding: "15px 5px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      paddingRight: "30px",
                                                    }}
                                                  >
                                                    No Loan
                                                  </span>
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <ul>
                                            <li style={{paddingLeft: 15, paddingRight: 15}}>Will get loan (Few Banks)</li></ul>
                                            </td>
                                          </tr>
                                        </Table>
                                      </div>
                                      <br/>
<br/><br/>
<br/>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "white",
                                            backgroundColor: "rgb(63, 140, 203)",
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          Things to remember for getting UL / SL
                                        </h1>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: 20,
                                        }}
                                      >
                                        <Table
                                          border="1"
                                          style={{ width: "70%" }}
                                        >
                                          <tr>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>UNSECURED LOAN</th>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>SECURED LOAN</th>
                                          </tr>

                                          <tr>
                                            <td>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  width: "100%",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "flex-start",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <ul>
                                                    <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                      If Parent is having Rural
                                                      Property (i.e Agriculture
                                                      land, Farm house, Village
                                                      house)
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    width: "100%",
                                                    padding: "15px 5px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      paddingRight: "30px",
                                                      paddingTop: "10px",
                                                    }}
                                                  >
                                                    No Loan
                                                  </span>
                                                </div>
                                              </div>
                                            </td>
                                            <td style={{ paddingLeft: 10 }}>
                                              No loan
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  width: "100%",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "flex-start",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <ul>
                                                    <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                      If Parent is having Urban
                                                      Property(i.e
                                                      House,Flat,Open
                                                      Plot,Blocks,Shop
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    width: "100%",
                                                    padding: "15px 5px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      paddingRight: "30px",
                                                    }}
                                                  >
                                                    Will get Loan
                                                  </span>
                                                </div>
                                              </div>
                                            </td>
                                            <td style={{ paddingLeft: 10 }}>
                                              Will getloan
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  width: "100%",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "flex-start",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <ul>
                                                    <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                      If Property Title Deed
                                                      documents are laminated
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    width: "100%",
                                                    padding: "15px 5px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      paddingRight: "30px",
                                                    }}
                                                  >
                                                    No Loan
                                                  </span>
                                                </div>
                                              </div>
                                            </td>
                                            <td style={{ paddingLeft: 10 }}>
                                              No loan
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  width: "100%",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "flex-start",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <ul>
                                                    <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                      If Property documents are
                                                      not in the name of present
                                                      owner
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    width: "100%",
                                                    padding: "15px 5px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      paddingRight: "30px",
                                                    }}
                                                  >
                                                    No Loan
                                                  </span>
                                                </div>
                                              </div>
                                            </td>
                                            <td style={{ paddingLeft: 10 }}>
                                              No loan
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  width: "100%",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "flex-start",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <ul>
                                                    <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                      If residential area is not
                                                      coming under the
                                                      serviceable area of bank
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    width: "100%",
                                                    padding: "15px 5px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      paddingRight: "30px",
                                                    }}
                                                  >
                                                    No{" "}
                                                  </span>
                                                </div>
                                              </div>
                                            </td>
                                            <td style={{ paddingLeft: 10 }}>
                                              Loan
                                            </td>
                                          </tr>
                                        </Table>
                                      </div>
                                      <br/>
<br/><br/>
<br/>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "white",
                                            backgroundColor: "rgb(63, 140, 203)",
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          Documents related to the Univ./College
                                        </h1>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: 10,
                                        }}
                                      >
                                        <ul>
                                          <li type="1" style={{ padding: 3 }}>
                                            Cost sheet
                                          </li>
                                          <li type="1" style={{ padding: 3 }}>
                                            Offer letter
                                          </li>
                                          <li type="1" style={{ padding: 3 }}>
                                            Course details of college/
                                            university
                                          </li>
                                          <li type="1" style={{ padding: 3 }}>
                                            WHED Details
                                          </li>
                                          <li type="1" style={{ padding: 3 }}>
                                            Ranking of the university
                                          </li>
                                          <li type="1" style={{ padding: 3 }}>
                                            Refund Policy of the university /
                                            college
                                          </li>
                                          <li type="1" style={{ padding: 3 }}>
                                            Application for Pre-Visa
                                            Disbursement for Deviation
                                          </li>
                                          <li type="1" style={{ padding: 3 }}>
                                            Living Expenses Proof ex: GIC, FTS,
                                            Block Account along with visa norms
                                          </li>
                                        </ul>
                                      </div>
                                      <br/>
<br/><br/>
<br/>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "white",
                                            backgroundColor: "rgb(63, 140, 203)",
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          Income Related Documents
                                        </h1>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: 20,
                                        }}
                                      >
                                        <Table
                                          border="1"
                                          style={{ width: "70%" }}
                                        >
                                          <tr>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>For Salaried Person</th>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>For Bussiness Person</th>
                                          </tr>

                                          <tr>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Income Tax Returns -2 yrs
                                                </li>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>Form No. 16 - 2 yrs</li>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>Salary Slips - 3 months</li>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Salary Passbook - 6 months
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Income Tax Returns -2 yrs
                                                </li>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>Computation of Income</li>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>Balance Sheet</li>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>Profit & Loss Account</li>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Bank statement - 6 months
                                                </li>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Bussiness Regn Certificate
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>

                                          <tr>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>For Pensioner</th>
                                            <th style={{ textAlign: "center", fontWeight:'bold'}}>For Rental Income</th>
                                          </tr>
                                          <tr>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>Pension Order</li>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Pension Passbook - 12 months
                                                </li>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Income Tax Returns (Optional)
                                                </li>
                                              </ul>
                                            </td>
                                            <td>
                                              <ul>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>Rent Agreement</li>
                                                <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                  Bank Statement reflecting rent
                                                  recieved - months
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>
                                        </Table>
                                      </div>

                                      {/*

//
//
//
//
//
//
//
//

*/}<br/>
<br/><br/>
<br/>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "white",
                                            backgroundColor: "rgb(63, 140, 203)",
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          Property Related Documents
                                        </h1>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            border: "1px solid",
                                            width: "70%",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                width: "100%",
                                              }}
                                            >
                                              <Table border="1">
                                                <tr>
                                                  <th style={{ textAlign: "center", fontWeight:'bold'}}>
                                                    Urban Property -
                                                    (Res./Commercial)
                                                  </th>
                                                  <th style={{ textAlign: "center", fontWeight:'bold'}}>
                                                    In case of Apartment (Flat /
                                                    Shop)
                                                  </th>
                                                  <th style={{ textAlign: "center", fontWeight:'bold'}}>
                                                    Improvement Trust Property
                                                  </th>
                                                  <th style={{ textAlign: "center", fontWeight:'bold'}}>
                                                    MHADA /CIDCO/ Nazul Prop.
                                                  </th>
                                                </tr>

                                                <tr>
                                                  <td>
                                                    <ul>
                                                      <li style={{paddingLeft: 15, paddingRight: 15}}>Sale Deed</li>
                                                      <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                        Previous Sale Deed
                                                      </li>
                                                      <li style={{paddingLeft: 15, paddingRight: 15}}>Agreement to Sale</li>
                                                      <li style={{paddingLeft: 15, paddingRight: 15}}>Tax Reciept</li>
                                                      <li style={{paddingLeft: 15, paddingRight: 15}}>Mutation copy</li>
                                                      <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                        Sanctioned layout Map
                                                      </li>
                                                      <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                        Building Permit letter
                                                      </li>
                                                      <li style={{paddingLeft: 15, paddingRight: 15}}>Electric Bill</li>
                                                    </ul>
                                                  </td>
                                                  <td>
                                                    <ul>
                                                      <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                        Deed of Declaration
                                                      </li>
                                                      <li style={{paddingLeft: 15, paddingRight: 15}}>Power of Attorney</li>
                                                      <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                        Allotment/Possession
                                                        Letter
                                                      </li>
                                                      <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                        Soc Regn Certificate
                                                      </li>
                                                      <li style={{paddingLeft: 15, paddingRight: 15}}>NOC from Society</li>
                                                      <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                        Occupancy Certificate
                                                      </li>
                                                      <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                        Shares Certificate
                                                      </li>
                                                    </ul>
                                                  </td>
                                                  <td>
                                                    <ul>
                                                      <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                        Valid Lease Deed or
                                                      </li>
                                                      <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                        Non-Agriculture
                                                        Certificate
                                                      </li>
                                                      <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                        RL - Regularization
                                                        Order
                                                      </li>
                                                    </ul>
                                                  </td>
                                                  <td>
                                                    <ul>
                                                      <li style={{paddingLeft: 15, paddingRight: 15}}>Valid Lease Deed</li>
                                                      <li style={{paddingLeft: 15, paddingRight: 15}}>NOC</li>
                                                      <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                        Allotment/Possession
                                                        Letter
                                                      </li>
                                                      <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                        Soc Regn Certificate
                                                      </li>
                                                    </ul>
                                                  </td>
                                                </tr>
                                              </Table>
                                            </div>
                                            <div>
                                              <img
                                                src="/images/property.png"
                                                width="150px"
                                                height="300px"
                                              />
                                            </div>
                                          </div>

                                          <div>
                                            <Table
                                              border="1"
                                              style={{ width: "100%" }}
                                            >
                                              <tr>
                                                <td>
                                                  <ul>
                                                    <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                      Rural Property is not
                                                      accepted. Ex: Agricultural
                                                      land, Farm house, Village
                                                      house, Open Plot.
                                                    </li>
                                                    <li style={{paddingLeft: 15, paddingRight: 15}}>
                                                      Grandparents Property:
                                                      WILL copy is
                                                      mandatory,Legal heirs will
                                                      be considered as
                                                      Co-Applicants or
                                                      Registered document of
                                                      Relinquishment of Rights.
                                                    </li>
                                                  </ul>
                                                </td>
                                              </tr>
                                            </Table>
                                          </div>
                                        </div>
                                      </div>
                                      <br/>
<br/><br/>
<br/>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "white",
                                            backgroundColor: "rgb(63, 140, 203)",
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          Things to be checked before applying
                                          for loan
                                        </h1>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: 20,
                                        }}
                                      >
                                        <Table
                                          border="1"
                                          style={{ width: "50%" }}
                                        >
                                          <tr>
                                            <td
                                              style={{
                                                padding: 5,
                                                width: "200px",
                                              }}
                                            >
                                              <b>Loan Amount :</b>
                                            </td>
                                            <td style={{ padding: 5 }}>
                                              The loan amount renges from 7.5
                                              lakhs to a maxinmum of 1.50 crores
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                padding: 5,
                                                width: "200px",
                                              }}
                                            >
                                              <b>Rate of interest :</b>
                                            </td>
                                            <td style={{ padding: 5 }}>
                                              It ranges from 9.0% to 15% per
                                              annum
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                padding: 5,
                                                width: "200px",
                                              }}
                                            >
                                              <b>Loan Tenure :</b>
                                            </td>
                                            <td style={{ padding: 5 }}>
                                              The minimum duration starts from 5
                                              years to a maximum of 20 years
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                padding: 5,
                                                width: "200px",
                                              }}
                                            >
                                              <b>Moratorium Period :</b>
                                            </td>
                                            <td style={{ padding: 5 }}>
                                              Bank gives 6 or 12 months of time
                                              to search for the job after
                                              completion of the course duration
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                padding: 5,
                                                width: "200px",
                                              }}
                                            >
                                              <b>Processing charges :</b>
                                            </td>
                                            <td style={{ padding: 5 }}>
                                              For Nationalised / Private Banks
                                              it's around 15,000 to 20,000 and
                                              for Private institutions its 1.5%
                                              to 2% of loan amount
                                            </td>
                                          </tr>
                                        </Table>
                                      </div>
                                      <br/>
<br/><br/>
<br/>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "white",
                                            backgroundColor: "rgb(63, 140, 203)",
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          CREDIT / CIBIL SCORE
                                        </h1>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: 5,
                                        }}
                                      >
                                        <ul>
                                          <li type='disc' style={{ padding: 3 }}>
                                            Credit / CIBIL Score is a
                                            three-digit numeric summary of your
                                            credit history found in the CIBIL
                                            Report
                                          </li>
                                          <li type='disc' style={{ padding: 3 }}>
                                            CIBIL score will be in the range of
                                            300 to 900.
                                          </li>
                                          <li type='disc' style={{ padding: 3 }}>
                                            Bank check student, father, mother
                                            or any other sponsorer CIBIL score
                                            before approving the loan.
                                          </li>
                                          <li type='disc' style={{ padding: 3 }}>
                                            For Education loan, credit score
                                            should be -1 or above 600
                                          </li>
                                          <li type='disc' style={{ padding: 3 }}>
                                            If there is any delay in EMI or EMI
                                            bounce frequently then loan case
                                            will be not accepted by the bank.
                                          </li>
                                        </ul>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: 5,
                                        }}
                                      >
                                        <img src="/images/credit.png" />
                                      </div>
                                      <br/>
<br/><br/>
<br/>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "white",
                                            backgroundColor: "rgb(63, 140, 203)",
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          PAN CARD
                                        </h1>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: 5,
                                        }}
                                      >
                                        <ul>
                                          <li type='disc' style={{ padding: 3 }}>
                                            Student PAN card : Mandatory
                                          </li>
                                          <li type='disc' style={{ padding: 3 }}>
                                            Parents / Sponsorer PAN card :
                                            Mandatory
                                          </li>
                                          <li type='disc' style={{ padding: 3 }}>
                                            MINOR PAN card :
                                          </li>
                                          <ul>
                                            <li style={{paddingLeft: 15, paddingRight: 15}}>
                                            <b>* &nbsp;</b>It is not accepted if student age
                                              is above 18
                                            </li>
                                            <li style={{paddingLeft: 15, paddingRight: 15}}>
                                              <b>* &nbsp;</b>If student age is under 18 and
                                              planning for UG degree then it is
                                              accepted but after age 18, he/she
                                              has to submit Major Pan Card
                                            </li>
                                          </ul>
                                        </ul>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: 5,
                                        }}
                                      >
                                        <img src="/images/pan_card.png" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Container>
  );
}
