/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {postDataAndImage,postData, postDataLocal} from '../FetchServicesLocal';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import createSvgIcon from '@material-ui/core';
import Header from './Header';
import Footer from './Footer';
import PropTypes from 'prop-types';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';
  





const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
input:{
  padding:'0px 0px',
},
  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:16,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },
widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));


async function loginUser(credentials) {
  return fetch('http://localhost:3000/Login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
 }
 

export default function Login(props, setToken) {
  const classes = useStyles();
  //const [firstName,setFirstName]=React.useState('')
  //const [lastName,setLastName]=React.useState('')
  const [email, setEmail]=React.useState('')
  const [password,setPassword]=React.useState('')
  //const [verifyPassword,setVerifyPassword]=React.useState('')
  const [messages, setMessages ]=React.useState('')
  //const [picture, setPicture] = React.useState("");
  //const [getpicturePath, setPicturePath] = React.useState("");

  const handleLogin = async(e)=>{
    let body={'email':email, 'password':password}
    localStorage.setItem('user',JSON.stringify(body))
    localStorage.setItem('emaill',JSON.stringify(email))
    

    var result = await postDataLocal("profilebuilder/chkadminlogin", body)
    console.log(result.status);
    if(result.status){
      // console.log("Inside Body",result.status);
      // console.log("dataaaaaaaaaaaaaaaaaaaaaaa",result.data[0]);
      

      localStorage.setItem('student',JSON.stringify(result.data[0]))
      
      
      props.history.push({pathname:'/Dashboard'})
    }
    else{
      setMessages(<font color="red">Invalid Email or Password</font>)
    }
}
//------------------------------------------------------------------------//

  // const handlePicture=(event)=>{
  //   setPicturePath(URL.createObjectURL(event.target.files[0]))
  //   setPicture(event.target.files[0])
  //  }

  //  const handleVerifyPassword=(e)=>{
  //   setVerifyPassword(e.target.value)
  //   if(password===e.target.value){
  //     setStatus("Password Matched")
  //   }
  //   else{
  //     setStatus("Password not matched")
  //   }
  // }


//   const addNewRecord=async()=>{
// if(picture=='')
// {
//     var body={'firstName':firstName,
//     'lastName':lastName, 
//     'emailId':emailId, 
//     'password':password,
//     'status':"Not Approved",
//     'picture':"Not Found"}
//     var result=await postData('selfregister/addNewRecord',body)
//     if(result){
//       await setMessages("Agent Succefully Created")

//       var body={
//         'emailid':emailId,
//         'password':password
//        }
// var resultt = await  postData('selfregister/checkagentsignin',body)

// if (resultt.RESULT)
// {

// localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
// props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
// }
// else
// {
// alert("Failed To Created Account")
// }

// await setFirstName('')
// await setLastName('')
// await setEmailId('')
// await setPassword('')
// await setVerifyPassword('')
// await setPicturePath('')
// }
// else{setMessages("Faild to Submit")}

// }

// else{
//    var formData=new FormData()
//    formData.append('firstName',firstName)
//    formData.append('lastName',lastName)
//    formData.append('emailId',emailId)
//    formData.append('password',password)
//    formData.append('picture',picture)
//    formData.append('status', "Not Approve")
  
//    var config={headers:{'content-type':'multipart/form-data'}}  
//     var result=await postDataAndImage('selfregister/addNewRecordPic',formData,config)


//     if(result){
//       await setMessages("Agent Succefully Created")

//       var body={
//         'emailid':emailId,
//         'password':password
//        }
// var resultt = await  postData('selfregister/checkagentsignin',body)

// if (resultt.RESULT)
// {

// localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
// props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
// }
// else
// {
// alert("Failed To Created Account")
// }

// await setFirstName('')
// await setLastName('')
// await setEmailId('')
// await setPassword('')
// await setVerifyPassword('')
// await setPicturePath('')
// }
// else{setMessages("Faild to Submit")}
//     }
//     }


    const [state, setState] = React.useState({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  
    const { vertical, horizontal, open } = state;
  
    const handleClick = (newState) => () => {
      setState({ open: true, ...newState });
    };
  
    const handleClose = () => {
      setState({ ...state, open: false });
    };
   
   
    console.log("History ==  ",props)
  return (
   <Container className={classes.widths}>
    
    <Header/>
    <div className="login-area default-padding" style={{backgroundColor:'#f3f3f3'}}>
        <div className="container">
            <div className="row" >
                <div className="col-md-12">
               
                <div className="col-md-6">
                  
                <Container style={{paddingLeft:0,paddingRight:0,}}>
      
      <CssBaseline />
      
      <MDBContainer style={{paddingLeft:0,paddingRight:0,}}>
      <MDBRow>
        <MDBCol md="9" >
          <MDBCard>
            <MDBCardBody>
              <MDBCardHeader className="form-header deep-blue-gradient rounded" style={{color: "white", textAlign:"center",padding:'2px 2px',}}>
                <h3 className="my-2">
                  <MDBIcon icon="lock" /> Login
                </h3>
              </MDBCardHeader>
              <div className={classes.paper}>
       
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            
            <Grid item xs={12}>
              <TextField
             // InputLabelProps={{style: {fontSize: 12}}}
                variant="outlined"
                // required
                fullWidth
                id="email"
                value={email}
                label="Email"
                name="email"
                autoComplete="email"
                onChange={(event)=>setEmail(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
             // InputLabelProps={{style: {fontSize: 12}}}
                variant="outlined"
                // required
                fullWidth
                id="password"
                value={password}
                label="Password"
                name="password"
                type="password"
                autoComplete="password"
                onChange={(event)=>setPassword(event.target.value)}
              />
            </Grid>

  <Grid item xs={12} sm={6}>
          <Checkbox style ={{color: "#3d8ecd",}}  variant="outlined"   fullWidth type="checkbox"/>
 <label>Remember me</label>

            </Grid> 
   <Grid item xs={12} sm={6} style={{marginTop:10,flexBasis:'auto'}}>
<a href="/ForgotPassword" style={{float:'right',}}>Forgot Password?</a>
            </Grid> 
          </Grid>
         

<div className="text-center mt-3">
<div className="blog-area">
<div className="info">
<div className="content" style={{padding:0,position:'static'}}>
<a href="#" onClick={()=>handleLogin()} style={{color:'#000',width:'100%',textAlign:'center'}}><i className="fa fa-lock" style={{color:'#000'}}></i> Login</a>
</div>
</div>
</div>  
<Snackbar open={open} autoHideDuration={2000} 
anchorOrigin={{ vertical, horizontal }} 
key={vertical + horizontal} 
onClose={handleClose}>
<Alert onClose={handleClose} severity="success">This is a success login!</Alert>
 </Snackbar>

       </div>
       <br/>
       <br/>
        <Typography>
        {messages}
        </Typography>
 
        <br/>
        <Grid container spacing={2}>
        <Grid item xs={12} style={{textAlign:'center'}}>

 <label>Don't have an account?<a href="/CreateAccount" style={{float:'right',}}>&nbsp;&nbsp; Register</a> </label>

            </Grid> 

            </Grid>

        </form>
      </div>
             
            </MDBCardBody>
          </MDBCard>
       </MDBCol>
       </MDBRow>
    </MDBContainer>
      
     
    </Container>

                </div>
                <div className="col-md-6 " style={{display:'flex'}}>
                <img style={{flex:1,display:'flex',height:360,width:540,marginTop:40}} class="login_image" src="assets/img/login5.jpg" alt="Login" data-original-title="" title="" />
                </div>
</div>
</div>
</div>
</div>
<Footer/>
    </Container>
  );
}