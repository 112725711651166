import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { postData } from "../FetchServices";
import Header from "./Header";
import Footer from "./Footer";
import Grid from "@material-ui/core/Grid";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    // margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
}));

export default function UniversityDisplay() {
  const classes = useStyles();

  // const parameter = useParams();
  const location = useLocation();
  const history = useHistory();
  const [getName, setName] = useState({});
  const [clickDisplay,setClickDisplay]=useState(false)
  const [showData,setShowData]=React.useState('USA')
  const [allProgramList,setAllProgramList]=useState([])
  const [bracketName,setBracketName]=useState('Please Select A University')
  useEffect(function () {
    if (!location.state) {
      history.replace("/UndergraduatePrograms");
    } else {
      fetchDataById();
    }
  }, []); 

  const fetchDataById = async () => {
    console.log("location======>>>>>>>", location)

    console.log("location.state======>>>>>>>", location.state)
    console.log("location.state.id======>>>>>>>", location.state.id)

    // console.log("TRANSFERREDDATA:,,,,,",location.state)
    let body = { ID : location.state.id };
    let result = await postData("abroad/fetchbyid", body);
    // setName(result[0]);
    fetchAllPrograms(result[0]);
  };
  const fetchAllPrograms=async (a)=>{
    console.log("kya mila>>>>>>>>>>>>>>", a.name)
    let body = { name :a.name };
    let result = await postData("abroad/fetchbyname", body);
    setAllProgramList(result.data)
  }
  
  const showDetailedByUni=(perfectid,bracketname)=>{
    setClickDisplay(true)
    setBracketName(bracketname)
    allProgramList.map((item)=>{
      if(item.university_Uni_ID==perfectid){
        setName(item);
      }
    })
  }

  if (!location.state) {
    history.replace("/UndergraduatePrograms");
    return null;
  } else {
    return (
      <Container className={classes.widths}>
        <Header />

        <CssBaseline />
        <div className="login-area default-padding" style={{ paddingTop: 20 }}>
          <div className="">
            <div className="row">
              <div className="col-md-12">
                
              <div
                className="blog-area single full-blog left-sidebar full-blog default-padding"
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="container"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <div className="row">
                    <div className="blog-items">
                        <div class="sidebar col-md-3">
                        <aside >
                          <div class="sidebar-item category"  style={{position:'sticky'}}>
                            <div class="title">
                              <h4>Universities</h4>
                            </div>
                            <div class="sidebar-info" >
                              <ul>
                                {allProgramList.map((item)=>(
                              <li>
                                  <a style={{color: "#3f8ccb",display:'flex',marginBottom:2}} onClick={()=>showDetailedByUni(item.university_Uni_ID,item.university_name)}>{item.university_name}</a>
                                </li>
))}
                                
                                                              
                              </ul>
                            </div>
                          </div>
                        </aside>
                      </div>
                      
                      <div className="blog-content col-md-9">
                        <div className="content-items">
                          <div className="single-item">
                            <div className="item">
                              <div className="info">
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
<div
                                    className="content"
                                    style={{ paddingBottom: 0, width:1050}}
                                  >
                            {clickDisplay==true?(<>        
                                    <h2 style={{color:'black',display:'flex'}}>
                                      <span>
                                        <center>Programming Details </center>
                                      </span>

                                    </h2>
                                    <span style={{fontSize:20,marginBottom:15,color: "#3f8ccb"}}>{bracketName}</span>


                                    <div class="info">
{getName.course_type==0?(

                                                        <h6
                                                          style={{
                                                            fontSize: 15,
                                                            color: "black",
                                                          }}
                                                        >
                                                          
                                                         Undergraduate Program
                                                        </h6>

):(<></>)}
{getName.course_type==1?(

<h6
  style={{
    fontSize: 15,
    color: "black",
  }}
>
  
 Graduate Program
</h6>

):(<></>)}
{getName.course_type==2?(

<h6
  style={{
    fontSize: 15,
    color: "black",
  }}
>
  
 Doctoral Program
</h6>

):(<></>)}

{getName.course_type==3?(

<h6
  style={{
    fontSize: 15,
    color: "black",
  }}
>
  Certificate/Diploma Program
</h6>

):(<></>)}






                                                      </div>
                                    <h4>
                                      <span>
                                        {getName.name}
                                      </span>

                                    </h4>
                                    <br />
                                  
                                  
                                                    
                                                      
                                                      
                                    {/* <p
                                      style={{
                                        fontSize: 14,
                                        color: "#212121",
                                        textAlign: "justify",
                                      }}
                                    >
                                      {getName.AdditionalEntryRequirementsDescprition__c}
                                      
                                    </p> */}
                                    <br />


                                    <div class="info" style={{display:'flex'}}>
                                                      <Grid container spacing={2}>
                                                      <Grid item xs={12} sm={6}>
                                                        <h5
                                                          style={{
                                                            fontSize: 18,
                                                            // fontWeight: "bold",
                                                            color: '#000',
                                                          }}
                                                        >
                                                          <strong>
                                                          Fee Information :{" "}
                                                          </strong>{" "}
                                                          {
                                                            getName.AdditionalFeeInfo__c 
                                                          }
                                                          {/* Avg Living Exp. */}
                                                        </h5>
                                                      </Grid>
                                                      
                                                      <Grid item xs={12} sm={6}>
                                                        <h5
                                                          style={{
                                                            fontSize: 18,
                                                            // fontWeight: "bold",
                                                            color: "#000",
                                                            marginLeft:30
                                                          }}
                                                        >
                                                          <strong>
                                                          Course Degree Type :{" "}
                                                          </strong>{" "}
                                                          {
                                                            getName.CourseDegreeType__c
                                                          }
                                                          {/* Avg Living Exp. */}
                                                        </h5>
                                                      </Grid>
                                                    
                                                      </Grid>
                                                      </div>
                                                      <br/>
                                                      {/* <br/> */}

                                                      <div class="info" style={{display:'flex'}}>
                                                      <Grid container spacing={2}>
                                                      <Grid item xs={12} sm={6}>
                                                        <h5
                                                          style={{
                                                            fontSize: 18,
                                                            // fontWeight: "bold",
                                                            color: '#000',
                                                          }}
                                                        >
                                                          <strong>
                                                          Course Duration :{" "}
                                                          </strong>{" "}
                                                          {
                                                            getName.CourseDuration__c 
                                                          }
                                                          {/* Avg Living Exp. */}
                                                        </h5>
                                                      </Grid>
                                                      
                                                      <Grid item xs={12} sm={6}>
                                                        <h5
                                                          style={{
                                                            fontSize: 18,
                                                            // fontWeight: "bold",
                                                            color: "#000",
                                                            marginLeft:30
                                                          }}
                                                        >
                                                          <strong>
                                                          Location :{" "}
                                                          </strong>{" "}
                                                          {
                                                            getName.CourseLocation__c
                                                          }
                                                          {/* Avg Living Exp. */}
                                                        </h5>
                                                      </Grid>
                                                    
                                                      </Grid>
                                                      </div>
                                                      <br/>
                                                      {/* <br/> */}


                                                      <div class="info" style={{display:'flex'}}>
                                                      <Grid container spacing={2}>
                                                      <Grid item xs={12} sm={6}>
                                                        <h5
                                                          style={{
                                                            fontSize: 18,
                                                            // fontWeight: "bold",
                                                            color: '#000',
                                                          }}
                                                        >
                                                          <strong>
                                                          Study Mode :{" "}
                                                          </strong>{" "}
                                                          {
                                                            getName.CourseStudyMode__c 
                                                          }
                                                          {/* Avg Living Exp. */}
                                                        </h5>
                                                      </Grid>
                                                      
                                                      <Grid item xs={12} sm={6}>
                                                        <h5
                                                          style={{
                                                            fontSize: 18,
                                                            // fontWeight: "bold",
                                                            color: "#000",
                                                            marginLeft:30
                                                          }}
                                                        >
                                                          <strong>
                                                          Currency :{" "}
                                                          </strong>{" "}
                                                          {
                                                            getName.Currency__c
                                                          }
                                                          {/* Avg Living Exp. */}
                                                        </h5>
                                                      </Grid>
                                                    
                                                      </Grid>
                                                      </div>
                                                      <br/>
                                                      {/* <br/> */}


                                                      <div class="info" style={{display:'flex'}}>
                                                      <Grid container spacing={2}>
                                                      <Grid item xs={12} sm={6}>
                                                        <h5
                                                          style={{
                                                            fontSize: 18,
                                                            // fontWeight: "bold",
                                                            color: '#000',
                                                          }}
                                                        >
                                                          <strong>
                                                          Degree Level :{" "}
                                                          </strong>{" "}
                                                          {
                                                            getName.DegreeLevel__c 
                                                          }
                                                          {/* Avg Living Exp. */}
                                                        </h5>
                                                      </Grid>
                                                      
                                                      <Grid item xs={12} sm={6}>
                                                        <h5
                                                          style={{
                                                            fontSize: 18,
                                                            // fontWeight: "bold",
                                                            color: "#000",
                                                            marginLeft:30
                                                          }}
                                                        >
                                                          <strong>
                                                          Status :{" "}
                                                          </strong>{" "}
                                                          {
                                                            getName.Status__c
                                                          }
                                                          {/* Avg Living Exp. */}
                                                        </h5>
                                                      </Grid>
                                                    
                                                      </Grid>
                                                      </div>
                                                      <br/>
                                                      {/* <br/> */}

                               
                                                        <div class="info">
                                                        <span style={{
                                                            fontSize: 18,
                                                            // fontWeight: "bold",
                                                            color: "#000",
                                                           
                                                          }}>
                                                            <strong>    
                                                        Summary :{" "}
                                                        </strong>
                                                        
                                                          </span>
                                                          <span style={{fontSize:17,marginLeft:8}}>
                                                          {
                                                            getName.CourseSummary__c
                                                          }
                                                          </span>
                                                          {/* Avg Living Exp. */}
                                                        
                                                      </div>
                                                      
                                                      <div class="info" style={{display:'flex',marginTop:30}}>
                                                      <Grid container spacing={2}>
                                                      <Grid item xs={12} sm={6}>
                                                        <h5
                                                          style={{
                                                            fontSize: 15,
                                                            // fontWeight: "bold",
                                                            color: '#191970',
                                                          }}
                                                        >
                                                          <strong>
                                                            Start Date :{" "}
                                                          </strong>{" "}
                                                          {
                                                            getName.Start_Date__c
                                                          }
                                                          {/* Avg Living Exp. */}
                                                        </h5>
                                                      </Grid>
                                                      
                                                      <Grid item xs={12} sm={6}>
                                                        <h5
                                                          style={{
                                                            fontSize: 15,
                                                            // fontWeight: "bold",
                                                            color: "red",
                                                          }}
                                                        >
                                                          <strong>
                                                            End Date :{" "}
                                                          </strong>{" "}
                                                          {
                                                            getName.End_Date__c
                                                          }
                                                          {/* Avg Living Exp. */}
                                                        </h5>
                                                      </Grid>
                                                    
                                                      </Grid>
                                                      </div>
                                                      <br/>
                                                      <br/>

                                                      <div class="info">
                                                      <Grid container spacing={2}>
                                                      <Grid item xs={12} sm={6}>
                                                        <h5
                                                          style={{
                                                            fontSize: 15,
                                                            // fontWeight: "bold",
                                                            color: "black",
                                                          }}
                                                        >
                                                          <strong>
                                                            Duolingo Details :{" "}
                                                          </strong>{" "}
                                                          {
                                                            getName.EnglishLanguageDuolingoDetails__c
                                                          }
                                                          {/* Avg Living Exp. */}
                                                        </h5>
                                                      </Grid>
                                                      <Grid item xs={12} sm={6}>
                                                        <h5
                                                          style={{
                                                            fontSize: 15,
                                                            // fontWeight: "bold",
                                                            color: "black",
                                                          }}
                                                        >
                                                          <strong>
                                                            Duolingo Grade :{" "}
                                                          </strong>{" "}
                                                          {
                                                            getName.EnglishLanguageDuolingoGrade__c
                                                          }
                                                          {/* Avg Living Exp. */}
                                                        </h5>
                                                        </Grid>
                                                      </Grid>
                                                      </div>


                                                      <div class="info">
                                                      <Grid container spacing={2}>
                                                      <Grid item xs={12} sm={6}>
                                                        <h5
                                                          style={{
                                                            fontSize: 15,
                                                            // fontWeight: "bold",
                                                            color: "black",
                                                          }}
                                                        >
                                                          <strong>
                                                            IELTS Details :{" "}
                                                          </strong>{" "}
                                                          {
                                                            getName.EnglishLanguageIELTSDetails__c
                                                          }
                                                          {/* Avg Living Exp. */}
                                                        </h5>
                                                      </Grid>
                                                      <Grid item xs={12} sm={6}>
                                                        <h5
                                                          style={{
                                                            fontSize: 15,
                                                            // fontWeight: "bold",
                                                            color: "black",
                                                          }}
                                                        >
                                                          <strong>
                                                            IELTS Grade :{" "}
                                                          </strong>{" "}
                                                          {
                                                            getName.EnglishLanguageIELTSGrade__c
                                                          }
                                                          {/* Avg Living Exp. */}
                                                        </h5>
                                                        </Grid>
                                                        </Grid>
                                                      </div>


                                                      <div class="info">
                                                      <Grid container spacing={2}>
                                                      <Grid item xs={12} sm={6}>
                                                        <h5
                                                          style={{
                                                            fontSize: 15,
                                                            // fontWeight: "bold",
                                                            color: "black",
                                                          }}
                                                        >
                                                          <strong>
                                                            PTE Details :{" "}
                                                          </strong>{" "}
                                                          {
                                                            getName.EnglishLanguagePTEDetails__c
                                                          }
                                                          {/* Avg Living Exp. */}
                                                        </h5>
                                                      </Grid>
                                                      
                                                      <Grid item xs={12} sm={6}>
                                                        <h5
                                                          style={{
                                                            fontSize: 15,
                                                            // fontWeight: "bold",
                                                            color: "black",
                                                          }}
                                                        >
                                                          <strong>
                                                            PTE Grade :{" "}
                                                          </strong>{" "}
                                                          {
                                                            getName.EnglishLanguagePTEGrade__c
                                                          }
                                                          {/* Avg Living Exp. */}
                                                        </h5>
                                                        </Grid>
                                                        </Grid>
                                                      </div>


                                                      <div class="info">
                                                        
                                                      <Grid container spacing={2}>
                                                      <Grid item xs={12} sm={6}>
                                                        <h5
                                                          style={{
                                                            fontSize: 15,
                                                            // fontWeight: "bold",
                                                            color: "black",
                                                          }}
                                                        >
                                                          <strong>
                                                            TOEFL Details :{" "}
                                                          </strong>{" "}
                                                          {
                                                            getName.EnglishLanguageTOEFLDetails__c
                                                          }
                                                          {/* Avg Living Exp. */}
                                                        </h5>
                                                      </Grid>
                                                      <Grid item xs={12} sm={6}>
                                                        <h5
                                                          style={{
                                                            fontSize: 15,
                                                            // fontWeight: "bold",
                                                            color: "black",
                                                          }}
                                                        >
                                                          <strong>
                                                            TOEFL Grade :{" "}
                                                          </strong>{" "}
                                                          {
                                                            getName.EnglishLanguageTOEFLGrade__c
                                                          }
                                                          {/* Avg Living Exp. */}
                                                        </h5>
                                                        </Grid>
                                                        </Grid>
                                                      </div>
                                                      
                                                        <br/>
                                                        <br/>
                                 </>):(
                                 <div style={{display:'flex',flexDirection:'column'}}>                          
                                
                                   <h4 style={{display:'flex',marginLeft:100}}>
                                     Choose A University In The Box To The Left.
                                   </h4>
                                
                                   <div style={{display:'flex',marginLeft:50}}>
                                   <img src="assets/img/whichuni.jpg" />
                                   </div>
                                
                                </div>
                                    )}
                                  </div>
                                  
                                </div>
                          
                                <div
                                  className="content"
                                  style={{ paddingBottom: 0 }}
                                >
                                  <div className="col-md-12">
                                    <div
                                      className="content"
                                      style={{ paddingBottom: 0 }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

               
                <section>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>

                </section>

                {/* <div
                  class="features-area default-padding-bottom fixed bottom-less bg-color text-light"
                  style={{ marginTop: "50" }}
                >
                  <div class="container">
                    <div className="weekly-top-items">
                      <div className="col-md-12">
                        <div
                          className="top-courses"
                          style={{ paddingRight: 0 }}
                        >
                          <div
                            className="heading"
                            style={{ paddingBottom: 15 }}
                          >
                            <h4 style={{ color: "#3f8ccb" }}>
                              STUDY ABROAD PROGRAMS & UNIVERSITY{" "}
                              <strong></strong>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="">
                      <div class="features">
                        <div
                          class="equal-height col-md-3 col-sm-6"
                          style={{ height: 330 }}
                        >
                          <div class="item mariner">
                            <a href="#">
                              <div class="icon">
                                <icon
                                  style={{
                                    color: "white",
                                    fontSize: "4rem",
                                    marginBottom: 10,
                                  }}
                                  class="fa fa-university"
                                ></icon>
                              </div>
                              <div class="info">
                                <h1>947</h1>
                                <h4>Universities</h4>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div
                          class="equal-height col-md-3 col-sm-6"
                          style={{ height: 330 }}
                        >
                          <div class="item brilliantrose">
                            <a href="#">
                              <div class="icon">
                                <icon
                                  style={{
                                    color: "white",
                                    fontSize: "4rem",
                                    marginBottom: 10,
                                  }}
                                  class="fa fa-graduation-cap"
                                ></icon>
                              </div>
                              <div class="info">
                                <h1>56184</h1>
                                <h4>Programs</h4>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div
                          class="equal-height col-md-3 col-sm-6"
                          style={{ height: 330 }}
                        >
                          <div class="item casablanca">
                            <a href="#">
                              <div class="icon">
                                <icon
                                  style={{
                                    color: "white",
                                    fontSize: "4rem",
                                    marginBottom: 10,
                                  }}
                                  class="fa fa-flag"
                                ></icon>
                              </div>
                              <div class="info">
                                <h1>11</h1>
                                <h4>Countries</h4>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div
                          class="equal-height col-md-3 col-sm-6"
                          style={{ height: 330 }}
                        >
                          <div class="item malachite">
                            <a href="#">
                              <div class="icon">
                                <icon
                                  style={{
                                    color: "white",
                                    fontSize: "4rem",
                                    marginBottom: 10,
                                  }}
                                  class="fa fa-users"
                                ></icon>
                              </div>
                              <div class="info">
                                <h1>28052</h1>
                                <h4>Students</h4>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="weekly-top-items carousel-shadow default-padding-bottom">
                  <div className="container">
                    <div className="row" style={{ display: "block" }}>
                      <div className="col-md-12">
                        <div className="top-courses">
                          <div className="heading">
                            <h4>
                              Trending Programs <strong></strong>
                            </h4>
                          </div>
                          <div className="top-course-items top-courses-carousel owl-carousel owl-theme">
                            <div className="item">
                              <div className="thumb">
                                <span className="badge">New</span>
                                <img
                                  src="assets/img/courses/1.jpg"
                                  alt="Thumb"
                                />
                                <div className="overlay">
                                  <a href="#">
                                    <img
                                      src="assets/img/team/1.jpg"
                                      alt="Thumb"
                                    />
                                  </a>
                                  <ul>
                                    <li>
                                      <i className="fas fa-clock"></i> 04:15:38
                                    </li>
                                    <li>
                                      <i className="fas fa-list-ul"></i> 18
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="info">
                                <div className="meta">
                                  <ul>
                                    <li>
                                      <a href="#">Education</a>
                                      <a href="#">Tech</a>
                                    </li>
                                    <li>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star-half-alt"></i>
                                      <span>(1k)</span>
                                    </li>
                                  </ul>
                                </div>
                                <h4>
                                  <a href="#">data science and software</a>
                                </h4>
                                <p>
                                  Wisdom praise things she before. Be mother
                                  itself vanity favour do me of. Begin sex was
                                  power joy after had walls miles.
                                </p>
                                <div className="footer-meta">
                                  <div className="blog-area">
                                    <div className="info">
                                      <div
                                        className="content"
                                        style={{ padding: 0 }}
                                      >
                                        <a
                                          href="#"
                                          style={{
                                            padding: "10px 30px",
                                            color: "#000",
                                          }}
                                        >
                                          <i className="fa fa-bookmark"></i>{" "}
                                          Book Now
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="item">
                              <div className="thumb">
                                <img
                                  src="assets/img/courses/2.jpg"
                                  alt="Thumb"
                                />
                                <div className="overlay">
                                  <a href="#">
                                    <img
                                      src="assets/img/team/2.jpg"
                                      alt="Thumb"
                                    />
                                  </a>
                                  <ul>
                                    <li>
                                      <i className="fas fa-clock"></i> 08:27:00
                                    </li>
                                    <li>
                                      <i className="fas fa-list-ul"></i> 65
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="info">
                                <div className="meta">
                                  <ul>
                                    <li>
                                      <a href="#">Illustration</a>
                                      <a href="#">Design</a>
                                    </li>
                                    <li>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <span>(2k)</span>
                                    </li>
                                  </ul>
                                </div>
                                <h4>
                                  <a href="#">Social Science & Humanities</a>
                                </h4>
                                <p>
                                  Wisdom praise things she before. Be mother
                                  itself vanity favour do me of. Begin sex was
                                  power joy after had walls miles.
                                </p>
                                <div className="footer-meta">
                                  <div className="blog-area">
                                    <div className="info">
                                      <div
                                        className="content"
                                        style={{ padding: 0 }}
                                      >
                                        <a
                                          href="#"
                                          style={{
                                            padding: "10px 30px",
                                            color: "#000",
                                          }}
                                        >
                                          <i className="fa fa-bookmark"></i>{" "}
                                          Book Now
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="item">
                              <div className="thumb">
                                <span className="badge">Best Seller</span>
                                <img
                                  src="assets/img/courses/6.jpg"
                                  alt="Thumb"
                                />
                                <div className="overlay">
                                  <a href="#">
                                    <img
                                      src="assets/img/team/3.jpg"
                                      alt="Thumb"
                                    />
                                  </a>
                                  <ul>
                                    <li>
                                      <i className="fas fa-clock"></i> 04:15:38
                                    </li>
                                    <li>
                                      <i className="fas fa-list-ul"></i> 32
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="info">
                                <div className="meta">
                                  <ul>
                                    <li>
                                      <a href="#">Science</a>
                                      <a href="#">Tech</a>
                                    </li>
                                    <li>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <span>(4.6k)</span>
                                    </li>
                                  </ul>
                                </div>
                                <h4>
                                  <a href="#">Programming MasterclassName</a>
                                </h4>
                                <p>
                                  Wisdom praise things she before. Be mother
                                  itself vanity favour do me of. Begin sex was
                                  power joy after had walls miles.
                                </p>
                                <div className="footer-meta">
                                  <div className="blog-area">
                                    <div className="info">
                                      <div
                                        className="content"
                                        style={{ padding: 0 }}
                                      >
                                        <a
                                          href="#"
                                          style={{
                                            padding: "10px 30px",
                                            color: "#000",
                                          }}
                                        >
                                          <i className="fa fa-bookmark"></i>{" "}
                                          Book Now
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div> */}

                {/* <div className="weekly-top-items carousel-shadow default-padding-bottom">
                  <div className="container">
                    <div className="row" style={{ display: "block" }}>
                      <div className="col-md-12">
                        <div className="top-courses">
                          <div className="heading">
                            <h4>
                              Trending Universities <strong></strong>
                            </h4>
                          </div>
                          <div className="top-course-items top-courses-carousel owl-carousel owl-theme">
                            <div className="item">
                              <div className="thumb">
                                <span className="badge">New</span>
                                <img
                                  src="assets/img/courses/1.jpg"
                                  alt="Thumb"
                                />
                                <div className="overlay">
                                  <a href="#">
                                    <img
                                      src="assets/img/team/1.jpg"
                                      alt="Thumb"
                                    />
                                  </a>
                                  <ul>
                                    <li>
                                      <i className="fas fa-clock"></i> 04:15:38
                                    </li>
                                    <li>
                                      <i className="fas fa-list-ul"></i> 18
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="info">
                                <div className="meta">
                                  <ul>
                                    <li>
                                      <a href="#">Education</a>
                                      <a href="#">Tech</a>
                                    </li>
                                    <li>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star-half-alt"></i>
                                      <span>(1k)</span>
                                    </li>
                                  </ul>
                                </div>
                                <h4>
                                  <a href="#">
                                    Florida International University
                                  </a>
                                </h4>
                                <p>University Park, Florida,U.S.USA...</p>
                                <div className="footer-meta">
                                  <div className="blog-area">
                                    <div className="info">
                                      <div
                                        className="content"
                                        style={{ padding: 0 }}
                                      >
                                        <a
                                          href="#"
                                          style={{
                                            padding: "10px 30px",
                                            color: "#000",
                                          }}
                                        >
                                          <i className="fa fa-bookmark"></i>{" "}
                                          Contact
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="item">
                              <div className="thumb">
                                <img
                                  src="assets/img/courses/2.jpg"
                                  alt="Thumb"
                                />
                                <div className="overlay">
                                  <a href="#">
                                    <img
                                      src="assets/img/team/2.jpg"
                                      alt="Thumb"
                                    />
                                  </a>
                                  <ul>
                                    <li>
                                      <i className="fas fa-clock"></i> 08:27:00
                                    </li>
                                    <li>
                                      <i className="fas fa-list-ul"></i> 65
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="info">
                                <div className="meta">
                                  <ul>
                                    <li>
                                      <a href="#">Illustration</a>
                                      <a href="#">Design</a>
                                    </li>
                                    <li>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <span>(2k)</span>
                                    </li>
                                  </ul>
                                </div>
                                <h4>
                                  <a href="#">
                                    Florida International University
                                  </a>
                                </h4>
                                <p>University Park, Florida,U.S.USA...</p>
                                <div className="footer-meta">
                                  <div className="blog-area">
                                    <div className="info">
                                      <div
                                        className="content"
                                        style={{ padding: 0 }}
                                      >
                                        <a
                                          href="#"
                                          style={{
                                            padding: "10px 30px",
                                            color: "#000",
                                          }}
                                        >
                                          <i className="fa fa-bookmark"></i>{" "}
                                          Contact
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="item">
                              <div className="thumb">
                               
                                <img
                                  src="assets/img/courses/6.jpg"
                                  alt="Thumb"
                                />
                                <div className="overlay">
                                  <a href="#">
                                    <img
                                      src="assets/img/team/3.jpg"
                                      alt="Thumb"
                                    />
                                  </a>
                                  <ul>
                                    <li>
                                      <i className="fas fa-clock"></i> 04:15:38
                                    </li>
                                    <li>
                                      <i className="fas fa-list-ul"></i> 32
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="info">
                                <div className="meta">
                                  <ul>
                                    <li>
                                      <a href="#">Science</a>
                                      <a href="#">Tech</a>
                                    </li>
                                    <li>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <span>(4.6k)</span>
                                    </li>
                                  </ul>
                                </div>
                                <h4>
                                  <a href="#">
                                    Florida International University
                                  </a>
                                </h4>
                                <p>University Park, Florida,U.S.USA...</p>
                                <div className="footer-meta">
                                  <div className="blog-area">
                                    <div className="info">
                                      <div
                                        className="content"
                                        style={{ padding: 0 }}
                                      >
                                        <a
                                          href="#"
                                          style={{
                                            padding: "10px 30px",
                                            color: "#000",
                                          }}
                                        >
                                          <i className="fa fa-bookmark"></i>{" "}
                                          Contact
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div
                  className="fun-factor-area default-padding text-center bg-fixed shadow dark-hard"
                  style={{
                    backgroundImage:
                      "url(" + "../assets/img/banner/20.jpg" + ")",
                  }}
                >
                  <div className="container" style={{ padding: 100 }}>
                    <div className="row" style={{ display: "block" }}>
                      <div className="col-md-2 ">
                        <div className="fun-fact">
                          <div className="icon">
                            <i class="fab fa-facebook-f"></i>
                          </div>
                          <div className="info">
                            <span
                              className="timer"
                              data-to="116179"
                              data-speed="5000"
                            ></span>
                            <span className="medium">Facebook</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-6 item">
                        <div className="fun-fact">
                          <div className="icon">
                            <i
                              className="fab fa-twitter"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="info">
                            <span
                              className="timer"
                              data-to="116000"
                              data-speed="5000"
                            ></span>
                            <span className="medium">Twitter</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-6 item">
                        <div className="fun-fact">
                          <div className="icon">
                            <i className="fab fa-instagram"></i>
                          </div>
                          <div className="info">
                            <span
                              className="timer"
                              data-to="37300"
                              data-speed="5000"
                            ></span>
                            <span className="medium">Instagram</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-6 item">
                        <div className="fun-fact">
                          <div className="icon">
                            <i className="fab fa-youtube"></i>
                          </div>
                          <div className="info">
                            <span
                              className="timer"
                              data-to="11184"
                              data-speed="5000"
                            ></span>
                            <span className="medium">Youtube</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-6 item">
                        <div className="fun-fact">
                          <div className="icon">
                            <i className="fab fa-facebook-f"></i>
                          </div>
                          <div className="info">
                            <span
                              className="timer"
                              data-to="880"
                              data-speed="5000"
                            ></span>
                            <span className="medium">Facebook Review</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-6 item">
                        <div className="fun-fact">
                          <div className="icon">
                            <i className="fab fa-google-plus"></i>
                          </div>
                          <div className="info">
                            <span
                              className="timer"
                              data-to="100"
                              data-speed="5000"
                            ></span>
                            <span className="medium">Google Review</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="blog-area default-padding bottom-less">
                  <div className="weekly-top-items">
                    <div className="container">
                      <div className="col-md-12">
                        <div
                          className="top-courses"
                          style={{ paddingRight: 0 }}
                        >
                          <div
                            className="heading"
                            style={{ paddingBottom: 15 }}
                          >
                            <h4 style={{ color: "#3f8ccb" }}>
                              LATEST BLOGS<strong></strong>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container">
                    <div className="">
                      <div className="blog-items">
                        <div className="col-md-4 single-item">
                          <div className="item">
                            <div className="thumb">
                              <a href="#">
                                <img src="assets/img/blog/1.jpg" alt="Thumb" />
                              </a>
                            </div>
                            <div className="info">
                              <div className="content">
                                <h5>
                                  <a href="#">
                                    Inquietude assistance precaution any
                                    impression man sufficient.
                                  </a>
                                </h5>

                                <a href="#">
                                  <i className="fas fa-plus"></i> Read More
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 single-item">
                          <div className="item">
                            <div className="thumb">
                              <a href="#">
                                <img src="assets/img/blog/2.jpg" alt="Thumb" />
                              </a>
                            </div>
                            <div className="info">
                              <div className="content">
                                <h5>
                                  <a href="#">
                                    Discourse ye continued pronounce we
                                    Particular use abilities.
                                  </a>
                                </h5>

                                <a href="#">
                                  <i className="fas fa-plus"></i> Read More
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 single-item">
                          <div className="item">
                            <div className="thumb">
                              <a href="#">
                                <img src="assets/img/blog/3.jpg" alt="Thumb" />
                              </a>
                            </div>
                            <div className="info">
                              <div className="content">
                                <h5>
                                  <a href="#">
                                    Deficient discourse do newspaper be an
                                    eagerness continued.
                                  </a>
                                </h5>

                                <a href="#">
                                  <i className="fas fa-plus"></i> Read More
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </Container>
    );
  }
}
