/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";

import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';
  





const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
input:{
  padding:'0px 0px',
},
  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:16,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },
widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#002147',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function OnlinePayment(props) {
  const classes = useStyles();
  const [firstName,setFirstName]=React.useState('')
  const [lastName,setLastName]=React.useState('')
  const [emailId, setEmailId]=React.useState('')
  const [password,setPassword]=React.useState('')
  const [verifyPassword,setVerifyPassword]=React.useState('')
  const [messages, setMessages ]=React.useState('')
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus,setStatus]=React.useState('') 

  const handlePicture=(event)=>{
    setPicturePath(URL.createObjectURL(event.target.files[0]))
    setPicture(event.target.files[0])
   }

   const handleVerifyPassword=(e)=>{
    setVerifyPassword(e.target.value)
    if(password===e.target.value){
      setStatus("Password Matched")
    }
    else{
      setStatus("Password not matched")
    }
  }


  const addNewRecord=async()=>{
if(picture=='')
{
    var body={'firstName':firstName,
    'lastName':lastName, 
    'emailId':emailId, 
    'password':password,
    'status':"Not Approved",
    'picture':"Not Found"}
    var result=await postData('selfregister/addNewRecord',body)
    if(result){
      await setMessages("Agent Succefully Created")

      var body={
        'emailid':emailId,
        'password':password
       }
var resultt = await  postData('selfregister/checkagentsignin',body)

if (resultt.RESULT)
{

localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
}
else
{
alert("Failed To Created Account")
}

await setFirstName('')
await setLastName('')
await setEmailId('')
await setPassword('')
await setVerifyPassword('')
await setPicturePath('')
}
else{setMessages("Faild to Submit")}

}

else{
   var formData=new FormData()
   formData.append('firstName',firstName)
   formData.append('lastName',lastName)
   formData.append('emailId',emailId)
   formData.append('password',password)
   formData.append('picture',picture)
   formData.append('status', "Not Approve")
  
   var config={headers:{'content-type':'multipart/form-data'}}  
    var result=await postDataAndImage('selfregister/addNewRecordPic',formData,config)


    if(result){
      await setMessages("Agent Succefully Created")

      var body={
        'emailid':emailId,
        'password':password
       }
var resultt = await  postData('selfregister/checkagentsignin',body)

if (resultt.RESULT)
{

localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
}
else
{
alert("Failed To Created Account")
}

await setFirstName('')
await setLastName('')
await setEmailId('')
await setPassword('')
await setVerifyPassword('')
await setPicturePath('')
}
else{setMessages("Faild to Submit")}
    }
    }
   
   
    console.log("History ==  ",props)
  return (
   <Container className={classes.widths}>
    
    
   
      <div className="top-bar-area bgdark text-light" style={{backgroundColor:'#002147',color:'white',fontSize:13,}}>
        <div className="container" >
            <div className="row">
            
                <div className="col-md-8 address-info text-left">
                    <div className="info">
                        <ul style={{marginBottom:0,}}>
                            <li>
                                <i className="fas fa-copy"></i> Total courses: <strong>23400</strong>
                            </li>
                            <li>
                                <i className="fas fa-user-shield"></i> Partner: <strong>655</strong>
                            </li>
                            <li>
                                <i className="fas fa-phone"></i> Help Line: <strong>+91-8088-867-867</strong>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-md-4 link text-right">
                    <ul style={{marginBottom:0,}}>
                        <li >
                            <a href="#" className={classes.day}>Register</a>
                        </li>
                        <li>
                            <a href="/Login">Login</a>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
    <header id="home">
        <nav className="navbar navbar-default attr-border navbar-sticky bootsnav">
            <div className="container">
                <div className="row">
                    {/* <div className="top-search">
                    <div class="input-group">
                            <form action="#">
                                <input type="text" name="text" class="form-control" placeholder="Search" />
                                <button type="submit">
                                    <i class="ti-search"></i>
                                </button>
                            </form>
                        </div>
                    </div> */}
                </div>
            </div>


            <div className="container">


                {/* <div className="attr-nav">
                    <ul>
                        <li className="search"><a href="#"><i className="ti-search"></i></a></li>
                    </ul>
                </div> */}

    <div className="navbar-header">
    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                        <i class="fa fa-bars"></i>
                    </button>
                    <a class="navbar-brand" href="/">
                        <img src="assets/img/logo.png" class="logo" alt="Logo" />
                    </a>
</div>

                <div className="collapse navbar-collapse" id="navbar-menu">
                <ul className="nav navbar-nav navbar-right" style={{display: 'block',}} data-in="#" data-out="#">
                    <li className="dropdown">
						   <a href="/"  className="dropdown" >Home</a>
						                           
                        </li>
                        <li className="dropdown">
                            <a href="/AboutSection" className="dropdown"  > About Section</a>
                            
                        </li>
                        <li className="dropdown">
                            <a href="SchoolDetails" className="dropdown"  > University Detail</a>
                           
                        </li>
                        <li className="dropdown">
                            <a href="/CreateAccount" className="dropdown"  > Create Account</a>
                           
                        </li>
                       
                      
                        <li class="dropdown">
                            <a href="#" class="dropdown-toggle" data-toggle="dropdown" > Pages</a>
                            <ul class="dropdown-menu">
                                <li><a href="/SearchByCheckBox">Search By CheckBox</a></li>
                                <li><a href="/OnlinePayment">Payment</a></li>
                                <li><a href="/ApplyProgram">Apply Program</a></li>
                                <li><a href="contact.html">Contact</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>

        </nav>


    </header>
    <div className="login-area default-padding" style={{backgroundColor:'#FFF'}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">
                <div className="col-md-6 col-md-offset-1">
                <img style={{flex:1}} class="login_image" src="assets/img/payment.png" alt="Login" data-original-title="" title="" />

                </div>
                <div className="col-md-5">
                  
                <Container>
      
      <CssBaseline />
      
      <MDBContainer>
      <MDBRow>
        <MDBCol md="12">
          <MDBCard>
            <MDBCardBody>
              <MDBCardHeader className="form-header deep-blue-gradient rounded" style={{color: "white", textAlign:"center",padding:'2px 2px',}}>
                <h3 className="my-3">
                  <MDBIcon icon="credit-card" /> Payment
                </h3>
              </MDBCardHeader>
              <div className={classes.paper}>
       
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
             // inputProps={{style: {fontSize: 40}}}  
             // InputLabelProps={{style: {fontSize: 12}}} 
                autoComplete="name"
                name="name"
                variant="outlined"
                required
                fullWidth
                id="name"
                label="Name"
                autoFocus
                onChange={(event)=>setFirstName(event.target.value)}
              />
            </Grid>
           
            <Grid item xs={12}>
              <TextField
             // InputLabelProps={{style: {fontSize: 12}}}
                variant="outlined"
                required
                fullWidth
                id="emailid"
                value={emailId}
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(event)=>setEmailId(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
            <MuiPhoneInput
         
  defaultCountry='in'
  regions={'asia'}

  variant="outlined"
  className={classes.formControl}
  label="Phone"
 // inputProps={{    style: {fontSize: 12}   }}
 // InputLabelProps={{style: {fontSize: 12}}} // font size of input label
/>
             
            </Grid>
           
<Grid item xs={12}>
<TextField
variant="outlined"
multiline
rows={2}
rowsMax={4}
required
fullWidth
id="address"
label="Full Address"
name="address"
autoComplete="address"
onChange={(event)=>setEmailId(event.target.value)}
/>
</Grid>
            <Grid item xs={12}>
              <TextField
         //     InputLabelProps={{style: {fontSize: 12}}}
                variant="outlined"
                required
                fullWidth
                name="city"
                label="City"
                type="city"
                id="city"
                autoComplete="city"
                onChange={(e)=>handleVerifyPassword(e)}
              />
             
            </Grid>
           
   <Grid item xs={12}>
      <FormControl className={classes.formControl}>
        <InputLabel >Country</InputLabel>
        <Select
      //  InputLabelProps={{style: {fontSize: 12}}}
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          
        >
         
          <MenuItem  value={10}>India</MenuItem>
          <MenuItem  value={20}>USA</MenuItem>
          <MenuItem  value={30}>Canada</MenuItem>
        </Select>
      </FormControl>
</Grid>
   
<Grid item xs={12}>        
  <TextField
 
    label="Zip Code"
    type="text"

    className={classes.formControl}
   
  />
</Grid>

            <Grid item xs={12}>
               
            <TextField
                variant="outlined"
                required
                fullWidth
                name="amount"
                label="Amount"
                type="number"

              />
     
           </Grid>
       
          </Grid>
         

          <div className="text-center mt-3">
        <MDBBtn gradient="blue" rounded variant="contained" style={{fontSize:15,}}  onClick={addNewRecord}color="primary" className="btn-block z-depth-1a" fullWidth>
        Submit
        </MDBBtn>
       </div>
        <Typography>
        {messages}
        </Typography>
 
        <br/>
         


        </form>
      </div>
             
            </MDBCardBody>
          </MDBCard>
       </MDBCol>
       </MDBRow>
    </MDBContainer>
      
     
    </Container>

                </div>

</div>
</div>
</div>
</div>

<footer class="bgdark text-light" style={{backgroundColor:'#3f8ccb',color:'white',fontSize:13,}}>
        <div class="container">
            <div class="f-items default-padding">
                <div class="row" style={{display:'block'}}>

                    <div class="col-md-3 col-sm-6 item equal-height">
                        <div class="f-item link">
                        <h4>Services</h4>
                            <ul>
                                <li>
                                    <a href="#"><i class="ti-angle-right"></i> Students</a>
                                </li>
                                <li>
                                    <a href="#"><i class="ti-angle-right"></i> Universities</a>
                                </li>
                             
                              </ul>
                              
                              <div class="f-item about" style={{marginTop:50,}}>
                              <h4>Get Social</h4>
                              <div class="social">
                            <a href="#"><i style={{fontSize:20,margin:10,}} class="fab fa-facebook-f"></i></a>
                            <a href="#"><i style={{fontSize:20,margin:10,}} class="fab fa-twitter"></i></a>
                            <a href="#"><i style={{fontSize:20,margin:10,}} class="fab fa-linkedin-in"></i></a>
                            <a href="#"><i style={{fontSize:20,margin:10,}} class="fab fa-youtube"></i></a>
                            </div></div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 item equal-height">
                        <div class="f-item link">
                            <h4>About</h4>
                            <ul>
                                <li>
                                    <a href="#"><i class="ti-angle-right"></i> Our Story</a>
                                </li>
                                <li>
                                    <a href="#"><i class="ti-angle-right"></i> Careers</a>
                                </li>
                                <li>
                                    <a href="#"><i class="ti-angle-right"></i> Blog</a>
                                </li>
                                <li>
                                    <a href="#"><i class="ti-angle-right"></i> Press</a>
                                </li>
                                <li>
                                    <a href="#"><i class="ti-angle-right"></i> Life</a>
                                </li>
                                <li>
                                    <a href="#"><i class="ti-angle-right"></i> Leadership</a>
                                </li>
                                <li>
                                    <a href="#"><i class="ti-angle-right"></i> Contact</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 item equal-height">
                        <div class="f-item link">
                            <h4>Resources</h4>
                            <ul>
                                <li>
                                    <a href="#"><i class="ti-angle-right"></i> Events & Webinars</a>
                                </li>
                                <li>
                                    <a href="#"><i class="ti-angle-right"></i> StudyMetro Fees</a>
                                </li>
                                
                            </ul>
                            
                            <div class="f-item link" style={{marginTop:30,}}>
                              <h4>Product</h4>
                              <ul>
                              <li>
                                    <a href="#"><i class="ti-angle-right"></i> Discover Programs</a>
                                </li>
                                <li>
                                    <a href="#"><i class="ti-angle-right"></i> Discover Universities</a>
                                </li>
                                <li>
                                    <a href="#"><i class="ti-angle-right"></i> Register</a>
                                </li>
                            </ul></div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 item equal-height">
                    <div class="f-item link">
                            <h4>Legal</h4>
                            <ul>
                                <li>
                                    <a href="/Privacy"><i class="ti-angle-right"></i> Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="/TermsCondition"><i class="ti-angle-right"></i> Term & Conditions</a>
                                </li>
                                {/* <li>
                                    <a href="#"><i class="ti-angle-right"></i> Terms of Use</a>
                                </li> */}
                                
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <p style={{textAlign:'center'}}>&copy; Copyright 2020. Designed by <a href="https://www.plus91labs.com"> Plus91labs</a></p>
                    </div>
                    {/* <div class="col-md-6 text-right link">
                        <ul>
                            <li>
                                <a href="#">Terms of user</a>
                            </li>
                            <li>
                                <a href="#">License</a>
                            </li>
                            <li>
                                <a href="#">Support</a>
                            </li>
                        </ul>
                    </div> */}
                </div>
            </div>
        </div>

    </footer>
    </Container>
  );
}
