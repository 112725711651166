/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import icon from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Header from './Header';
import Footer from './Footer';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
  } from "mdbreact";
import color from '@material-ui/core/colors/amber';



const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav :{
    flexDirection:'row',

  },
newbtn:{
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
},

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize:12,
    
  },
 
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom:"10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop:"10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',
   
    
  },

widths:{
    paddingLeft:0,
    paddingRight:0,
    maxWidth:'100%',
    
},
bgdark:{
  backgroundColor:'#3f8ccb',
},
day: {
  "&:hover": {
    background: "#efefef"
  },
  "&:last-child": {
    borderRight: "solid 1px #cccccc"
  }
},
}));

export default function Compare(props) {
  const classes = useStyles();
 


  return (
   <Container className={classes.widths}>
   <Header/>  <CssBaseline />
    <div className="login-area default-padding" style={{paddingTop:20,}}>
        <div className="">
            <div className="row" >
                <div className="col-md-12">
<div className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard" style={{backgroundImage: "url(" + "../assets/img/banner/6.jpg" + ")",padding:70}}>             

        <div className="container">
            <div className="row">
            <div className="double-items col-md-12 info">
                <h2 className="wow fadeInLeft">Singapore</h2>
                </div>
             
            </div>
        </div>
    </div>


    <section class="video_sec blog_wrap">
<div class="container">
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <div class="blog_box faq">
                <h2>Frequently Asked Questions?</h2>
                <div class=" blog_box_content">
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is there a deadline for applying?</h3>
                        <p style={{color:'#333'}}>A: No deadline for admissions.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: IS GRE/GMAT or IELTS/TOEFL is required for admission?</h3>
                        <p style={{color:'#333'}}>A: IELTS/ TOFEL not required for admission</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much Time it will take to get the offer letter?</h3>
                        <p style={{color:'#333'}}>A: It takes minimum 3 weeks and maximum 1 month to get offer letter. </p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long will it take for me to obtain my internship employment?</h3>
                        <p style={{color:'#333'}}>A: To obtain a internship employment it will take around 1 or 2 months maximum.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What type of employment will I get initially?</h3>
                        <p style={{color:'#333'}}>A: Employment is depend on your skills and experience.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Must I find an internship job only in the field related to my major or is any job O.K.?</h3>
                        <p style={{color:'#333'}}>A: We recommend you to find internship jobs into the concerned field of your study program as internships are meant for international exposure and practical experience. 

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I have to work overtime? Can I keep the overtime bonus?</h3>
                        <p style={{color:'#333'}}>A: No.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What if I can't find employment?</h3>
                        <p style={{color:'#333'}}>A: You can find a part time work till you find your internship job into your profile.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Will I have to pay taxes?</h3>
                        <p style={{color:'#333'}}>A: Yes.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is there a way for me to work before actually starting to attend classes in order for me to save more money and add it to my current savings?</h3>
                        <p style={{color:'#333'}}>A: No as you will be flying only before 3 or 4 days of your intake date.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Could the work authorization be possibly converted into a full time work permit upon completion of the masters program?</h3>
                        <p style={{color:'#333'}}>A: Yes, once you complete you Bachelors or Masters Program you are entitled for 1years post study work visa where you can work full time.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much is the bank statement required for applying VISA?</h3>
                        <p style={{color:'#333'}}>A: Proof of financial ability (showing the total amount of more than SG$30,000) in the form of bank statements.
</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How long I have to keep amount in my bank account for VISA?</h3>
                        <p style={{color:'#333'}}>A: Minimum 1 days old bank statement should be provided for the visa interview.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much tuition fee I have to pay before and after getting VISA/Offer letter?</h3>
                        <p style={{color:'#333'}}>A: 1st year fees has to be paid before visa.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can one apply for Dependent VISA?</h3>
                        <p style={{color:'#333'}}>A: Yes. Preferably Dependents are allowed to fly only after 3 months.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How can apply as a dependent VISA?</h3>
                        <p style={{color:'#333'}}>A: You will require showing additional fund in the bank which supports their living expenses.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What is required in order to bring my spouse and family?</h3>
                        <p style={{color:'#333'}}>A: You will need to produce the documents needed to show your spouse’s or civil partner's status and evidence of your marital or civil partner status (for example, birth or naturalization certificate of Irish spouse, marriage or civil registration certificate), and also you require additional funds to support living Expenses.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my spouse get the wok permit there?</h3>
                        <p style={{color:'#333'}}>A: Yes.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much duration work permit will spouse get there?</h3>
                        <p style={{color:'#333'}}>A: Your spouse can work till the duration of the study program of the student.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I apply work permit after completing my studies?</h3>
                        <p style={{color:'#333'}}>A: Yes, student who has completed his Bachelors or Masters program are entitled for 6 Month  work permit after the study program if it’s a 1yr program and if its 2yrs program 1yr study and 1yr work permit is given.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How much time will I get after my studies to search job there?</h3>
                        <p style={{color:'#333'}}>A: The time to search job is included in 2yrs work permit.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my spouse extend the work permit?</h3>
                        <p style={{color:'#333'}}>A: No.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can my accompanying dependent attend school?</h3>
                        <p style={{color:'#333'}}>A: No.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is IELTS required for dependent?</h3>
                        <p style={{color:'#333'}}>A: No.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Will University secure job for me?</h3>
                        <p style={{color:'#333'}}>A: No. University can assist you in finding your internship jobs.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Why Should I study in xyz Company?</h3>
                        <p style={{color:'#333'}}>A: •	Basics Are Inexpensive
•	No ielts required
•	No embassy interview.
•	One day bank balance.
•	Tuition fee pay after visa.
•	Paid internship in hotels with stipend of 700 SGD to 1000 SGD.
•	Job according to minister of manpower rules.
•	6 months paid internship with Training Work Permit.

</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Why should I Study in Singapore?</h3>
                        <p style={{color:'#333'}}>A: Singapore is one of the most developed economies of Asia. It has world class education with National University of Singapore ranked 24th in the world. It is a safe &amp; secure place to stay for students. Diverse cultures, languages and religions give Singapore a cosmopolitan feel. Environment in Singapore is very good, clean &amp; buzzing. Singapore is also a corruption, crime &amp; drug free country. Its proximity to India means cheap &amp; quick travel.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Would I get Home Sick in Singapore?</h3>
                        <p style={{color:'#333'}}>A: There is a strong Indian influence in Singapore. Indian food – Veg, Non Veg, North Indian &amp; South Indian is available at every step. Presence of Indian Temples, Grocery Stores &amp; other shops will make you feel like home.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Why the Bachelors Degree is for 2 years in Singapore and Masters is for 1 year?</h3>
                        <p style={{color:'#333'}}>A: Many Singapore Universities/Institutes are affiliated to top USA, UK &amp; Australian Universities. Since the Curriculum &amp; subject referred are same as the mother university and there are less holidays so the course gets finished comparatively early.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: How do I apply to study in Singapore?</h3>
                        <p style={{color:'#333'}}>A: Top universities like National University of Singapore, Nanyang Technology University (NTU), Singapore Management University (SMU) have one intake per year and has a very structured application process. Private Universities are more flexible &amp; have multiple intakes and pathways. We are experts in putting the correct application for you within deadlines. Contact our counselor today.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Is there an Application fee to apply to Singapore institution?</h3>
                        <p style={{color:'#333'}}>A: Yes, some of the Singapore institutions do have an application fees and some don’t. We will give you all details – amount of the application fee for each Institute. In some cases you can avail the application fee waivers which are available with us.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I need to know a foreign language to study abroad?</h3>
                        <p style={{color:'#333'}}>A: English is the Official Language so communication is no problem. All courses are taught in English.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What kind of accommodation will I be able to get while studying in Singapore?</h3>
                        <p style={{color:'#333'}}>A: There are 3 types of accommodation available in Singapore. On campus or off campus college hostel, private hostels, shared flat or house with other students, paying guest/ home stay accommodation. We will assist you with all of the above.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I apply now even though I haven’t finished my studies (Class 12 or Bachelors)?</h3>
                        <p style={{color:'#333'}}>A: Yes, you can apply up to 1 year in advance. Some course &amp; universities are very, very competitive and you have to apply 3-6 months in advance. You would need to submit your current study details and you can obtain a provisional/ conditional admission offer letter. This will become a full offer when you submit your course completion details. Early admissions give you time to organize any compulsory exam like IELTS/SAT/GMAT etc required by that university. It also allows you to get your documentation, finances, passport, visa requirements etc in order by the time you complete your Class 12 or Bachelors.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I work part time while studying in Singapore?</h3>
                        <p style={{color:'#333'}}>A: No, students studying in private institutes are not allowed to work part-time during the studies. But you do have a option to take the paid internship while doing your course. Internships after the main course is also available in Singapore.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Do I need to give IELTS to apply in Singapore? What are the English Proficiency requirements?</h3>
                        <p style={{color:'#333'}}>A: No, IELTS is not compulsory in Singapore. Some institutions need a letter of instruction from your education institute to know if your last qualification was in English or not. If you have done CBSE, ISC, IB your English score will suffice. Note: Certain foreign University collaboration may ask for IELTS or TOEFL.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: Can I get an education loan?</h3>
                        <p style={{color:'#333'}}>A: Yes, every student can apply for an education loan. Banks and private companies give up to 90% funding for education abroad by meeting their criteria. The interest rate for education loans is lower. The repayments generally start 6 months after completion of the course. We will give you all the details.</p>
                    </div>
                                    <div class="faq_box">
                        <h3 style={{color:'#000'}}>Q: What are the benefits of studying in Singapore?</h3>
                        <p style={{color:'#333'}}>A: So many things make Studying in Singapore a great destination to Study Abroad. Singapore education gives you good career opportunity to grow. A premier education hub which offers students an international perspective and choice of options. Tuition fees are affordable and the cost of living is significantly lower than of many developed countries. It is well connected by air to all parts of India. It is fairly close to India encouraging students &amp; parents to visit during holidays. A very safe country to study in.

</p>
                    </div>
                                   
                </div>
                
            </div>

        </div>
    </div>

</div>

</section>



    <div className="advisor-details-area default-padding" style={{padding:0,}}>
   
   </div>



</div>
</div>
</div>
</div>

<Footer/>
    </Container>
  );
}
